import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ORG } from "../types/organisation";
import { removeUndefined } from "../utils/object";
import { queryParams } from "./../types/common";

interface OrganisationsQueryParams extends queryParams {
	seedingOrgId?: number;
}
@Injectable({
	providedIn: "root"
})
export class OrganisationService {
	baseURL = environment.baseUrl;
	selectedOrg = new BehaviorSubject<ORG | null>(null);
	orgsCache = new Map<string, Array<ORG>>();
	orgsWithPaginationCache = new Map<string, any>();
	membersMap = new Map();
	constructor(public http: HttpClient) {}
	refreshEvent = new EventEmitter();
	clearCache() {
		this.orgsCache.clear();
		this.orgsWithPaginationCache.clear();
	}
	setSelectedSubject(org: ORG | null) {
		this.selectedOrg.next(org);
	}
	getSelectedOrg() {
		return this.selectedOrg;
	}
	getOrg(id: number) {
		return this.http.get<any>(`${this.baseURL}/organisation/${id}`);
	}
	getOrgsFromAPI(qParams: OrganisationsQueryParams = {}) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...qParams,
					sortBy: qParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		const requestUrl = `${this.baseURL}/organisation`;
		return this.http.get<any>(requestUrl, { params });
	}
	refreshOrgs(qParams: OrganisationsQueryParams | null = null) {
		return new Promise<void>(resolve => {
			const params = new HttpParams({
				fromObject: removeUndefined(
					{
						...qParams
						// sortBy: qParams.sortBy?.toUpperCase()
					} ?? {}
				)
			});
			const requestUrl = `${this.baseURL}/organisation`;
			this.http.get<any>(requestUrl, { params }).subscribe((res: any) => {
				let key = JSON.stringify(qParams);
				this.orgsCache.set(key, res.list);
				this.orgsWithPaginationCache.set(key, res);
				resolve();
			});
		});
	}
	async getOrgs(qParams: OrganisationsQueryParams | null = null) {
		let key = JSON.stringify(qParams);
		if (!this.orgsCache.has(key)) await this.refreshOrgs(qParams);
		if (qParams) {
			return of(this.orgsWithPaginationCache.get(key));
		}
		return of(this.orgsCache.get(key) as ORG[]);
	}
	create(payload: any) {
		return this.http.post<any>(`${this.baseURL}/organisation`, payload);
	}
	save(id: string, payload: any) {
		return this.http.put<any>(`${this.baseURL}/organisation/${id}`, payload);
	}
	inviteMembers(payload: any) {
		return this.http.post<any>(`${this.baseURL}/organisation/invite`, payload);
	}
	getMembers(orgId: number, qParams: { role?: "ADMIN" | "MEMBER" } = {}) {
		const params = new HttpParams({
			fromObject: removeUndefined(qParams)
		});
		let key = orgId + params.toString();
		if (this.membersMap.has(key)) {
			return of({ list: this.membersMap.get(key) });
		}
		return new Observable(observer => {
			this.http
				.get<any>(`${this.baseURL}/organisation/${orgId}/members`, { params })
				.subscribe((res: any) => {
					this.membersMap.set(key, res.list);
					observer.next(res);
					observer.complete();
				});
		});
	}
	transferOwnership(id: number, payload: any) {
		return this.http.post<any>(
			`${this.baseURL}/organisation/${id}/ownership`,
			payload
		);
	}
}
