<h1 mat-dialog-title>
	Activity Logs - {{name}}
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<section fxLayout="column">
		<div fxLayout="row wrap" fxLayoutAlign="start center">
			<span class="ca-spacer"></span>
			<mat-paginator class="ca-mat-paginator" [length]="resultsLength" [pageSize]="pageSize"
				[showFirstLastButtons]="(resultsLength/pageSize)>2" [pageSizeOptions]="[10,25,50,100]" aria-label="Select page">
			</mat-paginator>
		</div>
		<div fxLayout="column" class="ca-page-content">
			<div fxLayout="row" fxLayoutAlign="center center" fxFlex="shrink" *ngIf="!isLoadingResults  && data.length===0">
				<div *ngIf="(filterFormControl?.value===null || filterFormControl?.value?.length===0)">
					All Activity on <b>{{name}}</b> will appear here
				</div>
				<div *ngIf="filterFormControl?.value?.length>0">
					There are no results available for this keyword <b>"{{ filterFormControl.value}}"</b>. Please
					refine
					your search with some other words.
				</div>
			</div>
			<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
			<div class="ca-mat-table-container" [hidden]="isLoadingResults || data.length===0">
				<app-ng-scrollbar-wrapper [autoHeightDisabled]="true">
					<mat-table class="mat-elevation-z8" [dataSource]="data" matSort [matSortActive]="'createdAt'"
						matSortDirection="desc" fixedLayout="true">
						<ng-container matColumnDef="index">
							<mat-header-cell *matHeaderCellDef>
								No.
							</mat-header-cell>
							<mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="activity">
							<mat-header-cell *matHeaderCellDef>
								Activity
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<div *ngIf="LOG_TYPE_STATUSES.includes(element.type);else changeTRef"
									[innerHTML]="element |log:activityOf">

								</div>
								<ng-template #changeTRef>
									<div style="display: flex;align-items: center;" *ngFor="let delta of element.delta">
										<span>
											{{logKeyLabelMap[delta]}} :&nbsp;</span>
										<span>
											{{element.oldValue[delta]}}
										</span>
										<mat-icon>arrow_right_alt</mat-icon>
										<span>
											{{element.newValue[delta]}}
										</span>
									</div>
								</ng-template>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="by">
							<mat-header-cell *matHeaderCellDef>
								By
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<app-avatar *ngIf="element?.by" [avatar]="element?.by">
								</app-avatar>
								<ng-container *ngIf="authService?.user?.role==='ADMIN' && element.character360Feedback?.ip">
									{{element.character360Feedback?.ip}}<ng-container
										*ngIf="element.character360Feedback?.location?.countryName">
										({{element.character360Feedback?.location?.countryName}})
									</ng-container></ng-container>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="createdAt">
							<mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">
								on
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								{{element.createdAt| date:'medium' }}
							</mat-cell>
						</ng-container>
						<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
					</mat-table>
				</app-ng-scrollbar-wrapper>
			</div>
		</div>
	</section>
</mat-dialog-content>
