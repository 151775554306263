<section>
	<app-organisation-select style="position: fixed;top: 0;left: 25%;"
		*ngIf="step2FG.get('orgId') && stepGroupStepper.selectedIndex===2" [showAll]="false" [global]="false"
		[showAvailableCredits]="true" [selectedOrgId]="step2FG?.value?.orgId" [forceReadOnly]="true"
		(selectedOrgChange)="updateOrg($event)" [showAvailableCredits]="true" [showOrgSelectedLabel]="true"
		[showAvailableCreditsLabel]="true">
	</app-organisation-select>
	<app-step-parent-group-stepper class="left-stepper-header  ca-stepper-with-content-scroll overflown-step-header"
		#stepGroupStepper [linear]="!(!!subscription?.id)" (selectionChange)="stepChange($event)">
		<cdk-step [stepControl]="step0FG">
			<ng-template cdkStepLabel>
				<div class="step-header">
					Select Organisation
					<app-avatar *ngIf="selectedOrg" [avatar]="selectedOrg">
					</app-avatar>
				</div>
			</ng-template>
			<div class="org-select">
				<app-organisation-select [showAll]="false" [selectedOrgId]="selectedOrg?.id" [global]="false"
				[showOrgSelectedLabel]="true" (selectedOrgChange)="updateStep0Org($event)"
				(preselectedEvent)="updateStep0Org($event)">
			</app-organisation-select>
		</div>
		</cdk-step>
		<cdk-step [stepControl]="step1FG" label="Select Parent">
			<ng-template cdkStepLabel>
				<div class="step-header">
					Select Parent
					<app-avatar *ngIf="selectedParent" [avatar]="selectedParent">
					</app-avatar>
					<button mat-button *ngIf="!selectedParent && stepGroupStepper.selectedIndex>0" (click)="openGroup()">
						<mat-icon color="primary">add</mat-icon>
						Create Group</button>
				</div>
			</ng-template>
			<app-step-parent-group-select *ngIf="selectedOrg" [selectedOrg]="selectedOrg"
				selectedParentId="step1FG.value.parentId" (selectParent)="selectParent($event)">
			</app-step-parent-group-select>
		</cdk-step>
		<cdk-step optional>
			<ng-template cdkStepLabel>
				<div class="step-header">
					Select Dependents
				</div>
			</ng-template>
			<form *ngIf="step2FG" [formGroup]="step2FG">
				<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="16px">
					<div class="dependent-panel" fxFlex="1 1 auto" fxFlexFill>
						<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
							<span class="ca-spacer"></span>
							<button mat-button color="primary" (click)="openCreateDependents()">
								<mat-icon>add</mat-icon> Create Group(s) </button>
							<button mat-button color="primary" (click)="openEligibleDependentsDialog()">
								<mat-icon>add</mat-icon> Add More
							</button>
							<button *ngIf="subscription?.id" [disabled]="step2FG.invalid || this.step2FG.pristine" mat-stroked-button
								color="primary" (click)="setupSubscription()">Update
								Dependents </button>
							<button *ngIf="subscription?.id" mat-flat-button color="primary" (click)="goto(3)">Setup
								Payment
							</button>
							<button *ngIf="!subscription?.id" [disabled]="step2FG.invalid" color="primary" mat-raised-button
								cdkStepperNext>Next</button>
							<button *ngIf="!subscription?.id" color="primary" mat-raised-button
								(click)="completeSetupSubscriptionAndClose()">Complete
								Setup</button>
						</div>
						<div fxLayout="column" fxLayoutAlign="start start" style="margin-bottom: 16px;">
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-form-field>
									<mat-label>filter</mat-label>
									<input matInput type="search" name="filterDependentsFC" [formControl]="filterDependentsFC">
									<button *ngIf="filterDependentsFC.value" matSuffix mat-icon-button aria-label="Clear"
										(click)="filterDependentsFC.setValue('')">
										<mat-icon>close</mat-icon>
									</button>
								</mat-form-field>
								<div style="position: relative;height:25px; width: 25px;">
									<app-loader [loaderId]="loaderId" [bgsSize]=25></app-loader>
								</div>
								<span class="ca-spacer"></span>
							</div>
							<mat-hint style="margin-top: -20px;">
								<small> NOTE: Groups shown are where you are
									listed as a
									Group member, Culture
									Consultant,
									Support
								</small>
							</mat-hint>
						</div>

						<table class="step-dependent-groups" *ngIf="filteredDependents?.length">
							<tr>
								<th>Ref</th>
								<th>Name</th>
								<th>Direct Debit</th>
								<th>Users in Group<br>(in Dependents)</th>
								<th>
									<span style="display: flex;align-items: center">
										Include Dependents
										<mat-icon matTooltip="This is applicable to upcoming added or removed dependent groups."
											color="accent">
											info
										</mat-icon>
									</span>
								</th>
								<th>Select </th>
							</tr>
							<tr formArrayName="dependents" *ngFor="let dependent of filteredDependents;">
								<ng-container [formGroupName]="dependent.i">
									<td>
										{{step2FG.value.dependents?.[dependent.i]?.groupRef}}
									</td>
									<td>
										<app-avatar *ngIf="step2FG.value.dependents?.[dependent.i]" [avatar]="dependent">
										</app-avatar>
									</td>
									<td>
										<span style="display: flex;align-items: center">
											<ng-container *ngIf="dependent.groupSubscription?.paymentInfoId; else notActivatedRef">
												<mat-icon matTooltip="Activated Payment" color="primary">
													paid
												</mat-icon>
												&nbsp;&nbsp;
												<mat-button-toggle-group formControlName="isPayByParent" color="primary"
													(change)="payByParentUpdate($event,dependent.i)">
													<mat-button-toggle [value]="true">
														<ng-container *ngIf="step2FG.value.dependents?.[dependent.i]?.status==='live'">
															You are paying
														</ng-container>
														<ng-container *ngIf="step2FG.value.dependents?.[dependent.i]?.status==='draft'">
															You will pay
														</ng-container>
													</mat-button-toggle>
													<mat-button-toggle [value]="false">
														<ng-container *ngIf="step2FG.value.dependents?.[dependent.i]?.status==='live'">
															They are paying
														</ng-container>
														<ng-container *ngIf="step2FG.value.dependents?.[dependent.i]?.status==='draft'">
															They will pay
														</ng-container>
													</mat-button-toggle>
												</mat-button-toggle-group>
											</ng-container>
											<ng-template #notActivatedRef>
												<mat-icon matTooltip="Not Activated Payment" color="accent">
													paid
												</mat-icon>
											</ng-template>
										</span>
									</td>
									<td>{{step2FG.value.dependents?.[dependent.i]?.forestCount}}
										<ng-container *ngIf="step2FG.value.dependents?.[dependent.i]?.hasDependent">
											({{step2FG.value.dependents?.[dependent.i]?.dependentForestCount||0}})
										</ng-container>
									</td>
									<td>
										<mat-slide-toggle color="primary" formControlName="hasIncludedDependents"
											(change)="onIncludeDependentChange($event,dependent.i)">
										</mat-slide-toggle>
									</td>
									<td>
										<mat-checkbox formControlName="selected" color="primary" (change)="onSelect($event,dependent.i)">
										</mat-checkbox>
									</td>
								</ng-container>
							</tr>
						</table>
						<mat-hint *ngIf="filterDependentsFC?.value && filteredDependents?.length===0">
							There are no results available for this keyword "{{filterDependentsFC?.value}}". Please
							refine your search with some other words.
						</mat-hint>
						<mat-hint *ngIf="!filterDependentsFC?.value && filteredDependents?.length===0">
							Click <button mat-stroked-button color="primary" (click)="openEligibleDependentsDialog()">
								<mat-icon>add</mat-icon> Add More
							</button> to Add Dependents.
						</mat-hint>
						<br><br>
						<mat-hint *ngIf="!filterDependentsFC?.value && filteredDependents?.length===0">
							Click <button mat-stroked-button color="primary" (click)="openCreateDependents()">
								<mat-icon>add</mat-icon> Create Group(s) </button> to Create Dependents.
						</mat-hint>
					</div>
					<div fxFlex="300px" fxLayout="column">
						<div class="forcasted-cost-container">
							<!-- <mat-card-header> -->
							<app-loader [loaderId]="loaderForecastId"></app-loader>
							<div class="forcasted-cost-header">
								Forecasted Cost</div>
							<!-- </mat-card-header> -->
							<div>
								<table class="forcasted-cost-table">
									<title>Forecasted Cost</title>
									<tr>
										<td class="col-1">
											No. of Unique Users
										</td>
										<td class="col-2">
											&nbsp;
										</td>
										<td class="col-3">
											Cost per User
										</td>
									</tr>
									<tr>
										<td class="col-1" style="text-align: center;">
											<b>{{totalNoOfUsers}}</b>
										</td>
										<td class="col-2">
											X
										</td>
										<td class="col-3" style="text-align: center;">
											<span class="ca-currency">A$</span><b>{{costPerUser | customNumber:2}} </b>
											<mat-icon mat-icon-button class="ca-along-text-icon" [matMenuTriggerFor]="priceTiers"
												color="primary">
												info</mat-icon>
											<mat-menu class="no-max-width" #priceTiers="matMenu" yPosition="above">
												<table class="tiers">
													<tr>
														<th>No. of Users</th>
														<th>Cost (A$/User/Month)</th>
													</tr>
													<tr *ngFor="let tier of tiers;let i=index" [class.active]="costPerUser===tier.unitAmount">
														<td class="">
															{{(tiers[i-1]?.upTo)?((tiers[i-1]?.upTo||0)+1):1 }}
															{{tier.upTo!==Infinity?'to':'or' }}
															{{tier.upTo!==Infinity?tier.upTo:'more' }}</td>
														<td class="center"><span class="ca-currency">A$</span>{{tier.unitAmount |
															customNumber:2}}</td>
													</tr>
												</table>
											</mat-menu>
										</td>
									</tr>
									<tr class="total">
										<td colspan="3" class="col-spanned">
											Total Monthly Cost
										</td>
									</tr>
									<tr>
										<td colspan="3" class="col-spanned padding-small">
											= <span class="ca-currency">A$</span><b>{{totalMonthlyCost |
												customNumber:2}}</b>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</form>

		</cdk-step>
		<cdk-step label="Payment Information">
			<div fxLayout="row" fxLayoutAlign="end middle">
				<button *ngIf="!subscription?.paymentInfoId; else elseTemplate" [disabled]="step3FG.invalid" color="primary"
					mat-raised-button (click)=completeSetup()>Complete
					Setup</button>
				<ng-template #elseTemplate>
					<button [disabled]="step3FG?.value?.paymentInfoId === subscription?.paymentInfoId || step3FG.invalid"
						color="primary" mat-raised-button (click)=completeSetup()>Update Payment</button>
				</ng-template>

			</div>
			<mat-card class="payment-info-container">
				<div class="step-payment-infos">
					<!-- <div *ngIf="isPaymentMethodNoAccess" class="step-payment-info" (click)="setExisting()">
						<span>
							<mat-icon [color]="subscription?.paymentInfoId===step3FG.value.paymentInfoId?'primary':'accent'">
								{{subscription?.paymentInfoId===step3FG.value.paymentInfoId?'check_circle':'check_circle_outline'}}
							</mat-icon>
						</span>
						<span>
							**** - **** **** **** <br>
							<small>
								<mat-hint>
									This Payment Method is Added from Different account.
								</mat-hint>
							</small>
						</span>
					</div> -->
					<!-- <div *ngFor="let paymentInfo of paymentInfos" class="step-payment-info"
						(click)="selectPaymentInfo(paymentInfo)">
						<span>
							<mat-icon [color]="paymentInfo.id===step3FG.value.paymentInfoId?'primary':'accent'">
								{{paymentInfo.id===step3FG.value.paymentInfoId?'check_circle':'check_circle_outline'}}
							</mat-icon>
						</span>
						<ng-container [ngSwitch]="paymentInfo.type">
							<ng-container *ngSwitchCase="'card'">
								<span>{{paymentInfo.details.brand }} - **** **** **** {{paymentInfo.details.last4}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'au_becs_debit'">
								<span>{{paymentInfo.details.bsb_number }} - *****{{paymentInfo.details.last4}}</span>
							</ng-container>
						</ng-container>
					</div> -->

					<div *ngIf="paymentInfo" class="step-payment-info" (click)="selectPaymentInfo(paymentInfo)">
						<span>
							<mat-icon [color]="paymentInfo.id===step3FG.value.paymentInfoId?'primary':'accent'">
								{{paymentInfo.id===step3FG.value.paymentInfoId?'check_circle':'check_circle_outline'}}
							</mat-icon>
						</span>
						<ng-container [ngSwitch]="paymentInfo.type">
							<ng-container *ngSwitchCase="'card'">
								<span>{{paymentInfo.details.brand }} - **** **** ****
									{{paymentInfo.details.last4}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'au_becs_debit'">
								<span>{{paymentInfo.details.bsb_number }} - *****{{paymentInfo.details.last4}}</span>
							</ng-container>
						</ng-container>
						<span class="step-payment-info-action">
							<button [disabled]="subscription?.paymentInfoId===null" color="warn" mat-stroked-button
								(click)=removePaymentInfo()>Remove Payment</button>
						</span>
					</div>
					<div class="step-payment-info-new" (click)="selectPaymentInfo(null,'NEW_CARD')">
						<div class="step-payment-info">
							<span>
								<mat-icon [color]="step3FG.get('card')?.enabled?'primary':'accent'">
									{{step3FG.get('card')?.enabled?'check_circle':'check_circle_outline'}}
								</mat-icon>
							</span>
							<span>
								Add New Card
							</span>
						</div>
						<div class="step-card-container" [class.hidden]="step3FG.get('card')?.disabled">
							<ngx-stripe-card (change)="onCardChange($event)" class="stripe" [options]="cardOptions"
								[elementsOptions]="elementsOptions">
							</ngx-stripe-card>
							<mat-error *ngIf="cardError">
								{{cardError}}
							</mat-error>
							<div style="margin-top: 20px;">
								<app-country fxFlex="1 1 100%" fxFlexFill [fg]="step3FG" fcName="country">
								</app-country>
							</div>
						</div>
					</div>
					<div class="step-payment-info-new" (click)="selectPaymentInfo(null,'NEW_ACCOUNT')">
						<div class="step-payment-info">
							<span>
								<mat-icon [color]="step3FG.get('auBankAccount')?.enabled?'primary':'accent'">
									{{step3FG.get('auBankAccount')?.enabled?'check_circle':'check_circle_outline'}}
								</mat-icon>
							</span>
							<span>
								Add New Direct Debit payments for Australia only
							</span>
						</div>
						<div class="step-bank-container" [class.hidden]="step3FG.get('auBankAccount')?.disabled">
							<ngx-stripe-au-bank-account (change)="onBankChange($event)" class="stripe" [options]="cardOptions"
								[elementsOptions]="elementsOptions">
							</ngx-stripe-au-bank-account>
							<br>
							<mat-error *ngIf="bankError">
								{{bankError}}
							</mat-error>
							<div style="text-align: justify;">
								By providing your bank account details, you agree to this Direct Debit Request
								and the <a href="https://stripe.com/au-becs-dd-service-agreement/legal" target="_blank">Direct Debit
									Request
									service
									agreement</a>,
								and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343
								Direct Debit User ID number 507156 (“Stripe”) to debit your account
								through the Bulk Electronic Clearing System (BECS) on behalf of
								ShareTree Inc (the "Merchant") for any amounts separately
								communicated to you by the Merchant. You certify that you are either
								an account holder or an authorised signatory on the account listed above.
							</div>
							<br>
						</div>
					</div>
					<small *ngIf="step3FG?.errors?.onlyOne">
						*Please either Choose a Payment Method or Add new Card/Account
					</small>
				</div>
				<mat-card-actions align="end">
					<img width="150px"
						src="https://cdn.brandfolder.io/KGT2DTA4/as/g7snp7xf3gp4skn433793s/Powered_by_Stripe.svg?position=2"
						alt="Powered by Stripe">
				</mat-card-actions>
			</mat-card>
		</cdk-step>
		<ng-template cdkStepperIcon="edit" let-index="index">{{index + 1}}</ng-template>
		<ng-template cdkStepperIcon="done" let-index="index">{{index + 1}}</ng-template>
	</app-step-parent-group-stepper>
</section>
