import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { isValidNumber } from "libphonenumber-js";

export function phoneNumberValidator(): ValidatorFn {
	return function (control: AbstractControl): ValidationErrors {
		if (
			control.value === "" ||
			control.value === null ||
			control.value === undefined
		)
			return {};
		return isValidNumber(control.value)
			? {}
			: {
					phone: true
			  };
	};
}
