import { titleGraphicBGGreen, titleStyle } from "../../../utils/chart-options";
import { EChartsOption } from "echarts";
import {
	backgroundColor,
	gridStyle,
	marker,
	textStyle,
	toFixedWithZeroTruncation,
	toTooltipHTML
} from "../../../utils/chart-options";

export const CHART_DEFAULTS: EChartsOption | any = {
	...backgroundColor,
	graphic: titleGraphicBGGreen,
	title: {
		...titleStyle
	},
	textStyle: textStyle,
	tooltip: {
		trigger: "axis",
		showContent: true,
		alwaysShowContent: false,
		axisPointer: {
			type: "shadow"
		},
		formatter: (params: any): string | HTMLElement | HTMLElement[] => {
			if (params[0].name == "") return "";
			return toTooltipHTML({
				name: params[0].name,
				values: params.map((param: any) => ({
					marker: marker(param),
					name: param.seriesName,
					value: `${toFixedWithZeroTruncation(param.value, 2)}%`
				}))
			});
		}
	},
	legend: {
		right: 15,
		top: 10,
		width: "70%",
		itemWidth: 10,
		itemHeight: 10,
		itemGap: 5,
		selectedMode: false,
		textStyle: {
			fontSize: 10,
			color: "#000"
		}
	},
	grid: gridStyle,
	xAxis: {
		type: "value",
		boundaryGap: [0, 0],
		minInterval: 1,
		axisTick: {
			show: false
		},
		min: 0,
		axisLine: {
			show: false
		},
		axisLabel: {
			show: true,
			color: "black",
			formatter: "{value}%",
			fontWeight: "bold"
		},
		splitLine: {
			show: true,
			interval: 1,
			lineStyle: {
				color: "#c7c7c4",
				width: 1.5
			}
		}
	},
	yAxis: {
		type: "category",
		axisTick: {
			show: false
		},
		z: 3,
		axisLabel: {
			show: true,
			color: "black",
			width: 100,
			overflow: "break",
			fontWeight: "bold"
		},
		axisLine: {
			show: true,
			lineStyle: {
				color: "#c7c7c4",
				width: 2.5
			}
		},
		splitLine: {
			show: true,
			interval: 6,
			lineStyle: {
				color: "#c7c7c4",
				width: 1.5
			}
		},
		inverse: true,
		data: [
			"Purposefulness & Clarity",
			"Hope & Encouragement",
			"Truthfulness & Honesty",
			"Love & Enthusiasm",
			"",
			"Respect Cooperation",
			"Mindfulness & Composure"
		]
	},
	series: [
		{
			type: "bar",
			name: "Personal",
			barWidth: 10,
			label: {
				show: false
			}
		},
		{
			type: "bar",
			name: "Avg",
			barWidth: 12,
			label: {
				show: true,
				position: "insideLeft",
				color: "#000",
				padding: 1,
				backgroundColor: "rgba(255,255,255,0.7)",
				offset: [-1, 0],
				// formatter: "{c}%"
				formatter: (params: any): string => {
					return (+params.value).toFixed(2) + "%";
				}
			}
		},

		{
			type: "bar",
			name: "In a Team",
			barWidth: 10,
			label: {
				show: false
			}
		}
	]
};
