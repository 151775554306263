import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { AutoUnsubscribeOnDestroy } from "src/app/_shared/decorators/autoUnsubscribeOnDestroy";
import { OrganisationService } from "src/app/_shared/services/organisation.service";
import { ST_PLATFORM } from "src/app/_shared/types/common";
import { ORG } from "src/app/_shared/types/organisation";
import { AuthService } from "../../../services/auth.service";

@Component({
	selector: "app-side-nav",
	templateUrl: "./side-nav.component.html",
	styleUrls: ["./side-nav.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class SideNavComponent implements OnInit, OnChanges {
	@ViewChild(MatAccordion) accordion?: MatAccordion;
	@Input() show!: boolean;
	@Input() routes: any = [];
	stPlatform?: ST_PLATFORM;
	subscriptions = new Subscription();
	user!: any;
	org?: ORG | null;
	get isSTCAPlatform() {
		return this.stPlatform === "STCA";
	}
	constructor(
		private organisationService: OrganisationService,
		private authService: AuthService,
		private _snack: MatSnackBar
	) {
		this.getUser();
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe((org: ORG | null) => {
				this.org = org;
			})
		);
	}
	getUser() {
		this.subscriptions.add(
			this.authService.getUserInfo().subscribe(user => {
				this.user = user;
			})
		);
	}
	ngOnInit() {
		this.stPlatform = this.authService.getSTPlatform();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (!changes["show"].currentValue) {
			this.accordion?.closeAll();
		}
	}
	canShow(roleLevel: number) {
		return (
			this.user.role === "ADMIN" ||
			roleLevel >= (this.org?.membership?.roleLevel ?? 2)
		);
	}
	showError() {
		this._snack.open("Please Select a Organisation to see Admin Level Views");
	}
}
