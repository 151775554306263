import {
	AfterViewInit,
	Component,
	EventEmitter,
	Inject,
	ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { interval, merge, of as observableOf } from "rxjs";
import {
	catchError,
	debounce,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
	tap
} from "rxjs/operators";
import { STEP_GROUP } from "../../types/step-group";
import { SelectionModel } from "../../utils/selection-model-material";
import { DialogsService } from "./../../services/dialogs.service";
import { StepGroupService } from "./../../services/step-group.service";
function compareWith(o1: any, o2: any) {
	return o1.id === o2.id;
}
@Component({
	selector: "app-step-dependent-group-list",
	templateUrl: "./step-dependent-group-list.component.html",
	styleUrls: ["./step-dependent-group-list.component.scss"]
})
export class StepDependentGroupListComponent implements AfterViewInit {
	constructor(
		public dialogRef: MatDialogRef<StepDependentGroupListComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private stepGroupService: StepGroupService,
		private UiLoaderService: NgxUiLoaderService,
		private dialogsService: DialogsService
	) {
		this.selection = new SelectionModel<STEP_GROUP>(
			true,
			this.dialogData.selected,
			true,
			compareWith
		);
	}
	displayedColumns: string[] = [
		"select",
		"groupRef",
		"groupName",
		"directDebit",
		"noOfUsers"
	];
	filterFormControl = new FormControl();
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	refreshEvent = new EventEmitter();
	loaderId = "dependents-selection";
	filterLoaderId = "dependents-selection-filter";
	isLoadingResults = false;
	resultsLength = 0;
	dataSource = new MatTableDataSource<STEP_GROUP>();
	pageSize = 10;
	selection;
	ngAfterViewInit() {
		this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		merge(
			this.sort?.sortChange,
			this.paginator?.page,
			this.filterFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.filterLoaderId);
					return interval(2000);
				}),
				tap(() => {
					this.UiLoaderService.stopBackgroundLoader(this.filterLoaderId);
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
				}),
				distinctUntilChanged()
			),
			this.refreshEvent
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					setTimeout(() => {
						this.isLoadingResults = true;
					}, 0);
					return this.stepGroupService!.getEligibleDependents({
						parentId: this.dialogData.parentId,
						offset: this.paginator.pageIndex * this.paginator.pageSize,
						limit: this.paginator.pageSize,
						sortingKey: this.sort.active,
						sortBy: this.sort.direction,
						searchText: this.filterFormControl.value || undefined
					}).pipe(catchError(() => observableOf(null)));
				}),
				map((data: any) => {
					setTimeout(() => {
						this.isLoadingResults = false;
					}, 0);
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
					if (data === null) {
						return [];
					}
					this.resultsLength = data.total;
					return data.groups;
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	toggleAllRows() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: STEP_GROUP): string {
		if (!row) {
			return `${this.isAllSelected() ? "deselect" : "select"} all`;
		}
		return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
			row.id
		}`;
	}
	addSelected() {
		this.dialogRef.close({ selected: this.selection.selected });
	}
	async toggle(row: STEP_GROUP) {
		this.selection.toggle(row);
		if (row.groupSubscription?.paymentInfoId) {
			if (this.selection.isSelected(row)) {
				let isPayByParent =
					await this.dialogsService.confirmIfAlreadyHasSubscriptionDialog(row);
				row.isPayByParent = isPayByParent;
				if (!isPayByParent) {
					let canAddAsDependent =
						await this.dialogsService.confirmIfAddAsDependentIfPayingAlreadyDialog(
							row
						);
					if (!canAddAsDependent) {
						this.selection.deselect(row);
					}
				}
			}
		}
	}
}
