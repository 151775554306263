import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

declare type SceenSizes =
	| "Small"
	| "Medium"
	| "Large"
	| "XLarge"
	| "Unknown"
	| "XSmall";

@Injectable({
	providedIn: "root"
})
export class ScreenService implements OnDestroy {
	destroyed = new Subject<void>();
	currentScreenSize: SceenSizes = "Unknown";
	displayNameMap = new Map([
		[Breakpoints.XSmall, "XSmall"],
		[Breakpoints.Small, "Small"],
		[Breakpoints.Medium, "Medium"],
		[Breakpoints.Large, "Large"],
		[Breakpoints.XLarge, "XLarge"]
	]);
	constructor(breakpointObserver: BreakpointObserver) {
		breakpointObserver
			.observe([
				Breakpoints.XSmall,
				Breakpoints.Small,
				Breakpoints.Medium,
				Breakpoints.Large,
				Breakpoints.XLarge
			])
			.pipe(takeUntil(this.destroyed))
			.subscribe(result => {
				for (const query of Object.keys(result.breakpoints)) {
					if (result.breakpoints[query]) {
						this.currentScreenSize = (this.displayNameMap.get(query) ??
							"Unknown") as SceenSizes;
					}
				}
			});
	}
	isSizeMatches(sizes: SceenSizes[]) {
		return sizes.includes(this.currentScreenSize);
	}
	ngOnDestroy(): void {
		this.destroyed.next();
		this.destroyed.complete();
	}
}
