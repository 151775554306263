import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { removeUndefined } from "../utils/object";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
	providedIn: "root"
})
export class StepUserService {
	baseURL_STEP = environment.stepBaseUrl;
	baseURL_CA = environment.baseUrl;
	constructor(private http: HttpClient) {}
	search(qParams: any) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...qParams,
					sortBy: qParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get<any>(`${this.baseURL_STEP}/user/autocomplete_search`, {
			params
		});
	}
}
