import { EChartsOption, SeriesOption } from "echarts";
import { WHOLE_CHARACTERS_LABELS } from "../character";
import { RichTextBold } from "../../../utils/chart-options";
import {
	backgroundColor,
	marker,
	singleComparisonColor,
	textStyle,
	titleStyle,
	toFixedWithZeroTruncation,
	toTooltipHTML
} from "../../../utils/chart-options";
const radius = "65%";
const center = ["50%", "50%"];
export const CHART_DEFAULTS: EChartsOption | any = {
	...backgroundColor,
	title: [
		{
			id: "main",
			...titleStyle
		},
		{
			id: "moral",
			text: "{bold|M} - Mostly Morale Quality",
			bottom: 15,
			right: 10,
			textStyle: {
				fontSize: 10,
				fontWeight: "normal",
				fontStyle: "normal",
				rich: {
					...RichTextBold
				}
			}
		},
		{
			id: "performance",
			text: "{bold|P} - Mostly Performance Quality",
			bottom: 0,
			right: 10,
			textStyle: {
				fontSize: 10,
				fontWeight: "normal",
				fontStyle: "normal",
				rich: {
					...RichTextBold
				}
			}
		}
	],
	textStyle: {
		...textStyle,
		fontWeight: "bold"
	},
	tooltip: {
		trigger: "axis",
		showContent: true,
		position: function (
			pos: Array<number>,
			params: any,
			dom: HTMLElement,
			rect: any,
			size: any
		) {
			// tooltip will be fixed on the right if mouse hovering on the left,
			// and on the left if hovering on the right.
			var obj: any = { top: size.viewSize[1] / 2 - size.contentSize[1] / 2 };
			obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
			return obj;
		},
		alwaysShowContent: false,
		formatter: (params: any): string | HTMLElement | HTMLElement[] => {
			return toTooltipHTML({
				marker: marker(params),
				name: params.name,
				values: WHOLE_CHARACTERS_LABELS.map((name, i) => ({
					name,
					value: `${toFixedWithZeroTruncation(params.value[i], 2)}%`
				}))
			});
		}
	},
	legend: {
		left: "center",
		top: 5,
		itemWidth: 10,
		itemHeight: 10,
		orient: "horizontal",
		itemGap: 15,
		selectedMode: false,
		textStyle: {
			width: 125,
			fontSize: 10,
			overflow: "break"
		},
		itemStyle: {
			borderType: "solid",
			borderWidth: 1.5
		}
	},
	radar: [
		{
			z: 0,
			radius,
			center,
			indicator: WHOLE_CHARACTERS_LABELS.map(name => ({
				name: "",
				min: 0,
				max: 100
			})),
			axisName: {
				show: false
			},
			axisLine: {
				show: false
			},
			splitArea: {
				show: true,
				areaStyle: {
					color: "white"
				}
			}
		},
		{
			z: 1,
			radius,
			center,
			indicator: [{ text: "", max: 100 }],
			axisLabel: {
				show: true,
				formatter: "{value}%",
				color: "#333333",
				opacity: 1,
				align: "right",
				fontWeight: "normal",
				padding: 190
			},
			axisTick: {
				show: true,
				length: 190,
				lineStyle: {
					color: "#d8d8d6"
				}
			},
			axisLine: {
				show: false
			},
			splitNumber: 5
		},
		{
			indicator: WHOLE_CHARACTERS_LABELS.map(name => ({
				name,
				min: 0,
				max: 100,
				color: "#000"
			})),
			radius,
			center,
			splitNumber: 5,
			axisNameGap: 5,
			z: 2,
			axisLine: {
				show: false
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: "#b8b6b7"
				}
			},
			splitArea: {
				show: false
			}
		}
	],
	series: [
		{
			type: "radar",
			tooltip: {
				trigger: "item"
			},
			color: "#90cf5b",
			areaStyle: {
				opacity: 1,
				shadowColor: "rgba(0, 0, 0, .7)",
				shadowBlur: 10,
				shadowOffsetX: 3,
				shadowOffsetY: 3
			},
			z: 4,
			symbol: "none",
			lineStyle: {
				color: "#90cf5b"
			},

			data: [
				{
					name: "Charater Strengths profile"
				}
			]
		}
	]
};
export const SERIE_DEFAULTS_ASPIRATION: SeriesOption | any = {
	type: "radar",
	tooltip: {
		trigger: "item"
	},
	color: "transparent",
	itemStyle: {
		show: false,
		borderColor: singleComparisonColor,
		color: "white"
	},
	z: 5,
	symbol: "none",
	lineStyle: {
		color: singleComparisonColor,
		width: 1.5,
		shadowColor: "rgba(0, 0, 0, .6)",
		shadowBlur: 5,
		shadowOffsetX: 2,
		shadowOffsetY: 2
	},
	data: [
		{
			name: "Aspiration"
		}
	]
};
