import { Pipe, PipeTransform } from "@angular/core";

declare type INDEX = "CHARAMETRICS" | "CHARACTER360";
const CA_PREFACES_COUNT = 3; // used to offset page number for cover page, how to read page and index page. so 3
@Pipe({
	name: "pageNumber"
})
export class PageNumberPipe implements PipeTransform {
	transform(
		id: string,
		pageNoOffsetForComparison: number = 0,
		index: INDEX = "CHARAMETRICS",
		previewOffset: number = 0
	): number {
		return getPageNumber(id, pageNoOffsetForComparison, index, previewOffset);
	}
}
export function getPageNumber(
	id: string,
	pageNoOffsetForComparison: number = 0,
	index: INDEX = "CHARAMETRICS",
	previewOffset: number = 0
) {
	let pageNoMap: any;
	switch (index) {
		case "CHARAMETRICS":
			pageNoMap = {
				YCASW: 1 + CA_PREFACES_COUNT,
				YCAST: 2 + CA_PREFACES_COUNT,
				"YCSG3@1": 3 + CA_PREFACES_COUNT,
				CAT: 3 + CA_PREFACES_COUNT + previewOffset,
				YCSP: 4 + CA_PREFACES_COUNT + previewOffset,
				YCSR: 5 + CA_PREFACES_COUNT + previewOffset,
				YCSP_C:
					5 + CA_PREFACES_COUNT + pageNoOffsetForComparison + previewOffset,
				YCSG3:
					6 + CA_PREFACES_COUNT + pageNoOffsetForComparison + previewOffset,
				YCGP: 12 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"YCGP@2": 13 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"CS&E": 14 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"Q/P": 14 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				CHALLENGES: 15 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				THRIVING: 15 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"A&D_12": 16 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				PERSONAS_4: 16 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				CSG: 17 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"ACS&C": 18 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"ACS&C@2": 19 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				HtIR: 20 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"HtIR@2": 21 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"HtIR@3": 22 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"HtIR@4": 23 + CA_PREFACES_COUNT + pageNoOffsetForComparison,
				"HtIR@5": 24 + CA_PREFACES_COUNT + pageNoOffsetForComparison
			};
			break;
		case "CHARACTER360":
			pageNoMap = {
				questions: 3,
				results: 4,
				overall: 4,
				avgByGroup: 4,
				manager: 5,
				team: 5,
				peer: 5,
				client: 6,
				friend: 6,
				supplier: 6,
				greatest3CharacterStrengths: 7
				// greatestGrowthFocus: 7
			};
			break;
	}
	return pageNoMap[id];
}
