<h1 mat-dialog-title>
	Move Report - {{dialogData?.report?.name}}
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<form fxLayout="column" [formGroup]="fg" (ngSubmit)="submit()">
	<mat-dialog-content>
		<div>
			This will Move the report to the
			selected Assessment.
		</div>
		<div fxLayout="row" fxLayoutAlign="center stretch" style="margin-top: 20px;">
			<mat-card fxFlex="1 1 auto">
				<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" fxFlex="1 1 100%">
					<app-avatar [fxInline]="true" [avatar]="dialogData?.report?.partnerSurvey?.org">
					</app-avatar>
					<div>
						- {{dialogData?.report?.partnerSurvey?.name}}
					</div>
				</div>
			</mat-card>
			<span fxFlex="0 0 20px" style="margin: auto; margin-right: 12px;">
				<mat-icon>arrow_right_alt</mat-icon>
			</span>
			<mat-card fxFlex="1 1 auto">
				<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
					<app-organisation-select [showAll]="false" [global]="false"
						[selectedOrgId]="org?.id||dialogData.report?.partnerSurvey?.org.id" (selectedOrgChange)=" updateOrg($event)"
						(preselectedEvent)=" updateOrg($event)" [showAvailableCredits]="false" [showOrgSelectedLabel]="false"
						[showAvailableCreditsLabel]="false">
					</app-organisation-select>
					<app-partner-survey-select style="width: 100%;" [selectedOrg]="org" [partnerSurveyCtrl]="partnerSurveyCtrl"
						[isGlobalSelectedOrg]="false" [showAll]="false">
					</app-partner-survey-select>
				</div>
			</mat-card>
		</div>

	</mat-dialog-content>
	<mat-dialog-actions>
		<span class="ca-spacer"></span>
		<button [disabled]="fg.invalid || fg.value.partnerSurvey?.id === dialogData?.report.partnerSurvey.id"
			mat-raised-button color="primary">Move</button>
	</mat-dialog-actions>
</form>