export const ORG_TYPE_DISCOUNT_RATES = {
	"NFP&C": 0.2,
	CCP: 0.2,
	RST: 0.1
};

export const ORG_TYPES = [
	{ name: "NFP & Charity", value: "NFP&C" },
	{ name: "Certified Culture Practitioner", value: "CCP" },
	{ name: "Registered Social Trader", value: "RST" }
];
export const ORG_TYPE_DISPLAY_MAP = {
	"NFP&C": "NFP & Charity",
	CCP: "Certified Culture Practitioner",
	RST: "Registered Social Trader"
};

export const COST_PER_ASSESSMENT = 125;
