<h1 mat-dialog-title>
	Forgot Password
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<form fxLayout="column" [formGroup]="userForm" (ngSubmit)="onSubmit()">
	<mat-dialog-content>
		<div fxLayout="column" fxLayoutGap="10px">
			<div class="step-form-group">
				<input type="text" formControlName="email" class="form-control"
					[ngClass]="{ 'is-invalid': submitted && fg?.get('email')?.errors }" placeholder="Email ID" />
				<div *ngIf="submitted && fg?.get('email')?.errors" class="invalid-feedback">
					<ng-container *ngIf="fg?.get('email')?.errors?.required">
						<mat-icon>error</mat-icon>Please type in Email Id.
					</ng-container>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<span class="ca-spacer"></span>
		<button type="button" mat-button [mat-dialog-close]>Cancel</button>
		<button [disabled]="userForm.invalid" mat-raised-button color="primary">
			<span style="font-size: 16px;font-weight: bold;">Submit</span>
		</button>
	</mat-dialog-actions>
</form>
