import { DialogsService } from "./../../../services/dialogs.service";
import { Component, Input } from "@angular/core";

@Component({
	selector: "app-preview-contents",
	templateUrl: "./preview-contents.component.html",
	styleUrls: ["./preview-contents.component.scss"]
})
export class PreviewContentsComponent {
	@Input() report: any;
	previews = [1, 2];
}
