import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { NgxStripeModule } from "ngx-stripe";
import {
	NgxUiLoaderHttpModule,
	NgxUiLoaderModule,
	NgxUiLoaderRouterModule,
	POSITION
} from "ngx-ui-loader";
import { environment } from "src/environments/environment";

import { HttpErrorInterceptor } from "./_shared/interceptors/http-error.interceptor";
import { JwtInterceptor } from "./_shared/interceptors/jwt.interceptor";
import { MaterialModule } from "./_shared/material/material.module";
import { SharedModule } from "./_shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { InviteComponent } from "./invite/invite.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { MsalModule } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

const isIE =
	window.navigator.userAgent.indexOf("MSIE ") > -1 ||
	window.navigator.userAgent.indexOf("Trident/") > -1;
@NgModule({
	declarations: [AppComponent, SignUpComponent, InviteComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		RecaptchaV3Module,
		SharedModule,
		MaterialModule,
		FlexLayoutModule,
		NgxUiLoaderModule.forRoot({
			fastFadeOut: false,
			bgsPosition: POSITION.bottomRight,
			minTime: 0,
			overlayColor: "rgba(40, 40, 40, 0.2)"
		}),
		NgxStripeModule.forRoot(environment.stripePublicKey),
		NgxUiLoaderRouterModule.forRoot({
			excludeRegexp: ["/report/.*"]
		}), // import NgxUiLoaderRouterModule. By default, it will show foreground loader.
		// If you need to show background spinner, do as follow:
		// NgxUiLoaderRouterModule.forRoot({ showForeground: false })
		NgxUiLoaderHttpModule.forRoot({
			showForeground: true,
			excludeRegexp: [
				"/report/*",
				"/character360/*",
				"/transaction/receipt",
				"/partner/survey/*",
				"/group",
				"/role",
				"/character-traits.json",
				"/organisation",
				"/payment/get_unique_users_count"
			]
		}),
		MsalModule.forRoot(
			new PublicClientApplication({
				auth: {
					clientId: environment.microsoft.clientId, // Application (client) ID from the app registration
					authority: `https://login.microsoftonline.com/common`, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
					redirectUri: document.location.origin
				},
				cache: {
					cacheLocation: "localStorage",
					storeAuthStateInCookie: isIE // Set to true for Internet Explorer 11
				}
			}),
			{ interactionType: InteractionType.Popup },
			{
				interactionType: InteractionType.Popup,
				protectedResourceMap: new Map<string, string[]>()
			}
		),
		BrowserAnimationsModule // import NgxUiLoaderHttpModule. By default, it will show background loader.
	],

	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha.siteKey
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
