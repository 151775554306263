import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReportService } from "../../services/report.service";

@Component({
	selector: "app-unpack-sessions-log",
	templateUrl: "./unpack-sessions-log.component.html",
	styleUrls: ["./unpack-sessions-log.component.scss"]
})
export class UnpackSessionsLogComponent implements OnInit {
	data: any[] = [];
	isLoadingResults = false;
	displayedColumns = ["index", "allocatedTo", "allocatedBy", "createdAt"];
	constructor(
		private reportService: ReportService,
		@Inject(MAT_DIALOG_DATA) public dialogData: any
	) {}
	ngOnInit(): void {
		this.getUnpackSessions();
	}
	getUnpackSessions() {
		this.isLoadingResults = true;
		this.reportService
			.getAllocatedUnpackSession(this.dialogData.report.id, { logs: true })
			.subscribe((res: any) => {
				this.isLoadingResults = false;
				this.data = res.unpackSessions;
			});
	}
}
