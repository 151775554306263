import { Component, ViewChild, ViewChildren, QueryList } from "@angular/core";
import { MatTable } from "@angular/material/table";
@Component({
	selector: "app-reports",
	template: "",
	styles: [""]
})
export class MatTableWrapperComponent {
	@ViewChild(MatTable) table?: MatTable<any>;
	@ViewChildren(MatTable) tables?: QueryList<MatTable<any>>;

	constructor() {}
	reRender() {
		setTimeout(() => {
			if (this.tables?.length) {
				this.tables.forEach(table => {
					table.updateStickyColumnStyles();
				});
			}
		}, 0);
	}
}
