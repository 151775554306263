import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	Output,
	ViewChild
} from "@angular/core";
import { GsiButtonConfiguration, accounts } from "google-one-tap";
import { defaults } from "lodash";
import { environment } from "src/environments/environment";
declare var google: any;

@Component({
	selector: "app-google-signin",
	templateUrl: "./google-signin.component.html",
	styleUrls: ["./google-signin.component.scss"]
})
export class GoogleSigninComponent implements AfterViewInit {
	@Input() options?: GsiButtonConfiguration;
	@Output() signedIn: EventEmitter<any> = new EventEmitter<any>();
	@Input() text = "Continue with Google";
	_options!: GsiButtonConfiguration;
	googleButton?: ElementRef;
	@ViewChild("googleWrapper") googleWrapper!: ElementRef;
	constructor(private ngZone: NgZone) {}
	ngAfterViewInit() {
		this._options = defaults(this.options, {
			size: "large",
			width: document.getElementById("gbtn")?.offsetWidth,
			logo_alignment: "center",
			text: "continue_with",
			type: "standard"
		} as GsiButtonConfiguration);
		const gAccounts: accounts = google.accounts;
		gAccounts.id.initialize({
			client_id: environment.google.clientId,
			ux_mode: "popup", // Choose the UX mode (popup, redirect, etc.)
			cancel_on_tap_outside: false,
			callback: ({ credential }) => {
				this.ngZone.run(() => {
					this.signedIn.emit(credential);
				});
			}
		});
		gAccounts?.id.renderButton(this.googleWrapper.nativeElement, this._options);
		this.googleButton =
			this.googleWrapper.nativeElement.querySelector("div[role=button]");
	}

	click() {
		(this.googleButton as any).click();
	}
}
