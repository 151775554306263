import { Component } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { filter, map, mergeMap } from "rxjs/operators";
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent {
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title
	) {
		// Grab the title from index.html
		const appTitle = this.titleService.getTitle();

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map(route => {
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter(route => route.outlet === "primary"),
				mergeMap(route => route.data)
			)
			.subscribe(event => {
				if (event?.["title"]) {
					return this.titleService.setTitle(event?.["title"]);
				}
				return this.titleService.setTitle(appTitle);
			});
	}
}
