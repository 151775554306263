import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { PaymentIntent } from "@stripe/stripe-js";

@Injectable({
	providedIn: "root"
})
export class PaymentService {
	baseURL = environment.baseUrl;
	constructor(public http: HttpClient) {}
	createPaymentIntent(payload: any): Observable<PaymentIntent> {
		return this.http.post<PaymentIntent>(
			`${environment.baseUrl}/payment/intent`,
			payload
		);
	}
	createReportPaymentIntent(payload: any): Observable<PaymentIntent> {
		return this.http.post<PaymentIntent>(
			`${environment.baseUrl}/payment/report`,
			payload
		);
	}
}
