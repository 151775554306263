import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { ErrorPageComponent } from "./_shared/components/error-page/error-page.component";
import { LoginComponent } from "./_shared/components/login/login.component";
import { AuthGuard } from "./_shared/guards/auth.guard";
import { InviteComponent } from "./invite/invite.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { UserSignupComponent } from "./_shared/components/user-signup/user-signup.component";
export const routingConfiguration: ExtraOptions = {
	paramsInheritanceStrategy: "always"
};
const routes: Routes = [
	{
		path: "character",
		data: {
			title: "Character"
		},
		loadChildren: () =>
			import("./survey/survey.module").then(m => m.SurveyModule)
	},
	{
		path: "report",
		loadChildren: () =>
			import("./report/report.module").then(m => m.ReportModule)
	},
	{
		path: "home",
		data: {
			title: "Partner Portal"
		},
		loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
		canLoad: [AuthGuard]
	},
	{
		path: "character-360",
		data: {
			title: "ShareTree Portal"
		},
		loadChildren: () =>
			import("./character360/character360.module").then(
				m => m.Character360Module
			)
	},

	{ path: "sign-up/:inviteToken", component: SignUpComponent },
	{
		path: "invite/:inviteToken",
		component: InviteComponent
	},
	{
		path: "login",
		component: LoginComponent,
		data: { type: "STCA", title: "Partner Portal" }
	},
	{
		path: "user-login",
		component: LoginComponent,
		data: { type: "STC360", title: "ShareTree Portal" }
	},
	{
		path: "user-sign-up",
		component: UserSignupComponent,
		data: { type: "STC360", title: "User Signup | ShareTree Portal" }
	},
	{ path: "c", redirectTo: "character-360" },
	{ path: "", redirectTo: "character", pathMatch: "full" },
	{ path: "**", component: ErrorPageComponent, data: { errorCode: 404 } }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, routingConfiguration)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
