import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { AutoUnsubscribeOnDestroy } from "src/app/_shared/decorators/autoUnsubscribeOnDestroy";
import { phoneNumberValidator } from "src/app/_shared/form/validator/phone-number";
import { AuthService } from "src/app/_shared/services/auth.service";
import { Character360Service } from "src/app/_shared/services/character360.service";
import { USER } from "src/app/_shared/types/user";
import { MobileNumberChangeEvent } from "../../types/common";

@Component({
	selector: "app-character360-edit",
	templateUrl: "./character360-edit.component.html",
	styleUrls: ["./character360-edit.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class Character360EditComponent {
	infoFG!: FormGroup;
	subscriptions = new Subscription();
	isMobileNumberVerified = false;
	user!: USER | null;
	loaderId = "character360EditLoader";
	constructor(
		public dialogRef: MatDialogRef<Character360EditComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private authService: AuthService,
		private fb: FormBuilder,
		private UiLoaderService: NgxUiLoaderService,
		private character360Service: Character360Service
	) {
		this.infoFG = this.fb.group({
			mobileNo: [, [phoneNumberValidator(), Validators.required]]
		});
		this.infoFG.patchValue(dialogData.character360);
		this.subscriptions.add(
			this.authService.getUserInfo().subscribe(user => {
				this.user = user;
				if (!this.infoFG.value.mobileNo) {
					this.infoFG.patchValue({
						mobileNo: this.user?.mobileNo
					});
				}
			})
		);
	}
	mobileNoVerifiedEvent(event: MobileNumberChangeEvent) {
		this.isMobileNumberVerified = event.isMobileNoVerified;
		if (event.isMobileNoVerified && event.canGoNext) {
			this.submit();
		}
	}
	submit() {
		if (this.infoFG?.valid) {
			this.UiLoaderService.startLoader(this.loaderId);
			let payload = this.infoFG.value;
			payload.isMobileNoVerified = this.isMobileNumberVerified;
			this.character360Service
				.patch(this.dialogData.character360.id, payload)
				.subscribe({
					next: (res: any) => {
						if (res.statusCode === 200) {
							this.UiLoaderService.stopLoader(this.loaderId);
							this.dialogRef.close({
								character360: res.character360,
								refresh: true
							});
						}
					},
					error: () => {
						this.UiLoaderService.stopLoader(this.loaderId);
					}
				});
		}
	}
}
