import { findLastIndex } from "lodash";
import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";
/**
 * Validates if only one of the provided fields has a value.
 * Fields can only be of type number or string.
 * @param fields name of the form fields that should be checked
 */
export function onlyOneRequired(...fields: string[]) {
	return (fg: AbstractControl): ValidationErrors | null => {
		let res = fields.map((fieldName: string) => {
			const field = fg.value[fieldName];
			if (typeof field === "number") return field && field >= 0 ? true : false;
			if (typeof field === "string")
				return field && field.length > 0 ? true : false;
			return false;
		});
		return onlyOneTrue(res)
			? null
			: ({
					onlyOne: true
			  } as ValidationErrors);
	};
}

function onlyOneTrue(list: Array<boolean>) {
	let foundOne = list.findIndex(l => l) > -1;
	return foundOne ? list.findIndex(l => l) === list.lastIndexOf(true) : false;
}
