<h1 *ngIf="dialogRef" mat-dialog-title>
	Buy Credits
</h1>
<button *ngIf="dialogRef" mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<section [class.padding]="dialogRef" [class.margin]="!dialogRef">
	<ng-container [ngSwitch]="status">
		<ng-container *ngSwitchCase="'PAYMENT'">
			<mat-card>
				<mat-card-content>
					<div fxLayout="row" fxLayout.xs="column" fxLayout.md="column" fxLayoutAlign="start center">
						<div fxFlex="1 1 auto" fxFlexFill>
							<mat-stepper class="centered-stepper-header" linear labelPosition="bottom" #stepper>
								<mat-step [stepControl]="detailsFormGroup" [editable]="'false'">
									<ng-template matStepLabel>Fill out Details</ng-template>
									<form [formGroup]="detailsFormGroup">
										<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20px">
											<div *ngIf="readonlyOrgId" fxFlex="1 1 calc(50%-20px)" fxFlexFill>
												<div class="padding-small border-bottom">
													<app-avatar [avatar]="org">
													</app-avatar>
												</div>
												<small>
													<mat-hint *ngIf="org?.wallet">
														Current balance : {{org?.wallet?.balance}}
													</mat-hint>
												</small>
											</div>
											<mat-form-field *ngIf="!readonlyOrgId" fxFlex="1 1 calc(50%-20px)" fxFlexFill>
												<mat-label>Organisation</mat-label>
												<mat-select #orgSelect formControlName="orgId" name="orgId" msInfiniteScroll
													(infiniteScroll)="onScroll()" [complete]="offset === (orgs$|async)?.length" cdkFocusInitial>
													<mat-select-trigger>
														<app-avatar *ngIf="org" size="16px" [fxInline]="true" [avatar]="org">
														</app-avatar>
													</mat-select-trigger>
													<mat-option *ngFor="let org of orgs$|async" [value]="org.id"
														[disabled]="user.role==='USER' && org?.membership?.role!=='ADMIN'">
														<app-avatar [avatar]="org" [showOrgRole]="true">
														</app-avatar>
													</mat-option>
													<div class="ca-list-loader">
														<app-loader [loaderId]="orgSelectListLoaderId" [bgsSize]="28" [fgsSize]="28"
															bgsPosition="center-center"></app-loader>
													</div>
												</mat-select>
												<mat-error
													*ngIf="detailsFormGroup.get('orgId')?.touched && detailsFormGroup.get('orgId')?.errors?.required">
													Please type in Organisation.
												</mat-error>
												<mat-hint *ngIf="org?.wallet">
													Current balance : {{org?.wallet?.balance}}
												</mat-hint>
											</mat-form-field>
											<!-- TODO: update to use organisation-select component as below  -->
											<!-- <app-organisation-select fxFlex="60%" fxFlexFill [showAll]="false" [global]="false"
												[forceReadOnly]="readonlyOrgId && !!org" [selectedOrgId]="surveyFormGroup.get('orgId')?.value"
												(selectedOrgChange)=" surveyFormGroup.get('orgId')?.setValue($event.id)"
												[error]="surveyFormGroup.get('orgId')?.touched && surveyFormGroup.get('orgId')?.errors?.required">
											</app-organisation-select> -->

											<mat-form-field fxFlex="1 1 calc(50%-20px)" fxFlexFill>

												<input matInput placeholder="Credits" type="number" formControlName="creditPoints" required />
												<mat-error
													*ngIf="detailsFormGroup.get('creditPoints')?.touched && detailsFormGroup.get('creditPoints')?.errors?.required">
													Please type in Credits required.
												</mat-error>
											</mat-form-field>
										</div>
										<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start" style="margin-top: 12px;">
											<app-country fxFlex="1 1 100%" fxFlexFill [fg]="detailsFormGroup">
											</app-country>
										</div>
										<div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
											<button mat-flat-button (click)="reset()">Cancel</button>
											<button mat-raised-button color="primary" (click)="createPaymentIntent()">Continue</button>

										</div>
									</form>
								</mat-step>
								<mat-step [editable]="'false'">
									<ng-template matStepLabel>Payment</ng-template>
									<ng-container *ngIf="elementsOptions.clientSecret && org">
										<h2 style="text-align:center ;">
											Paying <b>A${{breakdowns?.totalAmount|number: '1.0-2'}}</b>
											<mat-icon color="primary" class="ca-along-text-icon" aria-label="info">
												info_outline</mat-icon>
											for adding
											<b>{{detailsFormGroup?.value?.creditPoints}}</b> Credit Points to
											<app-avatar [fxInline]="true" [avatar]="org">
											</app-avatar>
										</h2>
										<ngx-stripe-payment [elementsOptions]="elementsOptions">
										</ngx-stripe-payment>

									</ng-container>
									<div class="stepper-actions" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
										<button mat-flat-button (click)="reset()">Cancel</button>
										<button mat-raised-button color="primary" type="button" (click)="pay()">PAY</button>
									</div>
								</mat-step>
							</mat-stepper>
						</div>
						<div *ngIf="!breakdowns" fxFlex="1 1 300px">
							<mat-card>
								<div>
									For your Group, the Cost per Assessment is <br>
									<div style="margin-top:32px;">
										{{costPerAssignmentAfterDiscount}} Credits.
									</div>
								</div>
								<hr>
								<small>
									<mat-hint *ngIf="this.org?.orgType" class="important-hint">
										*After {{this.org.orgType|orgTypeDiscount}} Discount for
										{{this.org.orgType|orgType}}
									</mat-hint>
								</small>
							</mat-card>
						</div>
						<div *ngIf="breakdowns" fxFlex="1 1 300px">
							<mat-card>
								<mat-icon style="position: absolute;top:3px;left:3px" color="primary">info_outline
								</mat-icon>
								<mat-card-content>
									<div class="ca-amount-breakdowns">
										<table>
											<tr>
												<th>Amount</th>
												<td>&nbsp;:&nbsp;</td>
												<td><span class="ca-currency">A$</span>{{detailsFormGroup?.value?.creditPoints
													|number: '1.2-2'}}</td>
											</tr>
											<tr *ngIf="detailsFormGroup?.value.countryCode==='AU'">
												<th>GST (10% for Australia)</th>
												<td>&nbsp;:&nbsp;</td>
												<td><span class="ca-currency">A$</span>{{breakdowns?.GST |number:
													'1.2-2'}}</td>
											</tr>
											<tr>
												<th>Total</th>
												<td>&nbsp;:&nbsp;</td>
												<th><span class="ca-currency">A$</span>{{breakdowns?.totalAmount
													|number: '1.2-2'}}</th>
											</tr>
										</table>
									</div>
								</mat-card-content>

							</mat-card>

						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</ng-container>

		<ng-container *ngSwitchCase="'SUCCESS'">
			<mat-card>
				<mat-card-content>

					<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
						<mat-icon fxFlexOffset="75px" class="lg-icon success-icon">
							check_circle
						</mat-icon>
						<h1>
							Success!
						</h1>
						<h2>
							{{detailsFormGroup?.value?.creditPoints}} Credit Points added to
							<app-avatar *ngIf="org" [fxInline]="true" [avatar]="org">
							</app-avatar>
						</h2>

					</div>
				</mat-card-content>
				<mat-card-actions align="end">
					<button mat-raised-button color="accent" (click)="reset()">Buy More</button>
				</mat-card-actions>
			</mat-card>
		</ng-container>
		<ng-container *ngSwitchCase="'FAILED'">
			<mat-card>
				<mat-card-content>
					<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
						<mat-icon fxFlexOffset="75px" class="lg-icon failed-icon">
							error
						</mat-icon>
						<h1>
							Failed!
						</h1>
						<h2>
							Failed to add {{detailsFormGroup?.value?.creditPoints}} credit points to <app-avatar *ngIf="org"
								[fxInline]="true" [avatar]="org">
							</app-avatar>
						</h2>
						<h3>{{error.message}}</h3>
						<h5>
							Please try again.
						</h5>
					</div>
				</mat-card-content>
				<mat-card-actions align="end">
					<button mat-raised-button color="accent" (click)="reset()">Try Again</button>
				</mat-card-actions>
			</mat-card>
		</ng-container>
	</ng-container>
</section>
