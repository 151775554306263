import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from "@angular/core";
import {
	CHARACTER360_EXTERNAL_STEPS,
	CHARACTER360_INTERNAL_STEPS,
	CHARACTER360_STEPS
} from "src/app/_shared/const/character360";
import { Character360, Step } from "src/app/_shared/types/character360";
import { C360_CATEGORY_KEY_MAP } from "../../const/character360";
import { MatDialog } from "@angular/material/dialog";
import { Character360RespondentsComponent } from "../character360-respondents/character360-respondents.component";
import { ScreenService } from "../../services/screen.service";

@Component({
	selector: "app-character360-overview",
	templateUrl: "./character360-overview.component.html",
	styleUrls: ["./character360-overview.component.scss"]
})
export class Character360OverviewComponent {
	@Input() character360!: Character360;
	@Output() character360Change = new EventEmitter<Character360>();
	@Output() edit = new EventEmitter<void>();
	@Output() openC360InNewTab = new EventEmitter<void>();
	@Input() currentStep?: Step;
	@Input() showStatus = false;
	@Input() highlightOverall = false;
	@Input() showActions = false;
	@Input() showRespondents = false;
	@Input() expiresAt!: Date;
	@ViewChild(`pageRef`) pageRef!: ElementRef;
	today = new Date();
	C360_CATEGORY_KEY_MAP = C360_CATEGORY_KEY_MAP;
	readonly steps: Step[] = CHARACTER360_STEPS;
	readonly internalSteps: Step[] = CHARACTER360_INTERNAL_STEPS;
	readonly externalSteps: Step[] = CHARACTER360_EXTERNAL_STEPS;
	get isExpired() {
		return this.expiresAt && this.today > this.expiresAt;
	}
	get isSmallScreen() {
		return this.screenService.isSizeMatches(["XSmall", "Small", "Medium"]);
	}
	get isMobileScreen() {
		return this.screenService.isSizeMatches(["XSmall"]);
	}
	constructor(
		private _dialog: MatDialog,
		public screenService: ScreenService,
		private elRef: ElementRef
	) {}
	focusHandle(stepId: string) {
		if (!this.isSmallScreen) {
			this.pageRef?.nativeElement.scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
			return;
		}
		const dynamicElement = this.elRef.nativeElement.querySelector(`.${stepId}`);
		if (dynamicElement) {
			dynamicElement.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}
	openRespondentsListDialog(step: Step, respondents: any) {
		const dialogRef = this._dialog.open(Character360RespondentsComponent, {
			panelClass: "ca-dialog",
			data: {
				step,
				respondents
			}
		});
	}
}
