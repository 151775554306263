import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { conditionalValidators } from "../../form/validator/conditional-validators";
import { FileService } from "../../services/file.service";
import { StepGroupService } from "../../services/step-group.service";
import { isNotNullAndUndefined } from "../../utils/object";
import { ImageCropperComponent } from "../image-cropper/image-cropper.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
type View = "details" | "invite_members";
@Component({
	selector: "app-ep-group-dialog",
	templateUrl: "./ep-group-dialog.component.html",
	styleUrls: ["./ep-group-dialog.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class EPGroupDialogComponent {
	fg: FormGroup;
	group?: any;
	customSupportNosOldvalue?: any;
	subscriptions = new Subscription();
	view: View = "details";
	checkInOftenList = [
		{ value: "DAILY", label: "Daily" },
		{ value: "DAILY_WEEKDAY", label: "Daily (Weekdays Only)" },
		{ value: "WEEKLY", label: "Weekly" },
		{ value: "NONE", label: "None" }
	];
	weekDays = [
		{ value: 0, label: "Sunday" },
		{ value: 1, label: "Monday" },
		{ value: 2, label: "Tuesday" },
		{ value: 3, label: "Wednesday" },
		{ value: 4, label: "Thursday" },
		{ value: 5, label: "Friday" },
		{ value: 6, label: "Saturday" }
	];
	profileImage: { src?: string; file?: File } = {};
	time: Date = new Date();
	get new() {
		return !!!this.dialogData?.group?.id;
	}
	goto(view: View) {
		this.view = view;
	}
	get customSupportNos() {
		return this.fg.get("customSupportNos") as FormArray;
	}
	get copiedFromParent() {
		return this.fg.get("copiedFromParent") as FormControl;
	}
	get customSupportNosList() {
		return this.copiedFromParent.value
			? this.group?.customSupportNos
			: this.customSupportNos?.controls;
	}
	get copyToDependents() {
		return this.fg.get("copyToDependents") as FormControl;
	}
	userSearchFC = new FormControl("");
	constructor(
		public dialogRef: MatDialogRef<EPGroupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		public fb: FormBuilder,
		public _dialog: MatDialog,
		private stepGroupService: StepGroupService,
		private fileService: FileService,
		private ngxUiLoaderService: NgxUiLoaderService,
		private _snack: MatSnackBar
	) {
		if (dialogData.group) {
			this.getGroup(dialogData.group.id);
		}
		this.fg = this.fb.group(
			{
				groupName: [, Validators.required],
				imageKey: [],
				checkInOften: [, Validators.required],
				checkInHour: [, Validators.required],
				checkInMinute: [, Validators.required],
				checkIn: [],
				remindDay: [],
				forests: [[]],
				copiedFromParent: [true],
				copyToDependents: [true],
				customSupportNos: this.fb.array([])
			},
			{
				validators: conditionalValidators("remindDay", [
					{
						isSelected: form => {
							return form?.get("checkInOften")?.value === "WEEKLY";
						},
						validators: Validators.required
					}
				])
			}
		);
		this.subscriptions.add(
			this.fg.get("checkIn")?.valueChanges.subscribe((checkin: Date) => {
				this.fg.patchValue(
					{
						checkInHour: checkin.getHours(),
						checkInMinute: checkin.getMinutes()
					},
					{ emitEvent: false }
				);
			})
		);

		this.subscriptions.add(
			this.fg.get("customSupportNos")?.valueChanges.subscribe(data => {
				this.toggleCopyToDependentsStatus();
			})
		);
		this.subscriptions.add(
			this.fg.get("copiedFromParent")?.valueChanges.subscribe(data => {
				this.toggleCopyToDependentsStatus();
			})
		);
	}
	toggleCopyToDependentsStatus() {
		let filledValues = this.customSupportNos.value.every((a: any) => {
			return a.contactName && (a.contactNo || a.supportLink);
		});
		if (
			!this.copiedFromParent.value &&
			this.customSupportNos.value.length > 0 &&
			filledValues
		) {
			this.copyToDependents.enable();
		} else {
			this.copyToDependents.disable();
		}
	}
	initSupport() {
		let g = this.fb.group(
			{
				contactName: [],
				contactNo: [],
				supportLink: []
			},
			{
				validators: [
					conditionalValidators("contactName", [
						{
							isSelected: (form: AbstractControl) => {
								return (
									(!!form.value.contactNo || !!form.value.supportLink) &&
									!this.copiedFromParent.value
								);
							},
							validators: Validators.required // or [Validators.required, ...]
						}
					]),
					conditionalValidators("contactNo", [
						{
							isSelected: (form: AbstractControl) => {
								return (
									!!form.value.contactName &&
									!!!form.value.supportLink &&
									!this.copiedFromParent.value
								);
							},
							validators: Validators.required // or [Validators.required, ...]
						}
					]),
					conditionalValidators("supportLink", [
						{
							isSelected: (form: AbstractControl) => {
								return (
									!!form.value.contactName &&
									!!!form.value.contactNo &&
									!this.copiedFromParent.value
								);
							},
							validators: Validators.required // or [Validators.required, ...]
						}
					])
				]
			}
		);
		return g;
	}
	addSupport() {
		this.customSupportNos.push(this.initSupport());
	}
	removeSupport(i: number) {
		this.customSupportNos.removeAt(i);
	}
	stopInheritFromParentGroup() {
		if (this.copiedFromParent.value) {
			this.copiedFromParent.setValue(false);
			// this._snack.open(
			// 	"We have turned off Sync from Parent group to enable updation."
			// );
		}
	}
	getGroup(id: number) {
		this.ngxUiLoaderService.start();
		this.stepGroupService.get(id).subscribe(res => {
			this.group = res.group;

			// // *!Important: remove this is commit
			// this.group.copiedFromParent = true;

			this.setGroupData();
			this.ngxUiLoaderService.stop();
		});
	}
	setGroupData() {
		if (this.group.customSupportNos) {
			this.customSupportNos.clear();
			this.group.customSupportNos.forEach(() =>
				this.customSupportNos.push(this.initSupport())
			);
		}
		this.fg.patchValue({
			...this.group
		});
		if (
			!isNotNullAndUndefined(this.group?.copiedFromParent) ||
			this.group?.copiedFromParent
		) {
			this.copyToDependents.disable();
		}
		let date = new Date();
		date.setHours(this.group.checkInHour ?? 9);
		date.setMinutes(this.group.checkInMinute ?? 0);
		date.setMilliseconds(0);
		this.fg.get("checkIn")?.setValue(date);
	}
	close() {
		this.dialogRef.close({ created: false });
	}
	onProfileUpload($event: Event) {
		const target = $event.target as HTMLInputElement;
		const files = target.files as FileList;
		if (files?.length) {
			const dialogRef = this._dialog.open(ImageCropperComponent, {
				data: {
					event: $event,
					type: "PROFILE_IMAGE"
				}
			});
			dialogRef.afterClosed().subscribe(async (result: any) => {
				if (result) {
					this.profileImage.src = result.base64;
					this.profileImage.file = result.blob;
				} else {
					($event.target as HTMLInputElement).value = "";
				}
			});
		}
	}
	removeProfilePic() {
		delete this.profileImage.src;
		delete this.profileImage.file;
	}

	async uploadProfileImage() {
		if (this.profileImage.file) {
			try {
				this.fg
					.get("imageKey")
					?.setValue(
						await this.fileService.uploadFile(
							this.profileImage.file,
							"GROUP_PROFILE",
							"STEP"
						)
					);
				delete this.profileImage.file;
				return;
			} catch (error) {
				return;
			}
		}
	}

	async save() {
		if (this.fg.valid) {
			this.ngxUiLoaderService.start();
			await this.uploadProfileImage();
			let payload = this.fg.value;
			if (payload.customSupportNos.length === 0) {
				payload.customSupportNos = null;
			}
			let res = <any>(
				await this.stepGroupService
					.updateGroup(this.dialogData.orgId, this.dialogData.group.id, payload)
					.toPromise()
			);
			this.ngxUiLoaderService.stop();
			this._snack.open("Updates made successfully");
			this.dialogRef.close({
				refresh: true,
				group: res.group
			});

			return;
		}
	}
	async create() {
		if (this.fg.valid) {
			await this.uploadProfileImage();
			let payload = this.fg.value;
			if (payload.customSupportNos.length === 0) {
				payload.customSupportNos = null;
			}
			payload.forests = payload.forests.map((f: any) => {
				f.role = "MEMBER";
				return f;
			});
			let res = <any>(
				await this.stepGroupService
					.createGroup(this.dialogData.orgId, payload)
					.toPromise()
			);
			this._snack.open("Group Created Successfully");
			this.dialogData.group = res.group;
		}
		return;
	}
	async complete() {
		this.ngxUiLoaderService.start();
		await this.create();
		this.dialogRef.close({
			created: true,
			group: this.dialogData.group
		});
		this.ngxUiLoaderService.stop();
	}
	async createDependents() {
		this.ngxUiLoaderService.start();
		await this.create();
		this.dialogRef.close({
			created: true,
			openCreateDependents: true,
			group: this.dialogData.group
		});
		this.ngxUiLoaderService.stop();
	}
	remove(i: number) {
		let value = this.fg.get("forests")?.value;
		value.splice(i, 1);
		this.fg.get("forests")?.setValue(value);
	}
}
