import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	ViewChild
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { random } from "lodash";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { LoggerService } from "../../services/logger.service";
import { OrganisationService } from "../../services/organisation.service";
import { BalanceType, TRANSACTION_VIEW_TYPE, Type } from "../../types/credits";
import { ORG } from "../../types/organisation";
import { TransactionService } from "./../../services/transaction.service";
@Component({
	selector: "app-transactions",
	templateUrl: "./transactions.component.html",
	styleUrls: ["./transactions.component.scss"]
})
export class TransactionsComponent implements AfterViewInit {
	@Input() viewType?: TRANSACTION_VIEW_TYPE;
	title?: string;
	subscriptions = new Subscription();
	loaderId = "transactions-loader-" + random(1, 10000);
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	refreshEvent = new EventEmitter();
	data: any[] = [];
	resultsLength = 0;
	pageSize = 10;
	isLoadingResults = false;
	displayedColumns: string[] = [];
	selectedOrg?: ORG | null;
	balanceType?: BalanceType;
	type?: Type;
	org?: ORG | null;
	constructor(
		public Logger: LoggerService,
		private transactionService: TransactionService,
		private UiLoaderService: NgxUiLoaderService,
		private organisationService: OrganisationService
	) {
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe((org: ORG | null) => {
				this.selectedOrg = org;
				this.refreshEvent.next();
				this.setDisplayColumns();
			})
		);
	}
	ngAfterViewInit() {
		this.initTable();
	}
	async initTable() {
		switch (this.viewType) {
			case "CREDIT_USAGE":
				await this.initCreditUsage();
				break;
			case "PURCHASE_HISTORY":
				await this.initPurchaseHistory();
				break;
		}
		this.setDisplayColumns();
		await this.initMatTable();
	}
	initMatTable() {
		this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		merge(this.sort?.sortChange, this.paginator?.page, this.refreshEvent)
			.pipe(
				startWith({}),
				switchMap(() => {
					setTimeout(() => {
						this.isLoadingResults = true;
					}, 0);
					return this.transactionService!.get({
						offset: this.paginator.pageIndex * this.paginator.pageSize,
						limit: this.paginator.pageSize,
						sortingKey: this.sort.active,
						sortBy: this.sort.direction,
						orgId: this.selectedOrg?.id || this.org?.id,
						balanceType: this.balanceType,
						type: this.type
					}).pipe(catchError(() => observableOf(null)));
				}),
				map((data: any) => {
					setTimeout(() => {
						this.isLoadingResults = false;
					}, 0);
					if (this.UiLoaderService.getLoader(this.loaderId)) {
						this.UiLoaderService.stopBackgroundLoader(this.loaderId);
					}
					if (data === null) {
						return [];
					}
					this.resultsLength = data.total;
					return data.list;
				})
			)
			.subscribe(data => (this.data = data));
	}
	async initCreditUsage() {
		this.title = "Credit Usage";
		this.type = "DEBIT";
	}
	async initPurchaseHistory() {
		this.title = "Purchase History";
		this.type = "CREDIT";
		// this.balanceType = "PAID";
	}
	setDisplayColumns() {
		this.displayedColumns = [];
		if (!this.selectedOrg) {
			this.displayedColumns = ["organisation"];
		}
		switch (this.viewType) {
			case "CREDIT_USAGE":
				this.displayedColumns = [
					...this.displayedColumns,
					"allocatedDate", //Allocated Date
					"allocatedBy", //Allocated By
					"creditsType", //Credits Type
					"creditsAllocated", //Credits Allocated
					"surveyName" //Survey Name
				];
				break;
			case "PURCHASE_HISTORY":
				this.displayedColumns = [
					...this.displayedColumns,
					"purchaseDate", //Purchase Date
					"purchasedBy", //Purchased By
					"creditsType", //Credits Type
					"purchasedCredits", //Purchased Credits
					"amountPaid", //Purchased Amount Paid
					"downloadReceipt", //Download Receipt
					"summary"
				];
				break;
		}
	}

	updateOrg(event: ORG) {
		this.org = event;
		this.refreshEvent.next();
	}
	async download(transaction: any) {
		this.UiLoaderService.startLoader(transaction.id);
		try {
			await this.transactionService.download(transaction);
		} catch (error) {
			this.Logger.error(error);
		} finally {
			this.UiLoaderService.stopLoader(transaction.id);
		}
	}
}
