<div class="org-branding" *ngIf="showPartnerBranding">
	<div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
		<div fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 0" fxFlexFill
			style="text-align: center; font-size: .8rem;line-height: 20px;" *ngIf="org.typeOf==='PARTNER'">
			In partnership with
		</div>
		<div *ngIf="imageKey" fxLayout="row" fxLayoutAlign="center center" style="text-align: center;">
			<img *ngIf="logoImageKey;else avatarTRef" height="100%" width="auto" class="ca-logo-img"
				[src]="(logoImageKey)|filepath:'CA'" appImgFallback="GROUP_PROFILE">
			<ng-template #avatarTRef>
				<img *ngIf="avatarImageKey" [style.height.px]="avatarSize" [style.width.px]="avatarSize" class="ca-avatar-img"
					[src]="(avatarImageKey)|filepath:'CA'" appImgFallback="GROUP_PROFILE">
			</ng-template>
		</div>
		<div *ngIf="!imageKey" fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 0" fxFlexFill
			style="text-align: center;">
			{{org?.name}}
		</div>
	</div>
</div>
