import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";

@Component({
	selector: "app-anchor",
	templateUrl: "./anchor.component.html",
	styleUrls: ["./anchor.component.scss"]
})
export class AnchorComponent {
	@Input() anchorId?: string;
	@Input() margin?: string;
	@Input() type?: "POINT" | "LINK" = "POINT";
	constructor(@Inject(DOCUMENT) document: Document) {}
	scrollIntoView() {
		let element = document.getElementById(this.anchorId as string);
		element?.scrollIntoView({ behavior: "smooth", block: "start" });
	}
}
