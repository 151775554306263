import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { random } from "lodash";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { RouteService } from "../../services/route.service";
import { ORG } from "../../types/organisation";
import { BuyComponent } from "../buy/buy.component";
import { OrganisationService } from "./../../services/organisation.service";
import { FormControl } from "@angular/forms";
import { debounce, distinctUntilChanged, tap } from "rxjs/operators";
import { interval } from "rxjs";
import { MenuCloseReason } from "@angular/material/menu/menu";
import { AuthService } from "../../services/auth.service";

@Component({
	selector: "app-organisation-select",
	templateUrl: "./organisation-select.component.html",
	styleUrls: ["./organisation-select.component.scss"]
})
export class OrganisationSelectComponent implements OnInit, OnChanges {
	loaderId = "balanceLoaderId-" + random(1, 10000);
	orgSelectLoaderId = "orgSelectLoaderId-" + random(1, 10000);
	orgFilterLoaderId = "orgFilterLoaderId-" + random(1, 10000);
	orgSelectListLoaderId = "orgSelectListLoaderId-" + random(1, 10000);
	organisations: Array<ORG> = [];
	selectedOrg?: ORG | null = null;
	readOnly = false;
	isMember = true;
	offset = 0;
	defaultLimit = 10;
	limit = this.defaultLimit;
	total = Infinity;
	filterFC = new FormControl("");
	isLoading = false;
	@Input() forceReadOnly = false;
	@Input() global = false;
	@Input() showAll = true;
	@Input() showAvailableCredits = false;
	@Input() disableMemberOrg = false;
	@Input() showQuickBuy = false;
	@Input() selectedOrgId?: number;
	@Output() selectedOrgChange: EventEmitter<ORG> = new EventEmitter();
	@Output() preselectedEvent: EventEmitter<ORG> = new EventEmitter();
	@Input() showOrgSelectedLabel = false;
	@Input() showAvailableCreditsLabel = false;
	@Input() showOrgRole = false;
	@Input() error = false;
	@ViewChild(MatMenuTrigger) selectOrgTrigger?: MatMenuTrigger;
	isOpenBuyDialog = false;
	constructor(
		public UiLoaderService: NgxUiLoaderService,
		private organisationService: OrganisationService,
		private activatedRoute: ActivatedRoute,
		private _dialog: MatDialog,
		private _snackBar: MatSnackBar,
		private routeService: RouteService,
		public authService: AuthService
	) {}
	ngOnInit(): void {
		if (this.global) {
			this.activatedRoute.paramMap.subscribe(params => {
				let id = params.get("orgId") as unknown;
				if (id !== "all" && id && !this.selectedOrg) {
					this.selectedOrgId = id as number;
					this.getOrganisation(id as number);
				} else if (id === "all") {
					this.select(null, { force: true });
				}
			});
			this.organisationService.refreshEvent.subscribe({
				next: () => {
					this.organisationService.clearCache();
					this.getOrganistions({ refresh: true, afterRefresh: true });
				}
			});
		}
		this.getOrganistions();
		this.filterFC.valueChanges
			.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.orgFilterLoaderId);
					this.isLoading = true;
					return interval(2000);
				}),
				tap(() => {
					this.isLoading = false;
					this.UiLoaderService.stopBackgroundLoader(this.orgFilterLoaderId);
				}),
				distinctUntilChanged(),
				tap(() => {
					this.getOrganistions({ refresh: true, afterRefresh: false });
				})
			)
			.subscribe(() => {});
		this.UiLoaderService.startBackgroundLoader(this.orgSelectListLoaderId);
	}
	getOrganisation(
		id: number,
		{ onlySelf }: { onlySelf: boolean } = { onlySelf: false }
	) {
		this.UiLoaderService.startLoader(this.loaderId);
		this.UiLoaderService.startLoader(this.orgSelectLoaderId);
		this.organisationService.getOrg(id).subscribe(
			res => {
				this.select(res.org, { onlySelf });
				this.readOnly = !res.isMember;
				this.isMember = res.isMember;
				this.UiLoaderService.stopLoader(this.loaderId);
				this.UiLoaderService.stopLoader(this.orgSelectLoaderId);
			},
			error => {
				this.navigate();
			}
		);
	}
	async afterRefresh() {
		if (this.selectedOrg) {
			this.selectedOrg = this.organisations?.find(
				org => org.id === this.selectedOrg?.id
			);
			if (this.selectedOrg) {
				this.select(this.selectedOrg as ORG, { force: true, onlySelf: true });
			}
		}
		if (this.selectedOrgId) {
			this.selectOrgById(this.selectedOrgId);
		}
		if (this.organisations.length == 1 && this.global) {
			this.select(this.organisations[0]);
		}
	}
	async getOrganistions(
		{ refresh, afterRefresh }: { refresh?: boolean; afterRefresh?: boolean } = {
			refresh: false,
			afterRefresh: true
		}
	) {
		if (refresh) {
			this.total = Infinity;
			this.limit = this.defaultLimit;
			this.offset = 0;
		}
		if (this.organisations.length < this.total) {
			this.UiLoaderService.startLoader(this.loaderId);
			this.UiLoaderService.startBackgroundLoader(this.orgSelectListLoaderId);
			this.isLoading = true;
			(
				await this.organisationService.getOrgs({
					offset: this.offset,
					limit: this.limit,
					searchText: this.filterFC.value
				})
			).subscribe(async (res: any) => {
				if (refresh) {
					this.organisations = [];
				}
				this.organisations = this.organisations.concat(res.list);
				this.total = res.total;
				afterRefresh && (await this.afterRefresh());
				this.UiLoaderService.stopLoader(this.loaderId);
				this.UiLoaderService.stopBackgroundLoader(this.orgSelectListLoaderId);
				this.isLoading = false;
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ("selectedOrgId" in changes && changes.selectedOrgId.currentValue) {
			this.selectOrgById(changes.selectedOrgId.currentValue);
		}
	}
	select(
		org: ORG | null,
		{ onlySelf, force }: { onlySelf?: boolean; force?: boolean } = {
			onlySelf: false,
			force: false
		}
	) {
		if (org) {
			this.preselectedEvent.emit(org as ORG);
		}
		if (onlySelf) this.selectedOrg = org;
		if (!force && org === this.selectedOrg) return;
		if (this.global) {
			this.selectedOrg = org;
			this.organisationService.setSelectedSubject(org);
			this.navigate();
		} else if (!onlySelf) {
			this.selectedOrgChange.emit(org as ORG);
		}
		if (this.isOpenBuyDialog) {
			this.isOpenBuyDialog = false;
			this.openBuyDialog();
		}
	}
	openBuyDialog() {
		if (!this.selectedOrg) {
			this.openIfEditable();
			this.isOpenBuyDialog = true;
		} else {
			if (
				this.selectedOrg?.membership?.role === "ADMIN" ||
				this.authService.user?.role === "ADMIN"
			) {
				let dialogRef = this._dialog.open(BuyComponent, {
					minWidth: "60%",
					minHeight: "80%",
					maxHeight: "100%",
					panelClass: "ca-dialog",
					disableClose: true
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res?.refresh) {
						this.organisationService.clearCache();
						this.getOrganistions({ refresh: true, afterRefresh: true });
					}
				});
			} else {
				this._snackBar.open(
					`You Don't have Permission to do this. Please contact Admin of ${this.selectedOrg?.name}`
				);
			}
		}
	}
	selectOrgById(id: number) {
		this.selectedOrg = this.organisations?.find(org => org.id === id);
		if (this.selectedOrg) {
			this.preselectedEvent.emit(this.selectedOrg);
		}
		if (id && !this.selectedOrg) {
			this.getOrganisation(id, { onlySelf: true });
		}
	}
	navigate() {
		if (this.selectedOrg) {
			this.routeService.updateRouteParams({
				orgId: this.selectedOrg.id
			});
		} else {
			this.routeService.updateRouteParams({ orgId: "all" });
		}
	}
	openIfEditable() {
		if (this.forceReadOnly) {
			return;
		}
		if (!this.isMember) {
			this._snackBar.open(
				`You Don't have Permission to do this. Please contact Admin of ${this.selectedOrg?.name}`
			);
		} else {
			this.selectOrgTrigger?.openMenu();
		}
	}
	onScroll() {
		this.offset = Math.min(this.offset + this.limit, this.total);
		this.limit = Math.min(this.total - this.offset, this.limit);
		this.getOrganistions();
	}
	closedMenu($event: MenuCloseReason) {
		if ($event !== "click") {
			if (this.filterFC.value) {
				this.filterFC.setValue("");
			}
		}
	}
}
