import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { C360OfferProgressDialogComponent } from "../components/c360-offer-progress-dialog/c360-offer-progress-dialog.component";
import { ChangePasswordComponent } from "../components/change-password/change-password.component";
import {
	ConfirmDialogComponent,
	ConfirmDialogModel,
	ConfirmDialogModelOptions
} from "../components/confirm-dialog/confirm-dialog.component";
import { EPGroupDialogComponent } from "../components/ep-group-dialog/ep-group-dialog.component";
import { LinkAccountComponent } from "../components/link-account/link-account.component";
import { MigrateReportComponent } from "../components/migrate-report/migrate-report.component";
import { ST_PLATFORM } from "../types/common";
import { STEP_GROUP } from "../types/step-group";
import { addMonths, isToday } from "../utils/date";
import { PurchaseCharametricsReportComponent } from "./../components/purchase-charametrics-report/purchase-charametrics-report.component";
@Injectable({
	providedIn: "root"
})
export class DialogsService {
	constructor(private _dialog: MatDialog) {}
	confirmIfAlreadyHasSubscriptionDialog(group: STEP_GROUP): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `${group.groupName} is already paying for their own subscription. Would you like to pay for this Group?`;
			const dialogData = new ConfirmDialogModel("Confirm Action", {
				message,
				confirmText: "Yes",
				dismissText: "No"
			});
			const dialogRef = this._dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	confirmIfAddAsDependentIfPayingAlreadyDialog(
		group: STEP_GROUP
	): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `Would you like to add this ${group.groupName}(Group) as dependent?`;
			const dialogData = new ConfirmDialogModel("Confirm Action", {
				message,
				confirmText: "Yes, Add",
				dismissText: "Remove"
			});
			const dialogRef = this._dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	openPurchaseReport(report: any) {
		return new Promise<boolean>(resolve => {
			const dialogRef = this._dialog.open(PurchaseCharametricsReportComponent, {
				data: { report },
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	openChangePassword(
		id: string,
		{ forPlatform, force }: { forPlatform: ST_PLATFORM; force: boolean }
	) {
		let dialog = this._dialog.getDialogById(id);
		if (dialog) {
			return;
		}
		let dialogRef = this._dialog.open(ChangePasswordComponent, {
			id,
			minWidth: "300px",
			maxWidth: "600px",
			panelClass: "ca-dialog",
			data: {
				for: forPlatform,
				force
			},
			closeOnNavigation: !force,
			disableClose: force
		});
		return dialogRef;
	}
	confirmIfUnsavedChanges(
		message: string,
		options: ConfirmDialogModelOptions
	): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const dialogData = new ConfirmDialogModel("Are you sure?", {
				message,
				...options
			});
			const dialogRef = this._dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	openMigrateReport(report: any) {
		let dialogRef = this._dialog.open(MigrateReportComponent, {
			id: `move-report-${report.id}`,
			minWidth: "900px",
			// maxWidth: "700px",
			panelClass: "ca-dialog",
			data: {
				report
			}
		});
		return dialogRef;
	}
	confirmIfNavigateToLogin(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `There already is an account associated with this email, would you like to login with this account instead?`;
			const dialogData = new ConfirmDialogModel("Confirm Action", {
				message,
				confirmText: "Yes",
				dismissText: "No"
			});
			const dialogRef = this._dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	openLinkAccount(type: "LINK" | "MANAGE" = "LINK") {
		let dialogRef = this._dialog.open(LinkAccountComponent, {
			minWidth: "300px",
			maxWidth: "450px",
			width: "50%",
			panelClass: "ca-dialog",
			data: {
				type
			}
		});
		return dialogRef;
	}
	openGroup(orgId: number, group?: STEP_GROUP) {
		let dialogRef = this._dialog.open(EPGroupDialogComponent, {
			minWidth: "600px",
			maxWidth: "600px",
			panelClass: "ca-dialog",
			disableClose: true,
			data: { group, orgId }
		});
		return dialogRef;
	}
	/**
	 *
	 * @param report
	 * @returns void
	 * we would stop showing the offer dialog if the following conditions are met
	 *
	 * -------- if report is not individual report
	 *
	 * --------------- OR if report is not public
	 *
	 * --------------- OR if report is already paid for
	 *
	 * --------------- OR if report does not have character 360 data
	 *
	 * --------------- OR if report does not have character 360 data
	 *
	 * --------------- OR if report already received 6 responses
	 */
	async openC360OfferProgress(report: any) {
		if (
			//  we would stop showing the offer dialog if the following conditions are met
			report.type !== "INDIVIDUAL" || // if report is not individual report
			!report.partnerSurvey.isPublic || // if report is not public
			report.isPaid || // if report is already paid for
			!report?.character360 || // if report does not have character 360 data
			report?.character360?.totalResponsesCount === undefined || // if report does not have character 360 data
			report?.character360?.totalResponsesCount >= 6 // if report already received 6 responses
		) {
			return;
		}
		if (!report.expiresAt)
			report.expiresAt = addMonths(new Date(report.createdAt), 6);
		if (report.expiresAt < new Date()) {
			return;
		}
		let last360OfferPopupShownAtString = localStorage.getItem(
			"last360OfferPopupShownAt"
		);
		let last360OfferPopupShownAt = last360OfferPopupShownAtString
			? new Date(last360OfferPopupShownAtString)
			: null;
		if (!last360OfferPopupShownAt || !isToday(last360OfferPopupShownAt)) {
			const dialogRef = this._dialog.open(C360OfferProgressDialogComponent, {
				minWidth: "300px",
				maxWidth: "400px",
				disableClose: true,
				panelClass: "ca-dialog",
				data: { report }
			});
			localStorage.setItem(
				"last360OfferPopupShownAt",
				new Date().toISOString()
			);
			return dialogRef;
		}
		return null;
	}
}
