<section>
    <h2 class="ca-chart-title">Character Strengths Focus by Groups</h2>
    <div class="ca-charts-container" [class.ca-sample]="isSample">
        <!-- TODO: fix loading  -->
        <div class="ca-chart" echarts [loadingOpts]="loadingOpts" [loading]="isLoading" [initOpts]="initOpts"
            [options]="default_A" [merge]="chartOptionMoral">
        </div>
        <!-- TODO: fix loading  -->
        <div class="ca-chart" echarts [loading]="isLoading" [initOpts]="initOpts" [options]="default_A"
            [merge]="chartOptionPerformance"></div>
        <div class="ca-chart ca-chart-shorter" echarts [loadingOpts]="loadingOpts" [loading]="isLoading"
            [initOpts]="initOpts" [options]="default_B" [merge]="chartOptionMoralVsPerformance">
        </div>
    </div>
</section>