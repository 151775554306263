<section id="aboutCS&C" class="ca-character-descriptions">
	<app-anchor anchorId="ACS&C"></app-anchor>
	<div class="ca-page-break-before ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-page-title title="How to Read this Report">
			</app-page-title>
			<p class="subtitle"> This short video will walk you through how to read the report
			</p>
			<a target="_blank"
				href="https://www.bing.com/search?pglt=163&q=google&cvid=c09a7763024540c2bc1c97f745c5662e&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARAAGEAyBwgCEOkHGEAyBwgDEOkHGEAyBwgEEOkHGEAyBggFEC4YQDIGCAYQRRg8MgYIBxBFGDwyBggIEEUYPDIICAkQ6QcY_FXSAQgyNTk2ajBqMagCALACAA&FORM=ANNAB1&PC=U531">
				<img src="/assets/images/how-to-read-this-report.png" alt="Video of How to Read this Report By Steven Farrugia">
			</a>
			<app-page-title title="Quick Start Guide">
			</app-page-title>
			<p class="font-family-raleway text-color">
				<b>Step 1: Explore your Leading Strength and the Character Archetype Wheel</b> <br>
				Review 'Your Leading Strengths' and reflect on how you use these strengths in various situations. Consider how
				they
				manifest in your professional capacity, social interactions, and spiritual or private life.
				<br><br>
				<b>Step 2: Read the detailed report on each of your leading strengths (Free Report incl. 1
					Strength)</b> <br>
				Review how your strength show up in various life contexts and around different people. You should also get a
				sense of
				when you underutilised or over-utilised a strength and how to achieve more balance.
				<br><br>

				<b>Step 3: Ask Your Trusted Network for Feedback Using Character 360</b> <br>
				Get feedback on how your strengths are perceived by others by sending out a 360 link. This will collect a score
				and
				verbal feedback on each strength for you to review in a 360 report.
				<br><br>

				<b>Go Further with a Character Coach</b>
				You can purchase an unpack with a certified Character Coach when you purchase the full report. They will help
				you
				interpret the report and identify how to implement the learnings effectively.
			</p>
			<br><br>
		</div>
	</div>
</section>
