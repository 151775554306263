import { Injectable, isDevMode } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class LoggerService {
	isDebug: boolean;
	constructor() {
		this.isDebug = isDevMode();
	}
	/* eslint-disable no-console */
	public assert(condition?: boolean, ...data: any[]): void {
		this.isDebug && console.assert(condition, ...data);
	}
	public clear(): void {
		this.isDebug && console.clear();
	}
	public count(label?: string): void {
		this.isDebug && console.count(label);
	}
	public countReset(label?: string): void {
		this.isDebug && console.countReset(label);
	}
	public debug(...data: any[]): void {
		this.isDebug && console.debug(...data);
	}
	public dir(item?: any, options?: any): void {
		this.isDebug && console.dir(item, options);
	}
	public dirxml(...data: any[]): void {
		this.isDebug && console.dirxml(...data);
	}
	public error(...data: any[]): void {
		this.isDebug && console.error(...data);
	}
	public group(...data: any[]): void {
		this.isDebug && console.group(...data);
	}
	public groupCollapsed(...data: any[]): void {
		this.isDebug && console.groupCollapsed(...data);
	}
	public groupEnd(): void {
		this.isDebug && console.groupEnd();
	}
	public info(...data: any[]): void {
		this.isDebug && console.info(...data);
	}
	public log(...data: any[]): void {
		this.isDebug && console.log(...data);
	}
	public table(tabularData?: any, properties?: string[]): void {
		this.isDebug && console.table(tabularData, properties);
	}
	public time(label?: string): void {
		this.isDebug && console.time(label);
	}
	public timeEnd(label?: string): void {
		this.isDebug && console.timeEnd(label);
	}
	public timeLog(label?: string, ...data: any[]): void {
		this.isDebug && console.timeLog(label, ...data);
	}
	public timeStamp(label?: string): void {
		this.isDebug && console.timeStamp(label);
	}
	public trace(...data: any[]): void {
		this.isDebug && console.trace(...data);
	}
	public warn(...data: any[]): void {
		this.isDebug && console.warn(...data);
	}
	/* eslint-enable no-console */
}
