import * as moment from "moment";
import { USER_SIGNUP } from "../types/user";
import {
	entries,
	pickBy,
	reduce,
	toPairs,
	isArrayLikeObject,
	isNull,
	isUndefined
} from "lodash";

export function omitNilValues(obj: object): any {
	if (Array.isArray(obj)) {
		return obj
			.map(v => (v && typeof v === "object" ? omitNilValues(v) : v))
			.filter(val => checkEmpty(val));
	} else {
		return pickBy(
			entries(obj)
				.map(([k, v]) => [k, v && typeof v === "object" ? omitNilValues(v) : v])
				.filter(([k, val]) => checkEmpty(val))
				.reduce((a: any, [k, v]) => ((a[k] = v), a), {}),
			val => checkEmpty(val)
		);
	}
}

function checkEmpty(val: any) {
	return val !== null;
}

export function generateAnswers(filledValues: any, surveyId: number) {
	return reduce(
		filledValues,
		(acc, value) => {
			for (let [questionID, answer] of toPairs(value)) {
				if (!isArrayLikeObject(answer)) {
					answer = [answer];
				}
				acc.push({
					surveyId,
					questionId: +questionID,
					answer: answer as any[]
				});
			}
			return acc;
		},
		[] as { questionId: number; answer: any[]; surveyId: number }[]
	);
}
export function removeUndefined(object: any) {
	Object.keys(object).forEach(key =>
		object[key] === undefined ? delete object[key] : {}
	);
	return object;
}

export const isNotNull = (value: any) => !isNull(value) || value === "";
export const isNotNullAndUndefined = (value: any) =>
	!(isNull(value) || isUndefined(value));

export function wrapDetailsAsAnswers(details: USER_SIGNUP) {
	return {
		"1": [
			{
				id: 1,
				sNo: 1,
				value: details.firstName
			}
		],
		"2": [
			{
				id: 1,
				sNo: 1,
				value: details.organisation
			}
		],
		"4": [
			{
				id: 1,
				sNo: 1,
				value: details.mobileNo || ""
			}
		],
		"5": [
			{
				id: 1,
				sNo: 1,
				value: details.emailId
			}
		],
		"78": [
			{
				id: 1,
				sNo: 1,
				value: details.dob ? moment().diff(details.dob, "years") + "" : "" // string value required
			}
		]
	};
}
