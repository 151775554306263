<h1 mat-dialog-title>Feedback from {{ data.step.name}}</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>

<div mat-dialog-content>
	<div *ngFor="let item of data.respondents">
		{{item}}
	</div>


</div>
<div mat-dialog-actions align="end">
</div>
