<div class="ca-qr-code-container">
	<img class="rings-gfx" src="/assets/images/rings-gfx.png">
	<a class="overlay-action" target="_BLANK" [href]="url">
	</a>
	<img class="qr-code" [src]="qrCode" alt="QR Code">
	<span class="ca-qr-code-text font-family-raleway" *ngIf="text">
		{{text}}
	</span>
	<a target="_BLANK" [href]="url" color="primary" class="link-button mini-fab copy-link" matTooltipPosition="below"
		matRipple><mat-icon>link</mat-icon></a>
	<span *ngIf="showInfoText" class="info-text">
		Click to Access
	</span>
</div>
