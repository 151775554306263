import { Directive, Input } from "@angular/core";
import {
	NG_VALIDATORS,
	Validator,
	ValidationErrors,
	FormGroup
} from "@angular/forms";
import { matchPassword } from "../validator/match-password";

@Directive({
	selector: "[appMatchPassword]",
	providers: [
		{ provide: NG_VALIDATORS, useExisting: MatchPasswordDirective, multi: true }
	]
})
export class MatchPasswordDirective implements Validator {
	@Input("appMatchPassword") MatchPassword: string[] = [];

	constructor() {}

	validate(formGroup: FormGroup): ValidationErrors {
		return matchPassword(
			this.MatchPassword[0],
			this.MatchPassword[1]
		)(formGroup);
	}
}
