import { EChartsOption, SeriesOption } from "echarts";
import {
	backgroundColor,
	gridStyle,
	marker,
	orangeColor,
	purpleColor,
	singleComparisonColor,
	textStyle,
	titleStyle,
	toFixedWithZeroTruncation,
	toTooltipHTML
} from "../../../utils/chart-options";
export const CHART_DEFAULTS_BAR_A: EChartsOption | any = {
	...backgroundColor,
	title: {
		...titleStyle,
		textStyle: {
			...titleStyle.textStyle,
			color: "white"
		}
	},
	textStyle: textStyle,
	tooltip: {
		trigger: "axis",
		showContent: true,
		alwaysShowContent: false,
		axisPointer: {
			type: "shadow"
		},
		formatter: (params: any): string | HTMLElement | HTMLElement[] => {
			if (params[0].name == "") return "";
			return toTooltipHTML({
				name: params[0].name,
				values: params.map((param: any) => ({
					marker: marker(param),
					name: param.seriesName,
					value: `${toFixedWithZeroTruncation(param.value, 2)}%`
				}))
			});
		}
	},
	grid: { ...gridStyle, right: 20 },
	xAxis: {
		type: "value",
		boundaryGap: [0, 0],
		minInterval: 1,
		axisTick: {
			show: false
		},
		min: 0,
		max: 100,
		axisLine: {
			show: false
		},
		axisLabel: {
			show: true,
			color: "black",
			fontWeight: "bold",
			formatter: (value: number) => {
				return `${toFixedWithZeroTruncation(value, 2)}%`;
			}
		},
		splitLine: {
			show: true,
			interval: 1,
			lineStyle: {
				color: "#c7c7c4",
				width: 1.5
			}
		}
	},
	yAxis: [
		{
			type: "category",
			axisTick: {
				show: false
			},
			z: 5,
			axisLabel: {
				show: true,
				color: "black",
				width: 130,
				overflow: "break",
				fontWeight: "bold"
			},
			axisLine: {
				show: true,
				lineStyle: {
					color: "#c7c7c4",
					width: 2.5
				}
			},
			splitLine: {
				show: true,
				interval: 6,
				lineStyle: {
					color: "#c7c7c4",
					width: 1.5
				}
			},

			inverse: true
		},
		{
			id: "axis Mask",
			type: "category",
			axisTick: {
				show: false
			},
			axisLabel: {
				show: false
			},
			axisLine: {
				show: false
			},
			inverse: true,
			position: "left"
		}
	],
	series: [
		{
			type: "bar",
			barWidth: 20,
			name: "Actual",
			color: "#90cf5b",
			label: {
				show: true,
				position: "insideLeft",
				color: "#000",
				padding: [2, 4],
				backgroundColor: "rgba(255,255,255,0.7)",
				offset: [5, 0],
				formatter: (params: any): string => {
					return `${toFixedWithZeroTruncation(params.value, 2)}%`;
				}
			}
		}
	]
};

export const SERIE_DEFAULTS_ASPIRATION_BAR_A: any = {
	name: "Target",
	type: "bar",
	barWidth: 20,
	color: singleComparisonColor,
	barGap: "-100%",
	z: 2,
	itemStyle: {
		color: "transparent",
		borderType: "solid",
		borderColor: singleComparisonColor,
		borderWidth: 1.5
	},
	label: {
		show: false
	}
};
export const SERIE_DEFAULTS_ASPIRATIONS_BAR_A: SeriesOption | any = {
	...SERIE_DEFAULTS_ASPIRATION_BAR_A,
	yAxisIndex: 1,
	barGap: 0,
	barWidth: 10
};

export const LEGEND_DEFAULTS_BAR_A = {
	right: 0,
	top: 5,
	itemGap: 2,
	itemWidth: 10,
	itemHeight: 10,
	selectedMode: false,
	textStyle: {
		fontSize: 10,
		color: "white"
	},
	itemStyle: {
		borderType: "solid",
		borderWidth: 1.5
	}
};

export const CHART_DEFAULTS_BAR_B: EChartsOption | any = {
	...backgroundColor,
	title: {
		text: "Moral vs Performance",
		...titleStyle
	},
	tooltip: {
		trigger: "item",
		axisPointer: {
			type: "shadow"
		},
		formatter: (params: any): string | HTMLElement | HTMLElement[] => {
			return `${marker(params)} ${params.name} : ${toFixedWithZeroTruncation(
				params.value,
				2
			)}%`;
		}
	},
	legend: {
		right: "5%",
		top: 5,
		width: "70%",
		type: "scroll",
		itemWidth: 10,
		itemHeight: 10,
		itemGap: 5,
		selectedMode: false,
		textStyle: {
			fontSize: 10
		}
	},
	grid: {
		top: "35",
		left: "5%",
		right: "5%",
		bottom: "10%",
		containLabel: true
	},
	xAxis: [
		{
			type: "value",
			min: 0,
			max: 100,
			z: 3,
			axisTick: { show: false },
			axisLabel: {
				show: false,
				color: "black",
				formatter: "{value}%",
				fontWeight: "bold"
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: "#c7c7c4",
					width: 1.5
				}
			}
		},
		{
			type: "value",
			min: 0,
			max: 100,
			inverse: true,
			axisTick: { show: false },
			axisLabel: { show: false },
			splitLine: {
				show: false
			}
		}
	],
	yAxis: [
		{
			type: "category",
			z: 3,
			data: ["Moral", ""],
			axisTick: { show: false },
			axisLabel: { show: false },
			axisLine: {
				show: true,
				lineStyle: {
					color: "#c7c7c4",
					width: 2.5
				}
			}
		},
		{
			type: "category",
			position: "right",
			data: ["", "Performance"],
			axisTick: { show: false },
			axisLabel: { show: false },
			axisLine: {
				show: true,
				lineStyle: {
					color: "#c7c7c4",
					width: 2.5
				}
			}
		},

		{
			id: "axis Mask Moral",
			type: "category",
			axisLabel: {
				show: false
			},
			data: ["Moral", ""],
			axisLine: {
				show: false
			},
			axisTick: { show: false },
			position: "left"
		},
		{
			id: "axis Mask Performance",
			type: "category",
			data: ["", "Performance"],
			axisLabel: {
				show: false
			},
			axisLine: {
				show: false
			},
			axisTick: { show: false },
			position: "right"
		}
	],
	series: [
		{
			name: "Moral Actual",
			type: "bar",
			color: purpleColor,
			label: {
				show: true,
				position: "insideLeft",
				color: "#000",
				padding: [2, 4],
				backgroundColor: "rgba(255,255,255,0.7)",
				offset: [5, 0],
				formatter: (params: any): string => {
					return `${toFixedWithZeroTruncation(params.value, 2)}%`;
				}
			},
			data: [0, null],
			barWidth: "100%"
		},
		{
			xAxisIndex: 1,
			yAxisIndex: 1,
			name: "Performance Actual",
			type: "bar",
			color: orangeColor,
			data: [null, 0],
			label: {
				show: true,
				position: "insideRight",
				color: "#000",
				padding: [2, 4],
				backgroundColor: "rgba(255,255,255,0.7)",
				offset: [-5, 0],
				formatter: (params: any): string => {
					return `${toFixedWithZeroTruncation(params.value, 2)}%`;
				}
			},
			barWidth: "100%"
		}
	]
};
export const SERIE_DEFAULTS_ASPIRATION_BAR_B: any = {
	name: "Target",
	type: "bar",
	barWidth: "100%",
	barGap: "-100%",
	z: 2,
	itemStyle: {
		color: "transparent",
		borderType: "solid",
		borderColor: singleComparisonColor,
		borderWidth: 1.5
	},
	label: {
		show: false
	}
};

export const SERIE_DEFAULTS_ASPIRATIONS_BAR_B: SeriesOption | any = {
	...SERIE_DEFAULTS_ASPIRATION_BAR_B,
	barGap: 0,
	barWidth: "50%"
};
