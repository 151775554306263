import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { matchPassword } from "../../form/validator/match-password";
import { notMatchPassword } from "../../form/validator/not-match-password";
import { patternValidator } from "../../form/validator/patten-validator";
import { AccountService } from "../../services/account.service";
import { StepAccountService } from "./../../services/step-account.service";
import { ST_PLATFORM } from "../../types/common";
import { AuthService } from "../../services/auth.service";

@Component({
	selector: "app-change-password",
	templateUrl: "./change-password.component.html",
	styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent {
	userForm!: FormGroup;
	submitted = false;
	returnUrl: string = "/home/step/group";
	isVisible = false;
	get fg() {
		return this.userForm;
	}
	get currentPasswordLabel() {
		return `${this.dialogData.force ? "Temporary" : "Current"} Password`;
	}
	constructor(
		public dialogRef: MatDialogRef<ChangePasswordComponent>,
		@Inject(MAT_DIALOG_DATA)
		public dialogData: { for: ST_PLATFORM; force: boolean },
		private stepAccountService: StepAccountService,
		private accountService: AccountService,
		private fb: FormBuilder,
		private _snackBar: MatSnackBar,
		private UiLoaderService: NgxUiLoaderService,
		public authService: AuthService
	) {
		this.userForm = this.fb.group(
			{
				currentPassword: [null, Validators.required],
				newPassword: [
					null,
					[
						Validators.required,
						Validators.minLength(8),
						patternValidator(new RegExp("(?=.*[0-9])"), {
							requiresDigit: true
						}),
						patternValidator(new RegExp("(?=.*[A-Z])"), {
							requiresUppercase: true
						}),
						patternValidator(new RegExp("(?=.*[a-z])"), {
							requiresLowercase: true
						}),
						patternValidator(
							new RegExp(/(?=[!@#$%^&*(){}[\]='".,+\-_])/, "g"),
							{
								requiresSpecialChars: true
							}
						)
					]
				],
				confirmPassword: [null, Validators.required]
			},
			{
				validators: [
					matchPassword("newPassword", "confirmPassword"),
					notMatchPassword("currentPassword", "newPassword")
				]
			}
		);
	}
	onSubmit() {
		if (this.userForm.valid) {
			switch (this.dialogData.for) {
				case "STEP":
					this.changePasswordSTEP();
					break;
				case "STCA":
				case "STC360":
					this.changePasswordSTCA();
					break;
			}
		}
	}
	changePasswordSTEP() {
		this.UiLoaderService.start();
		this.stepAccountService.changePassword(this.userForm.value).subscribe(
			(res: any) => {
				this.UiLoaderService.stop();
				this.dialogRef.close({ changed: true });
				this._snackBar.open(res.message);
			},
			error => {
				this.UiLoaderService.stop();
			}
		);
	}
	changePasswordSTCA() {
		this.UiLoaderService.start();
		this.accountService.changePassword(this.userForm.value).subscribe(
			(res: any) => {
				this.UiLoaderService.stop();
				this.dialogRef.close({ changed: true });
				this._snackBar.open(res.message);
			},
			error => {
				this.UiLoaderService.stop();
			}
		);
	}
}
