import { saveAs } from "file-saver";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { TransactionQueryParams } from "../types/credits";
import { removeUndefined } from "../utils/object";

@Injectable({
	providedIn: "root"
})
export class TransactionService {
	baseURL = environment.baseUrl;
	constructor(private http: HttpClient) {}
	get(qParams: TransactionQueryParams) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...qParams,
					sortBy: qParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get(`${this.baseURL}/transaction`, { params });
	}
	async download(transaction: any) {
		return new Promise<void>((resolve, reject) => {
			const params = new HttpParams({
				fromObject: removeUndefined(
					{
						id: transaction?.id
					} ?? {}
				)
			});
			this.http
				.get(`${this.baseURL}/transaction/receipt`, {
					params,
					responseType: "blob"
				})
				.subscribe(
					res => {
						saveAs(
							res,
							`${transaction?.org?.name || ""}-${transaction.receiptId}`
						);
						resolve();
					},
					err => {
						reject(err);
					}
				);
		});
	}
}
