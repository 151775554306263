import { Component, Input } from "@angular/core";

@Component({
	selector: "app-charametrics-descriptions",
	templateUrl: "./charametrics-descriptions.component.html",
	styleUrls: ["./charametrics-descriptions.component.scss"]
})
export class CharametricsDescriptionsComponent {
	@Input() pageNoOffsetForComparison: number = 0;
	ages = new Array(49).fill(0).map((v, i) => i);
	phases = [
		"Survival / Physical Needs",
		"Connection / Emotional Balance",
		"Achievement / Willpower",
		"Love / Being Loved / Stability",
		"Communication / Expression / Independence",
		"Awareness / Insight / Community",
		"Purpose / Spirituality / Universality",
		"Holistic Evolution / Contribution & Guidance"
	];
	phasesTag = [
		"(Security)",
		"(Acceptance)",
		"(Significance)",
		"(Gratitude)",
		"(Truth)",
		"(Discovery)",
		"(Meaning)",
		"(Aspect person seeking/cultivating)"
	];
	group = {
		name: "ShareTree",
		primaryAspirations: ["SERVICE", "INNOVATION", "EXCELLENCE"],
		secondaryAspirations: [
			"PURPOSEFULNESS",
			"UNITY",
			"RELIABILITY",
			"DETERMINATION"
		],
		MORAL: {
			PURPOSEFULNESS: 66,
			HOPE: 12,
			TRUTHFULNESS: 12,
			LOVE: 12,
			RESPECT: 66,
			MINDFULNESS: 12
		},
		PERFORMANCE: {
			WISDOM: 84,
			TRUSTWORTHINESS: 66,
			COMPASSION: 84,
			COURAGE: 66,
			TOLERANCE: 12,
			"SELF-DISCIPLINE": 84
		},
		primaryAspirationsGroups: ["COMPASSION", "WISDOM", "SELF-DISCIPLINE"],
		secondaryAspirationsGroups: [
			"PURPOSEFULNESS",
			"RESPECT",
			"TRUSTWORTHINESS",
			"COURAGE"
		],
		GROUP_TOTAL: {
			MORAL: 30,
			PERFORMANCE: 66
		},
		cultureFit: 83.56,
		fitType: "Very Strong",
		alignmentByLayers: {
			PURPOSEFULNESS: 66,
			"HOPE & WISDOM": 48,
			"TRUTHFULNESS & TRUSTWORTHINESS": 39,
			"LOVE & COMPASSION": 48,
			COURAGE: 66,
			"RESPECT & TOLERANCE": 39,
			"MINDFULNESS & SELF-DISCIPLINE": 48
		},
		TOTAL: 0
	};
	sample = {
		characterStrengths: {
			TEAM: {
				MORAL: {
					CLARITY: 5.66,
					HONESTY: 2.78,
					COMPOSURE: 2.78,
					ENTHUSIASM: 8.33,
					COOPERATION: 2.78,
					ENCOURAGEMENT: 5.56
				},
				PERFORMANCE: {
					CONFIDENCE: 13.89,
					LEADERSHIP: 16.67,
					FLEXIBILITY: 0,
					HELPFULNESS: 16.67,
					RELIABILITY: 13.89,
					PROFESSIONALISM: 11.11
				}
			},
			MORAL: {
				"LOVE & ENTHUSIASM": 8.33,
				"HOPE & ENCOURAGEMENT": 5.56,
				"RESPECT & COOPERATION": 1.39,
				"TRUTHFULNESS & HONESTY": 2.78,
				"MINDFULNESS & COMPOSURE": 5.56,
				"PURPOSEFULNESS & CLARITY": 8.33
			},
			vsGSD: {
				MORAL: {
					"LOVE & ENTHUSIASM": 1.06,
					"HOPE & ENCOURAGEMENT": 0.73,
					"RESPECT & COOPERATION": 0.15,
					"TRUTHFULNESS & HONESTY": 0.29,
					"MINDFULNESS & COMPOSURE": 0.88,
					"PURPOSEFULNESS & CLARITY": 1.0
				},
				PERFORMANCE: {
					"WISDOM & LEADERSHIP": 1.82,
					"COURAGE & CONFIDENCE": 1.63,
					"TOLERANCE & FLEXIBILITY": 0.21,
					"COMPASSION & HELPFULNESS": 1.3838209982788297,
					"TRUSTWORTHINESS & RELIABILITY": 0.96,
					"SELF-DISCIPLINE & PROFESSIONALISM": 1.91
				}
			},
			INDIVIDUAL: {
				MORAL: {
					HOPE: 5.56,
					LOVE: 8.33,
					RESPECT: 0,
					MINDFULNESS: 8.33,
					TRUTHFULNESS: 2.78,
					PURPOSEFULNESS: 11.1
				},
				PERFORMANCE: {
					WISDOM: 16.67,
					COURAGE: 13.89,
					TOLERANCE: 2.78,
					COMPASSION: 8.33,
					"SELF-DISCIPLINE": 16.67,
					TRUSTWORTHINESS: 5.55
				}
			},
			PERFORMANCE: {
				"WISDOM & LEADERSHIP": 16.67,
				"COURAGE & CONFIDENCE": 13.89,
				"TOLERANCE & FLEXIBILITY": 1.39,
				"COMPASSION & HELPFULNESS": 12.5,
				"TRUSTWORTHINESS & RELIABILITY": 9.72,
				"SELF-DISCIPLINE & PROFESSIONALISM": 13.89
			}
		},
		characterStrengthsProfile: {
			GROUP_TOTAL: {
				MORAL: 31.94,
				PERFORMANCE: 68.06
			},
			MORAL: {
				"LOVE & ENTHUSIASM": 50,
				"HOPE & ENCOURAGEMENT": 33.333333333333333,
				"RESPECT & COOPERATION": 8.333333333333333,
				"TRUTHFULNESS & HONESTY": 16.666666666666664,
				"MINDFULNESS & COMPOSURE": 33.333333333333333,
				"PURPOSEFULNESS & CLARITY": 50
			},

			PERFORMANCE: {
				"WISDOM & LEADERSHIP": 100,
				"COURAGE & CONFIDENCE": 83.333333333333333,
				"TOLERANCE & FLEXIBILITY": 8.333333333333333,
				"COMPASSION & HELPFULNESS": 75,
				"TRUSTWORTHINESS & RELIABILITY": 58.33,
				"SELF-DISCIPLINE & PROFESSIONALISM": 83.333333333333333
			},
			alignmentByLayers: {
				COURAGE: 83.333333333333333,
				"HOPE & WISDOM": 66.666666666666664,
				PURPOSEFULNESS: 50,
				"LOVE & COMPASSION": 62.5,
				"RESPECT & TOLERANCE": 8.333333333333333,
				"MINDFULNESS & SELF-DISCIPLINE": 58.333333333333333,
				"TRUTHFULNESS & TRUSTWORTHINESS": 37.5
			}
		}
	};
}
