<h1 mat-dialog-title>
	<ng-container *ngIf="dialogData?.typeOf==='CLIENT'">Client</ng-container>
	Organisation Invite - {{dialogData?.org?.name}}
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<mat-card>
		<form *ngIf="orgFormGroup" [formGroup]="orgFormGroup" (submit)="send()">
			<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
				<app-emails class="full-width" fcName="membersToBeInvited" [fg]="orgFormGroup"
					label="Invite {{dialogData?.typeOf==='CLIENT'?'Clients':'People'}}" placeholder="Type in Email address">
				</app-emails>
				<mat-form-field fxFlex="1 1 200px" fxFlexFill>
					<mat-label>Role</mat-label>
					<mat-select formControlName="role" name="role">
						<mat-option value="ADMIN"> Admin </mat-option>
						<mat-option value="MEMBER"> Member</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
				<button type="button" mat-button (click)="cancel()">Cancel</button>
				<button type="submit" [disabled]="orgFormGroup.invalid" mat-flat-button color="primary">
					<mat-icon>send</mat-icon>
					Send Invite
				</button>
			</div>
		</form>
		<mat-chip-list>
			<mat-chip *ngFor="let item of results; let i=index">
				<div [ngSwitch]="item.status">
					<div *ngSwitchCase="'ALREADY_EXISTING_USER'">
						<mat-icon color="warn" matTooltip="Already Existing User">error_outline</mat-icon>
					</div>
					<div *ngSwitchCase="'SENT'">
						<mat-icon matTooltip="Sent" color="primary">check</mat-icon>
					</div>
				</div>
				&nbsp;
				{{emails[i]}}
			</mat-chip>
		</mat-chip-list>
	</mat-card>

	<section fxLayout="column">
		<div fxLayout="row wrap" fxLayoutAlign="start center">
			<div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="16px">
				<mat-form-field>
					<mat-label>Filter</mat-label>
					<input matInput placeholder="Ex. Mia" #input [formControl]="filterFormControl">
				</mat-form-field>
				<div style="position: relative;height:25px; width: 25px;">
					<app-loader [loaderId]="loaderId" [bgsSize]=25></app-loader>
				</div>
			</div>
			<span class="ca-spacer"></span>
			<mat-paginator class="ca-mat-paginator" [length]="resultsLength" [pageSize]="pageSize"
				[showFirstLastButtons]="(resultsLength/pageSize)>2" [pageSizeOptions]="[10,25,50,100]" aria-label="Select page">
			</mat-paginator>
		</div>
		<div fxLayout="column" class="ca-page-content">
			<div fxLayout="row" fxLayoutAlign="center center" fxFlex="shrink" *ngIf="!isLoadingResults  && data.length===0">
				<div *ngIf="(filterFormControl?.value===null || filterFormControl?.value?.length===0)">
					All Your Invites will appear here
				</div>
				<div *ngIf="filterFormControl?.value?.length>0">
					There are no results available for this keyword <b>"{{ filterFormControl.value}}"</b>. Please
					refine
					your search with some other words.
				</div>
			</div>
			<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
			<div class="ca-mat-table-container" [hidden]="isLoadingResults || data.length===0">
				<app-ng-scrollbar-wrapper [autoHeightDisabled]="true">
					<mat-table class="mat-elevation-z8" [dataSource]="data" matSort [matSortActive]="'createdAt'"
						matSortDirection="desc" fixedLayout="true">
						<ng-container matColumnDef="index">
							<mat-header-cell *matHeaderCellDef>
								No.
							</mat-header-cell>
							<mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="email" sticky>
							<mat-header-cell *matHeaderCellDef>
								Email
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<app-avatar *ngIf="element?.to" [showEmail]="true" [avatar]="element?.to">
								</app-avatar>
								<ng-container *ngIf="!element?.to">
									<app-copy-to-clipboard [content]="element.email">
										{{element.email}}
									</app-copy-to-clipboard>
								</ng-container>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="from">
							<mat-header-cell *matHeaderCellDef>
								Invited By
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<app-avatar [avatar]="element?.from">
								</app-avatar>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="status">
							<mat-header-cell *matHeaderCellDef>
								Status
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<div class="status" [class]="element.status">
									{{element.status| titlecase }}
								</div>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="actions">
							<mat-header-cell *matHeaderCellDef>
								Action
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<ng-container [ngSwitch]="element.role">
									<ng-container *ngSwitchCase="'MEMBER'">
										<div [ngSwitch]="element.status">
											<div *ngSwitchCase="'PENDING'">
												<button matTooltip="Resend Invite" mat-icon-button color="primary" aria-label="Resend Invite"
													(click)="resend(element)">
													<mat-icon>send</mat-icon>
												</button>
												<button matTooltip="Edit" mat-icon-button color="primary" aria-label="Edit"
													(click)="openUpdateInvite(element)">
													<mat-icon>edit</mat-icon>
												</button>
												<button matTooltip="Cancel" mat-icon-button color="warn" aria-label="Cancel"
													(click)="withdraw(element)">
													<mat-icon>block</mat-icon>
												</button>
											</div>
											<div *ngSwitchCase="'ACCEPTED'">
												<button *ngIf="element.typeOf==='PARTNER'" mat-button (click)="remove(element)" color="warn">
													<mat-icon>close</mat-icon> Remove Access
												</button>
												<span *ngIf="element.typeOf==='CLIENT'">N/A</span>
											</div>
										</div>
									</ng-container>
									<ng-container *ngSwitchCase="'ADMIN'">
										<div [ngSwitch]="element.status">
											<div *ngSwitchCase="'PENDING'">
												<button matTooltip="Resend Invite" mat-icon-button color="primary" aria-label="Resend Invite"
													(click)="resend(element)">
													<mat-icon>send</mat-icon>
												</button>
												<button matTooltip="Edit" mat-icon-button color="primary" aria-label="Edit"
													(click)="openUpdateInvite(element)">
													<mat-icon>edit</mat-icon>
												</button>
												<button matTooltip="Cancel" mat-icon-button color="warn" aria-label="Cancel"
													(click)="withdraw(element)">
													<mat-icon>block</mat-icon>
												</button>
											</div>
											<div *ngSwitchCase="'ACCEPTED'">
												<div class="flex">
													<button matTooltip="Remove Access" *ngIf="element.to.id!==dialogData?.org.owner.id" mat-button
														(click)="remove(element)" color="warn">
														<mat-icon>close</mat-icon>
													</button>
													<button matTooltip="Transfer Ownership to this Admin"
														*ngIf="(dialogData?.org.owner.id=== authService.user?.id || authService.user?.role==='ADMIN' ) && element.to.id!==dialogData?.org.owner.id"
														mat-button (click)="transferOwnership(element)" color="warn">
														Transfer Ownership
													</button>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="createdAt">
							<mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">
								Invited on
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								{{element.createdAt| date:'medium' }}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="actionedBy">
							<mat-header-cell *matHeaderCellDef>
								Actioned By
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<app-avatar *ngIf="element?.actionedBy" [avatar]="element?.actionedBy">
								</app-avatar>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="actionedAt">
							<mat-header-cell *matHeaderCellDef mat-sort-header="actionedAt">
								Actioned on
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								{{element?.actionedAt| date:'medium' }}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="forOrganisation">
							<mat-header-cell *matHeaderCellDef>
								Client Organisation
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<app-avatar *ngIf="element.forOrganisation" [avatar]="element?.forOrganisation">
								</app-avatar>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="seedingRate">
							<mat-header-cell *matHeaderCellDef>
								Seeding Rate
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<ng-container *ngIf="element.forOrganisation?.seedingRate">
									{{element.forOrganisation?.seedingRate*100 }}%
								</ng-container>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="seedingEndAt">
							<mat-header-cell *matHeaderCellDef>
								Seeding End Date
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								{{element.forOrganisation?.seedingEndAt| date:'dd/MM/yyyy' }}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="role">
							<mat-header-cell *matHeaderCellDef>
								Role
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<ng-container *ngIf="element?.to?.id===dialogData?.org?.owner?.id">
									Owner
								</ng-container>
								<ng-container *ngIf="element?.to?.id!==dialogData?.org?.owner?.id">
									<mat-form-field fxFlex="1 1 200px" fxFlexFill>
										<mat-select [disabled]="element.status==='WITHDRAWN' || element.status==='REVOKED'" [value]="element.role" name="role"
											(selectionChange)="updateRole(element,$event)">
											<mat-option value="ADMIN"> Admin </mat-option>
											<mat-option value="MEMBER"> Member</mat-option>
										</mat-select>
									</mat-form-field>
								</ng-container>
							</mat-cell>
						</ng-container>
						<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
					</mat-table>
				</app-ng-scrollbar-wrapper>
			</div>
		</div>
	</section>
</mat-dialog-content>

<ng-template #message let-invite="invite">
	<div style="line-height: 1.8;">
		Do you wish to Make <app-avatar [avatar]="invite.to" [fxInline]="true"></app-avatar>
		as the <b>Owner</b> of <app-avatar [avatar]="invite.organisation" [fxInline]="true"></app-avatar>?
		<br><br>
		<ng-container *ngIf="authService.user?.role!=='ADMIN'">
			This action can't be reversed.
		</ng-container>
	</div>
</ng-template>
