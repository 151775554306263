import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { conditionalValidators } from "../../form/validator/conditional-validators";
import { validateChips } from "../../form/validator/validate-chips";
import { FILE_UPLOAD } from "../../types/common";
import { FileService } from "./../../services/file.service";
import { OrganisationService } from "./../../services/organisation.service";
import { ImageCropperComponent } from "./../image-cropper/image-cropper.component";
import { NgxUiLoaderService } from "ngx-ui-loader";
@Component({
	selector: "app-organisation",
	templateUrl: "./organisation.component.html",
	styleUrls: ["./organisation.component.scss"]
})
export class OrganisationComponent implements OnInit {
	groupProfileImage: FILE_UPLOAD = {};
	orgLogoImage: FILE_UPLOAD = {};
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	orgFormGroup?: FormGroup;
	constructor(
		private UiLoaderService: NgxUiLoaderService,
		private dialog: MatDialog,
		public dialogRef: MatDialogRef<OrganisationComponent>,
		private _snackBar: MatSnackBar,
		public fileService: FileService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private organisationService: OrganisationService
	) {
		this.orgFormGroup = this.fb.group(
			{
				name: [, Validators.required],
				imageKey: [],
				orgLogoImageKey: [],
				role: [, Validators.required],
				membersToBeInvited: [[], validateChips],
				seedingOrgId: []
			},
			{
				validators: conditionalValidators("role", [
					{
						isSelected: form => {
							return form?.get("membersToBeInvited")?.value.length > 0;
						},
						validators: Validators.required
					}
				])
			}
		);
	}
	get fg() {
		return this.orgFormGroup;
	}
	get membersToBeInvited() {
		return this.orgFormGroup?.get("membersToBeInvited");
	}
	ngOnInit(): void {
		if (this.data?.org) {
			this.orgFormGroup?.patchValue(this.data.org);
		}
	}
	cancel() {
		this.dialogRef.close();
	}
	submit() {
		this.orgFormGroup?.markAllAsTouched();
		if (this.orgFormGroup?.valid) {
			if (this.data?.org?.id) {
				this.save();
			} else {
				this.create();
			}
		}
	}
	async uploadImage() {
		if (this.groupProfileImage.file) {
			try {
				let imageKey = await this.fileService.uploadFile(
					this.groupProfileImage.file,
					"GROUP_PROFILE"
				);
				this.orgFormGroup?.patchValue({ imageKey });
				delete this.groupProfileImage.file;
			} catch (error) {
				return;
			}
		}
	}
	async uploadLogoImage() {
		if (this.orgLogoImage.file) {
			try {
				let orgLogoImageKey = await this.fileService.uploadFile(
					this.orgLogoImage.file,
					"GROUP_PROFILE"
				);
				this.orgFormGroup?.patchValue({ orgLogoImageKey });
				delete this.orgLogoImage.file;
			} catch (error) {
				return;
			}
		}
	}
	async save() {
		this.UiLoaderService.start();
		await this.uploadImage();
		await this.uploadLogoImage();
		const payload = this.orgFormGroup?.value;
		this.organisationService
			.save(this.data.org.id, payload)
			.subscribe((res: any) => {
				if (res.statusCode === 200) {
					this.dialogRef.close({ refresh: true });
					this._snackBar.open("Organisation Updated.");
				}
				this.UiLoaderService.stop();
			});
	}
	async create() {
		this.UiLoaderService.start();
		await this.uploadImage();
		await this.uploadLogoImage();
		let payload = {
			...this.orgFormGroup?.value,
			inviteToken: this.data.inviteToken
		};
		this.organisationService.create(payload).subscribe((res: any) => {
			if (res.statusCode === 200) {
				this.dialogRef.close({ refresh: true, org: res.org });
				this._snackBar.open("Organisation Created.");
			}
			this.UiLoaderService.stop();
		});
	}
	removePic() {
		this.orgFormGroup?.get("imageKey")?.setValue(null);
		delete this.groupProfileImage.src;
		delete this.groupProfileImage.file;
	}
	onGroupProfileUpload($event: Event) {
		const target = $event.target as HTMLInputElement;
		const files = target.files as FileList;
		if (files?.length) {
			const dialogRef = this.dialog.open(ImageCropperComponent, {
				data: {
					event: $event,
					type: "PROFILE_IMAGE"
				}
			});
			dialogRef.afterClosed().subscribe(async result => {
				if (result) {
					this.groupProfileImage.src = result.base64;
					this.groupProfileImage.file = result.blob;
				} else {
					($event.target as HTMLInputElement).value = "";
				}
			});
		}
	}
	removeOrgLogoImage() {
		this.orgFormGroup?.get("orgLogoImageKey")?.setValue(null);
		delete this.orgLogoImage.src;
		delete this.orgLogoImage.file;
	}
	onOrgLogoImageUpload($event: Event) {
		const target = $event.target as HTMLInputElement;
		const files = target.files as FileList;
		if (files?.length) {
			this.orgLogoImage.file = files[0];
			const reader = new FileReader();
			reader.onload = e => (this.orgLogoImage.src = reader.result as string);
			reader.readAsDataURL(files[0]);
		}
	}
}
