import { EChartsOption } from "echarts";
import { WHOLE_CHARACTERS_LABELS } from "../character";

export const CHART_DEFAULTS: EChartsOption | any = {
	radar: [
		{
			indicator: WHOLE_CHARACTERS_LABELS.map(name => ({
				name,
				min: 0,
				max: 2,
				color: "#000"
			})),
			radius: "34%",
			shape: "circle",
			startAngle: 105,
			center: ["50%", "50%"],
			z: 2,
			axisLine: {
				show: false
			},
			axisName: {
				show: false
			},

			splitArea: {
				show: false
			},
			splitNumber: 2,
			splitLine: {
				show: true,
				lineStyle: {
					color: ["#f68c43", "#69b455"],
					width: 1
				}
			}
		}
	],
	series: [
		{
			type: "radar",
			tooltip: {
				trigger: "item"
			},
			color: "#90cf5b",
			areaStyle: {
				opacity: 0.5,
				shadowColor: "rgba(0, 0, 0, .7)",
				shadowBlur: 10,
				shadowOffsetX: 3,
				shadowOffsetY: 3
			},
			z: 1,
			symbol: "none",
			lineStyle: {
				color: "#90cf5b"
			},

			data: [
				{
					name: "Charater Strengths profile"
				}
			]
		}
	]
};
