import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ImageCropperComponent } from "src/app/_shared/components/image-cropper/image-cropper.component";
import { AccountService } from "../../services/account.service";
import { AmplitudeService } from "../../services/amplitude.service";
import { AuthService } from "../../services/auth.service";
import { DeviceService } from "../../services/device.service";
import { DialogsService } from "../../services/dialogs.service";
import { FileService } from "../../services/file.service";
import { ReportService } from "../../services/report.service";
import { ORG } from "../../types/organisation";
import {
	USER_SIGNUP,
	USER_SIGNUP_EVENT,
	USER_SIGNUP_TYPE
} from "../../types/user";

@Component({
	selector: "app-user-signup",
	templateUrl: "./user-signup.component.html",
	styleUrls: ["./user-signup.component.scss"]
})
export class UserSignupComponent implements AfterViewInit {
	@ViewChild("signupForm") signupForm!: NgForm;
	@Input() flat: boolean = false;
	@Input() org?: ORG;
	@Output() alreadyExistingEmailEvent: EventEmitter<{ emailId: string }> =
		new EventEmitter();
	@Output() accountCreation: EventEmitter<USER_SIGNUP_EVENT> =
		new EventEmitter();
	@ViewChild("emailIdInput") emailIdInput?: ElementRef;
	isPasswordRequired = true;
	signupFormModel: USER_SIGNUP = {};
	reportId?: number;
	howDidYouFindOutAboutUsOther = "";
	howDidYouFindOutAboutUsOptions = [
		"Completed a 360",
		"A friend told me about it",
		"Google",
		"ShareTree",
		"Linkedin",
		"Newsletter",
		"Other"
	];
	userProfileImage: { src?: string; file?: File } = {};
	timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
	device: any;
	isCheckingIfAlreadyExistingEmail = false;
	today = new Date();
	constructor(
		private dialog: MatDialog,
		private accountService: AccountService,
		private activatedRoute: ActivatedRoute,
		private UiLoaderService: NgxUiLoaderService,
		private reportService: ReportService,
		private fileService: FileService,
		private deviceService: DeviceService,
		private authService: AuthService,
		private router: Router,
		private _snackBar: MatSnackBar,
		private dialogsService: DialogsService,
		private amplitudeService: AmplitudeService
	) {
		this.getDeviceInfo();
	}
	ngAfterViewInit(): void {
		this.prefill();
	}
	prefill() {
		let { firstName, emailId } = this.activatedRoute.snapshot
			.queryParams as any;
		if (firstName || emailId) {
			this.signupFormModel = { ...this.signupFormModel, firstName, emailId };
		}
	}
	onUserProfileUpload($event: Event) {
		const target = $event.target as HTMLInputElement;
		const files = target.files as FileList;
		if (files?.length) {
			const dialogRef = this.dialog.open(ImageCropperComponent, {
				data: {
					event: $event,
					type: "PROFILE_IMAGE"
				}
			});
			dialogRef.afterClosed().subscribe(async result => {
				if (result) {
					this.userProfileImage.src = result.base64;
					this.userProfileImage.file = result.blob;
				} else {
					($event.target as HTMLInputElement).value = "";
				}
			});
		}
	}
	removeProfilePic() {
		delete this.userProfileImage.src;
		delete this.userProfileImage.file;
	}
	async getDeviceInfo() {
		this.device = await this.deviceService.getDeviceInfo();
	}
	checkIfEmailExistingAccount() {
		if (
			!this.isCheckingIfAlreadyExistingEmail &&
			this.signupForm.form.get("emailId")?.valid
		) {
			let payload: any = {
				emailId: this.signupFormModel.emailId
			};
			this.isCheckingIfAlreadyExistingEmail = true;
			this.accountService.isEmailExistingAccount(payload).subscribe({
				next: (res: any) => {
					if (res.isExist) {
						this.confirmIfNavigateToLogin();
					}
					this.isCheckingIfAlreadyExistingEmail = false;
				}
			});
		}
	}
	async confirmIfNavigateToLogin() {
		let form = this.signupForm.form.value;
		this.signupFormModel.emailId = "";
		let canNavigate = await this.dialogsService.confirmIfNavigateToLogin();
		if (canNavigate) {
			this.navigateToLogin(form);
		} else {
			this.emailIdInput?.nativeElement.focus();
		}
	}
	navigateToLogin(form: any) {
		this.alreadyExistingEmailEvent.emit({
			emailId: form.emailId as string
		});
	}
	createAccount(type: USER_SIGNUP_TYPE, $event?: any) {
		let delay = false;
		if (type == "google" || type == "microsoft") {
			this.isPasswordRequired = false;
			delay = true;
		}
		const fn = () => {
			this.signupForm.form.markAllAsTouched();
			if (this.signupForm.valid) {
				this.accountCreation.emit({
					type,
					details: this.signupFormModel,
					$event
				});
			}
		};
		if (delay) {
			setTimeout(() => {
				fn();
			}, 0);
		} else {
			fn();
		}
	}
	async submit() {
		this.signupForm.form.markAllAsTouched();
		if (this.userProfileImage.file) {
			try {
				this.signupFormModel.imageKey = await this.fileService.uploadFile(
					this.userProfileImage.file as File,
					"USER_PROFILE"
				);
				delete this.userProfileImage.file;
			} catch (error) {
				return;
			}
		}
		let payload: any = {
			...this.signupFormModel,
			timeZone: this.timeZone,
			device: this.device
		};
		this.UiLoaderService.start();
		this.accountService.createUserAccount(payload).subscribe({
			next: this.signedUp,
			error: () => {
				this.UiLoaderService.stop();
			}
		});
	}
	submitWithGoogle(googleToken: string) {
		this.UiLoaderService.start();
		let payload = {
			...this.signupFormModel,
			googleToken,
			timeZone: this.timeZone,
			device: this.device
		};
		this.accountService.createUserAccount(payload).subscribe({
			next: this.signedUp,
			error: () => {
				this.UiLoaderService.stop();
			}
		});
	}
	submitWithMicrosoft(data: any) {
		this.UiLoaderService.start();
		let payload = {
			...this.signupFormModel,
			microsoftToken: data.idToken,
			timeZone: this.timeZone,
			device: this.device
		};
		this.accountService.createUserAccount(payload).subscribe({
			next: this.signedUp,
			error: () => {
				this.UiLoaderService.stop();
			}
		});
	}
	signedUp = async (res: any) => {
		if (res.statusCode === 200) {
			this.UiLoaderService.stop();
			this.amplitudeService.setUserId(res.user.id);
			this.amplitudeService.track("Signed Up");
			this.amplitudeService.setUserProperty("emailId", res.user.emailId);
			this.amplitudeService.setUserProperty("device", this.device);
			this.authService.setAuth(res.authToken);
			this.authService.setLoggedIn();
			this.authService.setUserInfo(res.user);
			this.authService.setSTPlatform("STC360");
			let { returnUrl } = this.activatedRoute.snapshot.queryParams as any;
			if (this.reportId) {
				this.addReportToUser(this.reportId);
			}
			if (returnUrl) {
				await this.router.navigateByUrl(returnUrl);
			} else {
				await this.router.navigate(["character-360", "verify-email"]);
			}
		} else {
			this.UiLoaderService.stop();
		}
	};
	addReportToUser(reportId: number) {
		this.reportService.addReportToUser(reportId).subscribe((res: any) => {
			if (res.statusCode === 201) {
				this._snackBar.open("Added report to your List");
			}
		});
	}
	setReportId(reportId: number) {
		this.reportId = reportId;
	}
}
