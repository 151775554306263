import { Pipe, PipeTransform } from "@angular/core";
import { ACTIVITY_OF, LOG_TYPE, Log } from "../types/log";
import { LOG_STATUS_MAP } from "../const/log";

@Pipe({
	name: "log"
})
export class LogPipe implements PipeTransform {
	transform(log: Log, activityOf: ACTIVITY_OF): unknown {
		let logType = LOG_STATUS_MAP?.[log?.type as LOG_TYPE];
		if (typeof logType === "object" && activityOf in (logType as any)) {
			return (logType as any)[activityOf](log);
		} else if (typeof logType === "function") {
			return (logType as any)(log);
		}
		return LOG_STATUS_MAP[log.type];
	}
}
