<section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div fxFlex="1 1 50%" fxFlexFill  fxFlexAlign="start">
        <span class="aspiration-title" >Primary Aspirations</span >
        <div *ngFor="let i of primaryIndices">
            <app-aspiration [aspirationCtrl]="getAspirationAt(i)"></app-aspiration>
        </div>
    </div>
    <div fxFlex="1 1 50%" fxFlexFill   fxFlexAlign="start">
        <span class="aspiration-title" >Secondary Aspirations</span >
        <div *ngFor="let i of secondaryIndices">
            <app-aspiration [aspirationCtrl]="getAspirationAt(i)"></app-aspiration>
        </div>
    </div>
</section>