<section fxLayout="column">
	<div fxLayout="row wrap" fxLayoutAlign="start center">
		<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px">
			<mat-form-field>
				<mat-label>Filter</mat-label>
				<input matInput placeholder="Ex. Mia" #input [formControl]="filterFormControl">
				<button matSuffix mat-icon-button (click)="filterFormControl.setValue('')">
					<mat-icon *ngIf="filterFormControl.value" aria-label="Clear">close</mat-icon>
					<app-loader [loaderId]="loaderId" [bgsSize]="24" bgsPosition="center-center"></app-loader>
				</button>
			</mat-form-field>
			<mat-form-field>
				<mat-label>On {{ searchOnFormControl.value?.length? '':"Any"}}</mat-label>
				<mat-select [formControl]="searchOnFormControl">
					<mat-option>Any</mat-option>
					<mat-option *ngFor="let field of searchFields" [value]="field.value">
						{{field.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<app-partner-survey-select [partnerSurveyCtrl]="partnerSurveyFormControl"
				[readOnly]="freezeFilter?.partnerSurvey">
			</app-partner-survey-select>
			<ng-container *ngIf="org && !selectedOrg">
				<mat-form-field fxFlex="1 1 " fxFlexFill>
					<mat-label>Paid Credits</mat-label>
					<input matInput readonly [value]="org?.wallet?.balance" type="number">
				</mat-form-field>
				<mat-form-field fxFlex="1 1 " fxFlexFill>
					<mat-label>Seed Credits</mat-label>
					<input matInput readonly [value]="org?.wallet?.seededBalance" type="number">
				</mat-form-field>
			</ng-container>
			<div
				[matTooltip]="!(selection.hasValue() && selection.selected.length>1)?'Select atleast 2 reports to Generate Group Charametrics':''">
				<button mat-flat-button [disabled]="!(selection.hasValue() && selection.selected.length>1)" color="primary"
					(click)="openGenerateGroupReport()">
					Generate Group Charametrics ({{selection.selected.length}}/{{maxLimitForGroupReport}})
				</button>
			</div>
			<button mat-flat-button [disabled]="noOfUnpackRequired===0 && !isOnlyUnpackRequired" color="primary"
				(click)="toggleUnpackRequiredFilter()">
				<ng-container *ngIf="!isOnlyUnpackRequired;else showAllRef">
					Unpack Required ({{noOfUnpackRequired}})
				</ng-container>
				<ng-template #showAllRef>Show All</ng-template>
			</button>
		</div>
		<span class="ca-spacer"></span>
		<mat-paginator class="ca-mat-paginator" [length]="resultsLength" [pageSize]="pageSize"
			[showFirstLastButtons]="(resultsLength/pageSize)>2" [pageSizeOptions]="[10,25,50,100]" aria-label="Select page">
		</mat-paginator>
	</div>
	<div fxLayout="column" class="ca-page-content">
		<div fxLayout="row" fxLayoutAlign="center center" fxFlex="shrink" *ngIf="!isLoadingResults && data.length===0 ">
			<div *ngIf="(filterFormControl?.value===null || filterFormControl?.value?.length===0)">
				All Your Reports will appear here
			</div>
			<div *ngIf="filterFormControl?.value?.length>0">
				There are no results available for this keyword <b>"{{ filterFormControl.value}}"</b>. Please
				refine
				your search with some other words.
			</div>
		</div>
		<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
		<div class="ca-mat-table-container" [hidden]="isLoadingResults || data.length===0">
			<app-ng-scrollbar-wrapper>
				<mat-table class="mat-elevation-z8" [dataSource]="data" matSort [matSortActive]="'createdAt'"
					matSortDirection="desc">
					<ng-container matColumnDef="select">
						<mat-header-cell *matHeaderCellDef>
							<mat-icon *ngIf="isAllSelected || selection.selected.length===maxLimitForGroupReport"
								matTooltip="Deselect All" (click)="deselectAll()">deselect</mat-icon>
							<mat-icon *ngIf="!isAllSelected && selection.selected.length<maxLimitForGroupReport"
								matTooltip="select All(Max {{maxLimitForGroupReport}})" (click)="selectAll()">select_all</mat-icon>
						</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="selectionChange($event,row)"
								[checked]="selection.isSelected(row)">
							</mat-checkbox>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="index" sticky>
						<mat-header-cell *matHeaderCellDef>
							No.
						</mat-header-cell>
						<mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="name" sticky>
						<mat-header-cell *matHeaderCellDef mat-sort-header="name">
							Name
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.name |firstCap}}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="log">
						<mat-header-cell *matHeaderCellDef>
							Logs
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button matTooltip="Log" mat-icon-button color="primary" (click)="openLogs(element)">
								<mat-icon svgIcon="log"></mat-icon>
							</button>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="email">
						<mat-header-cell *matHeaderCellDef>
							Email
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-copy-to-clipboard [content]="element.email" [overflowEllipsis]="true">
								{{element.email}}
							</app-copy-to-clipboard>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="user">
						<mat-header-cell *matHeaderCellDef>
							User Account
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<div *ngIf="!element.editUserEmail">
								<div (click)="openUpdateUserEmail(element);$event.stopPropagation()">
									<ng-container *ngIf="element?.user?.role!=='ADMIN'">
										<app-copy-to-clipboard [content]="element.user?.emailId" [overflowEllipsis]="true">
											{{element.user?.emailId}}
										</app-copy-to-clipboard>
										<button mat-icon-button color="primary" matTooltip="Click to Update Email">
											<mat-icon>edit</mat-icon>
										</button>
									</ng-container>
									<ng-container *ngIf="element?.user?.role==='ADMIN'">
										<app-avatar *ngIf="element?.user" [avatar]="element?.user">
										</app-avatar>
									</ng-container>
								</div>
							</div>
							<div *ngIf="element.editUserEmail">
								<mat-form-field class="full-width">
									<mat-label>Email ID</mat-label>
									<input #email="ngModel" required type="email" matInput placeholder="Enter Account Email"
										[(ngModel)]="element.newEmail">
								</mat-form-field>
								<button [disabled]="email.invalid" mat-stroked-button color="primary"
									(click)="update(element);$event.stopPropagation()">
									Update
									<app-loader [loaderId]="element.UUIDV4+'email'" [fgsSize]=32> </app-loader>
								</button>
								<button matTooltip="cancel" mat-icon-button color="warn"
									(click)="closeUpdateUserEmail(element);$event.stopPropagation()">
									<mat-icon>clear</mat-icon>
								</button>
							</div>

							<!-- Commented user listing as it might expose available user list to anyone with app access -->
							<!-- might be reused when we can handle it -->
							<!-- <mat-select placeholder="Allocate Advisor" [compareWith]="compareWith" [value]="element.user?.id"
								(selectionChange)="linkReportToUser(element,$event.value)">
								<mat-option *ngFor="let item of users" [value]="item">
									{{item.emailId}}
								</mat-option>
							</mat-select> -->
							<!-- end -->

						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="mobile">
						<mat-header-cell *matHeaderCellDef>
							Mobile Number
						</mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.mobile}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="cultureAdvisor">
						<mat-header-cell *matHeaderCellDef>
							Culture Advisor
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.cultureAdvisor |firstCap}}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="organisation">
						<mat-header-cell *matHeaderCellDef>
							Organisation
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.organisation |firstCap}}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="partnerSurvey">
						<mat-header-cell *matHeaderCellDef>
							Assessment Name
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element?.partnerSurvey?.name}}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="partnerSurveyOrganisation">
						<mat-header-cell *matHeaderCellDef>
							Assessment's Organisation
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-avatar *ngIf="element?.partnerSurvey?.org" [avatar]="element?.partnerSurvey?.org">
							</app-avatar>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="createdAt">
						<mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">
							Completed on
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.createdAt| date:'medium' }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="isPaid">
						<mat-header-cell *matHeaderCellDef>
							Payment
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<ng-container *ngIf="element.isPaid;else payButtonRef">
								<button mat-icon-button color="primary">
									<mat-icon matTooltip="Paid" color="primary">
										paid
									</mat-icon>
								</button>
								<ng-container [ngSwitch]="element?.transactions?.[0]?.by?.role">
									<ng-container *ngSwitchCase="'USER'">Paid by Own Credits</ng-container>
									<ng-container *ngSwitchCase="'ADMIN'">Paid by ShareTree</ng-container>
								</ng-container>
								<ng-container *ngIf="element?.transactions?.[0]?.meta?.type==='PAID_BY_USER'">
									Paid by User
								</ng-container>
							</ng-container>
							<ng-template #payButtonRef>
								<button mat-icon-button color="accent">
									<mat-icon matTooltip="Not Yet Paid" color="accent">
										paid
									</mat-icon>
								</button>
								<ng-container [ngSwitch]="user?.role">
									<ng-container *ngSwitchCase="'USER'">
										<button mat-stroked-button color="primary"
											(click)="openPayForReport(element,'PAY_BY_USING_CREDITS');$event.stopPropagation()">
											Pay by using Credits
										</button>
									</ng-container>
									<ng-container *ngSwitchCase="'ADMIN'">
										<button mat-stroked-button color="primary"
											(click)="openPayForReport(element,'PAY_BY_SHARETREE');$event.stopPropagation()">
											Pay by ShareTree
										</button>
									</ng-container>
								</ng-container>
							</ng-template>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="unpackSession">
						<mat-header-cell *matHeaderCellDef>
							Unpack with
						</mat-header-cell>
						<mat-cell *matCellDef="let element; let i = index;">
							<ng-container *ngIf="element.isUnpackPurchased">
								<mat-select placeholder="Allocate Advisor" [compareWith]="compareWith"
									[value]="element.unpackSession?.allocatedTo?.id"
									(selectionChange)="allocateUnpackSession(element,$event)">
									<mat-option *ngFor="let item of  element.advisors|async" [value]="item">
										<app-avatar [avatar]="item" [showEmail]="false">
										</app-avatar>
									</mat-option>
								</mat-select>
								<button [disabled]="!element.isUnpackSessionAllocated" matTooltip="Allocation Log" mat-icon-button
									color="primary" (click)="openAllocationLog(element)">
									<mat-icon>receipt</mat-icon>
								</button>
							</ng-container>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="actions-lg">
						<mat-header-cell *matHeaderCellDef>
							Actions
						</mat-header-cell>
						<mat-cell *matCellDef="let element">

							<button matTooltip="Open Preview In A New Tab" mat-icon-button color="accent"
								aria-label="Open Preview In A New Tab" (click)="openInNewTab(element,true);$event.stopPropagation()">
								<mat-icon>open_in_new</mat-icon>
							</button>
							<button matTooltip="Open In A New Tab" mat-icon-button color="primary" aria-label="Open In A New Tab"
								(click)="openInNewTab(element);$event.stopPropagation()">
								<mat-icon>open_in_new</mat-icon>
							</button>
							<button matTooltip="Download Preview" mat-icon-button class="mat-icon-loader-button" color="accent"
								aria-label="Download Preview" (click)="download(element,true);$event.stopPropagation()">
								<mat-icon>download</mat-icon>
								<app-loader [loaderId]="element.UUIDV4+'preview'" [fgsSize]=40></app-loader>
							</button>
							<button matTooltip="Download" mat-icon-button class="mat-icon-loader-button" color="primary"
								aria-label="Download" (click)="download(element);$event.stopPropagation()">
								<mat-icon>download</mat-icon>
								<app-loader [loaderId]="element.UUIDV4" [fgsSize]=40></app-loader>
							</button>
							<button matTooltip="Share Preview Report" mat-icon-button color="accent" aria-label="Share Preview Report"
								(click)="openShareReport(element,true);$event.stopPropagation()">
								<mat-icon>send </mat-icon>
							</button>
							<button matTooltip="Share Report" mat-icon-button color="primary" aria-label="Share Report"
								(click)="openShareReport(element);$event.stopPropagation()">
								<mat-icon>send </mat-icon>
							</button>
							<button *ngIf="user?.role==='ADMIN'" matTooltip="Move Report" mat-icon-button color="primary"
								aria-label="Move Report" (click)="openMigrateReport(element);$event.stopPropagation()">
								<mat-icon>drive_file_move </mat-icon>
							</button>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="singleComparison">
						<mat-header-cell *matHeaderCellDef>
							Single Comparison
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button matTooltip="Open Single Comparison" mat-icon-button
								[color]="element.selectedSingleComparisonGroupId?'primary':'accent'"
								(click)="openSingleComparison(element)">
								<mat-icon svgIcon="single_comparison" mat-list-icon></mat-icon>
							</button>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="dualComparison">
						<mat-header-cell *matHeaderCellDef>
							Dual Comparison
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button matTooltip="Open Dual Comparison" mat-icon-button
								[color]="element.selectedDualComparisonRoleId && element.selectedDualComparisonGroupId? 'primary':'accent'"
								(click)="openDualComparison(element);$event.stopPropagation()">
								<mat-icon svgIcon="dual_comparison" mat-list-icon></mat-icon>
							</button>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="expiresAt">
						<mat-header-cell *matHeaderCellDef>
							Assessment Expiry
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.expiresAt | date:'dd/MM/yyyy' }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="character360.responseRate">
						<mat-header-cell *matHeaderCellDef>
							Responses
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<ng-container *ngIf="element.character360 && element.character360.totalResponsesCount>-1">
								{{ element.character360?.totalResponsesCount }}
							</ng-container>
							<ng-container *ngIf="!!!element.character360">
								<ng-container *ngIf="today<=element.expiresAt ">
									Not Started
								</ng-container>
								<ng-container *ngIf="today>element.expiresAt ">
									Expired
								</ng-container>
							</ng-container>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="c360Actions">
						<mat-header-cell *matHeaderCellDef>
							Character 360 Actions
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button *ngIf="element.character360?.id  && today<=element.expiresAt" color="primary" mat-stroked-button
								(click)="openCharacter360(element)">
								{{element.character360?.totalResponsesCount===0?'Send Invites':'Track Progress'}}
							</button>
							<button *ngIf="element.character360?.id  && today>element.expiresAt" color="primary" mat-stroked-button
								(click)="openCharacter360(element)">
								Open Overview
							</button>
							<button *ngIf="!element.character360?.id && today<=element.expiresAt " color="primary" mat-raised-button
								(click)="openCharacter360(element)">
								Start Now
							</button>
							<ng-container *ngIf="element.character360?.id">
								<button [disabled]="element.character360.totalResponsesCount<3" matTooltip="Open In A New Tab"
									mat-icon-button color="primary" aria-label="link"
									(click)="openC360InNewTab(element.character360);$event.stopPropagation()">
									<mat-icon>open_in_new</mat-icon>
								</button>
								<button [disabled]="element.character360.totalResponsesCount<3" matTooltip="Download" mat-icon-button
									class="mat-icon-loader-button" color="primary" aria-label="link"
									(click)="downloadC360(element.character360);$event.stopPropagation()">
									<mat-icon>download</mat-icon>
									<app-loader *ngIf="element.character360" [loaderId]="element.character360?.UUIDV4"
										[fgsSize]=40></app-loader>
								</button>
							</ng-container>
						</mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectionChange($event,row)"
						[class.selected]="selection.isSelected(row)">
					</mat-row>
				</mat-table>
			</app-ng-scrollbar-wrapper>
		</div>
	</div>
</section>
