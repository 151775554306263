import {
	AfterViewInit,
	Component,
	EventEmitter,
	Inject,
	ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { uniqueId } from "lodash";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription, interval, merge, of as observableOf } from "rxjs";
import {
	catchError,
	debounce,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
	tap
} from "rxjs/operators";
import { LOG_STATUS_MAP, LOG_TYPE_STATUSES } from "../../const/log";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { LogService } from "../../services/log.service";
import { ACTIVITY_OF, Log } from "../../types/log";
import { AuthService } from "../../services/auth.service";

@Component({
	selector: "app-activity-logs",
	templateUrl: "./activity-logs.component.html",
	styleUrls: ["./activity-logs.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class ActivityLogsComponent implements AfterViewInit {
	subscriptions = new Subscription();
	index?: number = 10;
	logKeyLabelMap: any;
	loaderId = uniqueId("loader_");
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	refreshEvent = new EventEmitter();
	filterFormControl: FormControl = new FormControl();
	pageSize = 10;
	data: Log[] = [];
	resultsLength = 0;
	isLoadingResults = false;
	displayedColumns: string[] = ["index", "activity", "by", "createdAt"];
	LOG_STATUS_MAP = LOG_STATUS_MAP;
	LOG_TYPE_STATUSES = LOG_TYPE_STATUSES;

	constructor(
		private UiLoaderService: NgxUiLoaderService,
		public logService: LogService,
		public router: Router,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		public authService: AuthService
	) {}
	get name() {
		return (
			this.dialogData?.partnerSurvey?.name ||
			this.dialogData?.report?.name ||
			this.dialogData?.groupReport?.name ||
			this.dialogData?.organisation?.name
		);
	}
	get activityOf(): ACTIVITY_OF {
		return this.dialogData.activityOf;
	}
	ngAfterViewInit() {
		// If the user changes the sort order, reset back to the first page.
		this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		merge(
			this.sort?.sortChange,
			this.paginator?.page,
			this.filterFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				tap(() => {
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
				}),
				distinctUntilChanged()
			),
			this.refreshEvent
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					setTimeout(() => {
						this.isLoadingResults = true;
					}, 0);
					return this.logService!.getLogs({
						offset: this.paginator.pageIndex * this.paginator.pageSize,
						limit: this.paginator.pageSize,
						sortingKey: this.sort.active,
						sortBy: this.sort.direction,
						searchText: this.filterFormControl.value || undefined,
						orgId: this.dialogData?.org?.id,
						partnerSurveyId: this.dialogData?.partnerSurvey?.id,
						reportId: this.dialogData?.report?.id,
						groupReportId: this.dialogData?.groupReport?.id,
						byId: this.dialogData?.by?.id
					}).pipe(catchError(() => observableOf(null)));
				}),
				map(data => {
					// Flip flag to show that loading has finished.
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
					setTimeout(() => {
						this.isLoadingResults = false;
					}, 0);
					if (data === null) {
						return [];
					}
					this.resultsLength = data.total;
					this.logKeyLabelMap = data.logKeyLabelMap;
					return data.list;
				})
			)
			.subscribe(data => (this.data = data));
	}
}
