import { OrganisationService } from "./../../services/organisation.service";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ReportService } from "src/app/_shared/services/report.service";
import { COST_PER_ASSESSMENT } from "../../const/organisation";
import { getOrgTypeDiscount } from "../../pipes/org-type-discount.pipe";

@Component({
	selector: "app-pay-for-report",
	templateUrl: "./pay-for-report.component.html",
	styleUrls: ["./pay-for-report.component.scss"]
})
export class PayForReportComponent {
	org?: any;
	constructor(
		private organisationService: OrganisationService,
		public UiLoaderService: NgxUiLoaderService,
		public dialogRef: MatDialogRef<PayForReportComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private reportService: ReportService
	) {
		this.getOrg();
	}
	get costPerAssignmentAfterDiscount() {
		if (!this.org) return COST_PER_ASSESSMENT;
		return Math.floor(
			COST_PER_ASSESSMENT - COST_PER_ASSESSMENT * this.discountRate
		);
	}
	get discountRate() {
		return getOrgTypeDiscount(this.org.orgType);
	}
	getOrg() {
		this.org = null;
		if (
			this.data.by === "PAY_BY_USING_CREDITS" &&
			this.data.report?.partnerSurvey?.orgId
		) {
			this.organisationService
				.getOrg(this.data.report.partnerSurvey.orgId)
				.subscribe((res: any) => {
					this.org = res.org;
				});
		}
	}
	payForReport() {
		this.UiLoaderService.start();
		this.reportService
			.payForReport({
				reportId: this.data.report.id
			})
			.subscribe(
				(res: any) => {
					this.UiLoaderService.stop();
					this.dialogRef.close({ ...res.report });
				},
				err => {
					this.UiLoaderService.stop();
				}
			);
	}
}
