import { Component, Inject, Optional } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AccountService } from "../../services/account.service";
@Component({
	selector: "app-invite",
	templateUrl: "./invite.component.html",
	styleUrls: ["./invite.component.scss"]
})
export class InviteComponent {
	inviteFG?: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		@Optional() public dialogRef: MatDialogRef<InviteComponent>,
		private _snackBar: MatSnackBar,
		public accountService: AccountService,
		private fb: FormBuilder,
		private UiLoaderService: NgxUiLoaderService
	) {
		this.inviteFG = this.fb.group({
			email: [, Validators.required]
		});
		this.inviteFG.patchValue(dialogData.invite);
	}

	patch() {
		if (this.inviteFG?.valid) {
			this.UiLoaderService.start();
			let payload = this.inviteFG?.value;
			this.accountService
				.updateInvite(this.dialogData?.invite.id, payload)
				.subscribe(
					(res: any) => {
						this._snackBar.open("Invite Updated");
						this.dialogRef.close({ refresh: true });
						this.UiLoaderService.stop();
					},
					err => {
						this.UiLoaderService.stop();
					}
				);
		}
	}
}
