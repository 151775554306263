import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LogQueryParams } from "../types/log";
import { removeUndefined } from "../utils/object";

@Injectable({
	providedIn: "root"
})
export class LogService {
	baseURL = environment.baseUrl;
	constructor(public http: HttpClient) {}
	getLogs(queryParams: LogQueryParams): Observable<any> {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...queryParams,
					sortBy: queryParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		const requestUrl = `${this.baseURL}/log`;
		return this.http.get<any>(requestUrl, { params });
	}
}
