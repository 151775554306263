import { Directive, Input, ElementRef, HostListener } from "@angular/core";
import { PROFILE_TYPE } from "../types/common";

@Directive({
	selector: "img[appImgFallback]"
})
export class ImgFallbackDirective {
	@Input() appImgFallback!: PROFILE_TYPE | "";

	constructor(private eRef: ElementRef) {}

	@HostListener("error")
	loadFallbackOnError() {
		const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
		element.src = this.getFallbackImage();
	}
	getFallbackImage() {
		switch (this.appImgFallback) {
			case "USER_PROFILE":
				return "/assets/images/avatar.jpg";
			case "GROUP_PROFILE":
				return "/assets/images/group-logo-placeholder.png";
			case "STEP_GROUP_PROFILE":
				return "/assets/step_group.svg";
			case "STEP_USER_PROFILE":
				return "/assets/images/avatar.jpg";
			case "GROUP_SECONDARY_PROFILE":
				return "/assets/images/group-logo-placeholder-2.png";
			default:
				return "/assets/images/avatar.jpg";
		}
	}
}
