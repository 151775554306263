<mat-form-field *ngIf="disabled">
	<input placeholder="Assessment" matInput [value]="partnerSurveyCtrl?.value?.name" readonly>
</mat-form-field>
<mat-form-field [class.hidden]="disabled">
	<mat-select [formControl]="partnerSurveyCtrl" placeholder="Assessment" #singleSelect [compareWith]="compareFn">
		<mat-option>
			<ngx-mat-select-search [formControl]="partnerSurveyFilterCtrl" [noEntriesFoundLabel]="'No matching Found'"
				placeholderLabel="Search Assessment">
			</ngx-mat-select-search>
		</mat-option>
		<mat-option *ngIf="showAll">All</mat-option>
		<mat-option *ngFor="let partnerSurvey of filteredPartnerSurveys | async" [value]="partnerSurvey">
			{{partnerSurvey.name}} <ng-container *ngIf="!(this.selectedOrg?.id || this.org?.id )&& partnerSurvey.isPublic"> -
				{{partnerSurvey?.org?.name}}</ng-container>
		</mat-option>
	</mat-select>
</mat-form-field>