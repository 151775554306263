import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable({
	providedIn: "root"
})
export class RouteService {
	constructor(private router: Router, private activatedRoute: ActivatedRoute) {}
	updateRouteParams(params: { [key: string]: string | number }) {
		let commands = [];
		let actRoute: any = this.activatedRoute;
		while (actRoute) {
			let path: string = actRoute?.routeConfig?.path;
			if (path && path.startsWith(":")) {
				let key = path.substring(1);
				if (key in params) {
					commands.push(params[key]);
					actRoute = actRoute?.firstChild;
					continue;
				}
			}
			let urlSegment = actRoute?.snapshot?.url?.join();
			if (urlSegment) {
				commands.push(urlSegment);
			}
			actRoute = actRoute?.firstChild;
		}
		this.router.navigate(commands, {
			relativeTo: this.activatedRoute.parent,
			queryParamsHandling: "preserve"
		});
	}
}
