<footer class='ca-footer' [class.only-on-print]="onlyOnPrint">
    <span class="ca-bar">
        <span *ngIf="!hideCopyRights" class="ca-footer-copyright-text">
            © sharetree.org
        </span>
    </span>
    <span class="ca-image">
        <img height="100%" width="100%" src="/assets/images/rings-gfx.png">
        <span *ngIf="pageNo" class="ca-page-number">
            {{pageNo}}
        </span>
    </span>


</footer>