import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

@Component({
	selector: "app-aspirations",
	templateUrl: "./aspirations.component.html",
	styleUrls: ["./aspirations.component.scss"]
})
export class AspirationsComponent {
	@Input() aspirations!: FormArray;
	primaryIndices: number[] = [0, 1, 2];
	secondaryIndices: number[] = [3, 4, 5, 6];
	getAspirationAt(i: number) {
		return this.aspirations.at(i) as FormGroup;
	}
	constructor(public fb: FormBuilder) {}
}
