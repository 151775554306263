<section id="aboutCS&C" class="ca-character-descriptions">
	<app-anchor anchorId="ACS&C"></app-anchor>
	<div class="ca-page-break-before ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-page-title title="About Character Strengths & Charametrics">
			</app-page-title>
			<br>
			<p> ShareTree Charametrics is a uniquely designed assessment tool to assess the character strengths of a
				person
				effectively and accurately and how they may fit into a described relationship, team culture and/or
				specific
				role. <b> ShareTree Charametrics assessment</b> is a comparison of the character strengths of a person
				(Characteristics)
				and a set aspirational values or character traits (Metrics of measure). The combination of this
				comparison
				forms ‘Chara’ – ‘Metrics’.
				<br> <br>
				ShareTree conducted research and discovered patterns between globally recognised theories and sciences
				of
				human evolution and development. This research revealed a correlation to seven distinct phases of growth
				that human beings evolve through over the course of their lives. ShareTree describes these as the <b>‘7
					Layers
					of Evolution’.</b> Each person seeks the elements, and corresponding feelings in each layer of
				evolution through
				a professional, social and/or spiritual context. These layers also directly translate into the <b>‘7
					Layers of
					Team Culture and Engagement’</b>.
				<br> <br>
				ShareTree mapped an association between 120-character traits to the 7 layers of evolution and
				additionally
				evaluated if each character trait is mostly moral or performance in nature. This mapping and research
				formed
				12 distinct character groups that are associated to the 7 layers of human evolution.
			</p>
			<div class="ca-images">
				<img width="100%" height="auto" src="/assets/images/cultural-layers-and-character-traits-120.png">
			</div>
			<div class="ca-header">
				At the core of every person’s achievement resides specific character traits!
			</div>
			<div class="ca-link-container">
				<div class="ca-link-button">
					<div>
						See all character trait descriptor cards:&nbsp; <b> sharetree.org/<span class="primary-color">
								character-cards
							</span>
						</b>
					</div>
					<a class="ca-link-action-button" target="_BLANK" href="https://sharetree.org/character-cards/">Click
						here
						<mat-icon svgIcon="arrow"></mat-icon>
					</a>
				</div>
				<div class="ca-link-button">
					<div>
						Or download the ShareTree App : &nbsp; <b>sharetree.org/<span class="primary-color">sharetree-app</span></b>
					</div>
					<a class="ca-link-action-button" target="_BLANK" href="https://sharetree.org/sharetree-app/">Download
						<mat-icon svgIcon="download-custom"></mat-icon> </a>
				</div>
			</div>
		</div>
		<app-page-footer [pageNo]="'ACS&C'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
	<div class="ca-page-break-before ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-page-title title="About Character Strengths & Charametrics">
			</app-page-title>
			<p> Character resides at the inner core of consciousness and is the director of the outer layers of
				identity,
				beliefs, capability, behaviours, and others’ perceptions.
				<br> <br>Character trait strength combinations create a unique blueprint which determine how effective a
				person
				can
				be at actualising their outer layers. Other identity, psychometric, performance, emotional, behavioural
				and
				culture assessment tools provide great insight into how a person identifies, thinks, performs, and
				behaves. Whereas, Charametrics provides clarity about character strengths which give insight into the core
				makeup
				and
				how effective the outer layer functions can be in alignment to a certain culture or role.
			</p>
			<br>
			<div class="ca-images ca-side-by-side">
				<img width="100%" height="auto" src="/assets/images/st-offered-services.png">
			</div>
			<br>
			<p>
				Because it takes much longer to build character than it does competency and behaviours, and some may
				even argue that character traits are pre-defined.
				It is important that we match roles and engagements to character strengths so that a person has the best chance
				to thrive within a role and/or team culture.
				<br> <br>
				Better alignment of character strengths to roles and team cultures, means greater fulfilment which leads
				to
				higher performance and greater talent retention.
			</p>
		</div>
		<app-page-footer [pageNo]="'ACS&C@2'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>

	<div class="ca-page-break-before ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-anchor anchorId="HtIR"></app-anchor>
			<app-page-title title="How to Interpret Results">
			</app-page-title>
			<p>
				This profile will provide insights into your evolutionary focus based on the stage of life you’re in.
				Every
				phase of life brings different focuses as a major phase with a secondary focus from a sub phase within
				the
				major phase. The table below generally provides an insight into these phases that play out uniquely to
				each
				of us based on our personal experience and the context of our life.
			</p>
			<table class="table">
				<tbody>
					<tr>
						<th class="bg-light-gray-dark"> Age</th>
						<th class="bg-light-gray-dark"> Sub Phase</th>
						<th class="bg-light-gray-dark" colspan="2"> Major Phase</th>
					</tr>
					<tr *ngFor="let age of ages; let i=index">
						<td class="ca-bold ca-text-align-center bg-light-gray-1" [class.ca-border-bottom]="i%7===6">
							{{age}}
						</td>
						<td [class.ca-border-bottom]="i%7===6" class="bg-light-gray-1">{{phases[i%7]}} </td>
						<td [ngClass]="['ca-bold','ca-border-bottom','ca-fs-md','ca-text-align-center', 'color-'+i/7]"
							*ngIf="i%7===0" rowspan="7">
							{{phases[i/7]}} <br>
							{{phasesTag[i/7]}} </td>
						<td *ngIf="i%7===0" [ngClass]="['co	lorfixed','ca-border-bottom', 'color-strong-'+i/7]" rowspan="7">
						</td>
					</tr>
					<tr>
						<td class="ca-text-align-center ca-border-bottom ca-bold bg-light-gray-1">49+</td>
						<td class="ca-border-bottom bg-light-gray-1">{{phases[7]}} </td>
						<td class="ca-border-bottom ca-text-align-center ca-bold color-7" style="padding: 8px;" colspan="2">
							{{phases[7]}} <br>
							{{phasesTag[7]}} </td>
					</tr>
			</table>
		</div>
		<app-page-footer [pageNo]="'HtIR'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>

	<div class="ca-page-break-before ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-page-title title="How to Interpret Results">
			</app-page-title>
			<p>
				All stages are connected and constantly evolving with deeper insight from new knowledge acquired in the
				current phase. The breadth and depth of the growth is dependent on awareness, exploration, and ability to bounce
				back
				from challenges. A ShareTree Certified Culture Specialist can unpack and provide further insight into
				the
				phases and their meaning, or you can find out more from our online content.
			</p>
			<ol class="custom-list-marker">
				<li> <span class="ol-head primary-color"> <b> Ways of Being Valued Preference </b></span>- The ‘5 Ways
					of Being
					Valued’
					provides insight into how a person
					prefers
					to be appreciated and valued. This is at the core of team culture and can be used as a general
					strategy
					to
					engage this person. In one-to-one interactions, the leaders should still use their personal
					knowledge to
					engage in a manner appropriate to each individual. This facilitates greater engagement and provides
					clarity
					of team preferences for planning activities and events.
				</li>
				<br>
				<li>
					<span class="ol-head primary-color"> <b> Life & Team Culture Focus Preference </b></span>–
					This indicates a person’s greatest needs from both in life and team culture in order of preference
					from all 7 layers.
					This information is best coupled in reference to the ShareTree team Culture Engagement Survey results to
					evaluate if the organisational culture is strong in the desired preference layers. The desired layer sequence
					can also give further insight into the current evolution phase focus.
				</li>
				<br>
				<li>
					<span class="ol-head primary-color"> <b> Individual Character Strengths vs Global Average</b></span>
					– While
					each of
					us bring a unique set of character
					strengths, this segment of the assessment provides insight into the individual’s character strengths
					in
					comparison to a global average of ShareTree character strengths data. It indicates the ratio of this
					person’s
					character strengths compare against the ShareTree global user strengths average. Generally, when a
					person
					exhibits a strength that is above the average of their team, it will become a strength that others
					will
					look
					up to in this person.
				</li>
			</ol>
			<br>
			Charametrics is designed to highlight character strengths and does not mean a person’s indicated
			strengths
			leaves weaknesses in other areas. The report demonstrates which strength a person uses to have an
			impact
			in
			their team and life. Areas below the global average are areas where the person is not projecting a
			strength
			and does not necessarily mean there are voids in these areas.
			<div class="ca-chart-contents">
				<div class="ca-annotations">
					<span class="ca-annotation" style="width: 102px;left: 237px;
                    top: 118px;">
						<span class="ca-annotation-mark" style="width: 38px;
                            top: 12px;
                            left: -38px;
                            transform: rotate(356deg);">
							<img src="/assets/svg/rounded-arrow.svg" alt="">
						</span>
						Global Average Line
					</span>
					<span class="ca-annotation" style="
                        width: 102px;
    left: 597px;
    top: 111px;
    ">
						<span class="ca-annotation-mark" style="
width: 40px;
    top: 0px;
    left: -39px;
    transform: rotate(356deg);">
							<img src="/assets/svg/rounded-arrow.svg" alt="">
						</span>
						Global Average Line
					</span>
					<span class="ca-annotation" style="left: 0px;top: 285px;width: 305px;text-align: left;">
						A score of 0.00 means the strength was never selected in every case
					</span>
					<span class="ca-annotation" style="right: 0px;top: 285px;width: 185px;text-align: left;">
						<span class="ca-annotation-mark" style="    width: 63px;
                            top: -61px;
                            left: 48px;
                            transform: rotate(37deg);">
							<img src="/assets/svg/rounded-arrow.svg" alt="">
						</span>
						1.91 times higher than the Global Average
					</span>
					<span class="ca-sample-watermark" style="left: 0px;
                                top: 264px;
                                width: 185px;
                                text-align: left;
                            ">
						Sample Data Only
					</span>
				</div>
				<div class="ca-page ca-character-strengths-report">
					<div class="ca-chart-ICSVsGA">
						<app-ics-vs-ga [showTooltip]="false" [report]="sample">
						</app-ics-vs-ga>
					</div>
				</div>
			</div>

		</div>
		<app-page-footer [pageNo]="'HtIR@2'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
	<div class="ca-page-break-before ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<div class="ca-header">
				Character Strength Distribution
			</div>
			<br>
			This section indicates a person’s distribution of a total 100
			percent available strength. It provides insight into the following three segments:
			<ol type="A" class="allgreen-list">
				<li> Strengths – Personal reflection</li>
				<li> Strengths – In a team</li>
				<li> The average strength between the above two indicators</li>
			</ol>
			This provides insight into how a person may be flexing or contracting their character strengths
			within a
			team scenario. Because there is only a maximum allocation of 100%, it requires the person to
			indicate
			which strengths are most used to achieve outcomes in work and life and whether they have a balanced
			approach or flexing and contracting in certain areas. Flexing or contracting strengths is effective
			when
			it is done in a wilfully aware way. It can present challenges when it occurs in compromise of one’s
			truth and natural strength.

			<div class="ca-chart-contents">
				<div class="ca-annotations">
					<span class="ca-annotation" style="
                            width: 112px;
    left: 50%;
    top: 112px
;
    transform: translate(-50%, -50%);">
						<span class="ca-annotation-mark" style="
                             width: 25px;
    top: 3px;
    left: -24px;">
							<img src="/assets/svg/rounded-arrow.svg" alt="">
						</span>
						Contracting a Strength
					</span>
					<span class="ca-annotation" style="
                        width: 112px;
    left: 50%;
    top: 163px;
    transform: translate(-50%, -50%);">
						<span class="ca-annotation-mark" style="width: 65px;
                        top: -26px;
                        left: -66px;
                            transform: rotate(350deg);">
							<img src="/assets/svg/rounded-arrow.svg" alt="">
						</span>
						Congruent Strength
					</span>
					<span class="ca-annotation" style="
                        width: 112px;
    left: 602px;
    top: 247px;transform: translate(-50%, -50%)">
						<span class="ca-annotation-mark" style="width: 50px;
                            top: -29px;
                            left: 95px;
                        transform: rotate(94deg);">
							<img src="/assets/svg/rounded-arrow.svg" alt="">
						</span>
						Flexing a Strength
					</span>
					<span class="ca-sample-watermark" style="left: 0px;
                            top: 363px;
                            width: 185px;
                            text-align: left;
                        ">
						Sample Data Only
					</span>
				</div>
				<div class="ca-page ca-character-strengths-report">
					<div class="ca-chart-csd">
						<app-csd [showTooltip]="false" [report]="sample"></app-csd>
					</div>
				</div>
			</div>
			<br>

			<!-- <div class="ca-images ca-side-by-side">
                        <img width="100%" height="auto" src="/assets/images/sample-2.png">
                    </div> -->
			<span class="primary-color "> <b> Contracting Strengths </b></span> If a person is contracting a strength in
			a team scenario, it can be
			an
			indicator
			that they are not truly stepping fully into their strengths and may be a result of what is known as
			‘playing small’. Contracting strengths can occur for a range of reasons that need to be explored. It
			can
			also be that a person is willingly flexing in another area to serve a current need or not utilising
			this strength because it is not required or less valued in the team scenario.
			<br> <br>
			<span class="primary-color "> <b> Flexing Strengths </b></span> – If a person is flexing a strength in a
			team scenario, it can be an
			indicator
			that
			they are putting focused emphasis on this strength for growth or results. Flexing a strength
			wilfully is
			an indicator of character strength flexibility. Note that flexing a strength always creates a
			contraction of another natural strength. It is effective to explore if the strengths being flexed
			are
			serving a purpose and if the effort is sustainable.
			<br> <br>
			<span class="primary-color "> <b> Strength Congruence </b></span> – When the personal strength is in
			congruence with the team strength, it
			indicates
			that the person is working in balance and natural flow. The areas of highest strengths that are also
			in
			congruence are the most effective for flow, performance, and sustainability.
			<br> <br>
			<span class="primary-color "> <b> Overall Balance </b></span> One should also observe the balance between
			the overall strength’s activity
			between
			the Moral and Performance segments. Additionally, noticing which side is flexing or contracting
			most.
			This is a good indicator if a person is focusing more on performance or moral aspects.
		</div>
		<app-page-footer [pageNo]="'HtIR@3'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
	<div class="ca-page-break-before ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<div class="ca-header">
				Culture Fit
			</div>
			<br>
			This is the heart of the Charametrics reporting. With understanding about
			the natural
			character strengths of a person, we can couple this information with the aspirational culture of a
			team
			or the desired characteristics of a specific role.
			<br> <br>
			Culture Fit score indicates the percentage of alignment this person is to the Organisation Culture
			Aspiration or specific Desired Role Characteristics. Having clarity of culture aspiration or role
			characteristics is very important to this information being accurate. Clearly seeing what you aspire
			to
			attract is essential to validating Culture/Role Fit.
			<br><br>
			<div class="ca-report-content" style="margin-bottom: 64px;">
				<div class="ca-annotations">
					<span class="ca-annotation" style="width: 112px;
                    left: 241px;
                    top: 288px;
                    transform: translate(-50%, -50%);">
						<span class="ca-annotation-mark" style="    width: 58px;
                        top: -62px;
                        left: 24px;
                            transform: rotate(62deg);">
							<img src="/assets/svg/rounded-arrow.svg" alt="">
						</span>
						Filling the Aspiration
					</span>
					<span class="ca-sample-watermark" style="
                        left: 0px;
    top: 269px;
    width: 185px;
    text-align: left;
                        ">
						Sample Data Only
					</span>
				</div>
				<div class="ca-chart-contents">
					<div class=" ca-page ca-character-strengths-profile">
						<div class="ca-chart-csd">
							<app-cs-profile [showTooltip]="false" [report]="sample" [group]="group"></app-cs-profile>
						</div>
					</div>
				</div>
			</div>
			<br>
			The sample person will have a strong fit to the organisational culture or job role.
			The Aspiration is the desired Culture/Role characteristics profile, which can be formed via three
			different
			ways in order of accuracy:
			<ol type="A" class="allgreen-list">
				<li> ShareTree App data from an active and measured organic culture</li>
				<li> Culture or Characteristic Workshop run by a ShareTree Certified Culture Specialist</li>
				<li> Client Supplied values from the organisation’s own described values set</li>
			</ol>
			<br>
			This is made up of 3 primary and 4 secondary characteristics from a set of 120 character and culture
			traits. This information creates the aspirational profile to which the individual’s characteristics
			are
			overlayed in a fit comparison.
		</div>
		<app-page-footer [pageNo]="'HtIR@4'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
	<div class="ca-page-break-before ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<div class="ca-header">
				Characteristics Strengths Alignment
			</div>
			<br>
			This provides a detailed view of the individual’s
			characteristics
			profile and how it shows up in the team culture or desired role characteristics.

			Because character strengths are fluid between moral and performance qualities, the ‘Impact by the 7
			Layers’ provides a consolidated view of both moral and performance character group strengths
			combined.
			This is the greatest indicator about where this person will have an impact on the culture compared
			to
			the aspiration or fit for a described role.
			<br> <br>
			This information is best coupled with ShareTree App Monthly Culture Reporting data to better
			understand
			the areas where the culture has desired growth and where it already is at the desired state.
			<br>
			<br>
			<div class="ca-report-content" style="margin-bottom: 12px;">
				<div class="ca-annotations">
					<span class="ca-annotation" style="width: 85px;right: -6px;top: 503px;transform: translate(-50%, -50%);">
						<span class="ca-annotation-mark" style="width: 56px;top: -20px;left: -58px;transform: rotate(353deg);">
							<img src="/assets/svg/rounded-arrow.svg" alt="">
						</span>
						Exceeding the Aspiration
					</span>

					<span class="ca-sample-watermark" style="left: 1px;
                    top: 647px;
                    width: 185px;
                    text-align: left;">
						Sample Data Only
					</span>
				</div>
				<div class="ca-chart-contents">
					<div class=" ca-page ca-character-strengths-profile">
						<div class="ca-chart-cs-alignment-by-group">
							<app-cs-alignment-by-group [isSample]="true" [showTooltip]="false" [report]="sample"
								[group]="group"></app-cs-alignment-by-group>
						</div>
						<div class="ca-chart-cs-alignment-by-layers">
							<app-cs-alignment-by-layers [isSample]="true" [showTooltip]="false" [report]="sample" [group]="group"
								[showGroupAlignment]="true"></app-cs-alignment-by-layers>
						</div>
					</div>
				</div>
			</div>
			The addition of this person (from the Sample above) into a team will create culture growth in the
			following layers :
			<ul class="no-bullets allgreen-list" style="padding-inline-start: 20px;margin-block-start: 4px;">
				<li> Purposefulness and Clarity</li>

				<li> Hope and Wisdom</li>

				<li> Love and Compassion</li>
			</ul>
			An overall assessment is required to determine if the person’s characteristics is in alignment to
			the
			culture strategy or specific desired role characteristics.
		</div>
		<app-page-footer [pageNo]="'HtIR@5'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
</section>