import { Component, Input } from "@angular/core";

@Component({
	selector: "app-copy-to-clipboard",
	templateUrl: "./copy-to-clipboard.component.html",
	styleUrls: ["./copy-to-clipboard.component.scss"]
})
export class CopyToClipboardComponent {
	@Input() content!: string;
	@Input() overflowEllipsis = false;
	constructor() {}
}
