export const environment = {
	production: true,
	version: require("../../package.json").version,
	baseUrl: "https://stcaapi.sharetree.org",
	stepBaseUrl: "https://stepapi.sharetree.org",
	stripePublicKey: "pk_live_tILpljVEAnoxcbKLGvH9EmhI004BWrtVjr",
	IPInfoToken: "9408a461a0730c",
	defaultSurvey: "c6l-1wp-9d4-ma7",
	recaptcha: {
		siteKey: "6Le97JMUAAAAAE5kQuczw6le-SCSqfDNqaxo5cLv"
	},
	google: {
		clientId:
			"943838639478-b0e3eod8r56um4h5bfe2lhnml4k4211n.apps.googleusercontent.com"
	},
	microsoft: {
		clientId: "1cafcc94-b554-4297-9ed3-c3b930de8964",
		tenantId: "9efee338-8e96-4a02-b0f2-dd2cb0aa5f58"
	},
	// API key for Browser data source in Amplitude (Not same as in Server)
	amplitudeApiKey: "2193c22cdc1187df8648d7f219a6c6f9"
};
