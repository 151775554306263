<div [class]="handleClasses" *ngIf="step;else OverallTRef">
	<div class="handle-indicator">
		<app-handle-indicator [n]="count"></app-handle-indicator>
	</div>
	<!-- <div class="handle-hint" *ngIf="showStatus && !isExpired" [ngClass]="{'red': isRed,'orange':isOrange }">
		<ng-container *ngIf="count>0 && count<3">
			Need {{3-count}} more
		</ng-container>
		<ng-container *ngIf="count===0">
			No Responses Yet
		</ng-container>
	</div> -->
	<div class="handle-hover-container" matTooltip="{{step.name}} are people {{step.description}}"
		[matTooltipPosition]="step.tooltipPosition" matTooltipClass="ca-tooltip-lg">
		<div class="handle">
			<span class="handle-name"> <b> {{step.name}}</b></span>
			<span class="handle-status" *ngIf="showStatus">
				<ng-container *ngIf="count>0;else noResponsesYetTRef">
					{{count}} response{{count>1?'s':''}}
				</ng-container>
				<ng-template #noResponsesYetTRef>
					!
				</ng-template>
			</span>
		</div>
	</div>
	<div class="share-buttons" *ngIf="showActions && count<MAX_LIMIT && !isExpired">
		<div [class]="['share-button',step?.id]" [class.specific-sharebutton-positions]="!isCommonShareButtonPositions"
			(click)="openEmailClient(step)">
			<button color="primary" class="mini-fab email" matTooltip="Send Email" matTooltipPosition="below"
				matRipple><mat-icon svgIcon="email"></mat-icon></button>
		</div>
		<div [class]="['share-button',step?.id]" [class.specific-sharebutton-positions]="!isCommonShareButtonPositions"
			(click)="sendSMS(step)">
			<button color="primary" class="mini-fab sms" matTooltip="Send SMS" matTooltipPosition="below" matRipple><mat-icon
					svgIcon="sms"></mat-icon></button>
		</div>
		<div [class]="['share-button',step?.id]" [class.specific-sharebutton-positions]="!isCommonShareButtonPositions"
			(click)="copyToClipboard(step)">
			<button color="primary" class="mini-fab copy" matTooltip="Copy Message Template to Clipboard"
				matTooltipPosition="below" matRipple><mat-icon svgIcon="content_copy"></mat-icon></button>
		</div>
		<div [class]="['share-button',step?.id]" [class.specific-sharebutton-positions]="!isCommonShareButtonPositions"
			(click)="copyLinkToClipboard(step)">
			<button color="primary" class="mini-fab copy-link" matTooltip="Copy Link to Clipboard" matTooltipPosition="below"
				matRipple><mat-icon>link</mat-icon></button>
		</div>
	</div>
</div>
<ng-template #OverallTRef>
	<div class="handle-container overall" [ngClass]="{'active': !currentStep || highlightOverall}">
		<!-- <div class="handle-hint " *ngIf="showStatus" [ngClass]="{'red': isOverallRed,'orange':isOverallOrange }">
			<ng-container *ngIf="totalCount>0 && totalCount<3">
				Need {{3-totalCount}} more
			</ng-container>
			<ng-container *ngIf="totalCount===0">
				No Responses Yet
			</ng-container>
		</div> -->
		<div class="handle overall">
			<span class="handle-name"><b> Your Character 360</b></span>
			<span class="handle-user-name">
				<ng-container *ngIf="character360?.name">
					{{character360?.name}}
				</ng-container>
			</span>
		</div>
	</div>
</ng-template>
