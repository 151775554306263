import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Inject,
	OnInit,
	TemplateRef,
	ViewChild
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import { MatChipList } from "@angular/material/chips";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { interval, merge, of as observableOf } from "rxjs";
import {
	catchError,
	debounce,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
	tap
} from "rxjs/operators";
import { LoggerService } from "src/app/_shared/services/logger.service";
import { validateChips } from "../../form/validator/validate-chips";
import { AuthService } from "../../services/auth.service";
import {
	ConfirmDialogComponent,
	ConfirmDialogModel
} from "../confirm-dialog/confirm-dialog.component";
import { InviteComponent } from "../invite/invite.component";
import { AccountService } from "./../../services/account.service";
import { OrganisationService } from "./../../services/organisation.service";
@Component({
	selector: "app-organisation-invite",
	templateUrl: "./organisation-invite.component.html",
	styleUrls: ["./organisation-invite.component.scss"]
})
export class OrganisationInviteComponent implements OnInit, AfterViewInit {
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	@ViewChild("chipList", { static: false }) chipList!: MatChipList;
	@ViewChild("message") messageRef!: TemplateRef<any>;
	orgFormGroup?: FormGroup;
	results: any[] = [];
	emails: string[] = [];
	constructor(
		public dialogRef: MatDialogRef<OrganisationInviteComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		public Logger: LoggerService,
		private UiLoaderService: NgxUiLoaderService,
		private accountService: AccountService,
		public authService: AuthService,
		private fb: FormBuilder,
		private organisationService: OrganisationService,
		private _dialog: MatDialog,
		private _snack: MatSnackBar
	) {
		this.orgFormGroup = this.fb.group({
			id: [, Validators.required],
			role: ["MEMBER", Validators.required],
			membersToBeInvited: [[], [validateChips, Validators.required]],
			typeOf: []
		});
	}
	loaderId = "org-members-loader";
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	refreshEvent = new EventEmitter();
	filterFormControl: FormControl = new FormControl();
	data: any[] = [];
	resultsLength = 0;
	pageSize = 10;
	isLoadingResults = false;
	displayedColumns = [
		"index",
		"email",
		"status",
		"role",
		"actions",
		"from",
		"createdAt",
		"actionedBy",
		"actionedAt"
	];
	get fg() {
		return this.orgFormGroup;
	}
	get membersToBeInvited() {
		return this.orgFormGroup?.get("membersToBeInvited");
	}
	ngOnInit(): void {
		if (this.dialogData.org.id) {
			this.orgFormGroup?.patchValue({
				id: this.dialogData.org.id,
				typeOf: this.dialogData.typeOf
			});
		}
		if (this.dialogData.typeOf === "CLIENT") {
			this.displayedColumns = [
				"index",
				"email",
				"status",
				"actions",
				"forOrganisation",
				"seedingRate",
				"seedingEndAt",
				"from",
				"createdAt",
				"actionedBy",
				"actionedAt"
			];
		}
	}
	cancel() {
		this.reset();
	}
	reset() {
		this.membersToBeInvited?.setValue([]);
		this.membersToBeInvited?.markAsUntouched();
	}
	send() {
		let payload = {
			...this.orgFormGroup?.value
		};
		this.emails = payload.membersToBeInvited;
		this.reset();
		this.UiLoaderService.start();
		this.organisationService.inviteMembers(payload).subscribe(res => {
			if (res.statusCode === 200) {
				this.results = res.result;
				this.refreshEvent.emit();
				this._snack.open("Partner invite sent successfully.");
			}
			this.UiLoaderService.stop();
		});
	}

	ngAfterViewInit() {
		// If the user changes the sort order, reset back to the first page.
		this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		merge(
			this.sort?.sortChange,
			this.paginator?.page,
			this.filterFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				tap(() => {
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
				}),
				distinctUntilChanged()
			),
			this.refreshEvent
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					setTimeout(() => {
						this.isLoadingResults = true;
					}, 0);
					return this.accountService!.getInvites({
						offset: this.paginator.pageIndex * this.paginator.pageSize,
						limit: this.paginator.pageSize,
						sortingKey: this.sort.active,
						sortBy: this.sort.direction,
						searchText: this.filterFormControl.value || undefined,
						orgId: this.dialogData.org.id,
						typeOf: this.dialogData?.typeOf
						// status: "PENDING"
					}).pipe(catchError(() => observableOf(null)));
				}),
				map((data: any) => {
					// Flip flag to show that loading has finished.
					setTimeout(() => {
						this.isLoadingResults = false;
					}, 0);
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
					if (data === null) {
						return [];
					}
					this.resultsLength = data.total;
					return data.invites;
				})
			)
			.subscribe(data => (this.data = data));
	}
	withdraw(invite: any) {
		this.accountService
			.actionInvite({
				inviteId: invite.id,
				action: "WITHDRAW"
			})
			.subscribe((res: any) => {
				if (res.statusCode === 200) {
					this.refreshEvent.emit();
				}
			});
	}
	resend(invite: any) {
		let payload = {
			...this.orgFormGroup?.value,
			membersToBeInvited: [invite.email]
		};
		this.emails = payload.membersToBeInvited;
		this.organisationService.inviteMembers(payload).subscribe(res => {
			if (res.statusCode === 200) {
				this.results = res.result;
				this.refreshEvent.emit();
				this._snack.open("Partner invite resent successfully.");
			}
		});
	}
	remove(invite: any) {
		this.accountService
			.actionInvite({
				inviteId: invite.id,
				action: "REVOKE"
			})
			.subscribe((res: any) => {
				if (res.statusCode === 200) {
					this.refreshEvent.emit();
				}
			});
	}
	openUpdateInvite(invite: any) {
		const dialogRef = this._dialog.open(InviteComponent, {
			minWidth: "600px",
			panelClass: "ca-dialog",
			data: {
				invite
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result?.refresh) {
				this.refreshEvent.emit();
			}
		});
	}

	async updateRole(invite: any, $event: MatSelectChange) {
		let newRole = $event.value;
		let confirm = await this.confirmIfUpdateRole(invite);
		if (confirm) {
			this.accountService
				.actionInvite({
					inviteId: invite.id,
					role: newRole,
					action: "UPDATE_ROLE"
				})
				.subscribe((res: any) => {
					if (res.statusCode === 200) {
						this._snack.open("Role updated.");
					} else {
						$event.value = invite.role;
					}
				});
		} else {
			$event.value = invite.role;
		}
	}

	confirmIfUpdateRole(invite: any): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `Do you wish to Update role of ${invite.email}?`;
			const dialogData = new ConfirmDialogModel("Confirm Action", {
				message,
				confirmText: "Update",
				dismissText: "Cancel"
			});
			const dialogRef = this._dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	confirmIfTransferOwnership(invite: any): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const dialogData = new ConfirmDialogModel("Confirm Action", {
				messageTemplateRef: this.messageRef,
				messageTemplateContext: { invite },
				confirmText: "Yes",
				dismissText: "Cancel"
			});
			const dialogRef = this._dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				minWidth: "500px",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	async transferOwnership(invite: any) {
		let confirm = await this.confirmIfTransferOwnership(invite);
		if (confirm) {
			this.organisationService
				.transferOwnership(invite.organisation.id, {
					newOwnerId: invite.to.id
				})
				.subscribe((res: any) => {
					if (res.statusCode === 200) {
						this._snack.open("Owner updated.");
						this.dialogRef.close({ refresh: true });
					}
				});
		}
	}
}
