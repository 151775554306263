import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "filter"
})
export class FilterPipe implements PipeTransform {
	transform(items: any[], keys: string[], term: string): any[] {
		if (!term) return items;
		term = term.toLowerCase();
		return items.filter(item =>
			keys.some(key => (item[key] as string).toLowerCase().indexOf(term) !== -1)
		);
	}
}
