import { truncate as L_truncate } from "lodash";

export function suffix(value: string, suffix: string) {
	return value ? `${value} ${suffix}` : value;
}

export function truncate(
	s: string,
	options: { length: number } = {
		length: 15
	}
) {
	return L_truncate(s, { separator: " ", ...options });
}

const emailRegex =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function validateEmail(email: string) {
	return emailRegex.test(String(email).toLowerCase());
}

export function getSurveyURL() {
	return location.origin.replace("partner", "survey");
}
export function randstr(prefix: string = "") {
	return Math.random()
		.toString(36)
		.replace("0.", prefix || "");
}
