import { Component, Input } from "@angular/core";

@Component({
	selector: "app-handle-indicator",
	templateUrl: "./handle-indicator.component.html",
	styleUrls: ["./handle-indicator.component.scss"]
})
export class HandleIndicatorComponent {
	@Input() n: number = 0;
	get isRed() {
		return this.n === 1;
	}
	get isOrange() {
		return this.n === 2;
	}
	get isGreen() {
		return this.n >= 3;
	}
	constructor() {}
}
