<div class="ca-avatar-container" [class.fx-inline]="fxInline">
	<span class="ca-avatar-img-container" [ngClass]="{'hover-effect':hoverEffect}" [style.height]="size"
		[style.width]="size" [matTooltip]="title">
		<img [style.height]="size" [style.width]="size"
			*ngIf="avatarUser?.role && avatarUser?.role==='ADMIN';else profilepic" class="ca-avatar-img"
			[class.ca-avatar-img-bordered]="bordered" src="/assets/logo-small.png">
		<ng-template #profilepic>
			<img [style.height]="size" [style.width]="size" class="ca-avatar-img" [src]="imageKey|filepath:src"
				[appImgFallback]="type" [class.ca-avatar-img-bordered]="bordered">
		</ng-template>
		<span class="ca-avatar-admin">
			<mat-icon *ngIf="avatarUser?.role && avatarUser?.role==='ADMIN'" class="ca-avatar-admin-shield" color="primary">
				shield
			</mat-icon>
			<mat-icon *ngIf="avatarUser?.role && avatarUser?.role==='ADMIN'" class="ca-avatar-admin-settings">
				settings
			</mat-icon>

		</span>
	</span>
	<div *ngIf="!imageOnly" class="ca-avatar-info">
		<div class="ca-avatar-name" [matTooltip]="title">
			{{name | firstCap}}
		</div>
		<div *ngIf="type==='GROUP_PROFILE' && showOrgRole" class="ca-avatar-role">
			<ng-container *ngIf="isOrgOwner"> OWNER </ng-container>
			<ng-container *ngIf="isOrgOwner && role"> - </ng-container>
			{{role}}
		</div>
		<div *ngIf="showEmail && email && (type==='USER_PROFILE' || type==='STEP_USER_PROFILE')" class="ca-avatar-email">
			<app-copy-to-clipboard *ngIf="emailCopyToClipboard;else emailTRef" [content]="email" [overflowEllipsis]="true" >
				{{email }}
			</app-copy-to-clipboard>
			<ng-template #emailTRef>
				{{email }}
			</ng-template>
		</div>
	</div>
</div>
