import { CdkStepper } from "@angular/cdk/stepper";
import { Component } from "@angular/core";
@Component({
	selector: "app-character360-stepper",
	templateUrl: "./character360-stepper.component.html",
	styleUrls: ["./character360-stepper.component.scss"],
	providers: [
		{ provide: CdkStepper, useExisting: Character360StepperComponent }
	]
})
export class Character360StepperComponent extends CdkStepper {}
