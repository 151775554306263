import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-character360-respondents",
	templateUrl: "./character360-respondents.component.html",
	styleUrls: ["./character360-respondents.component.scss"]
})
export class Character360RespondentsComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
