import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { queryParams } from "../types/common";
import { removeUndefined } from "../utils/object";
interface InviteQueryParams extends queryParams {
	orgId?: number;
	status?: "PENDING";
	typeOf?: "PARTNER" | "CLIENT";
}

@Injectable({
	providedIn: "root"
})
export class AccountService {
	baseURL = environment.baseUrl;
	constructor(private http: HttpClient) {}
	verifyInvite(payload: any) {
		return this.http.post(`${this.baseURL}/invite/verify`, payload);
	}
	actionInvite(payload: any) {
		return this.http.post(`${this.baseURL}/invite/action`, payload);
	}
	updateInvite(id: number, payload: any) {
		return this.http.patch(`${this.baseURL}/invite/${id}`, payload);
	}
	createAccount(payload: any) {
		return this.http.post(`${this.baseURL}/account`, payload);
	}
	createUserAccount(payload: any) {
		return this.http.post(`${this.baseURL}/account/user`, payload);
	}
	isEmailExistingAccount(payload: any) {
		return this.http.post(`${this.baseURL}/account/exist`, payload);
	}
	getInvites(queryParams: InviteQueryParams = {}) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...queryParams,
					sortBy: queryParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get<any>(`${this.baseURL}/invite`, { params });
	}
	login(payload: any) {
		return this.http.post(`${this.baseURL}/account/login/email`, payload);
	}
	loginWithGoogle(payload: any) {
		return this.http.post(`${this.baseURL}/account/login/google`, payload);
	}
	linkWithGoogle(payload: any) {
		return this.http.post(`${this.baseURL}/account/link/google`, payload);
	}
	delinkWithGoogle() {
		return this.http.delete(`${this.baseURL}/account/link/google`);
	}
	loginWithMicrosoft(payload: any) {
		return this.http.post(`${this.baseURL}/account/login/microsoft`, payload);
	}
	linkWithMicrosoft(payload: any) {
		return this.http.post(`${this.baseURL}/account/link/microsoft`, payload);
	}
	delinkWithMicrosoft() {
		return this.http.delete(`${this.baseURL}/account/link/microsoft`);
	}
	get() {
		return this.http.get(`${this.baseURL}/account`);
	}
	forgotPassword(queryParams: { email: string }) {
		let params = new HttpParams({ fromObject: queryParams });
		return this.http.get(`${this.baseURL}/account/forgot_password`, {
			params
		});
	}
	changePassword(payload: { currentPassword: string; newPassword: string }) {
		return this.http.put(`${this.baseURL}/account/change_password`, payload);
	}
	logout() {
		return this.http.post(`${this.baseURL}/account/logout`, {});
	}
	sendOtp(payload: any) {
		return this.http.post(`${this.baseURL}/account/mobile/send_otp`, payload);
	}
	verifyOtp(payload: any) {
		return this.http.post(`${this.baseURL}/account/mobile/verify_otp`, payload);
	}

	getVerifiedMobileNos() {
		return this.http.get(`${this.baseURL}/account/mobile`);
	}
	getUsers() {
		return this.http.get(`${this.baseURL}/account/users`);
	}
	getSinglePurposeToken(
		payload: any
	): Promise<{ token: string; type: string }> {
		return this.http
			.post<{ token: string; type: string }>(
				`${this.baseURL}/account/single_purpose_token`,
				payload
			)
			.toPromise();
	}
}
