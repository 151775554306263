import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationExtras, Router } from "@angular/router";
import { BehaviorSubject, Subscription, of } from "rxjs";
import { delay } from "rxjs/operators";
import { StepAuthService } from "src/app/_shared/services/step-auth.service";
import { ST_PLATFORM } from "../types/common";
import { USER } from "../types/user";
import { AccountService } from "./account.service";
import { OrganisationService } from "./organisation.service";
import { MatDialog } from "@angular/material/dialog";
import { AmplitudeService } from "./amplitude.service";

@Injectable({
	providedIn: "root"
})
export class AuthService {
	tokenSubscription = new Subscription();
	authTokenKey = "authToken";
	authTypeKey = "authType";
	isLoggedInKey = "isLoggedIn";
	userKey = "user";
	$user = new BehaviorSubject<USER | null>(null);
	user: USER = JSON.parse(localStorage.getItem(this.userKey) ?? "{}");
	st_platform: ST_PLATFORM = "STCA";
	constructor(
		private stepAuthService: StepAuthService,
		private accountService: AccountService,
		private router: Router,
		private _snackBar: MatSnackBar,
		private organisationService: OrganisationService,
		private _dialog: MatDialog,
		private amplitudeService: AmplitudeService
	) {
		this.$user.next(this.user);
		this.st_platform = this.getSTPlatform() as ST_PLATFORM;
	}
	logout(redirect = true) {
		this.amplitudeService.track("Logged Out");
		return new Promise<void>(resolve => {
			if (this.user.role === "USER") {
				this.accountService.logout().subscribe(res => {
					this.removeAll();
					redirect && this.redirectToLogin();
					resolve();
				});
			} else {
				this.removeAll();
				redirect && this.redirectToLogin();
				resolve();
			}
		});
	}
	redirectToLogin(extras?: NavigationExtras) {
		if (!this.st_platform) {
			if (window.location.hostname.includes("partner")) {
				this.setSTPlatform("STCA");
			} else {
				this.setSTPlatform("STC360");
			}
		}
		switch (this.st_platform) {
			case "STC360":
				this.router.navigate(["/user-login"], extras);
				break;
			case "STCA":
			default:
				this.router.navigate(["/login"], extras);
				break;
		}
	}
	expirationCounter(timeoutInMinutes: number = 11) {
		this.tokenSubscription.unsubscribe();
		this.tokenSubscription = of(null)
			.pipe(delay(timeoutInMinutes * 1000 * 60))
			.subscribe(() => {
				this.removeAll();
				this._snackBar.open("Session Expired", "Close", { duration: 0 });
				this.router.navigate(["login"]);
			});
	}
	removeAll() {
		this.amplitudeService.reset();
		this._dialog.closeAll();
		this.stepAuthService.logout();
		this.removeAuth();
		this.setUserInfo({});
		this.setLoggedOut();
		this.organisationService.clearCache();
		if (!this.tokenSubscription.closed) {
			this.tokenSubscription.unsubscribe();
		}
	}
	setAuth(token: string, type: "ca_auth" | "st_auth" = "ca_auth") {
		localStorage.setItem(this.authTokenKey, token);
		localStorage.setItem(this.authTypeKey, type);
	}
	getAuth() {
		return {
			token: localStorage.getItem(this.authTokenKey) || null,
			type: localStorage.getItem(this.authTypeKey) || null
		};
	}
	getBasicAuth() {
		return {
			token:
				"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MCwiaWF0IjoxNjU0Njc4MTExfQ.tTBsroKGFzv8D1xcPL_rLmQ-CESTozIhMdqNRFhj2OY",
			type: "basicJWT"
		};
	}
	removeAuth() {
		localStorage.removeItem(this.authTokenKey);
		localStorage.removeItem(this.authTypeKey);
	}
	setLoggedIn() {
		localStorage.setItem(this.isLoggedInKey, "true");
	}
	setLoggedOut() {
		localStorage.setItem(this.isLoggedInKey, "false");
	}
	isLoggedIn() {
		return JSON.parse(localStorage.getItem(this.isLoggedInKey) ?? "false");
	}
	setUserInfo(data: any) {
		localStorage.setItem("user", JSON.stringify(data));
		this.user = data;
		this.$user.next(data);
	}
	getUserInfo() {
		return this.$user;
	}
	setSTPlatform(ST_PLATFORM: ST_PLATFORM) {
		this.st_platform = ST_PLATFORM;
		localStorage.setItem("ST_PLATFORM", ST_PLATFORM);
	}
	getSTPlatform(): ST_PLATFORM {
		return localStorage.getItem("ST_PLATFORM") as ST_PLATFORM;
	}
	getPlatformTitle() {
		switch (this.st_platform) {
			case "STEP":
				return "Emotional Pulse App";
			case "STCA":
				return "Partner Portal";
			case "STC360":
				return "User Dashboard";
		}
	}
	setUserInfoFromGetAccount() {
		return new Promise<void>(resolve => {
			this.accountService.get().subscribe((res: any) => {
				this.setUserInfo(res.user);
				resolve();
			});
		});
	}
}
