import { Directive } from "@angular/core";
import {
	NG_VALIDATORS,
	Validator,
	ValidationErrors,
	AbstractControl
} from "@angular/forms";
import { phoneNumberValidator } from "../validator/phone-number";
@Directive({
	selector: "[appPhoneNumber]",
	providers: [
		{ provide: NG_VALIDATORS, useExisting: PhoneNumberDirective, multi: true }
	]
})
export class PhoneNumberDirective implements Validator {
	constructor() {}
	validate(control: AbstractControl): ValidationErrors {
		return phoneNumberValidator()(control) as ValidationErrors;
	}
}
