<mat-select #matSelect multiple matTooltip="Select Members who can Access" placeholder="Select Members who can Access"
	[value]="selectedIds" (openedChange)="openedChange.emit({ $event, matSelect })">
	<mat-option *ngIf="$any(members|async)?.length===0" disabled>
		No Members Available
	</mat-option>
	<mat-option *ngIf="$any(members|async)?.length>0" [value]="-1" (click)="selected(-1)">
		All Members
	</mat-option>
	<mat-option *ngFor="let item of members|async" [value]="item.id" (click)="selected(item.id)">
		<app-avatar [avatar]="item" [showEmail]="false">
		</app-avatar>
	</mat-option>
</mat-select>