import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class AspirationService {
	private cache$!: Observable<any>;

	baseURL = environment.baseUrl;
	constructor(public http: HttpClient) {}
	get aspirations() {
		if (!this.cache$) {
			this.cache$ = this.getAll().pipe(shareReplay(1));
		}
		return this.cache$;
	}
	getAll() {
		return this.http.get(`${this.baseURL}/character-traits.json`);
	}
}
