import { EChartsOption, SeriesOption } from "echarts";
import {
	backgroundColor,
	// gridStyle,
	marker,
	singleComparisonColor,
	textStyle,
	titleStyle,
	toFixedWithZeroTruncation,
	toTooltipHTML
} from "../../../utils/chart-options";
export const CHART_DEFAULTS: EChartsOption | any = {
	...backgroundColor,
	title: {
		...titleStyle
	},
	textStyle: textStyle,
	tooltip: {
		trigger: "axis",
		showContent: true,
		alwaysShowContent: false,
		axisPointer: {
			type: "shadow"
		},
		formatter: (params: any): string | HTMLElement | HTMLElement[] => {
			if (params[0].name == "") return "";
			return toTooltipHTML({
				name: params[0].name,
				values: params.map((param: any) => ({
					marker: marker(param),
					name: param.seriesName,
					value: `${toFixedWithZeroTruncation(param.value, 2)}%`
				}))
			});
		}
	},
	legend: {
		right: 20,
		top: 5,
		itemGap: 3,
		itemWidth: 10,
		itemHeight: 10,
		selectedMode: false,
		textStyle: {
			fontSize: 10
		},
		itemStyle: {
			borderType: "solid",
			borderWidth: 1.5
		}
	},
	grid: {
		left: 240,
		right: 30,
		top: "10%",
		bottom: 30,
		containsLabel: true,
		backgroundColor: "white",
		show: true
	},
	xAxis: {
		type: "value",
		boundaryGap: [0, 0],
		minInterval: 0,
		splitNumber: 10,
		axisTick: {
			show: false
		},
		min: 0,
		max: 100,
		axisLine: {
			show: false
		},
		axisLabel: {
			show: true,
			color: "black",
			fontWeight: "bold",
			interval: 1,
			formatter: (value: number) => {
				return `${toFixedWithZeroTruncation(value, 2)}%`;
			}
		},
		splitLine: {
			show: true,
			interval: 1,
			lineStyle: {
				color: "#c7c7c4",
				width: 1.5
			}
		}
	},
	yAxis: [
		{
			id: "axis",
			type: "category",
			axisTick: {
				show: false
			},
			z: 5,
			offset: 200,
			axisLabel: {
				show: true,
				align: "left",
				color: "black",
				width: 258,
				interval: 0,
				overflow: "break"
			},
			axisLine: {
				show: true,
				lineStyle: {
					color: "#c7c7c4",
					width: 2.5
				}
			},
			splitLine: {
				show: false,
				lineStyle: {
					color: "#c7c7c4",
					width: 1.5
				}
			},
			inverse: false
		}
		// {
		// 	id: "axis Mask",
		// 	type: "category",
		// 	axisLabel: {
		// 		show: false
		// 	},
		// 	axisLine: {
		// 		show: false
		// 	},
		// 	position: "left"
		// }
	],
	series: [
		{
			type: "bar",
			name: "Actual",
			barWidth: 20,
			color: "#90cf5b",
			label: {
				show: true,
				position: "insideLeft",
				color: "#000",
				padding: [2, 4],
				backgroundColor: "rgba(255,255,255,0.7)",
				offset: [5, 0],
				formatter: (params: any): string => {
					return `${toFixedWithZeroTruncation(params.value, 2)}%`;
				}
			}
		}
	]
};
export const SERIE_DEFAULTS_ASPIRATION: SeriesOption | any = {
	name: "Target",
	type: "bar",
	barWidth: 20,
	color: singleComparisonColor,
	barGap: "-100%",
	z: 2,
	itemStyle: {
		color: "transparent",
		borderType: "solid",
		borderColor: singleComparisonColor,
		borderWidth: 1.5
	},
	label: {
		show: false
	}
};
/**=======================
 *  commented as it is not needed now
 *========================**/
export const SERIE_DEFAULTS_ASPIRATIONS: SeriesOption | any = {
	...SERIE_DEFAULTS_ASPIRATION,
	yAxisIndex: 1,
	barGap: 0,
	barWidth: 12.4
};
