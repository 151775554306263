<br>
<h1 style="text-align: center;">
	Only {{nMoreResponses }} more responses <br> to unlock 50% off.
</h1>
<button mat-mini-fab class="ca-dialog-close-icon outer dark-text" color="accent" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content class="content">
	You are almost there. You only need {{nMoreResponses }}x more responses to get 50% off the full report.
	<br><br>
	The full report provides
	<ul>
		<li>Detailed analysis of 3 main character strengths</li>
		<li>Comprehensive comparison of all strengths.</li>
		<li>Personal Growth Plan</li>
		<li>Full Details from 360 Feedback</li>
	</ul>
	<a mat-raised-button class="full-width" color="primary" target="_BLANK"
		href="{{ basePath +'/'+ character360Service.getCharacter360Path({character360:data.report.character360}).join('/')}}">
		Send out more links
	</a>
</mat-dialog-content>