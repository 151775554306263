import { Component, Input } from "@angular/core";
import { PROFILE_TYPE } from "../../types/common";
import { ORG } from "../../types/organisation";
import { STEP_GROUP } from "../../types/step-group";
import { STEP_USER, USER } from "../../types/user";
import { AuthService } from "../../services/auth.service";

@Component({
	selector: "app-avatar",
	templateUrl: "./avatar.component.html",
	styleUrls: ["./avatar.component.scss"]
})
export class AvatarComponent {
	@Input() avatar!: Partial<USER> | Partial<STEP_GROUP> | Partial<ORG>;
	@Input() fxInline = false;
	@Input() size = "32px";
	@Input() imageOnly = false;
	@Input() bordered = false;
	@Input() hoverEffect = false;
	@Input() forceType?: PROFILE_TYPE;
	@Input() showOrgRole: boolean = false;
	@Input() showEmail: boolean = true;
	@Input() emailCopyToClipboard: boolean = true;
	constructor(public authService: AuthService) {}
	get title() {
		switch (this.type) {
			case "GROUP_PROFILE":
			case "GROUP_SECONDARY_PROFILE":
				return this.avatarOrg.name;
			case "USER_PROFILE":
				let title = this.avatarUser?.role === "ADMIN" ? "Super Admin - " : "";
				title += `${this.avatarUser?.firstName || ""} ${
					this.avatarUser?.lastName || ""
				}`;
				if (this.email) title += `\n${this.email}`;
				return title;
			case "STEP_GROUP_PROFILE":
				return (this.avatar as STEP_GROUP).groupName;
			case "STEP_USER_PROFILE":
				return (this.avatar as STEP_USER).fullName;
		}
	}
	get isOrgOwner() {
		return this.avatarOrg?.owner.id === this.authService?.user.id;
	}
	get role(): string {
		return this.avatarOrg?.membership?.role as string;
	}
	get email(): string {
		switch (this.type) {
			case "GROUP_PROFILE":
			case "GROUP_SECONDARY_PROFILE":
				return this.avatarOrg.name;
			case "USER_PROFILE":
				return this.avatarUser?.emailId ?? "";
			case "STEP_GROUP_PROFILE":
				return (this.avatar as STEP_GROUP).groupName;
			case "STEP_USER_PROFILE":
				return this.avatarStepUser.email ?? "";
		}
	}
	get type() {
		if (this.forceType) return this.forceType;
		if (this.avatarOrg?.name) {
			return "GROUP_PROFILE";
		} else if (this.avatarUser?.role) {
			return "USER_PROFILE";
		} else if ((this.avatar as STEP_GROUP)?.groupName) {
			return "STEP_GROUP_PROFILE";
		} else if ((this.avatar as STEP_USER)?.userType) {
			return "STEP_USER_PROFILE";
		}
		return "USER_PROFILE";
	}
	get src() {
		switch (this.type) {
			case "GROUP_PROFILE":
			case "GROUP_SECONDARY_PROFILE":
			case "USER_PROFILE":
				return "CA";
			case "STEP_GROUP_PROFILE":
			case "STEP_USER_PROFILE":
				return "STEP";
		}
	}
	get name() {
		switch (this.type) {
			case "GROUP_PROFILE":
			case "GROUP_SECONDARY_PROFILE":
				return this.avatarOrg.name;
			case "USER_PROFILE":
				return `${this.avatarUser?.firstName || ""} ${
					this.avatarUser?.lastName || ""
				}`;
			case "STEP_GROUP_PROFILE":
				return (this.avatar as STEP_GROUP).groupName;
			case "STEP_USER_PROFILE":
				return (this.avatar as STEP_USER).fullName;
		}
	}
	get imageKey() {
		switch (this.type) {
			case "GROUP_PROFILE":
			case "GROUP_SECONDARY_PROFILE":
				return this.avatarOrg.imageKey;
			case "USER_PROFILE":
				return this.avatarUser?.imageKey;
			case "STEP_GROUP_PROFILE":
				return (this.avatar as STEP_GROUP).imageKey;
			case "STEP_USER_PROFILE":
				return (this.avatar as STEP_USER).imageKey;
		}
	}
	get avatarUser() {
		return this.avatar as USER;
	}
	get avatarOrg() {
		return this.avatar as ORG;
	}
	get avatarStepUser() {
		return this.avatar as STEP_USER;
	}
}
