import {
	CATEGORY,
	CATEGORY_ALL,
	CATEGORY_KEYS,
	CATEGORY_RESPONSE_KEYS,
	Step
} from "../types/character360";

export const C360_CATEGORY_REPORT_KEY_MAP: {
	[s in CATEGORY]: CATEGORY_RESPONSE_KEYS;
} = {
	supplier: "suppliersResponseCount",
	manager: "peopleYouReportToResponseCount",
	peer: "peopleWhoWorkWithYouResponseCount",
	team: "peopleWhoReportToYouResponseCount",
	client: "clientsResponseCount",
	friend: "friendsResponseCount"
};
export const Categories: CATEGORY[] = [
	"team",
	"peer",
	"manager",
	"client",
	"friend",
	"supplier"
];

export const COLORS = {
	manager: "#8064a2",
	team: "#e46c0a",
	peer: "#9bbb59",
	client: "#4bacc6",
	supplier: "#c0504d",
	friend: "#FFBF00"
};

export const C360_CATEGORY_KEY_MAP: { [s in CATEGORY]: CATEGORY_KEYS } = {
	supplier: "SUPPLIERS",
	manager: "PEOPLE_YOU_REPORT_TO",
	peer: "PEOPLE_WHO_WORK_WITH_YOU",
	team: "PEOPLE_WHO_REPORT_TO_YOU",
	client: "CLIENTS",
	friend: "FRIENDS"
};
export const C360_CATEGORY_TITLE_MAP: { [s in CATEGORY_ALL]: string } = {
	supplier: "Supplier Feedback",
	manager: "Manager Feedback",
	peer: "Peer Feedback",
	team: "Team Feedback",
	client: "Client Feedback",
	friend: "Friend Feedback",
	overall: "Overall Feedback",
	avgByGroup: "Average Feedback By Group"
};

export const C360_CATEGORY_RESPONSECOUNT_MAP: {
	[s in CATEGORY_KEYS]: CATEGORY_RESPONSE_KEYS;
} = {
	SUPPLIERS: "suppliersResponseCount",
	PEOPLE_YOU_REPORT_TO: "peopleYouReportToResponseCount",
	PEOPLE_WHO_WORK_WITH_YOU: "peopleWhoWorkWithYouResponseCount",
	PEOPLE_WHO_REPORT_TO_YOU: "peopleWhoReportToYouResponseCount",
	CLIENTS: "clientsResponseCount",
	FRIENDS: "friendsResponseCount"
};
export const C360_CATEGORY_LABEL_MAP: {
	[s in CATEGORY_KEYS]: string;
} = {
	SUPPLIERS: "Supplier",
	PEOPLE_YOU_REPORT_TO: "Manager",
	PEOPLE_WHO_WORK_WITH_YOU: "Peer",
	PEOPLE_WHO_REPORT_TO_YOU: "Team",
	CLIENTS: "Client",
	FRIENDS: "Friend"
};
// 	Order for sending out the invites is
// 	-	 Team Members
// 	-	 Peers
// 	-	 Managers
// 	-	 Clients
//  -	 Friends
// 	-	 Suppliers

export const CHARACTER360_INTERNAL_STEPS: Step[] = [
	{
		description: "who report to you",
		name: "Team Members",
		singular: "Team Member",
		id: "team",
		tooltipPosition: "left"
	},
	{
		description: "who work at your level",
		name: "Peers",
		singular: "Peer",
		id: "peer",
		tooltipPosition: "left"
	},
	{
		description: "whom you report to",
		name: "Managers",
		singular: "Manager",
		id: "manager",
		tooltipPosition: "left"
	}
];
export const CHARACTER360_EXTERNAL_STEPS: Step[] = [
	{
		description: "who receives a service from you",
		name: "Clients",
		singular: "Client",
		id: "client",
		tooltipPosition: "right"
	},
	{
		description: "who you spend time with outside of work",
		name: "Friends",
		singular: "Friend",
		id: "friend",
		tooltipPosition: "right"
	},
	{
		description: "who provides a service to you",
		name: "Suppliers",
		singular: "Supplier",
		id: "supplier",
		tooltipPosition: "right"
	}
];
export const CHARACTER360_STEPS: Step[] = [
	...CHARACTER360_INTERNAL_STEPS,
	...CHARACTER360_EXTERNAL_STEPS
];

export const MAX_LIMIT = 20;
