<h1 mat-dialog-title>Single Comparison</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]="groupFG?.value?.id">
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
		<mat-radio-group [(ngModel)]="comparison1" class="ca-full-width-radios" aria-label="Select an option"
			fxFlex="1 1 100%" fxFlexFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
			<mat-radio-button color="primary" value="USER" fxFlexFill>
				<mat-card fxFlex="1 1 100%" fxFlexFill>
					<app-report-select searchOn="name" [reportCtrl]="reportCtrl" [options]="{ hideReportIds:hideReportIds}">
					</app-report-select>
				</mat-card>
			</mat-radio-button>
			<span>
				<b> OR </b>
			</span>
			<mat-radio-button color="primary" value="GROUP" fxFlexFill>
				<mat-card>
					<app-group-report-select [groupReportCtrl]="groupReportCtrl"
						[options]="{ hideGroupReportIds:hideGroupReportIds ,label:'Group Report 1'}">
					</app-group-report-select>
				</mat-card>
			</mat-radio-button>
			<span>
				<b> OR </b>
			</span>
			<mat-radio-button color="primary" value="TARGET" fxFlexFill>
				<mat-card fxFlex="1 1 100%" fxFlexFill>
					<app-target fxFlex="1 1 100%" fxFlexFill type="GROUP" [(id)]="data.groupId" [orgId]="data.orgId"
						(aspirationFormGroupReady)="onAspirationFormGroupReady($event)"></app-target>
				</mat-card>
			</mat-radio-button>
		</mat-radio-group>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button [disabled]="isInvalid" color="primary" (click)="openShareReport()">
		<mat-icon>send</mat-icon>
		Send Report
	</button>
	<button mat-raised-button [disabled]="isInvalid" color="primary" class="mat-icon-loader-button"
		(click)="downloadReport()">
		<mat-icon>download</mat-icon>
		Download Report
		<app-loader [loaderId]="downloadButtonLoaderId" [fgsSize]=25></app-loader>
	</button>
	<button mat-raised-button [disabled]="isInvalid" color="primary" (click)="openInNewTab()">
		<mat-icon>open_in_new</mat-icon>
		Open Report
	</button>
</mat-dialog-actions>