import { Pipe, PipeTransform } from "@angular/core";
import { formatIfDecimal } from "../utils/number";

@Pipe({
	name: "customNumber"
})
export class CustomNumberPipe implements PipeTransform {
	transform(value: number, fractionDigits: number): string {
		return formatIfDecimal(value, fractionDigits);
	}
}
