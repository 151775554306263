import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { initOpts, loadingOpts } from "../../../utils/chart-options";
import { EChartsOption } from "echarts";
import { CHART_DEFAULTS } from "./chart-defaults";
import { getValues, prepareDataValues } from "../character";
import { TooltipOption } from "echarts/types/dist/shared";

@Component({
	selector: "app-csd",
	templateUrl: "./csd.component.html",
	styleUrls: ["./csd.component.scss"]
})
export class CSDComponent implements OnInit, OnChanges {
	@Input() report: any;
	@Input() isLoading: boolean = true;
	@Input() showTooltip: boolean = true;
	initOpts = initOpts;
	loadingOpts = loadingOpts;
	default: EChartsOption = CHART_DEFAULTS;
	chartOptionMoral!: EChartsOption;
	chartOptionPerformance!: EChartsOption;
	constructor() {}
	ngOnInit(): void {
		(this.default.tooltip! as TooltipOption).show = this.showTooltip;
		this.initMoralData();
		this.initPerformanceData();
	}
	ngOnChanges() {
		this.initMoralData();
		this.initPerformanceData();
	}
	initMoralData() {
		this.chartOptionMoral = {
			title: {
				text: "Moral"
			},
			yAxis: {
				data: getValues("LABELS", "MORAL", "COMBINED")
			},
			series: [
				{
					color: "#403251",
					data: prepareDataValues(
						this.report.characterStrengths.INDIVIDUAL.MORAL,
						"MORAL",
						"INDIVIDUAL"
					)
				},
				{
					color: "#604b79",
					data: prepareDataValues(
						this.report.characterStrengths.MORAL,
						"MORAL",
						"COMBINED"
					)
				},
				{
					color: "#ccc1d9",
					data: prepareDataValues(
						this.report.characterStrengths.TEAM.MORAL,
						"MORAL",
						"TEAM"
					)
				}
			]
		};
	}
	initPerformanceData() {
		this.chartOptionPerformance = {
			title: {
				text: "Performance"
			},
			yAxis: {
				data: getValues("LABELS", "PERFORMANCE", "COMBINED")
			},
			series: [
				{
					color: "#984807",
					data: prepareDataValues(
						this.report.characterStrengths.INDIVIDUAL.PERFORMANCE,
						"PERFORMANCE",
						"INDIVIDUAL"
					)
				},
				{
					color: "#E46C0A",
					data: prepareDataValues(
						this.report.characterStrengths.PERFORMANCE,
						"PERFORMANCE",
						"COMBINED"
					)
				},
				{
					color: "#FCD5B5",
					data: prepareDataValues(
						this.report.characterStrengths.TEAM.PERFORMANCE,
						"PERFORMANCE",
						"TEAM"
					)
				}
			]
		};
	}
}
