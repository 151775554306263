import { Component, OnInit, Input } from "@angular/core";
import { ORG } from "../../types/organisation";

@Component({
	selector: "app-org-branding",
	templateUrl: "./org-branding.component.html",
	styleUrls: ["./org-branding.component.scss"]
})
export class OrgBrandingComponent {
	@Input() org!: ORG;
	@Input() avatarSize = 75;
	get logoImageKey() {
		return this.org?.orgLogoImageKey;
	}
	get avatarImageKey() {
		return this.org?.imageKey;
	}
	get imageKey() {
		return this.logoImageKey || this.avatarImageKey;
	}
	constructor() {}
	get showPartnerBranding() {
		//hiding partner branding for Sharetree org id 1 in dev and live env
		return this.org?.id !== 1;
	}
}
