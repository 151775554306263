<section class="ca-archetype-descriptions">
	<div class="ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-page-header [report]="report"></app-page-header>
			<app-anchor anchorId="YCASW"></app-anchor>
			<app-page-title title="Your Character Archetypes & Persona Quadrants">
			</app-page-title>
			<div class="font-family-raleway" fxLayout="row" fxLayoutAlign="space-between baseline">
				<div class="ca-sub-heading ">
					Your Persona Type: {{report.characterStrengths.PERSONAS?.TYPE}}
				</div>
				<div class="ca-archetype-legend">
					Your Strengths vs Global Average &nbsp;
					<span class="circle x1"></span> 1x &nbsp;
					<span class="circle x2"></span> 2x
				</div>
			</div>
			<!-- <ng-container *ngIf="report?.isPreview; else elseTemplate">
				<app-preview-content [pageMargin]="false" [report]="report"
					bg="/assets/images/preview-bg-archetype-wheel-v1.png">
				</app-preview-content>
			</ng-container>
			<ng-template #elseTemplate> -->
			<div class="ca-images">
				<div class="ca-image-chart-container">
					<img width="100%" height="auto" src="/assets/images/archetype-12-wheel v2.png">
					<div class="ca-image-chart" echarts [loadingOpts]="loadingOpts" [loading]="isLoading" [initOpts]="initOpts"
						[options]="default" [merge]="chartOptions">
					</div>
					<div class="ca-archetype-values">
						<span *ngFor="let value of characterStrengthsVsGSD;let i=index"
							[ngClass]="['ca-archetype-value','ca-archetype-'+i]">
							{{value | number:'1.0-2'}}
						</span>
					</div>
					<div class="ca-personas font-family-raleway" *ngIf="report.characterStrengths.PERSONAS">
						<span *ngFor="let persona of personas;let i=index" [ngClass]="['ca-persona-value','ca-persona-'+i]">
							{{report.characterStrengths.PERSONAS[persona] | number:'1.0-2'}}%
						</span>
						<span *ngFor="let persona of personasCombo;let i=index"
							[ngClass]="['ca-persona-combo-value','ca-persona-'+i]">
							{{report.characterStrengths.PERSONAS[persona] | number:'1.0-2'}}%
						</span>
					</div>
				</div>
			</div>
			<!-- </ng-template> -->
			<table class="ca-archetype-table font-family-raleway">
				<colgroup>
					<col span="1" style="width: 35%" />
					<col span="1" />
					<col span="1" />
					<col span="1" />
				</colgroup>
				<tbody>
					<tr>
						<td class="ca-diag-cell">
							Your Leading Strengths
						</td>
						<td class="ca-archetype-strength" *ngFor="let archetype of report?.characterArchetypes">
							<img class="ca-archetype-strength-img"
								[src]="'/assets/images/character-strengths/'+ archetype.characterStrength+ '.png'" alt="">
						</td>
					</tr>
					<tr>
						<td></td>
						<td class="ca-header"> Primary</td>
						<td class="ca-header"> Secondary</td>
						<td class="ca-header"> Tertiary</td>
					</tr>
					<tr *ngFor="let row of rows">
						<td [ngClass]="[ 'ca-first-col','ca-'+row.key]">{{row.name}}</td>
						<td *ngFor="let archetype of report?.characterArchetypes"
							[ngClass]="[ 'ca-archetype-data-col ','ca-'+row.key]">
							{{archetype[row.key]|titlecase}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<app-page-footer [pageNo]="'YCASW'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
	<div class="ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-page-header [report]="report"></app-page-header>
			<app-anchor anchorId="YCAST"></app-anchor>
			<app-page-title title="Persona Quadrants">
			</app-page-title>
			<div class="ca-sub-heading font-family-raleway">
				Your Persona Type: {{report.characterStrengths.PERSONAS?.TYPE}}
			</div>
			<br>
			<br>
			<table class="ca-table-without-bg-border">
				<tr>
					<td width="220px" height="175px" class="cell padded-right-large padded-bottom-large  ca-text-align-center">
						<div class="img-value va-bg-gradient">
							<img width="70%" height="auto" src="/assets/images/VA.png">
							<span>{{report.characterStrengths.PERSONAS?.VISIONARY_ACTIVATOR| number:'1.0-2'}}%</span>
						</div>
					</td>
					<td width="auto" class="cell vertical-align-middle  padded-bottom-large">
						<div class="ca-row-heading font-family-raleway">Visionary Activators</div>
						<div class="ca-row-content">
							Are performance driven people that prefer to be always moving towards visionary
							goals and leading others in achievement. They seek high level results and have the
							ability to navigate through situations with a sense of logic and detachment whether
							it be in strategy, process or people. When in the thriving state they help others
							understand how a situations can move from one place to another with a logical
							approach. They lead action that helps integrators actualise ideas into reality.
						</div>
					</td>
				</tr>
				<tr>
					<td width="220px" height="175px" class="cell padded-right-large padded-bottom-large ca-text-align-center">
						<div class="img-value ga-bg-gradient">
							<img width="70%" height="auto" src="/assets/images/GA.png">
							<span>{{report.characterStrengths.PERSONAS?.GROUNDED_ACTIVATOR| number:'1.0-2'}}%</span>
						</div>
					</td>
					<td width="auto" class="cell vertical-align-middle  padded-bottom-large">
						<div class="ca-row-heading font-family-raleway ">Grounded Activators</div>
						<div class="ca-row-content">
							Are detailed performance driven people that create results by building processes
							and relationships that have focus on the individual parts that make up the whole.
							When in the thriving state they are capable of enduring in a set direction to contribute
							to bigger picture achievements by way of grit sacrifice and rigor in a step by step
							approach. They make formidable allies for Visionary people to actualise potential
							and vision into outcomes.
						</div>
					</td>
				</tr>
				<tr>
					<td width="220px" height="175px" class="cell padded-right-large padded-bottom-large ca-text-align-center">
						<div class="img-value gi-bg-gradient">
							<img width="70%" height="auto" src="/assets/images/GI.png">
							<span>{{report.characterStrengths.PERSONAS?.GROUNDED_INTEGRATOR| number:'1.0-2'}}%</span>
						</div>
					</td>
					<td width="auto" class="cell vertical-align-middle  padded-bottom-large">
						<div class="ca-row-heading font-family-raleway ">Grounded Integrators</div>
						<div class="ca-row-content">
							Are highly aware people that are grounded in their thoughts, emotions and
							interactions. Allowing themselves and others to be in a state that is true to their
							feelings and awakening potential from within. When in the thriving state they bring
							a sense of harmony and connection to relationships evoking feelings of presence,
							belonging and connection. They create balance when working with Activators who
							can give them enough space to work with their strengths of supporting and uniting
							people.
						</div>
					</td>
				</tr>
				<tr>
					<td width="220px" height="175px" class="cell padded-right-large ca-text-align-center">
						<div class="img-value vi-bg-gradient">
							<img width="70%" height="auto" src="/assets/images/VI.png">
							<span>{{report.characterStrengths.PERSONAS?.VISIONARY_INTEGRATOR | number:'1.0-2'}}%</span>
						</div>
					</td>
					<td width="auto" class="cell vertical-align-middle">
						<div class="ca-row-heading font-family-raleway">Visionary Integrators</div>

						<div class="ca-row-content">
							Are highly aware people that like to engage the thoughts, emotions, and interactions
							of others. Awakening groups to rise up through new perspective and vision. When in
							the thriving state they bring a sense of awe and inspiration to causes or projects by
							awakening feelings through purpose, hope and truth to higher cause, They create
							clarity and motivation when working with Grounded people who are purposefully
							aligned and believe in their vision.
						</div>
					</td>
				</tr>
			</table>
		</div>
		<app-page-footer [pageNo]="'YCAST'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
	<app-archetype-description *ngIf="report?.isPreview" [archetype]="report!.characterArchetypes[0]" [single]="true"
		[pageNumber]="('YCSG3@1'|pageNumber:pageNoOffsetForComparison)"></app-archetype-description>
	<div class="ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-page-header [report]="report"></app-page-header>
			<app-anchor anchorId="CAT"></app-anchor>
			<app-page-title title="Character Archetypes Summary Table">
			</app-page-title>
			<ng-container *ngIf="report?.isPreview; else elseSummaryTableTemplate">
				<app-preview-content [pageMargin]="false" [report]="report"
					bg="/assets/images/preview-archetype-summary-table-v2.png">
				</app-preview-content>
			</ng-container>
			<ng-template #elseSummaryTableTemplate>
				<table class="ca-cat font-family-raleway">
					<tbody>
						<tr>
							<td class="theader vertical-text quadrant-name bg-quadrants"> Quadrants </td>
							<td class="theader bg-gray-1"> Character<br> Groups<br> Strength vs<br> Global Avg </td>
							<td class="theader bg-gray-1"> Icon </td>
							<td class=" bg-gray-1"> <span class="theader"> Archetypes
								</span><br>Professional<br>Social<br>Spiritual</td>
							<td class="theader bg-gray-2"> Need </td>
							<td class="theader bg-gray-2"> Seeking </td>
							<td class="theader bg-gray-2"> Qualities/<br>Essence </td>
							<td class="theader bg-yellow"> Over-utilised <br>Ego Drive </td>
							<td class="theader bg-green"> Thriving<br> Ego Drive </td>
						</tr>
						<ng-container *ngFor="let quadrant of CAT_TABLE; let quadrantIndex=index">
							<tr *ngFor="let cg of quadrant.cgs; let cgFirst=first">
								<td [class]="['vertical-text','quadrant-name', 'bg-'+quadrant.id]" *ngIf="cgFirst" rowspan="3">
									{{quadrant.name}}
								</td>
								<td [class]="['theader','bg-light-'+quadrant.id]">
									<span [class]="['ca-color-'+cg.id]"> {{cg.name}}</span>
									<br>
									{{report?.characterStrengths?.vsGSD?.[quadrant.type]?.[cg.key]| number:'1.0-2'}}x
								</td>
								<td [class]="['bg-light-'+quadrant.id]">
									<span class="ca-archetype-strength-small">
										<img class="ca-archetype-strength-img" [src]="'/assets/images/character-strengths/'+ cg.id+ '.png'"
											alt="">
									</span>
								</td>
								<td class="bg-light-gray-1 whitespace-preline"> {{cg.archetypes}} </td>
								<td class="bg-light-gray-2"> {{cg.need}} </td>
								<td class="bg-light-gray-2"> {{cg.seeking}} </td>
								<td class="bg-light-gray-2"> {{cg.QorE}} </td>
								<td class="bg-light-yellow"> {{cg.overUtilised}} </td>
								<td class="bg-light-green"> {{cg.thriving}} </td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</ng-template>
		</div>
		<app-page-footer
			[pageNo]="'CAT'|pageNumber:pageNoOffsetForComparison:'CHARAMETRICS':previewOffset"></app-page-footer>
	</div>

</section>
