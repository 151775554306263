import { backgroundColor, titleStyle } from "../../../utils/chart-options";
import { EChartsOption } from "echarts";
import {
	gridStyle,
	marker,
	textStyle,
	titleGraphicBGGreen
} from "../../../utils/chart-options";
export const CHART_DEFAULTS: EChartsOption | any = {
	...backgroundColor,
	title: {
		...titleStyle
	},
	graphic: titleGraphicBGGreen,
	textStyle: textStyle,
	tooltip: {
		trigger: "item",
		showContent: true,
		alwaysShowContent: false,
		axisPointer: {
			type: "shadow"
		},
		position: function (
			pos: Array<number>,
			params: any,
			dom: HTMLElement,
			rect: any,
			size: any
		) {
			var obj: any = {
				top: rect.y + rect.height / 2 - size.contentSize[1] / 2
			};
			obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
			return obj;
		},
		formatter: (params: any): string | HTMLElement | HTMLElement[] => {
			return `${marker(params)} ${params.name} : ${params.value.toFixed(1)}`;
		}
	},
	legend: {},
	grid: gridStyle,
	xAxis: {
		type: "value",
		boundaryGap: [0, 0],
		minInterval: 1,
		axisTick: {
			show: false
		},
		min: 0,
		max: 3.5,
		axisLine: {
			show: false
		},
		axisLabel: {
			show: true,
			color: "black",
			fontWeight: "bold",
			formatter: (value: number) => {
				if (value == Math.round(value)) return value.toFixed(1);
				return "";
			}
		},
		splitLine: {
			show: true,
			interval: 1,
			lineStyle: {
				color: "#c7c7c4",
				width: 1.5
			}
		}
	},
	yAxis: {
		type: "category",
		axisTick: {
			show: false
		},
		z: 3,
		axisLabel: {
			show: true,
			color: "black",
			width: 100,
			overflow: "break",
			fontWeight: "bold"
		},
		splitLine: {
			show: true,
			interval: 6,
			lineStyle: {
				color: "#c7c7c4",
				width: 1.5
			}
		},
		axisLine: {
			show: true,
			lineStyle: {
				color: "#c7c7c4",
				width: 2.5
			}
		},
		inverse: true
	},
	series: [
		{
			type: "bar",
			barWidth: 15,
			label: {
				show: true,
				position: "insideLeft",
				color: "#000",
				padding: 4,
				backgroundColor: "rgba(255,255,255,0.7)",
				offset: [5, 0],
				formatter: (params: any): string => {
					return (+params.value).toFixed(2);
				}
			},
			markLine: {
				symbol: "none",
				label: {
					show: false
				},
				lineStyle: {
					type: "solid",
					color: "green"
				},
				data: [{ name: "Average", xAxis: 1 }]
			}
		}
	]
};
