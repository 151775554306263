import { formatDate } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { saveAs } from "file-saver";
import { environment } from "src/environments/environment";
import {
	ESTIMATION_PAYLOAD,
	STEP_ELIGIBLE_GROUP_Q_PARAMS,
	STEP_ELIGIBLE_PARENT_Q_PARAMS,
	STEP_GROUP_Q_PARAMS,
	SUBSCRIPTION_INVOICES_Q_PARAMS,
	SUBSCRIPTION_INVOICE_PAY_PAYLOAD,
	SUBSCRIPTION_INVOICE_Q_PARAMS,
	SUBSCRIPTION_INVOICE_RESEND_PAYLOAD,
	SUBSCRIPTION_Q_PARAMS
} from "../types/step-group";
import { removeUndefined } from "../utils/object";

@Injectable({
	providedIn: "root"
})
export class StepGroupService {
	baseURL = environment.stepBaseUrl;
	baseURL_CA = environment.baseUrl;
	constructor(
		private http: HttpClient,
		@Inject(LOCALE_ID) private locale: string
	) {}
	get(groupId: number) {
		const params = new HttpParams({
			fromObject: { groupId }
		});
		return this.http.get<any>(`${this.baseURL}/group`, {
			params
		});
	}
	getParent(qParams: STEP_ELIGIBLE_PARENT_Q_PARAMS = {}) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...qParams,
					sortBy: qParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get<any>(`${this.baseURL}/payment/list_eligible_parent`, {
			params
		});
	}
	getSubscriptionByGroupId(qParams: STEP_GROUP_Q_PARAMS) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...qParams
				} ?? {}
			)
		});
		return this.http.get<any>(
			`${this.baseURL}/payment/get_subscription_detail_by_group_id`,
			{
				params
			}
		);
	}
	getEligibleDependents(qParams: STEP_ELIGIBLE_GROUP_Q_PARAMS) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...qParams,
					sortBy: qParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get<any>(
			`${this.baseURL}/payment/list_eligible_dependent`,
			{
				params
			}
		);
	}
	setupSubscription(qParams: object, payload: any) {
		const params = new HttpParams({
			fromObject: removeUndefined({ ...qParams } ?? {})
		});
		return this.http.post(
			`${this.baseURL}/payment/setup_subscription_hierarchy`,
			payload,
			{ params }
		);
	}
	savePaymentInfo(qParams: { groupSubscriptionId: number }, payload: any) {
		const params = new HttpParams({
			fromObject: removeUndefined({ ...qParams } ?? {})
		});
		return this.http.post(
			`${this.baseURL}/payment/setup_subscription_payment`,
			payload,
			{ params }
		);
	}
	removePaymentInfo(subscriptionStripeId: string) {
		return this.http.delete(
			`${this.baseURL}/payment/remove_payment/${subscriptionStripeId}`
		);
	}

	getPaymentInfos() {
		return this.http.get(`${this.baseURL}/payment/payment_info`);
	}
	getPaymentInfo(id: number) {
		return this.http.get(`${this.baseURL}/payment/payment_info/${id}`);
	}

	getSubscriptions(qParams: SUBSCRIPTION_Q_PARAMS) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{ ...qParams, sortBy: qParams.sortBy?.toUpperCase() } ?? {}
			)
		});
		return this.http.get(`${this.baseURL_CA}/subscriptions`, {
			params
		});
	}
	updateSubscriptionOrg(qParams: object, payload: any) {
		const params = new HttpParams({
			fromObject: removeUndefined({ ...qParams } ?? {})
		});
		return this.http.put(
			`${this.baseURL_CA}/subscriptions/organisation`,
			payload,
			{
				params
			}
		);
	}
	updateSubscriptionTrialPeriod(qParams: object, payload: any) {
		const params = new HttpParams({
			fromObject: removeUndefined({ ...qParams } ?? {})
		});
		return this.http.put(
			`${this.baseURL}/payment/subscriptions/trial`,
			payload,
			{
				params
			}
		);
	}
	getSubscriptionGroupHierarchy(qParams: { groupId: number }) {
		const params = new HttpParams({
			fromObject: removeUndefined({ ...qParams } ?? {})
		});
		return this.http.get(`${this.baseURL}/payment/group_hierarchy_view`, {
			params
		});
	}

	getSubscriptionInvoices(qParams: SUBSCRIPTION_INVOICES_Q_PARAMS) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{ ...qParams, sortBy: qParams.sortBy?.toUpperCase() } ?? {}
			)
		});
		return this.http.get(`${this.baseURL}/payment/invoice_list`, {
			params
		});
	}

	downloadSubscriptionInvoice(
		qParams: SUBSCRIPTION_INVOICE_Q_PARAMS,
		invoice: any,
		subscription: any
	) {
		const params = new HttpParams({
			fromObject: removeUndefined(qParams ?? {})
		});

		return new Promise<void>((resolve, reject) => {
			let yearMonth = formatDate(
				new Date((invoice.meta.created - 60 * 60 * 3) * 1000),
				"YYYY MM",
				this.locale
			);
			let filename = `${yearMonth}-${subscription.group.groupName}|${subscription.group.groupRef}`;
			this.http
				.get(`${this.baseURL}/payment/invoice/download`, {
					params,
					responseType: "blob"
				})
				.subscribe(
					res => {
						saveAs(res, filename);
						resolve();
					},
					err => {
						reject(err);
					}
				);
		});
	}

	resendSubscriptionInvoice(payload: SUBSCRIPTION_INVOICE_RESEND_PAYLOAD) {
		return this.http.post(`${this.baseURL}/payment/invoice/resend`, payload);
	}
	retryInvoicePaymentNow(payload: SUBSCRIPTION_INVOICE_PAY_PAYLOAD) {
		return this.http.post(`${this.baseURL}/payment/invoice/pay`, payload);
	}

	getUniqueUsersCount(
		qParams: { groupId: number },
		payload: ESTIMATION_PAYLOAD
	) {
		const params = new HttpParams({
			fromObject: qParams
		});
		return this.http.post(
			`${this.baseURL}/payment/get_unique_users_count`,
			payload,
			{
				params
			}
		);
	}
	getSetupIntent() {
		return this.http.get<{ clientSecret: string }>(
			`${this.baseURL}/payment/setup_intent`
		);
	}
	createGroup(orgId: number, payload: any) {
		const params = new HttpParams({
			fromObject: { orgId }
		});
		return this.http.post(`${this.baseURL}/group/portal`, payload, { params });
	}
	updateGroup(orgId: number, groupId: number, payload: any) {
		const params = new HttpParams({
			fromObject: { orgId, groupId }
		});
		return this.http.put(`${this.baseURL}/group/portal`, payload, { params });
	}
	createDependents(orgId: number, parentId: number, payload: any) {
		const params = new HttpParams({
			fromObject: { orgId, parentId }
		});
		return this.http.post(`${this.baseURL}/group/portal_dependents`, payload, {
			params
		});
	}
	updateSubscriptionInvoiceEmails(groupSubscriptionId: number, payload: any) {
		const params = new HttpParams({
			fromObject: { groupSubscriptionId }
		});
		return this.http.put(
			`${this.baseURL}/payment/update_subscription_invoice_emails`,
			payload,
			{
				params
			}
		);
	}
}
