import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AutoUnsubscribeOnDestroy } from "src/app/_shared/decorators/autoUnsubscribeOnDestroy";
import { ST_PLATFORM } from "src/app/_shared/types/common";
import { AuthService } from "../../../services/auth.service";
import { DialogsService } from "../../../services/dialogs.service";

@Component({
	selector: "app-top-bar",
	templateUrl: "./top-bar.component.html",
	styleUrls: ["./top-bar.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class TopBarComponent implements OnInit {
	user!: any;
	subscriptions = new Subscription();
	@Input() ST_PLATFORM!: ST_PLATFORM;
	constructor(
		public dialogsService: DialogsService,
		private authService: AuthService
	) {}
	ngOnInit() {
		this.getUser();
	}
	getUser() {
		this.subscriptions.add(
			this.authService.getUserInfo().subscribe(user => {
				this.user = user;
			})
		);
	}
	logout() {
		this.authService.logout();
	}
	openChangePassword() {
		let dialogRef = this.dialogsService.openChangePassword(
			"change-password-" + this.ST_PLATFORM,
			{ forPlatform: this.ST_PLATFORM, force: false }
		);
		if (dialogRef) {
			this.subscriptions.add(
				dialogRef.afterClosed().subscribe(res => {
					if (res?.changed) {
						this.user.forceChangePwd = false;
						this.authService.setUserInfo(this.user);
					}
				})
			);
		}
	}
}
