<h1 *ngIf="dialogRef" mat-dialog-title>
	Update Invoice Emails
</h1>
<button *ngIf="dialogRef" mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<form [formGroup]="fg" (submit)="update()">
	<mat-dialog-content>
		<app-emails fcName="emails" [fg]="fg">
		</app-emails>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button [mat-dialog-close]>
			Close
		</button>
		<button mat-raised-button color="primary" type="submit">
			Update
		</button>
	</mat-dialog-actions>
</form>