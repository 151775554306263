import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable, Subscription } from "rxjs";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { AspirationService } from "./../../services/aspiration.service";

@Component({
	selector: "app-aspiration",
	templateUrl: "./aspiration.component.html",
	styleUrls: ["./aspiration.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class AspirationComponent implements OnInit {
	loaderId = "aspiration";
	@Input() aspirationCtrl!: FormGroup;
	filteredAspirations!: any[];
	aspirationsList!: Observable<Array<any>>;
	subscriptions = new Subscription();
	constructor(private aspirationService: AspirationService) {
		this.aspirationsList = this.aspirationService.aspirations;
	}
	get name() {
		return this.aspirationCtrl.get("name") as FormControl;
	}
	ngOnInit(): void {
		this.subscriptions.add(
			this.aspirationsList.subscribe(aspirations => {
				this.subscriptions.add(
					this.name.valueChanges.subscribe((value: string) => {
						if (value) {
							this.filteredAspirations = aspirations.filter(aspiration =>
								aspiration?.name.toLowerCase().includes(value.toLowerCase())
							);
							if (
								this.filteredAspirations.length < 1 ||
								!this.filteredAspirations.some(aspiration => {
									return (
										aspiration.name.toLocaleLowerCase() ===
										value.toLocaleLowerCase()
									);
								})
							) {
								this.name.setErrors({ incorrect: true });
							} else {
								this.name.setErrors(null);
							}
							if (
								this.filteredAspirations.length == 1 &&
								this.filteredAspirations[0].name.toLocaleLowerCase() ===
									value.toLocaleLowerCase()
							) {
								this.filteredAspirations = aspirations;
							}
						} else {
							this.filteredAspirations = aspirations;
						}
					})
				);
			})
		);
	}
	onSelected($event: MatAutocompleteSelectedEvent) {
		this.aspirationCtrl.patchValue({
			value: $event.option.id,
			name: $event.option.value
		});
	}

	clearSelection() {
		this.aspirationCtrl.patchValue({ name: null, value: null });
	}
	displayWith(value: any) {
		return value?.name;
	}
}
