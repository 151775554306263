import { OrganisationService } from "./../../services/organisation.service";
import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { PartnerSurveyService } from "./../../services/partner-survey.service";
import { ORG } from "../../types/organisation";
interface IPartnerSurvey {
	id: number;
	name: string;
	isPublic?: boolean;
	org?: ORG;
}
@Component({
	selector: "app-partner-survey-select",
	templateUrl: "./partner-survey-select.component.html",
	styleUrls: ["./partner-survey-select.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class PartnerSurveySelectComponent
	implements OnInit, OnDestroy, OnChanges
{
	subscriptions = new Subscription();
	org?: ORG | null;
	/** control for the selected partnerSurvey */
	@Input() partnerSurveyCtrl: FormControl = new FormControl();
	@Input() readOnly?: boolean = false;
	@Input() showAll = true;
	@Input() isGlobalSelectedOrg = true;
	@Input() selectedOrg?: ORG;
	get disabled() {
		return this.readOnly ?? false;
	}
	/** control for the MatSelect filter keyword */
	public partnerSurveyFilterCtrl: FormControl = new FormControl();

	/** list of partnerSurveys filtered by search keyword */
	public filteredPartnerSurveys: ReplaySubject<IPartnerSurvey[]> =
		new ReplaySubject<IPartnerSurvey[]>(1);

	/** Subject that emits when the component has been destroyed. */
	protected _onDestroy = new Subject<void>();

	constructor(
		public partnerSurveyService: PartnerSurveyService,
		private organisationService: OrganisationService
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedOrg) {
			this.getPartnerSurveys();
		}
	}

	ngOnInit() {
		// listen for search field value changes
		this.partnerSurveyFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterPartnerSurveys();
			});
		if (this.isGlobalSelectedOrg && !this.readOnly) {
			this.subscriptions.add(
				this.organisationService
					.getSelectedOrg()
					.subscribe((org: ORG | null) => {
						this.org = org;
						this.getPartnerSurveys();
						this.partnerSurveyCtrl.setValue(null);
					})
			);
		}
	}
	getPartnerSurveys(searchText: string = "") {
		this.partnerSurveyService
			.getPartnerSurvey({
				searchText,
				orgId: this.selectedOrg?.id ?? this.org?.id
			})
			.pipe(takeUntil(this._onDestroy))
			.subscribe(res => {
				this.filteredPartnerSurveys.next(res.list);
			});
	}

	compareFn(a: IPartnerSurvey, b: IPartnerSurvey): boolean {
		return a && b && a.id === b.id;
	}
	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	protected filterPartnerSurveys() {
		// get the search keyword
		let search = this.partnerSurveyFilterCtrl.value;
		if (!search) {
			this.getPartnerSurveys();
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the partnerSurveys
		this.getPartnerSurveys(search);
	}
}
