import { environment } from "./../../../environments/environment.prod";
import { DeviceDetectorService } from "ngx-device-detector";
import { Injectable } from "@angular/core";
import { randstr } from "../utils/string";

@Injectable({
	providedIn: "root"
})
export class DeviceService {
	platform = "WEB";
	constructor(private deviceService: DeviceDetectorService) {}
	async getDeviceInfo() {
		try {
			let deviceInfo = this.deviceService.getDeviceInfo();
			let token = "";
			return {
				deviceId: this.getDeviceId(),
				pushToken: token,
				deviceName: deviceInfo.deviceType,
				model: deviceInfo.userAgent,
				osVersion: deviceInfo.os_version,
				appVersion: environment.version,
				platform: this.platform
			};
		} catch (error) {
			throw new Error("Error in getting device info");
		}
	}
	getDeviceId() {
		let deviceId = localStorage.getItem("deviceId");
		if (!deviceId) {
			deviceId = randstr("deviceId_");
			localStorage.setItem("deviceId", deviceId);
		}
		return deviceId;
	}
}
