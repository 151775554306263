<section>
	<div class="ca-charts-container "
		[ngClass]="{'dual-comparison-height':isComparison,'no-border':isSingleComparison||isDualComparison}">
		<!-- TODO: fix loading  -->
		<div class="ca-info-chart">
			<!-- <div class="ca-culture-fit-info" *ngIf="isSingleComparison">
				<div>
					Culture Fit
					<span class="ca-culture-fit-value margin-left-10">{{(group||compReport||compGroupReport).cultureFit
						| number:
						'2.1-2'}}%</span>
				</div>
				<div>
					Fit Type
					<span class="ca-culture-fit-type margin-left-10">{{(group||compReport||compGroupReport).fitType}}</span>
				</div>
			</div> -->
			<div class="ca-culture-fits-info" *ngIf="isComparison">
				<table class="ca-culture-fits-info-table">
					<tr>
						<td class="type group-name-highlight right-align pad-right border-right-thin"><b>{{(group || compReport
								||{ name:compGroupReport.name||compGroupReport.reportNames.join(',')}).name|truncate:14}}
								Alignment</b></td>
						<td class="value group-value-highlight center-align border-right">
							<b>
								{{(group ||compReport||compGroupReport).cultureFit | number: '2.1-1'}}%</b>
						</td>
						<td class="value  center-align" [class.border-right-thin]="isSecondComparison"
							[class.role-value-highlight]="isSecondComparison">
							<b *ngIf="isSecondComparison">
								{{(role||compReport2||compGroupReport2).cultureFit | number:'2.1-1'}}%
							</b>
						</td>
						<td class="type  pad-left" [class.role-name-highlight]="isSecondComparison">
							<b *ngIf="isSecondComparison">
								{{
								(role
								||compReport2
								||{name:compGroupReport2.name||compGroupReport2.reportNames.join(',')}
								).name
								|truncate:14}}
								Alignment</b>
						</td>
					</tr>
					<tr>
						<td class="type right-align pad-right border-right-thin">Alignment Type</td>
						<td class="value center-align border-right"><b>{{(group ||compReport
								||compGroupReport).fitType}}</b></td>
						<td class="value center-align" [class.border-right-thin]="isSecondComparison"><b
								*ngIf="isSecondComparison">{{(role||compReport2||compGroupReport2).fitType}}</b>
						</td>
						<td class="type pad-left" *ngIf="isSecondComparison">Alignment Type</td>
					</tr>
				</table>
			</div>
			<div *ngIf="isComparison" class="ca-culture-fits-legend-table-container ">
				<table>
					<tr>
						<td class="border-right" align="right">Culture Alignment</td>
						<td class="border-right" align="center">&lt;60 = Average</td>
						<td class="border-right" align="center">60 to 70 = Good</td>
						<td class="border-right" align="center">71 to 80 = Strong</td>
						<td align="center">&gt;80 = Very Strong </td>
					</tr>
				</table>
			</div>
			<div class="ca-chart-with-aspirations">

				<div class="ca-chart" [ngClass]="{'ca-no-border':group && role}" echarts [loadingOpts]="loadingOpts"
					[loading]="isLoading" [initOpts]="initOpts" [options]="default" [merge]="chartOptions">
					<!-- <div *ngIf="isSingleComparison" class="ca-culture-fits-legend">
						<table>
							<caption>Culture Fit Score</caption>
							<tr>
								<td align="center">&lt;60 = Average</td>
							</tr>
							<tr>
								<td align="center">60 to 70 = Good</td>
							</tr>
							<tr>
								<td align="center">71 to 80 = Strong</td>
							</tr>
							<tr>
								<td align="center">&gt;80 = Very Strong</td>
							</tr>
						</table>
					</div> -->
				</div>
				<div class="ca-aspirations-container ca-aspirations-group" *ngIf="group && isComparison">
					<ng-container [ngTemplateOutlet]="aspirationsTemplate"
						[ngTemplateOutletContext]="{ title:'Primary',aspirations:group.primaryAspirations,groups:group.primaryAspirationsGroups }">
					</ng-container>
					<ng-container [ngTemplateOutlet]="aspirationsTemplate" [ngTemplateOutletContext]="{
                            title:'Secondary',
                            aspirations:group.secondaryAspirations,groups:group.secondaryAspirationsGroups }">
					</ng-container>
				</div>
				<div class="ca-aspirations-container  ca-aspirations-role" *ngIf="role && isComparison">
					<ng-container [ngTemplateOutlet]="aspirationsTemplate" [ngTemplateOutletContext]="{
                                title:'Primary',
                                aspirations:role.primaryAspirations,
                                groups:role.primaryAspirationsGroups }">
					</ng-container>

					<ng-container [ngTemplateOutlet]="aspirationsTemplate" [ngTemplateOutletContext]="{title:'Secondary',
                        aspirations:role.secondaryAspirations,
                        groups:role.secondaryAspirationsGroups }">
					</ng-container>
				</div>
			</div>
		</div>
		<!-- <div class="ca-chart-info" *ngIf="group && !isDualComparison">
			<h6 class="ca-chart-info-title">Organisation/Role Culture Aspiration of </h6>
			<h4 class="ca-chart-info-subtitle">{{group.name}}</h4>
			<div class="ca-aspirations-container">
				<div class="ca-aspirations">
					<table class="ca-aspirations-table">
						<tr class="ca-aspirations-table-head-row">
							<th class="ca-aspirations-table-head">Primary Aspirations</th>
							<th class="ca-aspirations-table-head">Group</th>
						</tr>
						<tr class="ca-aspirations-table-content-row" *ngFor="let asp of group.primaryAspirations;let i=index">
							<td class="ca-aspirations-table-content-data">{{asp|titlecase}}</td>
							<td class="ca-aspirations-table-content-data">
								{{group.primaryAspirationsGroups[i]|titlecase}}
							</td>
						</tr>
					</table>
				</div>
				<div class="ca-aspirations">
					<table class="ca-aspirations-table">
						<tr class="ca-aspirations-table-head-row">
							<th class="ca-aspirations-table-head">Secondary Aspirations</th>
							<th class="ca-aspirations-table-head">Group</th>
						</tr>
						<tr class="ca-aspirations-table-content-row" *ngFor="let asp of group.secondaryAspirations;let i=index">
							<td class="ca-aspirations-table-content-data">{{asp|titlecase}}</td>
							<td class="ca-aspirations-table-content-data">
								{{group.secondaryAspirationsGroups[i]|titlecase}}
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div> -->
	</div>
</section>

<ng-template #aspirationsTemplate let-title="title" let-aspirations="aspirations" let-groups="groups">
	<div class="ca-aspiration">
		<div class="ca-aspiration-title">{{title}} Aspirations</div>
		<table class="ca-aspirations-table">
			<tr class="ca-aspirations-table-content-row" *ngFor="let asp of aspirations;let i=index">
				<td class="ca-aspirations-table-content-data">
					<div class=" ca-aspirations-table-content-data-div ca-aspirations-table-content-data-aspiration">
						{{asp|titlecase}}
					</div>
					<div class="ca-aspirations-table-content-data-div ca-aspirations-table-content-data-group">
						{{groups[i]|titlecase}} Group
					</div>
				</td>
			</tr>
		</table>
	</div>
</ng-template>