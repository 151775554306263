import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: "app-error-page",
	templateUrl: "./error-page.component.html",
	styleUrls: ["./error-page.component.scss"]
})
export class ErrorPageComponent {
	@Input() error: any;
	@Input() clientSide = false;
	constructor(private activatedroute: ActivatedRoute) {
		this.activatedroute.data.subscribe(data => {
			if (data.errorCode) {
				this.clientSide = true;
				this.error = {
					statusCode: data.errorCode
				};
			}
		});
	}
}
