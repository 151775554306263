import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-page-footer",
	templateUrl: "./page-footer.component.html",
	styleUrls: ["./page-footer.component.scss"]
})
export class PageFooterComponent {
	@Input() hideCopyRights: boolean = false;
	@Input() pageNo?: number;
	@Input() onlyOnPrint?: boolean = true;
	constructor() {}
}
