<section class="card-container">
	<ng-container *ngFor="let item of characterGroups.moral; let i=index">
		<ng-container [ngTemplateOutlet]="card" [ngTemplateOutletContext]="{item:characterGroups.moral[i]}"></ng-container>
		<ng-container [ngTemplateOutlet]="card"
			[ngTemplateOutletContext]="{item:characterGroups.performance[i]}"></ng-container>
	</ng-container>
</section>
<ng-template #card let-item="item">
	<div class="card" [class.empty]="!item">
		<ng-container *ngIf="item">
			<mat-checkbox *ngIf="isMultiple" [checked]="selection.isSelected(item)" color="primary" (click)="select(item)">
				&nbsp; </mat-checkbox>
			<mat-radio-button *ngIf="!isMultiple" [checked]="selection.isSelected(item)" color="primary"
				(click)="select(item)"></mat-radio-button>
			<img (click)="select(item)" height="auto" width="100%"
				[src]="'/assets/images/character-cards/'+(item|lowercase)+'.png'">
		</ng-container>
	</div>
</ng-template>