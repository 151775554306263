<section *ngIf="fg">
	<form [formGroup]="fg">
		<div class="flex gap-20 align-center">
			<mat-form-field style="width: 250px;">
				<mat-label>Your Mobile <ng-container *ngIf="optional">(Optional)</ng-container>
				</mat-label>
				<input matInput [formControlName]="fcName" [readonly]="OTPsent">
				<mat-error *ngIf="fg.get(fcName)?.touched && fg.get(fcName)?.errors?.required">
					Please type in Mobile Number.
				</mat-error>
				<mat-error *ngIf="fg.get(fcName)?.touched && fg.get(fcName)?.errors?.phone">
					Please type in a Valid Mobile Number.
				</mat-error>
				<button *ngIf="OTPsent" matSuffix color="primary" mat-icon-button aria-label="Edit" (click)="editMobileNo()">
					<mat-icon>edit</mat-icon>
				</button>
			</mat-form-field>
			<ng-container *ngIf="isVerified;else verifyTRef">
				<mat-icon color="primary">check_circle</mat-icon>
			</ng-container>
			<ng-template #verifyTRef>
				<div *ngIf="!OTPsent && fg.get(fcName)?.value && fg.get(fcName)?.valid" class="flex gap-10">
					<button style="flex:0 0 130px;" [disabled]="fg.get(fcName)?.invalid || !fg.get(fcName)?.value"
						(click)="sendOTP()" mat-flat-button color="primary" type="button"> Send SMS</button>
					<span>
						<mat-hint>
							We will send you a passcode to verify your number.
						</mat-hint>
					</span>
				</div>
				<div *ngIf="!(isVerified) && OTPsent" class="flex gap-20 align-center">
					<mat-form-field style="width: 250px;">
						<mat-label>Passcode</mat-label>
						<input matInput autocomplete="one-time-code" type="text" inputmode="numeric" minlength="6" maxlength="6"
							size="6" [formControl]="otpFC">
					</mat-form-field>
					<div *ngIf="OTPsent &&  !otpFC?.value" class="flex flex-col">
						<mat-hint>
							Please type in the passcode we have sent via SMS.
						</mat-hint>
						<button [disabled]="(((secondsRemaining$|async) || -1)>0)" (click)="sendOTP()" mat-button color="primary"
							type="button">
							Resend
							<ng-container *ngIf="(((secondsRemaining$|async) || -1)>0)">
								({{secondsRemaining$| async}}s)
							</ng-container>
						</button>
					</div>
					<!-- <button *ngIf="OTPsent && otpFC?.valid" [disabled]="fg.get(fcName)?.invalid || !fg.get(fcName)?.value"
						(click)="verifyOTP()" mat-flat-button color="primary" type="button"> Verify
					</button> -->
				</div>
			</ng-template>
		</div>
	</form>
</section>