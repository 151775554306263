import { Clipboard } from "@angular/cdk/clipboard";
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { uniqueId } from "lodash";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
	Observable,
	Subscription,
	interval,
	merge,
	of as observableOf
} from "rxjs";
import {
	catchError,
	debounce,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
	tap
} from "rxjs/operators";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { AuthService } from "../../services/auth.service";
import { USER } from "../../types/user";
import { getSurveyURL } from "../../utils/string";
import { ActivityLogsComponent } from "../activity-logs/activity-logs.component";
import {
	ConfirmDialogComponent,
	ConfirmDialogModel
} from "../confirm-dialog/confirm-dialog.component";
import { PartnerSurveyComponent } from "../partner-survey/partner-survey.component";
import { ReportsDialogComponent } from "../reports-dialog/reports-dialog.component";
import { OrganisationService } from "./../../services/organisation.service";
import { PartnerSurveyService } from "./../../services/partner-survey.service";
import { PARTNER_SURVEY_ACTION } from "./../../types/common";
import { ORG } from "./../../types/organisation";
import { MatTableWrapperComponent } from "../../class/mat-table-wrapper";

@Component({
	selector: "app-surveys-table",
	templateUrl: "./surveys-table.component.html",
	styleUrls: ["./surveys-table.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class SurveysTableComponent
	extends MatTableWrapperComponent
	implements AfterViewInit, OnChanges
{
	subscriptions = new Subscription();
	@Input() isPublic: boolean = false;
	@Input() index?: number = 10;

	loaderId = uniqueId("loader_");
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	refreshEvent = new EventEmitter();
	filterFormControl: FormControl = new FormControl();
	pageSize = 10;
	data: any[] = [];
	resultsLength = 0;
	isLoadingResults = false;
	displayedColumns: string[] = [];
	org?: ORG | null;
	user?: USER | null;
	constructor(
		private UiLoaderService: NgxUiLoaderService,
		private clipboard: Clipboard,
		private dialog: MatDialog,
		public partnerSurveyService: PartnerSurveyService,
		public authService: AuthService,
		public router: Router,
		private route: ActivatedRoute,
		private _snackBar: MatSnackBar,
		private organisationService: OrganisationService
	) {
		super();
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe((org: ORG | null) => {
				this.org = org;
				this.refreshEvent.next();
			})
		);
		this.subscriptions.add(
			this.authService.getUserInfo().subscribe(user => (this.user = user))
		);
	}
	ngAfterViewInit() {
		this.init();
		const queryParamsSnapshot = this.route.snapshot.queryParams;
		if (
			queryParamsSnapshot.modal &&
			queryParamsSnapshot.tabIndex == this.index
		) {
			this.partnerSurveyService
				.getPartnerSurveyById(queryParamsSnapshot.modal)
				.subscribe(res => {
					if (res.statusCode == 200) {
						this.openReports(res.result);
					}
				});
		}
		// If the user changes the sort order, reset back to the first page.
		this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		merge(
			this.sort?.sortChange,
			this.paginator?.page,
			this.filterFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				tap(() => {
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
				}),
				distinctUntilChanged()
			),
			this.refreshEvent
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					setTimeout(() => {
						this.isLoadingResults = true;
					}, 0);
					return this.partnerSurveyService!.getPartnerSurvey({
						orgId: this.org?.id,
						offset: this.paginator.pageIndex * this.paginator.pageSize,
						limit: this.paginator.pageSize,
						sortingKey: this.sort.active,
						sortBy: this.sort.direction,
						searchText: this.filterFormControl.value || undefined,
						isPublic: this.isPublic
					}).pipe(catchError(() => observableOf(null)));
				}),
				map(data => {
					// Flip flag to show that loading has finished.
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
					setTimeout(() => {
						this.isLoadingResults = false;
					}, 0);
					if (data === null) {
						return [];
					}
					data.list.forEach(async (partnerSurvey: any) => {
						if (partnerSurvey?.orgId) {
							partnerSurvey.members = this.getMembers(partnerSurvey?.orgId);
						}
					});
					this.resultsLength = data.total;
					return data.list;
				})
			)
			.subscribe(data => {
				this.data = data;
				this.reRender();
			});
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.isPublic) {
			this.refreshEvent.emit();
		}
	}
	init() {
		this.displayedColumns = ["index", "name", "surveyLink"];
		if (!this.isPublic) {
			this.displayedColumns = [...this.displayedColumns, "noOfReports"];
		}
		this.displayedColumns = [
			...this.displayedColumns,
			"noOfReportsUtilized",
			"members",
			"org",
			"createdBy",
			"createdAt",
			"log"
		];
	}
	copyShareLink(element: any) {
		const surveyURL = getSurveyURL();
		this.clipboard.copy(`${surveyURL}/character/${element.slug}`);
	}
	openCreatePartnerSurvey() {
		const dialogRef = this.dialog.open(PartnerSurveyComponent, {
			width: "80%",
			minHeight: "80%",
			maxHeight: "80%",
			panelClass: "ca-dialog",
			data: {
				action: "CREATE"
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result?.refresh) {
				this.refreshEvent.emit();
			}
		});
	}
	openNoOfReportEditDialog(
		partnerSurvey: any,
		action: Exclude<PARTNER_SURVEY_ACTION, "CREATE">
	) {
		const dialogRef = this.dialog.open(PartnerSurveyComponent, {
			width: "700px",
			minHeight: "60%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				action,
				partnerSurvey
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result?.refresh) {
				this.refreshEvent.emit();
			}
		});
	}
	openReports(partnerSurvey: any) {
		this.router.navigate(["."], {
			relativeTo: this.route,
			queryParams: { modal: partnerSurvey.id },
			queryParamsHandling: "merge"
		});
		const dialogRef = this.dialog.open(ReportsDialogComponent, {
			width: "100%",
			minHeight: "80%",
			height: "80vh",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				partnerSurvey,
				freezeFilter: {
					partnerSurvey: true
				}
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			this.router.navigate(["."], {
				relativeTo: this.route,
				queryParams: { modal: undefined },
				queryParamsHandling: "merge"
			});
		});
	}
	openLogs(partnerSurvey: any) {
		this.dialog.open(ActivityLogsComponent, {
			width: "100%",
			minHeight: "80%",
			height: "80vh",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				partnerSurvey,
				activityOf: "PARTNER_SURVEY"
			}
		});
	}
	getMembers(orgId: number): Observable<USER[]> {
		return new Observable(observer => {
			this.organisationService
				.getMembers(orgId, { role: "MEMBER" })
				.subscribe((res: any) => {
					observer.next(res.list);
					observer.complete();
				});
		});
	}

	update(value: any, partnerSurvey: any) {
		partnerSurvey.newMemberIds = value;
	}
	async updateSelected(
		{ $event, matSelect }: { $event: boolean; matSelect: MatSelect },
		partnerSurvey: any
	) {
		if (partnerSurvey.newMemberIds && $event === false) {
			let confirm = await this.confirmIfUpdatePartnerSurveyMembers(
				partnerSurvey
			);
			if (confirm) {
				let payload = {
					memberIds: partnerSurvey.newMemberIds
				};
				this.partnerSurveyService
					.update(partnerSurvey.id, payload)
					.subscribe((res: any) => {
						partnerSurvey.newMemberIds = null;
						if (res.statusCode === 200) {
							this._snackBar.open("Members updated.");
						} else {
							matSelect.value = partnerSurvey.memberIds;
						}
					});
			} else {
				matSelect.value = partnerSurvey.memberIds;
				partnerSurvey.newMemberIds = null;
			}
		}
	}

	confirmIfUpdatePartnerSurveyMembers(partnerSurvey: any): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `Do you wish to Update who has Access to the ${partnerSurvey.name} Survey and its Reports?`;
			const dialogData = new ConfirmDialogModel("Confirm Action", {
				message,
				confirmText: "Update",
				dismissText: "Cancel"
			});
			const dialogRef = this.dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
}
