import {
	AfterViewInit,
	Component,
	EventEmitter,
	Inject,
	Optional,
	ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription, merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { LoggerService } from "../../services/logger.service";
import { ShareInvoiceComponent } from "../share-invoice/share-invoice.component";
import { StepGroupService } from "./../../services/step-group.service";
@Component({
	selector: "app-step-group-invoices",
	templateUrl: "./step-group-invoices.component.html",
	styleUrls: ["./step-group-invoices.component.scss"]
})
export class StepGroupInvoicesComponent implements AfterViewInit {
	subscriptions = new Subscription();
	loaderId = "reports-loader";
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	refreshEvent = new EventEmitter();
	filterFormControl: FormControl = new FormControl();
	data: any[] = [];
	resultsLength = 0;
	pageSize = 10;
	isLoadingResults = false;
	displayedColumns = [
		"index",
		"period",
		"quantity",
		"amount",
		"invoiceStatus",
		"actions",
		"createdAt",
		"updatedAt"
	];
	constructor(
		private UiLoaderService: NgxUiLoaderService,
		private stepGroupService: StepGroupService,
		@Optional() public dialogRef: MatDialogRef<StepGroupInvoicesComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
		private Logger: LoggerService,
		private _snackBar: MatSnackBar,
		private _dialog: MatDialog
	) {}
	ngAfterViewInit() {
		// If the user changes the sort order, reset back to the first page.
		this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		merge(this.sort?.sortChange, this.paginator?.page, this.refreshEvent)
			.pipe(
				startWith({}),
				switchMap(() => {
					setTimeout(() => {
						this.isLoadingResults = true;
					}, 0);
					return this.stepGroupService!.getSubscriptionInvoices({
						offset: this.paginator.pageIndex * this.paginator.pageSize,
						limit: this.paginator.pageSize,
						sortingKey: this.sort.active,
						sortBy: this.sort.direction,
						subscriptionStripeId:
							this.dialogData?.subscription?.subscriptionStripeId
					}).pipe(catchError(() => observableOf(null)));
				}),
				map((data: any) => {
					// Flip flag to show that loading has finished.
					setTimeout(() => {
						this.isLoadingResults = false;
					}, 0);
					if (data === null) {
						return [];
					}
					this.resultsLength = data.total;
					return data.list;
				})
			)
			.subscribe(data => (this.data = data));
	}

	async download(invoice: any) {
		this.UiLoaderService.startLoader(invoice.stripeId);
		try {
			await this.stepGroupService.downloadSubscriptionInvoice(
				{
					stripeId: invoice.stripeId
				},
				invoice,
				this.dialogData?.subscription
			);
		} catch (error) {
			this.Logger.error(error);
		} finally {
			this.UiLoaderService.stopLoader(invoice.stripeId);
		}
	}
	openShareInvoice(invoice: any) {
		this._dialog.open(ShareInvoiceComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				subscription: this.dialogData?.subscription,
				invoice
			}
		});
	}
	retryInvoicePaymentNow(invoice: any) {
		this.UiLoaderService.startLoader(this.loaderId);
		this.stepGroupService
			.retryInvoicePaymentNow({
				stripeId: invoice.stripeId
			})
			.subscribe((res: any) => {
				if (res.statusCode === 200) {
					this.refreshEvent.emit();
					this._snackBar.open("Payment Successfull");
				}
			});
	}
}
