<section class="stepper-container">
	<header>
		<ng-container *ngFor="let step of steps; let i = index;let last=last">
			<button class="stepper-step" [class.stepper-active]="selectedIndex === i">
				{{step.label}}
			</button>
			<span *ngIf="!last" class="line"></span>
		</ng-container>
	</header>
	<div [ngTemplateOutlet]="selected ? selected.content : null"></div>
</section>