<h1 mat-dialog-title>Pay For Report - {{data.report.name}}</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
    <ng-container [ngSwitch]="data.by">
        <ng-container *ngSwitchCase="'PAY_BY_USING_CREDITS'">
            <b>{{costPerAssignmentAfterDiscount}}</b> will be debited from your Available Credits.
            <div *ngIf="org?.orgType">
                <small>
                    *After {{org.orgType|orgTypeDiscount}} Discount for
                    {{org.orgType|orgType}}
                </small>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'PAY_BY_SHARETREE'">
            You are Making this as <b> Paid by ShareTree</b><br>
            <small><b> No Credits Points will be debited.</b> </small>
        </ng-container>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
    <span class="ca-spacer"></span>
    <button mat-button [mat-dialog-close]>Cancel</button>
    <button mat-raised-button color="primary" (click)="payForReport()">
        <ng-container [ngSwitch]="data.by">
            <ng-container *ngSwitchCase="'PAY_BY_USING_CREDITS'">
                Pay by using Credits
            </ng-container>
            <ng-container *ngSwitchCase="'PAY_BY_SHARETREE'">
                Pay by ShareTree
            </ng-container>
        </ng-container>
    </button>
</mat-dialog-actions>