<h1 mat-dialog-title>
	Share Report - {{data?.report?.name}}
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<mat-card>
		<form *ngIf="shareFG" [formGroup]="shareFG" (submit)="send()">
			<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
				<app-emails class="full-width" fcName="emailIds" [fg]="shareFG" label="Recipients"
					placeholder="Type in Email addresses">
				</app-emails>
				<div fxFlex="0 0 150px" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="16px">
					<button type="button" mat-button (click)="reset()">Reset</button>
					<button type="submit" [disabled]="shareFG.invalid" mat-flat-button color="primary">
						<mat-icon>send</mat-icon>
						Send
					</button>
				</div>
			</div>
		</form>
	</mat-card>
</mat-dialog-content>
