import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { StepUserService } from "../../services/step-user.service";
import { STEP_USER } from "../../types/user";

@Component({
	selector: "app-ep-user-search",
	templateUrl: "./ep-user-search.component.html",
	styleUrls: ["./ep-user-search.component.scss"]
})
export class EPUserSearchComponent implements OnInit, OnDestroy {
	/** control for the selected Group report */
	@Input() fcName: string = "users";
	@Input() fg?: FormGroup;
	@Input() multiple? = false;
	/** control for the MatSelect filter keyword */
	public filterFC: FormControl = new FormControl();

	/** list of reports filtered by search keyword */
	public filtered: ReplaySubject<STEP_USER[]> = new ReplaySubject<STEP_USER[]>(
		1
	);

	/** Subject that emits when the component has been destroyed. */
	protected _onDestroy = new Subject<void>();

	constructor(public stepUserService: StepUserService) {}
	get FC(): FormControl {
		return this.fg?.get(this.fcName) as FormControl;
	}
	ngOnInit() {
		// listen for search field value changes
		this.filterFC.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filter();
			});
	}
	get(searchText: string = "") {
		this.stepUserService
			.search({ searchText, limit: 50 })
			.pipe(takeUntil(this._onDestroy))
			.subscribe((res: any) => {
				this.filtered.next(res.users);
			});
	}

	compareFn(a: STEP_USER, b: STEP_USER): boolean {
		return a && b && a.id === b.id;
	}
	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}
	clearSelection($event: MouseEvent) {
		this.FC?.reset();
		$event.stopPropagation();
	}
	protected filter() {
		// get the search keyword
		let search = this.filterFC.value;
		if (!search) {
			this.get();
			return;
		} else {
			search = search.toLowerCase();
		}
		this.get(search);
	}
}
