import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	Output
} from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";

@Directive({
	selector: "[appCopyToClipboard]"
})
export class CopyToClipboardDirective {
	constructor(private _clipboard: Clipboard, private _snack: MatSnackBar) {}
	@Input("appCopyToClipboard")
	public payload!: string;
	@Output()
	public readonly copied: EventEmitter<string> = new EventEmitter<string>();

	@HostListener("click", ["$event"])
	public onClick(event: MouseEvent): void {
		this._clipboard.copy(this.payload);
		this._snack.open(`Copied to Clipboard "${this.payload}"`);
		event.preventDefault();
		event.stopPropagation();
	}
}
