<div class="preview-container" [class.ca-print-page]="pageMargin">
	<span class="sample-only-text">Unlock Full Report</span>
	<img class="preview-bg" width="100%" height="auto" src="{{bg}}" alt="">
	<div class="preview-info">
		<ng-container [ngSwitch]="case">
			<ng-container *ngSwitchCase="'PAID_BY_PARTNER'">You will be contacted soon by your Culture Practitioner with your
				<br> full Charametrics Report.</ng-container>
			<ng-container *ngSwitchCase="'PUBLIC_PAID_BY_PARTNER'">
				The full version of this report has already been made available to your Culture Practitioner, please contact
				them for your report.
			</ng-container>

			<ng-container *ngSwitchCase="'PAID_BY_ADMIN'">
				You will be contacted soon by your Culture Practitioner with your <br> full Charametrics Report.
			</ng-container>
			<ng-container *ngSwitchCase="'PUBLIC_PAID_BY_ADMIN'">
				You will be contacted soon by your Culture Practitioner with your <br> full Charametrics Report.
			</ng-container>

			<ng-container *ngSwitchCase="'PAID_BY_USER'">
				This report has already been purchased. Please check your email. <br> Should you require any support, please
				email us
				at <a
					href="mailto:support@sharetree.org?subject=Charametrics Report Purchase Issue&body={{body}}">support@sharetree.org</a>
			</ng-container>
			<ng-container *ngSwitchCase="'UNPAID'">

				<ng-container *ngIf="!report?.character360">
					<div class="preview-heading">
						Unlock the full report to get:
					</div>
					<div class="preview-content">
						<ul class="list-of-benefits">
							<li> Detailed analysis of 3 main character strengths</li>
							<li> Comprehensive comparison of all strengths.</li>
							<li> Personal Growth Plan</li>
							<li> Full Details from 360 Feedback</li>
						</ul>
					</div>
					<button class="only-on-screen stroke-color-border" mat-stroked-button color="primary"
						(click)="openPurchaseReport()">Buy
						Full Report
						($125)</button>
					<a mat-stroked-button color="primary" class="only-on-print stroke-color-border" target="_BLANK"
						href="{{ router.url.split('?')[0] }}">Buy Full Report ($125)</a>
					&nbsp; {{showDiscount ? 'or':''}} &nbsp;
					<a *ngIf="showDiscount" mat-raised-button color="primary" target="_BLANK"
						href="{{ basePath +'/'+ character360Service.getCharacter360Path(report).join('/')}}">
						Get 50% by Completing 360</a>
					<br>
					<br>
					<div *ngIf="showDiscount" class="disclaimer">
						You need 6 responses to unlock 50% off
					</div>
				</ng-container>
				<ng-container *ngIf="report?.character360 && report?.character360?.totalResponsesCount<6">
					<div class="preview-heading">
						Get {{6-report?.character360?.totalResponsesCount}} more responses to unlock 50% off.
					</div>
					<div class="preview-content">
						<div class="ca-text-align-left">
							You are almost there. You only need {{6-report?.character360?.totalResponsesCount}}x more responses to get
							50% off
							the
							full report.
							<br><br>
							The full report provides
						</div>
						<ul class="list-of-benefits">
							<li> Detailed analysis of 3 main character strengths</li>
							<li> Comprehensive comparison of all strengths.</li>
							<li> Personal Growth Plan</li>
							<li> Full Details from 360 Feedback</li>
						</ul>
					</div>
					<button class="only-on-screen stroke-color-border" mat-stroked-button color="primary"
						(click)="openPurchaseReport()">Buy
						Full Report
						($125)</button>
					<a mat-stroked-button color="primary" class="only-on-print stroke-color-border" target="_BLANK"
						href="{{ router.url.split('?')[0] }}">Buy Full Report ($125)</a>
					&nbsp; or &nbsp;
					<a mat-raised-button color="primary" target="_BLANK"
						href="{{ basePath +'/'+ character360Service.getCharacter360Path(report).join('/')}}">
						Get {{6-report?.character360?.totalResponsesCount}} more responses
					</a>
				</ng-container>
				<ng-container *ngIf="report?.character360?.totalResponsesCount>=6">
					<div class="preview-heading">
						Get the Full Report for only $62 (usually $125)
					</div>
					<div class="preview-content">
						<div class="ca-text-align-left">
							Thanks for doing your Character 360. You now get 50% of your Full report. Full report includes:
						</div>
						<ul class="list-of-benefits">
							<li>Detailed analysis of 3 main character strengths</li>
							<li>Comprehensive comparison of all strengths.</li>
							<li>Personal Growth Plan</li>
							<li>Full Details from 360 Feedback</li>
						</ul>
					</div>
					<button class="only-on-screen" mat-raised-button color="primary" (click)="openPurchaseReport()">Buy Full
						Report $62 (usually $125)</button>
					<a mat-raised-button color="primary" class="only-on-print" target="_BLANK"
						href="{{ router.url.split('?')[0] }}">Buy Full Report $62 (usually $125)</a>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
</div>