<section class="flex-row justify-center align-center fill">
	<mat-card class="ca-center-container ca-tab-view " [class.mat-elevation-z]="flat">
		<form #signupForm="ngForm" (submit)="createAccount('email')">
			<mat-card-title>
				<div fxLayout="row" fxLayoutAlign="center center">
					<img src="/assets/logo-small.png" />
				</div>
				<br>
				<h1 align="center"> Create Account <br> to access report</h1>
			</mat-card-title>
			<mat-card-subtitle>
				Once you create your account you will have access to the report, and be sent a confirmation email.
			</mat-card-subtitle>
			<mat-card-content>
				<!-- <div fxLayout="row" fxLayoutAlign="center center" class="ca-profile-pic-container">
					<div class="ca-profile-pic-view">
						<span class="ca-profile-pic" fxLayout="row" fxLayoutAlign="center center">
							<ng-container *ngIf="userProfileImage?.src; else profileElseTemplate">
								<img width="100%" [src]="userProfileImage?.src" alt="" srcset="">
							</ng-container>
							<ng-template #profileElseTemplate>
								<img width="100%" src="/assets/images/avatar.jpg" alt="" srcset="">
							</ng-template>
							<input #file id="profilePic" name="imageKey" type="file" (change)="onUserProfileUpload($event)">
						</span>
						<button class="ca-edit" (click)="file.click()" type="button" mat-icon-button>
							<mat-icon>edit</mat-icon>
						</button>
						<button class="ca-remove" *ngIf="userProfileImage?.src" (click)="removeProfilePic()" type="button"
							mat-icon-button>
							<mat-icon>close</mat-icon>
						</button>
					</div>
				</div> -->
				<div>
					<h4 class="ca-all-uppercase">Personal Details </h4>
					<mat-form-field fxFlexFill>
						<mat-label>Name</mat-label>
						<input #firstName="ngModel" placeholder="Your Full Name" matInput required name="firstName"
							[(ngModel)]="signupFormModel!.firstName">
						<mat-error *ngIf="firstName.touched && firstName.errors?.required">
							Please type in the Name.
						</mat-error>
					</mat-form-field>
					<br>
					<mat-form-field fxFlexFill>
						<mat-label>Organisation</mat-label>
						<input #organisation="ngModel" placeholder="Where you work" matInput required name="organisation"
							[(ngModel)]="signupFormModel!.organisation">
						<mat-error *ngIf="organisation.touched && organisation.errors?.required">
							Please type in Organisation.
						</mat-error>
					</mat-form-field>
					<mat-form-field style="width: 100%;">
						<mat-label>Birthday</mat-label>
						<input name="dob" placeholder="DD/MM/YYYY" [max]="today" [(ngModel)]="signupFormModel!.dob" #dob="ngModel"
							matInput [matDatepicker]="picker">
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
					<br>
					<mat-form-field fxFlexFill *ngIf="org?.id===1">
						<mat-label>How did you find out about us?</mat-label>
						<mat-select [(ngModel)]="signupFormModel!.howDidYouFindOutAboutUs" #howDidYouFindOutAboutUs="ngModel"
							name="howDidYouFindOutAboutUs" placeholder="Please Select">
							<mat-option *ngFor="let howDidYouFindOutAboutUsOption of howDidYouFindOutAboutUsOptions"
								[value]="howDidYouFindOutAboutUsOption">
								{{howDidYouFindOutAboutUsOption}}
							</mat-option>
						</mat-select>
						<mat-error *ngIf="howDidYouFindOutAboutUs.touched && howDidYouFindOutAboutUs.errors?.required">
							Please select in How did you find out about us.
						</mat-error>
					</mat-form-field>
					<mat-form-field *ngIf="signupFormModel!.howDidYouFindOutAboutUs==='Other'" fxFlexFill>
						<mat-label>Please describe how </mat-label>
						<input #howDidYouFindOutAboutUsOtherDescription="ngModel" maxlength="255" matInput required
							name="howDidYouFindOutAboutUsOtherDescription"
							[(ngModel)]="signupFormModel!.howDidYouFindOutAboutUsOtherDescription">
						<mat-error
							*ngIf="howDidYouFindOutAboutUsOtherDescription.touched && howDidYouFindOutAboutUsOtherDescription.errors?.required">
							Please type in the description.
						</mat-error>
					</mat-form-field>
					<!-- <mat-form-field fxFlexFill>
						<mat-label>Last Name</mat-label>
						<input #lastName="ngModel" matInput required name="lastName" [(ngModel)]="signupFormModel!.lastName">
						<mat-error *ngIf="lastName.touched && lastName.errors?.required">
							Please type in Last Name.
						</mat-error>
					</mat-form-field> -->
					<!-- <mat-form-field fxFlexFill>
						<mat-label>Mobile Number</mat-label>
						<input #mobileNo="ngModel" matInput required type="tel" placeholder="[Country Code][Number] +61 490 000 000"
							name="mobileNo" appPhoneNumber [(ngModel)]="signupFormModel!.mobileNo">
						<mat-error *ngIf="mobileNo.touched && mobileNo.errors?.required">
							Please type in Mobile No.
						</mat-error>
						<mat-error *ngIf="mobileNo.touched && mobileNo.errors?.phone">
							Invalid Mobile No. format:[Country Code][Number]
						</mat-error>
					</mat-form-field> -->
					<h4 class="ca-all-uppercase">Account Details </h4>
					<mat-form-field fxFlexFill>
						<mat-label>Email address</mat-label>
						<input #emailIdInput #emailId="ngModel" matInput required type="email" placeholder="abc@example.com"
							name="emailId" [(ngModel)]="signupFormModel!.emailId" email>
						<mat-error *ngIf="emailId.touched && emailId.errors?.required">
							Please type in Email Id.
						</mat-error>
						<mat-error *ngIf="emailId.touched && emailId.errors?.invalid">
							Please type in a valid Email Id.
						</mat-error>
					</mat-form-field>
					<br>
					<mat-form-field fxFlexFill>
						<mat-label>Enter a New Password</mat-label>
						<input #password="ngModel" matInput [required]="isPasswordRequired" type="password" name="password"
							[(ngModel)]="signupFormModel!.password">
						<mat-error *ngIf="password.touched && password.errors?.required">
							Please type in Password.
						</mat-error>
					</mat-form-field>
					<br>
					<br>
					<div class="check-box-container">
						<mat-checkbox [(ngModel)]="signupFormModel!.consent" name="consent" matInput
							class="check-box ca-check-box-first-line-aligned" color="primary">
							I'd like to receive educational content and offers <br> about my Character Strengths.
						</mat-checkbox>
					</div>

				</div>
			</mat-card-content>

			<mat-card-actions fxLayout="column" fxLayoutAlign="center center">
				<button fxFlexFill mat-flat-button color="primary"> Create Account</button>
				<br>
				<br>
				<app-google-signin (signedIn)="createAccount('google',$event)"></app-google-signin>
				<br>
				<app-microsoft-signin (signedIn)="createAccount('microsoft',$event)"></app-microsoft-signin>
			</mat-card-actions>
		</form>
	</mat-card>
</section>
