import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { LoginComponent } from "src/app/home/step/login/login.component";
import { STEP_USER } from "./../types/user";

@Injectable({
	providedIn: "root"
})
export class StepAuthService {
	authTokenKey = "stepAuthToken";
	isLoggedInKey = "isStepLoggedIn";
	userKey = "stepUser";
	$user = new BehaviorSubject<STEP_USER | null>(null);
	constructor(private _dialog: MatDialog) {
		this.$user.next(JSON.parse(localStorage.getItem(this.userKey) ?? "{}"));
	}
	getBasicAuth() {
		return "Basic Q3VzdG9tU3RhdGljQXV0aDpDdXN0b21TdGF0aWNBdXRoMTIzNCQ=";
	}
	async login(data?: any) {
		return new Promise(resolve => {
			let dialogRef = this._dialog.open(LoginComponent, { data });
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	async loginIfNot() {
		if (!this.isLoggedIn()) {
			let result: any = await this.login();
			return result?.refresh;
		}
		return true;
	}
	logout() {
		this.removeAuth();
		this.setUserInfo({});
		this.setLoggedOut();
	}
	setAuth(token: string) {
		localStorage.setItem(this.authTokenKey, token);
	}
	getAuth() {
		return {
			token: localStorage.getItem(this.authTokenKey) || null
		};
	}
	removeAuth() {
		localStorage.removeItem(this.authTokenKey);
	}
	setLoggedIn() {
		localStorage.setItem(this.isLoggedInKey, "true");
	}
	setLoggedOut() {
		localStorage.setItem(this.isLoggedInKey, "false");
	}
	isLoggedIn() {
		return JSON.parse(localStorage.getItem(this.isLoggedInKey) || "false");
	}
	setUserInfo(data: any) {
		localStorage.setItem(this.userKey, JSON.stringify(data));
		this.$user.next(data);
	}
	getUserInfo() {
		return this.$user;
	}
}
