<h1 mat-dialog-title>Dual Comparison</h1>
<button mat-icon-button class="ca-dialog-close-icon"
	[mat-dialog-close]="{groupId: groupFG?.value?.id,roleId:roleFG?.value?.id}">
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
		<mat-radio-group [(ngModel)]="comparison1" class="ca-full-width-radios" aria-label="Select an option"
			fxFlex="1 1 100%" fxFlexFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
			<mat-radio-button color="primary" value="USER" fxFlexFill>
				<mat-card>
					<app-report-select [reportCtrl]="reportCtrl" searchOn="name"
						[options]="{ hideReportIds:hideReportIds1 ,label:'User Report 1'}">
					</app-report-select>
				</mat-card>
			</mat-radio-button>
			<span>
				<b> OR </b>
			</span>
			<mat-radio-button color="primary" value="GROUP" fxFlexFill>
				<mat-card>
					<app-group-report-select [groupReportCtrl]="groupReportCtrl"
						[options]="{ hideGroupReportIds:hideGroupReportIds1 ,label:'Group Report 1'}">
					</app-group-report-select>
				</mat-card>
			</mat-radio-button>
			<span>
				<b> OR </b>
			</span>
			<mat-radio-button color="primary" value="TARGET">
				<mat-card>
					<app-target type="GROUP" [(id)]="data.groupId" [orgId]="data.orgId"
						(aspirationFormGroupReady)="onGroupFG($event)" [options]="{ hideIds:[this.roleFG?.value?.id]}">
					</app-target>
				</mat-card>
			</mat-radio-button>
		</mat-radio-group>

		<mat-radio-group [(ngModel)]="comparison2" class="ca-full-width-radios" aria-label="Select an option"
			fxFlex="1 1 100%" fxFlexFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
			<mat-radio-button color="primary" value="USER" fxFlexFill>
				<mat-card>
					<app-report-select fxFlexFill [reportCtrl]="report2Ctrl" searchOn="name"
						[options]="{hideReportIds:hideReportIds2,label:'User Report 2'}">
					</app-report-select>
				</mat-card>
			</mat-radio-button>
			<span>
				<b> OR </b>
			</span>
			<mat-radio-button color="primary" value="GROUP" fxFlexFill>
				<mat-card>
					<app-group-report-select [groupReportCtrl]="groupReport2Ctrl"
						[options]="{ hideGroupReportIds:hideGroupReportIds2 ,label:'Group Report 2'}">
					</app-group-report-select>
				</mat-card>
			</mat-radio-button>
			<span>
				<b> OR </b>
			</span>
			<mat-radio-button color="primary" value="TARGET">
				<mat-card>
					<app-target type="ROLE" [(id)]="data.roleId" [orgId]="data.orgId"
						(aspirationFormGroupReady)="onRoleFg($event)" [options]="{ hideIds:[this.groupFG?.value?.id]}">
					</app-target>
				</mat-card>
			</mat-radio-button>
		</mat-radio-group>
	</div>

</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button [disabled]="isInvalid" color="primary" (click)="openShareReport()">
		<mat-icon>send</mat-icon>
		Send Report
	</button>
	<button mat-raised-button [disabled]="isInvalid" color="primary" class="mat-icon-loader-button"
		(click)="downloadReport()">
		<mat-icon>download</mat-icon>
		Download Report
		<app-loader [loaderId]="downloadButtonLoaderId" [fgsSize]=25></app-loader>
	</button>
	<button mat-raised-button [disabled]="isInvalid" color="primary" (click)="openInNewTab()">
		<mat-icon>open_in_new</mat-icon>
		Open Report
	</button>
</mat-dialog-actions>