import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
	Observable,
	Subscription,
	interval,
	merge,
	of as observableOf
} from "rxjs";
import {
	catchError,
	debounce,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
	tap
} from "rxjs/operators";
import {
	ConfirmDialogComponent,
	ConfirmDialogModel
} from "src/app/_shared/components/confirm-dialog/confirm-dialog.component";
import { DualComparisonComponent } from "src/app/_shared/components/dual-comparison/dual-comparison.component";
import { SingleComparisonComponent } from "src/app/_shared/components/single-comparison/single-comparison.component";
import { LoggerService } from "src/app/_shared/services/logger.service";
import { ReportService } from "src/app/_shared/services/report.service";
import { getSurveyURL } from "src/app/_shared/utils/string";
import { MatTableWrapperComponent } from "../../class/mat-table-wrapper";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { Character360Service } from "../../services/character360.service";
import { DialogsService } from "../../services/dialogs.service";
import { ORG } from "../../types/organisation";
import { USER } from "../../types/user";
import { addMonths } from "../../utils/date";
import { SelectionModel } from "../../utils/selection-model-material";
import { ActivityLogsComponent } from "../activity-logs/activity-logs.component";
import { ShareReportComponent } from "../share-report/share-report.component";
import { OrganisationService } from "./../../services/organisation.service";
import { GenerateGroupReportComponent } from "./../generate-group-report/generate-group-report.component";
import { PayForReportComponent } from "./../pay-for-report/pay-for-report.component";
import { UnpackSessionsLogComponent } from "./../unpack-sessions-log/unpack-sessions-log.component";
function compareWith(o1: any, o2: any) {
	return o1.id === o2.id;
}
@Component({
	selector: "app-reports",
	templateUrl: "./reports.component.html",
	styleUrls: ["./reports.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class ReportsComponent
	extends MatTableWrapperComponent
	implements OnInit, AfterViewInit
{
	subscriptions = new Subscription();
	loaderId = "reports-loader";
	@Input() partnerSurvey?: any;
	@Input() freezeFilter?: {
		partnerSurvey?: boolean;
	} = {};
	@Input() isDialog: boolean = false;
	selection!: SelectionModel<any>;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	refreshEvent = new EventEmitter();
	filterFormControl: FormControl = new FormControl();
	searchOnFormControl: FormControl = new FormControl();
	partnerSurveyFormControl: FormControl = new FormControl();
	data: any[] = [];
	resultsLength = 0;
	pageSize = 10;
	isLoadingResults = false;
	user?: USER | null;
	org?: ORG | null;
	selectedOrg?: ORG | null;
	partnerSurveys$?: any[] = [];
	maxLimitForGroupReport = 20;
	displayedColumns = [
		"select",
		"index",
		"name",
		"organisation",
		"actions-lg",
		"singleComparison",
		"dualComparison",
		"isPaid",
		// "cultureAdvisor",
		"partnerSurveyOrganisation",
		"partnerSurvey",
		"unpackSession",
		"email",
		"mobile",
		"user",
		"createdAt",
		"c360Actions",
		"character360.responseRate",
		"expiresAt",
		"log"
	];
	noOfUnpackRequired = 0;
	today = new Date();
	isOnlyUnpackRequired = false;
	searchFields = [
		{ name: "Name", value: "name" },
		{ name: "Email Id", value: "email" },
		{ name: "Organisation", value: "organisation" },
		{ name: "Mobile No", value: "mobile" },
		{ name: "Culture Advisor", value: "cultureAdvisor" }
	];
	users: USER[] = [];
	constructor(
		public Logger: LoggerService,
		private UiLoaderService: NgxUiLoaderService,
		private dialog: MatDialog,
		private dialogsService: DialogsService,
		public reportService: ReportService,
		private organisationService: OrganisationService,
		private authService: AuthService,
		private accountService: AccountService,
		private character360Service: Character360Service,
		private router: Router,
		private _snackBar: MatSnackBar
	) {
		super();
		this.selection = new SelectionModel<any>(true, [], true, compareWith);
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe((org: ORG | null) => {
				this.org = org;
				this.selectedOrg = org;
				this.refreshEvent.next();
			})
		);
	}
	ngOnInit(): void {
		this.partnerSurvey &&
			this.partnerSurveyFormControl.setValue(this.partnerSurvey);
		this.getOrg();
		this.subscriptions.add(
			this.authService.getUserInfo().subscribe(user => (this.user = user))
		);
		// this.getUsers();
	}
	ngAfterViewInit() {
		// If the user changes the sort order, reset back to the first page.
		merge(
			this.sort?.sortChange,
			this.filterFormControl.valueChanges,
			this.searchOnFormControl.valueChanges,
			this.partnerSurveyFormControl?.valueChanges
		).subscribe(() => (this.paginator.pageIndex = 0));
		merge(
			this.sort?.sortChange,
			this.paginator?.page,
			this.filterFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				tap(() => {
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
				}),
				distinctUntilChanged()
			),
			this.searchOnFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				distinctUntilChanged()
			),
			this.refreshEvent,
			this.partnerSurveyFormControl?.valueChanges.pipe(
				tap(() => {
					this.getOrg();
				})
			)
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					setTimeout(() => {
						this.isLoadingResults = true;
					}, 0);
					this.getUnpackRequiredCount();

					return this.reportService!.getAll({
						offset: this.paginator.pageIndex * this.paginator.pageSize,
						limit: this.paginator.pageSize,
						sortingKey: this.sort.active,
						sortBy: this.sort.direction,
						searchText: this.filterFormControl.value || undefined,
						partnerSurveyId: this.partnerSurveyFormControl.value?.id,
						orgId: this.org?.id,
						searchOn: this.searchOnFormControl.value
							? this.searchOnFormControl.value
							: undefined,
						isOnlyUnpackRequired: this.isOnlyUnpackRequired
					}).pipe(catchError(() => observableOf(null)));
				}),
				map((data: any) => {
					// Flip flag to show that loading has finished.
					setTimeout(() => {
						this.isLoadingResults = false;
					}, 0);
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
					if (data === null) {
						return [];
					}
					this.resultsLength = data.total;
					data.reportsList.forEach(async (report: any) => {
						if (report.isUnpackPurchased && report.partnerSurvey?.orgId) {
							report.advisors = this.getMembers(report.partnerSurvey?.orgId);
						}
						if (report.isUnpackSessionAllocated) {
							report.unpackSession = await this.getAllocation(report.id);
						}
						report.expiresAt = addMonths(new Date(report.createdAt), 6);
					});
					return data.reportsList;
				})
			)
			.subscribe(data => {
				this.data = data;
				this.reRender();
			});
	}
	/** Whether the number of selected elements matches the total number of rows. */
	get isAllSelected() {
		const numSelected = this.selection.selected.length;
		return numSelected === this.resultsLength;
	}

	/** clear selection. */
	deselectAll() {
		this.selection.clear();
	}
	selectAll() {
		for (let row of this.data) {
			if (this.selection.selected.length >= this.maxLimitForGroupReport) {
				break;
			}
			if (!this.selection.isSelected(row)) {
				this.selection.select(row);
			}
		}
	}
	selectionChange($event: MatCheckboxChange | MouseEvent, row: any) {
		if ($event instanceof MatCheckboxChange) {
			if ($event.checked) {
				if (this.selection.selected.length < this.maxLimitForGroupReport) {
					this.selection.select(row);
				} else {
					this._snackBar.open(
						`Max Limit(${this.maxLimitForGroupReport}) for Group Report reached.`
					);
					$event.source.checked = false;
				}
			} else {
				this.selection.deselect(row);
			}
		} else {
			if (this.selection.isSelected(row)) {
				this.selection.deselect(row);
			} else {
				if (this.selection.selected.length < this.maxLimitForGroupReport) {
					this.selection.select(row);
				} else {
					this._snackBar.open(
						`Max Limit(${this.maxLimitForGroupReport}) for Group Report reached.`
					);
				}
			}
		}
	}
	async openInNewTab(report: any, previewOnly = false) {
		const surveyURL = getSurveyURL();
		const auth = await this.accountService.getSinglePurposeToken({
			reportUUIDV4: report.UUIDV4,
			previewOnly
		});
		let path = `${surveyURL}/report/${report.UUIDV4}?isPartner=true`;
		path += `&authorization=${auth.token}&authType=${auth.type}`;
		if (previewOnly) {
			path += `&previewOnly=true`;
		}
		window.open(path);
	}
	async download(report: any, previewOnly = false) {
		let loaderId = report.UUIDV4;
		previewOnly && (loaderId += "preview");
		this.UiLoaderService.startLoader(loaderId);
		try {
			await this.reportService.download({
				report,
				previewOnly
			});
		} catch (error) {
			this.Logger.error(error);
		} finally {
			this.UiLoaderService.stopLoader(loaderId);
		}
	}
	openShareReport(report: any, previewOnly = false) {
		this.dialog.open(ShareReportComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				report,
				previewOnly
			}
		});
	}
	openSingleComparison(report: any) {
		const dialogRef = this.dialog.open(SingleComparisonComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				type: "INDIVIDUAL",
				groupId: report.selectedSingleComparisonGroupId ?? null,
				orgId: report.partnerSurvey?.orgId,
				report
			}
		});
		dialogRef.afterClosed().subscribe(groupId => {
			if (groupId !== report.selectedSingleComparisonGroupId) {
				const payload = {
					selectedSingleComparisonGroupId: groupId
				};
				this.reportService
					.patchReportDefaults(report.UUIDV4, payload)
					.subscribe((res: any) => {
						report.selectedSingleComparisonGroupId = groupId;
					});
			}
		});
	}
	openDualComparison(report: any) {
		const dialogRef = this.dialog.open(DualComparisonComponent, {
			panelClass: "ca-dialog",
			minHeight: "80%",
			maxHeight: "100%",
			data: {
				type: "INDIVIDUAL",
				groupId: report.selectedDualComparisonGroupId ?? null,
				roleId: report.selectedDualComparisonRoleId ?? null,
				orgId: report.partnerSurvey?.orgId,
				report
			}
		});
		dialogRef.afterClosed().subscribe(({ groupId, roleId }) => {
			if (
				groupId !== report.selectedDualComparisonGroupId ||
				roleId !== report.selectedDualComparisonRoleId
			) {
				const payload = {
					selectedDualComparisonGroupId: groupId,
					selectedDualComparisonRoleId: roleId
				};
				this.reportService
					.patchReportDefaults(report.UUIDV4, payload)
					.subscribe((res: any) => {
						report.selectedDualComparisonGroupId = groupId;
						report.selectedDualComparisonRoleId = roleId;
					});
			}
		});
	}
	getOrg() {
		if (
			this.partnerSurveyFormControl.value?.orgId &&
			this.partnerSurveyFormControl.value?.orgId !== this.selectedOrg?.id
		) {
			this.organisationService
				.getOrg(this.partnerSurveyFormControl.value?.orgId)
				.subscribe(res => {
					this.org = res.org;
				});
		} else if (this.selectedOrg?.id) {
			this.org = this.selectedOrg;
		} else {
			this.org = null;
		}
	}
	openPayForReport(
		report: any,
		by: "PAY_BY_USING_CREDITS" | "PAY_BY_SHARETREE"
	) {
		if (!report.isPaid) {
			let ref = this.dialog.open(PayForReportComponent, {
				minWidth: "50%",
				panelClass: "ca-dialog",
				data: {
					report,
					by
				}
			});
			ref.afterClosed().subscribe(res => {
				if (res?.isPaid) {
					report.isPaid = res.isPaid;
					report.transactions = res.transactions;
					this.getOrg();
				}
			});
		}
	}
	openGenerateGroupReport() {
		let ref = this.dialog.open(GenerateGroupReportComponent, {
			minWidth: "50%",
			panelClass: "ca-dialog",
			data: {
				reports: this.selection.selected
			}
		});
		ref.afterClosed().subscribe(res => {
			if (res?.forward) {
			}
		});
	}

	getUnpackRequiredCount() {
		this.reportService!.getUnpackRequiredCount({
			partnerSurveyId: this.partnerSurveyFormControl.value?.id,
			orgId: this.org?.id
		}).subscribe((res: any) => {
			this.noOfUnpackRequired = res.count;
			if (this.isOnlyUnpackRequired && this.noOfUnpackRequired === 0) {
				this.isOnlyUnpackRequired = false;
				this.refreshEvent.next();
			}
		});
	}
	toggleUnpackRequiredFilter() {
		this.paginator.pageIndex = 0;
		this.isOnlyUnpackRequired = !this.isOnlyUnpackRequired;
		this.refreshEvent.next();
	}
	getMembers(orgId: number): Observable<any> {
		return new Observable(observer => {
			this.organisationService.getMembers(orgId).subscribe((res: any) => {
				observer.next(res.list);
				observer.complete();
			});
		});
	}
	getUsers() {
		this.accountService.getUsers().subscribe((res: any) => {
			this.users = res.list;
		});
	}
	getAllocation(reportId: number) {
		return new Promise(resolve => {
			this.reportService
				.getAllocatedUnpackSession(reportId)
				.subscribe((res: any) => {
					resolve(res.unpackSessions[0]);
				});
		});
	}
	openAllocationLog(report: any) {
		const dialogRef = this.dialog.open(UnpackSessionsLogComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				report
			}
		});
	}
	async allocateUnpackSession(report: any, $event: MatSelectChange) {
		let advisor = $event.value;
		let confirm = await this.confirmIfUpdateUnpackSessionAllocation(
			report,
			advisor
		);
		if (confirm) {
			let payload = { reportId: report.id, allocatedToId: advisor.id };
			this.reportService
				.allocateUnpackSession(payload)
				.subscribe((res: any) => {
					if (res.statusCode === 200) {
						this._snackBar.open("Unpack Session Allocated");
					} else {
						$event.source.value = report.unpackSession.allocatedTo.id;
					}
				});
		} else {
			if (report?.unpackSession?.allocatedTo?.id) {
				$event.source.value = report.unpackSession.allocatedTo.id;
			} else {
				$event.source.value = null;
			}
		}
	}
	confirmIfUpdateUnpackSessionAllocation(
		report: any,
		advisor: any
	): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `Do you wish to Allocate ${advisor.firstName} for the Unpack Session of ${report.name}?`;
			const dialogData = new ConfirmDialogModel("Confirm Action", {
				message,
				confirmText: "Allocate",
				dismissText: "Cancel"
			});
			const dialogRef = this.dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	compareWith(o1: any, o2: any) {
		return o1?.id === o2;
	}
	openLogs(report: any) {
		this.dialog.open(ActivityLogsComponent, {
			width: "100%",
			minHeight: "80%",
			height: "80vh",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				report,
				activityOf: "REPORT"
			}
		});
	}
	linkReportToUser(report: any, user: any) {
		this.reportService
			.linkReportToUser({ reportId: report.id, userId: user.id })
			.subscribe((res: any) => {
				if (res.statusCode === 201) {
					this._snackBar.open("Updated Report User Account");
				}
			});
	}
	openUpdateUserEmail(report: any) {
		report.editUserEmail = true;
		report.newEmail = report.user?.emailId ?? report.email;
	}
	closeUpdateUserEmail(report: any) {
		report.editUserEmail = false;
	}
	update(report: any) {
		this.UiLoaderService.startLoader(report.UUIDV4 + "email");
		this.reportService
			.linkReportToUserByEmailId({
				reportId: report.id,
				emailId: report.newEmail
			})
			.subscribe({
				next: (res: any) => {
					if (res.statusCode === 201) {
						this._snackBar.open("Updated Report User Account");
						this.refreshEvent.emit();
						this.UiLoaderService.stopLoader(report.UUIDV4 + "email");
					}
				},
				error: () => {
					this.UiLoaderService.stopLoader(report.UUIDV4 + "email");
				}
			});
	}
	async openC360InNewTab(character360: any, previewOnly = false) {
		const surveyURL = getSurveyURL();
		const auth = await this.accountService.getSinglePurposeToken({
			character360UUIDV4: character360.UUIDV4,
			previewOnly
		});
		let path = `${surveyURL}/character-360/report/${character360.UUIDV4}?isPartner=true`;
		if (!previewOnly) {
			path += `&authorization=${auth.token}&authType=${auth.type}`;
		} else {
			path += `&previewOnly=true`;
		}
		window.open(path);
	}
	async downloadC360(report: any) {
		this.UiLoaderService.startLoader(report.UUIDV4);
		try {
			await this.character360Service.download({
				report
			});
		} catch (error) {
			this.Logger.error(error);
		} finally {
			this.UiLoaderService.stopLoader(report.UUIDV4);
		}
	}
	openCharacter360(report?: any) {
		let org = this.organisationService.selectedOrg.getValue();
		let path = ["home", org?.id ?? "all"];
		if (report?.character360) {
			path.push("character-360");
			path.push(report.character360.id);
		} else {
			path.push(report.id);
			path.push("character-360");
		}
		if (this.isDialog) {
			window.open(`${location.origin}/${path.join("/")}`);
		} else {
			this.router.navigate(path);
		}
	}
	openMigrateReport(report: any) {
		let ref = this.dialogsService.openMigrateReport(report);
		ref.afterClosed().subscribe(res => {
			if (res.refresh) {
				this.refreshEvent.next();
			}
		});
	}
}
