import { AbstractControl } from "@angular/forms";

export function matchPassword(password: string, confirmPassword: string) {
	return (formGroup: AbstractControl) => {
		const passwordControl = formGroup?.get(password);
		const confirmPasswordControl = formGroup?.get(confirmPassword);

		if (!passwordControl || !confirmPasswordControl) {
			return {};
		}

		if (
			confirmPasswordControl.errors &&
			!confirmPasswordControl.errors.passwordMismatch
		) {
			return {};
		}

		if (passwordControl.value !== confirmPasswordControl.value) {
			confirmPasswordControl.setErrors({ passwordMismatch: true });
			return { passwordMismatch: true };
		} else {
			confirmPasswordControl.setErrors(null);
			return {};
		}
	};
}
