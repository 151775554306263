import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * @description
 * Form-level validator that adds or removes validators on a specified input by
 * evaluating a condition for each validator.
 * @usageNotes
 * This must be registered as a form-level validator.
 * ### Example
 * ```typescript
 * let form = new FormGroup({}, {
 *     validators: conditionalValidators(
 *         'companyName',
 *         [
 *             {
 *                 isSelected: form => form.get('addressType').value == 'business',
 *                 validators: Validators.required // or [Validators.required, ...]
 *             },
 *             // additional validators may be added here
 *         ]
 *     )
 * });
 * ```
 * @param inputName Name of the input that will have validators added or removed.
 * @param validatorSelectors Array of objects that include a function and validator(s).
 *        The function receives the form and returns a Boolean value that indicates
 *        if the validator(s) should be applied to the input.
 * @returns Validator function.
 */
export const conditionalValidators =
	(
		inputName: string,
		validatorSelectors: ReadonlyArray<ValidatorSelector>
	): ValidatorFn =>
	(form: AbstractControl): ValidationErrors | null => {
		let targetInput = form.get(inputName);
		if (targetInput) {
			let anyChanges = false;
			for (let selector of validatorSelectors) {
				let isSelected = selector.isSelected(form);
				let validators =
					selector.validators instanceof Array
						? selector.validators
						: [selector.validators];
				for (let validator of validators) {
					if (isSelected != targetInput.hasValidator(validator)) {
						anyChanges = true;
						if (isSelected) {
							targetInput.addValidators(validator);
						} else {
							targetInput.removeValidators(validator);
						}
					}
				}
			}
			if (anyChanges) {
				targetInput.setErrors(null);
				targetInput.updateValueAndValidity({
					onlySelf: false,
					emitEvent: true
				});
			}
		}
		return null;
	};

/**
 * Function that returns a Boolean indicating if validator(s) should be
 * applied to a form control.
 * @param form The form that contains the target form control.
 * @returns True if the validator(s) should be applied, else false.
 */
export interface ValidatorSelectionFn {
	(form: AbstractControl): boolean;
}

/**
 * Type used to conditionally select validator(s) for a form control.
 */
export interface ValidatorSelector {
	/**
	 * Function that returns a Boolean indicating if the validator(s) should be
	 * applied to the form control.
	 */
	readonly isSelected: ValidatorSelectionFn;
	/**
	 * Single validator or array of validators applied to the form control when
	 * isSelected returns true.
	 */
	readonly validators: ValidatorFn | ReadonlyArray<ValidatorFn>;
}
