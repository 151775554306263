import { DialogsService } from "./../../../services/dialogs.service";
import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Character360Service } from "../../../services/character360.service";

@Component({
	selector: "app-preview-content",
	templateUrl: "./preview-content.component.html",
	styleUrls: ["./preview-content.component.scss"]
})
export class PreviewContentComponent {
	@Input() report: any;
	@Input() bg: any;
	@Input() pageMargin = true;
	basePath = location.origin;
	showDiscount: boolean = false;
	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private dialogsService: DialogsService,
		public character360Service: Character360Service
	) {}
	get case() {
		if (this.report?.partnerSurvey?.isPublic) {
			if (this.report.isPaid) {
				if (this.report?.transactions?.[0]?.meta?.type === "PAID_BY_USER") {
					return "PAID_BY_USER";
				} else {
					switch (this.report.transactions?.[0]?.by?.role) {
						case "USER":
							return "PUBLIC_PAID_BY_PARTNER";
						case "ADMIN":
							return "PUBLIC_PAID_BY_ADMIN";
					}
					return "PUBLIC_PAID_BY_PARTNER";
				}
			} else {
				this.showDiscount = true;
				return "UNPAID";
			}
		} else {
			if (this.report.isPaid) {
				return "PAID_BY_PARTNER";
			} else {
				return "UNPAID";
			}
		}
	}
	async openPurchaseReport() {
		let result: any = await this.dialogsService.openPurchaseReport(this.report);
		if (result?.refresh) {
			this.router.navigate(["."], {
				relativeTo: this.route,
				queryParams: { piId: result.piId },
				queryParamsHandling: "merge"
			});
		}
	}
	get body() {
		return `Report Unique ID : ${this.report.UUIDV4}`;
	}
}
