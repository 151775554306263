<h1 *ngIf="dialogRef" mat-dialog-title>
    Invite - {{dialogData?.invite?.email}}
</h1>
<button *ngIf="dialogRef" mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
    <form *ngIf="inviteFG" [formGroup]="inviteFG">
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field fxFlexFill>
                <input matInput placeholder="Email" formControlName="email">
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" [mat-dialog-close]>cancel</button>
    <button mat-raised-button type="button" color="primary" (click)="patch()">Update</button>
</mat-dialog-actions>