<mat-form-field fxFlex="1 1 0" fxFlexFill [formGroup]="fg">
	<mat-label>{{label}}</mat-label>
	<mat-chip-list #chipList [formControlName]="fcName" multiple>
		<mat-chip [selected]="item.email" [value]="item.email" [color]="(item.invalid)?'warn':''"
			*ngFor="let item of emails; let i=index" (removed)="removeEmail(i)">
			{{item.email}}
			<button matChipRemove>
				<mat-icon>cancel</mat-icon>
			</button>
		</mat-chip>
		<input type="email" [placeholder]="placeholder" [matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
			(matChipInputTokenEnd)="chipEvent($event)">
	</mat-chip-list>
	<mat-error *ngIf="fc?.touched && fc?.errors?.containsInvalidEmail">
		Contains one or more Invalid {{label}}
	</mat-error>
	<mat-error *ngIf="fc?.touched && fc?.errors?.required">
		Please add atleast One {{label}}.
	</mat-error>
</mat-form-field>