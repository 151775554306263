<section fxLayout="column" >
	<div fxLayout="row" fxLayoutAlign="start center">
		<app-organisation-select *ngIf="!selectedOrg" [showAll]="false" [global]="false" [selectedOrgId]="org?.id"
			(selectedOrgChange)="updateOrg($event)" [disableMemberOrg]="true">
		</app-organisation-select>
		<span class="ca-spacer"></span>
		<mat-paginator class="ca-mat-paginator" [length]="resultsLength" [pageSize]="pageSize"
			[showFirstLastButtons]="(resultsLength/pageSize)>2" [pageSizeOptions]="[10,25,50,100]" aria-label="Select page">
		</mat-paginator>
	</div>
	<div fxLayout="column" class="ca-page-content">
		<div fxLayout="row" fxLayoutAlign="center center" fxFlex="shrink" *ngIf="!isLoadingResults  && data.length===0">
				<ng-container [ngSwitch]="viewType">
					<ng-container *ngSwitchCase="'CREDIT_USAGE'">
						All Your Credit Usages will appear here
					</ng-container>
					<ng-container *ngSwitchCase="'PURCHASE_HISTORY'">
						All Your Purchase History will appear here
					</ng-container>
				</ng-container>
			<!-- <div *ngIf="data?.length===0 && filterFormControl?.value?.length>0">
                There are no results available for this keyword &nbsp; <b>"{{ filterFormControl.value}}" </b>. Please
                refine
                your search with some other words.
            </div> -->
		</div>
		<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
		<div class="ca-mat-table-container"  [hidden]="isLoadingResults || data?.length===0">
				<mat-table class="mat-elevation-z8"  [dataSource]="data" matSort [matSortActive]="'createdAt'" matSortDirection="desc" fixedLayout="true">
					<ng-container matColumnDef="organisation">
						<mat-header-cell *matHeaderCellDef>
							Organisation
						</mat-header-cell>
						<mat-cell *matCellDef="let element;">
							<app-avatar *ngIf="element?.org" [avatar]="element?.org">
							</app-avatar>
							<ng-container *ngIf="element?.meta?.type==='PAID_BY_SHARETREE'">
								Paid By ShareTree
							</ng-container>
						</mat-cell>
					</ng-container>
					<!--
                        PURCHASE_HISTORY
                        "purchaseDate", //Purchase Date
			            "purchasedBy", //Purchased By
			            "purchasedAmount", // Purchased Amount
			            "downloadReceipt" //Download Receipt
                    -->

					<ng-container matColumnDef="purchaseDate">
						<mat-header-cell *matHeaderCellDef mat-sort-header="purchaseDate">
							Purchased Date
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.createdAt| date:'mediumDate' }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="purchasedBy">
						<mat-header-cell *matHeaderCellDef>
							Purchased By
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-avatar *ngIf="element?.by" [avatar]="element?.by">
							</app-avatar>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="purchasedCredits">
						<mat-header-cell *matHeaderCellDef>
							Purchased Credits
						</mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.creditedPoints}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="amountPaid">
						<mat-header-cell *matHeaderCellDef>
							Amount Paid
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<ng-container *ngIf="(element.meta?.totalAmount||element.meta?.amount);else emptyRef">
								<span class="ca-currency">A$</span>{{(element.meta?.totalAmount
								||element.meta?.amount)
								|number: '1.2-2'}}
							</ng-container>
							<ng-template #emptyRef>-</ng-template>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="downloadReceipt">
						<mat-header-cell *matHeaderCellDef>
							Download Receipt
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button matTooltip="Download" [disabled]="(!element.meta?.totalAmount && !element.meta?.amount)"
								mat-icon-button class="mat-icon-loader-button" color="primary" aria-label="link"
								(click)="download(element)">
								<mat-icon>download</mat-icon>
								<app-loader [loaderId]="element.id" [fgsSize]=40></app-loader>
							</button>
						</mat-cell>
					</ng-container>


					<!--
                        CREDIT_USAGE
                        "allocatedDate", //Allocated Date
			            "allocatedBy", //Allocated By
			            "creditsType", //Credits Type
			            "creditsAllocated", //Credits Allocated
			            "surveyName" //Survey Name
                     -->

					<ng-container matColumnDef="allocatedDate">
						<mat-header-cell *matHeaderCellDef mat-sort-header="allocatedDate">
							Allocated Date
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.createdAt| date:'mediumDate' }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="allocatedBy">
						<mat-header-cell *matHeaderCellDef>
							Allocated By
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-avatar *ngIf="element?.by" [avatar]="element?.by">
							</app-avatar>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="creditsType">
						<mat-header-cell *matHeaderCellDef>
							Credits Type
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<ng-container *ngIf="element?.meta?.type!=='PAID_BY_SHARETREE'">
								{{element.balanceType==='PAID'?
								"Paid":"Seed"}}
							</ng-container>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="creditsAllocated">
						<mat-header-cell *matHeaderCellDef>
							CreditsAllocated
						</mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.debitedPoints}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="surveyName">
						<mat-header-cell *matHeaderCellDef mat-sort-header="name">
							Allocated for
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<span matTooltip="Partner Survey" *ngIf="element.partnerSurvey">
								{{element.partnerSurvey?.name}}
							</span>
							<span matTooltip="Report" *ngIf="element.report">
								{{element.report?.name}}
							</span>
							<span matTooltip="Group Report" *ngIf="element.groupReport">
								{{
								element.groupReport.name ||
								element.groupReport.reportNames.join(", ")
								}}
							</span>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="summary">
						<mat-header-cell *matHeaderCellDef>
							Summary
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<span *ngIf="element.partnerSurvey">
								Refunded from
								<span matTooltip="Partner Survey">
									{{element.partnerSurvey?.name}}
								</span>
							</span>
							<span *ngIf="element?.meta?.groupSubscription?.orgId">
								Seed Credits from
								<app-organisation-select [showAll]="false" [global]="false"
									[selectedOrgId]="element.meta.groupSubscription.orgId" [forceReadOnly]="true">
								</app-organisation-select>
							</span>
						</mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
				</mat-table>
			</div>
		</div>
</section>
