<section class="flex c360-container">
	<app-loader [loaderId]="loaderId"></app-loader>
	<div class="c360-logo">
		<img height="auto" width="225px" src="assets/logo-transparent.png" alt="">
	</div>
	<div class="c360" style="position: relative;" #pageRef>
		<ng-container *ngIf="!onlyOverview">
			<div class="intro" *ngIf="intro">
				<br>

				<div class="intro-title">
					Find out how your strengths <br> show up in the real world with <br><b> Character 360.</b>
				</div>
				<br>
				<div>
					We have developed the Character 360 to allow you to ask trusted members of your network to give you insight on
					what they
					believe your character strengths are.
					<br><br>
					Comparing the results from your own assessment and that of your network allows you to identify where your
					strengths show
					up in the real world and how utilised they are.
					<br><br>
					This also provides a unique insight to potential areas of further growth or development.
				</div>
				<br>
				<div class="ca-text-align-center">
					<button mat-raised-button color="primary" (click)="goto(0)">START 360</button>
				</div>
				<br>
				<div class="intro-subtitle ca-font-weight-bold">
					How it works </div>
				<br>
				Completing your Character 360 is easy.
				<ol>
					<li>Send a link to various member of your network (at least 3 per category).</li>
					<li>Your network will answer a short set of questions about your character strengths (takes about 5 mins).
					</li>
					<li>Once we have received 3 response in a category we will publish the answers in your 360 report.</li>
				</ol>
				<br>
				<div class="ca-text-align-center">
					<img src="assets/images/c360-overview-sample.png" alt="">
				</div>
				<br>

				<div class="intro-subtitle">
					Get <span class="ca-primary-color-font ca-font-weight-bold"> 50% of the Full Report</span> by completing the
					360.
				</div>
				<br>
				When you get at least <span class="ca-font-weight-bold"> 6 responses </span> from your Character 360, you get
				50%
				of the Full Charametrics report.
				<br><br>
				The Full Report gives you insight into all your character strength and all insight collected from your 360.
				<br> <br>
				Full Report is usually $125 AUD. After 360 only <span class="ca-font-weight-bold">$62.</span>
				<br><br>
				<table class="info-table">
					<tr>
						<td><span class="ca-font-weight-bold">Full Report</span> gives you access to
							<ul>
								<li>Detailed analysis of 3 main character strengths</li>
								<li>Comprehensive comparison of all strengths</li>
								<li>Personal Growth Plan</li>
								<li>Full Details from 360 Feedback</li>
							</ul>
						</td>
						<td>
							<span class="ca-font-weight-bold">Free Report </span> gives you access to
							<ul>
								<li>1 character strength</li>
								<li>Limited 360 Feedback</li>
							</ul>
						</td>
					</tr>
				</table>
				<br><br>
				Start your 360 now to find out how your strengths show up in the real world!
				<br><br>
				<div class="ca-text-align-center">
					<button mat-raised-button color="primary" (click)="goto(0)">START 360</button>
				</div>
				<br><br>
			</div>
			<app-character360-stepper *ngIf="!intro" [@.disabled]="true" #stepper labelPosition="bottom" [linear]="false"
				[selectedIndex]="currentStepIndex">
				<cdk-step label="1">
					<div class="step-header">
						Introduction
					</div>
					<div class="overview-container">
						<app-character360-overview [character360]="character360"></app-character360-overview>
					</div>
					<div class="stepper-content">
						<div>
							ShareTree Character 360 is an online tool is designed to give you direct <b> 360 feedback from a range of
								your
								trusted interactions</b> to help you better understand your
							character strengths and growth areas. <br><br>
							They will be asked to rate your ability on the following:<br>
							<ol class="questions">
								<li>Communication - Does this person communicate clearly and effectively in a way that makes you feel
									safe
									to express yourself honestly and understand their point of view?</li>
								<li>Care - Does this person show a level of care and consideration for you and others to support mutual
									outcomes?</li>
								<li>Congruence - Is this person congruent between what they say and do when working with you and with
									others?</li>
								<li>Capability - Does this person have the required capability and skills to adequately complete their
									role?</li>
								<li>Confidentiality - Is this person confidential with important or sensitive information about you and
									others that allows you to speak freely and openly?</li>
								<li>Nominate your 3 strongest Character Strengths</li>
								<li>Nominate 1 area for Growth Focus</li>
							</ol>
							Their responses will be aggregated and <b>anonymity preserved</b>.
							Total time to provide feedback should take <b>no longer than 5-7 minutes.</b>
						</div>
						<br>
						<div>
							You need a <b>minimum of 3 responses </b> for each of the <b> 6 segments.</b>
						</div>
					</div>
					<div class="stepper-actions">
						<button mat-raised-button color="accent" (click)="goto(-1)">Back</button>
						<span class="ca-spacer"></span>
						<button mat-raised-button color="primary" cdkStepperNext (click)="scrollToTop()">Next</button>
					</div>
				</cdk-step>
				<cdk-step label="2" [stepControl]="infoFG" [completed]="isInfoCompleted">
					<div class="step-header">
						Your Details
					</div>
					<div class="overview-container">
						<app-character360-overview [highlightOverall]="true"
							[character360]="character360"></app-character360-overview>
					</div>
					<form [formGroup]="infoFG">
						<div class="stepper-content">
							<div>
								First, please enter in your <b> name, organisation </b> & <b> mobile number</b>.
							</div>
							<br>
							<div>
								NOTE: Once your mobile number is added, this can't be changed. We use it to
								forward you the invite so that you can send it to your contacts directly.
							</div>
							<br>
							<div class="flex gap-20">
								<mat-form-field style="width: 250px;">
									<mat-label>Your Name</mat-label>
									<input matInput formControlName="name">
									<mat-error *ngIf="infoFG.get('name')?.touched && infoFG.get('name')?.errors?.required">
										Please type in Your Name.
									</mat-error>
								</mat-form-field>
								<mat-form-field style="width: 250px;">
									<mat-label>Your Organisation</mat-label>
									<input matInput formControlName="organisationName">
									<mat-error
										*ngIf="infoFG.get('organisationName')?.touched && infoFG.get('organisationName')?.errors?.required">
										Please type in Your Organisation.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="flex gap-20 align-center">
								<app-mobile-number #mobileNumber [fg]="infoFG" fcName="mobileNo"
									[verifiedMobileNo]="character360Saved?.mobileNo"
									[isMobileNoVerified]="character360Saved?.isMobileNoVerified"
									(changeEvent)="mobileNoVerifiedEvent($event)">
								</app-mobile-number>
							</div>
						</div>
					</form>
					<div class="stepper-actions">
						<button mat-raised-button color="accent" cdkStepperPrevious (click)="scrollToTop()">Back</button>
						<span class="ca-spacer"></span>
						<button mat-raised-button color="primary" [disabled]="infoFG?.invalid" (click)="submit()">Next</button>
					</div>
				</cdk-step>
				<cdk-step label="3">
					<div class="step-header" #pageRef>
						Send Invites
					</div>
					<ng-container *ngIf="steps[sendInvitesStep] as step">
						<div class="overview-container">
							<app-character360-overview #overview [currentStep]="step"
								[character360]="character360"></app-character360-overview>
						</div>
						<div class="stepper-content">
							<div>
								Your <b>{{step.name}}</b> are the people <b>{{step.description}}</b>.
							</div>
							<br>
							You can send them an invite by <b>either</b>:
							<div class="shares">
								<div class="share">
									<div class="share-text">
										1) Emailing them : from your email client
									</div>
									<div class="share-button">
										<button color="primary" class="email" mat-fab (click)="openEmailClient(step)"
											matTooltip="Send Email">
											<mat-icon svgIcon="email"></mat-icon></button>
									</div>
								</div>
								<div class="share">
									<div class="share-text">
										2) Sending them an SMS : we will first send you
										an SMS which you can then forward on to them
									</div>
									<div class="share-button">
										<button color="primary" class="sms" matTooltip="Send SMS" mat-fab (click)="sendSMS(step)"><mat-icon
												svgIcon="sms"></mat-icon></button>
									</div>
								</div>
								<div class="share">
									<div class="share-text">
										3) Copy the message yourself : the message & the link will be
										copied to your Clipboard which you can then paste
									</div>
									<div class="share-button">
										<button color="primary" class="copy" mat-fab matTooltip="Copy to Clipboard"
											(click)="copyToClipboard(step)"><mat-icon svgIcon="content_copy"></mat-icon></button>
									</div>
								</div>
								<div class="share">
									<div class="share-text">
										4) Sending them the link directly : the link will be
										copied to your Clipboard which you can then paste
									</div>
									<div class="share-button">
										<button color="primary" class="copy-link" mat-fab matTooltip="Copy Link to Clipboard"
											(click)="copyLinkToClipboard(step)"><mat-icon>link</mat-icon></button>
									</div>
								</div>

								<div class="flex justify-end">
									<span style="text-align: center;">
										<mat-hint>
											click on the <br>
											buttons above
										</mat-hint>
									</span>
								</div>
							</div>
							<mat-card class="card-important-info">
								<div class="card-important-info-header">
									IMPORTANT
								</div>
								<div class="card-important-info-content">
									<ol class="important-notes">
										<li>
											Each Group will be sent a
											different URL, so please repeat
											this for the other groups
										</li>
										<li>
											We will need a minimum of 3
											responses to generate a report
											for the group
										</li>
									</ol>
								</div>

							</mat-card>
						</div>
						<div class="stepper-actions">
							<button mat-raised-button color="accent" *ngIf="sendInvitesStep===0" cdkStepperPrevious>Back</button>
							<button mat-raised-button color="accent" *ngIf="sendInvitesStep!==0" (click)="back()">Back</button>
							<span class="ca-spacer"></span>
							<button mat-raised-button color="primary" *ngIf="!(sendInvitesStep===5)" (click)="next()">Next</button>
							<button mat-raised-button color="primary" *ngIf="sendInvitesStep===5"
								(click)="complete()">Complete</button>
						</div>
					</ng-container>
				</cdk-step>
			</app-character360-stepper>
		</ng-container>
		<div *ngIf="onlyOverview">
			<div class="step-subheader">
				<div class="flex justify-between" style="padding: 0 20px;">
					<span style="font-weight: 800;">
						Assessment Done : {{character360.report.createdAt | date:'dd/MM/yyyy'}}
					</span>
					<span style="color:white;">
						<i> Assessment Expiry : {{expiresAt| date:'dd/MM/yyyy'}}</i>
					</span>
				</div>
			</div>
			<div class="overview-container">
				<app-character360-overview [character360]="character360" (character360)="updatedCharacter360($event)"
					[showStatus]="true" [showActions]="true" [showRespondents]="true" [expiresAt]="expiresAt" (edit)="edit()"
					(openC360InNewTab)="openC360InNewTab()"></app-character360-overview>
			</div>
			<div style="background-color: #F6FAED;font-size: 10pt;padding: 20px;font-family: 'Raleway';margin: 4px 0;">
				<span><b> Note:</b></span>
				<ol class="notes">
					<li>
						Each Group will be sent a different URL, so please click on the respective link for the respective group
					</li>
					<li>
						We will need a minimum of 3 responses to generate a report for the group
					</li>
				</ol>
			</div>
		</div>
	</div>
	<div class="c360-logo-adjuster">
	</div>
</section>