import {
	NGX_MAT_DATE_FORMATS,
	NgxMatDateFormats,
	NgxMatDatetimePickerModule,
	NgxMatNativeDateModule,
	NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
	MatMomentDateModule,
	MomentDateAdapter
} from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
	MatRippleModule
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
	MAT_SNACK_BAR_DEFAULT_OPTIONS,
	MatSnackBarModule
} from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import {
	MAT_TOOLTIP_DEFAULT_OPTIONS,
	MatTooltipDefaultOptions,
	MatTooltipModule
} from "@angular/material/tooltip";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
	showDelay: 500,
	hideDelay: 250,
	touchendHideDelay: 500,
	position: "above"
};
// If using Moment
const CUSTOM_DATE_TIME_FORMATS: NgxMatDateFormats = {
	parse: {
		dateInput: "DD/MM/YYYY"
	},
	display: {
		dateInput: "DD/MM/YYYY",
		monthYearLabel: "MMM YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "MMMM YYYY"
	}
};
@NgModule({
	declarations: [],
	imports: [CommonModule],
	exports: [
		MatButtonModule,
		MatIconModule,
		MatProgressBarModule,
		MatCardModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatChipsModule,
		MatSnackBarModule,
		MatSidenavModule,
		MatToolbarModule,
		MatListModule,
		MatDividerModule,
		MatMenuModule,
		MatDialogModule,
		MatTableModule,
		MatTabsModule,
		MatPaginatorModule,
		MatSortModule,
		ClipboardModule,
		MatSelectModule,
		MatTooltipModule,
		MatStepperModule,
		MatAutocompleteModule,
		NgxMatSelectSearchModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatRippleModule,
		MatExpansionModule,
		MatRadioModule,
		MatButtonToggleModule,
		CdkStepperModule,
		NgxMatTimepickerModule,
		MatDatepickerModule,
		NgxMatDatetimePickerModule,
		MatMomentDateModule,
		NgxMatNativeDateModule
	],
	providers: [
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: {
				duration: 5000,
				horizontalPosition: "right",
				verticalPosition: "top"
			}
		},
		{
			provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
			useValue: myCustomTooltipDefaults
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS },
		{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS }
	]
})
export class MaterialModule {
	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) {
		this.matIconRegistry.addSvgIcon(
			`eee`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/step.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`linked-in`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/svg/linked-in.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			`facebook`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/fb.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`apple`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/apple.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`single_comparison`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/svg/single-comparison.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			`dual_comparison`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/svg/dual-comparison.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			`arrow`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/svg/mouse-arrow.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			`download-custom`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/svg/download.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			`log`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/log.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`sms`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/sms.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`email`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/email.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`content_copy`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/svg/copy-to-clipboard.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			`ms`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/ms.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`google`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/google.svg")
		);
	}
}
