import { formatDate } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { saveAs } from "file-saver";
import { environment } from "src/environments/environment";
import { isNotNullAndUndefined, removeUndefined } from "../utils/object";
import { queryParams } from "./../types/common";
interface GroupReportQueryParams extends queryParams {
	orgId?: number;
}
interface ReportOptions {
	group?: any;
	role?: any;
	compReport?: any;
	compReport2?: any;
	compGroupReport?: any;
	compGroupReport2?: any;
}
interface ReportDownload extends ReportOptions {
	report: any;
}
interface GroupReportDownload extends ReportOptions {
	groupReport: any;
}
interface shareReport extends ReportOptions {
	groupReport?: any;
	emailIds: string[];
}

@Injectable({
	providedIn: "root"
})
export class GroupReportService {
	baseURL = environment.baseUrl;

	constructor(
		public http: HttpClient,
		@Inject(LOCALE_ID) private locale: string
	) {}
	getAll(queryParams: GroupReportQueryParams) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...queryParams,
					sortBy: queryParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get(`${this.baseURL}/report/group`, { params });
	}
	generateGroupReport(payload: any) {
		return this.http.post(`${this.baseURL}/report/group`, payload);
	}
	checkIfGroupReportAlreadyGenerated(payload: any) {
		return this.http.post(`${this.baseURL}/report/group/check`, payload);
	}

	get(reportUUIDV4: string, queryParams?: any) {
		const params = new HttpParams({
			fromObject: removeUndefined(queryParams ?? {})
		});
		return this.http.get(`${this.baseURL}/report/group/${reportUUIDV4}`, {
			params
		});
	}

	async download({
		groupReport,
		group,
		role,
		compReport,
		compReport2,
		compGroupReport,
		compGroupReport2
	}: GroupReportDownload) {
		return new Promise<void>((resolve, reject) => {
			let filename: any = [
				groupReport.name || groupReport.reportNames.join(", ")
			];
			let paramsObject: any = {};
			if (isNotNullAndUndefined(compReport?.UUIDV4)) {
				paramsObject.compReportUUIDV4 = compReport.UUIDV4;
				filename.push(compReport?.name);
			}
			if (isNotNullAndUndefined(compReport2?.UUIDV4)) {
				paramsObject.compReport2UUIDV4 = compReport2.UUIDV4;
				filename.push(compReport2?.name);
			}
			if (isNotNullAndUndefined(compGroupReport?.UUIDV4)) {
				paramsObject.compGroupReportUUIDV4 = compGroupReport.UUIDV4;
				filename.push(compGroupReport?.name);
			}
			if (isNotNullAndUndefined(compGroupReport2?.UUIDV4)) {
				paramsObject.compGroupReport2UUIDV4 = compGroupReport2.UUIDV4;
				filename.push(compGroupReport2?.name);
			}

			if (isNotNullAndUndefined(group?.id)) {
				paramsObject.groupId = group.id;
				filename.push(group?.name);
			}
			if (isNotNullAndUndefined(role?.id)) {
				paramsObject.roleId = role.id;
				filename.push(role?.name);
			}
			filename = filename.map((s: any) => s && s.trim()).join("_");
			const params = new HttpParams({
				fromObject: removeUndefined(paramsObject)
			});
			this.http
				.get(`${this.baseURL}/report/group/download/${groupReport.UUIDV4}`, {
					params,
					responseType: "blob"
				})
				.subscribe(
					res => {
						let yearMonth = formatDate(Date.now(), "yyyy MM", this.locale);
						saveAs(res, `${yearMonth} Charametrics Report - ${filename}`);
						resolve();
					},
					err => {
						reject(err);
					}
				);
		});
	}
	shareReport({
		emailIds,
		groupReport,
		group,
		role,
		compReport,
		compReport2,
		compGroupReport,
		compGroupReport2
	}: shareReport) {
		let payload: any = {
			emailIds
		};
		if (isNotNullAndUndefined(groupReport)) {
			payload.groupReportUUIDV4 = groupReport.UUIDV4;
		}
		if (isNotNullAndUndefined(compReport?.UUIDV4)) {
			payload.compReportUUIDV4 = compReport.UUIDV4;
		}
		if (isNotNullAndUndefined(compReport2?.UUIDV4)) {
			payload.compReport2UUIDV4 = compReport2.UUIDV4;
		}
		if (isNotNullAndUndefined(compGroupReport?.UUIDV4)) {
			payload.compGroupReportUUIDV4 = compGroupReport.UUIDV4;
		}
		if (isNotNullAndUndefined(compGroupReport2?.UUIDV4)) {
			payload.compGroupReport2UUIDV4 = compGroupReport2.UUIDV4;
		}
		if (isNotNullAndUndefined(group?.id)) {
			payload.groupId = group.id;
		}
		if (isNotNullAndUndefined(role?.id)) {
			payload.roleId = role.id;
		}
		return this.http.post(`${this.baseURL}/report/share`, payload);
	}
	patchReportDefaults(id: string, payload: any) {
		return this.http.patch(
			`${this.baseURL}/report/group/defaults/${id}`,
			payload
		);
	}
}
