<section class="character360-overview-container" #pageRef>
	<app-category-handle *ngIf="isSmallScreen" class="character360-overview-overall " [showStatus]="showStatus"
		[showActions]="showActions" [currentStep]="currentStep" [highlightOverall]="highlightOverall"
		[(character360)]="character360">
	</app-category-handle>
	<div class="shaded-bg" [class.small-screen]="isSmallScreen" [class.mobile-screen]="isMobileScreen">
		<div>
			<div class="heading internal">
				internal
			</div>
			<div class="internal character360-overview-internal" *ngIf="isSmallScreen">
				<ng-container
					*ngTemplateOutlet="stepsTRef;context:{steps:internalSteps,isCommonShareButtonPositions:true}"></ng-container>
			</div>
		</div>
		<div>
			<div class="heading external">
				external
			</div>
			<div class="external character360-overview-external" *ngIf="isSmallScreen">
				<ng-container
					*ngTemplateOutlet="stepsTRef;context:{steps:externalSteps,isCommonShareButtonPositions:true}"></ng-container>
			</div>
		</div>
		<div class="character360-actions-centered" *ngIf="showActions && isSmallScreen">
			<button mat-stroked-button color="primary" *ngIf="!isExpired" (click)="edit.emit()"><mat-icon>edit</mat-icon>Edit
				Character 360</button>
			<button mat-stroked-button color="primary" [disabled]="(character360?.totalResponsesCount||0)<3"
				(click)="openC360InNewTab.emit()"><mat-icon>open_in_new</mat-icon> Open Report</button>
		</div>
	</div>
	<div class="character360-overview" *ngIf="!isSmallScreen">
		<div class="shaded-bg">
			<div class="internal"></div>
			<div class="external"></div>
		</div>
		<div class="character360">
			<span class="ellipse"></span>
			<ng-container *ngTemplateOutlet="stepsTRef;context:{steps}"></ng-container>
			<app-category-handle class="overall" [showStatus]="showStatus" [showActions]="showActions"
				[currentStep]="currentStep" [highlightOverall]="highlightOverall" [(character360)]="character360">
			</app-category-handle>
		</div>
		<div class="character360-actions" *ngIf="showActions">
			<button mat-stroked-button color="primary" *ngIf="!isExpired" (click)="edit.emit()"><mat-icon>edit</mat-icon>Edit
				Character 360</button>
			<button mat-stroked-button color="primary" [disabled]="(character360?.totalResponsesCount||0)<3"
				(click)="openC360InNewTab.emit()"><mat-icon>open_in_new</mat-icon> Open Report</button>
		</div>
	</div>
</section>

<ng-template #stepsTRef let-steps="steps" let-isCommonShareButtonPositions="isCommonShareButtonPositions">
	<div *ngFor="let step of steps" class="step">
		<app-category-handle [isCommonShareButtonPositions]="isCommonShareButtonPositions" [showStatus]="showStatus"
			[showActions]="showActions" [currentStep]="currentStep" [class]="step.id" [step]="step"
			[(character360)]="character360">
		</app-category-handle>
		<div *ngIf="showRespondents" [class]="['respondents-list', step.id]" [class.placeholder]="showRespondents">
			<ng-container
				*ngIf="showRespondents &&  $any(character360.respondents)?.[ $any(C360_CATEGORY_KEY_MAP)[step?.id]]; let respondents">
				<b>Responded:</b>
				<div class="respondent" *ngFor="let item of respondents|slice:0:3">
					{{item}}
				</div>
				<a class="more-respondents " *ngIf="respondents.length>3" (click)="openRespondentsListDialog(step,respondents)">
					Click to see more
				</a>
			</ng-container>
		</div>
	</div>
</ng-template>
