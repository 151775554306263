import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Observable } from "rxjs";
import { USER } from "../../types/user";

@Component({
	selector: "app-members-visibility",
	templateUrl: "./members-visibility.component.html",
	styleUrls: ["./members-visibility.component.scss"]
})
export class MembersVisibilityComponent {
	constructor() {}
	@ViewChild(MatSelect) matSelect?: MatSelect;
	@Input() members?: Observable<USER[]>;
	@Input() selectedIds: number[] = [];

	@Output() selectionChange = new EventEmitter<any>();
	@Output() openedChange = new EventEmitter<{
		$event: boolean;
		matSelect: MatSelect;
	}>();
	selected(value: any) {
		if (value === -1) {
			let hasOther = this.matSelect?.value.some((a: number) => a !== -1);
			let hasAll = this.matSelect?.value.some((a: number) => a === -1);
			if ((hasOther && hasAll) || hasAll) {
				this.matSelect!.value = [-1];
			} else {
				this.matSelect!.value = [];
			}
		} else {
			this.matSelect!.value = this.matSelect?.value.filter(
				(a: number) => a !== -1
			);
		}
		this.selectionChange.emit(this.matSelect?.value);
	}
}
