import {
	STEPPER_GLOBAL_OPTIONS,
	StepperSelectionEvent
} from "@angular/cdk/stepper";
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import {
	StripeAuBankAccountElementChangeEvent,
	StripeCardElementChangeEvent,
	StripeCardElementOptions,
	StripeElementsOptions
} from "@stripe/stripe-js";
import {
	StripeAuBankAccountComponent,
	StripeCardComponent,
	StripeService
} from "ngx-stripe";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription, interval, merge } from "rxjs";
import {
	debounce,
	distinctUntilChanged,
	map,
	startWith,
	tap
} from "rxjs/operators";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { onlyOneRequired } from "../../form/validator/only-one-required";
import { DialogsService } from "../../services/dialogs.service";
import { StepAuthService } from "../../services/step-auth.service";
import { ORG } from "../../types/organisation";
import { STEP_GROUP, SUBSCRIPTION } from "../../types/step-group";
import { fontCenturyGothic } from "../../utils/font";
import { EPGroupDependentsComponent } from "../ep-group-dependents/ep-group-dependents.component";
import { StepParentGroupSelectComponent } from "../step-parent-group-select/step-parent-group-select.component";
import {
	COST_PER_USER_PER_MONTH_TIERS,
	getCostPerUserPerMonth
} from "./../../const/step-group";
import { FilterPipe } from "./../../pipes/filter.pipe";
import { OrganisationService } from "./../../services/organisation.service";
import { StepGroupService } from "./../../services/step-group.service";
import { StepDependentGroupListComponent } from "./../step-dependent-group-list/step-dependent-group-list.component";
const filterPipe = new FilterPipe();
@Component({
	selector: "app-step-parent-group",
	templateUrl: "./step-parent-group.component.html",
	styleUrls: ["./step-parent-group.component.scss"],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false }
		}
	]
})
@AutoUnsubscribeOnDestroy()
export class StepParentGroupComponent implements OnInit, AfterViewInit {
	@Input() refresh?: boolean;
	@Output() refreshChange = new EventEmitter<boolean>();
	@Input() canClose?: boolean = false;
	@Output() canCloseChange = new EventEmitter<boolean>();
	@Output() closeDialog = new EventEmitter<boolean>();
	@Input() subscription?: SUBSCRIPTION;
	@Output() subscriptionChange = new EventEmitter<SUBSCRIPTION>();
	@ViewChild("stepGroupStepper") stepper!: MatStepper;
	@ViewChild(StripeCardComponent) card!: StripeCardComponent;
	@ViewChild(StripeAuBankAccountComponent)
	au_becs_debit!: StripeAuBankAccountComponent;
	@ViewChild(StepParentGroupSelectComponent)
	parentSelect!: StepParentGroupSelectComponent;
	subscriptions = new Subscription();
	loaderId = "dependent-loader";
	loaderForecastId = "forcast-loader";
	step0FG: FormGroup = new FormGroup({});
	step1FG: FormGroup = new FormGroup({});
	step2FG: FormGroup = new FormGroup({});
	step3FG: FormGroup = new FormGroup({});
	selectedParent?: STEP_GROUP;
	dependents?: Array<STEP_GROUP> = [];
	newDependents?: Array<STEP_GROUP> = [];
	filterDependentsFC = new FormControl();
	filteredDependents?: Array<STEP_GROUP> = [];
	paymentInfos: any = [];
	paymentInfo: any;
	Infinity = Infinity;
	tiers = COST_PER_USER_PER_MONTH_TIERS;
	user!: any;
	cardError?: string;
	bankError?: string;
	paymentMethodType!: string; //="EXISTING"; //"EXISTING" | "NEW";
	cardOptions: StripeCardElementOptions = {
		style: {
			base: {
				fontFamily: "Century Gothic",
				fontSize: "18px"
			}
		},
		hidePostalCode: true
	};
	elementsOptions: StripeElementsOptions = {
		fonts: [fontCenturyGothic],
		locale: "en",
		loader: "always"
	};
	refreshDependentsEvent = new EventEmitter();
	orgs: Array<any> = [];
	globalSelectedOrg!: ORG | null;
	selectedOrg!: ORG;
	totalNoOfUsers: number = 0;
	constructor(
		private fb: FormBuilder,
		private stripeService: StripeService,
		private stepAuthService: StepAuthService,
		private stepGroupService: StepGroupService,
		private _dialog: MatDialog,
		private UiLoaderService: NgxUiLoaderService,
		public organisationService: OrganisationService,
		public dialogsService: DialogsService,
		private _snack: MatSnackBar
	) {
		this.stepAuthService.getUserInfo().subscribe(user => (this.user = user));
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe((org: ORG | null) => {
				this.globalSelectedOrg = org;
			})
		);
	}
	// get isPaymentMethodNoAccess() {
	// 	if (!this.subscription?.paymentInfoId) return false;
	// 	let pm = this.paymentInfos.find(
	// 		(payment: any) => payment.id === this.subscription?.paymentInfoId
	// 	);
	// 	if (pm) return false;
	// 	return true;
	// }
	ngOnInit(): void {
		this.getOrgs();
	}
	ngAfterViewInit(): void {
		this.initStep0FG();
		this.initStep1FG();
		if (this.subscription?.id) {
			this.goto(2);
		}
		this.card.focus.subscribe(res => {
			this.selectPaymentInfo(null);
		});
		merge(
			this.filterDependentsFC.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				tap(() => {
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
				}),
				distinctUntilChanged()
			),
			this.refreshDependentsEvent
		)
			.pipe(
				startWith(""),
				map((data: any) => {
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
					return filterPipe.transform(
						this.allDependents,
						["groupName", "groupRef"],
						this.filterDependentsFC.value
					);
				})
			)
			.subscribe(data => {
				return (this.filteredDependents = data);
			});
	}
	updateRefresh(value: boolean) {
		this.refresh = value;
		this.refreshChange.emit(value);
	}
	updateCanClose(value: boolean) {
		this.canClose = value;
		this.canCloseChange.emit(value);
	}
	updateSubscription(value?: SUBSCRIPTION) {
		this.subscription = value;
		this.subscriptionChange.emit(value);
	}
	async getOrgs() {
		(await this.organisationService.getOrgs()).subscribe((orgs: Array<ORG>) => {
			this.orgs = orgs;
		});
	}
	getSubscriptionByGroupId() {
		this.stepGroupService
			.getSubscriptionByGroupId({
				parentId: this.step1FG.value.parentId
			})
			.subscribe((res: any) => {
				if (res.statusCode === 200) {
					this.selectedParent = res.group;
					this.updateSubscription({
						...res.groupSubscription,
						group: res.group,
						dependentGroups: res.dependentGroups
					});
					this.dependents = res.dependentGroups.map((d: STEP_GROUP) => {
						d.selected = true;
						return d;
					});
					this.initStep2FG();
					this.refreshDependentsEvent.emit();
					this.getEstimatedUsersCount();
				}
			});
	}
	initStep0FG() {
		this.step0FG = this.fb.group({
			orgId: [
				this.subscription?.orgId || this.globalSelectedOrg?.id,
				Validators.required
			]
		});
		if (this.step0FG.valid) {
			this.getOrg();
			this.goto(1);
		}
	}
	initStep1FG() {
		this.step1FG = this.fb.group({
			parentId: [this.subscription?.group.id, Validators.required]
		});
	}
	initStep2FG() {
		this.step2FG = this.fb.group({
			orgId: [
				this.subscription?.orgId ||
					this.globalSelectedOrg?.id ||
					this.step0FG.value.orgId,
				Validators.required
			],
			dependents: this.fb.array(
				this.allDependents?.map((dep, i) => {
					dep.i = i;
					return this.initDependent(dep);
				}) ?? []
			)
		});
		this.step2FG
			.get("dependents")
			?.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startLoader(this.loaderForecastId);
					return interval(3000);
				}),
				tap(() => {
					this.UiLoaderService.stopLoader(this.loaderForecastId);
				}),
				distinctUntilChanged(),
				map((data: any) => {
					return this.getEstimatedUsersCount();
				})
			)
			.subscribe(data => {});
	}
	get allDependents() {
		return [
			...((this.dependents as STEP_GROUP[]) || []),
			...(this.newDependents as STEP_GROUP[])
		];
	}
	getOrg() {
		const orgId = this.step0FG.get("orgId")?.value;
		if (orgId) {
			this.organisationService.getOrg(orgId).subscribe(res => {
				this.selectedOrg = res.org;
			});
		}
	}

	initStep3FG() {
		this.step3FG = this.fb.group(
			{
				paymentInfoStripeId: [],
				paymentInfoId: [this.subscription?.paymentInfoId],
				card: [{ value: "", disabled: true }],
				auBankAccount: [{ value: "", disabled: true }],
				country: [, Validators.required]
			},
			{
				validators: onlyOneRequired("card", "paymentInfoId", "auBankAccount")
			}
		);
	}
	initDependent(v: any): FormGroup {
		return this.fb.group({
			id: [v?.id],
			groupName: [v.groupName],
			groupRef: [v.groupRef],
			imageKey: [v.imageKey],
			hasIncludedDependents: [
				{
					value: v?.hasIncludedDependents ?? true,
					disabled: !v?.selected
				}
			],
			hasDependent: [v?.hasDependent ?? false],
			selected: [v?.selected],
			forestCount: [v?.forestCount],
			dependentForestCount: [v?.dependentForestCount],
			groupSubscription: [v?.groupSubscription],
			isPayByParent: [v?.isPayByParent ?? true],
			status: [v?.status || "live"]
		});
	}

	selectParent(parent: STEP_GROUP) {
		this.selectedParent = parent;
		this.step1FG?.get("parentId")?.setValue(parent.id);
		this.goto(2);
		if (!this.subscription?.id) {
			this.setupSubscription();
		}
	}
	goto(index: 0 | 1 | 2 | 3) {
		this.UiLoaderService.start();
		setTimeout(() => {
			while (this.stepper.selectedIndex < index) {
				this.stepper.next();
			}
			this.UiLoaderService.stop();
		}, 0);
	}
	get totalMonthlyCost() {
		return this.costPerUser * this.totalNoOfUsers;
	}
	get costPerUser() {
		return getCostPerUserPerMonth(this.totalNoOfUsers);
	}
	get currentSelectedDependents() {
		return this.step2FG.value?.dependents?.filter((dep: any) => dep.selected);
	}
	async stepChange($event: StepperSelectionEvent) {
		switch ($event.selectedIndex) {
			case 0:
				break;
			case 1:
				if (
					$event.selectedIndex === 1 &&
					$event.previouslySelectedIndex !== 1
				) {
					this.parentSelect?.refreshEvent?.emit();
				}
				break;
			case 2:
				this.getSubscriptionByGroupId();
				this.initStep2FG();
				break;
			case 3:
				await this.setupSubscription();
				this.initStep3FG();
				this.getPaymentInfo();
				break;
		}
	}
	async completeSetupSubscriptionAndClose() {
		await this.setupSubscription();
		this._snack.open("Updates made Successfully");
		this.closeDialog.emit();
	}
	async onSelect($event: MatCheckboxChange, i: number) {
		let fc = this.step2FG.get("dependents")?.get(i + "");
		if ($event.checked) {
			fc?.get("hasIncludedDependents")?.enable();
			fc?.get("hasIncludedDependents")?.setValue(true);

			if (fc?.value.groupSubscription?.paymentInfoId) {
				let isPayByParent =
					await this.dialogsService.confirmIfAlreadyHasSubscriptionDialog(
						fc?.value
					);
				fc?.patchValue({ isPayByParent });
				if (!isPayByParent) {
					fc?.get("hasIncludedDependents")?.setValue(false);
					let canAddAsDependent =
						await this.dialogsService.confirmIfAddAsDependentIfPayingAlreadyDialog(
							fc?.value
						);
					if (!canAddAsDependent) {
						fc?.get("selected")?.setValue(false);
					}
				}
			}
		} else {
			fc?.get("hasIncludedDependents")?.disable();
		}
	}
	async payByParentUpdate($event: MatButtonToggleChange, i: number) {
		let fc = this.step2FG.get("dependents")?.get(i + "");
		if ($event.value) {
			fc?.get("hasIncludedDependents")?.setValue(true);
		}
	}

	onIncludeDependentChange($event: MatSlideToggleChange, i: number) {
		let fc = this.step2FG.get("dependents")?.get(i + "");
		if (fc?.value.isPayByParent) {
			if (!$event.checked) {
				this._snack.open("You have to Pay for their Dependents too.");
				fc.get("hasIncludedDependents")?.setValue(true);
			}
		}
	}
	createCardToken(): void {
		this.stripeService
			.createPaymentMethod({
				type: "card",
				card: this.card.element
			})
			.subscribe(result => {
				if (result.paymentMethod) {
					this.step3FG.patchValue({
						paymentInfoStripeId: result.paymentMethod.id,
						paymentInfoId: undefined
					});
					this.savePaymentInfo();
				} else if (result.error) {
					this.cardError = result.error.message;
				}
			});
	}
	async createAUBECSDebitToken() {
		let setupIntent = await this.stepGroupService.getSetupIntent().toPromise();
		this.stripeService
			.confirmAuBecsDebitSetup(setupIntent.clientSecret, {
				payment_method: {
					au_becs_debit: this.au_becs_debit.element,
					billing_details: {
						name: this.user.fullName,
						email: this.user.email
					}
				}
			})
			.subscribe(result => {
				if (result.setupIntent) {
					this.step3FG.patchValue({
						paymentInfoStripeId: result.setupIntent?.payment_method,
						paymentInfoId: undefined
					});
					this.savePaymentInfo();
				} else if (result.error) {
					this.bankError = result.error.message;
				}
			});
	}
	setExisting() {
		this.step3FG.patchValue({
			paymentInfoStripeId: undefined,
			paymentInfoId: this.subscription?.paymentInfoId
		});
		this.step3FG.get("card")?.disable();
		this.step3FG.get("auBankAccount")?.disable();
	}
	getPaymentInfos() {
		this.stepGroupService.getPaymentInfos().subscribe((res: any) => {
			this.paymentInfos = res.list;
		});
	}
	getPaymentInfo() {
		if (this.subscription?.paymentInfoId) {
			this.stepGroupService
				.getPaymentInfo(this.subscription?.paymentInfoId)
				.subscribe((res: any) => {
					this.paymentInfo = res.data;
				});
		}
	}
	savePaymentInfo() {
		if (this.subscription)
			this.stepGroupService
				.savePaymentInfo(
					{ groupSubscriptionId: this.subscription?.id as number },
					this.step3FG.value
				)
				.subscribe((res: any) => {
					this.getPaymentInfo();
					this.updateSubscription({
						...res.groupSubscription,
						group: this.selectedParent
					});
					this.updateRefresh(true);
					this.updateCanClose(true);
					this.closeDialog.emit();
					this._snack.open("Updates made Successfully");
				});
	}
	removePaymentInfo() {
		if (this.subscription)
			this.stepGroupService
				.removePaymentInfo(this.subscription?.subscriptionStripeId)
				.subscribe((res: any) => {
					this.updateSubscription({
						...res.groupSubscription,
						group: this.selectedParent
					});
					this.updateRefresh(true);
					this.updateCanClose(true);
					this.closeDialog.emit();
					this._snack.open("Updates made Successfully");
				});
	}
	selectPaymentInfo(
		paymentInfo: any,
		type: "EXISTING" | "NEW_CARD" | "NEW_ACCOUNT" = "EXISTING"
	) {
		if (type === "EXISTING") {
			this.step3FG.patchValue({
				paymentInfoStripeId: undefined,
				paymentInfoId: paymentInfo.id
			});
			this.step3FG.get("card")?.disable();
			this.step3FG.get("auBankAccount")?.disable();
			this.step3FG.get("country")?.disable();
		} else {
			this.step3FG.patchValue({
				paymentInfoId: undefined
			});
			if (type === "NEW_CARD") {
				this.step3FG.get("card")?.enable();
				this.step3FG.get("country")?.enable();
				this.step3FG.get("auBankAccount")?.disable();
			}
			if (type === "NEW_ACCOUNT") {
				this.step3FG.get("card")?.disable();
				this.step3FG.get("country")?.disable();
				this.step3FG.get("auBankAccount")?.enable();
			}
		}
	}

	onCardChange($event: StripeCardElementChangeEvent) {
		this.selectPaymentInfo(null, "NEW_CARD");
		this.step3FG.get("card")?.setValue($event.complete ? "set" : "");
		if ($event.error) {
			this.cardError = $event.error.message;
		} else {
			delete this.cardError;
		}
	}
	onBankChange($event: StripeAuBankAccountElementChangeEvent) {
		this.selectPaymentInfo(null, "NEW_ACCOUNT");
		this.step3FG.get("auBankAccount")?.setValue($event.complete ? "set" : "");
		if ($event.error) {
			this.bankError = $event.error.message;
		} else {
			delete this.bankError;
		}
	}
	openEligibleDependentsDialog() {
		let currentDependentsMap = new Map();
		this.step2FG?.value.dependents.forEach((dep: STEP_GROUP) => {
			currentDependentsMap.set(dep.id, dep);
		});
		let dialogRef = this._dialog.open(StepDependentGroupListComponent, {
			minWidth: "600px",
			panelClass: "ca-dialog",
			disableClose: true,
			data: {
				parentId: this.step1FG.value.parentId,
				selected: this.newDependents
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result?.selected) {
				this.newDependents = result.selected.map((d: STEP_GROUP) => {
					let alreadyExist = currentDependentsMap.get(d.id) as STEP_GROUP;
					if (alreadyExist) d = alreadyExist;
					else d.selected = true;
					d.status = "draft";
					return d;
				});
				this.dependents = this.dependents?.map((d: STEP_GROUP) => {
					let alreadyExist = currentDependentsMap.get(d.id) as STEP_GROUP;
					if (alreadyExist) d = alreadyExist;
					d.status = "live";
					return d;
				});
				this.initStep2FG();
				this.step2FG?.markAsDirty();
				this.refreshDependentsEvent.emit();
				this.getEstimatedUsersCount();
				this.updateCanClose(false);
			}
		});
	}
	setupSubscription() {
		return new Promise<void>(resolve => {
			let payload = {
				parentType: "Paying",
				dependents: this.currentSelectedDependents,
				orgId: this.step0FG.value.orgId
			};
			this.stepGroupService
				.setupSubscription({ groupId: this.step1FG.value.parentId }, payload)
				.subscribe((res: any) => {
					this.updateSubscription({
						...res.groupSubscription,
						group: this.parentSelect
					});
					this.dependents = payload.dependents;
					this.newDependents = [];
					this.initStep2FG();
					this.step2FG.patchValue({
						orgId: payload.orgId,
						dependents: this.dependents
					});
					this.initStep3FG();
					this.step2FG?.markAsDirty();
					this.refreshDependentsEvent.emit();
					this.updateRefresh(true);
					this.updateCanClose(true);
					this._snack.open("Parent Group Set up Successfully");
					resolve();
				});
		});
	}
	completeSetup() {
		if (this.step3FG.value.paymentInfoId) {
			this.savePaymentInfo();
		} else {
			if (
				this.step3FG.get("card")?.enable &&
				this.step3FG.value.card === "set"
			) {
				this.createCardToken();
			}
			if (
				this.step3FG.get("auBankAccount")?.enable &&
				this.step3FG.value.auBankAccount === "set"
			) {
				this.createAUBECSDebitToken();
			}
		}
	}
	updateOrg(org: ORG) {
		this.step2FG.get("orgId")?.setValue(org.id);
		this.step2FG.get("orgId")?.markAsDirty();
	}
	updateStep0Org(org: ORG) {
		this.step0FG.get("orgId")?.setValue(org.id);
		this.step0FG.get("orgId")?.markAsDirty();
		this.selectedOrg = org;
		if (this.stepper.selectedIndex === 0) {
			this.goto(1);
		}
	}
	getEstimatedUsersCount() {
		if (this.step1FG.valid && this.step2FG.valid) {
			this.UiLoaderService.startLoader(this.loaderForecastId);
			this.stepGroupService
				.getUniqueUsersCount(
					{ groupId: this.step1FG.value.parentId },
					{
						dependents: this.currentSelectedDependents
					}
				)
				.subscribe(
					(res: any) => {
						this.UiLoaderService.stopLoader(this.loaderForecastId);

						this.totalNoOfUsers = res.data.usersCount;
					},
					err => {
						this.UiLoaderService.stopLoader(this.loaderForecastId);
					}
				);
		}
	}
	openGroup(group?: STEP_GROUP) {
		let dialogRef = this.dialogsService.openGroup(this.selectedOrg.id, group);
		dialogRef.afterClosed().subscribe((result: any) => {
			if (result?.created) {
				this.selectParent(result?.group);
				if (result?.openCreateDependents) {
					this.openCreateDependents();
				}
			}
			if (result?.refresh) {
				this.parentSelect?.refreshEvent?.emit();
			}
		});
	}
	openCreateDependents() {
		let dialogRef = this._dialog.open(EPGroupDependentsComponent, {
			minWidth: "600px",
			panelClass: "ca-dialog",
			disableClose: true,
			data: {
				parentId: this.step1FG.value.parentId,
				orgId: this.selectedOrg.id
			}
		});
		dialogRef.afterClosed().subscribe((result: any) => {
			if (result?.refresh) {
				this.parentSelect?.refreshEvent?.emit();
			}
			this.newDependents = this.newDependents?.concat(
				result.dependents.map((d: STEP_GROUP) => {
					d.selected = true;
					d.status = "draft";
					return d;
				})
			);
			this.updateCanClose(false);
			this.initStep2FG();
			this.step2FG?.markAsDirty();
			this.refreshDependentsEvent.emit();
		});
	}
}
