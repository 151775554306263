import { Component, Inject, TemplateRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-confirm-dialog",
	templateUrl: "./confirm-dialog.component.html",
	styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent {
	title: string;
	message?: string;
	messageTemplateRef: TemplateRef<any>;
	confirmText;
	messageTemplateContext: any;
	dismissText;
	confirmColor?: COLOR = "primary";
	dismissColor?: COLOR;
	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
	) {
		// Update view with given values
		this.title = data.title;
		this.message = data?.option?.message;
		this.messageTemplateRef = data?.option
			?.messageTemplateRef as TemplateRef<any>;
		this.messageTemplateContext = data.option?.messageTemplateContext;
		this.confirmText = data.option?.confirmText;
		this.dismissText = data.option?.dismissText;
		this.confirmColor ??= data.option?.confirmColor;
		this.dismissColor ??= data.option?.dismissColor;
	}
	onConfirm(): void {
		// Close the dialog, return true
		this.dialogRef.close(true);
	}

	onDismiss(): void {
		// Close the dialog, return false
		this.dialogRef.close(false);
	}
}

declare type COLOR = "primary" | "accent" | "warn";
/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
	constructor(
		public title: string,
		public option?: ConfirmDialogModelOptions
	) {}
}
export interface ConfirmDialogModelOptions {
	message?: string;
	messageTemplateRef?: TemplateRef<any>;
	messageTemplateContext?: any;
	confirmText: string;
	dismissText: string;
	confirmColor?: COLOR;
	dismissColor?: COLOR;
}
