import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from "@angular/material/dialog";
import { map } from "lodash";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { LoggerService } from "src/app/_shared/services/logger.service";
import { ReportService } from "src/app/_shared/services/report.service";
import { getSurveyURL } from "src/app/_shared/utils/string";
import { AccountService } from "../../services/account.service";
import { GroupReportService } from "../../services/group-report.service";
import { COMPARISON_TYPE } from "../../types/common";
import { ShareReportComponent } from "../share-report/share-report.component";

@Component({
	selector: "app-single-comparison",
	templateUrl: "./single-comparison.component.html",
	styleUrls: ["./single-comparison.component.scss"]
})
export class SingleComparisonComponent {
	downloadButtonLoaderId = "download-loader";
	groupFG!: FormGroup;
	reportCtrl: FormControl = new FormControl(null, Validators.required);
	hideReportIds!: string[];
	groupReportCtrl: FormControl = new FormControl(null, Validators.required);
	hideGroupReportIds!: string[];
	comparison1: COMPARISON_TYPE = "TARGET";
	get isInvalid() {
		return !this.isComparison1Valid;
	}
	get reportIds() {
		return map(this.data.reports, "UUIDV4");
	}
	get comparison1Keys() {
		switch (this.comparison1) {
			case "TARGET":
				return {
					key: "groupId",
					field: "group",
					fg: this.groupFG,
					id: this.groupFG.value?.id,
					isValid: this.groupFG.value?.id
				};
			case "GROUP":
				return {
					key: "compGroupReportUUIDV4",
					field: "compGroupReport",
					fg: this.groupReportCtrl,
					id: this.groupReportCtrl.value?.UUIDV4,
					isValid: this.groupReportCtrl.valid
				};
			case "USER":
				return {
					key: "compReportUUIDV4",
					field: "compReport",
					fg: this.reportCtrl,
					id: this.reportCtrl.value?.UUIDV4,
					isValid: this.reportCtrl.valid
				};
		}
	}
	get isComparison1Valid() {
		return this.comparison1Keys?.isValid;
	}
	constructor(
		public dialogRef: MatDialogRef<SingleComparisonComponent>,
		public reportService: ReportService,
		public groupReportService: GroupReportService,
		private UiLoaderService: NgxUiLoaderService,
		private dialog: MatDialog,
		public Logger: LoggerService,
		private accountService: AccountService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.hideReportIds = [this.data.report?.UUIDV4];
		this.hideGroupReportIds = [this.data.groupReport?.UUIDV4];
	}
	onAspirationFormGroupReady($event: FormGroup) {
		this.groupFG = $event;
	}
	async openInNewTab() {
		const surveyURL = getSurveyURL();
		let auth;
		let url = `${surveyURL}/report`;
		switch (this.data.type) {
			case "INDIVIDUAL":
				url += `/${this.data.report.UUIDV4}?isPartner=true`;
				auth = await this.accountService.getSinglePurposeToken({
					reportUUIDV4: this.data.report.UUIDV4
				});
				break;
			case "GROUP":
				url += `/group/${this.data.groupReport.UUIDV4}?`;
				auth = await this.accountService.getSinglePurposeToken({
					groupReportUUIDV4: this.data.groupReport.UUIDV4
				});
				break;
		}
		url += `&authorization=${auth?.token}&authType=${auth?.type}`;
		let comp1 = this.comparison1Keys;
		url += `&${comp1.key}=${comp1.id}`;
		window.open(url);
	}
	getPayload() {
		let payload: any = {};
		let comp1 = this.comparison1Keys;
		payload[comp1.field] = comp1.fg.value;
		return payload;
	}
	async downloadReport() {
		let payload = this.getPayload();
		this.UiLoaderService.startLoader(this.downloadButtonLoaderId);
		try {
			switch (this.data.type) {
				case "INDIVIDUAL":
					await this.reportService.download({
						report: this.data.report,
						...payload
					});
					break;
				case "GROUP":
					await this.groupReportService.download({
						groupReport: this.data.groupReport,
						...payload
					});
					break;
			}
		} catch (error) {
			this.Logger.error(error);
		} finally {
			this.UiLoaderService.stopLoader(this.downloadButtonLoaderId);
		}
	}
	openShareReport() {
		let payload = this.getPayload();
		this.dialog.open(ShareReportComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				report: this.data.report,
				groupReport: this.data.groupReport,
				...payload
			}
		});
	}
}
