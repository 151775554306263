<h1 mat-dialog-title>Add More Dependents to Draft</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<section fxLayout="column">
		<div fxLayout="column" fxLayoutAlign="start stretch" style="margin-bottom: 16px;">
			<div fxLayout="row" fxLayoutAlign="start center">
				<mat-form-field fxFlex="0 0 30%" fxFlexFill>
					<mat-label>Filter</mat-label>
					<input matInput placeholder="Group Name or Group Ref" #input [formControl]="filterFormControl">
					<button matSuffix mat-icon-button (click)="filterFormControl.setValue('')">
						<mat-icon *ngIf="filterFormControl.value" aria-label="Clear">close</mat-icon>
						<app-loader [loaderId]="filterLoaderId" [bgsSize]="24" bgsPosition="center-center"></app-loader>
					</button>
				</mat-form-field>
				<div style="position: relative;height:25px; width: 25px;">
					<app-loader [loaderId]="loaderId" [bgsSize]=25></app-loader>
				</div>
				<span class="ca-spacer"></span>
				<mat-paginator class="ca-mat-paginator" [length]="resultsLength" [pageSize]="pageSize"
					[showFirstLastButtons]="(resultsLength/pageSize)>2" [pageSizeOptions]="[10,25,50,100]"
					aria-label="Select page">
				</mat-paginator>
			</div>
			<mat-hint style="margin-top: -20px;">
				<small> NOTE: Groups shown are where you are
					listed as a
					Group member, Culture
					Consultant,
					Support
				</small>
			</mat-hint>
		</div>
		<div>
			<mat-chip-list aria-label="Dependents selection">
				<mat-chip *ngFor="let item of selection.selected">
					<app-avatar [avatar]="item">
					</app-avatar>
					<button mat-icon-button (click)="toggle(item)">
						<mat-icon>
							close
						</mat-icon>
					</button>
				</mat-chip>
			</mat-chip-list>
		</div>
		<div fxLayout="column" class="ca-page-content">
			<div fxLayout="row" fxLayoutAlign="center center" fxFlex="shrink"
				*ngIf="!isLoadingResults && dataSource?.data?.length===0">
				<div *ngIf="(filterFormControl?.value===null || filterFormControl?.value?.length===0)">
					Your Group that are eligible will appear here
				</div>
				<div *ngIf="filterFormControl?.value?.length>0">
					There are no results available for this keyword &nbsp; <b>"{{ filterFormControl.value}}" </b>.
					Please
					refine
					your search with some other words.
				</div>
			</div>
			<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
			<div class="mat-elevation-z2" [hidden]="isLoadingResults || dataSource?.data?.length===0">
				<mat-table [dataSource]="dataSource" matSort [matSortActive]="'name'" matSortDirection="asc" fixedLayout="true">
					<ng-container matColumnDef="select">
						<mat-header-cell *matHeaderCellDef>
							<mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
								[checked]="selection.hasValue() && isAllSelected()"
								[indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
							</mat-checkbox>
						</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="toggle(row)"
								[checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
							</mat-checkbox>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="groupRef">
						<mat-header-cell *matHeaderCellDef mat-sort-header="groupRef">
							Group Ref
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.groupRef}}
						</mat-cell>

					</ng-container>
					<ng-container matColumnDef="groupName">
						<mat-header-cell *matHeaderCellDef mat-sort-header="groupName">
							Group
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-avatar [avatar]="element">
							</app-avatar>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="directDebit">
						<mat-header-cell *matHeaderCellDef>
							Direct Debit
						</mat-header-cell>
						<mat-cell *matCellDef="let element;">
							<ng-container *ngIf="element.groupSubscription?.paymentInfoId; else notActivatedRef">
								<mat-icon matTooltip="Activated Payment" color="primary">
									paid
								</mat-icon>
							</ng-container>
							<ng-template #notActivatedRef>
								<mat-icon matTooltip="Not Activated Payment" color="accent">
									paid
								</mat-icon>
							</ng-template>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="noOfUsers">
						<mat-header-cell *matHeaderCellDef>
							No of Users (In Dependents)
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.forestCount}}
							<ng-container *ngIf="element.hasDependent">
								({{element.dependentForestCount}})
							</ng-container>
						</mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
					<mat-row matRipple *matRowDef="let row; columns: displayedColumns;" (click)="toggle(row)">
					</mat-row>
				</mat-table>
			</div>
		</div>
	</section>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]>
		Close
	</button>
	<button color="primary" mat-raised-button (click)="addSelected()">
		Add selected
	</button>

</mat-dialog-actions>
