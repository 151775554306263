import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GroupReportService } from "src/app/_shared/services/group-report.service";
interface IReport {
	id: number;
	name: string;
	reportNames: string[];
	disabled?: boolean;
}
@Component({
	selector: "app-group-report-select",
	templateUrl: "./group-report-select.component.html",
	styleUrls: ["./group-report-select.component.scss"]
})
export class GroupReportSelectComponent
	implements OnInit, OnChanges, OnDestroy
{
	/** control for the selected Group report */
	@Input() groupReportCtrl: FormControl = new FormControl();
	@Input() readOnly?: boolean = false;
	@Input() options?: { label?: string; hideGroupReportIds?: string[] } = {};
	get disabled() {
		return this.readOnly ?? false;
	}
	/** control for the MatSelect filter keyword */
	public groupReportFilterCtrl: FormControl = new FormControl();

	/** list of reports filtered by search keyword */
	public filteredreports: ReplaySubject<IReport[]> = new ReplaySubject<
		IReport[]
	>(1);

	/** Subject that emits when the component has been destroyed. */
	protected _onDestroy = new Subject<void>();

	constructor(public groupReportService: GroupReportService) {}

	ngOnInit() {
		// listen for search field value changes
		this.groupReportFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterReports();
			});
		this.filterReports();
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.filterReports();
	}
	getreports(searchText: string = "") {
		this.groupReportService
			.getAll({ searchText, limit: 50 })
			.pipe(takeUntil(this._onDestroy))
			.subscribe((res: any) => {
				let ignored = res.reportsList.map((report: any) => ({
					...report,
					disabled: this.options?.hideGroupReportIds?.includes(report.UUIDV4)
				}));
				this.filteredreports.next(ignored);
			});
	}

	compareFn(a: IReport, b: IReport): boolean {
		return a && b && a.id === b.id;
	}
	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}
	clearSelection($event: MouseEvent) {
		this.groupReportCtrl.reset();
		$event.stopPropagation();
	}
	protected filterReports() {
		// get the search keyword
		let search = this.groupReportFilterCtrl.value;
		if (!search) {
			this.getreports();
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the reports
		this.getreports(search);
	}
}
