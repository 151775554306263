import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { NgScrollbar } from "ngx-scrollbar";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";

@Component({
	selector: "app-ng-scrollbar-wrapper",
	templateUrl: "./ng-scrollbar-wrapper.component.html",
	styleUrls: ["./ng-scrollbar-wrapper.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class NgScrollbarWrapperComponent implements AfterViewInit {
	@Input() autoHeightDisabled = true;
	private subscriptions = new Subscription();
	constructor() {}
	@ViewChild(NgScrollbar) scrollbarRef?: NgScrollbar;
	isTop: boolean = true;
	isBottom: boolean = false;
	isLeft: boolean = true;
	isRight: boolean = false;
	get ngClass() {
		return {
			"is-top": this.isTop,
			"is-bottom": this.isBottom,
			"is-left": this.isLeft,
			"is-right": this.isRight
		};
	}
	ngAfterViewInit() {
		this.subscriptions.add(
			this.scrollbarRef?.verticalScrolled
				.pipe(
					tap((e: any) => {
						if (e.target.scrollTop === 0) {
							this.isTop = true;
							this.isBottom = false;
						} else if (
							e.target.scrollTop + e.target.clientHeight ===
							e.target.scrollHeight
						) {
							this.isBottom = true;
							this.isTop = false;
						} else {
							this.isTop = false;
							this.isBottom = false;
						}
					})
				)
				.subscribe()
		);
		this.subscriptions.add(
			this.scrollbarRef?.horizontalScrolled
				.pipe(
					tap((e: any) => {
						if (e.target.scrollLeft === 0) {
							this.isLeft = true;
							this.isRight = false;
						} else if (
							e.target.scrollLeft + e.target.clientWidth ===
							e.target.scrollWidth
						) {
							this.isRight = true;
							this.isLeft = false;
						} else {
							this.isLeft = false;
							this.isRight = false;
						}
					})
				)
				.subscribe()
		);
	}
}
