<section class="ca-report-basic-tables">
	<table class="ca-report-basic-table">
		<ng-container [ngSwitch]="report.type">
			<ng-container *ngSwitchCase="'INDIVIDUAL'">
				<tr>
					<td class="ca-head ca-primary-bg ca-border-right stroke-right-thick">
						<strong> For {{report?.name | titlecase }}</strong>
					</td>
					<td class="ca-col-a ca-primary-bg ca-border-left ">
						<strong> Age</strong>
					</td>
					<td class="ca-col-b ca-primary-bg ">
						<strong>{{report?.age??"Age not provided"}}</strong>
					</td>
				</tr>
				<tr>
					<th
						class="ca-head no-bottom-padding ca-border-right stroke-right-thick ca-primary-bg-light ca-border-top stroke-top-thick">
						Major Phase</th>
					<td class="ca-col-a ca-tertiary-bg ca-border-top stroke-top-thick">&nbsp;</td>
					<td class="ca-col-b ca-tertiary-bg ca-border-top stroke-top-thick">
						{{report?.evolutionPhases?.majorPhase}}</td>
				</tr>
				<tr>
					<th class="ca-head no-top-padding ca-border-right stroke-right-thick ca-primary-bg-light">Sub Phase
					</th>
					<td class="ca-col-a ca-tertiary-bg">&nbsp;</td>
					<td class="ca-col-b ca-tertiary-bg"> {{report?.evolutionPhases?.subPhase}}</td>
				</tr>
			</ng-container>
			<ng-container *ngSwitchCase="'GROUP'">
				<tr>
					<td class="ca-head ca-primary-bg " colspan="3">
						<strong>For {{report.reportNames.join(", ")}}</strong>
					</td>
				</tr>
			</ng-container>
		</ng-container>

		<tr *ngFor="let beingPreference of report?.beingPreferences;let i=index">
			<th class="ca-head  ca-text-v-align ca-primary-bg-light ca-border-right stroke-right-thick ca-border-top "
				*ngIf="i===0" rowspan="3">
				Ways of Being Valued Preference
			</th>
			<td class="ca-col-a ca-tertiary-bg ca-border-right" [ngClass]="{'ca-border-top ': i===0}">
				{{i+1|ordinal}}
			</td>
			<td class="ca-col-b ca-tertiary-bg" [ngClass]="{'ca-border-top ': i===0}">
				{{beingPreference?.value}}</td>
		</tr>

		<tr *ngFor="let lifeCulturePreference of report?.lifeCulturePreferences;let i=index">
			<th class="ca-head  ca-text-v-align ca-primary-bg-light ca-border-right stroke-right-thick ca-border-top"
				*ngIf="i===0" rowspan="7">
				Life & Culture Needs Preference
			</th>
			<td class="ca-col-a ca-tertiary-bg ca-border-right" [ngClass]="{'ca-border-top': i===0}">
				{{i+1|ordinal}}
			</td>
			<td class="ca-col-b ca-tertiary-bg" [ngClass]="{'ca-border-top': i===0}">
				{{lifeCulturePreference.id}}.
				{{lifeCulturePreference?.value}}</td>
		</tr>
	</table>
</section>