<button mat-mini-fab class="ca-dialog-close-icon light-btn outer" (click)="close()">
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<mat-tab-group *ngIf="dialogData">
		<mat-tab [label]="label('Parent')"
			*ngIf="!dialogData?.subscription?.id||dialogData?.subscription?.parentType==='Paying'">
			<app-step-parent-group [(refresh)]="refresh" [(canClose)]="canClose" [(subscription)]="dialogData.subscription"
				(closeDialog)="close()">
			</app-step-parent-group>
		</mat-tab>
		<mat-tab [label]="label('Reporting')"
			*ngIf="!dialogData?.subscription?.id||dialogData?.subscription?.parentType==='Reporting'">
			coming soon!!!
		</mat-tab>
	</mat-tab-group>
</mat-dialog-content>
