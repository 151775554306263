<h1 *ngIf="dialogRef" mat-dialog-title>
	Group Hierarchy
</h1>
<button *ngIf="dialogRef" mat-mini-fab class="ca-dialog-close-icon light-btn outer" [mat-dialog-close]="{refresh}">
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<ng-container *ngTemplateOutlet="content">
	</ng-container>
</mat-dialog-content>
<ng-template #content>
	<section style="height: 100%;border: 1px solid gray;">
		<app-loader [loaderId]="loaderId"></app-loader>
		<div class="tree-chart-container">
			<div style="height: 100%;" echarts [options]="backdropOption" class="tree-chart-backdrop"
				(chartInit)="onBackdropChartInit($event)" [merge]="backdropChartOption"></div>
			<div style="height: 100%;" echarts [options]="option" class="tree-chart" (chartInit)="onChartInit($event)"
				(chartContextMenu)="onContextMenu($event)" [merge]="chartOption" (chartClick)="onChartClick($event)"></div>
		</div>
	</section>
</ng-template>
