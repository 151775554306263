import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { interval, merge, Subscription } from "rxjs";
import {
	debounce,
	distinctUntilChanged,
	filter,
	finalize,
	startWith,
	switchMap,
	tap
} from "rxjs/operators";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { UniqueAspirations } from "../../form/validator/unique-aspirations";
import { OrganisationService } from "../../services/organisation.service";
import { ORG } from "../../types/organisation";
import { GroupService } from "./../../services/group.service";
import { RoleService } from "./../../services/role.service";

@Component({
	selector: "app-target",
	templateUrl: "./target.component.html",
	styleUrls: ["./target.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class TargetComponent implements OnInit, OnChanges {
	subscriptions = new Subscription();
	refreshEvent = new EventEmitter();
	@Input() type!: "GROUP" | "ROLE";
	loaderId!: string;
	@Input() id?: number | null;
	@Output() idChange: EventEmitter<number | null> = new EventEmitter<
		number | null
	>();
	@Input() orgId!: number;
	targetFG!: FormGroup;
	@Output() aspirationFormGroupReady: EventEmitter<FormGroup> =
		new EventEmitter<FormGroup>();
	@Input() options?: { hideIds?: any[] } = {};
	groupCtrl = new FormControl("", Validators.required);
	errorMsg: string = "";
	filteredGroups!: any[];
	services!: { GROUP: GroupService; ROLE: RoleService };
	isLoading = false;
	globalSelectedOrg?: ORG | null;
	constructor(
		public fb: FormBuilder,
		private _snackBar: MatSnackBar,
		private UiLoaderService: NgxUiLoaderService,
		public groupService: GroupService,
		public organisationService: OrganisationService,
		public roleService: RoleService
	) {
		this.services = {
			GROUP: groupService,
			ROLE: roleService
		};
		this.targetFG = this.fb.group({
			orgId: [, Validators.required],
			id: [],
			name: [, Validators.required],
			aspirations: this.fb.array(
				new Array(7).fill(0).map((value, index) => {
					return this.fb.group({
						id: [],
						name: [, Validators.required],
						value: [, Validators.required],
						order: [index]
					});
				}),
				UniqueAspirations
			)
		});
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe((org: ORG | null) => {
				this.globalSelectedOrg = org;
			})
		);
	}
	get aspirations() {
		return this.targetFG.get("aspirations") as FormArray;
	}
	updateOrg(event: ORG) {
		this.targetFG.patchValue({ orgId: event.id });
		this.refreshEvent.next();
	}
	ngOnInit(): void {
		this.aspirationFormGroupReady.emit(this.targetFG);
		this.targetFG.patchValue({ orgId: this.orgId });
		this.loaderId = "target-" + this.type;
		this.subscriptions.add(
			merge(
				this.groupCtrl.valueChanges.pipe(
					filter(value => {
						return typeof value === "string";
					}),
					tap(value => {
						this.targetFG.patchValue({ name: value, id: null });
						this.id = null;
						this.idChange.emit(this.id);
					}),
					debounce(() => {
						this.UiLoaderService.startLoader(this.loaderId);
						this.isLoading = true;
						return interval(2000);
					}),
					tap(() => {
						this.UiLoaderService.stopLoader(this.loaderId);
					}),
					distinctUntilChanged()
				),
				this.refreshEvent
			)
				.pipe(
					startWith({}),
					tap(() => {
						this.errorMsg = "";
						this.filteredGroups = [];
					}),
					switchMap(value => {
						return this.services[this.type]
							.get({
								searchText: this.groupCtrl.value,
								orgId: this.orgId || this.globalSelectedOrg?.id
							})
							.pipe(
								finalize(() => {
									this.isLoading = false;
									this.UiLoaderService.stopLoader(this.loaderId);
								})
							);
					})
				)
				.subscribe((data: any) => {
					if (data.list == undefined) {
						this.errorMsg = data["message"];
						this.filteredGroups = [];
					} else {
						this.errorMsg = "";
						let ignored = data.list.map((o: any) => ({
							...o,
							disabled: this.options?.hideIds?.includes(o.id)
						}));
						this.filteredGroups = ignored;
					}
				})
		);

		this.init();
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.refreshEvent.emit();
	}
	init() {
		if (this.id) {
			this.UiLoaderService.startLoader(this.loaderId);
			this.services[this.type].getById(this.id + "", this.orgId + "").subscribe(
				(res: any) => {
					if (res.statusCode == 200) {
						this.targetFG.patchValue(res.data);
						this.groupCtrl.patchValue(res.data);
					}
					this.UiLoaderService.stopLoader(this.loaderId);
				},
				err => {
					this.UiLoaderService.stopLoader(this.loaderId);
				}
			);
		}
	}
	onSelected($event: MatAutocompleteSelectedEvent) {
		this.id = this.groupCtrl.value.id;
		this.idChange.emit(this.id);
		this.targetFG.patchValue(this.groupCtrl.value);
	}
	clearSelection() {
		this.id = null;
		this.idChange.emit(this.id);
		this.groupCtrl.setValue("");
		this.clearGroupData();
		// this.refreshEvent.next();
	}
	clearGroupData() {
		this.targetFG.patchValue({ name: null, id: null });
	}
	displayWith(value: any) {
		return value?.name;
	}
	save() {
		this.targetFG.value.id ? this.update() : this.create();
	}
	create() {
		this.UiLoaderService.startLoader(this.loaderId);
		const payload = {
			name: this.targetFG.value.name,
			orgId: this.targetFG.value.orgId || this.globalSelectedOrg?.id,
			aspirations: this.targetFG.value.aspirations.map(function (v: any) {
				delete v.id;
				return v;
			})
		};
		this.subscriptions.add(
			this.services[this.type].create(payload).subscribe(
				(res: any) => {
					if (res.statusCode == 200) {
						this.targetFG.patchValue(res.data);
						this._snackBar.open("Created Succesfully.");
					}
					this.refreshEvent.emit();
					this.UiLoaderService.stopLoader(this.loaderId);
				},
				errr => {
					this.UiLoaderService.stopLoader(this.loaderId);
				}
			)
		);
	}
	update() {
		this.UiLoaderService.startLoader(this.loaderId);
		this.subscriptions.add(
			this.services[this.type].update(this.targetFG.value).subscribe(
				(res: any) => {
					if (res.statusCode == 200) {
						this._snackBar.open("Updated Succesfully.");
					}
					this.UiLoaderService.stopLoader(this.loaderId);
				},
				err => {
					this.UiLoaderService.stopLoader(this.loaderId);
				}
			)
		);
	}
	delete() {
		this.UiLoaderService.startLoader(this.loaderId);
		this.subscriptions.add(
			this.services[this.type].delete(this.targetFG.value.id).subscribe(
				(res: any) => {
					if (res.statusCode == 200) {
						this.clearSelection();
						this.targetFG.updateValueAndValidity();
						this.targetFG.markAsPristine();
						this.UiLoaderService.stopLoader(this.loaderId);
						this._snackBar.open("Deleted Succesfully.");
						this.refreshEvent.emit();
					}
				},
				err => {
					this.UiLoaderService.stopLoader(this.loaderId);
				}
			)
		);
	}
}
