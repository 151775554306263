import { Observable, timer } from "rxjs";
import { map, takeWhile } from "rxjs/operators";

export function maxLimit(max: number) {
	return function (value: number) {
		return value < max ? value : max;
	};
}
export function formatIfDecimal(value: number, fractionDigits: number) {
	let v = value.toFixed(fractionDigits);
	return v.replace(new RegExp(".0{" + fractionDigits + "}$"), "");
}
export function countDown(seconds: number): Observable<number> {
	return timer(0, 1000).pipe(
		map(n => seconds - n),
		takeWhile(n => n >= 0)
	);
}
