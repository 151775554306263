<section [class.bonsai-bg]="!noBG" fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
	<mat-card class="ca-center-container ca-padding-75px-50px" [class.mat-elevation-z]="flat">
		<form #loginForm="ngForm" (submit)="login()">
			<ng-container [ngSwitch]="titleType">
				<ng-container *ngSwitchCase="'CONTINUE_C360'">
					<mat-card-title>
						<h1>
							Continue with Character 360
						</h1>
					</mat-card-title>
					<mat-card-subtitle>
						To continue with your Character 360 Assessment, please login using the account used to claim this
						charametrics
						report.
						Use the Forgot Password option below if you are having difficulties logging in.
					</mat-card-subtitle>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<mat-card-title>
						<div>
							<h1>ShareTree</h1>
							<h1>{{authService.getPlatformTitle()}}</h1>
						</div>
						<div fxLayout="row" fxLayoutAlign="center center">
							<img src="/assets/logo-small.png" />
						</div>
						<h4 style="text-align: center;">Login to your account </h4>
					</mat-card-title>
				</ng-container>
			</ng-container>
			<mat-card-content fxLayout="column" [fxLayoutGap]="titleType?'0':'20px'">
				<mat-form-field fxFlexFill fxFlexOffset="25px">
					<mat-label>Email address</mat-label>
					<input #emailId="ngModel" matInput required type="email" placeholder="abc@example.com" name="emailId"
						[(ngModel)]="loginFormModel!.emailId" email>
					<mat-error *ngIf="emailId.touched && emailId.errors?.required">
						Please type in Email Id.
					</mat-error>
					<mat-error *ngIf="emailId.touched && emailId.errors?.invalid">
						Please type in a valid Email Id.
					</mat-error>
				</mat-form-field>
				<mat-form-field fxFlexFill>
					<mat-label>Password</mat-label>
					<input #password="ngModel" matInput required [type]="isVisible?'text':'password'" name="password"
						[(ngModel)]="loginFormModel!.password">
					<mat-icon matSuffix (click)="isVisible=!isVisible">
						{{isVisible? "visibility_off":"visibility"}}
					</mat-icon>
					<mat-error *ngIf="password.touched && password.errors?.required">
						Please type in Password.
					</mat-error>
				</mat-form-field>

			</mat-card-content>
			<mat-card-actions fxLayout="column" fxLayoutAlign="center center">
				<button fxFlex="50%" fxFlexFill mat-raised-button color="primary">Login</button>
				<div class="ca-forgot-password-container">
					<a mat-flat-button class="ca-forgot-password" (click)="openForgotPassword()">Forgot Password or
						Can't login?</a>
				</div>
				<ng-container *ngIf="this.type==='STC360' && !isNewUser">
					<br>
					<br>
					<app-google-signin (signedIn)=signedInWithGoogle($event)></app-google-signin>
					<br>
					<app-microsoft-signin (signedIn)=signedInWithMicrosoft($event)></app-microsoft-signin>
				</ng-container>
			</mat-card-actions>
		</form>
	</mat-card>
</section>
