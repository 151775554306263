import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { SingleComparisonComponent } from "src/app/_shared/components/single-comparison/single-comparison.component";
import { AutoUnsubscribeOnDestroy } from "src/app/_shared/decorators/autoUnsubscribeOnDestroy";
import { LoggerService } from "src/app/_shared/services/logger.service";
import { COST_PER_ASSESSMENT } from "../../const/organisation";
import { getOrgTypeDiscount } from "../../pipes/org-type-discount.pipe";
import { AuthService } from "../../services/auth.service";
import { OrganisationService } from "../../services/organisation.service";
import { ORG, OrgType } from "../../types/organisation";
import { getSurveyURL } from "../../utils/string";
import { DualComparisonComponent } from "../dual-comparison/dual-comparison.component";
import { GroupReportService } from "./../../services/group-report.service";

@Component({
	selector: "app-generate-group-report",
	templateUrl: "./generate-group-report.component.html",
	styleUrls: ["./generate-group-report.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class GenerateGroupReportComponent {
	loaderId = "generate-group-report-loader";
	subscriptions = new Subscription();
	org?: ORG;
	COST_PER_ASSESSMENT = COST_PER_ASSESSMENT;
	fg!: FormGroup;
	isAlreadyGenerated = false;
	submitted = false;
	state: "GENERATE" | "GENERATED" = "GENERATE";
	groupReport: any;
	constructor(
		public dialogRef: MatDialogRef<GenerateGroupReportComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: { reports: any[] },
		public Logger: LoggerService,
		private fb: FormBuilder,
		private _snackBar: MatSnackBar,
		private _dialog: MatDialog,
		private authService: AuthService,
		private UiLoaderService: NgxUiLoaderService,
		private organisationService: OrganisationService,
		private groupReportService: GroupReportService
	) {
		this.fg = this.fb.group({
			orgId: [, Validators.required],
			name: [],
			reportUUIDV4s: [
				this.dialogData.reports.map(r => r.UUIDV4),
				Validators.required
			]
		});
		this.checkIfAlreadyGenerated();
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe((org: ORG | null) => {
				this.org = org as ORG;
				if (org) {
					this.fg.patchValue({ orgId: org.id });
				}
			})
		);
	}
	checkIfAlreadyGenerated() {
		this.UiLoaderService.startLoader(this.loaderId);
		let payload = this.fg.value;
		this.groupReportService
			.checkIfGroupReportAlreadyGenerated(payload)
			.subscribe({
				next: (res: any) => {
					if (res.isAlreadyGenerated) {
						this.groupReport = res.groupReport;
						this.isAlreadyGenerated = true;
						this.state = "GENERATED";
					}
					this.UiLoaderService.stopLoader(this.loaderId);
				},
				error: () => {
					this.UiLoaderService.stopLoader(this.loaderId);
				}
			});
	}
	get costPerAssignmentAfterDiscount() {
		if (!this.org) return COST_PER_ASSESSMENT;
		return Math.floor(
			COST_PER_ASSESSMENT - COST_PER_ASSESSMENT * this.discountRate
		);
	}
	get discountRate() {
		return getOrgTypeDiscount(this.org?.orgType as OrgType);
	}
	updateOrg(org: ORG) {
		this.fg.get("orgId")?.setValue(org.id);
		this.org = org;
		this.fg.get("orgId")?.markAsDirty();
	}
	onSubmit() {
		this.fg.markAllAsTouched();
		if (this.fg.valid) {
			this.UiLoaderService.startLoader(this.loaderId);
			let payload = this.fg.value;
			this.groupReportService.generateGroupReport(payload).subscribe({
				next: (res: any) => {
					this.groupReport = res.groupReport;
					this.state = "GENERATED";
					this.UiLoaderService.stopLoader(this.loaderId);
				},
				error: () => {
					this.UiLoaderService.stopLoader(this.loaderId);
				}
			});
		}
	}
	openInNewTab() {
		const surveyURL = getSurveyURL();
		const auth = this.authService.getAuth();
		window.open(
			`${surveyURL}/report/group/${this.groupReport.UUIDV4}?&authorization=${auth.token}&authType=${auth.type}`
		);
	}
	async download() {
		this.UiLoaderService.startLoader("groupReport");
		try {
			await this.groupReportService.download({
				groupReport: this.groupReport
			});
		} catch (error) {
			this.Logger.error(error);
		} finally {
			this.UiLoaderService.stopLoader("groupReport");
		}
	}
	openSingleComparison() {
		const dialogRef = this._dialog.open(SingleComparisonComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				type: "GROUP",
				groupId: this.groupReport.selectedSingleComparisonGroupId ?? null,
				orgId: this.groupReport?.orgId,
				groupReport: this.groupReport
			}
		});
		dialogRef.afterClosed().subscribe(groupId => {
			if (groupId !== this.groupReport.selectedSingleComparisonGroupId) {
				const payload = {
					selectedSingleComparisonGroupId: groupId
				};
				this.groupReportService
					.patchReportDefaults(this.groupReport.UUIDV4, payload)
					.subscribe((res: any) => {
						this.groupReport.selectedSingleComparisonGroupId = groupId;
					});
			}
		});
	}
	openDualComparison() {
		const dialogRef = this._dialog.open(DualComparisonComponent, {
			panelClass: "ca-dialog",
			minHeight: "80%",
			maxHeight: "100%",
			data: {
				type: "GROUP",
				groupId: this.groupReport.selectedDualComparisonGroupId ?? null,
				roleId: this.groupReport.selectedDualComparisonRoleId ?? null,
				orgId: this.groupReport?.orgId,
				groupReport: this.groupReport
			}
		});
		dialogRef.afterClosed().subscribe(({ groupId, roleId }) => {
			if (
				groupId !== this.groupReport.selectedDualComparisonGroupId ||
				roleId !== this.groupReport.selectedDualComparisonRoleId
			) {
				const payload = {
					selectedDualComparisonGroupId: groupId,
					selectedDualComparisonRoleId: roleId
				};
				this.groupReportService
					.patchReportDefaults(this.groupReport.UUIDV4, payload)
					.subscribe((res: any) => {
						this.groupReport.selectedDualComparisonGroupId = groupId;
						this.groupReport.selectedDualComparisonRoleId = roleId;
					});
			}
		});
	}
}
