import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
	C360_CATEGORY_REPORT_KEY_MAP,
	MAX_LIMIT
} from "src/app/_shared/const/character360";
import { Character360Service } from "src/app/_shared/services/character360.service";
import {
	CATEGORY,
	Character360,
	Counts
} from "src/app/_shared/types/character360";
import { Step } from "../../types/character360";
import { addMonths } from "src/app/_shared/utils/date";

@Component({
	selector: "app-category-handle",
	templateUrl: "./category-handle.component.html",
	styleUrls: ["./category-handle.component.scss"]
})
export class CategoryHandleComponent {
	@Input() id?: CATEGORY;
	@Input() currentStep?: Step;
	@Input() character360!: Character360;
	@Output() character360Change = new EventEmitter<Character360>();
	@Input() step?: Step;
	@Input() showStatus = false;
	@Input() showActions = false;
	@Input() highlightOverall = false;
	@Input() isCommonShareButtonPositions = false;
	today = new Date();
	get MAX_LIMIT() {
		return MAX_LIMIT;
	}
	get totalCount() {
		return this.character360?.totalResponsesCount || 0;
	}
	get count() {
		let key = C360_CATEGORY_REPORT_KEY_MAP[
			this.step?.id as CATEGORY
		] as keyof Counts;
		return this.character360?.[key] || 0;
	}
	get isRed() {
		return this.showStatus && this.count === 0;
	}
	get isOrange() {
		return this.showStatus && this.count > 0 && this.count < 3;
	}
	get isOverallRed() {
		return this.showStatus && this.totalCount === 0;
	}
	get isOverallOrange() {
		return this.showStatus && this.totalCount > 0 && this.totalCount < 3;
	}
	get expiresAt() {
		let createdAt = new Date(this.character360.report.createdAt);
		return addMonths(createdAt, 6);
	}
	get isExpired() {
		return this.today > this.expiresAt;
	}
	get handleClasses() {
		let classes = ["handle-container", this.step?.id];
		if (!!this.highlightOverall || this.currentStep?.id === this.step?.id) {
			classes.push("active");
		}
		return classes;
	}
	constructor(private character360Service: Character360Service) {}
	copyToClipboard(step: Step) {
		this.character360Service.copyToClipboard(this.character360, step);
	}
	copyLinkToClipboard(step: Step) {
		this.character360Service.copyLinkToClipboard(this.character360, step);
	}
	openEmailClient(step: Step) {
		this.character360Service.openEmailClient(this.character360, step);
	}
	async sendSMS(step: Step) {
		let result = await this.character360Service.sendSMSIfCanSend(
			this.character360,
			step
		);
		if (result?.refresh) {
			this.character360 = result.character360;
			this.character360Change.emit(this.character360);
		}
	}
}
