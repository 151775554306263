import { AbstractControl, FormArray } from "@angular/forms";
import { findLastIndex } from "lodash";

export function UniqueAspirations(control: AbstractControl) {
	const formarray = control as FormArray;
	const list = control.value as Array<any>;
	list.forEach((element1: any, index: number) => {
		if (!element1.value) return;

		const foundAt = list.findIndex(
			(element2: any) => element2.value === element1.value
		);
		const foundLastAt = findLastIndex(
			list,
			(element2: any) => element2.value === element1.value
		);
		const fc = formarray.at(index).get("name");
		if (foundAt < index) {
			fc?.setErrors({ unique: true, foundAt });
		} else if (index == foundAt && index == foundLastAt) {
			fc?.setErrors(null);
		} else {
			fc?.setErrors({ unique: true, foundAt: foundLastAt });
		}
	});
	return null;
}
