<ng-container [ngSwitch]="tokenStatus">
    <ng-container *ngSwitchCase="'WITHDRAWN'">
        <app-error-page [clientSide]=true [error]="{message:'Invite has been withdrawn by the inviter.'}">
        </app-error-page>

    </ng-container>
    <ng-container *ngSwitchCase="'INVALID'">
        <app-error-page [clientSide]=true [error]="{message:'Invite is invalid.'}">
        </app-error-page>
    </ng-container>
    <ng-container *ngSwitchCase="'NOT_FOUND'">
        <app-error-page [clientSide]=true [error]="{message:'Invite not found.'}">
        </app-error-page>
    </ng-container>
    <ng-container *ngSwitchCase="'VALID'">
        <section class="bonsai-bg" fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
            <ng-container *ngIf="isJoinOrgInvite;else createOrgRef">
                <mat-card class="ca-center-container">
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <div fxFlexOffset="10px">
                                You have been invited to join the Organisation.
                            </div>
                            <div>
                                <app-avatar *ngIf="org" [avatar]="org">
                                </app-avatar>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="ca-margin-0" fxLayout="row">
                        <span class="ca-spacer"></span>
                        <button mat-flat-button color="primary" (click)="accept()"> Accept</button>
                    </mat-card-actions>
                </mat-card>
            </ng-container>
            <ng-template #createOrgRef>
                <mat-card class="ca-center-container">
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <div fxFlexOffset="10px">
                                You have been invited to join as Client to the Organisation.
                            </div>
                            <div>
                                <app-avatar *ngIf="org" [avatar]="org">
                                </app-avatar>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="ca-margin-0" fxLayout="row">
                        <span class="ca-spacer"></span>
                        <button mat-flat-button color="primary" (click)="openCreateOrg()"> Accept</button>
                    </mat-card-actions>
                </mat-card>
            </ng-template>
        </section>
    </ng-container>
</ng-container>