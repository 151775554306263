import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "firstCap"
})
export class FirstCapPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): string {
		if (!value) return "";
		return value.split(" ").map(capitalize).join(" ");
	}
}
function capitalize(s: string) {
	if (!s) return "";
	return s[0].toUpperCase() + s.slice(1);
}
