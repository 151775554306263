import { Component, Input } from "@angular/core";
import { CharacterArchetypeDescription } from "src/app/_shared/types/character360";
import { previewOffset } from "../character";
@Component({
	selector: "app-archetype-descriptions",
	templateUrl: "./archetype-descriptions.component.html",
	styleUrls: ["./archetype-descriptions.component.scss"]
})
export class ArchetypeDescriptionsComponent {
	@Input() isLoading: boolean = true;
	@Input() pageNoOffsetForComparison: number = 0;
	@Input() report!: {
		characterArchetypes: CharacterArchetypeDescription[];
		isPreview: Boolean;
	};
	get previewOffset() {
		return previewOffset(<boolean>this.report.isPreview);
	}
}
