<section class="ca-archetype-descriptions">
	<ng-container *ngIf="!report?.isPreview">
		<app-archetype-description *ngFor="let archetype of report?.characterArchetypes;let first = first;let i=index"
			[archetype]="archetype" [first]="first"
			[pageNumber]="('YCSG3'|pageNumber:pageNoOffsetForComparison:'CHARAMETRICS':previewOffset)+(i*2)"></app-archetype-description>

	</ng-container>
	<div class="ca-report" *ngIf="report?.isPreview;else templateRefForYCGP">
		<app-preview-contents [report]="report"></app-preview-contents>
	</div>
	<ng-template #templateRefForYCGP>
		<app-anchor anchorId="YCGP"></app-anchor>
	</ng-template>
	<div class="ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-anchor anchorId="CS&E"></app-anchor>
			<app-page-title title="Character Strengths & Evolution">
			</app-page-title>
			<br>
			<p>
				<b> ShareTree Character Strengths Assessments</b> help identify your unique leading character strength
				groups and
				explores their aspects, evolution, and associated Archetypes. These assessments can be used to gain
				clarity
				around one’s own strengths and areas for potential growth. It is useful for personal development,
				coaching,
				career planning, human relationships, recruitment, culture alignment, team building and wellbeing.
				<br> <br>
				Each character trait has <b>“Qualities/Potential” </b>as well as associated <b>“Challenges”</b>. When
				these traits are
				explored, balanced and mastered, this evolves the trait into a <b>“Thriving”</b> state.
			</p>
			<br>
			<br>
			<table class="ca-table-with-bg">
				<tr>
					<th class="table-heading bg-colored padded-8-16 border-bottom font-family-raleway" colspan="2">
						<app-anchor anchorId="Q/P"></app-anchor>
						<b> Qualities/Potential</b>
					</th>
				</tr>
				<tr>
					<td class="cell bg-colored padded-16 border-bottom" colspan="2">
						Each character trait is individual and unique; having the potential to thrive in every person.
						For a trait to evolve
						and grow, we must explore how these traits “Contract” and “Flex”, in the same way muscles are
						formed.
						When a trait is Flexed and Contracted resourcefully, within harmonious boundaries; we make
						meaningful
						contributions that help ourselves and others grow. This experience helps us find balance, which
						creates a
						state of flow and over many cycles of exploration this trait can become a “Thriving” part of our
						character.
					</td>
				</tr>
				<tr>
					<td class="cell ca-text-align-center bg-colored-img border-right img-padded" width="68%">
						<img width="100%" height="auto" src="/assets/images/challenge-triangle.png">
					</td>
					<td class="cell bg-colored-text padded-16" width="32%">
						<img width="100%" height="auto" src="/assets/images/table-text-v1.png"
							alt="Boundaries are resourceful limits based on respect and care for ourselves and others. When our intention is one of mindful character growth for everyone involved, we must seek to wisely create healthy boundaries which can be managed internally and expressed peacefully, externally.">
					</td>
				</tr>
				<tr>
					<td class="cell bg-colored-text padded-16 border-top" colspan="2">
						A boundary can be likened to the limit that our muscles are willing and capable to handle;
						forming a protective framework around our capacity for strain. Invite too much strain and you
						may break; invite too little strain and you may limit your growth. Courageous Curiosity is the
						doorway to growth, and
						balance is the key to optimising and sustaining it. Once resourceful boundaries are established,
						they need to
						be expressed, maintained, and adjusted in conjunction with other people’s boundaries that you
						interact with in life.
					</td>
				</tr>
			</table>

		</div>
		<app-page-footer [pageNo]="'CS&E'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
	<div class=" ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<table class="ca-table-with-bg">
				<tr>
					<th class="table-heading bg-colored padded-8-16 border-bottom font-family-raleway" colspan="2">
						<app-anchor anchorId="CHALLENGES"></app-anchor>
						<b> Challenge</b>
					</th>
				</tr>
				<tr>
					<td class="cell bg-colored padded-16 border-bottom" colspan="2">
						<b> Challenge </b> is an essential and necessary part of character growth, in the same way load
						is required to grow
						muscle, or how shadows bring contrast and depth to a perspective when we view a landscape. When
						we
						experience challenge, we discover through strain, that there can be unresourceful and
						conflicting aspects of
						a character trait. The awareness to observe these aspects allows us to make mindful adjustments
						for growth.
					</td>
				</tr>
				<tr>
					<td class="cell bg-colored-text padded-16 border-right border-bottom" width="75%">
						<b> Over-utilised traits</b> are when we lead with a trait in an excessive and
						unbalanced way. <br> In the same way we might physically exert ourselves or make
						abrupt movements beyond the limits of our capacity. This can create undue
						stress, disharmony and even harm. The benefit is when we are mindful of these
						situations or we have trusted people around us to bring it to our attention, it can
						help us create meaningful boundaries and re-balance us.
					</td>
					<td class="cell ca-text-align-center bg-colored-img border-right img-padded border-bottom" width="25%">
						<img height="auto" width="100%" src="/assets/images/over-utilised-traits.png">

					</td>
				</tr>
				<tr>
					<td class="cell bg-colored-text padded-16 border-right border-bottom" width="75%">
						<b>Underutilised traits </b> are when we do not engage with a character trait, or we
						refrain from exercising it. This can be likened to when we do not engage with
						our muscles. Their growth becomes limited and their potential untapped. This
						is not the case with the traits listed in your profile, as this profile highlights your
						3 leading character strengths, though when people around you underutilise
						character traits that are your leading strengths, this may present as triggers that
						can potentially create frustration, division, and conflict.
					</td>
					<td class="cell ca-text-align-center bg-colored-img border-right img-padded border-bottom" width="25%">
						<img height="auto" width="100%" src="/assets/images/under-utilised-traits.png">
					</td>
				</tr>
				<tr>
					<td class="cell bg-colored-text padded-16 border-right border-bottom" width="75%">
						<b> Under-evolved traits</b> exist when we have natural strengths that we don’t exercise
						and evolve. This often occurs out of fear to engage with certain experiences
						and challenges. This fear can limit the depth and pace of our evolution and
						constrains our character trait reaching its fullest potential, also known as the
						Thriving state. Under-evolved traits can create turbulence in our lives, especially
						when other traits are evolved, and the under-evolved trait is required as a
						balancing pair. For example, if Truth is evolved and Respect is under-evolved,
						this can bring Truth delivered without tact.

					</td>
					<td class="cell ca-text-align-center bg-colored-img border-right img-padded border-bottom" width="25%">
						<img height="auto" width="100%" src="/assets/images/under-evolved-traits.png">
					</td>
				</tr>
			</table>
			<br>
			<table class="ca-table-with-bg">
				<tr>
					<th class="table-heading bg-colored padded-8-16 border-bottom font-family-raleway" colspan="2">
						<app-anchor anchorId="THRIVING"></app-anchor>
						<b> Thriving</b>
					</th>
				</tr>
				<tr>
					<td class="cell bg-colored padded-16 border-right" rowspan="2">
						Like a pendulum, our character strengths will oscillate naturally to provide
						growth and evolution until we find balance and flow in Flexing and Contracting.
						When one finds balance and flow, by embracing the evolution that comes
						from both the qualities and challenges, we see the potential of that strength
						fulfilled and a level of mastery achieved that inspires ourselves and those
						around us to flourish.
						<br><br>
						It’s knowing that a state of oneness only comes from the sum of its parts
						and that all shades of light are required to build perspective and contrast.
						It embraces performance that gives rise to achievement, while integrated
						with a state of moral being. Thriving character traits require Professional, Social
						and/or Spiritual evolution, this can only be attained by experiencing and
						exploring a character trait fully and embracing the qualities and challenges
						associated with it.
					</td>
					<td class="cell ca-text-align-center bg-colored-img border-right img-padded border-bottom" width="25%">
						<img height="auto" width="100%" src="/assets/images/thriving-1.png">
					</td>
				</tr>
				<tr>
					<td class="cell ca-text-align-center bg-colored-img border-right img-padded" width="25%">
						<img height="auto" width="100%" src="/assets/images/thriving-2.png">
					</td>
				</tr>
			</table>
		</div>
		<app-page-footer [pageNo]="'CHALLENGES'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
	<div class="ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-page-title title="Understanding Your Profile">
			</app-page-title>
			<br>
			<p>
				<b>
					In this report, you will be given your personalised Character Strengths Summary Wheel. To help you
					understand
					this visual better, the different parts have been broken down and explained over the following two
					pages.
				</b>
			</p>
			<br>
			<table class="ca-table-without-bg-border">
				<tr>
					<td class="cell padded-right padded-bottom-large" width="calc(100% - 240px)">
						<div class="ca-row-heading font-family-raleway">
							<app-anchor anchorId="PERSONAS_4"></app-anchor>
							4 Persona Quadrants
						</div>
						<div class="ca-row-content">
							Persona Quadrants are simple summary representations of a group
							of Character Archetypes and provide a reflection of how people
							that identify with each Persona may interact with others and present
							in the world. Each Persona Quadrant helps people better identify
							with the behaviours, mindsets, perspectives and challenges typically
							experienced.
							<br><br>
							Persona Quadrants are formed based on the combination between
							being Visionary or Grounded and being an Activator or Integrator.
							People can identify strongly with specific Persona Quadrants or can
							be balanced across all four types. The percentages in each Persona
							Quadrant provides an insight about which Personas most appear
							based on your character strengths.
						</div>
					</td>
					<td width="240px" height="270px" class="cell ca-text-align-center padded-bottom-large">
						<img width="auto" height="100%" src="/assets/images/4-persona-quadrants.png">
					</td>
				</tr>
				<tr>
					<td class="cell padded-right padded-bottom-large" width="calc(100% - 240px)">
						<div class="ca-row-heading font-family-raleway">
							<app-anchor anchorId="A&D_12"></app-anchor>

							12 Character Archetypes
						</div>
						<div class="ca-row-content">
							There are 12 unique Character Archetypes listed around in the outer
							ring of the wheel. The ShareTree Character Archetypes profile provides
							220 different combinations and 1320 permutations (these include the
							specific sequencing order of the three leading Archetypes).
							<br><br>
							More accurately, your character is made up of 12 groups of character
							traits, which combined together, can make up more than 479
							million combinations. Essentially, what this means is that each of us
							is completely unique. This profile gives you a simple summary of your
							leading strengths and their general patterns of associated archetypes
							to help you become more aware of yourself and others in the spirit of
							character discovery, growth, and evolution.
						</div>
					</td>
					<td width="240px" height="270px" class="cell ca-text-align-center padded-bottom-large">
						<img width="auto" height="100%" src="/assets/images/12-character-archetypes.png">
					</td>
				</tr>

				<tr>
					<td class="cell padded-right padded-bottom-large " width="calc(100% - 240px)">
						<div class="ca-row-heading font-family-raleway"> 3 Contexts of Expression</div>
						<div class="ca-row-content">

							The three inner circles of the wheel represent your character strengths
							profile. This is the combination of your three leading character strength
							groups represented as Archetypes. Archetypes are character traits as
							living qualities in the dance of life. These Archetypes are represented
							in <b> Professional </b>(darker green gring),<b> Social </b>(lighter green ring) and
							<b> Spiritual </b>(inner white circle)expressions. These are explained on the
							following page.
							<br><br>
							We are made up by a combination of all three expressions and some
							of us may have preference to one over another as our preferred
							expression for exploration and growth. Our character evolution can
							develop in any of the three and all three concurrently.
						</div>
					</td>
					<td width="240px" height="270px" class=" cell ca-text-align-center padded-bottom-large">
						<img width="auto" height="100%" src="/assets/images/3-contexts-of-expression.png">
					</td>
				</tr>
			</table>
		</div>
		<app-page-footer [pageNo]="'PERSONAS_4'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
	<div class=" ca-print-page">
		<div class="ca-page-content ca-page-margin">
			<app-page-title title="Understanding Your Profile">
			</app-page-title>
			<div class="ca-sub-heading font-family-raleway">
				<app-anchor anchorId="CSG"></app-anchor>
				Character Strength Groups
			</div>
			<table class="ca-table-without-bg-border">
				<tr>
					<td width="240px" class="cell padded-right padded-bottom-small">
						<img width="100%" height="auto" src="/assets/images/professional-ring.png">
					</td>
					<td width="auto" class="cell vertical-align-middle  padded-bottom-small">
						<div class="ca-row-heading professional-color font-family-raleway">Professional</div>
						<div class="ca-row-content">
							The darker ring of the Wheel represents the Professional context; our
							academic and career aspect of ourselves. It’s the way we engage in a
							professional sense. Our education and work environments can be great
							spaces for us to exercise our character strengths in our interactions with
							colleagues and in our pursuits for growth, progress, and achievement.
						</div>
					</td>
				</tr>
				<tr>
					<td width="240px" class="cell padded-right padded-bottom-small">
						<img width="100%" height="auto" src="/assets/images/social-ring.png">
					</td>
					<td width="auto" class="cell vertical-align-middle  padded-bottom-small">
						<div class="social-color ca-row-heading font-family-raleway ">Social</div>
						<div class="ca-row-content">
							The middle ring of the Wheel represents the Social context; our relationship
							with others and our social cause. Particularly, our interactions with those
							in our inner circle; friendships, family and our intimate partners. These
							relationships can be our best environment for both introspection and
							achievement.
						</div>
					</td>
				</tr>
				<tr>
					<td width="240px" class="cell padded-right padded-bottom-small">
						<img width="100%" height="auto" src="/assets/images/spiritual-ring.png">
					</td>
					<td width="auto" class="cell vertical-align-middle  padded-bottom-small">
						<div class="spiritual-color ca-row-heading font-family-raleway">Spiritual</div>
						<div class="ca-row-content">
							The inner circle of the Wheel represents the Spiritual context; related
							to our inner world, our beliefs and the spirit within. It’s at the core of our
							existence and linked strongly to our meaning. The spiritual context can
							take form in religion, spiritual exploration, dreams, and contemplation.
							Often, it’s a unique experience that is hard to express through words, though
							deeply significant and transformative.
						</div>
					</td>
				</tr>
				<tr>
					<td width="240px" class="cell padded-right">
						<img width="100%" height="auto" src="/assets/images/archetype-summary-wheel.png">
					</td>
					<td width="auto" class="cell vertical-align-middle">
						<div class="ca-row-content">
							<table class="ca-archetype-summary-table">
								<colgroup>
									<col span="1" style="width: 25%" />
									<col span="1" style="width: 25%" />
									<col span="1" style="width: 25%" />
									<col span="1" style="width: 25%" />
								</colgroup>
								<tr>
									<th class="archytype-heading border-right">Aspect</th>
									<th class="archytype-heading border-right">Qualities/
										Potential</th>
									<th class="archytype-heading border-right">Challenge</th>
									<th class="archytype-heading">Thriving</th>
								</tr>
								<tr>
									<th class="archetype-professional-bg border-right">
										<div class="arrow" style="width: 90px;
                                        right: calc(100% - 10px);">
											<div class="point"></div>
											<div class="line"></div>
										</div>
										Professional
									</th>
									<td class="archetype-professional-bg border-right">Resourceful</td>
									<td class="archetype-professional-bg border-right">Unresourceful</td>
									<td class="archetype-professional-bg">Inspiring</td>
								</tr>
								<tr>
									<th class="archetype-social-bg border-right">
										<div class="arrow" style="width: 92px;
                                        right: calc(100% - 10px);">
											<div class="point"></div>
											<div class="line"></div>
										</div>
										Social
									</th>
									<td class="archetype-social-bg border-right">Contributing</td>
									<td class="archetype-social-bg border-right">Conflicting</td>
									<td class="archetype-social-bg">Unifying</td>
								</tr>
								<tr>
									<th class="archetype-spiritual-bg border-right">
										<div class="arrow" style="width: 100px;
                                        right: calc(100% - 10px);">
											<div class="point"></div>
											<div class="line"></div>
										</div>
										Spiritual
									</th>
									<td class="archetype-spiritual-bg border-right">Light</td>
									<td class="archetype-spiritual-bg border-right">Shadow</td>
									<td class="archetype-spiritual-bg">Magic</td>
								</tr>
							</table>
							The table shows the 3 aspects of character in relation to the different
							expressions, coloured for each layer corresponding to the Archetype
							Summary Wheel.
						</div>
					</td>
				</tr>
			</table>
		</div>
		<app-page-footer [pageNo]="'CSG'|pageNumber:pageNoOffsetForComparison"></app-page-footer>
	</div>
</section>
