import { AuthService } from "./../services/auth.service";
import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
	UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { OrganisationService } from "../services/organisation.service";

@Injectable({
	providedIn: "root"
})
export class AuthGuard implements CanActivate, CanLoad {
	constructor(
		private authService: AuthService,
		private router: Router,
		private organisationService: OrganisationService
	) {}
	canActivate(
		activatedRouteSnapshot: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return new Promise(async resolve => {
			let isLoggedIn = this.isLoggedIn();
			if (!isLoggedIn) {
				resolve(false);
				return;
			}
			let user = this.authService.$user.getValue();
			if (user?.role === "ADMIN") {
				resolve(true);
				return;
			}
			const roleLevel = activatedRouteSnapshot.data.roleLevel;
			if (roleLevel) {
				let orgId = activatedRouteSnapshot.params.orgId;
				let org = this.organisationService.selectedOrg.getValue();
				if (!org && orgId !== "all") {
					let orgAwait: any = await this.organisationService
						.getOrg(orgId)
						.toPromise();
					org = orgAwait.org;
				}
				if (org) {
					if (roleLevel < org.membership.roleLevel) {
						this.router.navigate(["home", org.id, "report"]);
					} else {
						resolve(true);
						return;
					}
				} else {
					this.router.navigate(["home", "all", "report"]);
				}
			}
			resolve(true);
			return;
		});
	}
	canLoad(
		route: Route,
		segments: UrlSegment[]
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.isLoggedIn();
	}
	isLoggedIn() {
		let isLoggedIn = this.authService.isLoggedIn();
		if (!isLoggedIn) {
			let url = this.router.getCurrentNavigation()?.extractedUrl;
			this.authService.redirectToLogin({
				queryParams: { returnUrl: url },
				skipLocationChange: url ? true : false
			});
			return false;
		}
		return true;
	}
}
