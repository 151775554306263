import * as QRCode from "qrcode";

export const generateQRForString = async (link: string, size: number = 128) => {
	try {
		return await QRCode.toDataURL(link, {
			errorCorrectionLevel: "H",
			maskPattern: 7,
			margin: 2,
			width: size,
			color: {
				dark: "#000000",
				light: "#ffffff"
			}
		});
	} catch (err) {
		throw err;
	}
};
