export function AutoUnsubscribeOnDestroy() {
	return function (constructor: any) {
		const orig = constructor.prototype.ngOnDestroy;
		constructor.prototype.ngOnDestroy = function () {
			if (!this.subscriptions.closed) {
				this.subscriptions.unsubscribe();
			}
			if (orig) orig.bind(this).apply();
		};
	};
}
