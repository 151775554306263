import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
	selector: "app-link-account",
	templateUrl: "./link-account.component.html",
	styleUrls: ["./link-account.component.scss"]
})
export class LinkAccountComponent {
	constructor(
		private dialogRef: MatDialogRef<LinkAccountComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private accountService: AccountService,
		public authService: AuthService,
		private _snackBar: MatSnackBar
	) {}

	signedInWithGoogle(token: string) {
		this.accountService.linkWithGoogle({ token }).subscribe((res: any) => {
			this._snackBar.open(`Google Account is Linked Successfully`);
			this.updateUserInfoAndClose(res.user);
		});
	}
	delinkGoogle() {
		this.accountService.delinkWithGoogle().subscribe((res: any) => {
			this._snackBar.open(`Google Account removed Successfully`);
			this.updateUserInfoAndClose(res.user);
		});
	}
	signedInWithMicrosoft(data: any) {
		this.accountService
			.linkWithMicrosoft({ token: data.idToken })
			.subscribe((res: any) => {
				this._snackBar.open(`Microsoft Account is Linked Successfully`);
				this.updateUserInfoAndClose(res.user);
			});
	}
	delinkMicrosoft() {
		this.accountService.delinkWithMicrosoft().subscribe((res: any) => {
			this._snackBar.open(`Microsoft Account removed Successfully`);
			this.updateUserInfoAndClose(res.user);
		});
	}
	updateUserInfoAndClose(user: any) {
		this.authService.setUserInfo(user);
		this.dialogRef.close();
	}
}
