import {
	singleComparisonColor,
	dualComparisonColor2,
	getName,
	orangeColor,
	purpleColor,
	titleGraphicBGOrange,
	titleGraphicBGPurple
} from "../../../utils/chart-options";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { EChartsOption } from "echarts";
import { TooltipOption } from "echarts/types/dist/shared";
import { cloneDeep } from "lodash";
import { suffix, truncate } from "src/app/_shared/utils/string";
import { getValues, prepareDataValues } from "../character";
import { initOpts, loadingOpts } from "../../../utils/chart-options";
import {
	CHART_DEFAULTS_BAR_A,
	CHART_DEFAULTS_BAR_B,
	// LEGEND_DEFAULTS_BAR_A,
	SERIE_DEFAULTS_ASPIRATIONS_BAR_A,
	SERIE_DEFAULTS_ASPIRATIONS_BAR_B,
	SERIE_DEFAULTS_ASPIRATION_BAR_A,
	SERIE_DEFAULTS_ASPIRATION_BAR_B
} from "./chart-defaults";

@Component({
	selector: "app-cs-alignment-by-group",
	templateUrl: "./cs-alignment-by-group.component.html",
	styleUrls: ["./cs-alignment-by-group.component.scss"]
})
export class CSAlignmentByGroupComponent implements OnChanges, OnInit {
	@Input() group: any;
	@Input() role: any;
	@Input() compReport: any;
	@Input() compReport2: any;
	@Input() compGroupReport: any;
	@Input() compGroupReport2: any;
	@Input() report: any;
	@Input() isLoading: boolean = true;
	@Input() showTooltip: boolean = true;
	@Input() isSample: boolean = false;
	@Input() isDualComparison?: boolean;

	initOpts = initOpts;
	loadingOpts = loadingOpts;
	default_A: EChartsOption = CHART_DEFAULTS_BAR_A;
	default_B: EChartsOption = CHART_DEFAULTS_BAR_B;

	chartOptionMoral!: EChartsOption;
	chartOptionPerformance!: EChartsOption;
	chartOptionMoralVsPerformance!: EChartsOption;

	constructor() {}
	ngOnInit() {
		(this.default_A.tooltip! as TooltipOption).show = this.showTooltip;
		(this.default_B.tooltip! as TooltipOption).show = this.showTooltip;
	}
	ngOnChanges() {
		this.initMoralVsPerformanceData();
		this.initMoralData();
		this.initPerformanceData();
	}
	initMoralData() {
		let data = this.report.characterStrengthsProfile.MORAL;
		let temp: any = {
			graphic: titleGraphicBGPurple,
			title: {
				text: "Moral"
			},
			yAxis: [
				{
					data: getValues("LABELS", "MORAL", "INDIVIDUAL").map(
						(value: string) => suffix(value, "(M)")
					)
				},
				{
					data: getValues("LABELS", "MORAL", "INDIVIDUAL").map(
						(value: string) => suffix(value, "(M)")
					)
				}
			],
			series: [
				{
					data: prepareDataValues(data, "MORAL", "COMBINED"),
					color: purpleColor
				}
			]
		};
		if (this.isDualComparison) {
			temp.series[0].name = getName(this.report) + " Score";
			// temp.legend = LEGEND_DEFAULTS_BAR_A;

			if (this.group) {
				let groupMoral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(this.group.name, { length: 10 })
				};
				groupMoral.data = prepareDataValues(
					this.group.MORAL,
					"MORAL",
					"INDIVIDUAL"
				);
				temp.series.push(groupMoral);
			} else if (this.compReport) {
				let compReportMoral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(this.compReport.name, { length: 10 })
				};
				compReportMoral.data = prepareDataValues(
					this.compReport.characterStrengthsProfile.MORAL,
					"MORAL",
					"COMBINED"
				);
				temp.series.push(compReportMoral);
			} else if (this.compGroupReport) {
				let compGroupReportMoral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(
						this.compGroupReport.name ||
							this.compGroupReport.reportNames.join(", "),
						{ length: 10 }
					)
				};
				compGroupReportMoral.data = prepareDataValues(
					this.compGroupReport.characterStrengthsProfile.MORAL,
					"MORAL",
					"COMBINED"
				);
				compGroupReportMoral.name = truncate(
					this.compGroupReport.name ||
						this.compGroupReport.reportNames.join(", "),
					{
						length: 10
					}
				);
				temp.series.push(compGroupReportMoral);
			}
			if (this.compReport2) {
				let compReport2Moral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(this.compReport2.name, { length: 10 })
				};
				compReport2Moral.data = prepareDataValues(
					this.compReport2.characterStrengthsProfile.MORAL,
					"MORAL",
					"COMBINED"
				);
				compReport2Moral.itemStyle.borderColor = dualComparisonColor2;
				temp.series.push(compReport2Moral);
			} else if (this.role) {
				let roleMoral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(this.role.name, { length: 10 })
				};
				roleMoral.itemStyle.borderColor = dualComparisonColor2;
				roleMoral.data = prepareDataValues(
					this.role.MORAL,
					"MORAL",
					"INDIVIDUAL"
				);
				temp.series.push(roleMoral);
			} else if (this.compGroupReport2) {
				let compGroupReport2Moral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(
						this.compGroupReport2.name ||
							this.compGroupReport2.reportNames.join(", "),
						{ length: 10 }
					)
				};
				compGroupReport2Moral.data = prepareDataValues(
					this.compGroupReport2.characterStrengthsProfile.MORAL,
					"MORAL",
					"COMBINED"
				);
				compGroupReport2Moral.itemStyle.borderColor = dualComparisonColor2;
				temp.series.push(compGroupReport2Moral);
			}
		} else if (this.group) {
			// temp.legend = LEGEND_DEFAULTS_BAR_A;
			temp.series[0].name = getName(this.report) + " Score";

			let groupMoral = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_A)
			};
			groupMoral.data = prepareDataValues(
				this.group.MORAL,
				"MORAL",
				"INDIVIDUAL"
			);
			temp.series.push(groupMoral);
		} else if (this.compReport) {
			temp.series[0].name = getName(this.report) + " Score";
			// temp.legend = LEGEND_DEFAULTS_BAR_A;

			let compReportMoral = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_A),
				name: truncate(this.compReport.name)
			};
			compReportMoral.data = prepareDataValues(
				this.compReport.characterStrengthsProfile.MORAL,
				"MORAL",
				"COMBINED"
			);
			temp.series.push(compReportMoral);
		} else if (this.compGroupReport) {
			temp.series[0].name = getName(this.report) + " Score";
			// temp.legend = LEGEND_DEFAULTS_BAR_A;

			let compGroupReportMoral = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_A),
				name: truncate(
					this.compGroupReport.name ||
						this.compGroupReport.reportNames.join(", ")
				)
			};
			compGroupReportMoral.data = prepareDataValues(
				this.compGroupReport.characterStrengthsProfile.MORAL,
				"MORAL",
				"COMBINED"
			);
			temp.series.push(compGroupReportMoral);
		}
		this.chartOptionMoral = temp;
	}
	initPerformanceData() {
		let data = this.report.characterStrengthsProfile.PERFORMANCE;
		let temp: any = {
			graphic: titleGraphicBGOrange,

			title: {
				text: "Performance"
			},
			yAxis: [
				{
					data: getValues("LABELS", "PERFORMANCE", "INDIVIDUAL").map(
						(value: string) => suffix(value, "(P)")
					)
				},
				{
					data: getValues("LABELS", "PERFORMANCE", "INDIVIDUAL").map(
						(value: string) => suffix(value, "(P)")
					)
				}
			],
			series: [
				{
					data: prepareDataValues(data, "PERFORMANCE", "COMBINED"),
					color: orangeColor
				}
			]
		};
		if (this.isDualComparison) {
			temp.series[0].name = getName(this.report) + " Score";
			// temp.legend = LEGEND_DEFAULTS_BAR_A;

			if (this.group) {
				let groupPerformance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(this.group.name, { length: 10 })
				};
				groupPerformance.data = prepareDataValues(
					this.group.PERFORMANCE,
					"PERFORMANCE",
					"INDIVIDUAL"
				);
				temp.series.push(groupPerformance);
			} else if (this.compReport) {
				let compReportPerformance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(this.compReport.name, { length: 10 })
				};
				compReportPerformance.data = prepareDataValues(
					this.compReport.characterStrengthsProfile.PERFORMANCE,
					"PERFORMANCE",
					"COMBINED"
				);
				temp.series.push(compReportPerformance);
			} else if (this.compGroupReport) {
				let compGroupReportPerformance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(
						this.compGroupReport.name ||
							this.compGroupReport.reportNames.join(", "),
						{ length: 10 }
					)
				};
				compGroupReportPerformance.data = prepareDataValues(
					this.compGroupReport.characterStrengthsProfile.PERFORMANCE,
					"PERFORMANCE",
					"COMBINED"
				);
				temp.series.push(compGroupReportPerformance);
			}
			if (this.compReport2) {
				let compReport2Performance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(this.compReport2.name, { length: 10 })
				};
				compReport2Performance.data = prepareDataValues(
					this.compReport2.characterStrengthsProfile.PERFORMANCE,
					"PERFORMANCE",
					"COMBINED"
				);
				compReport2Performance.itemStyle.borderColor = dualComparisonColor2;
				temp.series.push(compReport2Performance);
			} else if (this.role) {
				let rolePerformance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(this.role.name, { length: 10 })
				};
				rolePerformance.data = prepareDataValues(
					this.role.PERFORMANCE,
					"PERFORMANCE",
					"INDIVIDUAL"
				);
				rolePerformance.itemStyle.borderColor = dualComparisonColor2;
				temp.series.push(rolePerformance);
			} else if (this.compGroupReport2) {
				let compGroupReport2Performance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_A),
					name: truncate(
						this.compGroupReport2.name ||
							this.compGroupReport2.reportNames.join(", "),
						{ length: 10 }
					)
				};
				compGroupReport2Performance.data = prepareDataValues(
					this.compGroupReport2.characterStrengthsProfile.PERFORMANCE,
					"PERFORMANCE",
					"COMBINED"
				);
				compGroupReport2Performance.itemStyle.borderColor =
					dualComparisonColor2;
				temp.series.push(compGroupReport2Performance);
			}
		} else if (this.group) {
			// temp.legend = LEGEND_DEFAULTS_BAR_A;
			temp.series[0].name = getName(this.report) + " Score";

			let groupPerformance = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_A)
			};
			groupPerformance.data = prepareDataValues(
				this.group.PERFORMANCE,
				"PERFORMANCE",
				"INDIVIDUAL"
			);
			temp.series.push(groupPerformance);
		} else if (this.compReport) {
			temp.series[0].name = getName(this.report) + " Score";
			// temp.legend = LEGEND_DEFAULTS_BAR_A;

			let compReportPerformance = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_A),
				name: truncate(this.compReport.name)
			};
			compReportPerformance.data = prepareDataValues(
				this.compReport.characterStrengthsProfile.PERFORMANCE,
				"PERFORMANCE",
				"COMBINED"
			);
			temp.series.push(compReportPerformance);
		} else if (this.compGroupReport) {
			temp.series[0].name = getName(this.report) + " Score";
			// temp.legend = LEGEND_DEFAULTS_BAR_A;

			let compGroupReportPerformance = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_A),
				name: truncate(
					this.compGroupReport.name ||
						this.compGroupReport.reportNames.join(", ")
				)
			};
			compGroupReportPerformance.data = prepareDataValues(
				this.compGroupReport.characterStrengthsProfile.PERFORMANCE,
				"PERFORMANCE",
				"COMBINED"
			);
			temp.series.push(compGroupReportPerformance);
		}
		this.chartOptionPerformance = temp;
	}
	initMoralVsPerformanceData() {
		let { MORAL, PERFORMANCE } =
			this.report.characterStrengthsProfile.GROUP_TOTAL;
		let temp: any = {
			series: [
				{
					name: "Moral Actual",
					data: [MORAL, null]
				},
				{
					name: "Performance Actual",
					data: [null, PERFORMANCE]
				}
			]
		};
		if (this.isDualComparison) {
			temp.series[0].name = `${getName(this.report)} Moral`;
			temp.series[1].name = `${getName(this.report)} Performance`;
			if (this.group) {
				let groupData = this.group.GROUP_TOTAL;
				let groupMoralSerie = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					name: `${truncate(this.group.name, { length: 20 })} Target`,
					yAxisIndex: 2,
					data: [groupData.MORAL, null]
				};
				groupMoralSerie.itemStyle.borderColor = singleComparisonColor;
				temp.series.push(groupMoralSerie);

				let groupPerformanceSerie = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					xAxisIndex: 1,
					yAxisIndex: 3,
					name: `${truncate(this.group.name, { length: 20 })} Target`,
					data: [null, groupData.PERFORMANCE]
				};
				groupPerformanceSerie.itemStyle.borderColor = singleComparisonColor;
				temp.series.push(groupPerformanceSerie);
			} else if (this.compReport) {
				let compReportData =
					this.compReport.characterStrengthsProfile.GROUP_TOTAL;
				let compReportMoral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					yAxisIndex: 2,
					name: truncate(this.compReport.name, { length: 20 }),
					data: [compReportData.MORAL, null]
				};
				compReportMoral.itemStyle.borderColor = singleComparisonColor;
				temp.series.push(compReportMoral);

				let compReportPerformance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					xAxisIndex: 1,
					yAxisIndex: 3,
					name: truncate(this.compReport.name, { length: 20 }),
					data: [null, compReportData.PERFORMANCE]
				};
				compReportPerformance.itemStyle.borderColor = singleComparisonColor;
				temp.series.push(compReportPerformance);
			} else if (this.compGroupReport) {
				let compGroupReportData =
					this.compGroupReport.characterStrengthsProfile.GROUP_TOTAL;
				let compGroupReportMoral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					yAxisIndex: 2,
					name: truncate(
						this.compGroupReport.name ||
							this.compGroupReport.reportNames.join(", "),
						{ length: 20 }
					),
					data: [compGroupReportData.MORAL, null]
				};
				compGroupReportMoral.itemStyle.borderColor = singleComparisonColor;
				temp.series.push(compGroupReportMoral);

				let compGroupReportPerformance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					xAxisIndex: 1,
					yAxisIndex: 3,
					name: truncate(
						this.compGroupReport.name ||
							this.compGroupReport.reportNames.join(", "),
						{ length: 20 }
					),
					data: [null, compGroupReportData.PERFORMANCE]
				};
				compGroupReportPerformance.itemStyle.borderColor =
					singleComparisonColor;
				temp.series.push(compGroupReportPerformance);
			}

			if (this.compReport2) {
				let compReport2Data =
					this.compReport2.characterStrengthsProfile.GROUP_TOTAL;
				let compReport2Moral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					name: truncate(this.compReport2.name, { length: 20 }),
					yAxisIndex: 2,
					data: [compReport2Data.MORAL, null]
				};
				compReport2Moral.itemStyle.borderColor = dualComparisonColor2;
				temp.series.push(compReport2Moral);

				let compReport2Performance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					xAxisIndex: 1,
					yAxisIndex: 3,
					name: truncate(this.compReport2.name, { length: 20 }),
					data: [null, compReport2Data.PERFORMANCE]
				};
				compReport2Performance.itemStyle.borderColor = dualComparisonColor2;
				temp.series.push(compReport2Performance);
			} else if (this.role) {
				let roleData = this.role.GROUP_TOTAL;
				let roleMoralSerie = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					name: `${truncate(this.role.name, { length: 20 })} Target`,
					yAxisIndex: 2,
					data: [roleData.MORAL, null]
				};
				roleMoralSerie.itemStyle.borderColor = dualComparisonColor2;
				temp.series.push(roleMoralSerie);

				let rolePerformanceSerie = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					xAxisIndex: 1,
					yAxisIndex: 3,
					name: `${truncate(this.role.name, { length: 20 })} Target`,
					data: [null, roleData.PERFORMANCE]
				};
				rolePerformanceSerie.itemStyle.borderColor = dualComparisonColor2;
				temp.series.push(rolePerformanceSerie);
			} else if (this.compGroupReport2) {
				let compGroupReport2Data =
					this.compGroupReport2.characterStrengthsProfile.GROUP_TOTAL;
				let compGroupReport2Moral = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					name: truncate(
						this.compGroupReport2.name ||
							this.compGroupReport2.reportNames.join(","),
						{ length: 20 }
					),
					yAxisIndex: 2,
					data: [compGroupReport2Data.MORAL, null]
				};
				compGroupReport2Moral.itemStyle.borderColor = dualComparisonColor2;
				temp.series.push(compGroupReport2Moral);

				let compGroupReport2Performance = {
					...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS_BAR_B),
					xAxisIndex: 1,
					yAxisIndex: 3,
					name: truncate(
						this.compGroupReport2.name ||
							this.compGroupReport2.reportNames.join(","),
						{ length: 20 }
					),
					data: [null, compGroupReport2Data.PERFORMANCE]
				};
				compGroupReport2Performance.itemStyle.borderColor =
					dualComparisonColor2;
				temp.series.push(compGroupReport2Performance);
			}
		} else if (this.compReport) {
			let { MORAL, PERFORMANCE } =
				this.compReport.characterStrengthsProfile.GROUP_TOTAL;
			let moral = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_B),
				name: `${truncate(this.compReport.name, { length: 8 })}'s Moral`,
				data: [MORAL, null]
			};

			moral.itemStyle.borderColor = singleComparisonColor;
			let performance = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_B),
				xAxisIndex: 1,
				yAxisIndex: 1,
				name: `${truncate(this.compReport.name, { length: 8 })}'s Performance`,
				data: [null, PERFORMANCE]
			};
			performance.itemStyle.borderColor = "#FFC000";
			temp.series[0].name = `${getName(this.report)} Moral`;
			temp.series[1].name = `${getName(this.report)} Performance`;
			temp.series = [...temp.series, moral, performance];
		} else if (this.group) {
			let { MORAL, PERFORMANCE } = this.group.GROUP_TOTAL;
			let moral = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_B),
				name: "Moral Target",
				data: [MORAL, null]
			};
			moral.itemStyle.borderColor = singleComparisonColor;
			let performance = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_B),
				xAxisIndex: 1,
				yAxisIndex: 1,
				name: "Performance Target",
				data: [null, PERFORMANCE]
			};
			performance.itemStyle.borderColor = "#FFC000";
			temp.series = [...temp.series, moral, performance];
		} else if (this.compGroupReport) {
			let { MORAL, PERFORMANCE } =
				this.compGroupReport.characterStrengthsProfile.GROUP_TOTAL;
			let moral = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_B),
				name: `${truncate(
					this.compGroupReport.name ||
						this.compGroupReport.reportNames.join(","),
					{ length: 8 }
				)}'s Moral`,
				data: [MORAL, null]
			};

			moral.itemStyle.borderColor = singleComparisonColor;
			let performance = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION_BAR_B),
				xAxisIndex: 1,
				yAxisIndex: 1,
				name: `${truncate(
					this.compGroupReport.name ||
						this.compGroupReport.reportNames.join(","),
					{
						length: 8
					}
				)}'s Performance`,
				data: [null, PERFORMANCE]
			};
			performance.itemStyle.borderColor = "#FFC000";
			temp.series[0].name = `${getName(this.report)} Moral`;
			temp.series[1].name = `${getName(this.report)} Performance`;
			temp.series = [...temp.series, moral, performance];
		}

		this.chartOptionMoralVsPerformance = temp;
	}
}
