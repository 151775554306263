export const initOpts = {
	renderer: "svg"
	// width: "380px",
	// height: "400px"
};
export const loadingOpts = {
	text: "loading",
	color: "#c23531",
	textColor: "#000",
	maskColor: "rgba(255, 255, 255, 0.8)",
	zlevel: 0
};

export const textStyle = {
	fontFamily: "Century Gothic"
};
export const titleStyle = {
	top: 5,
	left: 10,
	textStyle: {
		color: "black",
		fontSize: "16px"
	}
};

export const gridStyle = {
	top: 40,
	left: 140,
	right: 15,
	bottom: 30,
	containLabel: false,
	backgroundColor: "white",
	show: true
};
interface IToTooltipHTML {
	marker?: string;
	name: string;
	values: { marker?: string; name: string; value: string | number }[];
}

export function toTooltipHTML(data: IToTooltipHTML) {
	let output = `${data?.marker || ""}<b>${data.name}</b><br/><div>`;
	for (let i = 0; i < data.values.length; i++) {
		output += `<div style="display: flex;justify-content: space-between;">
			<span>${data?.values[i]?.marker || ""}${
			data?.values[i]?.name
		}&nbsp;</span><span><b>
			${data?.values[i]?.value}</b></span></div>`;
	}
	return `${output}</div>`;
}
export function toFixedWithZeroTruncation(value: number, n: number) {
	return value.toFixed(n).replace(/[.]0+$/, "");
}
export function marker({
	color,
	borderColor
}: {
	color?: string;
	borderColor?: string;
}) {
	return `<span style="display:inline-block;margin-right:4px;width:10px;height:10px;background-color:${color};border: 1.5px solid ${borderColor}"></span>`;
}
export function tooltipPosition(
	pos: Array<number>,
	params: any,
	dom: HTMLElement,
	rect: any,
	size: any
) {
	var obj: any = { top: pos[1] - size.contentSize[1] / 2 };
	obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
	return obj;
}
export const purpleColor = "#614c77";
export const orangeColor = "#c65b1a";
export const greenColor = "#98c555";

export const titleGraphicBGGreen = {
	type: "rect",
	left: 0,
	top: 0,
	z: 1,
	shape: {
		width: 1000,
		height: 35
	},
	style: {
		fill: greenColor
	}
};
export const titleGraphicBGPurple = {
	type: "rect",
	left: 0,
	top: 0,
	z: 1,
	shape: {
		width: 1000,
		height: 35
	},
	style: {
		fill: purpleColor
	}
};
export const titleGraphicBGOrange = {
	type: "rect",
	left: 0,
	top: 0,
	z: 1,
	shape: {
		width: 1000,
		height: 35
	},
	style: {
		fill: orangeColor
	}
};
export const backgroundColor = {
	backgroundColor: "#f5f5f5"
};
export function getName(report: any) {
	switch (report.type) {
		case "INDIVIDUAL":
			return "Your";
		case "GROUP":
			return "Group";
	}
	return report.name;
}

export const singleComparisonColor = "#00B0F0";
export const dualComparisonColor1 = "#1c80ba";
export const dualComparisonColor2 = "#3d7c4f";
export const RichTextBold = {
	bold: {
		fontWeight: "bolder"
	}
};
