<h1 mat-dialog-title>Purchase Report</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<ng-container [ngSwitch]="status">
		<ng-container *ngSwitchCase="'PAYMENT'">
			<div fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start center">
				<div fxFlex="1 1 auto" fxFlexFill>
					<mat-stepper class="centered-stepper-header" linear labelPosition="bottom" #stepper>
						<mat-step [stepControl]="detailsFormGroup" [editable]="'false'">
							<ng-template matStepLabel>Fill out Details</ng-template>
							<form [formGroup]="detailsFormGroup">
								<mat-radio-group class="ca-radio-group" fxLayout="column" fxLayoutAlign="start start"
									formControlName="product" color="primary" aria-label="Select a report option" fxLayoutGap="8px">
									<ng-container *ngIf="data?.report?.character360?.totalResponsesCount<6">
										<mat-radio-button value="CHARAMETRICS_REPORT">
											<span class="ca-currency">A$</span>{{125|number: '1.2-2'}} - Charametrics &
											Character 360 Report
										</mat-radio-button>
										<mat-radio-button value="CHARAMETRICS_REPORT_WITH_UNPACK">
											<span class="ca-currency">A$</span>{{299|number:
											'1.2-2'}} - Charametrics & Character 360 Report with Unpack Session
										</mat-radio-button>
									</ng-container>
									<ng-container *ngIf="data?.report?.character360?.totalResponsesCount>=6">
										<mat-radio-button value="CHARAMETRICS_REPORT_50PER_OFF">
											<span class="ca-currency">A$</span>{{62|number: '1.2-2'}} - 50% off Charametrics &
											Character 360 Report
										</mat-radio-button>
										<mat-radio-button value="CHARAMETRICS_REPORT_50PER_OFF_WITH_UNPACK">
											<span class="ca-currency">A$</span>{{237|number:
											'1.2-2'}} - 50% off Charametrics & Character 360 Report with Unpack Session
										</mat-radio-button>
									</ng-container>
								</mat-radio-group>
								<mat-form-field fxFlexFill>
									<input matInput placeholder="Email Id" type="email" formControlName="email" />
									<mat-error
										*ngIf="detailsFormGroup.get('email')?.touched && detailsFormGroup.get('email')?.errors?.required">
										Please type in Email Id.
									</mat-error>
									<mat-error
										*ngIf="detailsFormGroup.get('email')?.touched && detailsFormGroup.get('email')?.errors?.email">
										Please Enter a Valid Email Id
									</mat-error>
								</mat-form-field>
								<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start" style="margin-top: 12px;">
									<app-country fxFlex="1 1 100%" fxFlexFill [fg]="detailsFormGroup">
									</app-country>
								</div>

								<div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
									<button mat-raised-button color="primary" (click)="createPaymentIntent()">Continue</button>
								</div>
							</form>
						</mat-step>
						<mat-step [editable]="'false'">
							<ng-template matStepLabel>Payment</ng-template>
							<ng-container *ngIf="elementsOptions.clientSecret">
								<h2 style="text-align:center ;">
									Paying <b>A${{breakdowns?.totalAmount|number: '1.0-2'}}</b>
									<mat-icon color="primary" class="ca-along-text-icon" aria-label="info">
										info_outline</mat-icon>
								</h2>
								<ngx-stripe-payment [elementsOptions]="elementsOptions">
								</ngx-stripe-payment>
							</ng-container>
							<div class="stepper-actions" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
								<button mat-raised-button color="primary" type="button" (click)="pay()">PAY</button>
							</div>
						</mat-step>
					</mat-stepper>
				</div>
				<div *ngIf="breakdowns" fxFlex="1 1 auto">
					<mat-card>
						<mat-icon style="position: absolute;top:3px;left:3px" color="primary">info_outline
						</mat-icon>
						<mat-card-content>
							<div class="ca-amount-breakdowns">
								<table>
									<tr>
										<th>Amount</th>
										<td>&nbsp;:&nbsp;</td>
										<td><span class="ca-currency">A$</span>{{breakdowns?.amount |number: '1.2-2'}}
										</td>
									</tr>
									<tr *ngIf="detailsFormGroup?.value.countryCode==='AU'">
										<th>GST (10% for Australia)</th>
										<td>&nbsp;:&nbsp;</td>
										<td><span class="ca-currency">A$</span>{{breakdowns?.GST |number:
											'1.2-2'}}</td>
									</tr>
									<tr>
										<th>Total</th>
										<td>&nbsp;:&nbsp;</td>
										<th><span class="ca-currency">A$</span>{{breakdowns?.totalAmount
											|number: '1.2-2'}}</th>
									</tr>
								</table>
							</div>
						</mat-card-content>
					</mat-card>
				</div>
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="'SUCCESS'">
			<mat-card>
				<mat-card-content>

					<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
						<mat-icon fxFlexOffset="75px" class="lg-icon success-icon">
							check_circle
						</mat-icon>
						<h1>
							Success!
						</h1>
						<h2>
							Your Purchase is Completed.
							Your report will also be sent to the provided Email address.
						</h2>
					</div>
				</mat-card-content>
				<mat-card-actions align="end">
					<button mat-raised-button color="accent" (click)="close()">View Report</button>
				</mat-card-actions>
			</mat-card>
		</ng-container>
		<ng-container *ngSwitchCase="'FAILED'">
			<mat-card>
				<mat-card-content>
					<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
						<mat-icon fxFlexOffset="75px" class="lg-icon failed-icon">
							error
						</mat-icon>
						<h1>
							Failed!
						</h1>
						<h3>{{error.message}}</h3>
						<h5>
							Please try again.
						</h5>
					</div>
				</mat-card-content>
				<mat-card-actions align="end">
					<button mat-raised-button color="accent" (click)="reset()">Try Again</button>
				</mat-card-actions>
			</mat-card>
		</ng-container>
	</ng-container>
</mat-dialog-content>