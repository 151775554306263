<h1 mat-dialog-title>Create Dependent Groups</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<mat-form-field class="full-width">
		<mat-label>Type in Dependents name</mat-label>
		<mat-chip-list #chipList aria-label="Enter Dependent Names">
			<mat-chip *ngFor="let dependent of dependents;let i=index" [selectable]="false" [removable]="true"
				(removed)="remove(i)">
				<div fxLayout="row" fxLayoutAlign="center center" class="ca-chip-profile-pic-container">
					<span class="ca-profile-pic" fxLayout="row" fxLayoutAlign="center center">
						<ng-container *ngIf="files[i]?.src; else profileElseTemplate">
							<img width="100%" [src]="files[i]?.src">
						</ng-container>
						<ng-template #profileElseTemplate>
							<img width="100%" src="/assets/step_group.svg">
						</ng-template>
						<input #file id="profilePic" type="file" (change)="onProfileUpload($event,i)">
					</span>
					<button class="ca-remove" *ngIf="files[i]?.src" (click)="removeProfilePic(i)"
						type="button" mat-icon-button>
						<mat-icon>close</mat-icon>
					</button>
				</div>
				{{dependent.groupName}}
				<button matChipRemove>
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip>
			<input placeholder="New Dependent Name(s)..." [matChipInputFor]="chipList"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
				(matChipInputTokenEnd)="add($event)">
		</mat-chip-list>
		<mat-hint>
			To Enter multiple names, Paste names separated by <b>,</b> (comma) and press Enter
		</mat-hint>
	</mat-form-field>

</mat-dialog-content>
<mat-dialog-actions>
	<button type="button" mat-button [mat-dialog-close]>Cancel</button>
	<span class="ca-spacer"></span>
	<button type="button" [disabled]="dependents.length<1" mat-raised-button color="primary" (click)="complete()">
		Complete
	</button>
</mat-dialog-actions>
