<h2 mat-dialog-title>Crop</h2>
<mat-dialog-content>
    <image-cropper class="cropper" [onlyScaleDown]="true" [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true" [aspectRatio]="aspectRatio" [resizeToWidth]="resizeToWidth"
        [resizeToHeight]="resizeToHeight" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
    </image-cropper>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="save()">Okay</button>
</mat-dialog-actions>