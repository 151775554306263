import { Component } from "@angular/core";
import { Input } from "@angular/core";

@Component({
	selector: "app-page-title",
	templateUrl: "./page-title.component.html",
	styleUrls: ["./page-title.component.scss"]
})
export class PageTitleComponent {
	constructor() {}
	@Input() title!: string;
}
