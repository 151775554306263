import { Component, Input } from "@angular/core";
import { ORG } from "../../types/organisation";
import { STEP_GROUP } from "../../types/step-group";
import { USER } from "../../types/user";

@Component({
	selector: "app-avatars",
	templateUrl: "./avatars.component.html",
	styleUrls: ["./avatars.component.scss"]
})
export class AvatarsComponent {
	@Input() avatars!: Array<USER | STEP_GROUP | ORG | any>;
	@Input() size = "32px";
	@Input() n = 3;
	@Input() total = 0;
	constructor() {}
	get firstN() {
		return this.avatars.slice(0, this.n);
	}
	get restCount() {
		let rest = this.total > this.n ? this.total - this.n : 0;
		if (rest == 0) return null;
		return rest < 9 ? `+${rest}` : "9+";
	}
}
