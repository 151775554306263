<h1 mat-dialog-title>
	Login with Mobile
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<ng-container [ngSwitch]="status">
	<ng-container *ngSwitchCase="'SEND_OTP'">
		<form fxLayout="column" [formGroup]="sendOTPFG" (ngSubmit)="sendOTP()">
			<mat-dialog-content>
				<app-country [fg]="sendOTPFG">
				</app-country>
				<div fxLayout="column" fxLayoutGap="10px">
					<div fxLayout="row" fxLayoutAlign="start center">
						<div fxFlex="0 0 50px" fxFlexFill style="margin:0 20px">
							{{dialCode}}
						</div>
						<div class="step-form-group" fxFlex="1 1 0" fxFlexFill>
							<input type="text" formControlName="phoneNumber" class="form-control"
								[ngClass]="{ 'is-invalid': submitted && sendOTPFG?.get('phoneNumber')?.errors }"
								placeholder="Phone Number" />
							<div *ngIf="submitted && sendOTPFG?.get('phoneNumber')?.errors" class="invalid-feedback">
								<ng-container *ngIf="sendOTPFG?.get('phoneNumber')?.errors?.required">
									<mat-icon>error</mat-icon> Please type in PhoneNumber.
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</mat-dialog-content>
			<mat-dialog-actions>
				<span class="ca-spacer"></span>
				<button type="button" mat-button [mat-dialog-close]>Cancel</button>
				<button [disabled]="contactNo.invalid" mat-raised-button color="primary">
					<span style="font-size: 16px;font-weight: bold;">Submit</span>
				</button>
			</mat-dialog-actions>
		</form>
	</ng-container>
	<ng-container *ngSwitchCase="'VERIFY_OTP'">
		<form fxLayout="column" [formGroup]="verfiyOTPFG" (ngSubmit)="verifyOTP()">
			<mat-dialog-content>

				<div fxLayout="column" fxLayoutGap="10px">
					<div class="step-form-group" fxFlex="1 1 0" fxFlexFill>
						<input type="text" formControlName="code" class="form-control"
							[ngClass]="{ 'is-invalid': submitted && verfiyOTPFG?.get('code')?.errors }" placeholder="OTP" />
						<div *ngIf="submitted && verfiyOTPFG?.get('code')?.errors" class="invalid-feedback">
							<ng-container *ngIf="verfiyOTPFG?.get('code')?.errors?.required">
								<mat-icon>error</mat-icon> Please type in code.
							</ng-container>
						</div>
					</div>
				</div>
			</mat-dialog-content>
			<mat-dialog-actions>
				<button [disabled]="(((secondsRemaining$|async) || -1)>0)" type="button" mat-stroked-button
					(click)="sendOTP(true)">Resend OTP
					<ng-container *ngIf="(((secondsRemaining$|async) || -1)>0)">
						({{secondsRemaining$| async}}s)
					</ng-container>
				</button>
				<span class="ca-spacer"></span>
				<button type="button" mat-button [mat-dialog-close]>Cancel</button>
				<button [disabled]="contactNo.invalid" mat-raised-button color="primary">
					<span style="font-size: 16px;font-weight: bold;">Verify</span>
				</button>
			</mat-dialog-actions>
		</form>
	</ng-container>


</ng-container>