import { SeriesOption } from "echarts";

export const SERIE_DEFAULTS_GROUP_COVERAGE: SeriesOption | any = {
	type: "radar",
	tooltip: {
		trigger: "item"
	},
	color: "transparent",
	itemStyle: {
		show: false,
		borderColor: "#FFBF00",
		color: "white"
	},
	z: 5,
	symbol: "none",
	lineStyle: {
		color: "#FFBF00",
		width: 2,
		shadowColor: "rgba(0, 0, 0, .7)",
		shadowBlur: 5,
		shadowOffsetX: 2,
		shadowOffsetY: 2
	},
	data: [
		{
			name: "Coverage"
		}
	]
};
