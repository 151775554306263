<section class="stepper-container">
	<header>
		<ng-container *ngFor="let step of steps; let i = index;let last=last">
			<button matRipple class="stepper-step" [class.stepper-active]="selectedIndex === i" (click)="onClick(i)">
				<div class="cdk-step-icon" [class.completed]="step.completed">
					<div class="cdk-step-icon-content">{{i+1}}
					</div>
				</div>
				<div>
					<ng-container *ngIf="step.stepLabel?.template;else textLabelTRef">
						<ng-container [ngTemplateOutlet]="step.stepLabel.template"></ng-container>
					</ng-container>
					<ng-template #textLabelTRef>
						<div>
							{{step.label}}
						</div>
					</ng-template>
					<div class="cdk-step-optional" *ngIf="step.optional">Optional</div>
				</div>
			</button>
			<div class="stepper-line" *ngIf="!last">
			</div>
		</ng-container>
	</header>
	<div [ngTemplateOutlet]="selected ? selected.content : null"></div>
</section>
