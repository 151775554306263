import { AccountService } from "./../../services/account.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from "@angular/material/snack-bar";
import { StepAccountService } from "./../../services/step-account.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";

@Component({
	selector: "app-forgot-password",
	templateUrl: "./forgot-password.component.html",
	styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent {
	userForm!: FormGroup;
	submitted = false;
	returnUrl: string = "/home/step/group";
	get fg() {
		return this.userForm;
	}
	constructor(
		public dialogRef: MatDialogRef<ForgotPasswordComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: { for: "STEP" | "STCA" },
		private stepAccountService: StepAccountService,
		private fb: FormBuilder,
		private _snackBar: MatSnackBar,
		private UiLoaderService: NgxUiLoaderService,
		private accountService: AccountService
	) {
		this.userForm = this.fb.group({
			email: [null, Validators.required]
		});
	}
	onSubmit() {
		if (this.userForm.valid) {
			switch (this.dialogData.for) {
				case "STEP":
					this.forgotPasswordSTEP();
					break;
				case "STCA":
					this.forgotPasswordSTCA();
					break;
			}
		}
	}
	forgotPasswordSTEP() {
		this.UiLoaderService.start();
		this.stepAccountService.forgotPassword(this.userForm.value).subscribe(
			(res: any) => {
				this.UiLoaderService.stop();
				this.dialogRef.close();
				this._snackBar.open(res.message);
			},
			error => {
				this.UiLoaderService.stop();
			}
		);
	}
	forgotPasswordSTCA() {
		this.UiLoaderService.start();
		this.accountService.forgotPassword(this.userForm.value).subscribe(
			(res: any) => {
				this.UiLoaderService.stop();
				this.dialogRef.close();
				this._snackBar.open(res.message);
			},
			error => {
				this.UiLoaderService.stop();
			}
		);
	}
}
