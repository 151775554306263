import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { interval, merge, of as observableOf } from "rxjs";
import {
	catchError,
	debounce,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
	tap
} from "rxjs/operators";
import { DialogsService } from "../../services/dialogs.service";
import { ORG } from "../../types/organisation";
import { STEP_GROUP } from "../../types/step-group";
import { StepGroupService } from "./../../services/step-group.service";

@Component({
	selector: "app-step-parent-group-select",
	templateUrl: "./step-parent-group-select.component.html",
	styleUrls: ["./step-parent-group-select.component.scss"]
})
export class StepParentGroupSelectComponent implements AfterViewInit {
	displayedColumns: string[] = [
		"groupRef",
		"groupName",
		"action",
		"organisation",
		"noOfUsers"
	];
	filterFormControl = new FormControl();
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@Output() selectParent: EventEmitter<STEP_GROUP> = new EventEmitter();
	@Input() selectedOrg?: ORG | null;
	refreshEvent = new EventEmitter();
	loaderId = "parent-selection";
	isLoadingResults = false;
	resultsLength = 0;
	data: any[] = [];
	pageSize = 10;
	orgFilterFormControl = new FormControl("ALL");
	constructor(
		private stepGroupService: StepGroupService,
		private UiLoaderService: NgxUiLoaderService,
		private dialogsService: DialogsService
	) {}
	ngAfterViewInit() {
		// If the user changes the sort order, reset back to the first page.
		this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		merge(
			this.sort?.sortChange,
			this.paginator?.page,
			this.filterFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				tap(() => {
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
				}),
				distinctUntilChanged()
			),
			this.orgFilterFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				tap(() => {
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
				}),
				distinctUntilChanged()
			),
			this.refreshEvent
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					setTimeout(() => {
						this.isLoadingResults = true;
					}, 0);
					return this.stepGroupService!.getParent({
						offset: this.paginator.pageIndex * this.paginator.pageSize,
						limit: this.paginator.pageSize,
						sortingKey: this.sort.active,
						sortBy: this.sort.direction,
						searchText: this.filterFormControl.value || undefined,
						orgId: this.selectedOrg?.id,
						groupFilter: this.orgFilterFormControl.value || undefined
					}).pipe(catchError(() => observableOf(null)));
				}),
				map((data: any) => {
					setTimeout(() => {
						this.isLoadingResults = false;
					}, 0);
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
					if (data === null) {
						return [];
					}
					this.resultsLength = data.total;
					return data.groups;
				})
			)
			.subscribe(data => (this.data = data));
	}
	select(row: any) {
		this.selectParent.emit(row);
	}
	openGroup(group?: STEP_GROUP) {
		let dialogRef = this.dialogsService.openGroup(
			group?.groupSubscription?.orgId as number,
			group
		);
		dialogRef.afterClosed().subscribe((result: any) => {
			if (result?.refresh) {
				this.refreshEvent?.emit();
			}
		});
	}
}
