import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EChartsOption } from "echarts";
import { isNaN } from "lodash";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DialogsService } from "../../services/dialogs.service";
import { Tree } from "../../utils/tree";
import { FileService } from "./../../services/file.service";
import { StepGroupService } from "./../../services/step-group.service";
import {
	CHART_DEFAULTS,
	OVERLAY_CHART_DEFAULTS,
	RICH_STYLE
} from "./chart-defaults";
import { StepAuthService } from "../../services/step-auth.service";
@Component({
	selector: "app-step-group-hierarchy",
	templateUrl: "./step-group-hierarchy.component.html",
	styleUrls: ["./step-group-hierarchy.component.scss"]
})
export class StepGroupHierarchyComponent implements OnInit {
	loaderId = "hierarchyLoader";
	chartInstance: any;
	backdropChartInstance: any;
	dataTree!: Tree | null;
	chartOption!: EChartsOption;
	backdropChartOption!: EChartsOption;
	parent?: any;
	childs: any[] = [];
	option: EChartsOption = CHART_DEFAULTS;
	backdropOption: EChartsOption = OVERLAY_CHART_DEFAULTS;
	refresh = false;
	constructor(
		private stepGroupService: StepGroupService,
		@Optional() public dialogRef: MatDialogRef<StepGroupHierarchyComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
		public fileService: FileService,
		private UiLoaderService: NgxUiLoaderService,
		private dialogsService: DialogsService,
		private stepAuthService: StepAuthService
	) {}
	ngOnInit() {
		if (this.dialogData?.subscription?.group?.id) {
			this.getGroupHierarchy(this.dialogData?.subscription?.group?.id);
		}
	}
	filePathGenerator(imageKey: string) {
		if (!imageKey) return "/assets/step_group.svg";
		let path = this.fileService.generateFilePathSTEP(imageKey);
		if (!path) return "/assets/step_group.svg";
		return path;
	}
	getGroupHierarchy(groupId: number) {
		this.UiLoaderService.startLoader(this.loaderId);
		this.stepGroupService
			.getSubscriptionGroupHierarchy({ groupId })
			.subscribe((res: any) => {
				this.parent = res.group;
				this.childs = res.dependentGroups;
				this.refreshTree();
				this.UiLoaderService.stopLoader(this.loaderId);
			});
	}
	refreshTree() {
		this.dataTree = new Tree(
			this.parent.id,
			this.parent,
			this.filePathGenerator.bind(this)
		);
		this.dataTree?.insertNodes(this.childs);
		this.setvalues();
	}
	onChartInit(e: any) {
		this.chartInstance = e;
		this.connect();
	}
	onBackdropChartInit(e: any) {
		this.backdropChartInstance = e;
		this.connect();
	}

	connect() {
		if (this.chartInstance && this.backdropChartInstance) {
			this.chartInstance.on("treeroam", (a: any) => {
				let option = this.chartInstance.getOption();
				let zoom = option.series[0].zoom;
				let center = option.series[0].center;
				if (!isNaN(center[0]) && !isNaN(center[1])) {
					this.backdropChartInstance.setOption({
						series: [
							{
								zoom,
								center
							}
						]
					});
				}
			});
		}
	}

	setvalues() {
		let imgs = this.dataTree?.getImages();
		this.chartOption = {
			series: [
				{
					data: [this.dataTree?.values],
					roam: this.childs.length > 1,
					label: {
						rich: {
							...RICH_STYLE,
							...imgs
						}
					}
				}
			]
		};
		this.backdropChartOption = {
			series: [
				{
					roam: this.childs.length > 1,
					data: [this.dataTree?.values]
				}
			]
		};
	}
	onContextMenu(event: any) {
		event.event.event.preventDefault();
	}
	async onChartClick(event: any) {
		if (!(await this.stepAuthService.loginIfNot())) return;

		let dialogRef = this.dialogsService.openGroup(
			this.dialogData.subscription.orgId,
			event.data
		);
		dialogRef.afterClosed().subscribe((result: any) => {
			if (result?.refresh) {
				this.refresh = true;
				this.getGroupHierarchy(this.dialogData?.subscription?.group?.id);
			}
		});
	}
}
