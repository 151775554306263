<h1 mat-dialog-title>
    Generate Group Charametrics
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
</button>
<app-loader [loaderId]="loaderId"></app-loader>
<ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="'GENERATE'">
        <form fxLayout="column" [formGroup]="fg" (ngSubmit)="onSubmit()">
            <mat-dialog-content>
                <div>
                    Selected User Reports :&nbsp;
                    <span *ngFor="let report of dialogData.reports;let last=last">
                        {{report.name+(last?'':"," )}}
                    </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                    <span>Please Select Organisation</span>
                    <app-organisation-select [showAll]="false" [global]="false" [selectedOrgId]="fg?.value?.orgId"
                        (selectedOrgChange)="updateOrg($event)" [showAvailableCredits]="true"
                        [showOrgSelectedLabel]="false" [showAvailableCreditsLabel]="false"
                        [error]="fg.get('orgId')?.touched && fg.get('orgId')?.errors?.required">
                    </app-organisation-select>
                </div>
                <ng-container *ngIf="fg.get('orgId')?.valid">
                    <div class="ca-amount-breakdowns">
                        <table>
                            <tr>
                                <th>Report Base Cost</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>{{COST_PER_ASSESSMENT|number: '1.2-2'}}</td>
                            </tr>
                            <tr>
                                <th>Partners Discount <ng-container
                                        *ngIf="org?.orgType">({{org?.orgType|orgTypeDiscount}})</ng-container>
                                </th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>{{COST_PER_ASSESSMENT -
                                    costPerAssignmentAfterDiscount
                                    |number: '1.2-2'}}</td>
                            </tr>
                            <tr>
                                <th>Total Owed</th>
                                <td>&nbsp;:&nbsp;</td>
                                <th>{{costPerAssignmentAfterDiscount
                                    |number: '1.2-2'}}</th>
                            </tr>
                        </table>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                        <span> This report will cost <b>{{costPerAssignmentAfterDiscount}}</b> credits.
                        </span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" style="margin-top: 8px;">
                        <mat-form-field fxFlexFill>
                            <mat-label>Please enter the name for Group Charametrics (optional)</mat-label>
                            <input matInput #input formControlName="name">
                        </mat-form-field>
                    </div>
                </ng-container>
            </mat-dialog-content>
            <mat-dialog-actions>
                <ng-container>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                        Would you like to use your credits to purchase this group report?
                    </div>
                    <span class="ca-spacer"></span>
                    <button type="button" mat-button [mat-dialog-close]>No</button>
                    <button mat-raised-button color="primary">
                        <span style="font-size: 16px;font-weight: bold;">Yes</span>
                    </button>
                </ng-container>
            </mat-dialog-actions>
        </form>

    </ng-container>
    <ng-container *ngSwitchCase="'GENERATED'">
        <mat-dialog-content>
            <mat-card>
                <mat-card-content>

                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
                            <div>
                                <ng-container *ngIf="groupReport.name">
                                    {{groupReport.name |firstCap}} -
                                </ng-container>
                                {{groupReport?.reportNames.join(', ')}}
                            </div>
                        </div>
                        <div>
                            <button matTooltip="Open In A New Tab" mat-icon-button color="primary" aria-label="link"
                                (click)="openInNewTab()">
                                <mat-icon>open_in_new</mat-icon>
                            </button>
                            <button matTooltip="Download" mat-icon-button class="mat-icon-loader-button" color="primary"
                                aria-label="link" (click)="download()">
                                <mat-icon>download</mat-icon>
                                <app-loader [loaderId]="'groupReport'" [fgsSize]=40></app-loader>
                            </button>
                            <button matTooltip="Open Single Comparison" mat-icon-button (click)="openSingleComparison()"
                                [color]="groupReport.selectedSingleComparisonGroupId?'primary':'accent'">
                                <mat-icon svgIcon="single_comparison" mat-list-icon></mat-icon>
                            </button>
                            <button matTooltip="Open Dual Comparison" mat-icon-button (click)="openDualComparison()"
                                [color]="groupReport.selectedDualComparisonGroupId?'primary':'accent'">
                                <mat-icon svgIcon="dual_comparison" mat-list-icon></mat-icon>
                            </button>
                        </div>
                    </div>
                    <small>
                        <mat-hint fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                            <div>generated by</div>
                            <app-avatar size="16px" *ngIf="groupReport?.transactions?.[0]?.by"
                                [avatar]="groupReport?.transactions?.[0]?.by">
                            </app-avatar>
                            <div>
                                at {{groupReport?.createdAt| date:'medium' }}
                            </div>
                        </mat-hint>
                    </small>

                </mat-card-content>
                <mat-card-actions>
                    <mat-hint *ngIf="isAlreadyGenerated">
                        You've previously generated a group report with the same users.
                    </mat-hint>
                </mat-card-actions>
            </mat-card>
        </mat-dialog-content>
    </ng-container>
</ng-container>