import { Component, Input, OnChanges } from "@angular/core";
import { EChartsOption } from "echarts";
import { cloneDeep, map } from "lodash";
import { LoggerService } from "src/app/_shared/services/logger.service";
import { maxLimit } from "src/app/_shared/utils/number";
import { CAT_TABLE } from "../../../types/archetypes";
import {
	CHARACTER_GROUP,
	WHOLE_CHARACTERS_KEYS,
	previewOffset
} from "../character";
import { initOpts, loadingOpts } from "../../../utils/chart-options";
import { CHART_DEFAULTS } from "./chart-defaults";
import { SERIE_DEFAULTS_GROUP_COVERAGE } from "src/app/_shared/const/chart";

type keys = "characterStrength" | "professional" | "social" | "spiritual";
@Component({
	selector: "app-archetype",
	templateUrl: "./archetype.component.html",
	styleUrls: ["./archetype.component.scss"]
})
export class ArchetypeComponent implements OnChanges {
	@Input() isLoading: boolean = true;
	@Input() pageNoOffsetForComparison: number = 0;
	@Input() report!: {
		type: string;
		characterStrengthsCoverage: any;
		characterArchetypes: {
			characterStrength: CHARACTER_GROUP;
			professional: string;
			social: string;
			spiritual: string;
		}[];
		characterStrengths: any;
		isPreview: Boolean;
	};
	initOpts = initOpts;
	loadingOpts = loadingOpts;
	default: EChartsOption = CHART_DEFAULTS;
	chartOptions!: EChartsOption;
	personas = ["VISIONARY", "ACTIVATOR", "GROUNDED", "INTEGRATOR"];
	personasCombo = [
		"VISIONARY_INTEGRATOR",
		"VISIONARY_ACTIVATOR",
		"GROUNDED_ACTIVATOR",
		"GROUNDED_INTEGRATOR"
	];
	rows: { name: string; key: keys }[] = [
		{ name: "Character Strengths", key: "characterStrength" },
		{ name: "Professional Archetype", key: "professional" },
		{ name: "Social Archetype", key: "social" },
		{ name: "Spiritual Archetype", key: "spiritual" }
	];

	characterStrengthsVsGSD!: any[];
	CAT_TABLE = CAT_TABLE;
	get previewOffset() {
		return previewOffset(<boolean>this.report.isPreview);
	}
	constructor(public Logger: LoggerService) {}
	ngOnChanges(): void {
		this.initChart();
	}
	initChart() {
		this.characterStrengthsVsGSD = [
			...WHOLE_CHARACTERS_KEYS.MORAL.map(
				key => this.report?.characterStrengths?.vsGSD?.MORAL[key]
			),
			...WHOLE_CHARACTERS_KEYS.PERFORMANCE.map(
				key => this.report?.characterStrengths?.vsGSD?.PERFORMANCE[key]
			)
		];
		let characterStrengthsVsGSDCoverage = [
			...WHOLE_CHARACTERS_KEYS.MORAL.map(
				key => this.report?.characterStrengthsCoverage?.vsGSD?.MORAL[key]
			),
			...WHOLE_CHARACTERS_KEYS.PERFORMANCE.map(
				key => this.report?.characterStrengthsCoverage?.vsGSD?.PERFORMANCE[key]
			)
		];
		let chartOptionsTemp: any = {
			series: [
				{
					data: [
						{
							name: "Charater Strengths profile",
							value: map(this.characterStrengthsVsGSD, maxLimit(2))
						}
					]
				}
			]
		};
		if (this.report.type === "GROUP") {
			let groupCoverageSerieData: any = cloneDeep(
				SERIE_DEFAULTS_GROUP_COVERAGE
			);
			groupCoverageSerieData.data[0].value = map(
				characterStrengthsVsGSDCoverage,
				maxLimit(2)
			);
			chartOptionsTemp.series.push(groupCoverageSerieData);
		}
		this.chartOptions = chartOptionsTemp;
		this.Logger.log(this.chartOptions);
	}
}
