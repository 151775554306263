<section fxLayout="column" style="position: relative;">
	<app-loader [loaderId]="loaderId"></app-loader>
	<form [formGroup]="targetFG">
		<div fxLayout="row" fxLayoutAlign="start center">
			<app-organisation-select *ngIf="!globalSelectedOrg && !orgId" [showAll]="false" [global]="false"
				[selectedOrgId]="targetFG.value.orgId" (selectedOrgChange)="updateOrg($event)">
			</app-organisation-select>
			<mat-form-field fxFlex="1 1 100%" fxFlexFill>
				<input matInput [placeholder]="type==='GROUP'?'Group':'Role'" [matAutocomplete]="auto"
					[formControl]="groupCtrl">
				<button *ngIf="groupCtrl.value" matSuffix color="accent" mat-icon-button aria-label="Clear"
					(click)="clearSelection()">
					<mat-icon>close</mat-icon>
				</button>
				<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)" [displayWith]="displayWith">
					<mat-option *ngIf="isLoading" disabled>
						<span><small><i>Searching for <b>{{groupCtrl.value}}</b>...</i></small></span>
					</mat-option>
					<ng-container *ngIf="!isLoading">
						<mat-option *ngFor="let group of filteredGroups" [value]="group" [disabled]="group.disabled">
							<span>{{group.name}}</span>
						</mat-option>
						<mat-option *ngIf="filteredGroups.length===0" disabled>
							<span><small><i>No matches found. You can create a new one.</i></small></span>
						</mat-option>
					</ng-container>
				</mat-autocomplete>
				<mat-error *ngIf="errorMsg">
					{{errorMsg}}
				</mat-error>
				<mat-error *ngIf="groupCtrl.hasError">
					<span *ngIf="groupCtrl.errors?.required">
						Please type in Name.
					</span>
				</mat-error>
			</mat-form-field>
			<span class="ca-spacer"></span>
			<div fxFlex="0 0 140px" fxFlexFill style="position: relative;" fxLayout="row" fxLayoutAlign="end center">
				<button mat-flat-button color="primary" [disabled]="targetFG.invalid" (click)="save()">
					{{
					targetFG.value.id?'Update':'Create'
					}}
				</button>
				<button matTooltip="Delete" mat-icon-button color="warn" [disabled]="!targetFG.value.id" aria-label="link"
					(click)="delete()">
					<mat-icon>delete</mat-icon>
				</button>
			</div>
		</div>
		<app-aspirations [aspirations]="aspirations">
		</app-aspirations>
	</form>
</section>