import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class LocationService {
	private cache$!: Observable<any>;
	baseURL = environment.baseUrl;
	constructor(public http: HttpClient) {}
	get countries() {
		if (!this.cache$) {
			this.cache$ = this.getCountries().pipe(shareReplay(1));
		}
		return this.cache$;
	}
	getCountries() {
		return this.http.get(`${this.baseURL}/countries.json`);
	}
	//* INFO commented api in server also
	// getIPLocation() {
	// 	return this.http.get(`${this.baseURL}/ip_location`);
	// }
	getIPInfo() {
		return new Promise(resolve => {
			this.http
				.get(`https://ipinfo.io/?token=${environment.IPInfoToken}`)
				.subscribe(res => {
					let location;
					if (res) {
						location = res;
						localStorage.setItem("location", JSON.stringify(res));
					} else {
						location =
							JSON.parse(<string>localStorage.getItem("location")) || null;
					}
					resolve(location);
				});
		});
	}
}
