<h1 *ngIf="dialogRef" mat-dialog-title>
	<app-avatar [avatar]="dialogData?.subscription?.group" [fxInline]="true">
	</app-avatar> - Transaction History
</h1>
<button *ngIf="dialogRef" mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<section fxLayout="column">
		<div fxLayout="row wrap" fxLayoutAlign="start center">
			<span class="ca-spacer"></span>
			<mat-paginator class="ca-mat-paginator" [length]="resultsLength" [pageSize]="pageSize"
				[showFirstLastButtons]="(resultsLength/pageSize)>2" [pageSizeOptions]="[10,25,50,100]" aria-label="Select page">
			</mat-paginator>
		</div>
		<div fxLayout="column" class="ca-page-content">
			<div fxLayout="row" fxLayoutAlign="center center" fxFlex="shrink" *ngIf="!isLoadingResults  && data.length===0">
				<div *ngIf="(filterFormControl?.value===null || filterFormControl?.value?.length===0)">
					All Your Invoices will appear here
				</div>
				<div *ngIf="filterFormControl?.value?.length>0">
					There are no results available for this keyword <b>"{{ filterFormControl.value}}"</b>. Please
					refine
					your search with some other words.
				</div>
			</div>
			<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
			<div class="ca-mat-table-container" [hidden]="isLoadingResults || data.length===0">
				<app-ng-scrollbar-wrapper [autoHeightDisabled]="true">
					<mat-table class="mat-elevation-z8" [dataSource]="data" matSort [matSortActive]="'createdAt'"
						matSortDirection="desc" fixedLayout="true">
						<ng-container matColumnDef="index">
							<mat-header-cell *matHeaderCellDef>
								No.
							</mat-header-cell>
							<mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="period">
							<mat-header-cell *matHeaderCellDef>
								Billing Period
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								{{(element.meta.period_end +
								(60 * 60 * 24)) * 1000 | date:"MMM YYYY"}}
								<!-- adding 24hrs to the period end to get next month in all timezone -->
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="quantity">
							<mat-header-cell *matHeaderCellDef>
								No Of Users
							</mat-header-cell>
							<mat-cell *matCellDef="let element"> {{element.meta.billing_reason==="subscription_create"?
								'-':element.meta.lines.data[0].quantity}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="amount">
							<mat-header-cell *matHeaderCellDef>
								<span>
									Amount <br>
									<small>
										(incl. GST)
									</small>
								</span>
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<span>
									A$<b>{{element.meta.total/100 | customNumber:2}}</b>
									<mat-icon mat-icon-button class="ca-along-text-icon sm-icon" [matMenuTriggerFor]="priceBreakdown"
										color="accent">
										info_outline</mat-icon>
									<mat-menu class="no-max-width" #priceBreakdown="matMenu" yPosition="above">
										<table class="price-break-down">
											<tr>
												<td class="text-align-left"><b>Cost :</b>
													A${{element.meta.subtotal_excluding_tax/100 | customNumber:2}}</td>
											</tr>
											<tr>
												<td class="text-align-left"> <b>GST ({{element.meta.tax_percent}}%) :</b>
													A${{element.meta.tax/100 | customNumber:2}}
												</td>
											</tr>
										</table>
									</mat-menu>
								</span>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="invoiceStatus">
							<mat-header-cell *matHeaderCellDef>
								Invoice Status
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<ng-container *ngIf="element.meta.total>0;else noPaymentTRef">
									<ng-container [ngSwitch]="element.status">
										<ng-container *ngSwitchCase="'paid'">
											<mat-icon matTooltip="Paid" color="primary">
												paid
											</mat-icon>
										</ng-container>
										<ng-container *ngSwitchCase="'draft'">
											<mat-icon matTooltip="Draft status. Soon Will be Attempted to Charge." color="accent">
												pending
											</mat-icon>
										</ng-container>
										<ng-container *ngSwitchCase="'void'">
											<mat-icon matTooltip="Void, Payment was Voided" color="accent">
												block
											</mat-icon>
										</ng-container>
										<ng-container *ngSwitchCase="'open'">
											<ng-container *ngIf="element.meta.attempted">
												<mat-icon matTooltip="Payment Failure" color="warn">
													paid
												</mat-icon>
												&nbsp;
												<button mat-stroked-button color="primary" (click)="retryInvoicePaymentNow(element)">Retry
													Now</button>
											</ng-container>
										</ng-container>
									</ng-container>
								</ng-container>
								<ng-template #noPaymentTRef>
									-
								</ng-template>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="createdAt">
							<mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">
								Created on
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								{{element.createdAt| date:'medium' }}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="actions">
							<mat-header-cell *matHeaderCellDef>
								Actions
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<!-- <button matTooltip="Download" mat-icon-button class="mat-icon-loader-button" color="primary"
									aria-label="link" (click)="download(element);$event.stopPropagation()">
									<mat-icon>download</mat-icon>
									<app-loader [loaderId]="element.stripeId" [fgsSize]=40></app-loader>
								</button> -->
								<button matTooltip="Resend Invoice" mat-icon-button color="primary" aria-label="link"
									(click)="openShareInvoice(element);$event.stopPropagation()">
									<mat-icon>send </mat-icon>
								</button>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="updatedAt">
							<mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt">
								Last Updated on
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								{{element.updatedAt| date:'medium' }}
							</mat-cell>
						</ng-container>
						<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
					</mat-table>
				</app-ng-scrollbar-wrapper>
			</div>
		</div>
	</section>
</mat-dialog-content>
