import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, Inject } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from "@angular/material/dialog";
import { STEP_GROUP_BASIC } from "../../types/step-group";
import { ImageCropperComponent } from "../image-cropper/image-cropper.component";
import { StepGroupService } from "../../services/step-group.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FILE_UPLOAD } from "../../types/common";
import { FileService } from "../../services/file.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
@Component({
	selector: "app-ep-group-dependents",
	templateUrl: "./ep-group-dependents.component.html",
	styleUrls: ["./ep-group-dependents.component.scss"]
})
export class EPGroupDependentsComponent {
	dependents: STEP_GROUP_BASIC[] = [];
	files: FILE_UPLOAD[] = [];
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	constructor(
		public dialogRef: MatDialogRef<EPGroupDependentsComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		public fb: FormBuilder,
		public _dialog: MatDialog,
		public _snack: MatSnackBar,
		private stepGroupService: StepGroupService,
		private fileService: FileService,
		private ngxUiLoaderService: NgxUiLoaderService
	) {}
	add(event: MatChipInputEvent): void {
		const value = (event.value || "").trim();
		let names = value.split(",");
		if (names.length) {
			for (let groupName of names) {
				if (groupName) {
					this.dependents.push({ groupName });
					this.files.push({});
				}
			}
		}
		// Clear the input value
		event.chipInput!.clear();
	}

	remove(i: number): void {
		if (i >= 0) {
			this.dependents.splice(i, 1);
			this.files.splice(i, 1);
		}
	}
	onProfileUpload($event: Event, i: number) {
		const target = $event.target as HTMLInputElement;
		const files = target.files as FileList;
		if (files?.length) {
			const dialogRef = this._dialog.open(ImageCropperComponent, {
				data: {
					event: $event,
					type: "PROFILE_IMAGE"
				}
			});
			dialogRef.afterClosed().subscribe(async (result: any) => {
				if (result) {
					if (!this.files[i]) this.files[i] = {};
					this.files[i]!.src = result.base64;
					this.files[i]!.file = result.blob;
				} else {
					($event.target as HTMLInputElement).value = "";
				}
			});
		}
	}
	removeProfilePic(i: number) {
		delete this.files[i].src;
		delete this.files[i].file;
	}
	async uploadProfilePics() {
		for (let i in this.dependents) {
			if (this.files[i]?.file) {
				try {
					this.dependents[i].imageKey = await this.fileService.uploadFile(
						this.files[i].file as File,
						"GROUP_PROFILE",
						"STEP"
					);
					delete this.files[i].file;
				} catch (error) {}
			}
		}
	}

	async complete() {
		this.ngxUiLoaderService.start();
		await this.uploadProfilePics();
		this.stepGroupService
			.createDependents(this.dialogData.orgId, this.dialogData.parentId, {
				dependents: this.dependents
			})
			.subscribe((res: any) => {
				this.dialogRef.close({
					dependents: res.groups
				});
				this.ngxUiLoaderService.stop();
				this._snack.open("Dependents Created Successfully");
			});
	}
}
