import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { LoggerService } from "../../services/logger.service";

@Component({
	selector: "app-microsoft-signin",
	templateUrl: "./microsoft-signin.component.html",
	styleUrls: ["./microsoft-signin.component.scss"]
})
export class MicrosoftSigninComponent {
	@Input() text = "Continue with Microsoft";
	@Output() signedIn: EventEmitter<any> = new EventEmitter<any>();
	constructor(
		private msalService: MsalService,
		private loggerService: LoggerService
	) {}

	login() {
		this.msalService.loginPopup().subscribe({
			next: result => {
				this.signedIn.emit(result);
			},
			error: error => this.loggerService.log(error)
		});
	}
}
