<div mat-dialog-title>
	<app-avatar [fxInline]="true" [avatar]="data?.partnerSurvey?.org"> </app-avatar> -
	{{data?.partnerSurvey?.name}}
</div>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<app-reports [partnerSurvey]="data.partnerSurvey" [freezeFilter]="data.freezeFilter" [isDialog]="true"></app-reports>
</mat-dialog-content>
