import {
	AfterViewInit,
	Component,
	Inject,
	Optional,
	ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { random } from "lodash";
import { StripePaymentElementComponent, StripeService } from "ngx-stripe";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { scan } from "rxjs/operators";
import { AutoUnsubscribeOnDestroy } from "src/app/_shared/decorators/autoUnsubscribeOnDestroy";
import { COST_PER_ASSESSMENT } from "../../const/organisation";
import { getOrgTypeDiscount } from "../../pipes/org-type-discount.pipe";
import { AuthService } from "../../services/auth.service";
import { OrganisationService } from "../../services/organisation.service";
import { PaymentService } from "../../services/payment.service";
import { ORG } from "../../types/organisation";
import { fontCenturyGothic } from "../../utils/font";
@Component({
	selector: "app-buy",
	templateUrl: "./buy.component.html",
	styleUrls: ["./buy.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class BuyComponent implements AfterViewInit {
	@ViewChild("stepper") private stepper!: MatStepper;
	@ViewChild(StripePaymentElementComponent)
	paymentElement!: StripePaymentElementComponent;
	@ViewChild("orgSelect") orgSelect?: MatSelect;
	subscriptions = new Subscription();
	orgSelectListLoaderId = "orgSelectListLoaderId-" + random(1, 10000);
	selectedOrg!: ORG;
	orgs = new BehaviorSubject<Array<ORG>>([]);
	orgs$: Observable<Array<ORG>>;
	currentLength = 0;
	limit = 20;
	offset = 0;
	total = Infinity;
	globalSelectedOrg!: ORG | null;
	status: "PAYMENT" | "SUCCESS" | "FAILED" = "PAYMENT";
	error!: any;
	user?: any;
	breakdowns?: {
		totalAmount?: number;
		GST?: number;
	};
	elementsOptions: StripeElementsOptions = {
		locale: "en",
		loader: "always",
		fonts: [fontCenturyGothic],
		appearance: {
			variables: {
				fontFamily: "Century Gothic",
				fontSmooth: "never"
			},
			labels: "floating"
		}
	};
	detailsFormGroup!: FormGroup;
	result!: any;
	countryCode?: string;
	readonlyOrgId = false;
	constructor(
		@Optional() public dialogRef: MatDialogRef<BuyComponent>,
		private _snackBar: MatSnackBar,
		private stripeService: StripeService,
		private paymentService: PaymentService,
		private fb: FormBuilder,
		public organisationService: OrganisationService,
		private authService: AuthService,
		private UiLoaderService: NgxUiLoaderService,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: {
			orgId: number;
		}
	) {
		this.orgs$ = this.orgs.asObservable().pipe(
			scan((acc: ORG[], curr: ORG[]) => {
				return [...acc, ...curr];
			}, [])
		);
		this.detailsFormGroup = this.fb.group({
			orgId: [null, Validators.required],
			creditPoints: [null, [Validators.required, Validators.min(1)]],
			byId: [null, Validators.required],
			countryCode: [null, Validators.required]
		});
	}
	ngAfterViewInit(): void {
		this.init();
	}
	async init() {
		this.subscriptions.add(
			this.authService.getUserInfo().subscribe(user => (this.user = user))
		);

		this.subscriptions.add(
			this.detailsFormGroup.get("orgId")?.valueChanges.subscribe(res => {
				this.getOrg();
			})
		);
		this.setPresets();
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe(org => {
				this.globalSelectedOrg = org;
				if (org) {
					this.detailsFormGroup.patchValue({ orgId: org.id });
					this.readonlyOrgId = true;
				} else {
					this.readonlyOrgId = false;
				}
			})
		);
		if (this.data?.orgId) {
			this.readonlyOrgId = true;
		}
		await this.getOrgs();
		if (!this.readonlyOrgId) {
			setTimeout(() => {
				this.orgSelect?.open();
				this.orgSelect?.focus();
			}, 0);
		}
	}

	get costPerAssignmentAfterDiscount() {
		if (!this.selectedOrg) return COST_PER_ASSESSMENT;
		return Math.floor(
			COST_PER_ASSESSMENT - COST_PER_ASSESSMENT * this.discountRate
		);
	}
	get discountRate() {
		if (this.selectedOrg) return getOrgTypeDiscount(this.selectedOrg?.orgType);
		return 0;
	}
	get org() {
		return this.selectedOrg ?? this.globalSelectedOrg;
	}
	setPresets({ countryCode }: { countryCode?: string } = {}) {
		this.detailsFormGroup.patchValue({
			byId: this.user.id,
			countryCode,
			orgId: this.globalSelectedOrg?.id ?? this.data?.orgId
		});
	}
	getOrg() {
		let orgId = this.detailsFormGroup.get("orgId")?.value;
		if (orgId) {
			this.organisationService.getOrg(orgId).subscribe(res => {
				this.selectedOrg = res.org;
			});
		}
	}

	async getOrgs() {
		if (this.currentLength < this.total) {
			this.UiLoaderService.startLoader(this.orgSelectListLoaderId);
			(
				await this.organisationService.getOrgs({
					offset: this.offset,
					limit: this.limit
				})
			).subscribe(res => {
				this.orgs.next(res.list);
				this.currentLength += res.list.length;
				this.total = res.total;
				this.UiLoaderService.stopLoader(this.orgSelectListLoaderId);
			});
		}
	}
	createPaymentIntent() {
		this.detailsFormGroup.markAllAsTouched();
		if (this.detailsFormGroup.valid) {
			this.UiLoaderService.start();
			this.paymentService
				.createPaymentIntent(this.detailsFormGroup.value)
				.subscribe((pi: any) => {
					this.elementsOptions.clientSecret = pi.client_secret as string;
					this.breakdowns = pi;
					this.stepper.next();
					this.UiLoaderService.stop();
				});
		}
	}
	pay() {
		this.UiLoaderService.start();
		this.stripeService
			.confirmPayment({
				elements: this.paymentElement.elements,
				confirmParams: {
					payment_method_data: {
						billing_details: {
							name: `${this.user.firstName || ""} ${this.user.lastName || ""}`,
							email: this.user.email
						}
					}
				},
				redirect: "if_required"
			})
			.subscribe(result => {
				this.result = result;
				if (!result.error) {
					if (result?.paymentIntent?.status === "succeeded") {
						this._snackBar.open("Payment Success");
						this.status = "SUCCESS";
						setTimeout(() => {
							this.closeIfDialogElseRefreshOrg();
						}, 3000);
					}
				} else {
					this.error = result.error;
					this.status = "FAILED";
				}
				this.UiLoaderService.stop();
			});
	}
	reset() {
		this.countryCode = this.detailsFormGroup.value.countryCode;
		this.status = "PAYMENT";
		this.stepper?.reset();
		this.detailsFormGroup.reset();
		delete this.elementsOptions.clientSecret;
		delete this.breakdowns;
		this.setPresets({ countryCode: this.countryCode });
	}
	closeIfDialogElseRefreshOrg() {
		if (this.dialogRef) {
			this.dialogRef.close({ refresh: true });
		} else {
			this.getOrgs();
			this.organisationService.refreshEvent.emit();
		}
	}

	onScroll() {
		this.offset = Math.min(this.offset + this.limit, this.total);
		this.limit = Math.min(this.total - this.offset, this.limit);
		this.getOrgs();
	}
}
