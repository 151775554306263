import { LoggerService } from "src/app/_shared/services/logger.service";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import {
	dualComparisonColor2,
	getName,
	initOpts,
	loadingOpts
} from "../../../utils/chart-options";
import { EChartsOption } from "echarts";
import { SERIE_DEFAULTS_ASPIRATION, CHART_DEFAULTS } from "./chart-defaults";
import {
	WHOLE_CHARACTERS_KEYS,
	WHOLE_CHARACTERS_KEYS_INDIVIDUAL
} from "../character";
import { cloneDeep } from "lodash";
import { truncate } from "src/app/_shared/utils/string";
import { TooltipOption } from "echarts/types/dist/shared";
import { SERIE_DEFAULTS_GROUP_COVERAGE } from "src/app/_shared/const/chart";

@Component({
	selector: "app-cs-profile",
	templateUrl: "./cs-profile.component.html",
	styleUrls: ["./cs-profile.component.scss"]
})
export class CSProfileComponent implements OnChanges, OnInit {
	@Input() report: any;
	@Input() group: any;
	@Input() role: any;
	@Input() compReport: any;
	@Input() compReport2: any;
	@Input() compGroupReport: any;
	@Input() compGroupReport2: any;
	@Input() isLoading: boolean = true;
	@Input() showTooltip: boolean = true;

	initOpts = initOpts;
	loadingOpts = loadingOpts;
	default: EChartsOption = CHART_DEFAULTS;
	chartOptions!: EChartsOption;
	@Input() isSingleComparison?: boolean;
	@Input() isDualComparison?: boolean;
	@Input() isComparison?: boolean;
	@Input() isSecondComparison?: boolean;

	constructor(public Logger: LoggerService) {}
	ngOnInit(): void {
		(this.default.tooltip! as TooltipOption).show = this.showTooltip;
	}
	ngOnChanges() {
		this.initChart();
	}
	initChart() {
		let chartOptionsTemp: any = {
			series: [
				{
					data: [
						{
							name: "Charater Strengths profile",
							value: [
								...WHOLE_CHARACTERS_KEYS.MORAL.map(
									key => this.report.characterStrengthsProfile.MORAL[key]
								),
								...WHOLE_CHARACTERS_KEYS.PERFORMANCE.map(
									key => this.report.characterStrengthsProfile.PERFORMANCE[key]
								)
							]
						}
					]
				}
			]
		};
		if (this.report.type === "GROUP") {
			let groupCoverageSerieData: any = cloneDeep(
				SERIE_DEFAULTS_GROUP_COVERAGE
			);
			groupCoverageSerieData.data[0].value = [
				...WHOLE_CHARACTERS_KEYS.MORAL.map(
					key => this.report.characterStrengthsProfileCoverage.MORAL[key]
				),
				...WHOLE_CHARACTERS_KEYS.PERFORMANCE.map(
					key => this.report.characterStrengthsProfileCoverage.PERFORMANCE[key]
				)
			];
			chartOptionsTemp.series.push(groupCoverageSerieData);
		}
		if (this.isComparison) {
			chartOptionsTemp.title = [
				{ id: "moral", bottom: 0, left: 10, right: undefined }
			];
		}
		if (this.compReport) {
			let aspirationSerieData: any = cloneDeep(SERIE_DEFAULTS_ASPIRATION);
			aspirationSerieData.data[0].value = [
				...WHOLE_CHARACTERS_KEYS.MORAL.map(
					key => this.compReport.characterStrengthsProfile.MORAL[key]
				),
				...WHOLE_CHARACTERS_KEYS.PERFORMANCE.map(
					key => this.compReport.characterStrengthsProfile.PERFORMANCE[key]
				)
			];
			if (this.isComparison) {
				aspirationSerieData.data[0].name = truncate(this.compReport.name, {
					length: 20
				});
				chartOptionsTemp.series[0].data[0].name =
					getName(this.report) + " Score";
			} else {
				aspirationSerieData.data[0].name = truncate(this.compReport.name, {
					length: 25
				});
			}
			chartOptionsTemp.series.push(aspirationSerieData);
		}
		if (this.compReport2) {
			let aspirationSerieData: any = cloneDeep(SERIE_DEFAULTS_ASPIRATION);
			aspirationSerieData.itemStyle.borderColor = dualComparisonColor2;
			aspirationSerieData.lineStyle.color = dualComparisonColor2;
			aspirationSerieData.data[0].value = [
				...WHOLE_CHARACTERS_KEYS.MORAL.map(
					key => this.compReport2.characterStrengthsProfile.MORAL[key]
				),
				...WHOLE_CHARACTERS_KEYS.PERFORMANCE.map(
					key => this.compReport2.characterStrengthsProfile.PERFORMANCE[key]
				)
			];
			if (this.isComparison) {
				aspirationSerieData.data[0].name = truncate(this.compReport2.name, {
					length: 20
				});
				chartOptionsTemp.series[0].data[0].name =
					getName(this.report) + " Score";
			} else {
				aspirationSerieData.data[0].name = truncate(this.compReport2.name, {
					length: 25
				});
			}
			chartOptionsTemp.series.push(aspirationSerieData);
		}
		if (this.compGroupReport) {
			let aspirationSerieData: any = cloneDeep(SERIE_DEFAULTS_ASPIRATION);
			aspirationSerieData.data[0].value = [
				...WHOLE_CHARACTERS_KEYS.MORAL.map(
					key => this.compGroupReport.characterStrengthsProfile.MORAL[key]
				),
				...WHOLE_CHARACTERS_KEYS.PERFORMANCE.map(
					key => this.compGroupReport.characterStrengthsProfile.PERFORMANCE[key]
				)
			];
			if (this.isComparison) {
				aspirationSerieData.data[0].name = truncate(
					this.compGroupReport.name || this.compGroupReport.reportNames,
					{ length: 20 }
				);
				chartOptionsTemp.series[0].data[0].name =
					getName(this.report) + " Score";
			} else {
				aspirationSerieData.data[0].name = truncate(
					this.compGroupReport.name || this.compGroupReport.reportNames,
					{ length: 25 }
				);
			}
			chartOptionsTemp.series.push(aspirationSerieData);
		}
		if (this.compGroupReport2) {
			let aspirationSerieData: any = cloneDeep(SERIE_DEFAULTS_ASPIRATION);
			aspirationSerieData.itemStyle.borderColor = dualComparisonColor2;
			aspirationSerieData.lineStyle.color = dualComparisonColor2;
			aspirationSerieData.data[0].value = [
				...WHOLE_CHARACTERS_KEYS.MORAL.map(
					key => this.compGroupReport2.characterStrengthsProfile.MORAL[key]
				),
				...WHOLE_CHARACTERS_KEYS.PERFORMANCE.map(
					key =>
						this.compGroupReport2.characterStrengthsProfile.PERFORMANCE[key]
				)
			];

			if (this.isComparison) {
				aspirationSerieData.data[0].name = truncate(
					this.compGroupReport2.name || this.compGroupReport2.reportNames,
					{ length: 20 }
				);
				chartOptionsTemp.series[0].data[0].name =
					getName(this.report) + " Score";
			} else {
				aspirationSerieData.data[0].name = truncate(
					this.compGroupReport2.name || this.compGroupReport2.reportNames,
					{ length: 25 }
				);
			}
			chartOptionsTemp.series.push(aspirationSerieData);
		}
		if (this.group) {
			let aspirationSerieData: any = cloneDeep(SERIE_DEFAULTS_ASPIRATION);
			aspirationSerieData.data[0].value = [
				...WHOLE_CHARACTERS_KEYS_INDIVIDUAL.MORAL.map(
					key => this.group.MORAL[key]
				),
				...WHOLE_CHARACTERS_KEYS_INDIVIDUAL.PERFORMANCE.map(
					key => this.group.PERFORMANCE[key]
				)
			];

			if (this.isComparison) {
				aspirationSerieData.data[0].name = truncate(this.group.name, {
					length: 20
				});
				chartOptionsTemp.series[0].data[0].name =
					getName(this.report) + " Score";
			}
			chartOptionsTemp.series.push(aspirationSerieData);
		}
		if (this.role) {
			let aspirationSerieData = cloneDeep(SERIE_DEFAULTS_ASPIRATION);
			aspirationSerieData.itemStyle.borderColor = dualComparisonColor2;
			aspirationSerieData.lineStyle.color = dualComparisonColor2;

			aspirationSerieData.data[0].value = [
				...WHOLE_CHARACTERS_KEYS_INDIVIDUAL.MORAL.map(
					key => this.role.MORAL[key]
				),
				...WHOLE_CHARACTERS_KEYS_INDIVIDUAL.PERFORMANCE.map(
					key => this.role.PERFORMANCE[key]
				)
			];
			if (this.isComparison) {
				aspirationSerieData.data[0].name = truncate(this.role.name, {
					length: 20
				});
				chartOptionsTemp.series[0].data[0].name =
					getName(this.report) + " Score";
			}
			chartOptionsTemp.series.push(aspirationSerieData);
		}
		this.chartOptions = chartOptionsTemp;

		this.Logger.log(this.chartOptions);
	}
}
