import { SelectionModel } from "@angular/cdk/collections";
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
	interval,
	merge,
	Observable,
	of as observableOf,
	Subscription
} from "rxjs";
import {
	catchError,
	debounce,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
	tap
} from "rxjs/operators";
import { DualComparisonComponent } from "src/app/_shared/components/dual-comparison/dual-comparison.component";
import { SingleComparisonComponent } from "src/app/_shared/components/single-comparison/single-comparison.component";
import { LoggerService } from "src/app/_shared/services/logger.service";
import { getSurveyURL } from "src/app/_shared/utils/string";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { AuthService } from "../../services/auth.service";
import { GroupReportService } from "../../services/group-report.service";
import { ORG } from "../../types/organisation";
import { USER } from "../../types/user";
import { ActivityLogsComponent } from "../activity-logs/activity-logs.component";
import { ShareReportComponent } from "../share-report/share-report.component";
import { OrganisationService } from "./../../services/organisation.service";
import { AccountService } from "../../services/account.service";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { MatTableWrapperComponent } from "../../class/mat-table-wrapper";
import {
	ConfirmDialogComponent,
	ConfirmDialogModel
} from "../confirm-dialog/confirm-dialog.component";
@Component({
	selector: "app-group-reports",
	templateUrl: "./group-reports.component.html",
	styleUrls: ["./group-reports.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class GroupReportsComponent
	extends MatTableWrapperComponent
	implements OnInit, AfterViewInit
{
	subscriptions = new Subscription();
	loaderId = "reports-loader";
	@Input() partnerSurvey?: any;
	@Input() freezeFilter?: {
		partnerSurvey?: boolean;
	} = {};
	selection: SelectionModel<any> = new SelectionModel<any>(true, []);
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	refreshEvent = new EventEmitter();
	filterFormControl: FormControl = new FormControl();
	searchOnFormControl: FormControl = new FormControl();
	data: any[] = [];
	resultsLength = 0;
	pageSize = 10;
	isLoadingResults = false;
	user?: USER | null;
	org?: ORG | null;
	selectedOrg?: ORG | null;
	partnerSurveys$?: any[] = [];
	maxLimitForGroupReport = 20;
	displayedColumns = [
		"index",
		"name",
		"reportNames",
		"actions",
		"members",
		"singleComparison",
		"dualComparison",
		"createdAt",
		"paidBy",
		"org",
		"log"
	];
	constructor(
		public Logger: LoggerService,
		private UiLoaderService: NgxUiLoaderService,
		private dialog: MatDialog,
		public groupReportService: GroupReportService,
		private organisationService: OrganisationService,
		private authService: AuthService,
		private accountService: AccountService,
		private _snackBar: MatSnackBar
	) {
		super();
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe((org: ORG | null) => {
				this.org = org;
				this.selectedOrg = org;
				this.refreshEvent.next();
			})
		);
	}
	ngOnInit(): void {
		this.getOrg();
		this.subscriptions.add(
			this.authService.getUserInfo().subscribe(user => (this.user = user))
		);
	}
	ngAfterViewInit() {
		// If the user changes the sort order, reset back to the first page.
		this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		merge(
			this.sort?.sortChange,
			this.paginator?.page,
			this.filterFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				tap(() => {
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
				}),
				distinctUntilChanged()
			),
			this.searchOnFormControl.valueChanges.pipe(
				debounce(() => {
					this.UiLoaderService.startBackgroundLoader(this.loaderId);
					return interval(2000);
				}),
				distinctUntilChanged()
			),
			this.refreshEvent
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					setTimeout(() => {
						this.isLoadingResults = true;
					}, 0);
					return this.groupReportService!.getAll({
						offset: this.paginator.pageIndex * this.paginator.pageSize,
						limit: this.paginator.pageSize,
						sortingKey: this.sort.active,
						sortBy: this.sort.direction,
						searchText: this.filterFormControl.value || undefined,
						orgId: this.org?.id
					}).pipe(catchError(() => observableOf(null)));
				}),
				map((data: any) => {
					// Flip flag to show that loading has finished.
					setTimeout(() => {
						this.isLoadingResults = false;
					}, 0);
					this.UiLoaderService.stopBackgroundLoader(this.loaderId);
					if (data === null) {
						return [];
					}
					this.selection.clear();
					this.resultsLength = data.total;
					data.reportsList.forEach(async (partnerSurvey: any) => {
						if (partnerSurvey?.orgId) {
							partnerSurvey.members = this.getMembers(partnerSurvey?.orgId);
						}
					});
					return data.reportsList;
				})
			)
			.subscribe(data => {
				this.data = data;
				this.reRender();
			});
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.data.length;
		return numSelected == numRows;
	}

	/** clear selection. */
	deselectAll() {
		this.selection.clear();
	}
	selectionChange($event: MatCheckboxChange | MouseEvent, row: any) {
		if ($event instanceof MatCheckboxChange) {
			if ($event.checked) {
				if (this.selection.selected.length < this.maxLimitForGroupReport) {
					this.selection.select(row);
				} else {
					this._snackBar.open(
						`Max Limit(${this.maxLimitForGroupReport}) for Group Report reached.`
					);
					$event.source.checked = false;
				}
			} else {
				this.selection.deselect(row);
			}
		} else {
			if (this.selection.isSelected(row)) {
				this.selection.deselect(row);
			} else {
				if (this.selection.selected.length < this.maxLimitForGroupReport) {
					this.selection.select(row);
				} else {
					this._snackBar.open(
						`Max Limit(${this.maxLimitForGroupReport}) for Group Report reached.`
					);
				}
			}
		}
	}
	async openInNewTab(report: any) {
		const surveyURL = getSurveyURL();
		const auth = await this.accountService.getSinglePurposeToken({
			groupReportUUIDV4: report.UUIDV4
		});
		window.open(
			`${surveyURL}/report/group/${report.UUIDV4}?&authorization=${auth.token}&authType=${auth.type}`
		);
	}
	async download(groupReport: any) {
		this.UiLoaderService.startLoader(groupReport.UUIDV4);
		try {
			await this.groupReportService.download({
				groupReport
			});
		} catch (error) {
			this.Logger.error(error);
		} finally {
			this.UiLoaderService.stopLoader(groupReport.UUIDV4);
		}
	}
	openShareReport(groupReport: any) {
		this.dialog.open(ShareReportComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				groupReport
			}
		});
	}
	openSingleComparison(groupReport: any) {
		const dialogRef = this.dialog.open(SingleComparisonComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				type: "GROUP",
				groupId: groupReport.selectedSingleComparisonGroupId ?? null,
				orgId: groupReport?.orgId,
				groupReport
			}
		});
		dialogRef.afterClosed().subscribe(groupId => {
			if (groupId !== groupReport.selectedSingleComparisonGroupId) {
				const payload = {
					selectedSingleComparisonGroupId: groupId
				};
				this.groupReportService
					.patchReportDefaults(groupReport.UUIDV4, payload)
					.subscribe((res: any) => {
						groupReport.selectedSingleComparisonGroupId = groupId;
					});
			}
		});
	}
	openDualComparison(groupReport: any) {
		const dialogRef = this.dialog.open(DualComparisonComponent, {
			panelClass: "ca-dialog",
			minHeight: "80%",
			maxHeight: "100%",
			data: {
				type: "GROUP",
				groupId: groupReport.selectedDualComparisonGroupId ?? null,
				roleId: groupReport.selectedDualComparisonRoleId ?? null,
				orgId: groupReport?.orgId,
				groupReport
			}
		});
		dialogRef.afterClosed().subscribe(({ groupId, roleId }) => {
			if (
				groupId !== groupReport.selectedDualComparisonGroupId ||
				roleId !== groupReport.selectedDualComparisonRoleId
			) {
				const payload = {
					selectedDualComparisonGroupId: groupId,
					selectedDualComparisonRoleId: roleId
				};
				this.groupReportService
					.patchReportDefaults(groupReport.UUIDV4, payload)
					.subscribe((res: any) => {
						groupReport.selectedDualComparisonGroupId = groupId;
						groupReport.selectedDualComparisonRoleId = roleId;
					});
			}
		});
	}
	getOrg() {
		if (this.selectedOrg?.id) {
			this.org = this.selectedOrg;
		} else {
			this.org = null;
		}
	}
	openLogs(groupReport: any) {
		this.dialog.open(ActivityLogsComponent, {
			width: "100%",
			minHeight: "80%",
			height: "80vh",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				groupReport,
				activityOf: "GROUP_REPORT"
			}
		});
	}
	getMembers(orgId: number): Observable<any[]> {
		return new Observable(observer => {
			this.organisationService
				.getMembers(orgId, { role: "MEMBER" })
				.subscribe((res: any) => {
					observer.next(res.list);
					observer.complete();
				});
		});
	}
	update(value: any, groupReport: any) {
		groupReport.newMemberIds = value;
	}
	async updateSelected(
		{ $event, matSelect }: { $event: boolean; matSelect: MatSelect },
		groupReport: any
	) {
		if (groupReport.newMemberIds && $event === false) {
			let confirm = await this.confirmIfUpdateGroupReportMembers(groupReport);
			if (confirm) {
				let payload = {
					memberIds: groupReport.newMemberIds
				};
				this.groupReportService
					.patchReportDefaults(groupReport.UUIDV4, payload)
					.subscribe((res: any) => {
						groupReport.newMemberIds = null;
						if (res.statusCode === 200) {
							this._snackBar.open("Members updated.");
						} else {
							matSelect.value = groupReport.memberIds;
						}
					});
			} else {
				matSelect.value = groupReport.memberIds;
				groupReport.newMemberIds = null;
			}
		}
	}

	confirmIfUpdateGroupReportMembers(groupReport: any): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `Do you wish to Update who has Access to the ${groupReport.name}?`;
			const dialogData = new ConfirmDialogModel("Confirm Action", {
				message,
				confirmText: "Update",
				dismissText: "Cancel"
			});
			const dialogRef = this.dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
}
