import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { queryParams } from "../types/common";
import { removeUndefined } from "../utils/object";
interface GroupQueryParams extends queryParams {
	orgId?: number;
}

@Injectable({
	providedIn: "root"
})
export class GroupService {
	baseURL = environment.baseUrl;
	constructor(public http: HttpClient) {}
	getById(id: string, OrgId: string) {
		return this.http.get<any>(`${this.baseURL}/group/${OrgId}/${id}`);
	}
	get(groupQueryParams: GroupQueryParams = {}) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...groupQueryParams,
					sortBy: groupQueryParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get<any>(`${this.baseURL}/group`, { params });
	}
	create(payload: any) {
		return this.http.post<any>(`${this.baseURL}/group`, payload);
	}
	update(payload: any) {
		return this.http.put<any>(`${this.baseURL}/group/${payload.id}`, payload);
	}
	delete(id: number) {
		return this.http.delete<any>(`${this.baseURL}/group/${id}`);
	}
}
