<section>

	<ng-container *ngFor="let preview of report?.characterArchetypes.slice(1)">
		<app-preview-content [report]="report"
			[bg]="'/assets/images/archetypes-previews/'+preview.characterStrength+'-1.png'">
		</app-preview-content>
		<app-preview-content [report]="report"
			[bg]="'/assets/images/archetypes-previews/'+preview.characterStrength+'-2.png'">
		</app-preview-content>
	</ng-container>
	<app-anchor anchorId="YCGP"></app-anchor>
	<ng-container *ngFor="let preview of previews">
		<app-preview-content [report]="report" [bg]="'/assets/images/common-preview-bg-'+preview+'.png'">
		</app-preview-content>
	</ng-container>
</section>
