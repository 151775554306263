import { padEnd } from "lodash";
export class TreeNode {
	id: number;
	element: any;
	name: string;
	children: any;
	isRoot: boolean = false;
	constructor(id: number, element: any) {
		this.id = id;
		this.element = element;
		this.name = element.groupName;
		this.children = [];
	}
	get isLeaf() {
		return this.children.length === 0;
	}

	get hasChildren() {
		return !this.isLeaf;
	}
	get value() {
		let minLength =
			Math.max(this.name.length, this.element.groupRef.length) + 2;
		let paddedName = padEnd(this.name, minLength, " ");
		let paddedGroupRef = padEnd(this.element.groupRef, minLength, " ");
		return {
			...this.element,
			id: this.id,
			name: paddedName,
			children: this.children.map((child: TreeNode) => child.value),
			groupRef: paddedGroupRef,
			isRoot: this.isRoot
		};
	}
}

export class Tree {
	root;
	filepathGenerator: Function;
	nodesMap = new Map();
	constructor(id: number, value = id, filepathGenerator: Function) {
		this.root = new TreeNode(id, value);
		this.root.isRoot = true;
		this.nodesMap.set(id, this.root);
		this.filepathGenerator = filepathGenerator;
	}

	*preOrderTraversal(node: TreeNode = this.root): Generator<TreeNode> {
		yield node;
		if (node.children.length) {
			for (let child of node.children) {
				yield* this.preOrderTraversal(child);
			}
		}
	}

	*postOrderTraversal(node: TreeNode = this.root): Generator<TreeNode> {
		if (node.children.length) {
			for (let child of node.children) {
				yield* this.postOrderTraversal(child);
			}
		}
		yield node;
	}

	insert(parentNodeid: number, id: number, value: TreeNode) {
		for (let node of this.preOrderTraversal()) {
			if (node.id === parentNodeid) {
				node.children.push(new TreeNode(id, value));
				return true;
			}
		}
		return false;
	}
	insertNodes(nodes: any[]) {
		nodes.forEach(node => {
			this.nodesMap.set(node.id, new TreeNode(node.id, node));
		});
		nodes.forEach(node => {
			let child = this.nodesMap.get(node.id);
			if (node.parentId) {
				let parent = this.nodesMap.get(node.parentId);
				parent.children.push(child);
			}
		});
	}
	remove(id: number) {
		for (let node of this.preOrderTraversal()) {
			const filtered = node.children.filter((c: TreeNode) => c.id !== id);
			if (filtered.length !== node.children.length) {
				node.children = filtered;
				return true;
			}
		}
		return false;
	}

	find(id: number) {
		for (let node of this.preOrderTraversal()) {
			if (node.id === id) return node;
		}
		return undefined;
	}
	get values() {
		return this.root.value;
	}
	getImages() {
		let images: any = {};
		for (let node of this.preOrderTraversal()) {
			images[node.id] = {
				height: 80,
				width: 80,
				color: "transparent",
				align: "center",
				backgroundColor: {
					image: this.filepathGenerator(node.element.imageKey)
				}
			};
		}
		return images;
	}
}
