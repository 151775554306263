<section fxLayout="column">
	<div fxLayout="row" fxLayoutAlign="start center" *ngIf="!isPublic">
		<button mat-raised-button color="primary" (click)="openCreatePartnerSurvey()">
			<mat-icon>add</mat-icon> Create Assessment
		</button>
	</div>
	<div fxLayout="row" fxLayoutAlign="start center">
		<mat-form-field fxFlex="0 0 25%" fxFlexFill>
			<mat-label>Filter</mat-label>
			<input matInput placeholder="Ex. Mia" #input [formControl]="filterFormControl">
			<button matSuffix mat-icon-button (click)="filterFormControl.setValue('')">
				<mat-icon *ngIf="filterFormControl.value" aria-label="Clear">close</mat-icon>
				<app-loader [loaderId]="loaderId" [bgsSize]="24" bgsPosition="center-center"></app-loader>
			</button>
		</mat-form-field>
		<span class="ca-spacer"></span>
		<mat-paginator class="ca-mat-paginator" [showFirstLastButtons]="true" [length]="resultsLength"
			[pageSizeOptions]="[10,25,50,100]" [pageSize]="pageSize" [showFirstLastButtons]="(resultsLength/pageSize)>2"
			aria-label="Select page">
		</mat-paginator>
	</div>
	<div fxLayout="column" class="ca-page-content" [class.two-rows]="!isPublic">
		<div fxLayout="row" fxLayoutAlign="center center" fxFlexFill fxFlex="shrink"
			*ngIf="!isLoadingResults  && data.length===0">
			<div *ngIf="(filterFormControl?.value===null || filterFormControl?.value?.length===0)">
				All Your Assessments will appear here
			</div>
			<div *ngIf="filterFormControl?.value?.length>0">
				There are no results available for this keyword &nbsp; <b>"{{ filterFormControl.value}}" </b>. Please
				refine
				your search with some other words.
			</div>
		</div>
		<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
		<div class="ca-mat-table-container" [hidden]="isLoadingResults || data.length===0">
			<app-ng-scrollbar-wrapper [autoHeightDisabled]="true">
				<mat-table class="mat-elevation-z8" [dataSource]="data" matSort [matSortActive]="'createdAt'"
					matSortDirection="desc">
					<ng-container matColumnDef="index" sticky>
						<mat-header-cell *matHeaderCellDef>
							No.
						</mat-header-cell>
						<mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="name" sticky>
						<mat-header-cell *matHeaderCellDef mat-sort-header="name">
							Name
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.name}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="org">
						<mat-header-cell *matHeaderCellDef>
							Organisation
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-avatar [avatar]="element.org">
							</app-avatar>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="noOfReports">
						<mat-header-cell *matHeaderCellDef>
							No of Reports
						</mat-header-cell>
						<mat-cell *matCellDef="let element" class="ca-overflow-auto ca-overflow-x-scroll">
							<ng-container [ngSwitch]="element.assessmentType">
								<ng-container *ngSwitchCase="'ON_DEMAND'">
									On Demand
								</ng-container>
								<ng-container *ngSwitchCase="'STANDARD'">
									<button *ngIf="!isPublic" [disabled]="element.noOfReportsUtilized >=element.noOfReports"
										mat-icon-button matTooltip="Reduce No Of Reports" color="primary" aria-label="Open View"
										(click)="openNoOfReportEditDialog(element,'DECREASE_NO_OF_REPORTS')">
										<mat-icon>remove_circle_outline</mat-icon>
									</button>
									{{element.noOfReports??'N/A'}}
									<button *ngIf="!isPublic" [disabled]="element.org?.membership?.roleLevel>1" mat-icon-button
										[matTooltip]="element.org?.membership?.roleLevel>1?'Admin/Owner can only Increase No of Reports':'Increase No Of Reports'"
										color="primary" aria-label="Open View"
										(click)="openNoOfReportEditDialog(element,'INCREASE_NO_OF_REPORTS')">
										<mat-icon>add_circle_outline</mat-icon>
									</button>
								</ng-container>
							</ng-container>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="noOfReportsUtilized">
						<mat-header-cell *matHeaderCellDef>
							No of Reports Utilized
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button mat-icon-button matTooltip="View Reports" color="primary" aria-label="Open View"
								(click)="openReports(element)">
								<mat-icon>summarize</mat-icon>
							</button>
							{{element.noOfReportsUtilized}}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="createdBy">
						<mat-header-cell *matHeaderCellDef>
							Created By
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-avatar [avatar]="element.createdBy">
							</app-avatar>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="createdAt">
						<mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">
							Created At
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.createdAt| date:'medium' }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="log">
						<mat-header-cell *matHeaderCellDef>
							Logs
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button matTooltip="Log" mat-icon-button color="primary" (click)="openLogs(element)">
								<mat-icon svgIcon="log"></mat-icon>
							</button>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="surveyLink">
						<mat-header-cell *matHeaderCellDef>
							Assessment Link
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button mat-icon-button matTooltip="Copy Link to Clipboard" color="primary"
								aria-label="Copy Link to Clipboard" (click)="copyShareLink(element)">
								<mat-icon>content_copy</mat-icon>
							</button>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="members">
						<mat-header-cell *matHeaderCellDef>
							Visibility
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-members-visibility
								*ngIf="(user?.role==='ADMIN' || element.org?.membership?.role==='ADMIN' || element.createdBy.id===user?.id);else noAccessTRef"
								(selectionChange)="update($event,element)" [selectedIds]="element.memberIds" [members]="element.members"
								(openedChange)="updateSelected($event,element)"></app-members-visibility>
						</mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
				</mat-table>
			</app-ng-scrollbar-wrapper>
		</div>
	</div>
</section>
<ng-template #noAccessTRef>
	N/A
</ng-template>
