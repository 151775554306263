import { StepAuthService } from "./../services/step-auth.service";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "./../services/auth.service";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse
} from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoggerService } from "../services/logger.service";
import { AmplitudeService } from "../services/amplitude.service";
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private _snackBar: MatSnackBar,
		public Logger: LoggerService,
		public router: Router,
		public authService: AuthService,
		private stepAuthService: StepAuthService,
		private _dialog: MatDialog,
		private amplitudeService: AmplitudeService
	) {}
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				let errorMsg = "";
				if (error.error instanceof ErrorEvent) {
					this.Logger.log("Client side error");
					errorMsg = `Error: ${error.error.message}`;
					this.amplitudeService.track("Client side error", error);
					this._snackBar.open(errorMsg, undefined, { duration: 10000 });
				} else {
					this.Logger.log("Server side error");
					this.amplitudeService.track("Server side error", error);
					if (error.status == 401) {
						if (error.url?.startsWith(environment.baseUrl)) {
							this._dialog.closeAll();
							this.authService.logout();
						} else if (error.url?.startsWith(environment.stepBaseUrl)) {
							this._dialog.closeAll();
							this.stepAuthService.logout();
							this.stepAuthService.login();
						}
					}
					errorMsg = `Error Code: ${error.status},  Message: ${
						error.error.message || error.message
					}`;
					this._snackBar.open(error.error.message || error.message, undefined, {
						duration: 10000
					});
				}
				this.Logger.log(errorMsg);
				return throwError(error);
			})
		);
	}
}
