<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" id="svg5" xml:space="preserve"
	xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
	<defs id="defs2" />
	<g id="layer1" transform="translate(-92.261393,-164.72001)">
		<path class="sector" [class.red]="isRed" [class.orange]="isOrange" [class.green]="isGreen" id="path8726"
			d="m 144.38794,164.73523 v 5.03483 c 24.03802,1.06943 42.98165,20.85951 42.99996,44.9213 -0.004,7.22179 -1.74695,14.33644 -5.08031,20.74292 l 4.34443,2.50838 c 3.76731,-7.17134 5.7358,-15.15064 5.73609,-23.2513 -0.002,-26.83475 -21.1869,-48.88283 -48.00017,-49.95613 z" />
		<path class="sector" [class.orange]="isOrange" [class.green]="isGreen" id="path8764"
			d="m 104.38758,238.85578 -4.32532,2.49752 c 9.15123,14.50244 25.09611,23.30345 42.24445,23.31744 17.14823,-0.001 33.09952,-8.7904 42.2615,-23.28591 l -4.3527,-2.51355 c -8.25922,12.94761 -22.55122,20.78914 -37.9088,20.79925 -15.36426,-0.01 -29.6616,-7.85813 -37.91913,-20.81475 z" />
		<path class="sector" [class.green]="isGreen" id="path8650"
			d="m 140.26156,164.72 c -26.80127,1.07282 -47.981458,23.10238 -48.00017,49.92513 0.0121,8.09102 1.98755,16.05825 5.756755,23.21771 l 4.314465,-2.49081 c -3.327636,-6.40249 -5.066865,-13.5113 -5.071008,-20.7269 0.0096,-12.63126 5.234538,-24.08536 13.673968,-32.27206 7.63717,-7.40847 17.90675,-12.14122 29.32599,-12.64925 z" />
		<path id="path9482"
			style="display:inline;opacity:1;fill:#ececee;fill-opacity:1;stroke:#b8b5b4;stroke-width:0;stroke-linecap:butt;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke markers fill"
			d="m 144.32504,169.72142 v 43.7901 l 37.91552,21.89065 a 45,45 0 0 0 5.08444,-20.7269 45,45 0 0 0 -42.99996,-44.95385 z m -4.00027,0.0248 a 45,45 0 0 0 -42.999961,44.92904 45,45 0 0 0 5.089091,20.73568 l 37.91087,-21.88807 z m 2.01021,47.23536 -37.93722,21.90305 a 45,45 0 0 0 37.9274,20.79047 45,45 0 0 0 37.93205,-20.79925 z" />
	</g>
</svg>