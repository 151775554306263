import { AbstractControl } from "@angular/forms";

export function notMatchPassword(currentPassword: string, newPassword: string) {
	return (formGroup: AbstractControl) => {
		const currentPasswordControl = formGroup?.get(currentPassword);
		const newPasswordControl = formGroup?.get(newPassword);

		if (!currentPasswordControl || !newPasswordControl) {
			return {};
		}
		if (newPasswordControl.errors && !newPasswordControl.errors.passwordMatch) {
			return {};
		}

		if (currentPasswordControl.value === newPasswordControl.value) {
			newPasswordControl.setErrors({ passwordMatch: true });
			return { passwordMatch: true };
		} else {
			newPasswordControl.setErrors(null);
			return {};
		}
	};
}
