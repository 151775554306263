import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageCroppedEvent } from "ngx-image-cropper";
type IMAGE_TYPE = "PROFILE_IMAGE" | "COVER_IMAGE";

@Component({
	selector: "app-image-cropper",
	templateUrl: "./image-cropper.component.html",
	styleUrls: ["./image-cropper.component.scss"]
})
export class ImageCropperComponent implements OnInit {
	blob?: any;
	base64?: any;
	type?: IMAGE_TYPE;
	imageChangedEvent?: Event;
	aspectRatio!: number;
	resizeToWidth!: number;
	resizeToHeight!: number;
	constructor(
		public dialogRef: MatDialogRef<ImageCropperComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { type: IMAGE_TYPE; event: any }
	) {
		({
			aspectRatio: this.aspectRatio,
			resizeToWidth: this.resizeToWidth,
			resizeToHeight: this.resizeToHeight
		} = getConfigs(this.data.type));
	}
	ngOnInit(): void {
		this.imageChangedEvent = this.data.event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.base64 = event.base64;
		this.blob = this.dataURItoBlob(event.base64);
	}
	imageLoaded() {
		// show cropper
	}
	cropperReady() {
		// cropper ready
	}
	loadImageFailed() {
		// show message
	}
	dataURItoBlob(dataURI: any) {
		// console.log(dataURI);
		const binary = atob(dataURI.split(",")[1]);
		const array = [];
		for (let i = 0; i < binary.length; i++) {
			array.push(binary.charCodeAt(i));
		}
		return new Blob([new Uint8Array(array)], {
			type: "image/png"
		});
	}

	save() {
		this.dialogRef.close({
			base64: this.base64,
			blob: this.blob
		});
	}

	close() {
		this.dialogRef.close();
	}
}
function getConfigs(type: IMAGE_TYPE) {
	switch (type) {
		case "PROFILE_IMAGE":
			return {
				aspectRatio: 1 / 1,
				resizeToWidth: 125,
				resizeToHeight: 125
			};
		case "COVER_IMAGE":
			return {
				aspectRatio: 5 / 3,
				resizeToWidth: 400,
				resizeToHeight: 240
			};
		default:
			return {
				aspectRatio: 1 / 1,
				resizeToWidth: 125,
				resizeToHeight: 125
			};
	}
}
