import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { CHARACTER360_STEPS } from "src/app/_shared/const/character360";
import { AutoUnsubscribeOnDestroy } from "src/app/_shared/decorators/autoUnsubscribeOnDestroy";
import { phoneNumberValidator } from "src/app/_shared/form/validator/phone-number";
import { AuthService } from "src/app/_shared/services/auth.service";
import { Character360, Step } from "src/app/_shared/types/character360";
import { USER } from "src/app/_shared/types/user";
import { addMonths } from "src/app/_shared/utils/date";
import { getSurveyURL } from "src/app/_shared/utils/string";
import { Character360Service } from "../../../_shared/services/character360.service";
import { MobileNumberChangeEvent } from "../../types/common";
import { AccountService } from "../../services/account.service";
import { Character360OverviewComponent } from "../character360-overview/character360-overview.component";

@Component({
	selector: "app-my-character360",
	templateUrl: "./my-character360.component.html",
	styleUrls: ["./my-character360.component.scss"],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false }
		}
	]
})
@AutoUnsubscribeOnDestroy()
export class MyCharacter360Component implements OnInit {
	readonly loaderId = "my-character-360-loader";
	readonly steps: Step[] = CHARACTER360_STEPS;
	@ViewChild("stepper") stepper!: MatStepper;
	@ViewChild(`pageRef`) pageRef!: ElementRef;
	@ViewChild(`overview`) overviewRef!: Character360OverviewComponent;
	onlyOverview = false;
	intro = true;
	subscriptions = new Subscription();
	id?: number;
	reportId?: number;
	infoFG!: FormGroup;
	isInfoCompleted = false;
	currentStepIndex = 0;
	character360Saved?: Character360;
	report?: any;
	user!: USER | null;
	isMobileNumberVerified = false;
	sendInvitesStep: number = 0;
	isPartner = false;
	get character360(): Character360 {
		return this.character360Saved || this.infoFG.value;
	}
	get expiresAt() {
		let createdAt = new Date(this.character360.report.createdAt);
		return addMonths(createdAt, 6);
	}
	constructor(
		private fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private character360Service: Character360Service,
		private UiLoaderService: NgxUiLoaderService,
		private authService: AuthService,
		private accountService: AccountService
	) {
		this.infoFG = this.fb.group({
			name: [, Validators.required],
			organisationName: [, Validators.required],
			mobileNo: [, phoneNumberValidator()]
		});
		this.activatedRoute.paramMap.subscribe(params => {
			if (params.has("id")) {
				this.id = params.get("id") as unknown as number;
			}
			if (params.has("reportId")) {
				this.reportId = params.get("reportId") as unknown as number;
			}
		});
		this.activatedRoute.data.subscribe(data => {
			if (data.isPartner) {
				this.isPartner = data.isPartner;
			}
		});
	}
	ngOnInit(): void {
		this.getCharacter360();
		this.subscriptions.add(
			this.authService.getUserInfo().subscribe(user => {
				this.user = user;
				if (!this.infoFG.value.mobileNo) {
					this.infoFG.patchValue({
						mobileNo: this.user?.mobileNo
					});
				}
			})
		);
	}

	getCharacter360() {
		if (this.id || this.reportId) {
			this.UiLoaderService.startLoader(this.loaderId);
			this.character360Service
				.get({ id: this.id, reportId: this.reportId })
				.subscribe((res: any) => {
					if (res.statusCode === 200) {
						this.report = res.report;
						if (res.character360) {
							this.character360Saved = res.character360;
							this.id = res.character360.id;
							this.infoFG.patchValue(res.character360);
							this.isInfoCompleted = true;
							this.onlyOverview = true;
						} else {
							this.infoFG.patchValue({
								name: this.report.name,
								mobileNo: this.report.mobile,
								organisationName: this.report.organisation
							});
						}
						this.UiLoaderService.stopLoader(this.loaderId);
					}
				});
		}
	}
	goto(index: -1 | 0 | 1 | 2) {
		this.UiLoaderService.startLoader(this.loaderId);
		if (index > -1) {
			this.start360();
			setTimeout(() => {
				if (this.stepper.selectedIndex < index) {
					while (this.stepper.selectedIndex < index) {
						this.stepper.next();
					}
				} else if (this.stepper.selectedIndex > index) {
					while (this.stepper.selectedIndex > index) {
						this.stepper.previous();
					}
				}
				this.UiLoaderService.stopLoader(this.loaderId);
			}, 1);
		} else {
			this.startIntro();
			this.UiLoaderService.stopLoader(this.loaderId);
		}
		this.scrollToTop();
	}
	next() {
		if (this.sendInvitesStep < 5) {
			this.overviewRef.focusHandle(this.steps[this.sendInvitesStep].id);
			setTimeout(() => {
				this.sendInvitesStep++;
			}, 800);
		}
	}
	back() {
		if (this.sendInvitesStep > 0) {
			this.overviewRef.focusHandle(this.steps[this.sendInvitesStep - 1].id);
			setTimeout(() => {
				this.sendInvitesStep--;
			}, 800);
		}
	}
	scrollToTop() {
		this.pageRef?.nativeElement.scrollIntoView({
			behavior: "smooth",
			block: "start"
		});
	}
	mobileNoVerifiedEvent(event: MobileNumberChangeEvent) {
		this.isMobileNumberVerified = event.isMobileNoVerified;
		if (event.isMobileNoVerified && event.canGoNext) {
			this.submit();
		}
	}
	async submit() {
		if (this.infoFG.value.mobileNo && !this.isMobileNumberVerified) {
			let res =
				await this.character360Service.confirmIfContinueWithoutVerifying();
			if (!res) return;
		}
		if (this.infoFG?.valid) {
			if (!this.id) {
				this.UiLoaderService.startLoader(this.loaderId);
				let payload = this.infoFG.value;
				payload.isMobileNoVerified = this.isMobileNumberVerified;
				payload.reportId = this.reportId;
				this.character360Service.post(payload).subscribe({
					next: (res: any) => {
						if (res.statusCode === 200) {
							this.character360Saved = res.character360;
							this.id = this.character360Saved?.id;
							this.authService.setUserInfoFromGetAccount();
							this.isInfoCompleted = true;
							this.stepper.next();
							this.UiLoaderService.stopLoader(this.loaderId);
							this.scrollToTop();
						}
					},
					error: () => {
						this.UiLoaderService.stopLoader(this.loaderId);
					}
				});
			} else {
				this.UiLoaderService.startLoader(this.loaderId);
				let payload = this.infoFG.value;
				payload.reportId = this.reportId;
				payload.isMobileNoVerified = this.isMobileNumberVerified;
				this.character360Service.patch(this.id, payload).subscribe({
					next: (res: any) => {
						if (res.statusCode === 200) {
							this.character360Saved = res.character360;
							this.isInfoCompleted = true;
							this.stepper.next();
							this.UiLoaderService.stopLoader(this.loaderId);
							this.scrollToTop();
						}
					},
					error: () => {
						this.UiLoaderService.stopLoader(this.loaderId);
					}
				});
			}
		}
	}
	start360() {
		this.intro = false;
	}
	startIntro() {
		this.intro = true;
	}
	complete() {
		this.onlyOverview = true;
		this.sendInvitesStep = 0;
	}
	async openC360InNewTab() {
		const surveyURL = getSurveyURL();
		let path = `${surveyURL}/character-360/report/${this.character360.UUIDV4}`;
		if (this.isPartner) {
			const auth = await this.accountService.getSinglePurposeToken({
				character360UUIDV4: this.character360.UUIDV4
			});
			path += `?isPartner=true&authorization=${auth.token}&authType=${auth.type}`;
		}
		window.open(path);
	}
	copyToClipboard(step: Step) {
		this.character360Service.copyToClipboard(this.character360, step);
	}
	copyLinkToClipboard(step: Step) {
		this.character360Service.copyLinkToClipboard(this.character360, step);
	}
	openEmailClient(step: Step) {
		this.character360Service.openEmailClient(this.character360, step);
	}
	async sendSMS(step: Step) {
		let result = await this.character360Service.sendSMSIfCanSend(
			this.character360,
			step
		);
		if (result?.refresh) {
			this.character360Saved = result.character360;
		}
	}
	edit() {
		this.onlyOverview = false;
		this.goto(1);
	}
	updatedCharacter360(character360: any) {
		this.character360Saved = character360;
	}
}
