<section class="flex justify-start align-center gap-20">
	<div class="flex  justify-start align-center  gap-4 full-width">
		<div class="org-select-label" *ngIf="showOrgSelectedLabel">
			Organisation Selected:
		</div>
		<div style="position: relative;" class="full-width">
			<app-loader [loaderId]="orgSelectLoaderId" [bgsSize]="28" [fgsSize]="28"></app-loader>
			<mat-action-list style="padding-top: 0;">
				<mat-list-item (click)="openIfEditable()">
					<app-avatar *ngIf="selectedOrg;else allRef" [fxInline]="true" [avatar]="selectedOrg"
						[showOrgRole]="showOrgRole" forceType="GROUP_PROFILE">
					</app-avatar>
					<ng-template #allRef>
						<ng-container *ngIf="showAll;else chooseOneRef">
							<span *ngIf="organisations?.length">
								<app-avatars [avatars]="organisations" [total]="total">
								</app-avatars>
							</span> &nbsp;
							<small>Showing All</small>
						</ng-container>
						<ng-template #chooseOneRef>
							<div class="full-width">
								<mat-error *ngIf="error"> Please Choose a Organisation</mat-error>
								<mat-hint *ngIf="!error"> Choose a Organisation </mat-hint>
							</div>
						</ng-template>
					</ng-template>
					<mat-icon *ngIf="!(readOnly || forceReadOnly)">
						{{selectOrgTrigger?.menuOpen? "expand_less":"expand_more"}}
					</mat-icon>
					<span *ngIf="!(readOnly || forceReadOnly)" [matMenuTriggerFor]="selectOrg" class="menu-trigger"></span>
				</mat-list-item>
			</mat-action-list>
		</div>
	</div>
	<!-- <span class="ca-spacer"></span> -->
	<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
		<div *ngIf="selectedOrg && showAvailableCredits && showAvailableCreditsLabel">
			Credits Available:
		</div>
		<div style="padding-top: 0;" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
			<div class="wallet-balances" *ngIf="selectedOrg && showAvailableCredits" fxLayout="row"
				fxLayoutAlign="start center" fxLayoutGap="8px">
				<app-loader [loaderId]="loaderId" [bgsSize]="28" [fgsSize]="28"></app-loader>
				<div class="wallet-balance-container" fxLayout="column" fxLayoutAlign="start center" matTooltip="Paid Credits">
					<span class="wallet-balance"> {{selectedOrg?.wallet?.balance|number }} </span>
					<span class="wallet-balance-type">Paid</span>
				</div>
				<div class="ca-divider" *ngIf="selectedOrg?.typeOf==='PARTNER'">
				</div>
				<div class="wallet-balance-container" fxLayout="column" fxLayoutAlign="start center" matTooltip="Seeded Credits"
					*ngIf="selectedOrg?.typeOf==='PARTNER'">
					<span class="wallet-balance"> {{selectedOrg?.wallet?.seededBalance|number }} </span>
					<span class="wallet-balance-type">Seeding</span>
				</div>
			</div>
			<div
				*ngIf="showQuickBuy && (selectedOrg?.membership?.role==='ADMIN' || !selectedOrg || this.authService.user?.role === 'ADMIN')">
				<button mat-flat-button color="primary" (click)="openBuyDialog()">Buy Credits</button>
			</div>
		</div>
	</div>
</section>
<mat-menu #selectOrg="matMenu" xPosition="before" (closed)="closedMenu($event)">
	<div class="infinite-scroll" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="0"
		(scrolled)="onScroll()" [scrollWindow]="false">
		<mat-form-field class="full-width accent-bg ca-layout ca-sticky top" style="padding:0 16px;z-index: 1;"
			(click)="$event.stopPropagation()">
			<mat-label>Filter</mat-label>
			<input matInput placeholder="Ex. ShareTree" #input [formControl]="filterFC">
			<button matSuffix mat-icon-button (click)="filterFC.setValue('')">
				<mat-icon *ngIf="filterFC.value" aria-label="Clear">close</mat-icon>
				<app-loader [loaderId]="orgFilterLoaderId" [bgsSize]="24" bgsPosition="center-center"></app-loader>
			</button>
		</mat-form-field>
		<button mat-menu-item class="text-centered-button" [ngClass]="{'active':selectedOrg===null}"
			*ngIf="showAll && !filterFC.value && (organisations?.length||0)>1" (click)="select(null)">
			Show All
		</button>
		<ng-container>
			<button mat-menu-item [ngClass]="{'active':selectedOrg?.id===org.id}" *ngFor="let org of organisations"
				(click)="select(org)" [disabled]="disableMemberOrg && org.membership?.role==='MEMBER'">
				<app-avatar size="32px" [avatar]="org" [showOrgRole]="showOrgRole" forceType="GROUP_PROFILE">
				</app-avatar>
			</button>
		</ng-container>
		<div class="text-center">
			<mat-hint *ngIf="!isLoading">
				<ng-container *ngIf=" (organisations?.length||0)<total;">
					Scroll down to load more
				</ng-container>
				<ng-container *ngIf="(organisations?.length||0)===0;">
					No Match Found for <b>{{filterFC.value}}</b>
				</ng-container>
			</mat-hint>
		</div>
		<div class="ca-list-loader">
			<app-loader [loaderId]="orgSelectListLoaderId" [bgsSize]="28" [fgsSize]="28"
				bgsPosition="center-center"></app-loader>
		</div>
	</div>
</mat-menu>
