import { formatDate } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { saveAs } from "file-saver";
import { environment } from "src/environments/environment";
import {
	isNotNull,
	isNotNullAndUndefined,
	removeUndefined
} from "../utils/object";
import { queryParams } from "./../types/common";

interface ReportQueryParamsOnly {
	partnerSurveyId?: number;
	orgId?: number;
	isOnlyUnpackRequired?: boolean;
}
interface ReportQueryParams extends queryParams, ReportQueryParamsOnly {}
interface ReportOptions {
	group?: any;
	role?: any;
	compReport?: any;
	compReport2?: any;
	compGroupReport?: any;
	compGroupReport2?: any;
	previewOnly?: boolean;
}
interface ReportDownload extends ReportOptions {
	report: any;
	piId?: string;
}
interface shareReport extends ReportOptions {
	report?: any;
	emailIds: string[];
}

@Injectable({
	providedIn: "root"
})
export class ReportService {
	baseURL = environment.baseUrl;

	constructor(
		public http: HttpClient,
		@Inject(LOCALE_ID) private locale: string
	) {}
	getAll(queryParams: ReportQueryParams) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...queryParams,
					sortBy: queryParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get(`${this.baseURL}/report`, { params });
	}
	get(reportUUIDV4: string, queryParams?: any) {
		const params = new HttpParams({
			fromObject: removeUndefined(queryParams ?? {})
		});
		return this.http.get(`${this.baseURL}/report/${reportUUIDV4}`, { params });
	}

	async download({
		report,
		group,
		role,
		compReport,
		compReport2,
		compGroupReport,
		compGroupReport2,
		piId,
		previewOnly
	}: ReportDownload) {
		return new Promise<void>((resolve, reject) => {
			let filename: any = [report.name];

			let paramsObject: any = {};
			if (isNotNullAndUndefined(compGroupReport?.UUIDV4)) {
				paramsObject.compGroupReportUUIDV4 = compGroupReport.UUIDV4;
				filename.push(compGroupReport?.name);
			}
			if (isNotNullAndUndefined(compGroupReport2?.UUIDV4)) {
				paramsObject.compGroupReport2UUIDV4 = compGroupReport2.UUIDV4;
				filename.push(compGroupReport2?.name);
			}
			if (isNotNullAndUndefined(compReport?.UUIDV4)) {
				paramsObject.compReportUUIDV4 = compReport.UUIDV4;
				filename.push(compReport?.name);
			}
			if (isNotNullAndUndefined(compReport2?.UUIDV4)) {
				paramsObject.compReport2UUIDV4 = compReport2.UUIDV4;
				filename.push(compReport2?.name);
			}
			if (isNotNullAndUndefined(group?.id)) {
				paramsObject.groupId = group.id;
				filename.push(group?.name);
			}
			if (isNotNullAndUndefined(role?.id)) {
				paramsObject.roleId = role.id;
				filename.push(role?.name);
			}
			if (isNotNullAndUndefined(piId)) {
				paramsObject.piId = piId;
			}
			paramsObject.previewOnly = previewOnly || false;
			filename = filename
				.map((s: any) => s && s.trim())
				.filter(isNotNull)
				.join("_");
			const params = new HttpParams({
				fromObject: removeUndefined(paramsObject)
			});
			this.http
				.get(`${this.baseURL}/report/download/${report.UUIDV4}`, {
					params,
					responseType: "blob"
				})
				.subscribe(
					res => {
						let yearMonth = formatDate(Date.now(), "yyyy MM", this.locale);
						saveAs(res, `${yearMonth} Charametrics Report - ${filename}`);
						resolve();
					},
					err => {
						reject(err);
					}
				);
		});
	}

	shareReport({
		emailIds,
		report,
		group,
		role,
		compReport,
		compReport2,
		compGroupReport,
		compGroupReport2,
		previewOnly
	}: shareReport) {
		let payload: any = {
			emailIds
		};
		if (isNotNullAndUndefined(report?.UUIDV4)) {
			payload.reportUUIDV4 = report.UUIDV4;
		}
		if (isNotNullAndUndefined(compReport?.UUIDV4)) {
			payload.compReportUUIDV4 = compReport.UUIDV4;
		}
		if (isNotNullAndUndefined(compReport2?.UUIDV4)) {
			payload.compReport2UUIDV4 = compReport2.UUIDV4;
		}
		if (isNotNullAndUndefined(group?.id)) {
			payload.groupId = group.id;
		}
		if (isNotNullAndUndefined(role?.id)) {
			payload.roleId = role.id;
		}
		if (isNotNullAndUndefined(compGroupReport?.UUIDV4)) {
			payload.compGroupReportUUIDV4 = compGroupReport.UUIDV4;
		}
		if (isNotNullAndUndefined(compGroupReport2?.UUIDV4)) {
			payload.compGroupReport2UUIDV4 = compGroupReport2.UUIDV4;
		}
		if (isNotNullAndUndefined(previewOnly)) {
			payload.previewOnly = previewOnly;
		}
		return this.http.post(`${this.baseURL}/report/share`, payload);
	}
	patchReportDefaults(id: string, payload: any) {
		return this.http.patch(`${this.baseURL}/report/defaults/${id}`, payload);
	}
	payForReport(payload: { reportId: number }) {
		return this.http.post(`${this.baseURL}/report/pay`, payload);
	}
	getAllocatedUnpackSession(reportId: number, params?: any) {
		return this.http.get(`${this.baseURL}/report/${reportId}/unpack_session`, {
			params
		});
	}
	allocateUnpackSession(payload: any) {
		return this.http.post(`${this.baseURL}/report/unpack_session`, payload);
	}
	getUnpackRequiredCount(queryParams: ReportQueryParamsOnly) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...queryParams
				} ?? {}
			)
		});
		return this.http.get(`${this.baseURL}/report/unpack_required_count`, {
			params
		});
	}
	addReportToUser(reportId: number) {
		return this.http.post(`${this.baseURL}/report/add_to_user`, {
			reportId
		});
	}
	linkReportToUser(payload: { reportId: number; userId: number }) {
		return this.http.post(`${this.baseURL}/report/link_to_user`, payload);
	}
	linkReportToUserByEmailId(payload: { reportId: number; emailId: string }) {
		return this.http.post(`${this.baseURL}/report/link_to_user_email`, payload);
	}
	migrateReport(payload: { reportId: number; partnerSurveyId: number }) {
		return this.http.post(`${this.baseURL}/report/migrate`, payload);
	}
}
