import { Component, Input } from "@angular/core";
import { ARCHETYPES } from "../../../types/archetypes";
import { CharacterArchetypeDescription } from "src/app/_shared/types/character360";

@Component({
	selector: "app-archetype-description",
	templateUrl: "./archetype-description.component.html",
	styleUrls: ["./archetype-description.component.scss"]
})
export class ArchetypeDescriptionComponent {
	ARCHETYPES = ARCHETYPES;
	@Input() first: boolean = false;
	@Input() single: boolean = false;
	@Input() pageNumber!: number;
	@Input() archetype!: CharacterArchetypeDescription;

	archetyperows = [
		{ heading: "Of Self", subheading: "Self-perspective" },
		{ heading: "Of Others", subheading: "What I see in others" },
		{ heading: "Others of Me", subheading: "What others see in me" },
		{ heading: "Relationships", subheading: "How my relationships are seen" },
		{ heading: "My Environment", subheading: "Ecosystem" },
		{ heading: "My Belief", subheading: "Introspection and belief" }
	];
	constructor() {}
}
