<mat-form-field *ngIf="fg" style="width: 100%;" [formGroup]="fg" >
	<mat-label>Select members on the app</mat-label>
	<mat-select [formControl]="FC" [multiple]="multiple" [compareWith]="compareFn">

		<mat-select-trigger>
			{{FC?.value?.length||0}} User{{FC?.value?.length>1?"s":''}} Selected
		</mat-select-trigger>
		<mat-option>
			<ngx-mat-select-search [clearSearchInput]="false" [formControl]="filterFC"
				[noEntriesFoundLabel]="'No matching Found'" placeholderLabel="Type to search for members on the app">
			</ngx-mat-select-search>
		</mat-option>
		<mat-option *ngFor="let item of filtered | async" [value]="item">
			<app-avatar [avatar]="item" forceType="STEP_USER_PROFILE">
			</app-avatar>
		</mat-option>
	</mat-select>
	<button *ngIf="FC?.value" matSuffix color="accent" mat-icon-button aria-label="Clear"
		(click)="clearSelection($event)">
		<mat-icon>close</mat-icon>
	</button>
</mat-form-field>
