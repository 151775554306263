import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { removeUndefined } from "../utils/object";
import { queryParams } from "./../types/common";
interface RoleQueryParams extends queryParams {
	orgId?: number;
}
@Injectable({
	providedIn: "root"
})
export class RoleService {
	baseURL = environment.baseUrl;
	constructor(public http: HttpClient) {}
	getById(id: string, OrgId: string) {
		return this.http.get<any>(`${this.baseURL}/role/${OrgId}/${id}`);
	}
	get(RoleQueryParams: RoleQueryParams = {}) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...RoleQueryParams,
					sortBy: RoleQueryParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get<any>(`${this.baseURL}/role`, { params });
	}
	create(payload: any) {
		return this.http.post<any>(`${this.baseURL}/role`, payload);
	}
	update(payload: any) {
		return this.http.put<any>(`${this.baseURL}/role/${payload.id}`, payload);
	}
	delete(id: number) {
		return this.http.delete<any>(`${this.baseURL}/role/${id}`);
	}
}
