import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from "@angular/material/dialog";
import { map } from "lodash";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { AutoUnsubscribeOnDestroy } from "src/app/_shared/decorators/autoUnsubscribeOnDestroy";
import { LoggerService } from "src/app/_shared/services/logger.service";
import { ReportService } from "src/app/_shared/services/report.service";
import { getSurveyURL } from "src/app/_shared/utils/string";
import { AccountService } from "../../services/account.service";
import { GroupReportService } from "../../services/group-report.service";
import { COMPARISON_TYPE } from "../../types/common";
import { ShareReportComponent } from "../share-report/share-report.component";

@Component({
	selector: "app-dual-comparison",
	templateUrl: "./dual-comparison.component.html",
	styleUrls: ["./dual-comparison.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class DualComparisonComponent {
	subscriptions = new Subscription();
	downloadButtonLoaderId = "download-loader";
	groupFG!: FormGroup;
	roleFG!: FormGroup;
	reportCtrl: FormControl = new FormControl(null, Validators.required);
	report2Ctrl: FormControl = new FormControl(null, Validators.required);
	hideReportIds1!: string[];
	hideReportIds2!: string[];
	groupReportCtrl: FormControl = new FormControl(null, Validators.required);
	groupReport2Ctrl: FormControl = new FormControl(null, Validators.required);
	hideGroupReportIds1!: string[];
	hideGroupReportIds2!: string[];
	comparison1: COMPARISON_TYPE = "TARGET";
	comparison2: COMPARISON_TYPE = "TARGET";
	get comparison1Keys() {
		switch (this.comparison1) {
			case "TARGET":
				return {
					key: "groupId",
					field: "group",
					fg: this.groupFG,
					id: this.groupFG.value?.id,
					isValid: this.groupFG.value?.id
				};
			case "GROUP":
				return {
					key: "compGroupReportUUIDV4",
					field: "compGroupReport",
					fg: this.groupReportCtrl,
					id: this.groupReportCtrl.value?.UUIDV4,
					isValid: this.groupReportCtrl.valid
				};
			case "USER":
				return {
					key: "compReportUUIDV4",
					field: "compReport",
					fg: this.reportCtrl,
					id: this.reportCtrl.value?.UUIDV4,
					isValid: this.reportCtrl.valid
				};
		}
	}
	get comparison2Keys() {
		switch (this.comparison2) {
			case "TARGET":
				return {
					key: "roleId",
					field: "role",
					fg: this.roleFG,
					id: this.roleFG.value?.id,
					isValid: this.roleFG.value?.id
				};
			case "GROUP":
				return {
					key: "compGroupReport2UUIDV4",
					field: "compGroupReport2",
					fg: this.groupReport2Ctrl,
					id: this.groupReport2Ctrl?.value?.UUIDV4,
					isValid: this.groupReport2Ctrl?.valid
				};
			case "USER":
				return {
					key: "compReport2UUIDV4",
					field: "compReport2",
					fg: this.report2Ctrl,
					id: this.report2Ctrl?.value?.UUIDV4,
					isValid: this.report2Ctrl?.valid
				};
		}
	}
	get isComparison1Valid() {
		return this.comparison1Keys?.isValid;
	}
	get isComparison2Valid() {
		return this.comparison2Keys?.isValid;
	}

	get isInvalid() {
		return !(this.isComparison1Valid && this.isComparison2Valid);
	}
	get reportIds() {
		return map(this.data.reports, "UUIDV4");
	}
	constructor(
		public dialogRef: MatDialogRef<DualComparisonComponent>,
		private dialog: MatDialog,
		public reportService: ReportService,
		public groupReportService: GroupReportService,
		private UiLoaderService: NgxUiLoaderService,
		public Logger: LoggerService,
		private accountService: AccountService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.setHideReportIds1();
		this.setHideReportIds2();
		this.subscriptions.add(
			this.reportCtrl.valueChanges.subscribe(res => {
				this.setHideReportIds2();
			})
		);
		this.subscriptions.add(
			this.report2Ctrl.valueChanges.subscribe(res => {
				this.setHideReportIds1();
			})
		);
		this.setHideGroupReportIds1();
		this.setHideGroupReportIds2();
		this.subscriptions.add(
			this.groupReportCtrl.valueChanges.subscribe(res => {
				this.setHideGroupReportIds2();
			})
		);
		this.subscriptions.add(
			this.groupReport2Ctrl.valueChanges.subscribe(res => {
				this.setHideGroupReportIds1();
			})
		);
	}
	setHideReportIds1() {
		this.hideReportIds1 = [
			this.report2Ctrl?.value?.UUIDV4,
			this.data.gro?.UUIDV4
		];
	}
	setHideReportIds2() {
		this.hideReportIds2 = [
			this.reportCtrl?.value?.UUIDV4,
			this.data.report?.UUIDV4
		];
	}
	setHideGroupReportIds1() {
		this.hideGroupReportIds1 = [
			this.groupReport2Ctrl?.value?.UUIDV4,
			this.data.groupReport?.UUIDV4
		];
	}
	setHideGroupReportIds2() {
		this.hideGroupReportIds2 = [
			this.groupReportCtrl?.value?.UUIDV4,
			this.data.groupReport?.UUIDV4
		];
	}
	onGroupFG($event: FormGroup) {
		this.groupFG = $event;
	}
	onRoleFg($event: FormGroup) {
		this.roleFG = $event;
	}
	async openInNewTab() {
		const surveyURL = getSurveyURL();
		let auth;
		let url = `${surveyURL}/report`;
		switch (this.data.type) {
			case "INDIVIDUAL":
				url += `/${this.data.report.UUIDV4}?isPartner=true`;
				auth = await this.accountService.getSinglePurposeToken({
					reportUUIDV4: this.data.report.UUIDV4
				});
				break;
			case "GROUP":
				url += `/group/${this.data.groupReport.UUIDV4}?`;
				auth = await this.accountService.getSinglePurposeToken({
					groupReportUUIDV4: this.data.groupReport.UUIDV4
				});
				break;
		}
		url += `&authorization=${auth?.token}&authType=${auth?.type}`;
		let comp1 = this.comparison1Keys;
		let comp2 = this.comparison2Keys;
		url += `&${comp1.key}=${comp1.id}`;
		url += `&${comp2.key}=${comp2.id}`;
		window.open(url);
	}
	getPayload() {
		let payload: any = {};
		let comp1 = this.comparison1Keys;
		let comp2 = this.comparison2Keys;
		payload[comp1.field] = comp1.fg.value;
		payload[comp2.field] = comp2.fg.value;
		return payload;
	}
	async downloadReport() {
		try {
			this.UiLoaderService.startLoader(this.downloadButtonLoaderId);
			let payload = this.getPayload();
			switch (this.data.type) {
				case "INDIVIDUAL":
					await this.reportService.download({
						report: this.data.report,
						...payload
					});
					break;
				case "GROUP":
					await this.groupReportService.download({
						groupReport: this.data.groupReport,
						...payload
					});
					break;
			}
		} catch (error) {
			this.Logger.error(error);
		} finally {
			this.UiLoaderService.stopLoader(this.downloadButtonLoaderId);
		}
	}
	openShareReport() {
		let payload = this.getPayload();
		this.dialog.open(ShareReportComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			data: {
				report: this.data.report,
				groupReport: this.data.groupReport,
				...payload
			}
		});
	}
}
