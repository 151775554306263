export const COST_PER_USER_PER_MONTH_TIERS = [
	{ upTo: 10, unitAmount: 3 },
	{ upTo: 100, unitAmount: 2.5 },
	{ upTo: 1000, unitAmount: 2 },
	{ upTo: Infinity, unitAmount: 1 }
];

export const getCostPerUserPerMonth = (noOfUsers: number): number => {
	for (let tier of COST_PER_USER_PER_MONTH_TIERS) {
		if (noOfUsers <= tier.upTo) return tier.unitAmount;
	}
	return COST_PER_USER_PER_MONTH_TIERS[COST_PER_USER_PER_MONTH_TIERS.length - 1]
		.unitAmount;
};
