<section class="ca-archetype-descriptions">
	<div class="ca-print-page ">
		<app-anchor *ngIf="first" anchorId="YCSG3"></app-anchor>
		<app-anchor *ngIf="single" anchorId="YCSG3@1"></app-anchor>
		<div class="ca-archetype-contents  ca-page-content ca-page-margins ca-top ca-right">
			<div class="ca-archetype-header ca-page-margins ca-left ">
				<div class="ca-archetype-icon-container">
					<img class="ca-archetype-icon"
						[src]="'/assets/images/character-strengths/'+ archetype.characterStrength+ '.png'" alt="">
				</div>
				<div class="ca-archetype-title-container">
					<div [class]="['ca-archetype-title','ca-color-'+archetype.characterStrength]">
						{{ARCHETYPES[archetype.characterStrength].title}}
					</div>
					<div class="ca-archetype-subtitle">
						{{ARCHETYPES[archetype.characterStrength].subtitle}}
					</div>
				</div>
			</div>
			<div class="ca-archetype-content">
				<div [class]="['ca-archetype-content-heading','ca-bg-color-'+archetype.characterStrength]">
					Qualities/Potential
				</div>
				<div class="ca-archetype-content-body">
					{{ARCHETYPES[archetype.characterStrength].qualitiesOrPotential.description}}
					<table class="ca-archetype-desc-table">
						<tr>
							<th class="ca-professional">Professional</th>
							<td class="ca-bg">
								{{ARCHETYPES[archetype.characterStrength].qualitiesOrPotential.professional}}</td>
						</tr>
						<tr>
							<th class="ca-social">Social</th>
							<td class="ca-bg">
								{{ARCHETYPES[archetype.characterStrength].qualitiesOrPotential.social}}</td>
						</tr>
						<tr>
							<th class="ca-spiritual">Spiritual</th>
							<td class="ca-bg">
								{{ARCHETYPES[archetype.characterStrength].qualitiesOrPotential.spiritual}}</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="ca-archetype-content">
				<div [class]="['ca-archetype-content-heading','ca-bg-color-'+archetype.characterStrength]">
					Challenge
				</div>
				<div class="ca-archetype-content-body">
					<b>The Under-evolved </b>- {{ARCHETYPES[archetype.characterStrength].challenge.underEvolved}}
				</div>
				<div class="ca-archetype-content-body">
					<b>The Over-utilised </b>- {{ARCHETYPES[archetype.characterStrength].challenge.overUtilised}}
				</div>
			</div>
			<div class="ca-archetype-content">
				<div [class]="['ca-archetype-content-heading','ca-bg-color-'+archetype.characterStrength]">
					Thriving
				</div>
				<div class="ca-archetype-content-body">
					{{ARCHETYPES[archetype.characterStrength].thriving}}
				</div>
			</div>
		</div>
		<app-page-footer [pageNo]="pageNumber"></app-page-footer>
	</div>
	<div class="ca-print-page ca-not-print">
		<div class="ca-archetype-contents  ca-page-content  ca-page-margin">
			<div class="ca-sub-heading font-family-raleway">
				{{ARCHETYPES[archetype.characterStrength].title}} -
				{{ARCHETYPES[archetype.characterStrength].subtitle}}
			</div>
			<table class="ca-character-archetype-table">
				<tbody>
					<tr>
						<td></td>
						<td
							[class]="['ca-character-archetype-table-header1','ca-border-right','ca-bg-light1-color-'+archetype.characterStrength]">
							Potential Triggers</td>
						<td colspan="2"
							[class]="['ca-character-archetype-table-header1','ca-bg-light1-color-'+archetype.characterStrength]">
							Utilisation of Strength </td>
					</tr>
					<tr>
						<td
							[class]="['ca-character-archetype-table-header','ca-border-top','ca-border-right-light','ca-bg-color-'+archetype.characterStrength]">
							Perspective</td>
						<td
							[class]="['ca-character-archetype-table-header','ca-border-top','ca-border-right','ca-bg-color-'+archetype.characterStrength]">
							Underutilised*</td>
						<td
							[class]="['ca-character-archetype-table-header','ca-border-top','ca-border-right-light','ca-bg-color-'+archetype.characterStrength]">
							Thriving</td>
						<td
							[class]="['ca-character-archetype-table-header','ca-border-top','ca-bg-color-'+archetype.characterStrength]">
							Over-utilised</td>
					</tr>
					<tr *ngFor="let row of archetyperows; let i=index;let last = last;let first = first">
						<td
							class="{{'ca-character-archetype-table-first-col '+'ca-border-right '+'ca-bg-light2-color-'+archetype.characterStrength}}"
							[ngClass]="{'ca-border-top-light':!first,'ca-border-top':first }">
							<span class="header"> {{row.heading}}</span>
							<br>
							{{row.subheading}}
						</td>
						<td class="ca-character-archetype-table-data-col ca-border-top-light"
							[ngClass]="{'ca-border-right':ci===0,'ca-border-right-light':ci!==0,'ca-border-top-light':!first,'ca-border-top':first}"
							*ngFor="let col of ARCHETYPES[archetype.characterStrength].table[i];let ci=index;">
							{{col}}
						</td>
					</tr>
				</tbody>
			</table>
			<br>
			<span class="starred-info">
				*When this strength is underutilised by others, it can cause a potential trigger in you
			</span>
		</div>
	</div>
</section>
