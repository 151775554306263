import { FileService } from "./../services/file.service";
import { Pipe, PipeTransform } from "@angular/core";
import { FILE_SRC } from "../types/common";

@Pipe({
	name: "filepath"
})
export class FilepathPipe implements PipeTransform {
	constructor(private fileService: FileService) {}
	transform(value: string | null | undefined, src: FILE_SRC = "CA"): string {
		if (!value) return "";
		return this.fileService.generateFilePath(value, src) || "";
	}
}
