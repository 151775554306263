import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { OrganisationComponent } from "./../_shared/components/organisation/organisation.component";
import { AccountService } from "./../_shared/services/account.service";
import { AuthService } from "./../_shared/services/auth.service";
import { FileService } from "./../_shared/services/file.service";
@Component({
	selector: "app-invite",
	templateUrl: "./invite.component.html",
	styleUrls: ["./invite.component.scss"]
})
export class InviteComponent {
	inviteToken!: string;
	tokenStatus!: "VALID" | "INVALID" | "WITHDRAWN" | "NOT_FOUND";
	isJoinOrgInvite!: boolean;
	org!: any;
	constructor(
		private UiLoaderService: NgxUiLoaderService,
		private activatedRoute: ActivatedRoute,
		private accountService: AccountService,
		private authService: AuthService,
		private router: Router,
		public dialog: MatDialog,
		public fileService: FileService
	) {
		this.activatedRoute.paramMap.subscribe(params => {
			if (params.has("inviteToken")) {
				this.inviteToken = params.get("inviteToken") as string;
				this.verifyInvite();
			} else {
				this.tokenStatus = "INVALID";
			}
		});
	}
	verifyInvite() {
		this.UiLoaderService.start();
		this.accountService.verifyInvite({ token: this.inviteToken }).subscribe(
			async (res: any) => {
				if (!res.isLoggedInUser) {
					await this.authService.logout(false);
					// not logged in user so redirect to login page with the return url and return false
					this.router.navigate(["login"], {
						queryParams: { returnUrl: this.router.url },
						skipLocationChange: true
					});
					return;
				}
				this.isJoinOrgInvite = res.isJoinOrgInvite;
				this.org = res.organisation;
				this.tokenStatus = "VALID";
				this.UiLoaderService.stop();
			},
			(err: any) => {
				switch (err.error.statusCode) {
					case 422:
						this.tokenStatus = "WITHDRAWN";
						break;
					case 400:
						this.tokenStatus = "INVALID";
						break;
					case 404:
						this.tokenStatus = "NOT_FOUND";
						break;
				}
				this.UiLoaderService.stop();
			},
			() => {
				this.UiLoaderService.stop();
			}
		);
	}
	accept() {
		this.UiLoaderService.start();
		this.accountService
			.actionInvite({ inviteToken: this.inviteToken })
			.subscribe(
				(res: any) => {
					this.UiLoaderService.stop();
					this.router.navigate(["home", this.org.id]);
				},
				(err: any) => {
					this.UiLoaderService.stop();
				},
				() => {
					this.UiLoaderService.stop();
				}
			);
	}
	openCreateOrg() {
		const dialogRef = this.dialog.open(OrganisationComponent, {
			minWidth: "500px",
			panelClass: "ca-dialog",
			data: {
				inviteToken: this.inviteToken
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result?.refresh) {
				this.router.navigate(["home", result.org.id]);
			}
		});
	}
}
