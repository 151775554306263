import { C, COMMA, ENTER } from "@angular/cdk/keycodes";
import { AfterViewInit, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { validateEmail } from "../../utils/string";

@Component({
	selector: "app-emails",
	templateUrl: "./emails.component.html",
	styleUrls: ["./emails.component.scss"]
})
export class EmailsComponent implements AfterViewInit {
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	@Input() fcName!: string;
	@Input() fg!: FormGroup;
	@Input() emails: any[] = [];
	@Input() label = "Email Addresses";
	@Input() placeholder = "Type in Email address";
	get fc() {
		return this.fg.get(this.fcName);
	}
	constructor() {}
	ngAfterViewInit(): void {
		if (this.fc?.value) {
			this.setEmails(this.fc?.value);
		}
		this.fc?.valueChanges.subscribe(res => {
			if (this.fc?.value.length !== this.emails.length) {
				this.setEmails(res);
			}
		});
	}
	chipEvent(event: MatChipInputEvent) {
		const email = (event.value || "").trim();
		this.addEmail(email);
		event.chipInput!.clear();
	}
	reset() {
		this.fc?.setValue([]);
		this.emails = [];
	}
	addEmail(email: string, silent = false): void {
		if (email) {
			this.fc?.value.push(email);
		}
		if (!silent) this.updateValueAndValidity();
	}
	updateValueAndValidity() {
		this.fc?.updateValueAndValidity();
	}
	_addEmail(email: string) {
		this.emails.push({
			email,
			invalid: !validateEmail(email)
		});
	}
	setEmails(emails: string[]) {
		this.emails = [];
		emails.forEach(e => this._addEmail(e));
	}
	removeEmail(index: number): void {
		this.fc?.value.splice(index, 1);
		this.emails.splice(index, 1);
		this.fc?.updateValueAndValidity();
	}
}
