<h1 mat-dialog-title>
	{{title}}
</h1>

<div mat-dialog-content>
	<ng-container *ngIf="message;else elseTemplate">
		<p>{{message}}</p>
	</ng-container>
	<ng-template #elseTemplate>
		<ng-container *ngTemplateOutlet="messageTemplateRef;context:messageTemplateContext"></ng-container>
	</ng-template>
</div>
<div mat-dialog-actions align="end">
	<button mat-flat-button [color]="dismissColor" (click)="onDismiss()">{{dismissText??'No'}}</button>
	<button mat-raised-button [color]="confirmColor" (click)="onConfirm()">{{confirmText??'Yes'}}</button>
</div>
