import { WHOLE_CHARACTERS_KEYS } from "../components/report/character";
import { CHARACTER_GROUP } from "../components/report/character";
interface IArcheType {
	title: String;
	subtitle: String;
	qualitiesOrPotential: {
		professional: String;
		social: String;
		spiritual: String;
		description: String;
	};
	challenge: {
		underEvolved: String;
		overUtilised: String;
	};
	thriving: String;
	table: String[][];
}
type IArcheTypes = {
	[key in CHARACTER_GROUP]: IArcheType;
};
export const ARCHETYPES: IArcheTypes = {
	PURPOSEFULNESS: {
		title: "Purposefulness",
		subtitle: "The Storyteller / Elder / Cleric",
		qualitiesOrPotential: {
			professional:
				"The Storyteller is a person that has the natural ability to see beyond emotions and the superficial. They can lift people’s perspectives and understanding to inspire unifying clarity towards meaningful outcomes. They have a unique ability to gracefully paint awe inspiring visions with words of wonder.",
			social:
				"The Elder is a purposeful source of experience who provides clarity that connects people to a deeper more fulfilling calling in life. They help people look past material gain and find value in the more meaningful experiences and achievements that life has to offer. They awaken a deeper reason behind every action and interaction that seeds wisdom and harmony.",
			spiritual:
				"The Cleric can see what could be, based on an acceptance of what is. Their gaze and perception can see beyond the reality of the norms and speaks to hearts and minds of people to inspire greater potential. They fear not the necessary strain that comes with growth and knowingly embrace it as a joyful necessity. The Cleric experiences life as a dance rather than a destination.",
			description:
				"As someone who naturally feels connection to the deeper questions and mysteries of life, you are at home at the centre of a fire. Your tribe naturally gathers around you seeking deeper understanding and meaning. Your ability to live with purpose ignites the purpose in others. You live with a deep sense of knowing and faith that life is unfolding as it should."
		},
		challenge: {
			underEvolved:
				"you may lack the confidence to move with your inner voice, playing small and lacking trust in your own intuition. The under-evolved state of purpose seeks to make meaningful impact to feed one’s own self pride and wants to be in control at the expense of inspiring and collaborating with those around you.",
			overUtilised:
				"this strength tends to become lost in idealism and lose its footing and reality of pragmatism. Without its footing you and others will become disheartened or withdraw from the world rather than live to contribute to it."
		},
		thriving:
			"Your greatest potential lies in developing your purposefulness in ways that support helpful action in the world; to create purpose - driven communities that are good for both the people around them as well as the planet.",
		table: [
			[
				"When people feel lost and unfulfilled.",
				"I have a deep sense of purpose that I bring to life holistically.",
				"My purpose is grand and not connected to the parts."
			],
			[
				"When people are focused on the material and superficial.",
				"I can see the purpose in others and inspire them to bring it to life.",
				"I create un-invited pressure on others to walk with purpose."
			],
			[
				"When people don’t take the time to connect or communicate their purpose.",
				"Others are inspired to unified purpose of what I say and do.",
				"Others are inspired to my own purpose and not their own."
			],
			[
				"When people who are driven by personal gain.",
				"People are inspired to unite, collaborate, and act.",
				"People perceive a bigger picture although it’s philosophical."
			],
			[
				"When people form a perspective without understanding the connection to the whole.",
				"I understand all things are connected to play their unique part of the whole.",
				"I hold a vision of the ideal that does not engage with the parts."
			],
			[
				"When people don’t believe is something beyond the physical and aren't open to it's potential.",
				"I discern my true calling and trust it to unfold.",
				"I solely follow my intuition at the expense of my logic."
			]
		]
	},

	HOPE: {
		title: "Hope",
		subtitle: "The Visionary / Idealist / Innocent",
		qualitiesOrPotential: {
			professional:
				"The Visionary see things that could be and sparks enthusiasm to ignite passionate action in others and themselves. Painting pictures with words and concepts to help those see something that was unimaginable before.",
			social:
				"The Idealist visualises the potential that resides within those around them and the cultures they contribute to. The idealist expresses the dormant gifts in others that are yet to be discovered and evolved. They can see through challenge and encourage those crippled by its strain.",
			spiritual:
				"The Innocent is the humble provocateur of hope and belief. Helping others see the best potential in humanity. The innocent evokes a natural tendency in others to protect what is pure and precious. They are a symbol of inspiration of our greatest potential.",
			description:
				"You are someone who sees human potential naturally and easily in the people around you. You see how things can improve and have strong visions of what could be. You intuit how people can learn and grow and what would best serve them. People likely come to you for a sense of inspiration or connection to an optimistic view on life."
		},
		challenge: {
			underEvolved:
				"sees things as they could be, although focuses on the faults and not potential. Without the ability to see hope holistically you can find yourself changing direction whenever challenges present. Struggling to see the realism in idealism and giving up in making the ideal real.",
			overUtilised:
				"state of this trait may leave your visions and concepts in the clouds making them illogical and hard for people to connect with leading to a sense of delusion. It might find you painting flowery landscapes of people’s challenges in a way that frustrates them and diminishes the genuine strain of their experience and challenge."
		},
		thriving:
			"Your greatest potential is realised by seeding a vision of optimism in every person, process, and outcome, that is realistic for people to buy into and achieve. Once you’ve cultivated the talent to paint a vision and ground it through connections that are meaningful to every person’s individually aligned gifts, you will then see ideas rise from the earth that extend to heights beyond belief and create inspiring evolution and growth for all those that are touch by the collective creation.",
		table: [
			[
				"When people point out the risks, weaknesses, and flaws without generating solutions.",
				"I inspire people potential with my experience and knowledge.",
				"I pump up others without regard to lived experience or knowledge in the room."
			],
			[
				"When people tend to be pessimistic and focus on problems over solutions.",
				"I focus on solutions after understanding challenges.",
				"I focus on ideals ignoring problems and outcomes."
			],
			[
				"When people's attitude changes with circumstances.",
				"I maintain a positive attitude balance with pragmatism.",
				"I deny that challenge or hardship exists."
			],
			[
				"When people live the dreams of others and not their own.",
				"I have a dream and I enact it.",
				"I have dreams, but they do not come to fruition."
			],
			[
				"When people aren't connected to an inspiring vision of life.",
				"I share my vision to inspire action.",
				"I share my vision to share my vision."
			],
			[
				"When people do not feel in collaboration with life.",
				"I feel connected to the mystery of existence.",
				"I find it hard to relate to people outside my spiritual community."
			]
		]
	},

	TRUTHFULNESS: {
		title: "Truthfulness",
		subtitle: "The Challenger / Explorer / Sage",
		qualitiesOrPotential: {
			professional:
				"The Challenger expresses the undertones that others are often unwilling to express or admit to. They raise topics that can sometimes plague or linger if left unspoken. The challenger can be ridiculed for speaking the truth though it is an unspoken need and sometimes under appreciated.",
			social:
				"The Explorer is usually the person that is first to venture into the new and unknown. Often seeking to expand themselves and others by discovering the nature of things. Deep contemplation and multifaceted perception are qualities often gleaned by this archetype.",
			spiritual:
				"The Sage is empowered by courage, fuelled by knowledge, and driven by truth. A constant seeking to understand and explore what might not be understood. It’s a quest to expand one’s source of truth to be explored, expressed, and embodied.",
			description:
				"As someone who lives in truth, you are in tune with the inner voice of justice. Your perception is refined to explore right from wrong and notice what is often unspoken by others due to fear, shame, guilt, or ignorance. Living with authenticity is important to you, and you likely have explored many ways of living to find what is right and true for you. The bold likely come to you for guidance, advice, or an honest perspective. As someone who lives by their ideals, you can challenge others to be better if they have the capacity to receive it as the gift it is intended to be."
		},
		challenge: {
			underEvolved:
				"state of this trait can become closed or reject important sources of truth outside of your own perspective, overlooking other viewpoints and ideologies. This closure will turn your ability to cut through illusion into a barrier of ego. You may have challenges in choosing who and how much truth to share, leaving people confused, conflicted or unexpectedly vulnerable.",
			overUtilised:
				"use of this trait creates uninvited opinions projected onto others with limited tact and respect. In friendly discussions an over projection may leave people feeling sided one way or another and this may diminish the quality of discussion and people’s sense of freedom and flow."
		},
		thriving:
			"Your greatest potential is realised when you exercise tact and discernment to know when others can receive the truth you have to share and to deliver it with the grace that inspires expanded awareness. Providing discretion and listening without judgement builds trust and empowers others to come forward and stand vulnerable in their truth. By providing fair insights in a tone of friendship and within a perspective of growth, you will quickly find your insights called for in by people in times of meaningful discernment and those of great influence.",
		table: [
			[
				"When people care about getting what’s right for them.",
				"I care more about doing right than being right.",
				"I care more about being right than doing right."
			],
			[
				"When people ignore their moral compass and put themselves first over what is fair.",
				"I treat myself, others, and life with forgiveness.",
				"I find it hard to forgive myself, others, or life."
			],
			[
				"When people always have a good excuse for their mistakes.",
				"I accept responsibility for my mistakes and learn from them.",
				"I take responsibility for things outside my control."
			],
			[
				"When people are secretive and have a hidden agenda.",
				"I am transparent and have no hidden agendas.",
				"I share my deepest views and experiences inappropriately."
			],
			[
				"When people live by whatever code seems to please those around them.",
				"I live in co-created principles, ideals, and collective values.",
				"I expect others to live by my principles, ideals and values."
			],
			[
				"When people tend to agree with whoever they're talking with.",
				"I think for myself and speak my truth.",
				"I know what is right and speak the truth."
			]
		]
	},

	LOVE: {
		title: "Love",
		subtitle: "The Connector / Enthusiast / Harmoniser",
		qualitiesOrPotential: {
			professional:
				"The Connector brings a sense of joy that lights up people and teams. They have an ability to find and spread passion into the world around them by expressing their appreciation and excitement. They bring excitement to every task and help people feel valued for their contribution.",
			social:
				"The Enthusiast has the ability to see through a lens of gratitude to take whatever is and make it enough. They make loyal friendships and commit to causes wholeheartedly. This person brings a deep sense of fun and love to relationships that makes people feel seen, heard, and valued.",
			spiritual:
				"The Harmoniser sees the intention and connection of love in all creation. All things can be seen through the perspective of love. They understand that it is the essence of all existence and that both sadness and happiness only emerge from the source of loving something or someone.",
			description:
				"You are filled by the joy of life, an inner peace that is like a breath of fresh air to those entrenched in the physical and material aspects of life. Your state of being shows through your passion, enthusiasm, and zest for life. It is attractive to others, and you have probably been told how you can light up a room and bring energy to anything that is gifted with your heart’s grace."
		},
		challenge: {
			underEvolved:
				"state of this trait can be sensitive to situations where the purity of your intentions was not received with respect or taken advantage of. This can encase your heart to protect its vulnerability from further threat and stifle your passionate magic. When this trait lacks maturity, it can tend to avoid challenge and setting boundaries, leaving your energy in the clouds without touching down to ground them on earth.",
			overUtilised:
				"state of this trait can be overbearing, overprotective and smothering. It lacks the awareness that creating a state of flow requires a dance between joy and strain. It can have a tendency to see strain as negative and that is needs to be avoided at all costs. Excessive gentleness can create a false bubble of security that eventually bursts leaving feelings of falseness."
		},
		thriving:
			"The true potential of this archetype is that you can become an attractor and connector for your community, and inspire greater joy, appreciation, and love in others. When the heart is detached and in loving flow, it understands that a lens of appreciation and gratitude is all that is needed to achieve inner peace and enthusiasm for life. From this perspective every challenge can be gently unraveled to discover its gift of growth and every moment of joy as something to savour.",
		table: [
			[
				"When people connect superficially and avoid depth.",
				"I allow myself to connect deeply to heartfelt situations.",
				"I seek connection intensely and habitually."
			],
			[
				"When people  rarely express appreciation to others or fail to pause and celebrate life.",
				"I see and express appreciation to others and celebrate life regularly.",
				"I expect to see everyone act with loving care and get upset when harmony is broken."
			],
			[
				"When people don’t love what they do, and don’t make time to do what they love.",
				"I do what I love and love what I do including the challenges.",
				"I only do what brings me joy at the expense of long-term goals."
			],
			[
				"When people create undue strain on others and kill the joy in moments.",
				"I create a joyful environment for myself and others to feel valued.",
				"I coddle and smother others with care that stifles growth."
			],
			[
				"When people constantly push limits and there is no time to celebrate wins.",
				"I create a culture of celebration for every challenge and achievement.",
				"I celebrate wins often and find it difficult to see the good in challenging times."
			],
			[
				"When people see life as a struggle and others or always more fortunate than them.",
				"I have a deep sense that life is good and thankful for all its shades.",
				"I have difficulty making space for the pain and suffering of life."
			]
		]
	},
	RESPECT: {
		title: "Respect",
		subtitle: "The Humanitarian / Empath / Healer",
		qualitiesOrPotential: {
			professional:
				"The Humanitarian cares for all people and values everyone as a unique part of the whole. Their humility and tact allow others to feel respected and brings greater unity amongst those involved. They understand that every person has qualities that can contribute to each other.",
			social:
				"The Empath can understand another’s experience and is able to thoughtfully connect and listen to others without judgement. They can feel the challenge and joyful energies of those they connect with and have a unique ability to help all living things feel like they belong and matter.",
			spiritual:
				"The Healer has a unique ability to emotionally support people through their strains and distress. Their presence creates a feeling of acceptance and dignity for those that they support. Their connection extends beyond the physical to reach the minds, hearts, and souls.",
			description:
				"You are a natural pacifier. As someone who embodies the principles of respect, thoughtfulness, and cooperation, you have a felt sense of our shared humanity and the ties that bind us together. Others will likely come to you for understanding, consideration, and connection. You effortlessly provide hospitality and create unity that helps people see their place in the human tapestry."
		},
		challenge: {
			underEvolved:
				"use of this trait finds you making other people’s challenges your own. Other people’s dramas can be used in gossip and discussion in a need to feel important and satisfy your need for drama and being the rescuer or trusted source of information.",
			overUtilised:
				"state of this trait can tend to go too far in the consideration for others, overextending yourself to the point where your own needs and values are not considered by those around you and effectively becoming a doormat or dumping ground for other people’s problems."
		},
		thriving:
			"The greatest potential of this archetype is realised when the art of living is empowered by boundaries and a connection to higher purpose and truth. By understanding when to provide empathy for support and healing, and when to stay at a distance to maintain your own self-respect, will allow others to endure their own needed strains for growth. When you can create the openness for people to be accepted as they are, and bring in truth that liberates, empowers, and evolves someone’s understanding, you are living in true service to humanity.",
		table: [
			[
				"When people disrespect others to create drama or get want they want.",
				"I treat myself and others with respect and dignity.",
				"I allow disrespectful behaviour in order to avoid potential conflict."
			],
			[
				"When people are oblivious to the emotion strains others experience.",
				"I see and feel the emotional strains others experience.",
				"I take on the emotional strains of others experiences as my own."
			],
			[
				"When people treat others as if they owe them something.",
				"I treat others as they wish to be treated, based on reciprocity.",
				"I am often taken advantage of because I’m overly considerate."
			],
			[
				"When people listen with suspicion and expectation.",
				"I listen and communicate in a way that brings healing and clarity.",
				"I’m compelled to rescue or fix other people’s challenges."
			],
			[
				"When people create a a culture of judgement, blame and entitlement.",
				"I create a culture where people can express their truth respectfully with consideration.",
				"I create a culture where people speak with courtesy and respect, though suppressing truth."
			],
			[
				"When people's needs matter more than the impact of their behaviours.",
				"I am aware of, and reflect on, the impact of my behaviours on others.",
				"My respect to not offend others withholds my truth and wisdom."
			]
		]
	},
	MINDFULNESS: {
		title: "Mindfulness",
		subtitle: "The Reflector / Peacemaker / Alchemist",
		qualitiesOrPotential: {
			professional:
				"The Reflector can step back and view things from a place of simplicity. This allows them to find unique solutions to problems using their ability to see through clutter. They live by the fundamental principles of nature and apply balanced mindful presence that reflects on past learnings, contemplates future potentials, and integrates them to make wise decisions in the present moment.",
			social:
				"The Peacemaker can find contentment in any circumstance and understands that mindful presence is often all that is needed to resolve challenges and disagreements. The peacemaker embodies and emanates calm which helps others discover peace within themselves.",
			spiritual:
				"The Alchemist can transmute any experience into a landscape of beauty. Bringing awareness to greatness that is contained within every piece of simplicity. The alchemist nurtures serenity, and seeds contentment to find profound depth in what is often missed or taken for granted by others.",
			description:
				"You are attuned deeply within the present moment and can exist there with ease. This simple, yet profound presence, allows you to detach from the complexities of the mind. When people are tying themselves in knots, you ground them with the reflection they need to return to simplicity. Sustained by balance and nourished by nature, you have a gift of taming storms into refreshing breezes. Your composure brings a depth of awareness that is beyond all distractions."
		},
		challenge: {
			underEvolved:
				"use of this trait may lack the ability to blend calm with chaos as a grounding and stabilising force that empowers purpose. Paralysed by challenge and complexity, you feel powerless when situations require grounded flow. Choosing to avoid storms instead of taming them, this can lead to becoming anxious, overwhelmed, and disempowered.",
			overUtilised:
				"state of this trait can become so attached to peace and composure that it dulls the genius of creativity and innovation. Being so fixated on the present that it creates a disconnection from living and planning. Always remaining in a state of being and never actually doing, leaving one as an observer of the dance of life and never actually dancing with life."
		},
		thriving:
			"Your greatest potential lies in bringing the peace that lies at the top of the mountain into the marketplace. It is in bringing the depth and breadth of your presence to the spaces you occupy, that offers the possibility of transformation to the hurried and rushed ways of modern living. You have the ability to seed real and lasting contentment; for yourself and others.",
		table: [
			[
				"When people's life is often cluttered and excessive.",
				"I clear my life of clutter and excess.",
				"I have so much space in my life, it feels empty."
			],
			[
				"When people's circumstances dictate my mood.",
				"My inner wellbeing is not dependant on outer circumstances.",
				"I am so detached from the world that I do not feel part of it."
			],
			[
				"When people often rushing or hurrying to the next thing.",
				"I move calmly and mindfully noticing the lessons and beauty.",
				"I find myself unable to act when circumstances change."
			],
			[
				"When people are rarely satisfied or grateful.",
				"I allow myself to be satisfied and grateful with what is.",
				"I access bliss in order to bypass my troubles."
			],
			[
				"When people mostly live in the future or the past.",
				"I live in the present moment.",
				"I live for the present moment."
			],
			[
				"When people believe life never goes their way and they feel they always have to endure crisis.",
				"Life is unfolding as it should and what are the lessons for me to learn on my purposeful journey.",
				"Life is only to be observed and I don’t need to feel emotions or contribute, just be."
			]
		]
	},

	WISDOM: {
		title: "Wisdom",
		subtitle: "The Leader / Orchestrator / Magician",
		qualitiesOrPotential: {
			professional:
				"The Leader possesses the ability to lead and inspire themselves from which a sense of belief is instilled in others. Great leaders build inspiration for others to lead themselves and unite to a common vision or goal. The Leader is empowered with foresight and wisdom that builds confidence and clarity.",
			social:
				"The Orchestrator can see the strengths in others and inspires collaborative engagements to bring about efficiency, joy, and collective gain. They can conduct to unite the natural strengths of those around them to create outcomes where the collective is greater than the sum of the parts.",
			spiritual:
				"The Magician makes the perceived impossible possible, by wisely navigating the unlimited potential of life and its countless possibilities. They achieve this by choosing the most fitting path of discernment that sees patterns in both the perceivable and intangible aspects of the universe.",
			description:
				"Straddling the boundary between truth and purpose, you embody the wisdom of right action. A natural leader, community organiser and capable of making magic in teams. You bring strategy, foresight, and creativity to the challenges that we collectively face. People will look to you for the ‘how’ of a new project or endeavour, expecting you to chart the course."
		},
		challenge: {
			underEvolved:
				"you may have judgement entwined in your discernment that could leak into your expression and diminish the inspiration of those that aspire to serve your lead. Limited diversity in perspective may mean you miss opportunities that rest in your peripherals by not providing enough space and time for other potential pathways and unique insights to form in clarity.",
			overUtilised:
				"it can create such a large array of choices that adds complexity, leaving you paralysed in discerning the best ways to step forward into purposeful action for yourself and those you lead. You might also come across as a know-it-all and race ahead, failing to gain peoples’ perspective and trust."
		},
		thriving:
			"The greatest potential of this archetype arises when this trait can see the multiplicity of choices and invites trust and perspective from others to discern a united vision, that paves the best purposeful pathway forward. When you’ve mastered the talent of simplifying the matrix into meaningful and tangible steps for your team, you will find yourself at the forefront and centre of every meaningful pursuit. The thriving state sees all potential while seeding and guiding individuals to understand and activate their unique and meaningful part of the whole.",
		table: [
			[
				"When people live only for the present and let the future take care of itself.",
				"I act in the present for the benefit of the future.",
				"I forget to enjoy the present and live only in the future."
			],
			[
				"When people are more interested in the task or result than the logic and purpose.",
				"I help others discover the reason behind circumstances that develops their own inner wisdom.",
				"I place pressure on others to live by my experience and wisdom other than their own."
			],
			[
				"When people can’t be bothered thinking for themselves and they ask others for answers.",
				"Others are inspired by my wisdom to take initiative and find solutions.",
				"Others perceive me as a know it all and always knowing best."
			],
			[
				"When people rarely see the humour in situations and life.",
				"I see the humour and light up others with my unique perspective.",
				"I see the humour and exclude others with the way I share it."
			],
			[
				"When people's choices are self-centred amd depend on how they feel regardless of logic.",
				"I make discerning choices for the best outcome of all.",
				"I see so many options that it’s difficult to decide."
			],
			[
				"When people's perspective serves their own limiting beliefs.",
				"My insights are fair, accurate and useful to others.",
				"My insights are uninvited or confuse others."
			]
		]
	},

	TRUSTWORTHINESS: {
		title: "Trustworthiness",
		subtitle: "The Advisor / Coach / Guide",
		qualitiesOrPotential: {
			professional:
				"The Advisor is invited to bring an authentic, experienced, and just expression of truth that provides reliability and congruence for people to act without doubt . People will easily rely upon you as a stable foundation for support, refuge, and reflection.",
			social:
				"The Coach embodies integrity as a role model for those that seek to achieve it. The coach empowers transparency through choice and holds people accountable to the choices they make. They help people discover th at trust is at the core of all thriving human relationships.",
			spiritual:
				"The Guide inspires reflection of one’s own truth within themselves. They provide a guilt or shame free space to express it and a mirror of truth to explore it without judgment. They inspire others to ask themselves questions instead of questioning oneself.",
			description:
				"You are someone who embodies truth in action, the spirit of trustworthiness. You have a finely tuned sense for the importance of communication and how the flow information can help or hinder the people around you. Not content with only finding the truth, you feel you must live the truth and model this state of being to those around you. People will likely come to you in discretion when they are in a moral quandary to unravel their own sense of truth because you are deeply trusted."
		},
		challenge: {
			underEvolved:
				"use of this trait can tend to place and give your trust to those that do not invite or respect it. Placing deep trust linked with high expectations on others could leave you disheartened in your relationships. People can also perceive you as judgemental and direct.",
			overUtilised:
				"state of this trait is that in your quest to model Trustworthiness and share this with others, you run the risk of taking responsibility for things that are not yours to carry. You may find yourself carrying the burdens and responsibilities of other people that dump their own obligations on you as a shortcut to achieving better outcomes for themselves."
		},
		thriving:
			"The greatest potential of this archetype is in the constant return to humility. Others invite your perspective because you give it from honourable intent that is free of judgement and expressed tactfully. This gift is ignited when it creates space for people to make mistakes and allows them to find non-judgemental advice in your trusted and respected guidance. People find you as a reliable confidant based on your congruent actions and accountability. You pose questions that inspire others to discovery their own inner truth and self-trust.",
		table: [
			[
				"When people don't respect the right way of doing things.",
				"I opt to guide, rather than control, others.",
				"I impose the ‘right way’ of doing things on others."
			],
			[
				"When people not trust anyone, and believe everyone’s default intention is to serve themselves.",
				"People deserve to be trusted and I see past their mistakes when intentions are good.",
				"All people can be trusted and only have good intentions."
			],
			[
				"When people do what’s best for themselves in the moment regardless of commitments.",
				"My words, actions and reactions are congruent and reliable.",
				"I take on the responsibilities of others even when it means I need to sacrifice myself."
			],
			[
				"When people make decisions based mostly on their own interests and desires.",
				"I consider the needs and interests of others as well as my own values when making decisions.",
				"I make decisions that often feel proscriptive or paternalistic to myself or others."
			],
			[
				"When people spread information that is harmful to me or others.",
				"I share information discretely that helps uplift and create unity.",
				"I share information that people need to hear but aren’t ready to."
			],
			[
				"When people don't take responsibility for their behaviours and circumstances.",
				"I take responsibility for what occurs in the domain of my influence.",
				"I take responsibility for things occurring beyond my domain of influence."
			]
		]
	},

	COMPASSION: {
		title: "Compassion",
		subtitle: "The Helper / Carer / Nurturer",
		qualitiesOrPotential: {
			professional:
				"The Helper gives unconditionally. To be of service and provide value to others, in a way that is valued by them. The helper will often adopt other’s causes they believe in as their own and empower them with their unique ability to inspire through kindness and helpfulness.",
			social:
				"The Carer sees past emotions and connects with a heart of compassion to those they care about. They are charitable towards purposeful cause and have an ability to connect in a way that creates sanctuary for people to feel unconditionally valued and seen regardless of the circumstances.",
			spiritual:
				"The Nurturer brings a compassionate presence that helps others feel like no matter what they are going through or who they are, they are supported and ok. A balancing of all aspects and a reconnection to the spirit of being human. A selfless nurturing that cultivates and rejuvenates energies back to life.",
			description:
				"As someone who expresses loving care in action, you are both sensitive to the needs of others and find fulfillment in meeting them. A gentleness of spirit and connection to the people around you, make you someone that people will turn to when they seek heartfelt kindness and connection. You have a capacity to be of service through your presence, a soothing quality that you may notice others are drawn to. Your capacity for compassion and your spirit to be of charitable service, are welcome in any group and often play a core role in helping everyone stay connected to themselves and each other."
		},
		challenge: {
			underEvolved:
				"state of this trait can be so selfless that you run your own cup empty and lose touch with your own needs amidst the important work of helping others. Your selflessness can become selfishly dependant on others in order to sustain your existence and significance.",
			overUtilised:
				"state of this trait can nurture to the extent where it robs the natural strains needed for growth and evolution in self and others. This state can bring about a tendency to rescue and save others when they are experiencing necessary growth on the path to finding their own truth and clarity."
		},
		thriving:
			"Your greatest potential is being a role model of care to others through sharing your compassion, generosity, and service. Your acts of charity inspire hope in others, through acts of kindness with every person, in every action and for every cause that resonates with your heart.",
		table: [
			[
				"When people keep track of who owes them what.",
				"I share what I have with others without taking away from myself.",
				"I give even when I am likely to be taken advantage of."
			],
			[
				"When people are mostly concerned with their own problems and struggles.",
				"I notice when someone is hurt or needs to feel valued.",
				"I rescue others from their challenge or growth in strain."
			],
			[
				"When people rely on others to get the care I need to re-balance.",
				"I balanced between self-care and caring for those around me.",
				"I help everyone to the point that my own cup is empty."
			],
			[
				"When people connect to help people only to get something for themselves.",
				"I connect with people in a way that shows I care and value them.",
				"I connect with people to help them, so they don’t struggle."
			],
			[
				"When people avoid supporting the needs of others.",
				"I give people what they need – not always what they want.",
				"I give people what they want, even if it is not what they need."
			],
			[
				"I am indifferent to the suffering of others.",
				"My balance and care flows out from me in nurturing abundance.",
				"The world is filled with so much suffering beyond my capacity."
			]
		]
	},

	COURAGE: {
		title: "Courage",
		subtitle: "The Champion / Defender / Warrior",
		qualitiesOrPotential: {
			professional:
				"The Champion is looked upon for their assertiveness in boldly empowering action and results. The champion is determined to achieve and is relentless in their pursuit to succeed in committed goals. They are steadfast to endure and overcome to see things through to success.",
			social:
				"The Defender courageously leads and rallies others to stand confidently together. They do not shy away from challenge. They bravely step towards the uncomfortable, knowing that their vulnerability ignites passion in others and fuels people to rise-up to protect what they love.",
			spiritual:
				"The Warrior has a deep inner knowing that their soul is invincible, this empowers them to endure the density of any strain. They embrace fear in the journey of bringing light to shadow and protecting that which is sacred. The energy of the warrior transcends challenge into expansive growth.",
			description:
				"You embody the force of will that overcomes barriers and brings holistic growth. With commitment, endurance, and fortitude, you push past the blocks that others would turn back at. Your energy is much sought after in many aspects of life, and people invite you to join their mission because you increase the quality of their success and ensure results are achieved."
		},
		challenge: {
			underEvolved:
				"use of this trait lacks self-significance, not knowing the true nature of its power. It misdirects this gift, projecting it as an outward force on others with tones of competition and control, instead of focusing inward and self-empowering confidence and endurance.",
			overUtilised:
				"state of this trait seeks to be the hero, the saviour, and the winner, lacking the ability to blend the power of this strength with other traits that bring collaboration and harmonious results. Always leading with this force can break our relationships, others' will and ourselves."
		},
		thriving:
			"Your greatest potential lies in understanding the significance of your own power, and using this strength to empower other people, causes and character traits to rise-up in unity and expanded impact. This gift is empowered when you connect to the sensitivity for those that do not have the same strength as you and to adapt your pace to one that is achievable by those around you. Sometimes still surging ahead, though remembering to circle back around and gather and empower the team. Courage is the doorway to all achievement and growth and the thriving state ignites others to find their own strength beside the power of your existence.",
		table: [
			[
				"When people avoid the challenges of my life.",
				"I willingly face the challenges of my life.",
				"I approach all aspects of life as if it is a challenge."
			],
			[
				"When people stay silent on issues that matter to me.",
				"I stand up for what I think is right and inspire collaboration.",
				"I don’t tolerate difference of opinions on important issues."
			],
			[
				"When people abandon projects when difficulty arises.",
				"I efficiently finish what I start.",
				"I seek out challenge even when it distracts me from my goals."
			],
			[
				"When people walk away from relationships at the first signs challenges.",
				"When challenges arise in my relationships, I find a way to over- come them and build unity.",
				"When challenges arise in my relationships, I find a way to get it fixed the way it should be."
			],
			[
				"When people are paralysed by fear, guilt, and shame.",
				"I do not let fear stop me from acting in the moment with discernment.",
				"I can often be so fearless that I invite challenge for the fight and not the purpose."
			],
			[
				"When people feel that they're not worthy of great achievements and it’s all too hard.",
				"I believe I can achieve whatever I set my heart to accomplish.",
				"I set my heart to accomplish. I believe that I can win any fight and I can conquer all."
			]
		]
	},

	TOLERANCE: {
		title: "Tolerance",
		subtitle: "The Diplomat / Mediator / Mender",
		qualitiesOrPotential: {
			professional:
				"The Diplomat is a unifier and connector. They can resolve conflict and cultivate flexibility when situations seem deadlocked in opinion. They have a way with their words that leaves people feeling respected and invites resolution. Effective diplomats diffuse conflicts before they arise.",
			social:
				"The Mediator can see multiple perspectives and connect to the emotional needs of those expressing them. While being connected to emotions, they are not engulfed by them. They encourage detachment and bring a perspective of logic that aids in resolution and flexibility.",
			spiritual:
				"The Mender is a master of managing and mixing energies to create space for people as they explore the shadow and light in their lives. A unique gift where people feel safe and held, this gives them the strength to venture into parts, they often are reluctant to connect with or reveal.",
			description:
				"You are a natural space holder, someone who knows how to set your own judgements or agenda to one side when others need to be seen, heard, and supported. You intuit what makes people the way that they are and the necessary forgiveness for the human condition. People likely come to you for understanding and acceptance as they feel like they are heard and supported."
		},
		challenge: {
			underEvolved:
				"use of this trait can invite people in to share what is distressing and challenging and you can find yourself sometimes caught up in their emotions and drama without the ability to detach. It can also occur that the challenges in your own life become a drama for you as you tolerant too much in life and fail to set clear boundaries for yourself and those around you.",
			overUtilised:
				"state of this trait can leave you reaching into other people’s business without invitation or exaggerating strain to the point where it becomes a distress, that would otherwise have naturally unraveled itself. It can lack moderation, discretion, and discernment. You may make mountains of molehills and misunderstand the value of challenge, as a call to rescue."
		},
		thriving:
			"In the thriving state you are able to connect to all people and gather great insight from their experience and viewpoint before detaching through a mindset of acceptance. When thriving you intuitively and intellectually know when to support a person in their challenge and when to patiently observe and support people through their own growth in presence, conversation, and connection. The thriving state is aware that all things change, and emotions are a choice. Mastering the balancing act between taking on the challenge of others and guiding others through confident wisdom and respectful boundaries will ignite this gift in every interaction.",
		table: [
			[
				"When people are emotionally triggered by things they can’t control.",
				"I accept what is and set clear boundaries to maintain balance.",
				"I intervene when uninvited and sometimes when not needed."
			],
			[
				"When people are not in tune to the needs of others and how to support them.",
				"I support others with detachment and understand when it is beneficial.",
				"I support people in distressed and it triggers my emotions."
			],
			[
				"When people don't listen before they speak.",
				"I listen to understand all perspective.",
				"I’m involved with many people’s detailed challenges."
			],
			[
				"When people gossip about other people’s challenges.",
				"I seed unity through harmonious perspective and wisdom.",
				"I try to fix problems for others because I feel compelled."
			],
			[
				"When people aren't willing to give up anything that will affect them.",
				"I give up things of importance for the betterment of life.",
				"I sacrifice myself to the point of losing balance."
			],
			[
				"When people only connect with people they like or gain from.",
				"I’m inclusive to connect and value everyone, including myself.",
				"I include people even when they will not respect myself or others."
			]
		]
	},

	"SELF-DISCIPLINE": {
		title: "Self-Discipline",
		subtitle: "The Master / Builder / Weaver",
		qualitiesOrPotential: {
			professional:
				"The Master archetype is attentive to details and holds an interest over long durations with intent and professionalism. They master any craft or skill that they take on. Diligent and consistent to a level that is often rarely achieved by others. Their orderliness in arranging information and process can take time, though once established becomes a pillar of strength to those that lean on them.",
			social:
				"The Builder meticulously lays foundations in all their social networks. They form bonds of excellence where they’re seen as people who can always be relied upon and trusted to see things through. They choose their pursuits wisely, care for them attentively and build their impacts one piece at a time over a long duration. A builder is always at the foundations of any great cause or result.",
			spiritual:
				"The Weaver holds intricate awareness in navigating aspects of our being that is often not understood by many people. They can navigate the spiritual and dream realms in ways that brings clarity to others and purity to life. Their gift of knitting together the detailed fragments to form a meaningful picture, leaves most people astonished about how a view is perceived from shards.",
			description:
				"You are someone that can weave depth, focus and endurance simultaneously. Constantly building upon your skills and the quality of the outcome. Embodying self-discipline leading to self-mastery, you offer a grounded, practical, and orderly presence to those around you, that brings a natural sense of security and stability. Where others focus on the why you are focused on the what and how. People lean on your ability to build, make, or create something worthwhile."
		},
		challenge: {
			underEvolved:
				"use of this trait may find your gift of intensity misdirected toward meaningless pursuits that don’t build anything of significance or can be found drowning in detail that loses sight of the big picture. The immature state of this trait can become fixated on creating without purpose, to the point where the laying of bricks becomes more important than the structure itself.",
			overUtilised:
				"state of this trait can 	become so diligent and structured that it can stifle growth in other aspects of character. Rigidly building structures around them to the extent that new experiences and discoveries are prevented, and others feel like failures trying to meet your standards. Your professionalism turns to perfectionism and what can be security becomes a cage."
		},
		thriving:
			"Your greatest potential lies in using your pragmatic sensibilities to create with passion and purpose. When you find the wisdom to discern your focus purposefully and in balance with enjoying your creations, you can step back to admire the grandeur or your tapestry.",
		table: [
			[
				"When people do just enough to get things done.",
				"I give my best while balancing excellence with efficiency.",
				"I let perfectionism delay or halt meaningful progress."
			],
			[
				"When people do things their own way without building upon the existing standards.",
				"I build upon and follow standards that are good for me and others.",
				"I set standards that others can’t meet or see the value in."
			],
			[
				"When people work in without order, structure and planning.",
				"I am a master of my craft and always have everything in order.",
				"I am so organised that others feel constrained in my space."
			],
			[
				"When people say, act and do whatever they feel is going to make others happy.",
				"I take time to reflect and adjust my interactions constantly learning and growing with others.",
				"I cautiously select my relationships in alignment to my own values and perspectives."
			],
			[
				"When people create mess in the spaces they occupy.",
				"I create order in such a way that everyone involved achieves.",
				"Spontaneity and creativity are stifled by excessive order."
			],
			[
				"When people habitually excuse their own disregard to respect agreed standards.",
				"I set meaningful values for myself and live by those standards.",
				"I set unachievable standards for myself and often raise them."
			]
		]
	}
};

export const CAT_TABLE = [
	{
		id: "va",
		name: "Visionary Activator",
		type: "PERFORMANCE",
		cgs: [
			{
				id: "WISDOM",
				name: "Wisdom",
				key: WHOLE_CHARACTERS_KEYS.PERFORMANCE[5],
				archetypes: "Leader\nOrchestrator\nMagician",
				need: "To know",
				seeking: "Knowledge",
				QorE: "Informed Knowledge",
				overUtilised: "Overthinking possibilities",
				thriving: "To lead Strategic direction"
			},
			{
				id: "TRUSTWORTHINESS",
				key: WHOLE_CHARACTERS_KEYS.PERFORMANCE[4],

				name: "Trustworthiness",
				archetypes: "Advisor\nCoach\nGuide",
				need: "To set free",
				seeking: "Fairness",
				QorE: "Equitable Guidance",
				overUtilised: "Being the leading source of truth",
				thriving: "To guide growth"
			},
			{
				key: WHOLE_CHARACTERS_KEYS.PERFORMANCE[3],
				id: "COMPASSION",
				name: "Compassion",
				archetypes: "Helper\nCarer\nNurturer",
				need: "To serve",
				seeking: "Harmony",
				QorE: "Activated Selflessness",
				overUtilised: "To fix the problems of others",
				thriving: "To be of service"
			}
		]
	},
	{
		id: "ga",
		type: "PERFORMANCE",
		name: "Grounded Activator",
		cgs: [
			{
				key: WHOLE_CHARACTERS_KEYS.PERFORMANCE[2],
				id: "COURAGE",
				name: "Courage",
				archetypes: "Champion\nDefender\nWarrior",
				need: "To achieve",
				seeking: "Significance",
				QorE: "Highly Driven",
				overUtilised: "To win",
				thriving: "To expand"
			},
			{
				key: WHOLE_CHARACTERS_KEYS.PERFORMANCE[1],
				id: "TOLERANCE",
				name: "Tolerance",
				archetypes: "Diplomat\nMediator\nMender",
				need: "To belong",
				seeking: "Acceptance",
				QorE: "Perspective Awareness",
				overUtilised: "To appease the needs of others",
				thriving: "To foster unity"
			},
			{
				key: WHOLE_CHARACTERS_KEYS.PERFORMANCE[0],
				id: "SELF-DISCIPLINE",
				name: "Self-discipline",
				archetypes: "Master\nBuilder\nWeaver",
				need: "To stabilise",
				seeking: "Security",
				QorE: "Enduring Rigour",
				overUtilised: "To always be prepared",
				thriving: "To create stability"
			}
		]
	},
	{
		id: "gi",
		name: "Grounded Integrator",
		type: "MORAL",
		cgs: [
			{
				key: WHOLE_CHARACTERS_KEYS.MORAL[5],
				id: "MINDFULNESS",
				name: "Mindfulness",
				archetypes: "Reflector\nPeacemaker\nAlchemist",
				need: "To be",
				seeking: "Tranquility",
				QorE: "Deep Presence",
				overUtilised: "To observe the world",
				thriving: "To bring connected presence"
			},
			{
				key: WHOLE_CHARACTERS_KEYS.MORAL[4],
				id: "RESPECT",
				name: "Respect",
				archetypes: "Humanitarian\nEmpath\nHealer",
				need: "To unify",
				seeking: "Unity",
				QorE: "Emotional Awareness",
				overUtilised: "To rescue others from distress",
				thriving: "To walk beside others through distress"
			},
			{
				key: WHOLE_CHARACTERS_KEYS.MORAL[3],
				id: "LOVE",
				name: "Love",
				archetypes: "Connector\nEnthusiast\nHarmoniser",
				need: "To connect",
				seeking: "Appreciation",
				QorE: "Valued Connections",
				overUtilised: "To be loved",
				thriving: "To seed appreciation"
			}
		]
	},
	{
		id: "vi",
		type: "MORAL",
		name: "Visionary Integrator",
		cgs: [
			{
				key: WHOLE_CHARACTERS_KEYS.MORAL[2],
				id: "TRUTHFULNESS",
				name: "Truthfulness",
				archetypes: "Challenger\nExplorer\nSage",
				need: "To seek",
				seeking: "Freedom",
				QorE: "Just Awareness",
				overUtilised: "Being right",
				thriving: "To uncover truth"
			},
			{
				key: WHOLE_CHARACTERS_KEYS.MORAL[1],
				id: "HOPE",
				name: "Hope",
				archetypes: "Visionary\nIdealist\nInnocent",
				need: "To flourish",
				seeking: "Idealism",
				QorE: "Idealistic Awareness",
				overUtilised: "Creating utopia",
				thriving: "Inspiring a better future"
			},
			{
				key: WHOLE_CHARACTERS_KEYS.MORAL[0],
				id: "PURPOSEFULNESS",
				name: "Purposefulness",
				archetypes: "Storyteller\nElder\nCleric",
				need: "To understand",
				seeking: "Meaning",
				QorE: "Holistic Awareness",
				overUtilised: "Airy mysticism",
				thriving: "Inspiring meaningful understanding"
			}
		]
	}
];
