<section fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
    <!-- <mat-card class="ca-padding-75px-50px ca-center-container "> -->
    <!-- <mat-card-content fxLayout="column"> -->
    <div class="ca-center-container " fxLayout="column">
        <img src="/assets/step-logo-full.webp" class="navbar-brand-img" alt="...">
        <div style="width: 75%;margin: auto;" fxLayout="column" fxFlexOffset="20px" fxLayoutGap="10px"
            fxLayoutAlign="start start">
            <!-- <button fxFlexFill mat-flat-button class="social-button linked-in" (click)="signInWithLinkedin()">
                <mat-icon class="btn-icon" svgIcon="linked-in"></mat-icon>
                <span>Login using LinkedIn</span>
            </button>
            <button fxFlexFill mat-flat-button class="social-button facebook" (click)="sizzzgnInWithFB()">
                <mat-icon class="btn-icon" svgIcon="facebook"></mat-icon>
                <span>Login using Facebook</span>
            </button>
            <button fxFlexFill mat-flat-button class="social-button apple" (click)="signInWithApple()">
                <mat-icon class="btn-icon" svgIcon="apple"></mat-icon>
                <span>Login using Apple</span>
            </button> -->
            <form fxLayout="column" fxFlexFill fxFlexOffset="30px" [formGroup]="userForm" (ngSubmit)="onSubmit()">
                <div fxLayout="column" fxLayoutGap="10px">
                    <div class="step-form-group">
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': fg?.get('email')?.touched && fg?.get('email')?.errors }"
                            placeholder="Email ID" />
                        <div *ngIf="fg?.get('email')?.touched && fg?.get('email')?.errors" class="invalid-feedback">
                            <ng-container *ngIf="fg?.get('email')?.errors?.required">
                                <mat-icon>error</mat-icon> Please type in Email Id.
                            </ng-container>
                        </div>
                    </div>
                    <div class="step-form-group">
                        <input [type]="isVisible?'text':'password'" formControlName="password" maxlength="20"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': fg?.get('password')?.touched && fg?.get('password')?.errors }"
                            placeholder="Password" />
                        <mat-icon class="password-visibility" (click)="isVisible=!isVisible">
                            {{isVisible? "visibility_off":"visibility"}}
                        </mat-icon>
                        <div *ngIf="fg?.get('password')?.touched && fg?.get('password')?.errors"
                            class="invalid-feedback">
                            <ng-container *ngIf="fg?.get('password')?.errors?.required">
                                <mat-icon>error</mat-icon>Please type in Password.
                            </ng-container>
                        </div>
                    </div>

                </div>
                <button [disabled]="fg?.invalid" fxFlexFill fxFlexOffset="20px" mat-raised-button color="primary">
                    <span style="font-size: 16px;font-weight: bold;">Login</span>
                </button>
                <div class="ca-forgot-password-container">
                    <a mat-flat-button class="ca-forgot-password" (click)="openForgotPassword()">Forgot Password or
                        Can't login?</a>
                </div>
            </form>
            <button class=" btn-with-icon-left" fxFlexFill fxFlexOffset="20px" mat-raised-button color="primary"
                (click)="openLoginWithMobile()">
                <mat-icon class="btn-icon">phone_iphone</mat-icon>
                <span style="font-size: 14px;"> Login with Mobile</span>
            </button>
        </div>
    </div>

    <!-- </mat-card-content> -->
    <!-- </mat-card> -->
</section>
