import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class StepAccountService {
	baseURL = environment.stepBaseUrl;
	constructor(private http: HttpClient) {}
	login(payload: any) {
		return this.http.post(`${this.baseURL}/account/login/email`, payload);
	}
	forgotPassword(queryParams: { email: string }) {
		let params = new HttpParams({ fromObject: queryParams });
		return this.http.get(`${this.baseURL}/account/forgot_password`, {
			params
		});
	}
	changePassword(payload: { currentPassword: string; newPassword: string }) {
		return this.http.put(`${this.baseURL}/account/change_password`, payload);
	}
	loginWithMobileSendOTP(payload: { contactNo: string }) {
		return this.http.post(
			`${this.baseURL}/account/login/sms_send_otp`,
			payload
		);
	}

	loginWithMobileVerifyOTP(payload: { contactNo: string }) {
		return this.http.post(
			`${this.baseURL}/account/login/sms_verify_otp`,
			payload
		);
	}
}
