import { Component } from "@angular/core";
import { Input } from "@angular/core";

@Component({
	selector: "app-csr",
	templateUrl: "./csr.component.html",
	styleUrls: ["./csr.component.scss"]
})
export class CsrComponent {
	@Input() report: any;
	@Input() isLoading: boolean = true;
	constructor() {}
}
