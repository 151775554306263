<h1 mat-dialog-title>
	{{dialogData.title}}
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<form fxLayout="column" [formGroup]="infoFG" (ngSubmit)="submit()">
	<mat-dialog-content>
		<app-mobile-number [fg]="infoFG" fcName="mobileNo" [optional]="false" (changeEvent)="mobileNoVerifiedEvent($event)">
		</app-mobile-number>
	</mat-dialog-content>
	<mat-dialog-actions>
		<span class="ca-spacer"></span>
		<button mat-raised-button
			[disabled]="infoFG?.invalid || (infoFG.value.mobileNo && user?.isPhoneVerified &&  user?.mobileNo!==infoFG.value.mobileNo)"
			color="primary">Update</button>
	</mat-dialog-actions>
</form>