import { Component, Input, OnInit } from "@angular/core";
import { CHARACTER_GROUP } from "../report/character";
import { FormGroup } from "@angular/forms";
import { SelectionModel } from "../../utils/selection-model-material";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
	MAT_CHECKBOX_DEFAULT_OPTIONS,
	MatCheckboxDefaultOptions
} from "@angular/material/checkbox";

@Component({
	selector: "app-character-card-selecter",
	templateUrl: "./character-card-selecter.component.html",
	styleUrls: ["./character-card-selecter.component.scss"],
	providers: [
		{
			provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
			useValue: { clickAction: "noop" } as MatCheckboxDefaultOptions
		}
	]
})
export class CharacterCardSelecterComponent implements OnInit {
	@Input() n: number = 1;
	@Input() fcName!: string;
	@Input() fg!: FormGroup;
	selection!: SelectionModel<CHARACTER_GROUP>;
	get isMultiple() {
		return this.n > 1;
	}
	readonly characterGroups: {
		moral: (CHARACTER_GROUP | null)[];
		performance: (CHARACTER_GROUP | null)[];
	} = {
		moral: [
			"PURPOSEFULNESS",
			"HOPE",
			"TRUTHFULNESS",
			"LOVE",
			null,
			"RESPECT",
			"MINDFULNESS"
		],
		performance: [
			null,
			"WISDOM",
			"TRUSTWORTHINESS",
			"COMPASSION",
			"COURAGE",
			"TOLERANCE",
			"SELF-DISCIPLINE"
		]
	};
	constructor(private _snackBar: MatSnackBar) {}
	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.selection = new SelectionModel<CHARACTER_GROUP>(
			this.isMultiple,
			this.fg?.get(this.fcName)?.value || [],
			true
		);
	}
	select(characterStrengthGroup: CHARACTER_GROUP) {
		let isChanged = false;
		if (this.isMultiple) {
			if (this.selection.isSelected(characterStrengthGroup)) {
				this.selection.deselect(characterStrengthGroup);
				isChanged = true;
			} else {
				if (this.selection.selected.length < this.n) {
					this.selection.select(characterStrengthGroup);
					isChanged = true;
				} else {
					this._snackBar.open(
						`Please Select only ${this.n} Character Strength Groups`
					);
				}
			}
		} else {
			this.selection.select(characterStrengthGroup);
			isChanged = true;
		}
		if (isChanged) {
			if (this.isMultiple) {
				this.fg.get(this.fcName)?.setValue(this.selection.selected);
			} else {
				this.fg.get(this.fcName)?.setValue(this.selection.selected[0]);
			}
		}
	}
}
