import { OrgType } from "src/app/_shared/types/organisation";
import { ORG_TYPE_DISCOUNT_RATES } from "./../const/organisation";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "orgTypeDiscount"
})
export class OrgTypeDiscountPipe implements PipeTransform {
	transform(value?: OrgType, ...args: unknown[]): unknown {
		if (!value) return;
		return `${getOrgTypeDiscount(value) * 100}%`;
	}
}
export function getOrgTypeDiscount(value: OrgType) {
	return ORG_TYPE_DISCOUNT_RATES[value] || 0;
}
