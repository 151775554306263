import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { AccountService } from "src/app/_shared/services/account.service";
import { AuthService } from "src/app/_shared/services/auth.service";
import { countDown } from "src/app/_shared/utils/number";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { USER } from "../../types/user";
import { parsePhoneNumber } from "libphonenumber-js";
import { MobileNumberChangeEvent } from "../../types/common";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
	selector: "app-mobile-number",
	templateUrl: "./mobile-number.component.html",
	styleUrls: ["./mobile-number.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class MobileNumberComponent implements AfterViewInit, OnChanges {
	@Input() fg!: FormGroup;
	@Input() fcName: string = "mobileNo";
	@Input() verifiedMobileNo?: string;
	@Input() optional = true;
	@Input() isMobileNoVerified: boolean | undefined = false;
	@Output() changeEvent = new EventEmitter<MobileNumberChangeEvent>();
	isVerified = false;
	otpFC = new FormControl({ value: null, disabled: true }, Validators.required);
	subscriptions = new Subscription();
	secondsRemaining$?: Observable<number>;
	OTPsent = false;
	user!: USER | null;
	verifiedMobileNos: any[] = [];
	constructor(
		private accountService: AccountService,
		private authService: AuthService,
		private _snack: MatSnackBar
	) {
		this.getVerifiedMobileNumbers();
		this.subscriptions.add(
			this.authService.getUserInfo().subscribe(user => {
				this.user = user;
				if (!this.fg?.value[this.fcName]) {
					this.fg?.patchValue({
						[this.fcName]: this.user?.mobileNo
					});
				}
			})
		);
	}
	ngAfterViewInit(): void {
		this.subscriptions.add(
			this.fg?.get(this.fcName)?.valueChanges.subscribe(res => {
				this.updateValidity();
			})
		);
		this.subscriptions.add(
			this.otpFC.valueChanges.subscribe(value => {
				if (this.otpFC.valid) {
					this.verifyOTP();
				}
			})
		);
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.verifiedMobileNo) {
			this.updateValidity();
		}
	}
	setIsVerified(isMobileNoVerified: boolean, canGoNext: boolean = false) {
		this.isVerified = isMobileNoVerified;
		this.changeEvent.next({ isMobileNoVerified, canGoNext });
	}
	updateValidity(canGoNext = false) {
		let mobileNumber = this.fg?.get(this.fcName)?.value;
		if (!mobileNumber) {
			this.setIsVerified(false);
			return;
		}
		try {
			let parsedNum = parsePhoneNumber(mobileNumber).number;
			let verified = this.verifiedMobileNos.find(n => n.mobileNo === parsedNum);
			if (this.isMobileNoVerified && this.verifiedMobileNo === parsedNum) {
				this.setIsVerified(true);
			} else if (verified) {
				this.setIsVerified(true, canGoNext);
			} else {
				this.setIsVerified(false);
			}
		} catch (error) {
			this.setIsVerified(false);
		}
	}
	getVerifiedMobileNumbers(canGoNext = false) {
		this.accountService.getVerifiedMobileNos().subscribe((res: any) => {
			this.verifiedMobileNos = res.list;
			this.updateValidity(canGoNext);
		});
	}
	editMobileNo() {
		this.OTPsent = false;
	}
	sendOTP() {
		let payload = {
			mobileNo: this.fg?.value.mobileNo
		};
		this.accountService.sendOtp(payload).subscribe((res: any) => {
			if (res.statusCode === 200) {
				this.OTPsent = true;
				this.otpFC?.enable();
				this.secondsRemaining$ = countDown(20);
			}
		});
	}
	verifyOTP() {
		let payload = {
			mobileNo: this.fg?.value.mobileNo,
			OTP: this.otpFC.value
		};
		this.accountService.verifyOtp(payload).subscribe({
			next: (res: any) => {
				if (res.statusCode === 200) {
					this._snack.open("Mobile Number Verified Successfully");
					this.authService.setUserInfo(res.user);
					this.getVerifiedMobileNumbers(true);
				}
				this.otpFC.reset();
			}
		});
	}
}
