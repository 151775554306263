import { Pipe, PipeTransform } from "@angular/core";
import { truncate } from "lodash";

@Pipe({
	name: "truncate"
})
export class TruncatePipe implements PipeTransform {
	transform(
		value: string,
		length: number = 20,
		omission: string = "..."
	): string {
		return truncate(value, { length, omission });
	}
}
