import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
	selector: "app-star-rating",
	templateUrl: "./star-rating.component.html",
	styleUrls: ["./star-rating.component.scss"]
})
export class StarRatingComponent {
	@Input() fg!: FormGroup;
	@Input() fcName: string = "default";
	ratingLabels = [
		// "Not at all", "Rarely", "Sometimes", "Mostly", "Always"
		"Needs Focused Improvement",
		"Below Average",
		"Average",
		"Very Strong",
		"Role Model"
	];
	constructor() {}
	get value() {
		return this.fg.get(this.fcName)?.value;
	}
	rate(i: number) {
		this.fg.get(this.fcName)?.setValue(i);
	}
}
