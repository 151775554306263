import { formatDate } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { saveAs } from "file-saver";
import { environment } from "src/environments/environment";
import { queryParams } from "../types/common";
import { removeUndefined } from "../utils/object";
import { getSurveyURL } from "../utils/string";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Clipboard } from "@angular/cdk/clipboard";
import { C360_CATEGORY_KEY_MAP } from "../const/character360";
import { Character360, Step } from "../types/character360";
import { Router } from "@angular/router";
import {
	ConfirmDialogComponent,
	ConfirmDialogModel
} from "../components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Character360EditComponent } from "../components/character360-edit/character360-edit.component";
interface ReportQueryParamsOnly {
	userId?: number;
}
interface ReportQueryParams extends queryParams, ReportQueryParamsOnly {}
interface ReportDownload {
	report: any;
	previewOnly?: boolean;
}
@Injectable({
	providedIn: "root"
})
export class Character360Service {
	baseURL = environment.baseUrl;
	readonly surveyURL = getSurveyURL();
	constructor(
		public http: HttpClient,
		@Inject(LOCALE_ID) private locale: string,
		private clipboard: Clipboard,
		private _snackBar: MatSnackBar,
		private _dialog: MatDialog,
		private router: Router
	) {}
	getCharacter360Path(report?: any) {
		let path = ["character-360", "home"];
		if (report?.character360) {
			path.push("my-character-360");
			path.push(report.character360.id);
		} else {
			path.push(report.id);
			path.push("my-character-360");
		}
		return path;
	}
	startCharacter360(
		report?: any,
		{ openInNewTab }: { openInNewTab: boolean } = { openInNewTab: false }
	) {
		let path = this.getCharacter360Path(report);

		if (openInNewTab) {
			window.open(`${location.origin}/${path.join("/")}`);
		} else {
			this.router.navigate(path);
		}
	}
	post(payload: any) {
		return this.http.post(`${this.baseURL}/character360`, payload);
	}
	patch(id: number, payload: any) {
		return this.http.patch(`${this.baseURL}/character360/${id}`, payload);
	}
	get(qParams: {
		id?: number;
		UUIDV4?: string;
		reportId?: number;
		slug?: string;
	}) {
		let params = new HttpParams({ fromObject: removeUndefined(qParams) });
		return this.http.get(`${this.baseURL}/character360`, { params });
	}

	feedback(payload: any) {
		return this.http.post(`${this.baseURL}/character360/feedback`, payload);
	}
	getReport(qParams: { UUIDV4?: string; id?: number; previewOnly?: boolean }) {
		let params = new HttpParams({ fromObject: removeUndefined(qParams) });
		return this.http.get(`${this.baseURL}/character360/report`, { params });
	}
	getMyReports(queryParams: ReportQueryParams) {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...queryParams,
					sortBy: queryParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		return this.http.get(`${this.baseURL}/character360/reports`, { params });
	}

	async download({ report }: ReportDownload) {
		return new Promise<void>((resolve, reject) => {
			let filename: any = [report.name];

			let paramsObject: any = {
				id: report.id
			};
			const params = new HttpParams({
				fromObject: removeUndefined(paramsObject)
			});
			this.http
				.get(`${this.baseURL}/character360/download`, {
					params,
					responseType: "blob"
				})
				.subscribe(
					res => {
						let yearMonth = formatDate(Date.now(), "yyyy MM", this.locale);
						saveAs(res, `${yearMonth} Character 360 Report - ${filename}`);
						resolve();
					},
					err => {
						reject(err);
					}
				);
		});
	}

	getFeedbackURL(character360: Character360, step: Step) {
		let url = `${this.surveyURL}/c/f/${character360.slug}`;
		if (step) {
			url += `/${C360_CATEGORY_KEY_MAP[step.id]}`;
		}
		return url;
	}
	getInviteContent(step: Step, character360: Character360) {
		let subject = `Character 360 - ${step.singular} Feedback Invite`;
		let feedbackURL = this.getFeedbackURL(character360, step);
		let message = `Hi there,\r\n\r\nAs part of my ongoing professional/personal development, I’m undertaking a Character 360 assessment. I’m inviting you to provide authentic and honest feedback because I value your opinion and believe that you can provide insights that will contribute to my growth.\r\nThe survey link I’m sending you is asking between 5-7 minutes of your time from the perspective of a ${step.singular}.\r\n\r\n${feedbackURL} `;
		return { message, subject };
	}
	openEmailClient(character360: Character360, step: Step) {
		let { subject, message } = this.getInviteContent(step, character360);
		let url = `mailto:?subject=${subject}&body=${encodeURIComponent(message)}`;
		window.open(url, "_BLANK");
	}
	copyToClipboard(character360: Character360, step: Step) {
		let { message } = this.getInviteContent(step, character360);
		this.clipboard.copy(message);
		this._snackBar.open("Copied Message Template to your Clipboard");
	}
	copyLinkToClipboard(character360: Character360, step: Step) {
		let feedbackURL = this.getFeedbackURL(character360, step);
		this.clipboard.copy(feedbackURL);
		this._snackBar.open("Copied Link to your Clipboard");
	}
	sendSMS(character360: Character360, step: Step) {
		let { message } = this.getInviteContent(step, character360);
		let content = message;
		let payload = {
			id: character360.id,
			content
		};
		this.http.post(`${this.baseURL}/character360/sms`, payload).subscribe({
			next: (res: any) => {
				if (res.statusCode === 200) {
					this._snackBar.open("SMS has been sent to your number.");
				}
			}
		});
	}

	async sendSMSIfCanSend(character360: Character360, step: Step): Promise<any> {
		if (character360.report.isPaid) {
			if (character360.mobileNo && character360.isMobileNoVerified) {
				this.sendSMS(character360, step);
				return { success: true };
			} else {
				if (!character360.mobileNo) {
					if (await this.confirmIfEdit()) {
						return await this.openUpdateMobileDialog(character360);
					}
				}
				if (!character360.isMobileNoVerified) {
					if (await this.confirmIfVerify()) {
						return await this.openUpdateMobileDialog(
							character360,
							"Verify Mobile Number"
						);
					}
				}
				return { edit: false };
			}
		} else {
			this._snackBar.open("Please purchase full report to send SMS");
			return { paid: false };
		}
	}
	openUpdateMobileDialog(
		character360: Character360,
		title = "Update Mobile Number"
	) {
		return new Promise(resolve => {
			let ref = this._dialog.open(Character360EditComponent, {
				data: {
					character360,
					title
				},
				panelClass: "ca-dialog"
			});
			ref.afterClosed().subscribe(res => {
				resolve(res);
			});
		});
	}
	confirmIfEdit(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `You haven't registered your Mobile number. Do you want to update now?`;
			const dialogData = new ConfirmDialogModel("Update Mobile Number", {
				message,
				confirmText: "Yes",
				dismissText: "No"
			});
			const dialogRef = this._dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	confirmIfVerify(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `You haven't verified your mobile number, do you want to verify now? `;
			const dialogData = new ConfirmDialogModel("Verify Mobile Number", {
				message,
				confirmText: "Yes",
				dismissText: "No"
			});
			const dialogRef = this._dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
	confirmIfContinueWithoutVerifying(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			const message = `You haven't verified your mobile number, Do you still wish to continue?`;
			const dialogData = new ConfirmDialogModel("Confirm", {
				message,
				confirmText: "Yes",
				dismissText: "No"
			});
			const dialogRef = this._dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				panelClass: "ca-dialog",
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(resolve);
		});
	}
}
