<section>
    <h2 class="ca-chart-title">Character Strength Distribution (100% Total)</h2>
    <div class="ca-charts-container">
        <!-- TODO: fix loading  -->
        <div class="ca-chart" echarts [loadingOpts]="loadingOpts" [loading]="isLoading" [initOpts]="initOpts"
            [options]="default" [merge]="chartOptionMoral">
        </div>
        <!-- TODO: fix loading  -->
        <div class="ca-chart" echarts [loading]="isLoading" [initOpts]="initOpts" [options]="default"
            [merge]="chartOptionPerformance"></div>
    </div>
</section>