<ng-container [ngSwitch]="tokenStatus">
	<ng-container *ngSwitchCase="'WITHDRAWN'">
		<app-error-page [clientSide]=true [error]="{message:'Invite has been withdrawn by the inviter'}">
		</app-error-page>

	</ng-container>
	<ng-container *ngSwitchCase="'INVALID'">
		<app-error-page [clientSide]=true [error]="{message:'Invite is invalid'}">
		</app-error-page>
	</ng-container>
	<ng-container *ngSwitchCase="'VALID'">
		<section class="bonsai-bg" fxLayout="row" fxLayoutAlign="center start" fxFlexFill>
			<mat-card class="ca-center-container ca-tab-view" [class.ca-tab-show]="view ==='USER_CREATION'">
				<form #signupForm="ngForm" (submit)="continue()" [appMatchPassword]="['password', 'cpassword']">
					<mat-card-content>
						<div fxLayout="row" fxLayoutAlign="center center" class="ca-profile-pic-container">
							<div class="ca-profile-pic-view">
								<span class="ca-profile-pic" fxLayout="row" fxLayoutAlign="center center">
									<ng-container *ngIf="userProfileImage?.src; else profileElseTemplate">
										<img width="100%" [src]="userProfileImage?.src" alt="" srcset="">
									</ng-container>
									<ng-template #profileElseTemplate>
										<img width="100%" src="/assets/images/avatar.jpg" alt="" srcset="">
									</ng-template>
									<input #file id="profilePic" name="imageKey" type="file" (change)="onUserProfileUpload($event)">
								</span>
								<button class="ca-edit" (click)="file.click()" type="button" mat-icon-button>
									<mat-icon>edit</mat-icon>
								</button>
								<button class="ca-remove" *ngIf="userProfileImage?.src" (click)="removeProfilePic()" type="button"
									mat-icon-button>
									<mat-icon>close</mat-icon>
								</button>
							</div>
						</div>
						<div>
							<h1>{{ typeOf|titlecase}} Setup</h1>
							<span><b> Step 1 of 2 : Basic Information</b></span>
							<mat-form-field fxFlexFill>
								<mat-label>First Name</mat-label>
								<input #firstName="ngModel" matInput required name="firstName" [(ngModel)]="signupFormModel!.firstName">
								<mat-error *ngIf="firstName.touched && firstName.errors?.required">
									Please type in First Name.
								</mat-error>
							</mat-form-field>
							<mat-form-field fxFlexFill>
								<mat-label>Last Name</mat-label>
								<input #lastName="ngModel" matInput required name="lastName" [(ngModel)]="signupFormModel!.lastName">
								<mat-error *ngIf="lastName.touched && lastName.errors?.required">
									Please type in Last Name.
								</mat-error>
							</mat-form-field>
							<mat-form-field fxFlexFill>
								<mat-label>Mobile Number</mat-label>
								<input #mobileNo="ngModel" matInput required type="tel"
									placeholder="[Country Code][Number] +61 490 000 000" name="mobileNo" appPhoneNumber
									[(ngModel)]="signupFormModel!.mobileNo">
								<mat-error *ngIf="mobileNo.touched && mobileNo.errors?.required">
									Please type in Mobile No.
								</mat-error>
								<mat-error *ngIf="mobileNo.touched && mobileNo.errors?.phone">
									Invalid Mobile No. format:[Country Code][Number]
								</mat-error>
							</mat-form-field>
							<mat-form-field fxFlexFill>
								<mat-label>Email address</mat-label>
								<input #emailId="ngModel" matInput required type="email" placeholder="abc@example.com" name="emailId"
									[(ngModel)]="signupFormModel!.emailId">
								<mat-error *ngIf="emailId.touched && emailId.errors?.required">
									Please type in Email Id.
								</mat-error>
							</mat-form-field>
							<mat-form-field fxFlexFill>
								<mat-label>Enter a New Password</mat-label>
								<input #password="ngModel" matInput required type="password" name="password"
									[(ngModel)]="signupFormModel!.password">
								<mat-error *ngIf="password.touched && password.errors?.required">
									Please type in Password.
								</mat-error>
							</mat-form-field>
							<mat-form-field fxFlexFill>
								<mat-label>Confirm Password</mat-label>
								<input #confirmPassword="ngModel" matInput required type="password" name="cpassword"
									[(ngModel)]="signupFormModel!.cpassword">
								<mat-error *ngIf="confirmPassword.touched && confirmPassword.errors?.required">
									Please type in Password.
								</mat-error>
								<mat-error *ngIf="confirmPassword.touched && confirmPassword.errors?.passwordMismatch">
									Please type in Password same as above
								</mat-error>
							</mat-form-field>
						</div>
					</mat-card-content>
					<mat-card-actions class="ca-margin-0" fxLayout="row">
						<span class="ca-spacer"></span>
						<button mat-flat-button color="primary">Continue</button>
					</mat-card-actions>
				</form>
			</mat-card>

			<mat-card class="ca-center-container ca-tab-view" [class.ca-tab-show]="view ==='GROUP_CREATION'">
				<form #createGroupForm="ngForm" (submit)="signup()">
					<mat-card-content>
						<div fxLayout="row" fxLayoutAlign="center center" *ngIf="!isJoinOrgInvite" class="ca-profile-pic-container">
							<div class="ca-profile-pic-view">
								<span class="ca-profile-pic" fxLayout="row" fxLayoutAlign="center center">
									<ng-container *ngIf="groupProfileImage?.src; else groupprofileElseTemplate">
										<img width="100%" [src]="groupProfileImage?.src" alt="" srcset="">
									</ng-container>
									<ng-template #groupprofileElseTemplate>
										<img width="100%" src="/assets/images/group-logo-placeholder.png">
									</ng-template>
									<input #file1 id="groupPic" name="imageKey" type="file" (change)="onGroupProfileUpload($event)">
								</span>
								<button class="ca-edit" (click)="file.click()" type="button" mat-icon-button>
									<mat-icon>edit</mat-icon>
								</button>
								<button class="ca-remove" *ngIf="groupProfileImage?.src" (click)="removeGroupPic()" type="button"
									mat-icon-button>
									<mat-icon>close</mat-icon>
								</button>
							</div>
						</div>
						<div>
							<h1>{{ typeOf|titlecase }} Setup</h1>
							<span><b> Step 2 of 2 : Group Information</b></span>
							<ng-container *ngIf="isJoinOrgInvite; else elseTemplate">
								<div>
									You have been invited to join <b>{{org.name}}</b>
								</div>
							</ng-container>
							<ng-template #elseTemplate>
								<div *ngIf="org">
									You have been invited to join <b>{{org.name}}</b> as a Client Organisation
								</div>
								<mat-form-field fxFlexFill>
									<mat-label>Create a Group</mat-label>
									<input #name="ngModel" matInput required placeholder="Type in Group Name" name="name"
										[(ngModel)]="createGroupFormModel!.name">
									<mat-error *ngIf="name.touched && name.errors?.required">
										Please type in Group Name.
									</mat-error>
								</mat-form-field>
								<mat-form-field fxFlexFill>
									<mat-label>Add members</mat-label>
									<mat-chip-list #chipList aria-label="Fruit selection">
										<mat-chip [selected]="item.invalid" [color]="(item.invalid)?'warn':''"
											*ngFor="let item of createGroupFormModel?.membersToBeInvited; let i=index"
											(removed)="removeEmail(i,members)">
											{{item.email}}
											<button matChipRemove>
												<mat-icon>cancel</mat-icon>
											</button>
										</mat-chip>
										<input #members="ngModel" name='memberfieldRaw' [(ngModel)]="memberfieldRaw" type="email"
											placeholder="Type in Email address" [matChipInputFor]="chipList"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
											(matChipInputTokenEnd)="addEmail($event,members)">
									</mat-chip-list>
									<mat-error *ngIf="members.touched && members.errors?.containsInvalidEmail">
										Contains one or more Invalid Email address
									</mat-error>
								</mat-form-field>
							</ng-template>
						</div>
					</mat-card-content>
					<mat-card-actions class="ca-margin-0" fxLayout="row">
						<button type="button" mat-button (click)="back()">Back</button>
						<span class="ca-spacer"></span>
						<button mat-flat-button color="primary">Complete</button>
					</mat-card-actions>
				</form>
			</mat-card>
		</section>
	</ng-container>
</ng-container>