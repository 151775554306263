<h1 mat-dialog-title>STEP Group</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<ng-container [ngSwitch]="view">
	<ng-container *ngSwitchCase="'details'">
		<form [formGroup]="fg">
			<mat-dialog-content>
				<div fxLayout="row" fxLayoutAlign="center center" class="ca-profile-pic-container">
					<div class="ca-profile-pic-view">
						<span class="ca-profile-pic" fxLayout="row" fxLayoutAlign="center center">
							<ng-container *ngIf="!profileImage?.src && fg.value.imageKey; else uploadedProfileElseTemplate">
								<img width="100%" [src]="fg.value.imageKey|filepath:'STEP'">
							</ng-container>
							<ng-template #uploadedProfileElseTemplate>
								<ng-container *ngIf="profileImage?.src; else profileElseTemplate">
									<img width="100%" [src]="profileImage?.src">
								</ng-container>
							</ng-template>
							<ng-template #profileElseTemplate>
								<img width="100%" src="/assets/step_group.svg">
							</ng-template>
							<input #file id="profilePic" name="imageKey" type="file" (change)="onProfileUpload($event)">
						</span>
						<button class="ca-edit" (click)="file.click()" type="button" mat-icon-button>
							<mat-icon>edit</mat-icon>
						</button>
						<button class="ca-remove" *ngIf="profileImage?.src" (click)="removeProfilePic()" type="button"
							mat-icon-button>
							<mat-icon>close</mat-icon>
						</button>
					</div>
				</div>
				<div>
					<mat-form-field class="full-width">
						<mat-label>Group Name</mat-label>
						<input type="text" matInput formControlName="groupName" required>
					</mat-form-field>
				</div>
				<br>
				<div>
					How often would you like members to check-in?
				</div>
				<div>
					<mat-form-field class="full-width">
						<mat-label>Select often to check-in</mat-label>
						<mat-select formControlName="checkInOften" required>
							<mat-option *ngFor="let checkInOften of checkInOftenList" [value]="checkInOften.value">
								{{checkInOften.label}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<br>
				<div>
					Select a desired time for members to check-in
				</div>
				<div class="flex justify-center">
					<ngx-mat-timepicker formControlName="checkIn" [showSpinners]="true" [stepHour]="1" [stepMinute]="1"
						[stepSecond]="0" [showSeconds]="false" color="primary" [enableMeridian]="true">
					</ngx-mat-timepicker>
				</div>
				<br>
				<ng-container *ngIf="fg.value.checkInOften==='WEEKLY'">
					<div>
						Which day would you like to be reminded?
					</div>
					<div>
						<mat-form-field class="full-width">
							<mat-label>Select day to be reminded</mat-label>
							<mat-select formControlName="remindDay" required>
								<mat-option *ngFor="let day of weekDays" [value]="day.value">
									{{day.label}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<br>
				</ng-container>
				<div fxLayout="row" fxLayoutAlign="stretch center" style="height: 40px;">
					Adding Group Support Info
					<span class="ca-spacer"></span>
					<span>
						{{customSupportNosList?.length||0}}/3
						<button mat-icon-button class="cursorPointer" color="primary"
							[matTooltip]="fg.value.copiedFromParent?'Please Turn off Sync from Parent Group to Add/Update Support Info':'Add upto 3 Support Infos'"
							[disabled]="fg.value.copiedFromParent ||customSupportNos.controls.length>=3"
							(click)="addSupport()"><mat-icon>add_circle_outline</mat-icon>
						</button>
					</span>
				</div>
				<div class="info">
					<ng-container *ngIf="group?.copiedFromParent">
						<mat-hint *ngIf="copiedFromParent.value">
							<mat-icon inline=true color="primary">sync</mat-icon> The Group inherits the Support Info and Profile
							Image from its
							Parent Group, including any future updates.
						</mat-hint>
						<mat-hint *ngIf="!copiedFromParent.value">
							<mat-icon inline=true color="warn">sync_disabled</mat-icon>
							The group will maintain its own support info and profile image, independent of any future updates from the
							parent group.
							<mat-error>Once saved, this action is not reversible.</mat-error>
						</mat-hint>
					</ng-container>
					<mat-hint *ngIf="!group?.copiedFromParent">
						<mat-icon>sync_disabled</mat-icon> The Group now maintains its own Support Info and Profile Image,
						independent of any future updates from the
						Parent Group.
					</mat-hint>
				</div>
				<div class="custom-supports">
					<mat-card class="custom-support" formArrayName="customSupportNos"
						*ngFor="let customSupportNo of customSupportNosList; let i = index">
						<button mat-icon-button class="delete-icon cursorPointer" color="warn" [disabled]="copiedFromParent.value"
							[matTooltip]="copiedFromParent.value?'Please Turn off Sync from Parent Group to Remove/Update Support Info':''"
							(click)="removeSupport(i)"><mat-icon>delete</mat-icon>
						</button>
						<div>
							<mat-form-field class="full-width">
								<mat-label>Group Support Information Label</mat-label>
								<ng-container *ngIf="!copiedFromParent.value" [formGroupName]="i">
									<input type="text" matInput formControlName="contactName">
								</ng-container>
								<input (click)="stopInheritFromParentGroup()" readonly *ngIf="copiedFromParent.value" type="text"
									matInput [value]="group?.customSupportNos[i].contactName || ''">
							</mat-form-field>
						</div>
						<div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="8px">
							<mat-form-field fxFlex="50%" fxFlexFill>
								<mat-label>Group Support Number</mat-label>
								<ng-container *ngIf="!copiedFromParent.value" [formGroupName]="i">
									<input type="text" matInput formControlName="contactNo">
								</ng-container>
								<input (click)="stopInheritFromParentGroup()" readonly *ngIf="copiedFromParent.value" type="text"
									matInput [value]="group?.customSupportNos[i].contactNo || ''">
							</mat-form-field>
							&nbsp;
							<span>
								or
							</span>
							&nbsp;
							<mat-form-field fxFlex="50%" fxFlexFill>
								<mat-label>Group Support Information URL Link</mat-label>
								<ng-container *ngIf="!copiedFromParent.value" [formGroupName]="i">
									<input type="text" matInput formControlName="supportLink">
								</ng-container>
								<input (click)="stopInheritFromParentGroup()" readonly *ngIf="copiedFromParent.value" type="text"
									matInput [value]="group?.customSupportNos[i].supportLink || ''">
							</mat-form-field>
						</div>
						<!-- <div>
								<mat-checkbox formControlName="copyToDependents" color="primary">
									Copy Info to Dependent Groups
								</mat-checkbox>
							</div> -->
						<!-- </ng-container> -->
					</mat-card>
					<mat-card *ngIf="!customSupportNosList?.length">
						<mat-hint *ngIf="copiedFromParent.value">
							The Parent Group has not yet added their Group Support Information. <br>
							<br>
							<button mat-stroked-button color="primary" (click)="stopInheritFromParentGroup()">Add Your Support Numbers
							</button>
						</mat-hint>
						<mat-hint *ngIf="!copiedFromParent.value">
							Please click on <button mat-icon-button class="cursorPointer" color="primary"
								[matTooltip]="fg.value.copiedFromParent?'Please Turn off Sync from Parent Group to Add/Update Support Info':'Add upto 3 Support Infos'"
								[disabled]="fg.value.copiedFromParent ||customSupportNos.controls.length>=3"
								(click)="addSupport()"><mat-icon>add_circle_outline</mat-icon>
							</button> to Add Group Support Information
						</mat-hint>
					</mat-card>
					<br>
					<mat-checkbox formControlName="copyToDependents" color="primary">
						Copy Info to Dependent Groups
					</mat-checkbox>
				</div>
			</mat-dialog-content>
			<mat-dialog-actions>
				<button type="button" mat-button [mat-dialog-close]>Cancel</button>
				<span class="ca-spacer"></span>
				<button type="button" *ngIf="!new" mat-stroked-button color="primary" (click)="setGroupData()">Reset</button>
				<button type="button" *ngIf="!new" [disabled]="fg.invalid" mat-raised-button color="primary" (click)="save()">
					Save
				</button>
				<button type="button" *ngIf="new" [disabled]="fg.invalid" mat-raised-button color="primary"
					(click)="goto('invite_members')">
					Next
				</button>
			</mat-dialog-actions>
		</form>
	</ng-container>
	<ng-container *ngSwitchCase="'invite_members'">
		<mat-dialog-content>
			<app-ep-user-search [fcName]="'forests'" [fg]="fg" [multiple]="true">
			</app-ep-user-search>
			<div class="members" *ngFor="let item of fg.get('forests')?.value;let i=index">
				<div class="member">
					<app-avatar [avatar]="item" forceType="STEP_USER_PROFILE">
					</app-avatar>
					<button mat-icon-button (click)="remove(i)">
						<mat-icon>close</mat-icon>
					</button>
				</div>
			</div>
		</mat-dialog-content>
		<mat-dialog-actions>
			<button type="button" mat-button (click)="goto('details')">Back</button>
			<span class="ca-spacer"></span>
			<button type="button" [disabled]="fg.invalid" mat-raised-button color="primary" (click)="complete()">
				Complete
			</button>
			<button type="button" [disabled]="fg.invalid" mat-stroked-button color="primary" (click)="createDependents()">
				Create Dependents
			</button>
		</mat-dialog-actions>
	</ng-container>
</ng-container>
