import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';
var renderer = new marked.Renderer();
renderer.link = function(href, title, text) {
    var link = marked.Renderer.prototype.link.apply(this, [href, title, text]);
    return link.replace("<a","<a target='_blank'");
};

marked.setOptions({
    renderer: renderer
});

@Pipe({
  name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {

  transform(value: string) {
    if (value && value.length > 0) {
      return marked(value);
    }
    return value;
  }
}
