import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Character360Service } from "../../services/character360.service";

@Component({
	selector: "app-c360-offer-progress-dialog",
	templateUrl: "./c360-offer-progress-dialog.component.html",
	styleUrls: ["./c360-offer-progress-dialog.component.scss"]
})
export class C360OfferProgressDialogComponent {
	basePath = location.origin;

	constructor(
		public dialogRef: MatDialogRef<C360OfferProgressDialogComponent>,
		public character360Service: Character360Service,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	get nMoreResponses() {
		return 6 - this.data.report.character360.totalResponsesCount;
	}
}
