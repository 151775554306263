import { Component, Inject, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ORG } from "../../types/organisation";
import { ReportService } from "../../services/report.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
	selector: "app-migrate-report",
	templateUrl: "./migrate-report.component.html",
	styleUrls: ["./migrate-report.component.scss"]
})
export class MigrateReportComponent {
	fg!: FormGroup;
	org?: ORG;
	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<MigrateReportComponent>,
		private _snackBar: MatSnackBar,

		private reportService: ReportService,
		@Inject(MAT_DIALOG_DATA) public dialogData: any
	) {
		this.fg = this.fb.group({
			reportId: [dialogData.report.id, Validators.required],
			partnerSurvey: [, Validators.required]
		});
	}
	get partnerSurveyCtrl(): FormControl {
		return this.fg.get("partnerSurvey") as FormControl;
	}
	updateOrg(org: ORG) {
		this.org = org;
	}
	submit() {
		let payload = {
			reportId: this.dialogData.report.id,
			partnerSurveyId: this.fg.value.partnerSurvey.id
		};
		this.reportService.migrateReport(payload).subscribe((res: any) => {
			if (res.statusCode === 201) {
				this._snackBar.open(res.message);
				this.dialogRef.close({ refresh: true });
			}
		});
	}
}
