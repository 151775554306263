<mat-form-field style="width: 100%;">
	<mat-select [formControl]="reportCtrl" [placeholder]="options?.label||'User Report'" #singleSelect
		[compareWith]="compareFn">
		<mat-option>
			<ngx-mat-select-search [clearSearchInput]="false" [formControl]="reportFilterCtrl"
				[noEntriesFoundLabel]="'No matching Found'" placeholderLabel="Search User Report">
			</ngx-mat-select-search>
		</mat-option>
		<mat-option>
			None
		</mat-option>
		<mat-option *ngFor="let report of filteredreports | async" [value]="report" [disabled]="report.disabled">
			{{report.name}}
		</mat-option>
	</mat-select>
	<button *ngIf="reportCtrl.value" matSuffix color="accent" mat-icon-button aria-label="Clear"
		(click)="clearSelection($event)">
		<mat-icon>close</mat-icon>
	</button>
</mat-form-field>
