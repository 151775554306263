export const environment = {
	production: false,
	version: require("../../package.json").version + "-dev",
	baseUrl: "https://stcaapi-test.sharetree.org",
	stepBaseUrl: "https://stepapi-test.sharetree.org",
	stripePublicKey: "pk_test_mzWJdbfnd29hDiWiibVhvocn0071kvL8LB",
	IPInfoToken: "7a1be552310328",
	defaultSurvey: "ssd-ugq-yuo-e2a",
	recaptcha: {
		siteKey: "6Le97JMUAAAAAE5kQuczw6le-SCSqfDNqaxo5cLv"
	},
	google: {
		clientId:
			"943838639478-b0e3eod8r56um4h5bfe2lhnml4k4211n.apps.googleusercontent.com"
	},
	microsoft: {
		clientId: "1cafcc94-b554-4297-9ed3-c3b930de8964",
		tenantId: "9efee338-8e96-4a02-b0f2-dd2cb0aa5f58"
	},
	// API key for Browser data source in Amplitude (Not same as in Server)
	amplitudeApiKey: "8b3bd476e78662ccba95527665777748"
};
