<mat-action-list fxLayout="row" style="padding-top: 0;">
	<mat-list-item (click)="dialogsService.openLinkAccount();"
		*ngIf="ST_PLATFORM==='STC360' && !user.googleId && !user.microsoftId ">
		<mat-icon class="rotate45deg" matListIcon>link</mat-icon>
		<span matLine>
			Click here to link your Gmail or Outlook accounts for easier access.
		</span>
	</mat-list-item>
	<mat-list-item [matMenuTriggerFor]="menu">
		<app-avatar [avatar]="user" [showEmail]="true" [emailCopyToClipboard]="false">
		</app-avatar>
		<mat-icon>expand_more</mat-icon>
	</mat-list-item>
</mat-action-list>
<mat-menu #menu="matMenu" xPosition='before' yPosition="below">
	<button mat-menu-item *ngIf="user.role === 'USER'" (click)="openChangePassword()">
		<mat-icon>password</mat-icon>
		<span>Change Password</span>
	</button>
	<button mat-menu-item *ngIf="ST_PLATFORM==='STC360' && user.role === 'USER' && (user.googleId || user.microsoftId)"
		(click)="dialogsService.openLinkAccount('MANAGE');">
		<mat-icon>manage_accounts</mat-icon>
		<span>Linked Accounts</span>
	</button>
	<button mat-menu-item (click)="logout()">
		<mat-icon>logout</mat-icon>
		<span>Logout</span>
	</button>
</mat-menu>
