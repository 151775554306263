import { OrgType } from "src/app/_shared/types/organisation";
import { Pipe, PipeTransform } from "@angular/core";
import { ORG_TYPE_DISPLAY_MAP } from "../const/organisation";

@Pipe({
	name: "orgType"
})
export class OrgTypePipe implements PipeTransform {
	transform(value?: OrgType, ...args: unknown[]): unknown {
		if (!value) return;
		return getOrgType(value);
	}
}
export function getOrgType(value: OrgType) {
	return ORG_TYPE_DISPLAY_MAP[value] ?? "None";
}
