import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { DeviceService } from "../../services/device.service";
import { DialogsService } from "../../services/dialogs.service";
import { OrganisationService } from "../../services/organisation.service";
import { ST_PLATFORM } from "../../types/common";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { AmplitudeService } from "../../services/amplitude.service";
@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class LoginComponent implements OnInit {
	@Input() report!: any;
	@Input() redirectToURL: boolean = true;
	@Input() flat: boolean = false;
	@Input() noBG: boolean = false;
	@Input() type: ST_PLATFORM;
	@Input() autoLogin: boolean = true;
	@Input() titleType?: "CONTINUE_C360";
	@Input() prefillData?: any;
	@Output() loggedIn: EventEmitter<void> = new EventEmitter();
	subscriptions: Subscription = new Subscription();
	returnUrl: string = "/home";
	isVisible = false;
	deviceInfo: any;
	timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
	loginFormModel: {
		emailId?: string;
		password?: string;
	} = {};
	user: any;
	isNewUser = false;
	handleLogin = {
		next: async (res: any) => {
			this.UiLoaderService.stop();
			if (res.statusCode == 200) {
				this.user = res.user;
				this.amplitudeService.setUserId(this.user.id);
				this.amplitudeService.track("Logged In");
				this.amplitudeService.setUserProperty("emailId", this.user.emailId);
				this.amplitudeService.setUserProperty("device", this.deviceInfo);
				this.authService.setAuth(res.authToken);
				this.authService.setUserInfo(res.user);
				this.authService.setLoggedIn();
				if (this.user.role === "USER" && this.user.forceChangePwd) {
					await this.openChangePassword();
				}
				this.loggedIn.emit();
				if (this.redirectToURL) {
					this.organisationService.refreshOrgs();
					this.router.navigateByUrl(this.returnUrl);
				}
			}
		},
		error: () => {
			this.UiLoaderService.stop();
		}
	};
	constructor(
		private amplitudeService: AmplitudeService,
		private UiLoaderService: NgxUiLoaderService,
		private accountService: AccountService,
		public authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private organisationService: OrganisationService,
		private router: Router,
		private _dialog: MatDialog,
		private deviceService: DeviceService,
		private dialogsService: DialogsService
	) {
		this.type ??= activatedRoute.snapshot.data["type"];
	}
	ngOnInit(): void {
		this.setReturnURL();
		this.checkIfHasActiveSession();
		this.getDeviceInfo();
		this.prefill();
		this.isNewUser = this.activatedRoute.snapshot.queryParams["isNewUser"];
	}
	prefill() {
		if (this.report) {
			this.setEmail(this.report?.email);
		}
		if (this.activatedRoute.snapshot.queryParams["prefillEmailId"]) {
			this.setEmail(this.activatedRoute.snapshot.queryParams["prefillEmailId"]);
		}
		if (this.prefillData?.emailId) {
			this.setEmail(this.prefillData?.emailId);
		}
	}
	setEmail(emailId: string) {
		this.loginFormModel["emailId"] = emailId;
	}
	setReturnURL() {
		this.authService.setSTPlatform(this.type);
		switch (this.type) {
			case "STCA":
				this.returnUrl = "/home";
				break;
			case "STC360":
				this.returnUrl = "/character-360";
				break;
		}
	}

	async getDeviceInfo() {
		this.deviceInfo = await this.deviceService.getDeviceInfo();
	}
	login() {
		this.UiLoaderService.start();
		let payload = {
			...this.loginFormModel,
			timeZone: this.timeZone,
			device: this.deviceInfo
		};
		this.amplitudeService.track("Login using Email submitted");
		this.accountService.login(payload).subscribe(this.handleLogin);
	}
	async checkIfHasActiveSession() {
		let isLoggedIn = this.authService.isLoggedIn();
		if (this.activatedRoute.snapshot.queryParams["returnUrl"]) {
			this.returnUrl = this.activatedRoute.snapshot.queryParams["returnUrl"];
		}
		const queryParamsSnapshot = this.activatedRoute.snapshot.queryParams;
		if (this.autoLogin && queryParamsSnapshot?.authorization) {
			let newAuthToken = queryParamsSnapshot?.authorization;
			let newAuthType = queryParamsSnapshot?.authType;
			let existingAuth = this.authService.getAuth();
			if (!isLoggedIn || existingAuth.token !== newAuthToken) {
				if (isLoggedIn) await this.authService.logout(false);
				this.authService.setAuth(newAuthToken, newAuthType);
				this.authService.setLoggedIn();
				await this.setUserInfoFromGetAccount();
				isLoggedIn = true;
			}
		}
		if (isLoggedIn) {
			if (this.redirectToURL) this.router.navigateByUrl(this.returnUrl);
		}
	}
	setUserInfoFromGetAccount() {
		return this.authService.setUserInfoFromGetAccount();
	}
	openForgotPassword() {
		this._dialog.open(ForgotPasswordComponent, {
			minWidth: "500px",
			panelClass: "ca-dialog",
			data: {
				for: "STCA"
			}
		});
	}
	openChangePassword() {
		return new Promise<void>(resolve => {
			let dialogRef = this.dialogsService.openChangePassword(
				"change-password-STCA",
				{ forPlatform: "STCA", force: true }
			);
			if (dialogRef) {
				this.subscriptions.add(
					dialogRef.afterClosed().subscribe((res: any) => {
						if (res?.changed) {
							this.user.forceChangePwd = false;
							this.authService.setUserInfo(this.user);
							resolve();
						}
					})
				);
			}
		});
	}
	signedInWithGoogle(token: string) {
		this.UiLoaderService.start();
		let payload = {
			token,
			timeZone: this.timeZone,
			device: this.deviceInfo
		};
		this.amplitudeService.track("Login using Google submitted");
		this.accountService.loginWithGoogle(payload).subscribe(this.handleLogin);
	}
	signedInWithMicrosoft(data: any) {
		this.UiLoaderService.start();
		let payload = {
			token: data.idToken,
			timeZone: this.timeZone,
			device: this.deviceInfo
		};
		this.amplitudeService.track("Login using Microsoft 365 submitted");
		this.accountService.loginWithMicrosoft(payload).subscribe(this.handleLogin);
	}
}
