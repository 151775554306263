import { CATEGORY_KEYS } from "../types/character360";
import { ACTIVITY_OF, LOG_TYPE, LOG_TYPE_STATUS, Log } from "../types/log";
import { C360_CATEGORY_LABEL_MAP } from "./character360";

export const LOG_TYPE_STATUSES: LOG_TYPE_STATUS[] = [
	"C360_CREATED",
	"C360_FEEDBACK_CREATED",
	"C360_REPORT_DOWNLOAD",
	"REPORT_PAID_BY_PARTNER",
	"REPORT_PAID_BY_SHARETREE",
	"REPORT_UTILIZED_PARTNER_SURVEY",
	"REPORT_CREATED",
	"REPORT_DOWNLOAD",
	"REPORT_PURCHASED",
	"REPORT_REMOVED_FROM_PARTNER_SURVEY",
	"REPORT_ADDED_TO_PARTNER_SURVEY",
	"GROUP_REPORT_DOWNLOAD",
	"GROUP_REPORT_CREATED",
	"REPORT_GENERATED_PARTNER_SURVEY"
];

export const LOG_TYPES: LOG_TYPE[] = [
	...LOG_TYPE_STATUSES,
	"INCREASE_NO_OF_REPORTS",
	"DECREASE_NO_OF_REPORTS",
	"ORGANISATION_UPDATE"
];
export type LOG_TEMPLATE =
	| string
	| Function
	| { [s in ACTIVITY_OF]?: string | Function };
export const LOG_STATUS_MAP: { [s in LOG_TYPE]?: LOG_TEMPLATE } = {
	C360_CREATED: "Character 360 Created",
	C360_REPORT_DOWNLOAD: "Character 360 Report Downloaded",
	REPORT_PAID_BY_PARTNER: "Report Paid by Partner using own credits",
	REPORT_PAID_BY_SHARETREE: "Report Paid by ShareTree",
	REPORT_UTILIZED_PARTNER_SURVEY: {
		REPORT: (log: Log) =>
			`Assessment Done - <b>${log?.partnerSurvey?.name}</b> (Assessment)`,
		PARTNER_SURVEY: (log: Log) =>
			`Assessment Done - <b>${log.report?.name}</b> (Report)`
	},
	REPORT_GENERATED_PARTNER_SURVEY: {
		REPORT: (log: Log) =>
			`Assessment Done - <b>${log?.partnerSurvey?.name}</b> (Assessment)`,
		PARTNER_SURVEY: (log: Log) =>
			`Assessment Done - <b>${log.report?.name}</b> (Report)`
	},
	REPORT_CREATED: "Report Generated",
	REPORT_DOWNLOAD: "Report Downloaded",
	REPORT_REMOVED_FROM_PARTNER_SURVEY: {
		REPORT: (log: Log) =>
			`Removed from Assessment - <b>${log?.partnerSurvey?.name} (${log?.partnerSurvey?.org?.name})</b>`,
		PARTNER_SURVEY: (log: Log) =>
			`Moved Report <b>${log.report?.name}</b> to <b>${log?.newValue?.name} (${log?.newValue?.org?.name})</b>`
	},
	REPORT_ADDED_TO_PARTNER_SURVEY: {
		REPORT: (log: Log) =>
			`Added to Assessment - <b>${log?.partnerSurvey?.name} (${log?.partnerSurvey?.org?.name})</b>`,
		PARTNER_SURVEY: (log: Log) =>
			`Moved Report <b>${log.report?.name}</b> from <b>${log?.oldValue?.name} (${log?.oldValue?.org?.name})</b>`
	},
	C360_FEEDBACK_CREATED: (log: Log) =>
		`Character 360 <b>${
			C360_CATEGORY_LABEL_MAP[
				log.character360Feedback?.category as CATEGORY_KEYS
			]
		}</b> response received.`,
	REPORT_PURCHASED: "Report Purchased By User",
	GROUP_REPORT_DOWNLOAD: "Group Report Downloaded",
	GROUP_REPORT_CREATED: "Generated Group Report"
};
export const ACTIVITY_OFS: ACTIVITY_OF[] = [
	"PARTNER_SURVEY",
	"GROUP_REPORT",
	"REPORT"
];
