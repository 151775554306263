<section fxLayout="column" >
	<div fxLayout="row wrap" fxLayoutAlign="start center">
		<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px">
			<mat-form-field>
				<mat-label>Filter</mat-label>
				<input matInput placeholder="Ex. Mia" #input [formControl]="filterFormControl">
				<button matSuffix mat-icon-button (click)="filterFormControl.setValue('')">
					<mat-icon *ngIf="filterFormControl.value" aria-label="Clear">close</mat-icon>
					<app-loader [loaderId]="loaderId" [bgsSize]="24" bgsPosition="center-center"></app-loader>
				</button>
			</mat-form-field>
		</div>
		<span class="ca-spacer"></span>
		<mat-paginator class="ca-mat-paginator" [length]="resultsLength" [pageSize]="pageSize"
			[showFirstLastButtons]="(resultsLength/pageSize)>2" [pageSizeOptions]="[10,25,50,100]" aria-label="Select page">
		</mat-paginator>
	</div>
	<div fxLayout="column" class="ca-page-content">
		<div fxLayout="row" fxLayoutAlign="center center" fxFlex="shrink" *ngIf="!isLoadingResults  && data.length===0">
			<div *ngIf="(filterFormControl?.value===null || filterFormControl?.value?.length===0)">
				All Your Reports will appear here
			</div>
			<div *ngIf="filterFormControl?.value?.length>0">
				There are no results available for this keyword <b>"{{ filterFormControl.value}}"</b>. Please
				refine
				your search with some other words.
			</div>
		</div>
		<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
		<div class="ca-mat-table-container"  [hidden]="isLoadingResults || data.length===0">
				<app-ng-scrollbar-wrapper>
					<mat-table class="mat-elevation-z8"  [dataSource]="data" matSort [matSortActive]="'createdAt'" matSortDirection="desc">
						<ng-container matColumnDef="select">
							<mat-header-cell *matHeaderCellDef>
								<mat-icon *ngIf="selection.selected.length>0" matTooltip="Deselect All"
									(click)="deselectAll()">deselect</mat-icon>
							</mat-header-cell>
							<mat-cell *matCellDef="let row">
								<mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="selectionChange($event,row)"
									[checked]="selection.isSelected(row)">
								</mat-checkbox>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="index" sticky>
							<mat-header-cell *matHeaderCellDef>
								No.
							</mat-header-cell>
							<mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="name" sticky>
							<mat-header-cell *matHeaderCellDef mat-sort-header="name">
								Group Name
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<ng-container *ngIf="element.name">
									{{element.name|firstCap}}
								</ng-container>
							</mat-cell>
						</ng-container>

						<ng-container matColumnDef="reportNames">
							<mat-header-cell *matHeaderCellDef>
								Member Names
							</mat-header-cell>
							<mat-cell *matCellDef="let element"> {{element.reportNames.join(", ")}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="org">
							<mat-header-cell *matHeaderCellDef>
								Organisation
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<app-avatar [avatar]="element.org">
								</app-avatar>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="log">
							<mat-header-cell *matHeaderCellDef>
								Logs
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<button matTooltip="Log" mat-icon-button color="primary" (click)="openLogs(element)">
									<mat-icon svgIcon="log"></mat-icon>
								</button>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="createdAt">
							<mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">
								Paid on
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								{{element.createdAt| date:'medium' }}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="paidBy">
							<mat-header-cell *matHeaderCellDef>
								Paid By
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<app-avatar *ngIf="element.transactions?.[0]?.by" [avatar]="element.transactions?.[0]?.by">
								</app-avatar>
							</mat-cell>
						</ng-container>

						<ng-container matColumnDef="actions">
							<mat-header-cell *matHeaderCellDef>
								Actions
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<button matTooltip="Open In A New Tab" mat-icon-button color="primary" aria-label="link"
									(click)="openInNewTab(element);$event.stopPropagation()">
									<mat-icon>open_in_new</mat-icon>
								</button>
								<button matTooltip="Download" mat-icon-button class="mat-icon-loader-button" color="primary"
									aria-label="link" (click)="download(element);$event.stopPropagation()">
									<mat-icon>download</mat-icon>
									<app-loader [loaderId]="element.UUIDV4" [fgsSize]=40></app-loader>
								</button>
								<button matTooltip="Share Report" mat-icon-button color="primary" aria-label="link"
									(click)="openShareReport(element);$event.stopPropagation()">
									<mat-icon>send </mat-icon>
								</button>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="singleComparison">
							<mat-header-cell *matHeaderCellDef>
								Single Comparison
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<button matTooltip="Open Single Comparison" mat-icon-button
									[color]="element.selectedSingleComparisonGroupId?'primary':'accent'"
									(click)="openSingleComparison(element)">
									<mat-icon svgIcon="single_comparison" mat-list-icon></mat-icon>
								</button>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="dualComparison">
							<mat-header-cell *matHeaderCellDef>
								Dual Comparison
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<button matTooltip="Open Dual Comparison" mat-icon-button
									[color]="element.selectedDualComparisonRoleId && element.selectedDualComparisonGroupId? 'primary':'accent'"
									(click)="openDualComparison(element);$event.stopPropagation()">
									<mat-icon svgIcon="dual_comparison" mat-list-icon></mat-icon>
								</button>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="members">
							<mat-header-cell *matHeaderCellDef>
								Visibility
							</mat-header-cell>
							<mat-cell *matCellDef="let element">
								<app-members-visibility
									*ngIf="(user?.role==='ADMIN' || element.org?.membership?.role==='ADMIN' || element.transactions?.[0]?.by.id===user?.id);else noAccessTRef"
									(selectionChange)="update($event,element)" [selectedIds]="element.memberIds"
									[members]="element.members" (openedChange)="updateSelected($event,element)"></app-members-visibility>
							</mat-cell>
						</ng-container>
						<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectionChange($event,row)">
						</mat-row>
					</mat-table>
				</app-ng-scrollbar-wrapper>
			</div>
	</div>
</section>
<ng-template #noAccessTRef>
	N/A
</ng-template>
