<h1 mat-dialog-title>{{action}}</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<ng-container *ngIf="data.action==='CREATE'">
	<form [formGroup]="surveyFormGroup" (submit)="submit()">
		<mat-dialog-content>
			<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start" fxLayoutGap="72px" style="padding: 0 32px;">
				<div fxFlex="50%" fxFlexFill>
					<div fxLayout="column">
						<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
							<label fxFlex="30%" fxFlexFill for="">Assessment Name</label>
							<mat-form-field fxFlex="70%" fxFlexFill>
								<!-- <mat-label>Survey Name</mat-label> -->
								<input cdkFocusInitial formControlName="name" name="name" matInput
									placeholder="Type in Name for Assessment" #input required>
								<mat-error
									*ngIf="surveyFormGroup.get('name')?.touched && surveyFormGroup.get('name')?.errors?.required">
									Please type in Name.
								</mat-error>
							</mat-form-field>
						</div>

						<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
							<label fxFlex="30%" fxFlexFill for="">Assessment</label>
							<mat-form-field fxFlex="70%" fxFlexFill>
								<!-- <mat-label>Assessment</mat-label> -->
								<mat-select formControlName="assessment" name="assessment">
									<mat-option *ngFor="let assessment of assessments" [value]="assessment.value">
										{{assessment.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
							<label fxFlex="30%" fxFlexFill fxFlexOffset="10px" for="" style="margin-left: 20px;">Assessment
								Type</label>
							<mat-form-field fxFlex="70%" fxFlexFill>
								<mat-select formControlName="assessmentType" name="assessmentType"
									placeholder="Please Select Assessment Type">
									<mat-option *ngFor="let assessmenttype of assessments_type" [value]="assessmenttype.value">
										{{assessmenttype.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
							<div fxFlex="30%" fxFlexFill fxFlexOffset="10px" style="margin-left: 20px;"></div>
							<div fxFlex="70%" fxFlexFill *ngIf="assessmentTypeFC?.value==='ON_DEMAND'" fxLayout="row"
								fxLayoutAlign="space-between center" fxLayoutGap="16px">
								<mat-hint>
									After the Assessment is finished, reports can be bought whenever needed.
								</mat-hint>
							</div>
						</div>
						<ng-container *ngIf="assessmentTypeFC?.value==='STANDARD'">
							<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
								<label fxFlex="30%" fxFlexFill for="">No. of Reports</label>
								<mat-form-field fxFlex="70%" fxFlexFill>
									<!-- <mat-label>No. of Reports</mat-label> -->
									<input matInput formControlName="noOfReports" type="number" required [readonly]="readonlyNoOfReport"
										placeholder="Type in number of Reports" #input>
									<mat-error
										*ngIf="surveyFormGroup.get('noOfReports')?.touched && surveyFormGroup.get('noOfReports')?.errors">
										<span *ngIf="surveyFormGroup.get('noOfReports')?.errors?.required"> Please type in Number of
											Reports required.</span>
										<span *ngIf="surveyFormGroup.get('noOfReports')?.errors?.max"> Can't be more than
											allowable number ({{maxLimit}})</span>
										<span *ngIf="surveyFormGroup.get('noOfReports')?.errors?.min as min"> Can't be less than
											{{min.min}}
										</span>
									</mat-error>
									<mat-hint *ngIf="readonlyNoOfReport">
										Member can only create Assessment with 0 No. of Reports.
									</mat-hint>
								</mat-form-field>
							</div>

							<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
								<label fxFlex="30%" fxFlexFill>Report for User</label>
								<mat-form-field fxFlex="70%" fxFlexFill>
									<mat-select formControlName="onCompletion" name="onCompletion"
										placeholder="After user completes Survey">
										<mat-option value="SHOW_PREVIEW_REPORT">Show Preview</mat-option>
										<mat-option value="SHOW_FULL_REPORT">Show Full Report</mat-option>

										<!--* commented SHOW_THANK_YOU as no longer needed --->
										<!-- <mat-option value="SHOW_THANK_YOU">Show Thank you Page (No Report) </mat-option> -->
										<!--===  ===-->

									</mat-select>
									<mat-error
										*ngIf="surveyFormGroup.get('onCompletion')?.touched && surveyFormGroup.get('onCompletion')?.errors">
										<span *ngIf="surveyFormGroup.get('onCompletion')?.errors?.required"> Report for User
											is
											required</span>
									</mat-error>
								</mat-form-field>
							</div>
						</ng-container>

					</div>
				</div>
				<div fxFlex="50%" fxFlexFill>
					<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
						<label fxFlex="40%" fxFlexFill>
							Group</label>
						<div *ngIf="readonlyOrgId && org" fxFlex="60%" fxFlexFill>
							<div class="padding-small border-bottom" style="margin-bottom: 16px;">
								<app-avatar [avatar]="org">
								</app-avatar>
							</div>
						</div>
						<mat-form-field *ngIf="!readonlyOrgId" fxFlex="1 1 calc(50%-20px)" fxFlexFill>
							<mat-label>Choose a Organisation</mat-label>
							<mat-select formControlName="orgId" name="orgId" msInfiniteScroll (infiniteScroll)="onScroll()"
								[complete]="offset === (orgs$|async)?.length">
								<mat-select-trigger>
									<app-avatar *ngIf="org" size="16px" [fxInline]="true" [avatar]="org">
									</app-avatar>
								</mat-select-trigger>
								<mat-option *ngFor="let org of orgs$|async" [value]="org.id">
									<app-avatar [avatar]="org">
									</app-avatar>
								</mat-option>
								<div class="ca-list-loader">
									<app-loader [loaderId]="orgSelectListLoaderId" [bgsSize]="28" [fgsSize]="28"
										bgsPosition="center-center"></app-loader>
								</div>
							</mat-select>
							<mat-error
								*ngIf="surveyFormGroup.get('orgId')?.touched && surveyFormGroup.get('orgId')?.errors?.required">
								Please select Organisation!
							</mat-error>
						</mat-form-field>

						<!-- TODO: update to use organisation-select component as below  -->
						<!-- <app-organisation-select fxFlex="60%" fxFlexFill [showAll]="false" [global]="false"
												[forceReadOnly]="readonlyOrgId && !!org" [selectedOrgId]="surveyFormGroup.get('orgId')?.value"
												(selectedOrgChange)=" surveyFormGroup.get('orgId')?.setValue($event.id)"
												[error]="surveyFormGroup.get('orgId')?.touched && surveyFormGroup.get('orgId')?.errors?.required">
											</app-organisation-select> -->
					</div>
					<ng-container *ngIf="org && assessmentTypeFC?.value==='STANDARD'">
						<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
							<label fxFlex="1 0 40" fxFlexFill>
								Available Credits </label>
							<div fxFlex="60" fxFlexFill fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-between center">
								<mat-form-field fxFlex="1 1 45" fxFlexFill>
									<mat-label>Paid Credits</mat-label>
									<input matInput readonly [value]="org?.wallet?.balance" type="number">
								</mat-form-field>
								<mat-form-field fxFlex="1 1 45" fxFlexFill>
									<mat-label>Seed Credits</mat-label>
									<input matInput readonly [value]="org?.wallet?.seededBalance" type="number">
								</mat-form-field>
							</div>
						</div>
						<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
							<label fxFlex="40%" fxFlexFill>
								Cost per Assessment </label>
							<mat-form-field fxFlex="60%" fxFlexFill>
								<input readonly matInput [value]=costPerAssignmentAfterDiscount type="number">
								<mat-hint *ngIf="this.org.orgType" class="important-hint">
									*After {{this.org.orgType|orgTypeDiscount}} Discount for
									{{this.org.orgType|orgType}}
								</mat-hint>
							</mat-form-field>
						</div>
						<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
							<label fxFlex="40%" fxFlexFill>
								Total reports Allowable </label>
							<mat-form-field fxFlex="60%" fxFlexFill>
								<input readonly matInput value="{{(maxLimit)|number:'1.0-0'}}" type="number">
							</mat-form-field>
						</div>
					</ng-container>
				</div>
			</div>
		</mat-dialog-content>
		<mat-dialog-actions>
			<div fxFlexFill fxLayout="row" fxLayout.xs="column">
				<div fxFlex="50%" fxFlexFill>
					<div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 10px;">
						<button mat-raised-button color="primary" type="submit">Create Assessment</button>
					</div>
				</div>
				<div fxFlex="50%" fxFlexFill>
				</div>
			</div>
		</mat-dialog-actions>
	</form>


</ng-container>
<ng-container *ngIf="data.action==='INCREASE_NO_OF_REPORTS' ||data.action==='DECREASE_NO_OF_REPORTS'">
	<form [formGroup]="surveyFormGroup" (submit)="submitNoOfReportsUpdate()">
		<mat-dialog-content>
			<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
				<label fxFlex="70%" fxFlexFill>
					How many reports would you like to be
					<ng-container [ngSwitch]="data.action">
						<ng-container *ngSwitchCase="'INCREASE_NO_OF_REPORTS'">added</ng-container>
						<ng-container *ngSwitchCase="'DECREASE_NO_OF_REPORTS'">refunded</ng-container>
					</ng-container>?
				</label>
				<mat-form-field fxFlex="30%" fxFlexFill>
					<!-- <mat-label>Survey Name</mat-label> -->
					<input cdkFocusInitial formControlName="count" name="count" matInput placeholder="No of reports"
						type="number">
					<mat-error *ngIf="surveyFormGroup.get('count')?.touched && surveyFormGroup.get('count')?.errors;">
						<span *ngIf="surveyFormGroup.get('count')?.errors?.required"> Please type in Number of
							Reports.</span>
						<span *ngIf="surveyFormGroup.get('count')?.errors?.max"> Can't be more than
							allowable number ({{maxLimit}})</span>
						<span *ngIf="surveyFormGroup.get('count')?.errors?.min"> Can't be less than
							1</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div>
				<label>
					Total reports Allowable : {{maxLimit}}
				</label>

			</div>
			<button *ngIf="data.action==='INCREASE_NO_OF_REPORTS'" mat-flat-button type="button" color="primary"
				(click)="openBuyDialog()">Buy
				Credits</button>
		</mat-dialog-content>
		<mat-dialog-actions align="end">
			<button mat-raised-button type="button" mat-dialog-close>cancel</button>
			<button mat-raised-button color="primary" type="submit">Confirm</button>
		</mat-dialog-actions>
	</form>
</ng-container>
