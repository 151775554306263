import { StepAuthService } from "./step-auth.service";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FILE_SRC } from "../types/common";
@Injectable({
	providedIn: "root"
})
export class FileService {
	baseURL = environment.baseUrl;
	stepBaseURL = environment.stepBaseUrl;
	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private stepAuthService: StepAuthService
	) {}

	uploadFile(file: File, sourceType: string, src: FILE_SRC = "CA") {
		let url = src === "CA" ? this.baseURL : this.stepBaseURL;
		return new Promise<string>((resolve, reject) => {
			let formData = new FormData();
			formData.append("file", file);
			const params = new HttpParams({ fromObject: { sourceType } });
			this.http.post<any>(`${url}/file`, formData, { params }).subscribe(
				res => {
					if (res.statusCode == 200) {
						resolve(res.imageId);
					} else {
						reject("Please upload a different picture");
					}
				},
				err => {
					reject(err);
				}
			);
		});
	}

	generateFilePath(imageId: string, src: FILE_SRC) {
		switch (src) {
			case "CA":
				return this.generateFilePathCA(imageId);
			case "STEP":
				return this.generateFilePathSTEP(imageId);
		}
	}
	generateFilePathCA(imageId: string) {
		let auth;
		if (this.authService.isLoggedIn()) {
			auth = this.authService.getAuth();
		} else {
			auth = this.authService.getBasicAuth();
		}
		let authorization: any = {};
		authorization[auth.type as string] = auth.token;
		const params = new HttpParams({
			fromObject: { ...authorization, imageId }
		});
		return `${this.baseURL}/file?${params.toString()}`;
	}
	generateFilePathSTEP(imageId: string) {
		let authorization;
		if (this.stepAuthService.isLoggedIn()) {
			let auth = this.stepAuthService.getAuth();
			authorization = <string>auth.token;
		} else {
			authorization = this.stepAuthService.getBasicAuth();
		}
		const params = new HttpParams({
			fromObject: { authorization, imageId }
		});
		return `${this.stepBaseURL}/file?${params.toString()}`;
	}
}
