import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { removeUndefined } from "../utils/object";
import { queryParams } from "./../types/common";

interface PartnerSurveyQueryParams extends queryParams {
	isPublic?: boolean;
	orgId?: number;
}
@Injectable({
	providedIn: "root"
})
export class PartnerSurveyService {
	baseURL = environment.baseUrl;
	constructor(public http: HttpClient) {}
	create(payload: any) {
		return this.http.post(`${this.baseURL}/partner/survey`, payload);
	}
	getPartnerSurvey(queryParams: PartnerSurveyQueryParams): Observable<any> {
		const params = new HttpParams({
			fromObject: removeUndefined(
				{
					...queryParams,
					sortBy: queryParams.sortBy?.toUpperCase()
				} ?? {}
			)
		});
		const requestUrl = `${this.baseURL}/partner/survey`;
		return this.http.get<any>(requestUrl, { params });
	}
	getPartnerSurveyById(id: number) {
		return this.http.get<any>(`${this.baseURL}/partner/survey/${id}`);
	}
	update(id: number, payload: any) {
		return this.http.patch(`${this.baseURL}/partner/survey/${id}`, payload);
	}
}
