import { Component, Inject, Optional } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ForgotPasswordComponent } from "src/app/_shared/components/forgot-password/forgot-password.component";
import { LoginWithMobileComponent } from "./../../../_shared/components/login-with-mobile/login-with-mobile.component";
import { DeviceService } from "./../../../_shared/services/device.service";
import { StepAccountService } from "./../../../_shared/services/step-account.service";
import { StepAuthService } from "./../../../_shared/services/step-auth.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
	isVisible = false;
	userForm!: FormGroup;
	deviceInfo: any;
	timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
	returnUrl?: string;
	constructor(
		private fb: FormBuilder,
		private deviceService: DeviceService,
		private stepAuthService: StepAuthService,
		private UiLoaderService: NgxUiLoaderService,
		private stepAccountService: StepAccountService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private _snackBar: MatSnackBar,
		private _dialog: MatDialog,
		@Optional() public dialogRef: MatDialogRef<LoginComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public dialogData: { returnUrl: string }
	) {
		this.checkIfHasActiveSession();
		this.userForm = this.fb.group({
			email: [, Validators.required],
			password: [, Validators.required]
		});
		this.getDeviceInfo();
	}
	async checkIfHasActiveSession() {
		let isLoggedIn = this.stepAuthService.isLoggedIn();
		this.returnUrl ??= this.activatedRoute.snapshot.queryParams["returnUrl"];
		if (isLoggedIn) {
			this.navigateNext();
		}
	}
	async getDeviceInfo() {
		this.deviceInfo = await this.deviceService.getDeviceInfo();
	}
	get fg() {
		return this.userForm;
	}
	handleLoginResponse(res: any) {
		if (res.statusCode == 200) {
			this.UiLoaderService.stop();
			this.stepAuthService.setLoggedIn();
			this.stepAuthService.setAuth(res.authorization);
			this.stepAuthService.setUserInfo(res.user);
			this.navigateNext();
		}
	}
	navigateNext() {
		if (this.dialogRef) {
			this.dialogRef.close({ refresh: true });
			if (this.dialogData?.returnUrl) {
				this.router.navigateByUrl(this.dialogData?.returnUrl);
			}
			return;
		}
		if (this.returnUrl) {
			this.router.navigateByUrl(this.returnUrl);
		} else {
			this.router.navigate(["../group"], { relativeTo: this.activatedRoute });
		}
	}
	onSubmit() {
		this._snackBar.dismiss();
		let payload = {
			...this.userForm.value,
			timeZone: this.timeZone,
			device: this.deviceInfo
		};
		this.UiLoaderService.start();
		this.stepAccountService.login(payload).subscribe(
			this.handleLoginResponse.bind(this),
			err => {
				this.UiLoaderService.stop();
			},
			() => {
				this.UiLoaderService.stop();
			}
		);
	}
	openForgotPassword() {
		this._dialog.open(ForgotPasswordComponent, {
			minWidth: "500px",
			panelClass: "ca-dialog",
			data: {
				for: "STEP"
			}
		});
	}
	openLoginWithMobile() {
		let ref = this._dialog.open(LoginWithMobileComponent, {
			minWidth: "500px",
			panelClass: "ca-dialog",
			data: {
				payload: {
					timeZone: this.timeZone,
					device: this.deviceInfo
				}
			}
		});
		ref.afterClosed().subscribe({
			next: res => {
				if (res) {
					this.handleLoginResponse(res);
				}
			},
			error: err => {
				this.UiLoaderService.stop();
			}
		});
	}
}
