<section fxLayout="column">
	<div fxLayout="column" fxLayoutAlign="start stretch" style="margin-bottom: 16px;">
		<div fxLayout="row" fxLayoutAlign="start center">
			<mat-form-field fxFlex="0 1 250px" fxFlexFill>
				<mat-label>Filter</mat-label>
				<input matInput placeholder="Group Name or Group Ref" #input [formControl]="filterFormControl">
				<button matSuffix mat-icon-button (click)="filterFormControl.setValue('')">
					<mat-icon *ngIf="filterFormControl.value" aria-label="Clear">close</mat-icon>
					<app-loader [loaderId]="loaderId" [bgsSize]="24" bgsPosition="center-center"></app-loader>
				</button>
			</mat-form-field>
			<span class="ca-spacer"></span>
			<mat-paginator class="ca-mat-paginator" [length]="resultsLength" [pageSize]="pageSize"
				[showFirstLastButtons]="(resultsLength/pageSize)>2" [pageSizeOptions]="[10,25,50,100]" aria-label="Select page">
			</mat-paginator>
		</div>
		<mat-hint style="margin-top: -20px;">
			<small> NOTE: Groups shown are where you are
				listed as a
				Group member, Culture
				Consultant,
				Support
			</small>
		</mat-hint>
	</div>
	<div fxLayout="column" class="ca-page-content">
		<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
		<div class="mat-elevation-z2" [hidden]="isLoadingResults">
			<mat-table [dataSource]="data" matSort [matSortActive]="'name'" matSortDirection="asc" fixedLayout="true">
				<ng-container matColumnDef="groupRef">
					<mat-header-cell *matHeaderCellDef mat-sort-header="groupRef">
						Group Ref
					</mat-header-cell>
					<mat-cell *matCellDef="let element">
						{{element.groupRef}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="groupName">
					<mat-header-cell *matHeaderCellDef mat-sort-header="groupName">
						EP Group Name
					</mat-header-cell>
					<mat-cell *matCellDef="let element">
						<app-avatar [avatar]="element">
						</app-avatar>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="action">
					<mat-header-cell *matHeaderCellDef>
						Action
					</mat-header-cell>
					<mat-cell *matCellDef="let element">
						<button mat-icon-button [disabled]="!element?.groupSubscription?.orgId" color="primary"
							(click)="openGroup(element);$event.stopPropagation()">
							<mat-icon>
								edit
							</mat-icon>
						</button>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="organisation">
					<mat-header-cell *matHeaderCellDef>
						<div>
							Linked Portal Organisation
							<mat-select [formControl]="orgFilterFormControl" placeholder="Organisation">
								<mat-select-trigger>
									<div [ngSwitch]="orgFilterFormControl.value">
										<div *ngSwitchCase="'SUBSCRIBED'">
											<app-avatar *ngIf="selectedOrg" size="16px" [avatar]="selectedOrg">
											</app-avatar>
										</div>
										<div *ngSwitchCase="'ALL'">
											All
										</div>
										<div *ngSwitchCase="'NOT-SUBSCRIBED'">
											N/A
										</div>
									</div>
								</mat-select-trigger>
								<mat-option value="ALL">All</mat-option>
								<mat-option value="NOT-SUBSCRIBED">N/A</mat-option>
								<mat-option value="SUBSCRIBED">
									<app-avatar *ngIf="selectedOrg" [avatar]="selectedOrg" size="24px">
									</app-avatar>
								</mat-option>
							</mat-select>
						</div>
					</mat-header-cell>
					<mat-cell *matCellDef="let element">
						<app-avatar *ngIf="element?.groupSubscription?.orgId && selectedOrg;else noOrgRef" [avatar]="selectedOrg">
						</app-avatar>
						<ng-template #noOrgRef>
							N/A
						</ng-template>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="noOfUsers">
					<mat-header-cell *matHeaderCellDef>
						No of Users (In Dependents)
					</mat-header-cell>
					<mat-cell *matCellDef="let element">
						{{element.forestCount}}
						<ng-container *ngIf="element.hasDependent">
							({{element.dependentForestCount}})
						</ng-container>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
				<mat-row matRipple *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></mat-row>
			</mat-table>
		</div>
	</div>
	<div fxLayout="row" fxLayoutAlign="center center" fxFlex="shrink" *ngIf="!isLoadingResults  && data.length===0">
		<div *ngIf="data?.length===0 && (filterFormControl?.value===null || filterFormControl?.value?.length===0)">
			All Your Groups will appear here
		</div>
		<div *ngIf="data?.length===0 && filterFormControl?.value?.length>0">
			There are no results available for this keyword &nbsp; <b>"{{ filterFormControl.value}}" </b>.
			Please
			refine
			your search with some other words.
		</div>
	</div>
</section>
