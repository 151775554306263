<h1 mat-dialog-title>
	<ng-container *ngIf="!data?.org?.id">Create </ng-container>
	<ng-container *ngIf="data?.org?.seedingOrgId"> Client </ng-container>
	Organisation
	<ng-container *ngIf="data?.org?.id"> -
		<app-avatar [avatar]="data?.org" [fxInline]="true"> </app-avatar>
	</ng-container>
	<ng-container *ngIf="data?.seedingOrg?.id"> -
		<app-avatar [avatar]="data.seedingOrg" [fxInline]="true"> </app-avatar>
	</ng-container>

</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<form *ngIf="orgFormGroup" [formGroup]="orgFormGroup" (submit)="submit()">
	<mat-dialog-content>
		<div fxLayout="row" fxLayoutAlign="center center" class="ca-profile-pic-container">
			<div class="ca-profile-pic-view">
				<span class="ca-profile-pic" fxLayout="row" fxLayoutAlign="center center">
					<ng-container *ngIf="groupProfileImage?.src; else existingGroupImage">
						<img width="100%" [src]="groupProfileImage?.src" alt="" srcset="">
					</ng-container>
					<ng-template #existingGroupImage>
						<ng-container *ngIf="fg?.get('imageKey')?.value as imageKey; else groupPlaceHolderImage">
							<img width="100%" [src]="imageKey|filepath" appImgFallback="GROUP_PROFILE">
						</ng-container>
					</ng-template>
					<ng-template #groupPlaceHolderImage>
						<img width="100%" src="/assets/images/group-logo-placeholder.png" alt="" srcset="">
					</ng-template>
					<input #file id="groupPic" name="imageKey" type="file" (change)="onGroupProfileUpload($event)">
				</span>
				<button class="ca-edit" (click)="file.click()" type="button" mat-icon-button>
					<mat-icon>edit</mat-icon>
				</button>
				<button class="ca-remove" *ngIf="groupProfileImage?.src||fg?.get('imageKey')?.value" (click)="removePic()"
					type="button" mat-icon-button>
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</div>
		<div>
			<mat-form-field fxFlexFill>
				<mat-label>Name</mat-label>
				<input matInput placeholder="Type in Organisation Name" name="name" formControlName="name">
				<mat-error *ngIf="fg?.get('name')?.touched && fg?.get('name')?.errors?.required">
					Please type in Name.
				</mat-error>
			</mat-form-field>
		</div>
		<br>
		<div style="margin-bottom: 16px;">
			Secondary Logo <br>
			<mat-hint>To be used in Report Cover page and Assessment</mat-hint>
		</div>
		<div class="ca-image-uploader" style="margin-bottom: 16px;">
			<ng-container *ngIf="orgLogoImage?.src; else existingOrgLogoImage">
				<img width="100%" [src]="orgLogoImage?.src" alt="asf" srcset="">
			</ng-container>
			<ng-template #existingOrgLogoImage>
				<ng-container *ngIf="fg?.get('orgLogoImageKey')?.value as orgLogoImageKey; else groupPlaceHolderLogoImage">
					<img width="100%" [src]="orgLogoImageKey|filepath" alt="existingGroupImage"
						appImgFallback="GROUP_SECONDARY_PROFILE">
				</ng-container>
			</ng-template>
			<ng-template #groupPlaceHolderLogoImage>
				<img width="100%" src="/assets/images/group-logo-placeholder-2.png" alt="asdf" srcset="">
			</ng-template>
			<input #file id="orgLogo" name="orgLogoImageKey" type="file" (change)="onOrgLogoImageUpload($event)">
			<button class="ca-remove" *ngIf="orgLogoImage?.src||fg?.get('orgLogoImageKey')?.value"
				(click)="removeOrgLogoImage()" type="button" mat-icon-button>
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div *ngIf="!data?.org?.id" fxFlexFill fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
			<app-emails class="full-width" fcName="membersToBeInvited" [fg]="orgFormGroup" label="Invite People"
				placeholder="Type in Email address">
			</app-emails>
			<mat-form-field fxFlex="0 0 200px">
				<mat-label>Role</mat-label>
				<mat-select formControlName="role" name="role">
					<mat-option value="ADMIN"> Admin </mat-option>
					<mat-option value="MEMBER"> Member</mat-option>
				</mat-select>
				<mat-error *ngIf="fg?.get('role')?.touched && fg?.get('role')?.errors?.required">
					Please select Role.
				</mat-error>
			</mat-form-field>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button type="button" mat-button (click)="cancel()">Cancel</button>
		<span class="ca-spacer"></span>
		<button type="submit" mat-flat-button color="primary" *ngIf="data?.org?.id"
			[disabled]="orgFormGroup.invalid">Save</button>
		<button type="submit" mat-flat-button color="primary" *ngIf="!data?.org?.id">Create</button>
	</mat-dialog-actions>
</form>
