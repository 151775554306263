import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LocationService } from "../../services/location.service";
import { ICountry } from "../../types/common";
@Component({
	selector: "app-country",
	templateUrl: "./country.component.html",
	styleUrls: ["./country.component.scss"]
})
export class CountryComponent implements OnInit, OnDestroy, OnChanges {
	@Input() fg!: FormGroup;
	@Input() fcName: string = "countryCode";
	/** control for the MatSelect filter keyword */
	public countryFilterCtrl: FormControl = new FormControl();

	/** list of countrys filtered by search keyword */
	public filteredCountries: ReplaySubject<ICountry[]> = new ReplaySubject<
		ICountry[]
	>(1);

	/** Subject that emits when the component has been destroyed. */
	protected _onDestroy = new Subject<void>();

	constructor(public LocationService: LocationService) {}

	ngOnInit() {
		// listen for search field value changes
		this.countryFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterCountries();
			});
		this.getCountries();
	}

	ngOnChanges(): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		if (this.fg.get(this.fcName)) {
			this.getIPLocation();
		}
	}
	getIPLocation() {
		this.LocationService.getIPInfo().then((res: any) => {
			const countryCode = res.country;
			if (countryCode) {
				this.fg.get(this.fcName)?.setValue(countryCode);
			}
		});
	}
	getCountries(searchText: string = "") {
		searchText = searchText.toLowerCase();
		this.LocationService.countries
			.pipe(takeUntil(this._onDestroy))
			.subscribe(res => {
				this.filteredCountries.next(
					res.filter((country: ICountry) =>
						country.name.toLowerCase().includes(searchText)
					)
				);
			});
	}
	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	protected filterCountries() {
		// get the search keyword
		let search = this.countryFilterCtrl.value;
		if (!search) {
			this.getCountries();
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the countrys
		this.getCountries(search);
	}
}
