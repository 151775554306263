import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { Component, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { StripePaymentElementComponent, StripeService } from "ngx-stripe";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { PaymentService } from "../../services/payment.service";
import { AuthService } from "../../services/auth.service";
import { AmplitudeService } from "../../services/amplitude.service";

@Component({
	selector: "app-purchase-charametrics-report",
	templateUrl: "./purchase-charametrics-report.component.html",
	styleUrls: ["./purchase-charametrics-report.component.scss"]
})
export class PurchaseCharametricsReportComponent {
	subscriptions = new Subscription();
	@ViewChild("stepper") private stepper!: MatStepper;
	@ViewChild(StripePaymentElementComponent)
	paymentElement!: StripePaymentElementComponent;
	status: "PAYMENT" | "SUCCESS" | "FAILED" = "PAYMENT";
	error!: any;
	piId?: string;
	breakdowns?: {
		totalAmount?: number;
		amount?: number;
		GST?: number;
	};
	elementsOptions: StripeElementsOptions = {
		locale: "en",
		loader: "always",
		// fonts: [{cssSrc:  window.location.origin+"/assets/fonts/century-gothic/font.scss"}],
		appearance: {
			variables: {
				fontFamily: "Century Gothic",
				fontSmooth: "never"
			},
			labels: "floating"
		}
	};
	detailsFormGroup!: FormGroup;
	result!: any;
	countryCode?: string;
	readonlyOrgId = false;
	constructor(
		private stripeService: StripeService,
		private paymentService: PaymentService,
		private _snackBar: MatSnackBar,
		public dialogRef: MatDialogRef<PurchaseCharametricsReportComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private UiLoaderService: NgxUiLoaderService,
		private fb: FormBuilder,
		private authService: AuthService,
		private amplitudeService: AmplitudeService
	) {
		this.detailsFormGroup = this.fb.group({
			reportId: [, Validators.required],
			countryCode: [null, Validators.required],
			product: ["CHARAMETRICS_REPORT", Validators.required],
			email: [, [Validators.required, Validators.email]]
		});
		this.setPresets();
	}
	setPresets({ countryCode }: { countryCode?: string } = {}) {
		this.detailsFormGroup.patchValue({
			countryCode,
			reportId: this.data.report.id,
			email: this.data.report.email
		});
	}

	reset() {
		this.detailsFormGroup.reset();
		this.setPresets();
	}
	createPaymentIntent() {
		this.detailsFormGroup.markAllAsTouched();
		if (this.detailsFormGroup.valid) {
			this.UiLoaderService.start();
			this.paymentService
				.createReportPaymentIntent(this.detailsFormGroup.value)
				.subscribe((pi: any) => {
					this.elementsOptions.clientSecret = pi.client_secret as string;
					this.breakdowns = pi;
					this.stepper.next();
					this.UiLoaderService.stop();
				});
		}
	}
	pay() {
		this.UiLoaderService.start();
		this.stripeService
			.confirmPayment({
				elements: this.paymentElement.elements,
				confirmParams: {
					payment_method_data: {
						billing_details: {
							name: this.data.report.name,
							email: this.detailsFormGroup.value.email
						}
					}
				},
				redirect: "if_required"
			})
			.subscribe(result => {
				this.result = result;
				if (!result.error) {
					if (result?.paymentIntent?.status === "succeeded") {
						this._snackBar.open("Payment Success");
						this.status = "SUCCESS";
						this.piId = result.paymentIntent.id;
						let event = "";
						switch (this.detailsFormGroup.value.product) {
							case "CHARAMETRICS_REPORT":
								event = "Purchases Full Report";
								break;
							case "CHARAMETRICS_REPORT_WITH_UNPACK":
								event = "Purchases Full Report + Unpack";
								break;
						}
						this.amplitudeService.track(event, {
							userId: this.authService.user.id,
							email: this.detailsFormGroup.value.email,
							reportId: this.data.report.id
						});
					}
				} else {
					this.error = result.error;
					this.status = "FAILED";
				}
				this.UiLoaderService.stop();
			});
	}
	close() {
		this.dialogRef.close({ refresh: true, piId: this.piId || "test" });
	}
}
