import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatChipInputEvent, MatChipList } from "@angular/material/chips";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { uniq } from "lodash";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { GroupReportService } from "src/app/_shared/services/group-report.service";
import { LoggerService } from "src/app/_shared/services/logger.service";
import { ReportService } from "src/app/_shared/services/report.service";
import { validateChips } from "../../form/validator/validate-chips";

@Component({
	selector: "app-share-report",
	templateUrl: "./share-report.component.html",
	styleUrls: ["./share-report.component.scss"]
})
export class ShareReportComponent implements OnInit {
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	shareFG?: FormGroup;
	@ViewChild("chipList", { static: false }) chipList!: MatChipList;
	get emailIds() {
		return this.shareFG?.get("emailIds");
	}
	constructor(
		public dialogRef: MatDialogRef<ShareReportComponent>,
		public reportService: ReportService,
		public groupReportService: GroupReportService,
		private UiLoaderService: NgxUiLoaderService,
		public Logger: LoggerService,
		private _snackBar: MatSnackBar,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.shareFG = this.fb.group({
			emailIds: [[], [validateChips, Validators.required]]
		});
	}
	ngOnInit(): void {
		this.prefill();
	}
	prefill() {
		if (this.data?.report?.email) {
			this.addEmail(this.data.report.email);
		}
	}
	handleChipInput(event: MatChipInputEvent): void {
		const email = (event.value || "").trim();
		if (email) {
			this.addEmail(email);
		}
		event.chipInput!.clear();
	}
	addEmail(email: string) {
		this.emailIds?.value.push(email);
		this.emailIds?.updateValueAndValidity();
	}

	removeEmail(index: number): void {
		this.emailIds?.value.splice(index, 1);
		this.emailIds?.updateValueAndValidity();
	}
	reset() {
		this.chipList.errorState = false;
		this.emailIds?.setValue([]);
		this.prefill();
		this.emailIds?.markAsUntouched();
	}
	send() {
		if (this.shareFG?.valid) {
			let payload = {
				emailIds: uniq(this.shareFG?.value.emailIds),
				...this.data
			};
			this.UiLoaderService.start();
			let subscription;
			if (payload.report) {
				subscription = this.reportService.shareReport(payload);
			} else {
				subscription = this.groupReportService.shareReport(payload);
			}
			subscription.subscribe((res: any) => {
				if (res.statusCode === 200) {
					this.UiLoaderService.stop();
					this.dialogRef.close();
					this._snackBar.open(
						"Email will be sent within a few minutes.",
						undefined,
						{
							duration: 1000 * 8
						}
					);
				}
			});
		}
	}
}
