<section class="ca-report-cover">
	<div class="ca-report-qr-code-container" *ngIf="showReportQRCode">
		<app-qr-code [size]="128" [url]="reportURL" [showInfoText]="true" text="Continue with Character 360">
		</app-qr-code>
	</div>
	<div class="ca-survey-qr-code-container" *ngIf="showOrgSurveyQRCode">
		<app-qr-code [size]="128" [url]="surveyURL" [text]="surveyInfoText">
		</app-qr-code>
	</div>
	<div class="ca-cover-content">
		<div class="ca-content-logo">
			<img height="100%" width="100%" src="/assets/logo.png">
		</div>
		<div class="ca-content-header">
			CHARAMETRICS REPORT
		</div>
		<div class="ca-report-desc">
			<ng-container [ngSwitch]="report.type">
				<ng-container *ngSwitchCase="'INDIVIDUAL'">
					<div class="ca-report-type">
						Individual Report
					</div>
					<div class="ca-hr"></div>
					<div class="ca-report-name">
						{{report.name}}
					</div>
					<div class="ca-report-org">
						{{report.organisation}}
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="'GROUP'">
					<div class="ca-report-type">
						Group Report
					</div>
					<div class="ca-hr"></div>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="ca-report-info-container" *ngIf="report.type==='GROUP'" [ngSwitch]="report.type">
		<div #name class="ca-report-name" [style.font-size.pt]="fontSize(name)">
			{{report.reportNames.join(", ")}}
		</div>
	</div>

	<div class="ca-cover-partner-container">
		<app-org-branding [org]="org">
		</app-org-branding>
	</div>
</section>
