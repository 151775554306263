<mat-form-field fxFlex="0 0 100%" fxFlexFill>
	<!-- [placeholder]="'Aspiration ' + (order+1) " -->
	<input matInput placeholder="Aspiration" [matAutocomplete]="auto" [formControl]="name">
	<button *ngIf="aspirationCtrl.value" matSuffix color="accent" mat-icon-button aria-label="Clear"
		(click)="clearSelection()">
		<mat-icon>close</mat-icon>
	</button>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)">
		<mat-option *ngFor="let aspiration of filteredAspirations" [value]="aspiration.name" [id]="aspiration.value">
			<span>{{aspiration.name}}</span>
		</mat-option>
		<ng-container *ngIf="filteredAspirations?.length===0">
			<mat-option disabled>
				<span> <small> <i> No Matching found for <b>{{name.value}}</b> </i></small></span>
			</mat-option>
		</ng-container>


	</mat-autocomplete>
	<mat-error *ngIf="name.hasError">
		<span *ngIf="name.errors?.required">
			Please type in Aspiration.
		</span>
		<span *ngIf="name.errors?.incorrect">
			Please select one from the list
		</span>
		<span *ngIf="name.errors?.unique">
			Selected multiple times
		</span>
	</mat-error>
</mat-form-field>