<h1 mat-dialog-title>
	Change Password for {{authService.getPlatformTitle()}}
</h1>
<button *ngIf="!dialogData.force" mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<form fxLayout="column" [formGroup]="userForm" (ngSubmit)="onSubmit()">
	<mat-dialog-content>
		<div fxLayout="column" fxLayoutGap="10px">
			<div class="step-form-group">
				<input type="password" formControlName="currentPassword" class="form-control" [ngClass]="{ 'is-invalid':(fg?.get('currentPassword')?.touched || fg?.get('currentPassword')?.dirty)
                    && fg?.get('currentPassword')?.errors }" [placeholder]="currentPasswordLabel" />
				<div *ngIf="(fg?.get('currentPassword')?.touched || fg?.get('currentPassword')?.dirty)
                && fg?.get('currentPassword')?.errors ;let errors" class="invalid-feedback">
					<ng-container *ngIf="errors?.required">
						<mat-icon>error</mat-icon> Please type in {{currentPasswordLabel}}.
					</ng-container>
				</div>
			</div>
			<div class="step-form-group">
				<input [type]="isVisible?'text':'password'" formControlName="newPassword" class="form-control" [ngClass]="{ 'is-invalid':(fg?.get('newPassword')?.touched || fg?.get('newPassword')?.dirty)
                    && fg?.get('newPassword')?.errors }" placeholder="New Password" />
				<mat-icon class="password-visibility" (click)="isVisible=!isVisible">
					{{isVisible? "visibility_off":"visibility"}}
				</mat-icon>
				<div *ngIf="(fg?.get('newPassword')?.touched || fg?.get('newPassword')?.dirty)
                && fg?.get('newPassword')?.errors ;let errors" class="invalid-feedback">
					<mat-icon>error</mat-icon>
					<ng-container *ngIf="errors.required">
						Please type in New Password.
					</ng-container>
					<ng-container *ngIf="errors.minlength">
						Password Should be atleast {{errors.minlength.requiredLength }} characters
					</ng-container>
					<ng-container *ngIf="errors?.passwordMatch">
						New Password should not be same as {{currentPasswordLabel}}.
					</ng-container>
					<ng-container *ngIf="errors.requiresDigit
                    ||  errors.requiresUppercase
                    ||  errors.requiresLowercase
                    ||  errors.requiresSpecialChars">
						Please Enter
						<ng-container *ngIf="errors.requiresDigit">
							atleast 1 number
						</ng-container>
						<ng-container *ngIf="errors.requiresUppercase">
							atleast 1 Uppercase
						</ng-container>
						<ng-container *ngIf="errors.requiresLowercase">
							atleast 1 Lowercase
						</ng-container>
						<ng-container *ngIf="errors.requiresSpecialChars">
							atleast 1 from @$+#.!%*?&!^;,}{{'{'}}_/-][`~/)(=
						</ng-container>.
					</ng-container>
				</div>
				<div class="step-hint">
					Passwords must have a minimum of 8 characters with 1 uppercase letter, 1
					number, and 1 special
					character. Please only use the following special characters
					@$+#.!%*?&!^;,}{{'{'}}_/-][`~/)(=
				</div>
			</div>
			<div class="step-form-group">
				<input [type]="isVisible?'text':'password'" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid':(fg?.get('confirmPassword')?.touched || fg?.get('confirmPassword')?.dirty)
                    && fg?.get('confirmPassword')?.errors }" placeholder="Confirm Password" />
				<mat-icon class="password-visibility" (click)="isVisible=!isVisible">
					{{isVisible? "visibility_off":"visibility"}}
				</mat-icon>
				<div *ngIf="(fg?.get('confirmPassword')?.touched || fg?.get('confirmPassword')?.dirty)
                && fg?.get('confirmPassword')?.errors  ;let errors" class="invalid-feedback">
					<mat-icon>error</mat-icon>
					<ng-container *ngIf="fg?.get('confirmPassword')?.errors?.required">
						Please type in Confirm Password.
					</ng-container>
					<ng-container *ngIf="fg?.get('confirmPassword')?.errors?.passwordMismatch">
						New Password and Confirm password should match.
					</ng-container>
				</div>
			</div>
		</div>


	</mat-dialog-content>
	<mat-dialog-actions>
		<span class="ca-spacer"></span>
		<button *ngIf="!dialogData.force" type="button" mat-button [mat-dialog-close]>Cancel</button>
		<button [disabled]="userForm.invalid" mat-raised-button color="primary">
			<span style="font-size: 16px;font-weight: bold;">Submit</span>
		</button>
	</mat-dialog-actions>
</form>