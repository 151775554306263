import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { validateChips } from "../../form/validator/validate-chips";
import { StepGroupHierarchyComponent } from "../step-group-hierarchy/step-group-hierarchy.component";
import { StepGroupService } from "../../services/step-group.service";

@Component({
	selector: "app-step-subscription",
	templateUrl: "./step-subscription.component.html",
	styleUrls: ["./step-subscription.component.scss"]
})
export class StepSubscriptionComponent implements OnInit {
	fg!: FormGroup;
	constructor(
		public dialogRef: MatDialogRef<StepGroupHierarchyComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private fb: FormBuilder,
		private stepGroupService: StepGroupService
	) {
		this.fg = this.fb.group({
			emails: [[], [validateChips, Validators.required]]
		});
	}
	ngOnInit(): void {
		if (this.dialogData?.subscription?.emails?.length) {
			this.fg?.patchValue({
				emails: [...this.dialogData.subscription.emails]
			});
		}
	}
	update() {
		this.fg.markAllAsTouched();
		if (this.fg.valid) {
			this.stepGroupService
				.updateSubscriptionInvoiceEmails(
					this.dialogData.subscription.id,
					this.fg.value
				)
				.subscribe(res => {
					this.dialogRef.close({ refresh: true });
				});
		}
	}
}
