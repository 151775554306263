import { Component, Inject, OnDestroy } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { phoneNumberValidator } from "../../form/validator/phone-number";
import { LocationService } from "../../services/location.service";
import { StepAccountService } from "./../../services/step-account.service";
import { countDown } from "../../utils/number";

@Component({
	selector: "app-login-with-mobile",
	templateUrl: "./login-with-mobile.component.html",
	styleUrls: ["./login-with-mobile.component.scss"]
})
export class LoginWithMobileComponent implements OnDestroy {
	status: "SEND_OTP" | "VERIFY_OTP" = "SEND_OTP";
	sendOTPFG!: FormGroup;
	verfiyOTPFG!: FormGroup;
	secondsRemaining$?: Observable<number>;
	submitted = false;
	returnUrl: string = "/home/step/group";
	dialCode?: string;
	countries?: any;
	message?: string;
	contactNo = new FormControl(null, [
		Validators.required,
		phoneNumberValidator()
	]);
	/** Subject that emits when the component has been destroyed. */
	protected _onDestroy = new Subject<void>();
	constructor(
		public dialogRef: MatDialogRef<LoginWithMobileComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private stepAccountService: StepAccountService,
		private fb: FormBuilder,
		private UiLoaderService: NgxUiLoaderService,
		private locationService: LocationService
	) {
		this.sendOTPFG = this.fb.group({
			countryCode: [null, Validators.required],
			phoneNumber: [null, Validators.required]
		});
		this.verfiyOTPFG = this.fb.group({
			code: [null, Validators.required]
		});
		this.getCountries();
		this.sendOTPFG.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(value => {
				let country = this.countries.find(
					(country: any) => country.code == value.countryCode
				);
				this.dialCode = country.dial_code;
				this.contactNo.setValue(country.dial_code + <string>value.phoneNumber);
				this.contactNo.markAsTouched();
			});
	}
	getCountries() {
		this.locationService.countries.pipe(takeUntil(this._onDestroy)).subscribe({
			next: res => {
				this.countries = res;
			}
		});
	}
	sendOTP(isResendReq = false) {
		if (this.sendOTPFG.valid && this.contactNo.valid) {
			this.UiLoaderService.start();
			let payoad = {
				contactNo: this.contactNo.value,
				...this.dialogData.payload,
				isResendReq
			};
			this.stepAccountService.loginWithMobileSendOTP(payoad).subscribe(
				(res: any) => {
					this.UiLoaderService.stop();
					this.status = "VERIFY_OTP";
					this.secondsRemaining$ = countDown(20);
				},
				error => {
					this.UiLoaderService.stop();
				}
			);
		}
	}

	verifyOTP() {
		if (this.verfiyOTPFG.valid) {
			this.UiLoaderService.start();
			let payoad = {
				contactNo: this.contactNo.value,
				...this.verfiyOTPFG.value,
				...this.dialogData.payload
			};
			this.stepAccountService.loginWithMobileVerifyOTP(payoad).subscribe(
				(res: any) => {
					this.UiLoaderService.stop();
					this.dialogRef.close(res);
				},
				error => {
					this.UiLoaderService.stop();
				}
			);
		}
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}
}
