import { EChartsOption } from "echarts";
import { textStyle, backgroundColor } from "../../utils/chart-options";
export const RICH_STYLE = {
	name: {
		fontSize: 18,
		verticalAlign: "center",
		align: "left",
		padding: [0, 10, 15, 10]
	},
	id: {
		width: 1,
		lineHeight: 0,
		fontSize: 12,
		fontStyle: "italic",
		padding: [0, 0, 35, 20],
		align: "left",
		verticalAlign: "center",
		color: "grey"
	},
	empty: {
		width: 80,
		lineHeight: 0,
		height: 0,
		align: "left",
		color: "transparent"
	}
};
export const LABEL_STYLE = {
	color: "#000",
	position: "insideTop",
	verticalAlign: "middle",
	align: "center",
	formatter: (v: any) => {
		const nodeStyles = [
			`{${v.data.id}|_}`,
			`{name|${v.name}}\n`,
			`{empty|_}`,
			`{id|${v.data.groupRef}}`
		].join("");
		return nodeStyles;
	},
	backgroundColor: "#fff",
	borderColor: "#333",
	borderWidth: 1,
	padding: 8,
	borderRadius: 13,
	// shadowColor: "rgba(0, 0, 0, 0.5)",
	// shadowBlur: 10,
	overflow: "none",
	rich: RICH_STYLE
};
let options = {
	type: "tree",
	orient: "vertical",
	layout: "orthogonal",
	top: "15%",
	left: "5%",
	bottom: "15%",
	right: "5%",
	symbolSize: 0,
	roam: true,
	expandAndCollapse: false,
	edgeForkPosition: 0.5,
	edgeShape: "polyline",
	animationDurationUpdate: 750,
	animationDuration: 750
	// emphasis: {
	// 	focus: "relative",
	// 	label: {
	// 		overflow: "none",
	// 		padding: 12
	// 	},
	// 	lineStyle: {
	// 		shadowColor: "rgba(0, 0, 0, 0.5)"
	// 	}
	// }
};

export const CHART_DEFAULTS: EChartsOption | any = {
	textStyle: textStyle,
	series: [
		{
			...options,
			label: LABEL_STYLE,
			leaves: {
				label: LABEL_STYLE
			}
		}
	]
};

export const OVERLAY_RICH_STYLE = {
	empty: {
		fontSize: 18,
		align: "center",
		color: "transparent"
	},
	noOfUniqueUsers: {
		align: "center",
		fontSize: 20,
		// backgroundColor: "green",
		padding: [0, 150, 0, 0]
	},
	noOfUniqueUsersLegend: {
		color: "gray",
		align: "center",
		fontSize: 15,
		padding: [5, 150, 0, 0]
		// backgroundColor: "green"
	},

	total: {
		align: "right",
		fontSize: 28,
		width: 500,
		// backgroundColor: "yellow",
		padding: [30, 70, 0, 0]
	},
	totalLegend: {
		color: "gray",
		align: "right",
		width: 500,
		fontSize: 15,
		padding: [5, 0, 0, 0]
	},

	noOfUsers: {
		align: "center",
		fontSize: 20,
		width: 150,
		// backgroundColor: "cyan",
		padding: [30, 0, 0, 0]
	},
	noOfUsersLegend: {
		color: "gray",
		width: 150,
		padding: [5, 0, 0, 0],
		align: "center",
		// backgroundColor: "cyan",
		fontSize: 15
	},

	noOfUniqueUsersInDependants: {
		align: "center",
		fontSize: 20,
		width: 150,
		// backgroundColor: "green",
		padding: [30, 0, 0, 0]
	},

	noOfUniqueUsersInDependantsLegend: {
		color: "gray",
		width: 150,
		padding: [5, 0, 0, 0],
		align: "center",
		// backgroundColor: "green",
		fontSize: 15
	}
};
export const OVERLAY_LABEL_STYLE = {
	color: "#000",
	position: "inside",
	verticalAlign: "middle",
	align: "center",
	formatter: (v: any) => {
		const nodeStyles = [];
		if (!v.data.isRoot || (v.data.isRoot && v.data.children.length)) {
			nodeStyles.push(`{noOfUniqueUsers|[${v.data.noOfUniqueUsers || 0}]}`);
		}
		if (v.data.isRoot) {
			if (v.data.children.length) {
				nodeStyles.push("\n{noOfUniqueUsersLegend|Unique Members In Group}");
				nodeStyles.push(`\n{empty|${v.name}}`);
			} else {
				nodeStyles.push("\n");
				nodeStyles.push("\n");
			}
			nodeStyles.push(`{total|${v.data.totalNoOfUsers || 0}}`);
			nodeStyles.push(`\n{empty|${v.name}}`);
			nodeStyles.push("{totalLegend|Unique Members In Total}");
		} else {
			nodeStyles.push("\n");
			nodeStyles.push("\n");
			nodeStyles.push("\n");
			nodeStyles.push("\n");
			nodeStyles.push("\n");
		}
		nodeStyles.push(`\n{noOfUsers|${v.data.forestCount || 0}}`);
		nodeStyles.push(
			`{noOfUniqueUsersInDependants|${
				v.data.children.length
					? `(${v.data.noOfUniqueUsersInDependants || 0})`
					: ""
			}}`
		);
		if (v.data.isRoot) {
			nodeStyles.push("\n{noOfUsersLegend|Members In Group}");
			nodeStyles.push(
				`{noOfUniqueUsersInDependantsLegend|${
					v.data.children.length ? "Unique Members" : ""
				}}`
			);
			nodeStyles.push("\n{noOfUsersLegend|}");
			nodeStyles.push(
				`{noOfUniqueUsersInDependantsLegend|${
					v.data.children.length ? "In Dependents" : ""
				}}`
			);
		}
		return nodeStyles.join("");
	},
	backgroundColor: "#fff",
	borderColor: "#333",
	borderWidth: 0,
	fontSize: 20,
	padding: 0,
	height: 180,
	rich: OVERLAY_RICH_STYLE
};

export const OVERLAY_CHART_DEFAULTS: EChartsOption | any = {
	textStyle: textStyle,
	series: [
		{
			...options,
			label: OVERLAY_LABEL_STYLE,
			lineStyle: {
				width: 0
			},
			leaves: {
				label: OVERLAY_LABEL_STYLE
			}
		}
	]
};
