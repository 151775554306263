import { Component } from "@angular/core";

@Component({
	selector: "app-how-to-read-this-report",
	templateUrl: "./how-to-read-this-report.component.html",
	styleUrls: ["./how-to-read-this-report.component.scss"]
})
export class HowToReadThisReportComponent {
	constructor() {}
}
