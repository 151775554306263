<div mat-dialog-title>
	{{dialogData?.report?.name}} - Unpack Sessions
</div>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<div fxLayout="column" class="ca-page-content">
		<div fxLayout="row" fxLayoutAlign="center center" fxFlex="shrink" *ngIf="!isLoadingResults  && data.length===0">
			<div *ngIf="data.length===0">
				Unpack Session Logs will appear here
			</div>
		</div>
		<mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>
		<div class="ca-mat-table-container" [hidden]="isLoadingResults || data.length===0">
				<mat-table class="mat-elevation-z8"  [dataSource]="data" matSort [matSortActive]="'createdAt'" matSortDirection="desc" fixedLayout="true">
					<ng-container matColumnDef="index">
						<mat-header-cell *matHeaderCellDef>
							No.
						</mat-header-cell>
						<mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="allocatedTo">
						<mat-header-cell *matHeaderCellDef>
							Allocated To
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-avatar [avatar]="element.allocatedTo">
							</app-avatar>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="allocatedBy">
						<mat-header-cell *matHeaderCellDef>
							Allocated By
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<app-avatar [avatar]="element.allocatedBy">
							</app-avatar>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="createdAt">
						<mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">
							Allocated on
						</mat-header-cell>
						<mat-cell *matCellDef="let element">
							{{element.createdAt| date:'medium' }}
						</mat-cell>
					</ng-container>

					<mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;">
					</mat-row>
				</mat-table>
		</div>
	</div>
</mat-dialog-content>
