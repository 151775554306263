import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { COST_PER_ASSESSMENT } from "../../const/organisation";
import { AutoUnsubscribeOnDestroy } from "../../decorators/autoUnsubscribeOnDestroy";
import { getOrgTypeDiscount } from "../../pipes/org-type-discount.pipe";
import { PARTNER_SURVEY_ACTION, PARTNER_SURVEY_TYPE } from "../../types/common";
import { ORG } from "../../types/organisation";
import { isNotNullAndUndefined } from "../../utils/object";
import { BuyComponent } from "../buy/buy.component";

import { random } from "lodash";
import { scan } from "rxjs/operators";
import { OrganisationService } from "./../../services/organisation.service";
import { PartnerSurveyService } from "./../../services/partner-survey.service";

@Component({
	selector: "app-partner-survey",
	templateUrl: "./partner-survey.component.html",
	styleUrls: ["./partner-survey.component.scss"]
})
@AutoUnsubscribeOnDestroy()
export class PartnerSurveyComponent implements OnInit {
	assessments = [{ name: "Charametrics", value: "charametrics" }];
	assessments_type: { name: string; value: PARTNER_SURVEY_TYPE }[] = [
		{ name: "Prepaid", value: "STANDARD" },
		{ name: "On Demand", value: "ON_DEMAND" }
	];
	surveyFormGroup!: FormGroup;
	subscriptions = new Subscription();
	readonlyOrgId = false;
	globalSelectedOrg?: ORG | null;
	orgSelectListLoaderId = "orgSelectListLoaderId-" + random(1, 10000);

	orgs = new BehaviorSubject<Array<ORG>>([]);
	orgs$: Observable<Array<ORG>>;
	currentLength = 0;
	limit = 20;
	offset = 0;
	total = Infinity;
	readonlyNoOfReport = false;
	get action() {
		switch (this.data.action) {
			case "CREATE":
				return "Create Assessment";
			case "DECREASE_NO_OF_REPORTS":
				return "Decrease No of Reports";
			case "INCREASE_NO_OF_REPORTS":
				return "Increase No of Reports";
		}
	}
	get costPerAssignmentAfterDiscount() {
		if (!this.org) return COST_PER_ASSESSMENT;
		return Math.floor(
			COST_PER_ASSESSMENT - COST_PER_ASSESSMENT * this.discountRate
		);
	}
	get discountRate() {
		return getOrgTypeDiscount(this.org.orgType);
	}
	get assessmentTypeFC() {
		return this.surveyFormGroup.get("assessmentType");
	}
	org!: ORG;
	getOrg() {
		let orgId = this.surveyFormGroup.get("orgId")?.value;
		if (orgId) {
			this.organisationService.getOrg(orgId).subscribe(res => {
				this.org = res.org;
				if (this.org?.membership.role === "MEMBER") {
					this.surveyFormGroup.patchValue({ noOfReports: 0 });
					this.readonlyNoOfReport = true;
				} else {
					this.readonlyNoOfReport = false;
					this.surveyFormGroup.patchValue({ noOfReports: undefined });
				}
			});
		}
	}
	get maxLimit() {
		switch (this.data.action) {
			case "CREATE":
			case "INCREASE_NO_OF_REPORTS":
				return (
					Math.floor(
						(this.org?.wallet?.balance + this.org?.wallet?.seededBalance) /
							this.costPerAssignmentAfterDiscount
					) || 0
				);
			case "DECREASE_NO_OF_REPORTS":
				return (
					this.data?.partnerSurvey?.noOfReports -
					this.data?.partnerSurvey?.noOfReportsUtilized
				);
		}
	}
	constructor(
		public partnerSurveyService: PartnerSurveyService,
		private _dialog: MatDialog,
		public organisationService: OrganisationService,
		private fb: FormBuilder,
		public UiLoaderService: NgxUiLoaderService,
		public dialogRef: MatDialogRef<PartnerSurveyComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			action: PARTNER_SURVEY_ACTION;
			partnerSurvey: any;
		}
	) {
		this.orgs$ = this.orgs.asObservable().pipe(
			scan((acc: ORG[], curr: ORG[]) => {
				return [...acc, ...curr];
			}, [])
		);
		switch (this.data.action) {
			case "CREATE":
				this.initCreateForm();
				break;
			case "DECREASE_NO_OF_REPORTS":
			case "INCREASE_NO_OF_REPORTS":
				this.initNoOfReportUpdateForm();
				break;
		}
		this.subscriptions.add(
			this.organisationService.getSelectedOrg().subscribe(org => {
				this.globalSelectedOrg = org;
				if (org) {
					this.surveyFormGroup.patchValue({ orgId: org.id });
					if (this.globalSelectedOrg?.membership.role === "MEMBER") {
						this.surveyFormGroup.patchValue({ noOfReports: 0 });
						this.readonlyNoOfReport = true;
					}
					this.readonlyOrgId = true;
				} else {
					this.readonlyNoOfReport = false;
					this.readonlyOrgId = false;
				}
			})
		);
	}
	ngOnInit(): void {
		this.getOrgs();
	}
	initCreateForm() {
		this.surveyFormGroup = this.fb.group({
			name: ["", Validators.required],
			assessment: [{ value: "charametrics", disabled: true }],
			assessmentType: [this.assessments_type[0].value, Validators.required],
			noOfReports: [, [Validators.required, Validators.min(0)]],
			surveyId: [1, Validators.required],
			orgId: ["", Validators.required],
			onCompletion: ["SHOW_PREVIEW_REPORT", Validators.required]
		});
		this.subscriptions.add(
			this.surveyFormGroup.get("orgId")?.valueChanges.subscribe(res => {
				this.getOrg();
			})
		);
		const noOfReportsFC = this.surveyFormGroup.get("noOfReports");
		this.subscriptions.add(
			this.surveyFormGroup.valueChanges.subscribe(n => {
				let errors: any = noOfReportsFC?.errors ?? null;
				if (this.org && noOfReportsFC?.value) {
					if (this.maxLimit < n.noOfReports) {
						if (!errors) errors = {};
						errors.max = { max: this.maxLimit, actual: n.noOfReports };
					} else {
						delete errors?.max;
					}
					noOfReportsFC?.setErrors(errors);
				}
			})
		);
		const onCompletionFC = this.surveyFormGroup.get("onCompletion");
		this.subscriptions.add(
			this.assessmentTypeFC?.valueChanges.subscribe(value => {
				switch (value as PARTNER_SURVEY_TYPE) {
					case "STANDARD":
						noOfReportsFC?.enable();
						onCompletionFC?.enable();
						break;
					case "ON_DEMAND":
						noOfReportsFC?.disable();
						onCompletionFC?.disable();
						break;
				}
				noOfReportsFC?.updateValueAndValidity();
			})
		);
	}
	initNoOfReportUpdateForm() {
		this.surveyFormGroup = this.fb.group({
			id: [],
			action: [],
			count: [1, [Validators.required]],
			orgId: []
		});
		this.subscriptions.add(
			this.surveyFormGroup.valueChanges.subscribe(n => {
				const fc = this.surveyFormGroup.get("count");
				if (isNotNullAndUndefined(n.count)) {
					if (this.maxLimit < n.count) {
						fc?.setErrors({ max: true });
					} else if (n.count < 1) {
						fc?.setErrors({ min: true });
					} else {
						fc?.setErrors(null);
					}
				}
			})
		);
		this.subscriptions.add(
			this.surveyFormGroup.get("orgId")?.valueChanges.subscribe(res => {
				this.getOrg();
			})
		);
		this.surveyFormGroup.patchValue({
			...this.data.partnerSurvey,
			action: this.data.action
		});
	}

	async getOrgs() {
		if (this.currentLength < this.total) {
			this.UiLoaderService.startBackgroundLoader(this.orgSelectListLoaderId);

			(
				await this.organisationService.getOrgs({
					offset: this.offset,
					limit: this.limit
				})
			).subscribe(res => {
				this.orgs.next(res.list);
				this.currentLength += res.list.length;
				this.total = res.total;
				this.UiLoaderService.stopBackgroundLoader(this.orgSelectListLoaderId);
			});
		}
	}
	submit() {
		this.surveyFormGroup.markAllAsTouched();
		if (this.surveyFormGroup.valid) {
			this.UiLoaderService.start();
			this.partnerSurveyService.create(this.surveyFormGroup.value).subscribe(
				(res: any) => {
					this.UiLoaderService.stop();
					if (res.statusCode == 200) {
						this.organisationService.refreshOrgs();
						this.dialogRef.close({ refresh: true });
					}
				},
				err => {
					this.UiLoaderService.stop();
				},
				() => {
					this.UiLoaderService.stop();
				}
			);
		}
	}
	submitNoOfReportsUpdate() {
		this.surveyFormGroup.markAllAsTouched();
		if (this.surveyFormGroup.valid) {
			this.UiLoaderService.start();
			let updated = this.surveyFormGroup.value;
			this.partnerSurveyService.update(updated.id, updated).subscribe(
				(res: any) => {
					this.UiLoaderService.stop();
					if (res.statusCode == 200) {
						this.organisationService.clearCache();
						this.organisationService.refreshEvent.emit();

						this.dialogRef.close({ refresh: true });
					}
				},
				err => {
					this.UiLoaderService.stop();
				},
				() => {
					this.UiLoaderService.stop();
				}
			);
		}
	}
	openBuyDialog() {
		this._dialog.open(BuyComponent, {
			minWidth: "60%",
			minHeight: "80%",
			maxHeight: "100%",
			panelClass: "ca-dialog",
			disableClose: true,
			data: {
				orgId: this.data.partnerSurvey.orgId
			}
		});
	}

	onScroll() {
		this.offset = Math.min(this.offset + this.limit, this.total);
		this.limit = Math.min(this.total - this.offset, this.limit);
		this.getOrgs();
	}
}
