import { Injectable } from "@angular/core";
import * as amplitude from "@amplitude/analytics-browser";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class AmplitudeService {
	amplitude = amplitude;
	identifyEvent;

	constructor() {
		amplitude.init(environment.amplitudeApiKey, {
			minIdLength: 1,
			appVersion: environment.version,
			defaultTracking: true
		});
		this.identifyEvent = new amplitude.Identify();
	}
	track(eventType: string, eventProperties?: any) {
		amplitude.track(eventType, eventProperties);
	}
	setUserId(userId: string) {
		amplitude.setUserId(userId);
	}
	setUserProperty(property: string, value: amplitude.Types.ValidPropertyType) {
		this.identifyEvent.set(property, value);
		amplitude.identify(this.identifyEvent);
	}
	setsessionId(sessionId: number) {
		amplitude.setSessionId(sessionId);
	}
	reset() {
		this.amplitude.reset();
	}
}
