import { Component, Input } from "@angular/core";
import { POSITION } from "ngx-ui-loader";
import { PositionType } from "ngx-ui-loader/lib/utils/types";

@Component({
	selector: "app-loader",
	templateUrl: "./loader.component.html",
	styleUrls: ["./loader.component.scss"]
})
export class LoaderComponent {
	@Input() loaderId?: string;
	@Input() text?: string;
	@Input() fgsSize: number = 60;
	@Input() bgsSize: number = 60;
	@Input() bgsPosition: PositionType = POSITION.bottomRight;
	@Input() inplaceLoader = false;
	@Input() showLoader = false;

	constructor() {}
}
