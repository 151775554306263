import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxStripeModule } from "ngx-stripe";
import { NgxUiLoaderModule } from "ngx-ui-loader";

import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { BarChart, LinesChart, RadarChart, TreeChart } from "echarts/charts";
import {
	GraphicComponent,
	GridComponent,
	LegendComponent,
	MarkLineComponent,
	TitleComponent,
	TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";

import { NgxEchartsModule } from "ngx-echarts";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgScrollbarModule } from "ngx-scrollbar";
import { ActivityLogsComponent } from "./components/activity-logs/activity-logs.component";
import { AspirationComponent } from "./components/aspiration/aspiration.component";
import { AspirationsComponent } from "./components/aspirations/aspirations.component";
import { AvatarComponent } from "./components/avatar/avatar.component";
import { AvatarsComponent } from "./components/avatars/avatars.component";
import { BuyComponent } from "./components/buy/buy.component";
import { C360OfferProgressDialogComponent } from "./components/c360-offer-progress-dialog/c360-offer-progress-dialog.component";
import { CategoryHandleComponent } from "./components/category-handle/category-handle.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { CharacterCardSelecterComponent } from "./components/character-card-selecter/character-card-selecter.component";
import { Character360EditComponent } from "./components/character360-edit/character360-edit.component";
import { Character360OverviewComponent } from "./components/character360-overview/character360-overview.component";
import { Character360RespondentsComponent } from "./components/character360-respondents/character360-respondents.component";
import { Character360StepperComponent } from "./components/character360-stepper/character360-stepper.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { CopyToClipboardComponent } from "./components/copy-to-clipboard/copy-to-clipboard.component";
import { CountryComponent } from "./components/country/country.component";
import { DualComparisonComponent } from "./components/dual-comparison/dual-comparison.component";
import { EmailsComponent } from "./components/emails/emails.component";
import { EPGroupDependentsComponent } from "./components/ep-group-dependents/ep-group-dependents.component";
import { EPGroupDialogComponent } from "./components/ep-group-dialog/ep-group-dialog.component";
import { EPUserSearchComponent } from "./components/ep-user-search/ep-user-search.component";
import { ErrorPageComponent } from "./components/error-page/error-page.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { GenerateGroupReportComponent } from "./components/generate-group-report/generate-group-report.component";
import { GoogleSigninComponent } from "./components/google-signin/google-signin.component";
import { GroupReportSelectComponent } from "./components/group-report-select/group-report-select.component";
import { GroupReportsComponent } from "./components/group-reports/group-reports.component";
import { HandleIndicatorComponent } from "./components/handle-indicator/handle-indicator.component";
import { ImageCropperComponent } from "./components/image-cropper/image-cropper.component";
import { InviteComponent } from "./components/invite/invite.component";
import { LinkAccountComponent } from "./components/link-account/link-account.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { LoginWithMobileComponent } from "./components/login-with-mobile/login-with-mobile.component";
import { LoginComponent } from "./components/login/login.component";
import { MembersVisibilityComponent } from "./components/members-visibility/members-visibility.component";
import { SideNavComponent } from "./components/menu/side-nav/side-nav.component";
import { TopBarComponent } from "./components/menu/top-bar/top-bar.component";
import { MicrosoftSigninComponent } from "./components/microsoft-signin/microsoft-signin.component";
import { MigrateReportComponent } from "./components/migrate-report/migrate-report.component";
import { MobileNumberComponent } from "./components/mobile-number/mobile-number.component";
import { MyCharacter360Component } from "./components/my-character360/my-character360.component";
import { NgScrollbarWrapperComponent } from "./components/ng-scrollbar-wrapper/ng-scrollbar-wrapper.component";
import { OrgBrandingComponent } from "./components/org-branding/org-branding.component";
import { OrganisationInviteComponent } from "./components/organisation-invite/organisation-invite.component";
import { OrganisationSelectComponent } from "./components/organisation-select/organisation-select.component";
import { OrganisationComponent } from "./components/organisation/organisation.component";
import { PartnerSurveySelectComponent } from "./components/partner-survey-select/partner-survey-select.component";
import { PartnerSurveyComponent } from "./components/partner-survey/partner-survey.component";
import { PayForReportComponent } from "./components/pay-for-report/pay-for-report.component";
import { PurchaseCharametricsReportComponent } from "./components/purchase-charametrics-report/purchase-charametrics-report.component";
import { QrCodeComponent } from "./components/qr-code/qr-code.component";
import { ReportSelectComponent } from "./components/report-select/report-select.component";
import { AnchorComponent } from "./components/report/anchor/anchor.component";
import { ArchetypeDescriptionComponent } from "./components/report/archetype-description/archetype-description.component";
import { ArchetypeDescriptionsComponent } from "./components/report/archetype-descriptions/archetype-descriptions.component";
import { ArchetypeComponent } from "./components/report/archetype/archetype.component";
import { CharametricsDescriptionsComponent } from "./components/report/charametrics-descriptions/charametrics-descriptions.component";
import { CoverComponent } from "./components/report/cover/cover.component";
import { CSAlignmentByGroupComponent } from "./components/report/cs-alignment-by-group/cs-alignment-by-group.component";
import { CSAlignmentByLayersComponent } from "./components/report/cs-alignment-by-layers/cs-alignment-by-layers.component";
import { CSProfileComponent } from "./components/report/cs-profile/cs-profile.component";
import { CSDComponent } from "./components/report/csd/csd.component";
import { CsrComponent } from "./components/report/csr/csr.component";
import { HowToReadThisReportComponent } from "./components/report/how-to-read-this-report/how-to-read-this-report.component";
import { ICSVsGAComponent } from "./components/report/ics-vs-ga/ics-vs-ga.component";
import { IndexComponent } from "./components/report/index/index.component";
import { PageBreakComponent } from "./components/report/page-break/page-break.component";
import { PageFooterComponent } from "./components/report/page-footer/page-footer.component";
import { PageHeaderComponent } from "./components/report/page-header/page-header.component";
import { PageTitleComponent } from "./components/report/page-title/page-title.component";
import { PreviewContentComponent } from "./components/report/preview-content/preview-content.component";
import { PreviewContentsComponent } from "./components/report/preview-contents/preview-contents.component";
import { ReportsDialogComponent } from "./components/reports-dialog/reports-dialog.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { ShareInvoiceComponent } from "./components/share-invoice/share-invoice.component";
import { ShareReportComponent } from "./components/share-report/share-report.component";
import { SingleComparisonComponent } from "./components/single-comparison/single-comparison.component";
import { StarRatingComponent } from "./components/star-rating/star-rating.component";
import { StepDependentGroupListComponent } from "./components/step-dependent-group-list/step-dependent-group-list.component";
import { StepGroupHierarchyComponent } from "./components/step-group-hierarchy/step-group-hierarchy.component";
import { StepGroupInvoicesComponent } from "./components/step-group-invoices/step-group-invoices.component";
import { StepGroupComponent } from "./components/step-group/step-group.component";
import { StepParentGroupSelectComponent } from "./components/step-parent-group-select/step-parent-group-select.component";
import { StepParentGroupStepperComponent } from "./components/step-parent-group-stepper/step-parent-group-stepper.component";
import { StepParentGroupComponent } from "./components/step-parent-group/step-parent-group.component";
import { StepSubscriptionComponent } from "./components/step-subscription/step-subscription.component";
import { SurveysTableComponent } from "./components/surveys-table/surveys-table.component";
import { TargetComponent } from "./components/target/target.component";
import { TransactionsComponent } from "./components/transactions/transactions.component";
import { UnpackSessionsLogComponent } from "./components/unpack-sessions-log/unpack-sessions-log.component";
import { UserSignupComponent } from "./components/user-signup/user-signup.component";
import { CopyToClipboardDirective } from "./directives/copy-to-clipboard.directive";
import { ImgFallbackDirective } from "./directives/img-fallback.directive";
import { MatchPasswordDirective } from "./form/directives/match-password.directive";
import { PhoneNumberDirective } from "./form/directives/phone-number.directive";
import { MaterialModule } from "./material/material.module";
import { CustomNumberPipe } from "./pipes/custom-number.pipe";
import { FilepathPipe } from "./pipes/filepath.pipe";
import { FilterPipe } from "./pipes/filter.pipe";
import { FirstCapPipe } from "./pipes/first-cap.pipe";
import { LogPipe } from "./pipes/log.pipe";
import { MarkdownPipe } from "./pipes/markdown.pipe";
import { OrdinalPipe } from "./pipes/ordinal.pipe";
import { OrgTypeDiscountPipe } from "./pipes/org-type-discount.pipe";
import { OrgTypePipe } from "./pipes/org-type.pipe";
import { PageNumberPipe } from "./pipes/page-number.pipe";
import { PhoneNumberPipe } from "./pipes/phonenumber.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";

echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	BarChart,
	LegendComponent,
	SVGRenderer,
	MarkLineComponent,
	RadarChart,
	TreeChart,
	LinesChart,
	GraphicComponent
]);
@NgModule({
	declarations: [
		LoaderComponent,
		StepParentGroupStepperComponent,
		MarkdownPipe,
		SafePipe,
		OrdinalPipe,
		TruncatePipe,
		ErrorPageComponent,
		CSDComponent,
		CSProfileComponent,
		ICSVsGAComponent,
		CSAlignmentByGroupComponent,
		CSAlignmentByLayersComponent,
		CsrComponent,
		CoverComponent,
		CharametricsDescriptionsComponent,
		PageTitleComponent,
		PageBreakComponent,
		ArchetypeComponent,
		PageFooterComponent,
		MatchPasswordDirective,
		PhoneNumberDirective,
		ImageCropperComponent,
		FilepathPipe,
		PreviewContentComponent,
		AvatarComponent,
		PartnerSurveyComponent,
		ImgFallbackDirective,
		FirstCapPipe,
		AspirationsComponent,
		SingleComparisonComponent,
		DualComparisonComponent,
		AspirationComponent,
		TargetComponent,
		PartnerSurveySelectComponent,
		ReportsDialogComponent,
		ReportsComponent,
		OrganisationComponent,
		OrganisationInviteComponent,
		ReportSelectComponent,
		SurveysTableComponent,
		OrgTypePipe,
		OrgTypeDiscountPipe,
		CountryComponent,
		ForgotPasswordComponent,
		ChangePasswordComponent,
		LoginWithMobileComponent,
		PayForReportComponent,
		StepGroupComponent,
		StepParentGroupComponent,
		StepParentGroupSelectComponent,
		StepDependentGroupListComponent,
		FilterPipe,
		ConfirmDialogComponent,
		OrganisationSelectComponent,
		AvatarsComponent,
		BuyComponent,
		StepGroupHierarchyComponent,
		StepGroupInvoicesComponent,
		InviteComponent,
		CustomNumberPipe,
		TransactionsComponent,
		ShareReportComponent,
		GenerateGroupReportComponent,
		GroupReportsComponent,
		GroupReportSelectComponent,
		PurchaseCharametricsReportComponent,
		PreviewContentsComponent,
		IndexComponent,
		AnchorComponent,
		UnpackSessionsLogComponent,
		ActivityLogsComponent,
		ArchetypeDescriptionsComponent,
		PageNumberPipe,
		UserSignupComponent,
		TopBarComponent,
		SideNavComponent,
		LoginComponent,
		StarRatingComponent,
		CharacterCardSelecterComponent,
		MobileNumberComponent,
		LogPipe,
		ShareInvoiceComponent,
		PhoneNumberPipe,
		CategoryHandleComponent,
		MyCharacter360Component,
		Character360OverviewComponent,
		Character360StepperComponent,
		Character360EditComponent,
		EPGroupDialogComponent,
		EPUserSearchComponent,
		EPGroupDependentsComponent,
		QrCodeComponent,
		OrgBrandingComponent,
		HandleIndicatorComponent,
		MigrateReportComponent,
		MembersVisibilityComponent,
		EmailsComponent,
		StepSubscriptionComponent,
		CopyToClipboardDirective,
		CopyToClipboardComponent,
		NgScrollbarWrapperComponent,
		PageHeaderComponent,
		GoogleSigninComponent,
		LinkAccountComponent,
		MicrosoftSigninComponent,
		Character360RespondentsComponent,
		ArchetypeDescriptionComponent,
		C360OfferProgressDialogComponent,
		HowToReadThisReportComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		MaterialModule,
		NgxUiLoaderModule,
		NgxEchartsModule.forRoot({ echarts }),
		ImageCropperModule,
		FlexLayoutModule,
		NgxStripeModule,
		RouterModule,
		InfiniteScrollModule,
		MatSelectInfiniteScrollModule,
		NgScrollbarModule
	],
	exports: [
		LoaderComponent,
		ErrorPageComponent,
		NgxUiLoaderModule,
		DragDropModule,
		MarkdownPipe,
		SafePipe,
		OrdinalPipe,
		ReactiveFormsModule,
		TruncatePipe,
		FormsModule,
		CSDComponent,
		CSProfileComponent,
		PageTitleComponent,
		ICSVsGAComponent,
		CSAlignmentByGroupComponent,
		CSAlignmentByLayersComponent,
		CsrComponent,
		CoverComponent,
		CharametricsDescriptionsComponent,
		ArchetypeComponent,
		PageFooterComponent,
		MatchPasswordDirective,
		PageBreakComponent,
		PhoneNumberDirective,
		FilepathPipe,
		PreviewContentComponent,
		PreviewContentsComponent,
		AvatarComponent,
		ImgFallbackDirective,
		FirstCapPipe,
		PartnerSurveySelectComponent,
		SurveysTableComponent,
		OrgTypePipe,
		OrgTypeDiscountPipe,
		CountryComponent,
		ForgotPasswordComponent,
		ChangePasswordComponent,
		OrganisationSelectComponent,
		BuyComponent,
		CustomNumberPipe,
		IndexComponent,
		TransactionsComponent,
		AnchorComponent,
		ArchetypeDescriptionsComponent,
		PageNumberPipe,
		UserSignupComponent,
		TopBarComponent,
		SideNavComponent,
		LoginComponent,
		StarRatingComponent,
		CharacterCardSelecterComponent,
		MobileNumberComponent,
		LogPipe,
		InfiniteScrollModule,
		MatSelectInfiniteScrollModule,
		ShareInvoiceComponent,
		PhoneNumberPipe,
		CategoryHandleComponent,
		MyCharacter360Component,
		Character360OverviewComponent,
		Character360StepperComponent,
		Character360EditComponent,
		QrCodeComponent,
		OrgBrandingComponent,
		MembersVisibilityComponent,
		CopyToClipboardDirective,
		CopyToClipboardComponent,
		NgScrollbarWrapperComponent,
		PageHeaderComponent,
		HowToReadThisReportComponent
	]
})
export class SharedModule {}
