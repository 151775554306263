import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ViewChild } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { MatChipInputEvent, MatChipList } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ImageCropperComponent } from "../_shared/components/image-cropper/image-cropper.component";
import { DeviceService } from "../_shared/services/device.service";
import { IMember } from "../_shared/types/common";
import { validateEmail } from "../_shared/utils/string";
import { AccountService } from "./../_shared/services/account.service";
import { AuthService } from "./../_shared/services/auth.service";
import { FileService } from "./../_shared/services/file.service";
@Component({
	selector: "app-sign-up",
	templateUrl: "./sign-up.component.html",
	styleUrls: ["./sign-up.component.scss"]
})
export class SignUpComponent {
	inviteToken!: string;
	tokenStatus!: "VALID" | "INVALID" | "WITHDRAWN";
	typeOf?: "PARTNER" | "CLIENT";
	isJoinOrgInvite!: boolean;
	org!: any;
	signupFormModel: {
		firstName?: string;
		lastName?: string;
		mobileNo?: string;
		emailId?: string;
		password?: string;
		cpassword?: string;
		imageKey?: string;
	} = {};
	createGroupFormModel: {
		imageKey?: string;
		name?: string;
		membersToBeInvited: IMember[];
	} = {
		membersToBeInvited: []
	};
	memberfieldRaw!: string;
	userProfileImage: { src?: string; file?: File } = {};
	groupProfileImage: { src?: string; file?: File } = {};
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	view: "USER_CREATION" | "GROUP_CREATION" = "USER_CREATION";
	device: any;
	timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
	@ViewChild("chipList") chipList!: MatChipList;
	@ViewChild("signupForm") signupForm!: NgForm;
	@ViewChild("createGroupForm") createGroupForm!: NgForm;
	constructor(
		private UiLoaderService: NgxUiLoaderService,
		private activatedRoute: ActivatedRoute,
		private accountService: AccountService,
		private authService: AuthService,
		private router: Router,
		public dialog: MatDialog,
		public fileService: FileService,
		private deviceService: DeviceService
	) {
		this.getDeviceInfo();
		this.activatedRoute.paramMap.subscribe(params => {
			if (params.has("inviteToken")) {
				this.inviteToken = params.get("inviteToken") as string;
				this.verifyInvite();
			} else {
				this.tokenStatus = "INVALID";
			}
		});
	}
	async getDeviceInfo() {
		this.device = await this.deviceService.getDeviceInfo();
	}
	addEmail(event: MatChipInputEvent, membersNgModel: NgModel): void {
		const email = (event.value || "").trim();
		if (email) {
			this.createGroupFormModel?.membersToBeInvited?.push({
				email,
				invalid: !validateEmail(email)
			});
			this.validateMemberEmails(membersNgModel);
		}
		event.chipInput!.clear();
	}

	removeEmail(index: number, membersNgModel: NgModel): void {
		this.createGroupFormModel?.membersToBeInvited?.splice(index, 1);
		this.validateMemberEmails(membersNgModel);
	}
	validateMemberEmails(membersNgModel: NgModel) {
		let invalid = this.createGroupFormModel.membersToBeInvited.some(
			element => element.invalid
		);
		if (invalid) {
			this.chipList.errorState = true;
			membersNgModel.control.setErrors({ containsInvalidEmail: true });
		} else {
			this.chipList.errorState = false;
			membersNgModel.control.setErrors(null);
		}
	}
	verifyInvite() {
		this.UiLoaderService.start();
		this.accountService.verifyInvite({ token: this.inviteToken }).subscribe(
			async (res: any) => {
				if (res.isAlreadySignedUp) {
					if (res.isLoggedInUser) {
						this.router.navigate(["invite", this.inviteToken]);
					} else {
						if (this.authService.isLoggedIn()) {
							await this.authService.logout(false);
						}
						// not logged in user so redirect to login page with the return url and return false
						this.router.navigate(["login"], {
							queryParams: { returnUrl: this.router.url },
							skipLocationChange: true
						});
					}
				} else {
					if (this.authService.isLoggedIn()) {
						await this.authService.logout(false);
					}
				}
				this.typeOf = res.typeOf;
				this.isJoinOrgInvite = res.isJoinOrgInvite;
				this.org = res.organisation;
				this.tokenStatus = "VALID";
				this.UiLoaderService.stop();
			},
			err => {
				switch (err.error.statusCode) {
					case 422:
						this.tokenStatus = "WITHDRAWN";
						break;
					case 400:
						this.tokenStatus = "INVALID";
						break;
					case 404:
						this.tokenStatus = "INVALID";
						break;
				}
				this.UiLoaderService.stop();
			},
			() => {
				this.UiLoaderService.stop();
			}
		);
	}
	continue() {
		this.signupForm.form.markAllAsTouched();
		if (this.signupForm.valid) {
			this.view = "GROUP_CREATION";
		}
	}
	back() {
		this.view = "USER_CREATION";
	}
	async signup() {
		this.createGroupForm.form.markAllAsTouched();
		if (
			this.signupForm.valid &&
			(this.isJoinOrgInvite || this.createGroupForm.valid)
		) {
			if (this.userProfileImage.file) {
				try {
					this.signupFormModel.imageKey = await this.fileService.uploadFile(
						this.userProfileImage.file,
						"USER_PROFILE"
					);
					delete this.userProfileImage.file;
				} catch (error) {
					return;
				}
			}
			let payload: any = {
				...this.signupFormModel,
				inviteToken: this.inviteToken,
				timeZone: this.timeZone,
				device: this.device
			};
			if (!this.isJoinOrgInvite) {
				if (this.groupProfileImage.file) {
					try {
						this.createGroupFormModel.imageKey =
							await this.fileService.uploadFile(
								this.groupProfileImage.file,
								"GROUP_PROFILE"
							);
						delete this.groupProfileImage.file;
					} catch (error) {
						return;
					}
				}
				payload.group = {
					...this.createGroupFormModel,
					membersToBeInvited: this.createGroupFormModel.membersToBeInvited.map(
						m => m.email
					)
				};
			}
			delete payload.cpassword;
			this.UiLoaderService.start();
			this.accountService.createAccount(payload).subscribe(
				(res: any) => {
					this.UiLoaderService.stop();
					if (res.statusCode == 200) {
						this.authService.setAuth(res.authToken);
						this.authService.setLoggedIn();
						this.authService.setUserInfo(res.user);
						this.router.navigate(["/home"]);
					}
				},
				err => {
					this.UiLoaderService.stop();
				}
			);
		}
	}
	onUserProfileUpload($event: Event) {
		const target = $event.target as HTMLInputElement;
		const files = target.files as FileList;
		if (files?.length) {
			const dialogRef = this.dialog.open(ImageCropperComponent, {
				data: {
					event: $event,
					type: "PROFILE_IMAGE"
				}
			});
			dialogRef.afterClosed().subscribe(async result => {
				if (result) {
					this.userProfileImage.src = result.base64;
					this.userProfileImage.file = result.blob;
				} else {
					($event.target as HTMLInputElement).value = "";
				}
			});
		}
	}
	onGroupProfileUpload($event: Event) {
		const target = $event.target as HTMLInputElement;
		const files = target.files as FileList;
		if (files?.length) {
			const dialogRef = this.dialog.open(ImageCropperComponent, {
				data: {
					event: $event,
					type: "PROFILE_IMAGE"
				}
			});
			dialogRef.afterClosed().subscribe(async result => {
				if (result) {
					this.groupProfileImage.src = result.base64;
					this.groupProfileImage.file = result.blob;
				} else {
					($event.target as HTMLInputElement).value = "";
				}
			});
		}
	}
	removeGroupPic() {
		delete this.groupProfileImage.src;
		delete this.groupProfileImage.file;
	}
	removeProfilePic() {
		delete this.userProfileImage.src;
		delete this.userProfileImage.file;
	}
}
