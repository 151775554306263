<h1 mat-dialog-title>
	<ng-container [ngSwitch]="dialogData.type">
		<ng-container *ngSwitchCase="'LINK'">
			Link Your Accounts
		</ng-container>
		<ng-container *ngSwitchCase="'MANAGE'">
			Linked Accounts
		</ng-container>
	</ng-container>
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<div class="flex-col gap-20">
		<app-google-signin *ngIf="!authService.user?.googleId" (signedIn)="signedInWithGoogle($event)"></app-google-signin>
		<div class="flex justify-between align-center gap-10 " *ngIf="authService.user?.googleId">
			<div class="flex align-center gap-5 flex-auto">
				<mat-icon svgIcon="google"></mat-icon>
				<mat-hint class="email">
					<app-copy-to-clipboard [content]="authService.user?.google.email" [overflowEllipsis]="true">
						{{authService.user?.google.email}}
					</app-copy-to-clipboard>
				</mat-hint>
			</div>
			<button class="remove-btn" (click)="delinkGoogle()" mat-stroked-button color="warn">
				<mat-icon>close</mat-icon>
				Remove
			</button>
		</div>

		<app-microsoft-signin *ngIf="!authService.user?.microsoftId"
			(signedIn)=signedInWithMicrosoft($event)></app-microsoft-signin>
		<div class="flex justify-between align-center gap-10" *ngIf="authService.user?.microsoftId">
			<div class="flex align-center gap-5 flex-auto ">
				<mat-icon svgIcon="ms"></mat-icon>
				<mat-hint class="email">
					<app-copy-to-clipboard [content]="authService.user?.microsoft.preferred_username" [overflowEllipsis]="true">
						{{authService.user?.microsoft.preferred_username}}
					</app-copy-to-clipboard>
				</mat-hint>
			</div>
			<button class="remove-btn" (click)="delinkMicrosoft()" mat-stroked-button color="warn">
				<mat-icon>close</mat-icon>
				Remove
			</button>
		</div>
	</div>
</mat-dialog-content>
