import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { EChartsOption } from "echarts";
import { TooltipOption } from "echarts/types/dist/shared";
import { getValues, prepareDataValues } from "../character";

import { initOpts, loadingOpts } from "../../../utils/chart-options";
import { CHART_DEFAULTS } from "./chart-defaults";

@Component({
	selector: "app-ics-vs-ga",
	templateUrl: "./ics-vs-ga.component.html",
	styleUrls: ["./ics-vs-ga.component.scss"]
})
export class ICSVsGAComponent implements OnInit, OnChanges {
	@Input() report: any;
	@Input() isLoading: boolean = true;
	@Input() showTooltip: boolean = true;
	initOpts = initOpts;
	loadingOpts = loadingOpts;
	default: EChartsOption = CHART_DEFAULTS;
	chartOptionMoral!: EChartsOption;
	chartOptionPerformance!: EChartsOption;
	constructor() {}
	ngOnInit(): void {
		(this.default.tooltip! as TooltipOption).show = this.showTooltip;
		this.initMoralData();
		this.initPerformanceData();
	}
	ngOnChanges() {
		this.initMoralData();
		this.initPerformanceData();
	}
	initMoralData() {
		let data = this.report.characterStrengths.vsGSD.MORAL;
		this.chartOptionMoral = {
			title: {
				text: "Moral"
			},
			yAxis: {
				data: getValues("LABELS", "MORAL", "INDIVIDUAL")
			},
			series: {
				color: "#604b79",
				data: prepareDataValues(data, "MORAL", "COMBINED")
			}
		};
	}
	initPerformanceData() {
		let data = this.report.characterStrengths.vsGSD.PERFORMANCE;
		this.chartOptionPerformance = {
			title: {
				text: "Performance"
			},
			yAxis: {
				data: getValues("LABELS", "PERFORMANCE", "INDIVIDUAL")
			},
			series: {
				color: "#E46C0A",
				data: prepareDataValues(data, "PERFORMANCE", "COMBINED")
			}
		};
	}
}
