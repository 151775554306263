<section>
	<div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
		<div fxLayout="column" fxFlex="0 0 60%" fxLayoutAlign="start center" fxFlexFill>
			<div fxLayout="row" fxFlex="0 0 30%" fxLayoutAlign="center center">
				<img height="auto" width="100%" src="/assets/logo.png" />
			</div>
			<div fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 40%" fxFlexFill style="text-align: center;">
				<ng-container *ngIf="clientSide; then thenTemplate; else elseTemplate"></ng-container>
			</div>
		</div>
	</div>
</section>

<ng-template #thenTemplate>
	<ng-container [ngSwitch]="error.statusCode">
		<ng-container *ngSwitchCase="404">
			<div>
				<mat-icon class="error-icon" color="accent">warning_amber</mat-icon>
				<br>
				The page you are looking for does not exist. It may have been moved, or removed altogether. Perhaps you
				can return back to the site's homepage and see if you can find what you are looking for.
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="403">
			<div>
				<mat-icon class="error-icon" color="accent">warning_amber</mat-icon>
				<br>
				You don't have Access to the page you are looking for. It may have been moved, access changed or removed
				altogether. Perhaps you
				can return back to the site's homepage and see if you can find what you are looking for.
			</div>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<div>
				<mat-icon class="error-icon" color="accent">warning_amber</mat-icon>
				<br>
				{{error.message}}
			</div>
		</ng-container>
	</ng-container>
</ng-template>
<ng-template #elseTemplate>
	<div [ngSwitch]="error.statusCode">
		<mat-icon class="error-icon" color="accent">warning_amber</mat-icon>
		<br>
		<ng-container *ngSwitchCase="490">
			{{error.message}}
		</ng-container>
		<ng-container *ngSwitchCase="404">
			The page you are looking for does not exist. It may have been moved, or removed altogether.
			Perhaps
			you
			can
			return back to the site's homepage and see if you can find what you are looking for.
		</ng-container>

		<ng-container *ngSwitchCase="403">
			You don't have Access to the page you are looking for. It may have been moved, access changed or removed
			altogether. Perhaps you
			can return back to the site's homepage and see if you can find what you are looking for.
		</ng-container>
		<ng-container *ngSwitchDefault> {{error.message}}</ng-container>
	</div>
</ng-template>
