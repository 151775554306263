<!-- <mat-nav-list>
    <mat-list-item *ngFor="let route of routes" [routerLink]="route?.routeLink" routerLinkActive="active">
        <mat-icon *ngIf="route?.icon" mat-list-icon>{{route?.icon}}</mat-icon>
        <mat-icon *ngIf="route?.svgIcon" svgIcon="{{route?.svgIcon}}" mat-list-icon> </mat-icon>
        <h4 mat-line *ngIf="show">{{route?.name}}</h4>
    </mat-list-item>
</mat-nav-list> -->



<mat-nav-list id="main">
	<mat-accordion class="nav-accordian" displayMode="flat">
		<ng-container *ngFor="let route of routes">
			<ng-container *ngTemplateOutlet="!route.children ? itemRef:nestedRef; context: {route}"></ng-container>
			<ng-template #nestedRef let-route="route">
				<mat-expansion-panel [class.showing]="show" #rla="routerLinkActive" [expanded]="show && rla.isActive"
					routerLinkActive="active" [class.mat-elevation-z0]="true" [hideToggle]="!show"
					[routerLinkActiveOptions]="{exact: false}">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-icon *ngIf="route?.icon" mat-list-icon>{{route?.icon}}</mat-icon>
							<mat-icon *ngIf="route?.svgIcon" svgIcon="{{route?.svgIcon}}" mat-list-icon> </mat-icon>
						</mat-panel-title>
						<mat-panel-description *ngIf="show">
							{{route?.name}}
						</mat-panel-description>
					</mat-expansion-panel-header>
					<mat-nav-list>
						<ng-container *ngFor="let child of route.children">
							<mat-list-item class="nav-item" [routerLink]="child.routeLink" routerLinkActive="active">
								<mat-icon *ngIf="child.icon" mat-list-icon>{{child.icon}}</mat-icon>
								<mat-icon *ngIf="child.svgIcon" svgIcon="{{child.svgIcon}}" mat-list-icon> </mat-icon>
								<mat-icon *ngIf="!child.icon && !child.svgIcon" mat-list-icon> </mat-icon>
								<h4 mat-line *ngIf="show">{{child.name}}</h4>
							</mat-list-item>
						</ng-container>
					</mat-nav-list>
				</mat-expansion-panel>
			</ng-template>
			<ng-template #itemRef let-route="route">
				<ng-container *ngIf="!isSTCAPlatform">
					<ng-container *ngTemplateOutlet="link; context: {route}"></ng-container>
				</ng-container>
				<ng-container *ngIf="isSTCAPlatform">
					<ng-container *ngIf="!org">
						<ng-container *ngIf="user.role==='ADMIN'">
							<ng-container *ngTemplateOutlet="link; context: {route}"></ng-container>
						</ng-container>
						<ng-container *ngIf="user.role==='USER'">
							<ng-container *ngIf="route.roleLevel===2">
								<ng-container *ngTemplateOutlet="link; context: {route}"></ng-container>
							</ng-container>
							<ng-container *ngIf="route.roleLevel===1">
								<ng-container *ngTemplateOutlet="disabledLink; context: {route}"></ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="org && canShow(route.roleLevel) ">
						<ng-container *ngTemplateOutlet="link; context: {route}"></ng-container>
					</ng-container>
				</ng-container>
			</ng-template>
		</ng-container>
	</mat-accordion>
</mat-nav-list>


<ng-template #link let-route="route">
	<mat-list-item class="nav-item" [routerLink]="route?.routeLink" routerLinkActive="active">
		<mat-icon *ngIf="route?.icon" mat-list-icon>{{route?.icon}}</mat-icon>
		<mat-icon *ngIf="route?.svgIcon" svgIcon="{{route?.svgIcon}}" mat-list-icon> </mat-icon>
		<mat-icon *ngIf="!route?.icon && !route?.svgIcon" mat-list-icon> </mat-icon>
		<h4 mat-line *ngIf="show">{{route?.name}}</h4>
	</mat-list-item>
</ng-template>

<ng-template #disabledLink let-route="route">
	<mat-list-item class="nav-item disabled" (click)="showError()">
		<mat-icon *ngIf="route?.icon" mat-list-icon>{{route?.icon}}</mat-icon>
		<mat-icon *ngIf="route?.svgIcon" svgIcon="{{route?.svgIcon}}" mat-list-icon> </mat-icon>
		<mat-icon *ngIf="!route?.icon && !route?.svgIcon" mat-list-icon> </mat-icon>
		<h4 mat-line *ngIf="show">{{route?.name}}</h4>
	</mat-list-item>
</ng-template>