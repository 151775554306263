import { Component } from "@angular/core";

@Component({
	selector: "app-page-break",
	template: `
		<p class="page-break-after">&nbsp;</p>
		<p class="page-break-before">&nbsp;</p>
	`,
	styleUrls: ["page-break.component.scss"]
})
export class PageBreakComponent {
	constructor() {}
}
