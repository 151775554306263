import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

@Component({
	selector: "app-reports-dialog",
	templateUrl: "./reports-dialog.component.html",
	styleUrls: ["./reports-dialog.component.scss"]
})
export class ReportsDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
