import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { getSurveyURL } from "src/app/_shared/utils/string";
const fontSize = { min: 7.5, max: 20 };
@Component({
	selector: "app-cover",
	templateUrl: "./cover.component.html",
	styleUrls: ["./cover.component.scss"]
})
export class CoverComponent {
	@Input() report: any;
	reportURL: string;
	surveyBASEURL = getSurveyURL();

	constructor(public router: Router) {
		this.reportURL = location.origin + this.router.url.split("?")[0];
	}
	fontSize(name: HTMLDivElement) {
		return Math.max(
			Math.min(fontSize.min * (600 / name.innerText.length), fontSize.max),
			fontSize.min
		);
	}
	get showReportQRCode() {
		return this.report?.type === "INDIVIDUAL";
	}
	get showGroupReportQRCode() {
		return this.report?.type === "GROUP";
	}
	get showOrgSurveyQRCode() {
		return this.report?.quickLinkedPartnerSurvey?.slug;
	}
	get surveyInfoText() {
		switch (this.report.type) {
			case "INDIVIDUAL":
				return "Scan to do another Charametrics";
			case "GROUP":
				return "Take a Character Strengths Assessment";
		}
		return "";
	}

	get surveyURL() {
		return `${this.surveyBASEURL}/character/${this.report?.quickLinkedPartnerSurvey?.slug}`;
	}
	get org() {
		return this.report?.org || this.report?.partnerSurvey?.org;
	}
}
