<div class="rating-container">
	<div class="labels flex align-center justify-center">
		<div class="cell label text-center" *ngFor="let label of ratingLabels;let i=index" (click)="rate(i+1)">
			{{label}}
		</div>
	</div>
	<div class="stars flex align-center justify-center">
		<div class="cell star grey-bg text-center padding-8 cursorPointer" *ngFor="let label of ratingLabels;let i=index"
			(click)="rate(i+1)">
			<mat-icon *ngIf="(i)<value" color="primary">
				star
			</mat-icon>
			<mat-icon *ngIf="!((i)<value)" color="primary">
				star_border
			</mat-icon>
		</div>
	</div>
</div>
