import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { EChartsOption } from "echarts";
import { TooltipOption } from "echarts/types/dist/shared";
import { cloneDeep } from "lodash";
import {
	ALIGNMENT_BY_LAYERS_KEYS,
	ALIGNMENT_BY_LAYERS_LABELS
} from "../character";
import { initOpts, loadingOpts } from "../../../utils/chart-options";
import { CHART_DEFAULTS, SERIE_DEFAULTS_ASPIRATION } from "./chart-defaults";

@Component({
	selector: "app-cs-alignment-by-layers",
	templateUrl: "./cs-alignment-by-layers.component.html",
	styleUrls: ["./cs-alignment-by-layers.component.scss"]
})
export class CSAlignmentByLayersComponent implements OnChanges, OnInit {
	@Input() report: any;
	@Input() group: any;
	@Input() role: any;
	@Input() compReport: any;
	@Input() compReport2: any;
	@Input() compGroupReport: any;
	@Input() compGroupReport2: any;
	@Input() isLoading: boolean = true;
	@Input() showTooltip: boolean = true;
	@Input() showGroupAlignment: boolean = false;
	@Input() isSample: boolean = false;
	initOpts = initOpts;
	loadingOpts = loadingOpts;
	default: EChartsOption = CHART_DEFAULTS;
	chartOption!: EChartsOption;
	constructor() {}
	ngOnInit(): void {
		(this.default.tooltip! as TooltipOption).show = this.showTooltip;
	}
	ngOnChanges(): void {
		this.initAlignmentByLayers();
	}
	initAlignmentByLayers() {
		let data = this.report.characterStrengthsProfile.alignmentByLayers;
		let temp: any = {
			yAxis: [
				{
					data: ALIGNMENT_BY_LAYERS_LABELS
				}
				// {
				// 	data: ALIGNMENT_BY_LAYERS_LABELS.map(label =>
				// 		label.replace(/& /, "&\n\t\t\t\t")
				// 	)
				// }
			],
			series: [
				{
					data: ALIGNMENT_BY_LAYERS_KEYS.map(key => data[key])
				}
			]
		};
		if (
			this.showGroupAlignment &&
			this.group &&
			!this.role &&
			!this.compReport2
		) {
			let targetsData = this.group.alignmentByLayers;
			let targets = {
				...cloneDeep(SERIE_DEFAULTS_ASPIRATION),
				data: ALIGNMENT_BY_LAYERS_KEYS.map(key => targetsData[key])
			};
			temp.series.push(targets);
		}
		/**=======================
		 *  commented as it is not needed now
		 *========================**/
		// if (this.group && (this.role ||this.compReport2)) {
		// 	let grouptargetsData = this.group.alignmentByLayers;
		// 	let groupTarget = {
		// 		...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS),
		// 		color: comparison1Color,
		// 		name: truncate(this.group.name, { length: 35 }),
		// 		data: ALIGNMENT_BY_LAYERS_KEYS.map(key => grouptargetsData[key])
		// 	};
		// 	groupTarget.itemStyle.borderColor = comparison1Color;
		// 	temp.series.push(groupTarget);
		// 	let roletargetsData = this.role.alignmentByLayers;
		// 	let roleTarget = {
		// 		...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS),
		// 		color: comparison2Color,
		// 		name: truncate(this.role.name, { length: 35 }),
		// 		data: ALIGNMENT_BY_LAYERS_KEYS.map(key => roletargetsData[key])
		// 	};
		// 	roleTarget.itemStyle.borderColor = comparison2Color;
		// 	temp.series.push(roleTarget);
		// }

		// if (this.compReport && !this.compReport2) {
		// 	temp.series[0].name = getName(this.report)+" Score"

		// 	let targetsData =
		// 		this.compReport.characterStrengthsProfile.alignmentByLayers;
		// 	let targets = {
		// 		...cloneDeep(SERIE_DEFAULTS_ASPIRATION),
		// 		data: ALIGNMENT_BY_LAYERS_KEYS.map(key => targetsData[key]),
		// 		name: truncate(this.compReport.name)
		// 	};
		// 	temp.series.push(targets);
		// } else if (this.compReport2 && !this.compReport) {
		// 	temp.series[0].name = getName(this.report)+" Score"

		// 	let targetsData =
		// 		this.compReport2.characterStrengthsProfile.alignmentByLayers;
		// 	let targets = {
		// 		...cloneDeep(SERIE_DEFAULTS_ASPIRATION),
		// 		data: ALIGNMENT_BY_LAYERS_KEYS.map(key => targetsData[key]),
		// 		color: comparison2Color,
		// 		name: truncate(this.compReport2.name)
		// 	};
		// 	targets.itemStyle.borderColor = comparison2Color;
		// 	temp.series.push(targets);
		// } else if (this.compReport && this.compReport2) {
		// 	temp.series[0].name = getName(this.report)+" Score"

		// 	let compReportData =
		// 		this.compReport.characterStrengthsProfile.alignmentByLayers;
		// 	let compReportSerie = {
		// 		...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS),
		// 		data: ALIGNMENT_BY_LAYERS_KEYS.map(key => compReportData[key]),
		// 		color: comparison1Color,
		// 		name: truncate(this.compReport.name)
		// 	};
		// 	temp.series.push(compReportSerie);

		// 	let compReport2Data =
		// 		this.compReport2.characterStrengthsProfile.alignmentByLayers;
		// 	let compReport2Serie = {
		// 		...cloneDeep(SERIE_DEFAULTS_ASPIRATIONS),
		// 		data: ALIGNMENT_BY_LAYERS_KEYS.map(key => compReport2Data[key]),
		// 		color: comparison2Color,
		// 		name: truncate(this.compReport2.name)
		// 	};
		// 	compReport2Serie.itemStyle.borderColor = comparison2Color;
		// 	temp.series.push(compReport2Serie);
		// }
		this.chartOption = temp;
	}
}
