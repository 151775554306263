<section class="ca-index-container">
	<div class="ca-index-cover">
		<img height="100%" width="100%" src="/assets/images/index-cover.png">
	</div>
	<div class="ca-index-panel">
		<div class="ca-index-content">
			<div class="ca-index-title">
				Understanding Your Report
			</div>
			<div class="ca-index-groups">
				<div class="ca-index-group">
					<div class="ca-heading">
						Your Profile
					</div>
					<app-anchor type="LINK" anchorId="YCASW">
						<div class="ca-sub-heading">
							<span class="ca-title">Your Character Archetypes Summary Wheel</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"YCASW"|pageNumber:pageNoOffsetForComparison}}

							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="YCAST">
						<div class="ca-sub-heading">
							<span class="ca-title">Your Character Archetypes Summary Traits</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"YCAST"|pageNumber:pageNoOffsetForComparison}}

							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="CAT">
						<div class="ca-sub-heading">
							<span class="ca-title">Character Archetypes Table</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"CAT"|pageNumber:pageNoOffsetForComparison:'CHARAMETRICS':previewOffset}}
							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="YCSP">
						<div class="ca-sub-heading ">
							<span class="ca-title">Your Character Strengths Focus</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"YCSP"|pageNumber:pageNoOffsetForComparison:'CHARAMETRICS':previewOffset}}

							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="YCSR">
						<div class="ca-sub-heading">
							<span class="ca-title">Your Character Strengths Report</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"YCSR"|pageNumber:pageNoOffsetForComparison:'CHARAMETRICS':previewOffset}}

							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="YCSP_C" *ngIf="isComparison">
						<div class="ca-sub-heading ">
							<span class="ca-title">Your Character Strengths Focus{{comparisonTitle}}</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"YCSP_C"|pageNumber:pageNoOffsetForComparison:'CHARAMETRICS':previewOffset}}

							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="YCSG3">
						<div class="ca-sub-heading">
							<span class="ca-title">Your 3 leading character strength groups</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"YCSG3"|pageNumber:pageNoOffsetForComparison + previewOffset}}

							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="YCGP" class="only-on-print">
						<div class="ca-sub-heading">
							<span class="ca-title">Your Character Growth Plan</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"YCGP"|pageNumber:pageNoOffsetForComparison}}

							</span>
						</div>
					</app-anchor>
				</div>
				<div class="ca-index-group ">
					<div class="ca-heading">
						Background
					</div>
					<app-anchor type="LINK" anchorId="CS&E">
						<div class="ca-sub-heading">
							<span class="ca-title">Character Strengths & Evolution</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"CS&E"|pageNumber:pageNoOffsetForComparison}}
							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="Q/P">
						<div class="ca-sub-heading">
							<span class="ca-title">Qualities/Potential</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"Q/P"|pageNumber:pageNoOffsetForComparison}}

							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="CHALLENGES">
						<div class="ca-sub-heading">
							<span class="ca-title">Challenges</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"CHALLENGES"|pageNumber:pageNoOffsetForComparison}}

							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="THRIVING">
						<div class="ca-sub-heading">
							<span class="ca-title">Thriving</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"THRIVING"|pageNumber:pageNoOffsetForComparison}}

							</span>
						</div>
					</app-anchor>
				</div>
				<div class="ca-index-group">
					<div class="ca-heading">
						Understanding Your profile
					</div>

					<app-anchor type="LINK" anchorId="A&D_12">
						<div class="ca-sub-heading">
							<span class="ca-title">12 Archetypes and Descriptors</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"A&D_12"|pageNumber:pageNoOffsetForComparison}}
							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="PERSONAS_4">
						<div class="ca-sub-heading">
							<span class="ca-title">4 Personas</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"PERSONAS_4"|pageNumber:pageNoOffsetForComparison}}
							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="CSG">
						<div class="ca-sub-heading">
							<span class="ca-title">Character Strength Groups</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"CSG"|pageNumber:pageNoOffsetForComparison}}
							</span>
						</div>
					</app-anchor>
				</div>
				<div class="ca-index-group">
					<app-anchor type="LINK" anchorId="ACS&C">
						<div class="ca-heading">
							<span class="ca-title"> About Character Strengths & Charametrics</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"ACS&C"|pageNumber:pageNoOffsetForComparison}}
							</span>
						</div>
					</app-anchor>
					<app-anchor type="LINK" anchorId="HtIR">
						<div class="ca-heading">
							<span class="ca-title">How to Interpret Results</span>
							<span class="ca-dots"></span>
							<span class="ca-page-number">
								{{"HtIR"|pageNumber:pageNoOffsetForComparison}}
							</span>
						</div>
					</app-anchor>
				</div>
			</div>
		</div>
		<app-page-footer [onlyOnPrint]="false" [hideCopyRights]="true"></app-page-footer>
	</div>
</section>
