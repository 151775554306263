import { Directionality } from "@angular/cdk/bidi";
import { CdkStepper } from "@angular/cdk/stepper";
import { ElementRef, OnInit } from "@angular/core";
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component
} from "@angular/core";

@Component({
	selector: "app-step-parent-group-stepper",
	templateUrl: "./step-parent-group-stepper.component.html",
	styleUrls: ["./step-parent-group-stepper.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{ provide: CdkStepper, useExisting: StepParentGroupStepperComponent }
	]
})
export class StepParentGroupStepperComponent extends CdkStepper {
	onClick(index: number): void {
		this.selectedIndex = index;
	}
	changeDetectorRef;
	constructor(
		dir: Directionality,
		changeDetectorRef: ChangeDetectorRef,
		ElementRef: ElementRef
	) {
		super(dir, changeDetectorRef, ElementRef, null);
		this.changeDetectorRef = changeDetectorRef;
		// this.selectionChange.subscribe(res => {		});
	}
}
