export function addMonths(date: Date, months: number) {
	date.setMonth(date.getMonth() + months);
	return date;
}

export function isToday(date: Date): boolean {
	const today = new Date();
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	);
}
