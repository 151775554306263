<h1 mat-dialog-title>
	<app-avatar [avatar]="data?.subscription?.group" [fxInline]="true">
	</app-avatar> - Share Invoice
</h1>
<button mat-icon-button class="ca-dialog-close-icon" [mat-dialog-close]>
	<mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
	<mat-card>
		<form *ngIf="shareFG" [formGroup]="shareFG" (submit)="send()">
			<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
				<div fxFlex="1 1" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="16px">
					<app-emails #emails class="full-width" fcName="emailIds" [fg]="shareFG" label="Recipients"
						placeholder="Type in Email address">
					</app-emails>
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
						<button type="button" mat-stroked-button [matTooltip]="user?.emailId" (click)="prefillUserEmail()">Add
							Partner Portal User Email</button>
						<button type="button" [disabled]="!stepUser?.email"
							[matTooltip]="!stepUser?.email?'User Not Logged In':stepUser?.email" mat-stroked-button
							(click)="prefillStepUserEmail()">Add STEP User Email</button>
						<button type="button" [disabled]="!data.subscription?.group?.owner?.email"
							[matTooltip]="data.subscription?.group?.owner?.email" mat-stroked-button (click)="prefillOwnerEmail()">Add
							STEP Group owner Email</button>
					</div>
				</div>

				<div fxFlex="0 0 150px" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="16px">
					<button type="button" mat-button (click)="reset()">Reset</button>
					<button type="submit" [disabled]="shareFG.invalid" mat-flat-button color="primary">
						<mat-icon>send</mat-icon>
						Send
					</button>
				</div>
			</div>
		</form>
	</mat-card>
</mat-dialog-content>