import { Component, Input, OnChanges } from "@angular/core";
import { generateQRForString } from "../../utils/qr-code";

@Component({
	selector: "app-qr-code",
	templateUrl: "./qr-code.component.html",
	styleUrls: ["./qr-code.component.scss"]
})
export class QrCodeComponent implements OnChanges {
	@Input() url?: string;
	@Input() text?: string;
	@Input() size?: number = 128;
	@Input() showInfoText?: boolean = false;
	qrCode?: string;
	constructor() {}
	ngOnChanges(): void {
		this.generateQRCode();
	}
	generateQRCode() {
		if (this.url) {
			generateQRForString(this.url, this.size).then(res => {
				this.qrCode = res;
			});
		}
	}
}
