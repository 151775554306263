<ngx-ui-loader *ngIf="!inplaceLoader" [text]="text||''" [loaderId]="loaderId||'master'" [hasProgressBar]="false"
	[fgsTemplate]="foregroundSpinner" [bgsTemplate]="backgroundSpinner" [fgsSize]="fgsSize" [bgsSize]="bgsSize"
	[bgsPosition]="bgsPosition">
</ngx-ui-loader>
<ng-template #foregroundSpinner>
	<mat-spinner [diameter]="fgsSize"></mat-spinner>
</ng-template>
<ng-template #backgroundSpinner>
	<mat-spinner [diameter]="bgsSize"></mat-spinner>
</ng-template>


<ng-container *ngIf="inplaceLoader && showLoader">
	<div class="inplace-loader">
		<ng-container *ngTemplateOutlet="foregroundSpinner"></ng-container>
	</div>
</ng-container>
