import { capitalize } from "lodash";
import {
	descsCharacterStrengthGroup,
	valuesCharacterStrengthGroup
} from "../../types/character360";
declare type CHARACTER_TYPE = "PERFORMANCE" | "MORAL";
declare type GROUP_TYPE = "INDIVIDUAL" | "TEAM" | "COMBINED";
export type CHARACTER_GROUP =
	| "PURPOSEFULNESS"
	| "HOPE"
	| "TRUTHFULNESS"
	| "LOVE"
	| "RESPECT"
	| "MINDFULNESS"
	| "WISDOM"
	| "TRUSTWORTHINESS"
	| "COMPASSION"
	| "COURAGE"
	| "TOLERANCE"
	| "SELF-DISCIPLINE";
export const CHARACTER_GROUPS: CHARACTER_GROUP[] = [
	"PURPOSEFULNESS",
	"HOPE",
	"TRUTHFULNESS",
	"LOVE",
	"RESPECT",
	"MINDFULNESS",
	"WISDOM",
	"TRUSTWORTHINESS",
	"COMPASSION",
	"COURAGE",
	"TOLERANCE",
	"SELF-DISCIPLINE"
];
export const CHARACTER_GROUPS_LABELS = (() =>
	CHARACTER_GROUPS.map(capitalizeEachWord))();

export const KEYS = {
	MORAL: {
		COMBINED: [
			"PURPOSEFULNESS & CLARITY",
			"HOPE & ENCOURAGEMENT",
			"TRUTHFULNESS & HONESTY",
			"LOVE & ENTHUSIASM",
			"",
			"RESPECT & COOPERATION",
			"MINDFULNESS & COMPOSURE"
		],
		TEAM: [
			"CLARITY",
			"ENCOURAGEMENT",
			"HONESTY",
			"ENTHUSIASM",
			"",
			"COOPERATION",
			"COMPOSURE"
		],
		INDIVIDUAL: [
			"PURPOSEFULNESS",
			"HOPE",
			"TRUTHFULNESS",
			"LOVE",
			"",
			"RESPECT",
			"MINDFULNESS"
		]
	},
	PERFORMANCE: {
		COMBINED: [
			"",
			"WISDOM & LEADERSHIP",
			"TRUSTWORTHINESS & RELIABILITY",
			"COMPASSION & HELPFULNESS",
			"COURAGE & CONFIDENCE",
			"TOLERANCE & FLEXIBILITY",
			"SELF-DISCIPLINE & PROFESSIONALISM"
		],
		TEAM: [
			"",
			"LEADERSHIP",
			"RELIABILITY",
			"HELPFULNESS",
			"CONFIDENCE",
			"FLEXIBILITY",
			"PROFESSIONALISM"
		],
		INDIVIDUAL: [
			"",
			"WISDOM",
			"TRUSTWORTHINESS",
			"COMPASSION",
			"COURAGE",
			"TOLERANCE",
			"SELF-DISCIPLINE"
		]
	}
};
export const LABELS = (() => {
	let labels: any = {};
	for (let typeKey of Object.keys(KEYS)) {
		let type = KEYS[typeKey as CHARACTER_TYPE];
		labels[typeKey] = {};
		for (let groupKey of Object.keys(type)) {
			let group = type[groupKey as GROUP_TYPE];
			labels[typeKey][groupKey] = group.map(capitalizeEachWord);
		}
	}
	return labels;
})();
const DATA = {
	LABELS,
	KEYS
};
export function prepareDataValues(
	data: any,
	type: CHARACTER_TYPE,
	group: GROUP_TYPE
) {
	let keys = getValues("KEYS", type, group);
	let values = keys.map((key: string) => data[key]);
	return values;
}
export function capitalizeEachWord(s: string) {
	return s.replace(/\w+/g, capitalize);
}
export function getValues(
	datatype: "LABELS" | "KEYS",
	type: CHARACTER_TYPE,
	group: GROUP_TYPE
) {
	return DATA[datatype][type][group];
}
export const WHOLE_CHARACTERS_LABELS = [
	"Purposefulness (M)",
	"Hope (M)",
	"Truthfulness (M)",
	"Love (M)",
	"Respect (M)",
	"Mindfulness (M)",
	"Self-Discipline (P)",
	"Tolerance (P)",
	"Courage (P)",
	"Compassion (P)",
	"Trustworthiness (P)",
	"Wisdom (P)"
];
export const WHOLE_CHARACTERS_KEYS = {
	MORAL: [
		"PURPOSEFULNESS & CLARITY",
		"HOPE & ENCOURAGEMENT",
		"TRUTHFULNESS & HONESTY",
		"LOVE & ENTHUSIASM",
		"RESPECT & COOPERATION",
		"MINDFULNESS & COMPOSURE"
	],
	PERFORMANCE: [
		"SELF-DISCIPLINE & PROFESSIONALISM",
		"TOLERANCE & FLEXIBILITY",
		"COURAGE & CONFIDENCE",
		"COMPASSION & HELPFULNESS",
		"TRUSTWORTHINESS & RELIABILITY",
		"WISDOM & LEADERSHIP"
	]
};
export const WHOLE_CHARACTERS_KEYS_INDIVIDUAL = {
	MORAL: [
		"PURPOSEFULNESS",
		"HOPE",
		"TRUTHFULNESS",
		"LOVE",
		"RESPECT",
		"MINDFULNESS"
	],
	PERFORMANCE: [
		"SELF-DISCIPLINE",
		"TOLERANCE",
		"COURAGE",
		"COMPASSION",
		"TRUSTWORTHINESS",
		"WISDOM"
	]
};
export const ALIGNMENT_BY_LAYERS_KEYS = [
	"MINDFULNESS & SELF-DISCIPLINE",
	"RESPECT & TOLERANCE",
	"COURAGE",
	"LOVE & COMPASSION",
	"TRUTHFULNESS & TRUSTWORTHINESS",
	"HOPE & WISDOM",
	"PURPOSEFULNESS"
];
export const ALIGNMENT_BY_LAYERS_LABELS = (() =>
	ALIGNMENT_BY_LAYERS_KEYS.map(capitalizeEachWord).map(
		(name: string, i: number) => `${i + 1}. ${name}`
	))();

export function prepareCharacterStrengthData(
	characterStrengths: valuesCharacterStrengthGroup,
	characterStrengthsDescriptions: descsCharacterStrengthGroup
) {
	return CHARACTER_GROUPS.map((label, i) => {
		return {
			label,
			displayLabel: CHARACTER_GROUPS_LABELS[i],
			value: characterStrengths?.[label]?.[0] || 0,
			valuePercent: characterStrengths?.[label]?.[1] || 0,
			descs: characterStrengthsDescriptions?.[label] || []
		};
	}).sort((a, b) => b.value - a.value);
}
export function previewOffset(isPreview: boolean) {
	if (isPreview) return 2;
	return 0;
}
