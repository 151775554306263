import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";
import { DialogsService } from "../../services/dialogs.service";

@Component({
	selector: "app-step-group",
	templateUrl: "./step-group.component.html",
	styleUrls: ["./step-group.component.scss"]
})
export class StepGroupComponent {
	refresh = false;
	canClose = false;
	constructor(
		public dialogRef: MatDialogRef<StepGroupComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private dialogsService: DialogsService
	) {}
	label(type: "Parent" | "Reporting") {
		return `${
			this.dialogData?.subscription?.id ? "Edit" : "New"
		} ${type} Group`;
	}
	async close() {
		if (this.canClose) {
			this.dialogRef.close({ refresh: this.refresh });
		} else {
			let canClose = await this.dialogsService.confirmIfUnsavedChanges(
				"Any unsaved changes will be lost. Are you still want to close?",
				{
					confirmText: "Yes",
					dismissText: "No",
					confirmColor: "warn"
				}
			);
			if (canClose) {
				this.dialogRef.close({ refresh: this.refresh });
			}
		}
	}
}
