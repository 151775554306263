import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { uniq } from "lodash";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { LoggerService } from "src/app/_shared/services/logger.service";
import { validateChips } from "../../form/validator/validate-chips";
import { AuthService } from "../../services/auth.service";
import { StepAuthService } from "../../services/step-auth.service";
import { StepGroupService } from "../../services/step-group.service";
import { SUBSCRIPTION_INVOICE_RESEND_PAYLOAD } from "../../types/step-group";
import { EmailsComponent } from "../emails/emails.component";

@Component({
	selector: "app-share-invoice",
	templateUrl: "./share-invoice.component.html",
	styleUrls: ["./share-invoice.component.scss"]
})
export class ShareInvoiceComponent implements AfterViewInit {
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	shareFG?: FormGroup;
	@ViewChild("emails", { static: false }) emails!: EmailsComponent;
	user!: any;
	stepUser!: any;
	get emailIds() {
		return this.shareFG?.get("emailIds");
	}
	constructor(
		public dialogRef: MatDialogRef<ShareInvoiceComponent>,
		public stepGroupService: StepGroupService,
		private UiLoaderService: NgxUiLoaderService,
		public Logger: LoggerService,
		private _snackBar: MatSnackBar,
		private fb: FormBuilder,
		private stepAuthService: StepAuthService,
		private authService: AuthService,

		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.shareFG = this.fb.group({
			emailIds: [[], [validateChips, Validators.required]]
		});
	}
	ngAfterViewInit(): void {
		this.authService.getUserInfo().subscribe(user => {
			this.user = user;
			this.prefillUserEmail();
		});
		this.stepAuthService.getUserInfo().subscribe(user => {
			this.stepUser = user;
			this.prefillStepUserEmail();
		});
		this.prefillOwnerEmail();
	}
	prefillUserEmail(silent = false) {
		if (this.user?.emailId) {
			this.addEmail(this.user.emailId, silent);
		}
	}
	prefillStepUserEmail(silent = false) {
		if (this.stepUser?.email) {
			this.addEmail(this.stepUser.email, silent);
		}
	}
	prefillOwnerEmail(silent = false) {
		if (this.data?.subscription?.group?.owner?.email) {
			this.addEmail(this.data?.subscription?.group?.owner?.email, silent);
		}
	}
	prefill() {
		this.prefillUserEmail(true);
		this.prefillStepUserEmail(true);
		this.prefillOwnerEmail(true);
		this.emails.updateValueAndValidity();
	}
	addEmail(email: string, silent = false) {
		this.emails.addEmail(email, silent);
	}
	reset() {
		this.emails.reset();
		this.prefill();
		this.emailIds?.markAsUntouched();
	}
	send() {
		if (this.shareFG?.valid) {
			let payload: SUBSCRIPTION_INVOICE_RESEND_PAYLOAD = {
				emailIds: uniq(this.shareFG?.value.emailIds),
				stripeId: this.data.invoice.stripeId
			};
			this.UiLoaderService.start();
			this.stepGroupService
				.resendSubscriptionInvoice(payload)
				.subscribe((res: any) => {
					if (res.statusCode === 200) {
						this.UiLoaderService.stop();
						this.dialogRef.close();
						this._snackBar.open(
							"Email will be sent within a few minutes.",
							undefined,
							{
								duration: 1000 * 8
							}
						);
					}
				});
		}
	}
}
