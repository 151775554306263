<mat-form-field [formGroup]="fg" *ngIf="fg.get(fcName)">
	<mat-select [formControlName]="fcName" placeholder="Country">
		<mat-option>
			<ngx-mat-select-search [formControl]="countryFilterCtrl" [noEntriesFoundLabel]="'No matching Found'"
				placeholderLabel="Search Country">
			</ngx-mat-select-search>
		</mat-option>
		<mat-option *ngFor="let country of filteredCountries | async" [value]="country.code">
			{{country.name}}
		</mat-option>
	</mat-select>
</mat-form-field>