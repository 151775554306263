import { Component, Input } from "@angular/core";
import { previewOffset } from "../character";

@Component({
	selector: "app-index",
	templateUrl: "./index.component.html",
	styleUrls: ["./index.component.scss"]
})
export class IndexComponent {
	@Input() report: any;
	@Input() isComparison?: boolean;
	@Input() comparisonTitle?: string;
	@Input() pageNoOffsetForComparison: number = 0;
	get previewOffset() {
		return previewOffset(<boolean>this.report.isPreview);
	}
}

// CS&E -  Character Strengths & Evolution
// Q/P -  Qualities/Potential
// CHALLENGES -  Challenges
// THRIVING -  Thriving
// CSG -  Character Strength Groups
// A&D_12 -  12 Archetypes and Descriptors
// PERSONAS_4 -  4 Personas
// YCASW -  Your Character Archetypes Summary Wheel
// YCAST -  Your Character Archetypes Summary Traits
// CAT -  Character Archetypes Table
// YCSG3 -  Your 3 leading character strength groups
// YCSG3@1 -  Your 3 leading character strength groups first one only
// YCGP -  Your Character Growth Plan
// YCSR -  Your Character Strengths Report
// YCSP -  Your Character Strengths Profile
// ACS&C -  About Character Strengths & Charametrics
// HtIR -  How to Interpret Results

// <app-anchor type="LINK" anchorId="CS&E">   // placed in contents page
// <app-anchor anchorId="CS&E"></app-anchor>    // point where the link ref to
