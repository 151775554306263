import { StepAuthService } from "./../services/step-auth.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "./../services/auth.service";
import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService,
		public activatedRoute: ActivatedRoute,
		public stepAuthService: StepAuthService
	) {}
	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		let authReq;
		if (this.authService.isLoggedIn()) {
			this.authService.expirationCounter(60);
		}
		if (request.url?.startsWith(environment.baseUrl)) {
			//* INFO jwt auth for CA app
			if (
				this.activatedRoute?.snapshot?.queryParams &&
				!request.url?.includes("account/user") &&
				!request.url?.includes("account/login/email") &&
				!request.url?.includes("report/add_to_user")
			) {
				const qParams = this.activatedRoute?.snapshot?.queryParams;
				if (qParams?.authorization) {
					let authToken = qParams?.authorization;
					let authType = qParams?.authType;
					let authReq = request.clone({
						setHeaders: {
							authorization: `${authType} ${authToken}`
						}
					});
					return next.handle(authReq);
				}
			}
			if (this.authService.isLoggedIn()) {
				let auth = this.authService.getAuth();
				let headers: any = {
					Authorization: `${auth.type} ${auth.token}`
				};
				if (this.stepAuthService.isLoggedIn()) {
					let stepAuth = this.stepAuthService.getAuth();
					headers["STEP-Authorization"] = stepAuth.token;
				}
				authReq = request.clone({
					setHeaders: headers
				});
				return next.handle(authReq);
			} else {
				const basicAuth =
					"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MCwiaWF0IjoxNjU0Njc4MTExfQ.tTBsroKGFzv8D1xcPL_rLmQ-CESTozIhMdqNRFhj2OY";
				authReq = request.clone({
					setHeaders: {
						Authorization: `basicJWT ${basicAuth}`
					}
				});
				return next.handle(authReq);
			}
		}

		if (request.url?.startsWith(environment.stepBaseUrl)) {
			//* INFO jwt auth for STEP app
			let headers: any = {};
			if (this.authService.isLoggedIn()) {
				let auth = this.authService.getAuth();
				headers["STCA-Authorization"] = `${auth.type} ${auth.token}`;
			}
			if (this.stepAuthService.isLoggedIn()) {
				let auth = this.stepAuthService.getAuth();
				headers["Authorization"] = <string>auth.token;
			}
			authReq = request.clone({
				setHeaders: headers
			});
			return next.handle(authReq);
		}
		return next.handle(request);
	}
}

export const addTokenHeader = new HttpHeaders({ addAuthToken: "authToken" });
